import React from "react";

function ContactUs() {
  return (
    <div>
      <div className=" asdfghjk jumbotron mt-5 mb-5">
        <div className="container mb-5">
          <h1>Contact us!</h1>
          <p>
            If you want to contact us please use this form below. Or send the
            email to{" "}
            <a href="admission@swaminarayanuniversity.ac.in">
              admission@swaminarayanuniversity.ac.in
            </a>{" "}
            &amp; call us at <a href="tel:6357675561">+91 6357675561</a> or{" "}
            <a href="tel:1800-843-2900">1800-843-2900</a>
          </p>
        </div>
      </div>
      <div className="container mb-5">
        <div className="row">
          <div className="col-md-6">
            <h2>Google Map</h2>
            <p>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3666.5012270655993!2d72.5079789!3d23.224840899999993!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395c3f9120099141%3A0xa2ea509a8429c97d!2sSwaminarayan%20University!5e0!3m2!1sen!2sin!4v1671776635756!5m2!1sen!2sin"
                title="SU sims"
                width="600"
                height="450"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </p>
          </div>
          <div className="col-md-6">
            <h2>Address</h2> <hr />
            <div className="row mt-3">
              <div className="col-md-">
                <h4>
                  Swaminarayan Institute of Medical Sciences and Research,
                  Kalol.
                </h4>
                <p>Ahmedabad Mehsana Highway </p>
                <p>At & Post: Saij </p>
                <p>Ta: Kalol, Dist.: Gandhinagar </p>
                <p>State: Gujarat</p>
                <p>Pin: 382725</p>
                <h6>
                  Mobile: <a href="tel:+91-9114139114"> +91-9114139114</a>
                </h6>
                <h6>
                  Email:{" "}
                  <a href="mailto:deanoffice.sims@swaminarayanuniversity.ac.in">
                    deanoffice.sims@swaminarayanuniversity.ac.in
                  </a>
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
