import React from "react";

function Mission_Vision() {
  return (
    <div>
      <>
        <div className="MissionVision">
          <div className="health-care-area ptb-100 bg-f4f6f9">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-6 col-md-6">
                  <div className="single-health-care-card">
                    <div className="card"></div>
                    <div className="health-care-content">
                      <a href="#">
                        <h3>Mission</h3>
                      </a>
                      <p>
                        <i
                          className="ri-checkbox-circle-line"
                          style={{ color: "#e32845" }}
                        />{" "}
                        Our pharmacy college is dedicated to preparing students
                        to become compassionate, competent, and innovative
                        pharmacists who contribute meaningfully to healthcare.
                        Our mission is to promote the students in higher
                        education and to assess our progress through Strategic
                        Plan with farsighted fortune. Our college will provide a
                        comprehensive education that integrates cutting-edge
                        research, evidence-based practice, and ethical
                        principles to ensure our graduates are well-equipped to
                        meet the evolving needs of patients and society. we
                        foster a supportive environment that nurtures personal
                        and professional growth. Our mission extends beyond the
                        classroom as we actively engage with our community to
                        promote health, and advance the pharmacy profession.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="single-health-care-card">
                    <div className="img">
                      <a href="#">
                        <img
                          src="../assets/images/academics/mission.jpg"
                          alt="Image"
                          className="missionpharm"
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-lg-6 col-md-6">
                    <div className="single-health-care-card">
                      <div className="card">
                        <a href="#">
                          <img
                            src="../assets/images/academics/vision.jpg"
                            alt="Image"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="single-health-care-card">
                      <div className="card"></div>
                      <div className="health-care-content">
                        <a href="#">
                          <h3>Vision</h3>
                        </a>
                        <p>
                          <i
                            className="ri-checkbox-circle-line"
                            style={{ color: "#e32845" }}
                          />{" "}
                          Our vision is to be a global leader in pharmaceutical
                          education, research, and healthcare innovation. To
                          nurture and promote education with value based
                          education. Our graduates will be recognized for their
                          clinical expertise, leadership abilities, and
                          commitment to serving as advocates for health equity
                          and social justice. We aspire to cultivate a diverse
                          learning environment that empowers students to excel
                          academically, professionally, and ethically. As we
                          keep pushing the boundaries of knowledge and practice,
                          we envision our pharmacy college as a catalyst for
                          good change in healthcare on a local and global level.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

export default Mission_Vision;
