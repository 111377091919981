import React from 'react'
import axios from 'axios';
import "../Connect/Downloads/Brochure.scss";
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { RESEARCH_PUBLICATION_GET } from '../../Utils/InfoUploadingConst';

function ReseachandPublicationEng() {

  const [data, setData] = useState([]);

  const navigate = useNavigate();

  const getData = async () => {
      const config = {
          method: "get",
          url: RESEARCH_PUBLICATION_GET,
          headers: {
              "Content-Type": "application/json",
          },
      };

      await axios(config)
          .then((res) => {
              res.data.data.sort((a, b) => b.id - a.id);
              res.data.data.forEach((element) => {
                  element.attachments = JSON.parse(element.attachments);
              });
              console.log(res.data.data)
              setData(res.data.data);

          })
          .catch((err) => {
              console.log(err);
          });
  };

  useEffect(() => {
      getData();
  }, []);

  const next = async (id, data1, images) => {
      console.log("error here");
      navigate(`/engineering/ReseachandPublicationEng/${id}`, {
          state: {
              images: images,
              data: data1,
          },
      });
  };

  return (
    <div>

<div className="container mt-5 mb-5">
  <div className="row">
    <div className='col-md-12'>
      <h3> Research and Publications </h3>
    </div>


    <div className="row">

    {data.map((item, key) => {
      return (
      item.status === "ACTIVE" && item.college_id == "1111000"  &&(

    <div className='col-md-6 pt-4'>   
    <div className='card border-danger shadow-reguler' key={item.id} style={{ cursor: "pointer" }}>

    <a href={item?.attachments} target='_blank'>

      <div className="card-body">    
        <h5>{item?.title}</h5>
      
      <div className="d-flex justify-content-between">    
        <p>{item?.researcher}</p>
        <p> {item?.month} - {item?.publishdate.split('-')[0]}</p>
      </div>

      </div>

    </a>

    </div>                                        
  </div>

)
)
})
}
  
  </div>
   
  </div>
  <br />
  <br />
</div>



    </div>
  )
}

export default ReseachandPublicationEng;

// function ReseachandPublicationEng() {
    
//     const [data, setData] = useState([]);

//     const navigate = useNavigate();

//     const getData = async () => {
//         const config = {
//           method: "get",
//           url: RESEARCH_PUBLICATION_GET,
//           headers: {
//             "Content-Type": "application/json",
//           },
//         };
    
//         await axios(config)
//           .then((res) => {

           
            
//             const ResearchEng = res.data.data.filter(element => element.college_id == "1111000");       
        
//             setData(ResearchEng);
            
//           })
//           .catch((err) => {
//             console.log(err);
//           });
//       };
    
//       useEffect(() => {
//         getData();
//       }, []);

   
//     return (
//       <div>

        
//         <div className="container mt-5" >
//         <div className="row">

//             <div className='col-md-12'>
//                 <h3> Research and Publications </h3>
//             </div>

            

//           {data &&
//               data?.map((item, key) => {
//                 return (

//                     <>

//                     <div className='col-md-6 pt-4'>   
//                       <div className='card border-danger shadow-reguler' style={{ cursor: "pointer" }}>

//                       <a href={item?.attachments} target='_blank'>

//                         <div className="card-body">    
//                           <h5>{item?.title}</h5>
                        
//                         <div className="d-flex justify-content-between">    
//                           <p>{item?.researcher}</p>
//                           <p> {item?.month} - {item?.publishdate.split('-')[0]}</p>
//                         </div>

//                         </div>

//                       </a>

//                       </div>                                        
//                     </div>

//                     </>  

//               );
//             })}
            
            
//         </div>
//         </div>

//         <br></br>
//         <br></br>
//         <br></br>
//         <br></br>
//         <br></br>
//         <br></br>
//         <br></br>
//         <br></br>
//         <br></br>

        
//       </div>
      
//     )
// }

// export default ReseachandPublicationEng;