import React from 'react'

function HomeopathyHospitalStaff() {
  return (
<div className="Notifications px-5">
  
  <div className="courses-details-area pt-100 pb-70">
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          <div className="courses-details">
            <div className="courses-card">
              <h2 className="text-center">Hospital Staff</h2>
            </div>
            <div className="description">
             <table className="table table-bordered table-hover">
      <thead>

      <tr>
    <th>Sr. No</th>
    <th>Names</th>
    <th>Department</th>
    <th>Designation</th>
    <th>QUALIFICATION</th>
    <th>WORK EXPERIENCE</th>
    <th>JOINING DATE</th>
    <th>DATE OF BIRTH</th>
  </tr>
      </thead>
  <tbody>
  <tr>
    <td>1</td>
    <td>Dr. Dinesh Rao</td>
    <td>PM</td>
    <td>Professor</td>
    <td>MD</td>
    <td>20 YEARS</td>
    <td>14/11/2022</td>
    <td>30/3/1968</td>
  </tr>
  <tr>
    <td>2</td>
    <td>Dr.Jigna Patel</td>
    <td>HOUSE PHYSICIAN</td>
    <td>MO</td>
    <td></td>
    <td></td>

    <td></td>
    <td>31-Dec</td>
  </tr>

  <tr>
    <td>3</td>
    <td>Dr. Chirag Soni</td>
    <td>Homo.Hospital</td>
    <td>R.M.O</td>
    <td></td>
    <td></td>

    <td>1/2/2017</td>
    <td></td>
  </tr>
  
  
  <tr>
    <td>4</td>
    <td>Dr. Hashmukh Rathod</td>
    <td>HOUSE PHYSICIAN</td>
 <td></td>
    <td></td>
    <td></td>

    <td>1/12/2018</td>
    <td></td>
  </tr>
  
  <tr>
    <td>5</td>
    <td>Dr.Anil</td>
    <td>Homo.Hospital</td>
    <td></td>
    <td></td>
    <td></td>

   <td></td>
    <td></td>
  </tr>
  
  <tr>
    <td>6</td>
    <td>Dr.Parth </td>
    <td>Homo.Hospital</td>
   <td></td>
    <td></td>
    <td></td>

    <td></td>
    <td></td>
  </tr>
  
  <tr>
    <td>7</td>
    <td>Dr.Aekta Pitroda</td>
    <td>Homo.Hospital</td>
    <td></td>
    <td></td>
    <td></td>
<td></td>
    <td></td>
  </tr>
  <tr>
    <td>8</td>
    <td>DR Dhaval Desai</td>
    <td>Homo.Hospital</td>
    <td></td>
    <td></td>
    <td></td>
<td></td>
    <td></td>
  </tr>
  <tr>
    <td>9</td>
    <td>Dr. Shweta Gahlot</td>
    <td>Homo.Hospital</td>
    <td></td>
    <td></td>
    <td></td>
<td></td>
    <td></td>
  </tr>
  <tr>
    <td>10</td>
    <td>Dr.Vismay Raval</td>
    <td>Homo.Hospital</td>
    <td></td>
    <td></td>
    <td></td>
<td></td>
    <td></td>
  </tr>
  <tr>
    <td>11</td>
    <td>Dr.Nkul sule</td>
    <td>Homo.Hospital</td>
    <td></td>
    <td></td>
    <td></td>
<td></td>
    <td></td>
  </tr>
  <tr>
    <td>12</td>
    <td>Dr. Darshan patel</td>
    <td>Homo.Hospital</td>
    <td></td>
    <td></td>
    <td></td>
<td></td>
    <td></td>
  </tr>
  <tr>
    <td>13</td>
    <td>Dr. Shailesh Makvana</td>
    <td>Homo.Hospital</td>
    <td></td>
    <td></td>
    <td></td>
<td></td>
    <td></td>
  </tr>
  <tr>
    <td>14</td>
    <td>Dr. kiran Chokhavala</td>
    <td>Homo.Hospital</td>
    <td>pathologist</td>
    <td></td>
    <td></td>
<td></td>
    <td></td>
  </tr>
  <tr>
    <td>15</td>
    <td>Dr. Roma Piprota</td>
    <td>Homo.Hospital</td>
    <td></td>
    <td></td>
    <td>1/1/2016</td>
<td></td>
    <td></td>
  </tr>
  <tr>
    <td>16</td>
    <td>Dr. Kapil Vyash</td>
    <td>Homo.Hospital</td>
    <td>YOGA EXPERT </td>
    <td></td>
    <td></td>
<td></td>
    <td></td>
  </tr>
  <tr>
    <td>17</td>
    <td>Dr.Charmi Shah
</td>
    <td>Homo.Hospital</td>
    <td></td>
    <td></td>
    <td></td>
<td></td>
    <td></td>
  </tr>
 
  

  </tbody>
             </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
  )
}

export default HomeopathyHospitalStaff