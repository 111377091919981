import React from "react";

function Eligibility() {
  return (
    <div>
      <div className="container mt-5 mb-5">
        <div className="row register" style={{maxWidth: "100%"}}>
          <div className="col-md-12">
            <p>
              <span style={{ fontSize: "18pt" }}>
                <h3>Eligibility Details</h3>
              </span>
              <br />
            </p>
            <p>
              The following are the course eligibility conducted by Shree
              Swaminarayan Enginnering College
            </p>

            <p>
              <strong>All Courses Eligibility Details:</strong>
              <br />
            </p>
            <table
              className="table table-bordered"
              style={{ borderCollapse: "collapse", width: "100%", height: 173 }}
              border={1}
            >
              <tbody>
                <tr style={{ height: 20 }}>
                  <td
                    style={{
                      width: "5.17544%",
                      height: 20,
                      textAlign: "center",
                    }}
                  >
                    <strong>S.No</strong>
                    <br />
                  </td>
                  <td style={{ width: "10.4386%", height: 20 }}>
                    <strong>Course</strong>
                    <br />
                  </td>

                  <td style={{ width: "74.2105%", height: 20 }}>
                    <strong>Eligibility</strong>
                  </td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td
                    style={{
                      width: "5.17544%",
                      height: 20,
                      textAlign: "center",
                    }}
                    rowSpan={6}
                  >
                    1<br />
                  </td>
                  <td style={{ width: "10.4386%", height: 20 }} rowSpan={6}>
                    B.Pharm
                    <br />
                  </td>

                  <td>
                    Educational Qualification: Candidates must have completed a
                    10+2 or equivalent examination from a recognized board or
                    university.
                  </td>
                </tr>
                <tr>
                  <td>
                    The qualifying examination should include Physics,
                    Chemistry, and Biology or Mathematics as compulsory
                    subjects.
                  </td>
                </tr>
                <tr>
                  <td>
                    Domicile: Candidates must be Indian nationals and should
                    fulfill the domicile requirements of the state of Gujarat as
                    specified by ACPC.
                  </td>
                </tr>
                <tr>
                  <td>
                    Entrance Exam: ACPC conducts the Gujarat Common Entrance
                    Test (GUJCET) for admission to B.Pharm programs.
                  </td>
                </tr>
                <tr>
                  <td>
                    Candidates must have appeared for the GUJCET and obtained a
                    valid rank in the examination.
                  </td>
                </tr>
                <tr>
                  <td>
                    Age Limit: There is no specific age limit mentioned by ACPC
                    for admission to B.Pharm programs.
                  </td>
                </tr>

                <tr style={{ height: 20 }}>
                  <td
                    style={{
                      width: "5.17544%",
                      height: 20,
                      textAlign: "center",
                    }}
                    rowSpan={8}
                  >
                    2<br />
                  </td>
                  <td style={{ width: "10.4386%", height: 20 }} rowSpan={8}>
                    M.Pharm
                    <br />
                  </td>

                  <td>
                    Educational Qualification: Candidates must possess a
                    Bachelor's degree in Pharmacy (B.Pharm) from a recognized
                    institution approved by the Pharmacy Council of India (PCI)
                    or the All India Council for Technical Education (AICTE).
                  </td>
                </tr>
                <tr>
                  <td>
                    Minimum Percentage: Candidates must have obtained a minimum
                    aggregate score in their B.Pharm degree. The minimum
                    percentage requirement is 55% may vary depending on the
                    specialization and the institute. Please note that the exact
                    minimum percentage may vary each year, so it's essential to
                    refer to the official ACPC notifications for the most
                    up-to-date information.
                  </td>
                </tr>
                <tr>
                  <td>
                    (a)For candidates having not less than 5 years of
                    professional experience, after passing B. Pharm course,
                    there shall be a relaxation in pass percentage from 55% to
                    50% for admission to the M.Pharm program.
                  </td>
                </tr>
                <tr>
                  <td>
                    (b)There shall be reservation of seats for the students
                    belonging to the Scheduled Castes, Scheduled Tribes and
                    Other Backward Classes in accordance with the instructions
                    issued by the Central Government/State Government/Union
                    Territory Administration, as the case may be, from time to
                    time.
                  </td>
                </tr>
                <tr>
                  <td>
                    (c)For SC/ST candidates the prescribed percentage of marks
                    will be 50 % of the maximum marks (aggregate of four years
                    of B.Pharm).
                  </td>
                </tr>
                <tr>
                  <td>
                    (d)Every student, selected for admission to a postgraduate
                    pharmacy course in any of the pharmacy institutions in the
                    country should have obtained Registration with the State
                    Pharmacy Council or should obtain the same within one month
                    from the date of his admission, failing which the admission
                    of the candidate shall be cancelled.
                  </td>
                </tr>
                <tr>
                  <td>
                    Entrance Exam: ACPC conducts the Gujarat Common Entrance
                    Test (GCET) for admission to M.Pharm courses in Gujarat.
                    Candidates must appear for GCET and obtain a valid rank to
                    be eligible for admission. The GCET is typically held in May
                    or June every year, and the exam pattern and syllabus are
                    based on the B.Pharm curriculum.
                  </td>
                </tr>
                <tr>
                  <td>
                    Domicile Requirement: Candidates must fulfill the domicile
                    requirements set by ACPC for admission to M.Pharm courses in
                    Gujarat. Generally, candidates must be Indian citizens and
                    either be a resident of Gujarat or have passed the
                    qualifying examination (B.Pharm) from an institute located
                    in Gujarat.
                  </td>
                </tr>

                <tr style={{ height: 20 }}>
                  <td
                    style={{
                      width: "5.17544%",
                      height: 20,
                      textAlign: "center",
                    }}
                    rowSpan={3}
                  >
                    3<br />
                  </td>
                  <td style={{ width: "10.4386%", height: 20 }} rowSpan={3}>
                    Pharm D.
                    <br />
                  </td>

                  <td>
                    Educational Qualification: Candidates must have completed a
                    10+2 or equivalent examination from a recognized board or
                    university.
                  </td>
                </tr>
                <tr>
                  <td>
                    The qualifying examination should include Physics,
                    Chemistry, and Biology or Mathematics as compulsory
                    subjects.
                  </td>
                </tr>
                <tr>
                  <td>
                    Age Limit: There is no specific age limit mentioned by ACPC
                    for admission to B.Pharm programs.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Eligibility;
