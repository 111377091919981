import { BASE_URL } from "./apiConst";

//Academics

//Add Subject
export const ACADEMICS_ADD_SUBJECT = `${BASE_URL}/academics/course`

//Add Lesson
export const ACADEMICS_ADD_LESSON = `${BASE_URL}/academics/lesson`

//Add Topic
export const ACADEMICS_ADD_TOPIC = `${BASE_URL}/academics/topic`

//Add Class
export const ACADEMICS_ADD_CLASS = `${BASE_URL}/academics/class`

//Add Semester
export const ACADEMICS_ADD_SEMESTER = `${BASE_URL}/academics/semester`

//Add Semester
export const ACADEMICS_ADD_SECTION = `${BASE_URL}/academics/section`

//Class Time Table
export const ACADEMICS_CLASS_TIME_TABLE = `${BASE_URL}/academics/timetable`

//Add Subject
// export const ACADEMICS_ADD_SUBJECT = `${BASE_URL}/academics/course`

export const ACADEMICS_ADD_SYLLABUS = `${BASE_URL}/academics/course-session`


export const ACADEMICS_ADD_LESSON_PLAN = `${BASE_URL}/academics/lessonplan`


export const ACADEMICS_PROMOTE_STUDENT = `${BASE_URL}/student/session`

export const ACADEMICS_ADD_QUESTION_BANK = `${BASE_URL}/api/academics/questionbank`