import React from 'react'
import axios from "axios"
import {useState,useEffect} from "react"

function Payment_Success() {
  return (
    <div>Payment_Success</div>
  )
}

export default Payment_Success