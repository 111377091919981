import React from "react";

export default function DiagnosticServices() {
  return (
    <div>
      <div className="container mt-5 mb-5">
        <div className="row register" style={{maxWidth: "100%"}}>
          <div className="col-md-12">
            <p>
              <span style={{ fontSize: "18pt" }}>
                <h3>Diagnostic Services</h3>
              </span>
              <br />
            </p>

            <p>
              <strong>CENTRAL LABORATORY</strong>
            </p>

            <table
              className="table table-bordered"
              style={{ borderCollapse: "collapse", width: "100%", height: 173 }}
              border={1}
            >
              <tbody>
                <tr>
                  <td>
                    <p>
                      <strong>S.No.</strong>
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>
                        Investigation done in the from 1st Jan 2022 to 31
                        <sup>st</sup> Dec &nbsp;2022
                      </strong>
                    </p>
                  </td>
                  <td>
                    <p style={{ textAlign: "center" }}>
                      <strong >Number</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p data-mce-word-list={1}>
                      <span data-mce-word-list={1}>
                        1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                      </span>
                      &nbsp;
                    </p>
                  </td>
                  <td>
                    <p>Hematological Tests.</p>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <p>1800</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p data-mce-word-list={1}>
                      <span data-mce-word-list={1}>
                        2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                      </span>
                      &nbsp;
                    </p>
                  </td>
                  <td>
                    <p>Bio-Chemistry Tests.</p>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <p>734</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p data-mce-word-list={1}>
                      <span data-mce-word-list={1}>
                        3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                      </span>
                      &nbsp;
                    </p>
                  </td>
                  <td>
                    <p>Serology Tests.</p>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <p>650</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p data-mce-word-list={1}>
                      <span data-mce-word-list={1}>
                        4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                      </span>
                      &nbsp;
                    </p>
                  </td>
                  <td>
                    <p>Microbiology Tests.</p>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <p>682</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p data-mce-word-list={1}>
                      <span data-mce-word-list={1}>
                        5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                      </span>
                      &nbsp;
                    </p>
                  </td>
                  <td>
                    <p>Total number of Investigations carried out</p>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <p>3866</p>
                  </td>
                </tr>
              </tbody>
            </table>

            <br />

            <table
              className="table table-bordered"
              style={{ borderCollapse: "collapse", width: "100%", height: 173 }}
              border={1}
            >
              <tbody>
                <tr>
                  <td>
                    <p>
                      <strong>Other Investigations</strong>
                    </p>
                  </td>
                  <td>
                    <p style={{ textAlign: "center" }}>
                      <strong>Number</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <strong>X-rays</strong>
                    </p>
                  </td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <strong>
                        Total number of X-ray done from 1st Jan 2022 to 31st Dec
                        2022
                      </strong>
                    </p>
                  </td>
                  <td>
                    <p style={{ textAlign: "center" }}>369</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <strong>ECG</strong>
                    </p>
                  </td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <strong>
                        Total number of ECG done from 1st Jan 2022 to &nbsp;31st
                        Dec 2022
                      </strong>
                    </p>
                  </td>
                  <td>
                    <p style={{ textAlign: "center" }}>63</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <strong>USG</strong>
                    </p>
                  </td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <strong>
                        Total number of USG done from 1st Jan 2022 to 31st Dec
                        2022
                      </strong>
                    </p>
                  </td>
                  <td>
                    <p style={{ textAlign: "center" }}>398</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <strong>AMBULANCE </strong>
                    </p>
                  </td>
                  <td>
                    <p style={{ textAlign: "center" }}> Available</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
