import React from "react";

export default function ResearchActivity() {
  return (
    <div>
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-md-12">
            <p>
              <span style={{ fontSize: "18pt" }}>
                <strong>Research Activity Carried Out</strong>
              </span>
              <br />
            </p>

            <p>
              <strong>All Courses Details:</strong>
              <br />
            </p>

            <ul>
              <li>
                <p>
                  Participated in scientific research association registered
                  international organization, short term training program on
                  “Development personal improvement through soft skill” o 7 th
                  to 13th April,2022
                </p>
              </li>

              <li>
                <p>
                  Participated in “National level faculty development program on
                  inclusive innovative value education and NEP” from 16th to
                  20th march,2022.
                </p>
              </li>
              <li>
                <p>
                  International conference attended on “Teacher education and
                  social concerns on April 27 to 28th ,2018 organized at
                  Udaipur, Rajasthan.
                </p>
              </li>
              <li>
                <p>
                  Participated in 40 hour online faculty development program on
                  “Contemporary research trends and technologies in the field of
                  commerce and management” sponsored by ministry of electronics
                  and information technology (Meity) Gol, organized by E & ICT
                  academy, NIT Warangal and Sabarmati university, Ahmedabad
                  during 1st to 10th February,2022.{" "}
                </p>
              </li>
              <li>
                <p>
                  A Study of Personality Traits of Primary School Teachers of
                  Dahod City. -M.Ed.-Dissertation.
                </p>
              </li>
              <li>
                <p>
                  A Study of Personality Traits of Primary School Teachers of
                  Kalol City. -M.Ed.-Dissertation.
                </p>
              </li>
              <li>
                <p>
                  Construction and Effectiveness of Multimedia Package for the
                  selected units of Business Administration of Std.11
                </p>
              </li>
              <li>
                <p>
                  The Effect of Computer Assisted Instruction (CAI) And
                  Traditional Method On The Educational Achievement Of Students
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
