export const programData = [
    {
        id: "02",
        name: "DIPLOMA",
        status: "ACTIVE"
    },
    {
        id: "04",
        name: "UG",
        status: "ACTIVE"
    },
    {
        id: "05",
        name: "PG/MASTERS",
        status: "ACTIVE"
    }
]

export const newProgramData = [
    {
        "id": "01",
        "name": "CERTIFICATE",
        "description": null,
        "status": "ACTIVE",
        "createdAt": "2022-09-22T09:22:54.000Z",
        "updatedAt": "2022-09-22T09:22:54.000Z"
    },
    {
        "id": "02",
        "name": "DIPLOMA",
        "description": null,
        "status": "ACTIVE",
        "createdAt": "2022-09-22T09:22:54.000Z",
        "updatedAt": "2022-09-22T09:22:54.000Z"
    },
    {
        "id": "03",
        "name": "PG DIPLOMA",
        "description": null,
        "status": "ACTIVE",
        "createdAt": "2022-09-22T09:22:54.000Z",
        "updatedAt": "2022-09-22T09:22:54.000Z"
    },
    {
        "id": "04",
        "name": "UG",
        "description": null,
        "status": "ACTIVE",
        "createdAt": "2022-09-22T09:22:54.000Z",
        "updatedAt": "2023-01-02T11:50:45.000Z"
    },
    {
        "id": "05",
        "name": "PG/MASTERS",
        "description": null,
        "status": "ACTIVE",
        "createdAt": "2022-09-22T09:22:54.000Z",
        "updatedAt": "2022-09-22T09:22:54.000Z"
    },
    {
        "id": "06",
        "name": "DOCTORATE",
        "description": null,
        "status": "ACTIVE",
        "createdAt": null,
        "updatedAt": null
    }
]


export const facultyData = [
    {
        id: 1111000,
        name: "FACULTY OF ENGINEERING AND TECHNOLOGY",
        college_type_id: "ENG",
        status: "ACTIVE"
    },
    {
        id: 1111001,
        name: "FACULTY OF AYURVEDA",
        college_type_id: "AYU",
        status: "ACTIVE"
    },
    {
        id: 1111002,
        name: "FACULTY OF LAW",
        college_type_id: "LAW",
        status: "ACTIVE"
    }, {
        id: 1111003,
        name: "FACULTY OF NURSING",
        college_type_id: "NUR",
        status: "ACTIVE"
    },
    {
        id: 1111005,
        name: "FACULTY OF COMMERCE AND MANAGEMENT",
        college_type_id: "COM",
        status: "ACTIVE"
    },
    {
        id: 1111010,
        name: "FACULTY OF EDUCATION",
        college_type_id: "EDU",
        status: "ACTIVE"
    },
    {
        id: 1111011,
        name: "FACULTY OF SCIENCE",
        college_type_id: "SCI",
        status: "ACTIVE"
    },
    {
        id: 1111007,
        name: "FACULTY OF HOMEOPATHY",
        college_type_id: "HOMEOPATHY",
        status: "ACTIVE"
    },
    {
        id: 1111008,
        name: "FACULTY OF PHARMACY",
        college_type_id: "PHA",
        status: "ACTIVE"
    },
    {
        id: 1111014,
        name: "FACULTY OF PHYSIOTHERAPY",
        college_type_id: "PHA",
        status: "ACTIVE"
    },
]
export const newFacultyData = [
    {
        "id": 1111000,
        "name": "FACULTY OF ENGINEERING ",
        "location": "kalol-Gandhinagar",
        "phone": "9588803611",
        "email": "deanengg@swaminarayanuniversity.ac.in",
        "college_type_id": "05",
        "code": "ENG",
        "status": "ACTIVE",
        "createdAt": "2022-09-22T12:14:25.000Z",
        "updatedAt": "2022-11-22T04:46:10.000Z"
    },
    {
        "id": 1111001,
        "name": "FACULTY OF AYURVEDA",
        "location": null,
        "phone": null,
        "email": null,
        "college_type_id": "02",
        "code": "AYU",
        "status": "ACTIVE",
        "createdAt": "2022-09-22T12:14:25.000Z",
        "updatedAt": "2022-11-22T04:46:58.000Z"
    },
    {
        "id": 1111002,
        "name": "FACULTY OF LAW",
        "location": null,
        "phone": null,
        "email": null,
        "college_type_id": "08",
        "code": "LAW",
        "status": "ACTIVE",
        "createdAt": "2022-09-22T12:14:25.000Z",
        "updatedAt": "2022-09-22T12:14:25.000Z"
    },
    {
        "id": 1111003,
        "name": "FACULTY OF NURSING",
        "location": null,
        "phone": null,
        "email": null,
        "college_type_id": "10",
        "code": "NUR",
        "status": "ACTIVE",
        "createdAt": "2022-09-22T12:14:25.000Z",
        "updatedAt": "2022-09-22T12:14:25.000Z"
    },
    {
        "id": 1111004,
        "name": "FACULTY OF ARTS AND HUMANITIES",
        "location": null,
        "phone": null,
        "email": null,
        "college_type_id": "01",
        "code": "ART",
        "status": "ACTIVE",
        "createdAt": "2022-09-22T12:14:25.000Z",
        "updatedAt": "2022-09-22T12:14:25.000Z"
    },
    {
        "id": 1111005,
        "name": "FACULTY OF COMMERCE & MANAGEMENT",
        "location": null,
        "phone": null,
        "email": null,
        "college_type_id": "03",
        "code": "COM",
        "status": "ACTIVE",
        "createdAt": "2022-09-22T12:14:25.000Z",
        "updatedAt": "2022-09-24T06:22:48.000Z"
    },
    {
        "id": 1111006,
        "name": "FACULTY OF MEDICINE",
        "location": null,
        "phone": null,
        "email": null,
        "college_type_id": "09",
        "code": "MED",
        "status": "ACTIVE",
        "createdAt": "2022-09-22T12:14:25.000Z",
        "updatedAt": "2023-01-02T11:47:58.000Z"
    },
    {
        "id": 1111007,
        "name": "FACULTY OF HOMOEOPATHY",
        "location": null,
        "phone": null,
        "email": null,
        "college_type_id": "06",
        "code": "HOM",
        "status": "ACTIVE",
        "createdAt": "2022-09-22T12:14:25.000Z",
        "updatedAt": "2022-09-22T12:14:25.000Z"
    },
    {
        "id": 1111008,
        "name": "FACULTY OF PHARMACY",
        "location": null,
        "phone": "9099063158",
        "email": "deanpharmacy@swaminarayanuniversity.ac.in",
        "college_type_id": "11",
        "code": "PHA",
        "status": "ACTIVE",
        "createdAt": "2022-09-22T12:14:25.000Z",
        "updatedAt": "2023-01-03T10:40:48.000Z"
    },
    {
        "id": 1111009,
        "name": "FACULTY OF IT AND COMPUTER APPLICATIONS",
        "location": null,
        "phone": null,
        "email": null,
        "college_type_id": "07",
        "code": "ITC",
        "status": "ACTIVE",
        "createdAt": "2022-09-22T12:14:25.000Z",
        "updatedAt": "2022-09-24T06:23:03.000Z"
    },
    {
        "id": 1111010,
        "name": "FACULTY OF EDUCATION",
        "location": null,
        "phone": "9879010744",
        "email": "gunjan@swaminarayanuniversity.ac.in",
        "college_type_id": "04",
        "code": "EDU",
        "status": "ACTIVE",
        "createdAt": "2022-09-22T12:14:25.000Z",
        "updatedAt": "2022-10-13T09:23:48.000Z"
    },
    {
        "id": 1111011,
        "name": "FACULTY OF SCIENCE",
        "location": "SWAMINARAYAN UNIVERSITY CAMPUS - KALOL",
        "phone": "9033071787",
        "email": "deanscience@swaminarayanuniversity.ac.in",
        "college_type_id": "12",
        "code": "SCI",
        "status": "ACTIVE",
        "createdAt": "2022-09-22T12:14:25.000Z",
        "updatedAt": "2022-12-27T10:08:11.000Z"
    },
    {
        "id": 1111012,
        "name": "SWAMINARAYAN UNIVERSITY",
        "location": null,
        "phone": null,
        "email": "info@swaminarayanuniversity.ac.in",
        "college_type_id": "13",
        "code": "SUU",
        "status": "ACTIVE",
        "createdAt": "2022-09-22T12:14:25.000Z",
        "updatedAt": "2022-09-24T06:23:51.000Z"
    },
    {
        "id": 1111013,
        "name": "DOCTORAL AND POST DOCTORAL PROGRAMME",
        "location": null,
        "phone": null,
        "email": null,
        "college_type_id": "14",
        "code": "PHD",
        "status": "ACTIVE",
        "createdAt": null,
        "updatedAt": null
    }
]

export const specializationData = [
    {
        id: "AYU_01",
        name: "AYURVEDA",
        program_id: "01",
        specialization_id: "AYU",  //collegeType Id
        status: "ACTIVE"
    },
    {
        id: "NUR_02",
        name: "NURSING",
        program_id: "02",
        specialization_id: "NUR",
        status: "ACTIVE"
    },
    {
        id: "ENG_02",
        name: "ENGINEERING",
        program_id: "02",
        specialization_id: "ENG",
        status: "ACTIVE"
    },
    {
        id: "LAW_03",
        name: "LAW",
        program_id: "03",
        specialization_id: "LAW",
        status: "ACTIVE"
    },
    {
        id: "AYU_04",
        name: "AYURVEDA",
        program_id: "04",
        specialization_id: "AYU",
        status: "ACTIVE"
    },
    {
        id: "EDU_04",
        name: "EDUCATION",
        program_id: "04",
        specialization_id: "EDU",
        status: "ACTIVE"
    },
    {
        id: "HOM_04",
        name: "HOMOEOPATHY",
        program_id: "04",
        specialization_id: "HOM",
        status: "ACTIVE"
    },
    {
        id: "NUR_04",
        name: "NURSING",
        program_id: "04",
        specialization_id: "NUR",
        status: "ACTIVE"
    },
    {
        id: "PHA_04",
        name: "PHARMACY",
        program_id: "04",
        specialization_id: "PHA",
        status: "ACTIVE"
    },
    {
        id: "LAW_04",
        name: "LAW",
        program_id: "04",
        specialization_id: "LAW",

        status: "ACTIVE"
    },
    {
        id: "SCI_04",
        name: "SCIENCE",
        program_id: "04",
        specialization_id: "SCI",

        status: "ACTIVE"
    },
    {
        id: "ENG_04",
        name: "ENGINEERING",
        program_id: "04",
        specialization_id: "ENG",

        status: "ACTIVE"
    },
    {
        id: "COM_04",
        name: "COMMERCE",
        program_id: "04",
        specialization_id: "COM",

        status: "ACTIVE"
    },
    {
        id: "ART_04",
        name: "ARTS",
        program_id: "04",
        specialization_id: "ART",

        status: "ACTIVE"
    },
    {
        id: "MGT_04",
        name: "MANAGEMENT",
        program_id: "04",
        specialization_id: "MGT",

        status: "ACTIVE"
    },
    {
        id: "ITC_04",
        name: "IT AND COMPUTER",
        program_id: "04",
        specialization_id: "ITC",

        status: "ACTIVE"
    },

    {
        id: "AYU-05",
        name: "AYURVEDA",
        program_id: "05",
        specialization_id: "AYU",

        status: "ACTIVE"
    },
    {
        id: "NUR_05",
        name: "NURSING",
        program_id: "05",
        specialization_id: "NUR",

        status: "ACTIVE"
    },
    {
        id: "PHA_05",
        name: "PHARMACY",
        program_id: "05",
        specialization_id: "PHA",

        status: "ACTIVE"
    },
    {
        id: "LAW_05",
        name: "LAW",
        program_id: "05",
        specialization_id: "LAW",
        status: "ACTIVE"
    },
    {
        id: "SCI_05",
        name: "SCIENCE",
        program_id: "05",
        specialization_id: "SCI",

        status: "ACTIVE"
    },
    {
        id: "ENG_05",
        name: "ENGINEERING",
        program_id: "05",
        specialization_id: "ENG",

        status: "ACTIVE"
    },
    {
        id: "COM_05",
        name: "COMMERCE",
        program_id: "05",
        specialization_id: "COM",

        status: "ACTIVE"
    },
    {
        id: "ART_05",
        name: "ARTS",
        program_id: "05",
        specialization_id: "ART",

        status: "ACTIVE"
    },
    {
        id: "MGT_05",
        name: "MANAGEMENT",
        program_id: "05",
        specialization_id: "MGT",

        status: "ACTIVE"
    },
    {
        id: "ITC_05",
        name: "IT AND COMPUTER",
        program_id: "05",
        specialization_id: "ITC",

        status: "ACTIVE"
    },

    {
        id: "AYU_05",
        name: "AYURVEDA",
        program_id: "06",
        specialization_id: "AYU",

        status: "ACTIVE"
    },
    {
        id: "EDU_05",
        name: "EDUCATION",
        program_id: "06",
        specialization_id: "EDU",

        status: "ACTIVE"
    },
    {
        id: "HOM_06",
        name: "HOMOEOPATHY",
        program_id: "06",
        specialization_id: "HOM",

        status: "ACTIVE"
    },
    {
        id: "LAW_06",
        name: "LAW",
        program_id: "06",
        specialization_id: "LAW",

        status: "ACTIVE"
    },
    {
        id: "PHA_06",
        name: "PHARMACY",
        program_id: "06",
        specialization_id: "PHA",
        status: "ACTIVE"
    },
    {
        id: "ENG_06",
        name: "ENGINEERING",
        specialization_id: "ENG",
        program_id: "06",
        status: "ACTIVE"
    },
    {
        id: "SCI_06",
        name: "SCIENCE",
        program_id: "06",
        specialization_id: "SCI",

        status: "ACTIVE"
    },
    {
        id: "ART_06",
        name: "ARTS",
        specialization_id: "ART",

        program_id: "05",
        status: "ACTIVE"
    },
    {
        id: "HOMEO_04",
        name: "HOMEO",
        specialization_id: "HOMEOPATHY",

        program_id: "04",
        status: "ACTIVE"
    },
    {
        id: "PHYSIO_01",
        name: "PHYSIO",
        specialization_id: "PHYSIOLOGY",
        program_id: "04",
        status: "ACTIVE"
    },
]


export const departmentData = [
    {
        "id": 1,
        "name": "Automobile Engineering - DEGREE",
        "college_id": 1111000,
        "program_id": "04",
        "department_id": "AE",
        "description": null,
        "status": "ACTIVE",
        "createdAt": "2022-09-22T12:14:25.000Z",
        "updatedAt": "2022-11-10T13:17:59.000Z"
    },
    {
        "id": 2,
        "name": "Mechanical Engineering - DEGREE",
        "college_id": 1111000,
        "program_id": "04",
        "department_id": "ME",
        "description": null,
        "status": "ACTIVE",
        "createdAt": "2022-09-22T12:14:25.000Z",
        "updatedAt": "2022-11-10T13:17:51.000Z"
    },
    {
        "id": 3,
        "name": "Electrical Engineering - DEGREE",
        "college_id": 1111000,
        "program_id": "04",
        "department_id": "EE",
        "description": null,
        "status": "ACTIVE",
        "createdAt": "2022-09-22T12:14:25.000Z",
        "updatedAt": "2022-11-10T13:18:54.000Z"
    },
    {
        "id": 4,
        "name": "Computer Engineering - DEGREE",
        "college_id": 1111000,
        "program_id": "04",
        "department_id": "CE",
        "description": null,
        "status": "ACTIVE",
        "createdAt": "2022-09-22T12:14:25.000Z",
        "updatedAt": "2022-11-10T13:18:23.000Z"
    },
    {
        "id": 6,
        "name": "Civil Engineering - DEGREE",
        "college_id": 1111000,
        "program_id": "04",
        "department_id": "CV",
        "description": null,
        "status": "ACTIVE",
        "createdAt": "2022-09-22T12:14:25.000Z",
        "updatedAt": "2022-11-10T13:19:13.000Z"
    },
    {
        "id": 7,
        "name": "Automobile Engineering - DIPLOMA",
        "college_id": 1111000,
        "program_id": "02",
        "department_id": "AE",
        "description": null,
        "status": "ACTIVE",
        "createdAt": "2022-09-22T12:14:25.000Z",
        "updatedAt": "2022-11-10T13:19:21.000Z"
    },
    {
        "id": 8,
        "name": "Mechanical Engineering - DIPLOMA",
        "college_id": 1111000,
        "program_id": "02",
        "department_id": "ME",
        "description": null,
        "status": "ACTIVE",
        "createdAt": "2022-09-22T12:14:25.000Z",
        "updatedAt": "2022-11-10T13:19:38.000Z"
    },
    {
        "id": 9,
        "name": "Electrical Engineering - DIPLOMA",
        "college_id": 1111000,
        "program_id": "02",
        "department_id": "EE",
        "description": null,
        "status": "ACTIVE",
        "createdAt": "2022-09-22T12:14:25.000Z",
        "updatedAt": "2022-11-10T13:19:45.000Z"
    },
    {
        "id": 10,
        "name": "Computer Engineering - DIPLOMA",
        "college_id": 1111000,
        "program_id": "02",
        "department_id": "CE",
        "description": null,
        "status": "ACTIVE",
        "createdAt": "2022-09-22T12:14:25.000Z",
        "updatedAt": "2022-11-10T13:18:30.000Z"
    },
    {
        "id": 11,
        "name": "Information Technology - DIPLOMA",
        "college_id": 1111000,
        "program_id": "02",
        "department_id": "IT",
        "description": null,
        "status": "ACTIVE",
        "createdAt": "2022-09-22T12:14:25.000Z",
        "updatedAt": "2022-11-10T13:19:09.000Z"
    },
    {
        "id": 12,
        "name": "Civil Engineering - DIPLOMA",
        "college_id": 1111000,
        "program_id": "02",
        "department_id": "CV",
        "description": null,
        "status": "ACTIVE",
        "createdAt": "2022-09-22T12:14:25.000Z",
        "updatedAt": "2022-11-10T13:18:45.000Z"
    },
    {
        "id": 14,
        "name": "M.E. Mechanical (CAD/CAM)",
        "college_id": 1111000,
        "program_id": "05",
        "department_id": "ME",
        "description": null,
        "status": "ACTIVE",
        "createdAt": "2022-09-22T12:14:25.000Z",
        "updatedAt": "2022-12-17T11:20:02.000Z"
    },
    {
        "id": 15,
        "name": "M.E. Electrical",
        "college_id": 1111000,
        "program_id": "05",
        "department_id": "EE",
        "description": null,
        "status": "ACTIVE",
        "createdAt": "2022-09-22T12:14:25.000Z",
        "updatedAt": "2022-12-17T11:20:41.000Z"
    },
    {
        "id": 16,
        "name": "M.E. Computer",
        "college_id": 1111000,
        "program_id": "05",
        "department_id": "CE",
        "description": null,
        "status": "ACTIVE",
        "createdAt": "2022-09-22T12:14:25.000Z",
        "updatedAt": "2022-12-17T11:20:26.000Z"
    },
    {
        "id": 20,
        "name": "BAMS",
        "college_id": 1111001,
        "program_id": "04",
        "department_id": "AY",
        "description": null,
        "status": "ACTIVE",
        "createdAt": "2022-09-22T12:14:25.000Z",
        "updatedAt": "2022-09-22T12:14:25.000Z"
    },
    {
        "id": 21,
        "name": "MD (RN)",
        "college_id": 1111001,
        "program_id": "05",
        "department_id": "RN",
        "description": null,
        "status": "ACTIVE",
        "createdAt": "2022-09-22T12:14:25.000Z",
        "updatedAt": "2022-09-22T12:14:25.000Z"
    },
    {
        "id": 22,
        "name": "MD (SAMHITA)",
        "college_id": 1111001,
        "program_id": "05",
        "department_id": "SA",
        "description": null,
        "status": "ACTIVE",
        "createdAt": "2022-09-22T12:14:25.000Z",
        "updatedAt": "2022-09-22T12:14:25.000Z"
    },
    {
        "id": 23,
        "name": "MD (KC)",
        "college_id": 1111001,
        "program_id": "05",
        "department_id": "KC",
        "description": null,
        "status": "ACTIVE",
        "createdAt": "2022-09-22T12:14:25.000Z",
        "updatedAt": "2022-09-22T12:14:25.000Z"
    },
    {
        "id": 24,
        "name": "MS (SHALYA)",
        "college_id": 1111001,
        "program_id": "05",
        "department_id": "SH",
        "description": null,
        "status": "ACTIVE",
        "createdAt": "2022-09-22T12:14:25.000Z",
        "updatedAt": "2022-09-22T12:14:25.000Z"
    },
    {
        "id": 25,
        "name": "MS ( PTSR)",
        "college_id": 1111001,
        "program_id": "05",
        "department_id": "PS",
        "description": null,
        "status": "ACTIVE",
        "createdAt": "2022-09-22T12:14:25.000Z",
        "updatedAt": "2022-09-22T12:14:25.000Z"
    },
    {
        "id": 27,
        "name": "LLB  ( 3 Years )",
        "college_id": 1111002,
        "program_id": "04",
        "department_id": "LB",
        "description": null,
        "status": "ACTIVE",
        "createdAt": "2022-09-22T12:14:25.000Z",
        "updatedAt": "2022-10-18T03:44:28.000Z"
    },
    {
        "id": 28,
        "name": "PG Diploma ( Labour practice )",
        "college_id": 1111002,
        "program_id": "03",
        "department_id": "LP",
        "description": null,
        "status": "ACTIVE",
        "createdAt": "2022-09-22T12:14:25.000Z",
        "updatedAt": "2022-10-18T03:46:28.000Z"
    },
    {
        "id": 29,
        "name": "PG Diploma ( Banking Law )",
        "college_id": 1111002,
        "program_id": "03",
        "department_id": "BL",
        "description": null,
        "status": "ACTIVE",
        "createdAt": "2022-09-22T12:14:25.000Z",
        "updatedAt": "2022-10-18T03:47:06.000Z"
    },
    {
        "id": 30,
        "name": "PG Diploma ( Tax Practice )",
        "college_id": 1111002,
        "program_id": "03",
        "department_id": "TP",
        "description": null,
        "status": "ACTIVE",
        "createdAt": "2022-09-22T12:14:25.000Z",
        "updatedAt": "2022-10-18T03:47:31.000Z"
    },
    {
        "id": 31,
        "name": "LL.M ( Business Law )",
        "college_id": 1111002,
        "program_id": "05",
        "department_id": "BU",
        "description": null,
        "status": "ACTIVE",
        "createdAt": "2022-09-22T12:14:25.000Z",
        "updatedAt": "2022-10-18T03:45:55.000Z"
    },
    {
        "id": 32,
        "name": "LL.M ( Criminal Law )",
        "college_id": 1111002,
        "program_id": "05",
        "department_id": "CL",
        "description": null,
        "status": "ACTIVE",
        "createdAt": "2022-09-22T12:14:25.000Z",
        "updatedAt": "2022-10-18T03:45:36.000Z"
    },
    {
        "id": 33,
        "name": "LL.M  ( Marine Time Law )",
        "college_id": 1111002,
        "program_id": "05",
        "department_id": "MT",
        "description": null,
        "status": "ACTIVE",
        "createdAt": "2022-09-22T12:14:25.000Z",
        "updatedAt": "2022-10-18T03:45:13.000Z"
    },
    {
        "id": 35,
        "name": "B.Sc. Nursing",
        "college_id": 1111003,
        "program_id": "04",
        "department_id": "NU",
        "description": null,
        "status": "ACTIVE",
        "createdAt": "2022-09-22T12:14:25.000Z",
        "updatedAt": "2022-09-22T12:14:25.000Z"
    },
    {
        "id": 36,
        "name": "Post Basic B.Sc. Nursing",
        "college_id": 1111003,
        "program_id": "04",
        "department_id": "PN",
        "description": null,
        "status": "ACTIVE",
        "createdAt": "2022-09-22T12:14:25.000Z",
        "updatedAt": "2022-09-22T12:14:25.000Z"
    },
    {
        "id": 37,
        "name": "GNM Nursing",
        "college_id": 1111003,
        "program_id": "02",
        "department_id": "GN",
        "description": null,
        "status": "ACTIVE",
        "createdAt": "2022-09-22T12:14:25.000Z",
        "updatedAt": "2022-09-22T12:14:25.000Z"
    },
    {
        "id": 38,
        "name": "ANM",
        "college_id": 1111003,
        "program_id": "02",
        "department_id": "AN",
        "description": null,
        "status": "ACTIVE",
        "createdAt": "2022-09-22T12:14:25.000Z",
        "updatedAt": "2022-09-22T12:14:25.000Z"
    },
    {
        "id": 39,
        "name": "M.Sc. Nursing",
        "college_id": 1111003,
        "program_id": "05",
        "department_id": "MN",
        "description": null,
        "status": "ACTIVE",
        "createdAt": "2022-09-22T12:14:25.000Z",
        "updatedAt": "2022-09-22T12:14:25.000Z"
    },
    {
        "id": 42,
        "name": "B.Com",
        "college_id": 1111005,
        "program_id": "04",
        "department_id": "BC",
        "description": null,
        "status": "ACTIVE",
        "createdAt": "2022-09-22T12:14:25.000Z",
        "updatedAt": "2022-09-22T12:14:25.000Z"
    },
    {
        "id": 43,
        "name": "M.Com",
        "college_id": 1111005,
        "program_id": "05",
        "department_id": "MC",
        "description": null,
        "status": "ACTIVE",
        "createdAt": "2022-09-22T12:14:25.000Z",
        "updatedAt": "2022-09-22T12:14:25.000Z"
    },
    {
        "id": 44,
        "name": "MBBS",
        "college_id": 1111006,
        "program_id": "04",
        "department_id": "BB",
        "description": null,
        "status": "ACTIVE",
        "createdAt": "2022-09-22T12:14:25.000Z",
        "updatedAt": "2023-01-02T11:51:41.000Z"
    },
    {
        "id": 45,
        "name": "MBA- Hospitality",
        "college_id": 1111006,
        "program_id": "04",
        "department_id": "HS",
        "description": null,
        "status": "ACTIVE",
        "createdAt": "2022-09-22T12:14:25.000Z",
        "updatedAt": "2022-09-22T12:14:25.000Z"
    },
    {
        "id": 46,
        "name": "MBA- Finance",
        "college_id": 1111006,
        "program_id": "04",
        "department_id": "FI",
        "description": null,
        "status": "ACTIVE",
        "createdAt": "2022-09-22T12:14:25.000Z",
        "updatedAt": "2022-09-22T12:14:25.000Z"
    },
    {
        "id": 47,
        "name": "MBA- Marketing",
        "college_id": 1111006,
        "program_id": "04",
        "department_id": "MR",
        "description": null,
        "status": "ACTIVE",
        "createdAt": "2022-09-22T12:14:25.000Z",
        "updatedAt": "2022-09-22T12:14:25.000Z"
    },
    {
        "id": 48,
        "name": "MBA- HR",
        "college_id": 1111006,
        "program_id": "05",
        "department_id": "HR",
        "description": null,
        "status": "ACTIVE",
        "createdAt": "2022-09-22T12:14:25.000Z",
        "updatedAt": "2022-09-22T12:14:25.000Z"
    },
    {
        "id": 50,
        "name": "B.H.M.S.",
        "college_id": 1111007,
        "program_id": "04",
        "department_id": "HO",
        "description": null,
        "status": "ACTIVE",
        "createdAt": "2022-09-22T12:14:25.000Z",
        "updatedAt": "2022-09-22T12:14:25.000Z"
    },
    {
        "id": 52,
        "name": "Pharm D",
        "college_id": 1111008,
        "program_id": "05",
        "department_id": "DP",
        "description": null,
        "status": "ACTIVE",
        "createdAt": "2022-09-22T12:14:25.000Z",
        "updatedAt": "2022-09-22T12:14:25.000Z"
    },
    {
        "id": 53,
        "name": "B.Pharm",
        "college_id": 1111008,
        "program_id": "04",
        "department_id": "BP",
        "description": null,
        "status": "ACTIVE",
        "createdAt": "2022-09-22T12:14:25.000Z",
        "updatedAt": "2022-09-22T12:14:25.000Z"
    },
    {
        "id": 54,
        "name": "M.Pharm (Pharmaceutics)",
        "college_id": 1111008,
        "program_id": "05",
        "department_id": "MP",
        "description": null,
        "status": "ACTIVE",
        "createdAt": "2022-09-22T12:14:25.000Z",
        "updatedAt": "2022-09-22T12:14:25.000Z"
    },
    {
        "id": 55,
        "name": "M.Pharm (Pharmaceutical Quality Assurance)",
        "college_id": 1111008,
        "program_id": "05",
        "department_id": "MQ",
        "description": null,
        "status": "ACTIVE",
        "createdAt": "2022-09-22T12:14:25.000Z",
        "updatedAt": "2022-09-22T12:14:25.000Z"
    },
    {
        "id": 57,
        "name": "BCA",
        "college_id": 1111009,
        "program_id": "04",
        "department_id": "CA",
        "description": null,
        "status": "ACTIVE",
        "createdAt": "2022-09-22T12:14:25.000Z",
        "updatedAt": "2022-09-22T12:14:25.000Z"
    },
    {
        "id": 58,
        "name": "MCA",
        "college_id": 1111009,
        "program_id": "05",
        "department_id": "CA",
        "description": null,
        "status": "ACTIVE",
        "createdAt": "2022-09-22T12:14:25.000Z",
        "updatedAt": "2022-09-22T12:14:25.000Z"
    },
    {
        "id": 59,
        "name": "M.Sc.IT",
        "college_id": 1111009,
        "program_id": "05",
        "department_id": "MI",
        "description": null,
        "status": "ACTIVE",
        "createdAt": "2022-09-22T12:14:25.000Z",
        "updatedAt": "2022-09-22T12:14:25.000Z"
    },
    {
        "id": 60,
        "name": "B.Ed.",
        "college_id": 1111010,
        "program_id": "04",
        "department_id": "ED",
        "description": null,
        "status": "ACTIVE",
        "createdAt": "2022-09-22T12:14:25.000Z",
        "updatedAt": "2022-09-22T12:14:25.000Z"
    },
    {
        "id": 69,
        "name": "Department of Chemistry (PG/Masters)",
        "college_id": 1111011,
        "program_id": "05",
        "department_id": "CH",
        "description": null,
        "status": "ACTIVE",
        "createdAt": "2022-09-22T12:14:25.000Z",
        "updatedAt": "2023-01-02T10:16:13.000Z"
    },
    {
        "id": 70,
        "name": "Department of Microbiology (PG/Masters)",
        "college_id": 1111011,
        "program_id": "05",
        "department_id": "MB",
        "description": null,
        "status": "ACTIVE",
        "createdAt": "2022-09-22T12:14:25.000Z",
        "updatedAt": "2023-01-02T10:16:34.000Z"
    },
    {
        "id": 71,
        "name": "Department of Botany (PG/Masters)",
        "college_id": 1111011,
        "program_id": "05",
        "department_id": "BO",
        "description": null,
        "status": "ACTIVE",
        "createdAt": "2022-09-22T12:14:25.000Z",
        "updatedAt": "2023-01-02T10:16:30.000Z"
    },
    {
        "id": 72,
        "name": "Department of Zoology (PG/Masters)",
        "college_id": 1111011,
        "program_id": "05",
        "department_id": "ZO",
        "description": null,
        "status": "ACTIVE",
        "createdAt": "2022-09-22T12:14:25.000Z",
        "updatedAt": "2023-01-02T10:16:27.000Z"
    },
    {
        "id": 73,
        "name": "Department of Mathematics (PG/Masters)",
        "college_id": 1111011,
        "program_id": "05",
        "department_id": "MA",
        "description": null,
        "status": "ACTIVE",
        "createdAt": "2022-09-22T12:14:25.000Z",
        "updatedAt": "2023-01-02T10:16:23.000Z"
    },
    {
        "id": 74,
        "name": "Department of Biotechnology (PG/Masters)",
        "college_id": 1111011,
        "program_id": "05",
        "department_id": "BT",
        "description": null,
        "status": "ACTIVE",
        "createdAt": "2022-09-22T12:14:25.000Z",
        "updatedAt": "2023-01-02T10:16:20.000Z"
    },
    {
        "id": 75,
        "name": "Department of Physics (PG/Masters)",
        "college_id": 1111011,
        "program_id": "05",
        "department_id": "PH",
        "description": null,
        "status": "ACTIVE",
        "createdAt": "2022-09-22T12:14:25.000Z",
        "updatedAt": "2023-01-02T10:16:16.000Z"
    },
    {
        "id": 78,
        "name": "Diploma in Vedic Astro ",
        "college_id": 1111012,
        "program_id": "02",
        "department_id": "VA",
        "description": null,
        "status": "ACTIVE",
        "createdAt": "2022-09-22T12:14:25.000Z",
        "updatedAt": "2022-09-22T12:14:25.000Z"
    },
    {
        "id": 79,
        "name": "Sanskrit Samhita & Siddhanta",
        "college_id": 1111001,
        "program_id": "04",
        "department_id": "SSS",
        "description": "",
        "status": "ACTIVE",
        "createdAt": "2022-09-23T10:26:39.000Z",
        "updatedAt": "2022-12-01T14:28:16.000Z"
    },
    {
        "id": 80,
        "name": "Administration",
        "college_id": 1111012,
        "program_id": "04",
        "department_id": "Admin",
        "description": "",
        "status": "ACTIVE",
        "createdAt": "2022-09-24T06:26:32.000Z",
        "updatedAt": "2022-09-24T06:26:32.000Z"
    },
    {
        "id": 81,
        "name": "BBA, UG",
        "college_id": 1111005,
        "program_id": "04",
        "department_id": "BBA_UG",
        "description": "",
        "status": "ACTIVE",
        "createdAt": "2022-10-15T10:19:25.000Z",
        "updatedAt": "2022-10-15T10:19:25.000Z"
    },
    {
        "id": 82,
        "name": "MBA, PG/MASTERS",
        "college_id": 1111005,
        "program_id": "05",
        "department_id": "MBA_PGM",
        "description": "",
        "status": "ACTIVE",
        "createdAt": "2022-10-15T10:19:57.000Z",
        "updatedAt": "2022-10-15T10:19:57.000Z"
    },
    {
        "id": 83,
        "name": "BSc Zoology (UG)",
        "college_id": 1111011,
        "program_id": "04",
        "department_id": "CH",
        "description": "",
        "status": "ACTIVE",
        "createdAt": "2022-10-16T14:14:22.000Z",
        "updatedAt": "2023-01-07T04:19:28.000Z"
    },
    {
        "id": 84,
        "name": "BSc Chemistry (UG)",
        "college_id": 1111011,
        "program_id": "04",
        "department_id": "MATH",
        "description": "",
        "status": "ACTIVE",
        "createdAt": "2022-10-16T14:15:13.000Z",
        "updatedAt": "2023-01-07T04:19:06.000Z"
    },
    {
        "id": 86,
        "name": "BSc Biotechnology (UG)",
        "college_id": 1111011,
        "program_id": "04",
        "department_id": "BOT",
        "description": "",
        "status": "ACTIVE",
        "createdAt": "2022-10-16T14:17:04.000Z",
        "updatedAt": "2023-01-07T04:19:24.000Z"
    },
    {
        "id": 87,
        "name": "BSc Botany (UG)",
        "college_id": 1111011,
        "program_id": "04",
        "department_id": "MIC",
        "description": "",
        "status": "ACTIVE",
        "createdAt": "2022-10-16T14:17:39.000Z",
        "updatedAt": "2023-01-07T04:19:19.000Z"
    },
    {
        "id": 88,
        "name": "BSc Physics (UG)",
        "college_id": 1111011,
        "program_id": "04",
        "department_id": "ZOO",
        "description": "",
        "status": "ACTIVE",
        "createdAt": "2022-10-16T14:18:18.000Z",
        "updatedAt": "2023-01-07T04:19:14.000Z"
    },
    {
        "id": 89,
        "name": "BSc Maths (UG)",
        "college_id": 1111011,
        "program_id": "04",
        "department_id": "BIO",
        "description": "",
        "status": "ACTIVE",
        "createdAt": "2022-10-16T14:18:43.000Z",
        "updatedAt": "2023-01-07T04:19:33.000Z"
    },
    {
        "id": 90,
        "name": "AYURVEDA - PH.D",
        "college_id": 1111013,
        "program_id": "06",
        "department_id": "AYUDR",
        "description": "",
        "status": "ACTIVE",
        "createdAt": "2022-10-18T14:50:41.000Z",
        "updatedAt": "2022-10-18T14:50:41.000Z"
    },
    {
        "id": 91,
        "name": "EDUCATION - PH.D",
        "college_id": 1111013,
        "program_id": "06",
        "department_id": "EDUDR",
        "description": "",
        "status": "ACTIVE",
        "createdAt": "2022-10-18T14:51:52.000Z",
        "updatedAt": "2022-10-18T14:51:52.000Z"
    },
    {
        "id": 92,
        "name": "HOMOEOPATHY - PH.D",
        "college_id": 1111013,
        "program_id": "06",
        "department_id": "HOMODR",
        "description": "",
        "status": "ACTIVE",
        "createdAt": "2022-10-18T14:52:16.000Z",
        "updatedAt": "2022-10-18T14:52:16.000Z"
    },
    {
        "id": 93,
        "name": "PHARMACY - PH.D",
        "college_id": 1111013,
        "program_id": "06",
        "department_id": "PHARMADR",
        "description": "",
        "status": "ACTIVE",
        "createdAt": "2022-10-18T14:52:49.000Z",
        "updatedAt": "2022-10-18T14:52:49.000Z"
    },
    {
        "id": 94,
        "name": "LAW - PH.D",
        "college_id": 1111013,
        "program_id": "06",
        "department_id": "LAWDR",
        "description": "",
        "status": "ACTIVE",
        "createdAt": "2022-10-18T14:53:09.000Z",
        "updatedAt": "2022-10-18T14:53:09.000Z"
    },
    {
        "id": 95,
        "name": "SCIENCE - PH.D",
        "college_id": 1111013,
        "program_id": "06",
        "department_id": "SCIDR",
        "description": "CHEMISTRY, PHYSICS, MICROBIOLOGY, MATHEMATICS, BIOTECHNOLOGY, ZOOLOGY, BOTANY",
        "status": "ACTIVE",
        "createdAt": "2022-10-18T14:53:42.000Z",
        "updatedAt": "2022-12-31T18:24:11.000Z"
    },
    {
        "id": 96,
        "name": "ENGINEERING - PH.D",
        "college_id": 1111013,
        "program_id": "06",
        "department_id": "ENGGDR",
        "description": "",
        "status": "ACTIVE",
        "createdAt": "2022-10-18T14:54:19.000Z",
        "updatedAt": "2022-10-18T14:54:19.000Z"
    },
    {
        "id": 97,
        "name": "COMMERCE - PH.D",
        "college_id": 1111013,
        "program_id": "06",
        "department_id": "COMMDR",
        "description": "",
        "status": "ACTIVE",
        "createdAt": "2022-10-18T14:54:48.000Z",
        "updatedAt": "2022-10-18T14:54:48.000Z"
    },
    {
        "id": 98,
        "name": "ARTS & HUMANITIES - PH.D",
        "college_id": 1111013,
        "program_id": "06",
        "department_id": "ARTSHUMDR",
        "description": "",
        "status": "ACTIVE",
        "createdAt": "2022-10-18T14:55:21.000Z",
        "updatedAt": "2022-10-18T14:55:21.000Z"
    },
    {
        "id": 99,
        "name": "IT AND COMPUTER SCIENCE - PH.D",
        "college_id": 1111013,
        "program_id": "06",
        "department_id": "ITCSDR",
        "description": "",
        "status": "ACTIVE",
        "createdAt": "2022-10-18T14:55:45.000Z",
        "updatedAt": "2022-10-18T14:55:45.000Z"
    },
    {
        "id": 100,
        "name": "M.Sc.  Pharmaceutical Analytical Chemistry",
        "college_id": 1111008,
        "program_id": "05",
        "department_id": "MSCCHEM",
        "description": "",
        "status": "ACTIVE",
        "createdAt": "2022-11-08T16:31:59.000Z",
        "updatedAt": "2022-11-08T16:31:59.000Z"
    },
    {
        "id": 101,
        "name": "Swasthavritta",
        "college_id": 1111001,
        "program_id": "04",
        "department_id": "SW",
        "description": "",
        "status": "ACTIVE",
        "createdAt": "2022-11-12T11:59:56.000Z",
        "updatedAt": "2022-11-12T11:59:56.000Z"
    },
    {
        "id": 102,
        "name": "Biomedical Engineering",
        "college_id": 1111006,
        "program_id": "04",
        "department_id": "BME",
        "description": "",
        "status": "ACTIVE",
        "createdAt": "2022-11-14T10:50:12.000Z",
        "updatedAt": "2022-11-14T10:50:12.000Z"
    },
    {
        "id": 103,
        "name": "Community Medicine",
        "college_id": 1111006,
        "program_id": "04",
        "department_id": "CM",
        "description": "",
        "status": "ACTIVE",
        "createdAt": "2022-11-16T11:28:01.000Z",
        "updatedAt": "2022-11-16T11:28:01.000Z"
    },
    {
        "id": 104,
        "name": "Dravyaguna",
        "college_id": 1111001,
        "program_id": "04",
        "department_id": "DG",
        "description": "",
        "status": "ACTIVE",
        "createdAt": "2022-11-25T09:47:26.000Z",
        "updatedAt": "2022-11-25T09:47:26.000Z"
    },
    {
        "id": 105,
        "name": "Rasashastra and Bhaishajya Kalpana",
        "college_id": 1111001,
        "program_id": "04",
        "department_id": "RSBK",
        "description": "",
        "status": "ACTIVE",
        "createdAt": "2022-12-01T14:24:29.000Z",
        "updatedAt": "2022-12-01T14:24:29.000Z"
    },
    {
        "id": 106,
        "name": "Rachana Sharir",
        "college_id": 1111001,
        "program_id": "04",
        "department_id": "RS",
        "description": "",
        "status": "ACTIVE",
        "createdAt": "2022-12-01T14:25:30.000Z",
        "updatedAt": "2022-12-01T14:25:30.000Z"
    },
    {
        "id": 107,
        "name": "Kriya Sharir",
        "college_id": 1111001,
        "program_id": "04",
        "department_id": "KS",
        "description": "",
        "status": "ACTIVE",
        "createdAt": "2022-12-01T14:26:16.000Z",
        "updatedAt": "2022-12-01T14:26:16.000Z"
    },
    {
        "id": 108,
        "name": "Roganidan",
        "college_id": 1111001,
        "program_id": "04",
        "department_id": "RN",
        "description": "",
        "status": "ACTIVE",
        "createdAt": "2022-12-01T14:26:54.000Z",
        "updatedAt": "2022-12-01T14:26:54.000Z"
    },
    {
        "id": 109,
        "name": "Agada Tantra",
        "college_id": 1111001,
        "program_id": "04",
        "department_id": "AT",
        "description": "",
        "status": "ACTIVE",
        "createdAt": "2022-12-01T14:28:46.000Z",
        "updatedAt": "2022-12-01T14:28:46.000Z"
    },
    {
        "id": 110,
        "name": "Streeroga and Prasuti Tantra",
        "college_id": 1111001,
        "program_id": "04",
        "department_id": "SRPT",
        "description": "",
        "status": "ACTIVE",
        "createdAt": "2022-12-01T14:29:27.000Z",
        "updatedAt": "2022-12-01T14:29:27.000Z"
    },
    {
        "id": 111,
        "name": "Balroga",
        "college_id": 1111001,
        "program_id": "04",
        "department_id": "BR",
        "description": "",
        "status": "ACTIVE",
        "createdAt": "2022-12-01T14:30:11.000Z",
        "updatedAt": "2022-12-01T14:30:11.000Z"
    },
    {
        "id": 112,
        "name": "Panchakarma",
        "college_id": 1111001,
        "program_id": "04",
        "department_id": "PK",
        "description": "",
        "status": "ACTIVE",
        "createdAt": "2022-12-01T14:32:07.000Z",
        "updatedAt": "2022-12-01T14:32:07.000Z"
    },
    {
        "id": 113,
        "name": "Kayachikitsa",
        "college_id": 1111001,
        "program_id": "04",
        "department_id": "KC",
        "description": "",
        "status": "ACTIVE",
        "createdAt": "2022-12-01T14:32:29.000Z",
        "updatedAt": "2022-12-01T14:32:29.000Z"
    },
    {
        "id": 114,
        "name": "Shalya Tantra",
        "college_id": 1111001,
        "program_id": "04",
        "department_id": "ST",
        "description": "",
        "status": "ACTIVE",
        "createdAt": "2022-12-01T14:32:49.000Z",
        "updatedAt": "2022-12-01T14:32:49.000Z"
    },
    {
        "id": 115,
        "name": "Shalakya Tantra",
        "college_id": 1111001,
        "program_id": "04",
        "department_id": "ShT",
        "description": "",
        "status": "ACTIVE",
        "createdAt": "2022-12-01T14:33:31.000Z",
        "updatedAt": "2022-12-01T14:33:31.000Z"
    },
    {
        "id": 116,
        "name": "BA",
        "college_id": 1111004,
        "program_id": "04",
        "department_id": "BA_004",
        "description": "",
        "status": "ACTIVE",
        "createdAt": "2022-12-17T06:42:36.000Z",
        "updatedAt": "2022-12-17T06:42:36.000Z"
    },
    {
        "id": 117,
        "name": "MA",
        "college_id": 1111004,
        "program_id": "05",
        "department_id": "MA_004",
        "description": "",
        "status": "ACTIVE",
        "createdAt": "2022-12-17T06:42:54.000Z",
        "updatedAt": "2022-12-17T06:42:54.000Z"
    },
    {
        "id": 118,
        "name": "Anatomy",
        "college_id": 1111006,
        "program_id": "06",
        "department_id": "01",
        "description": "",
        "status": "ACTIVE",
        "createdAt": "2023-01-06T06:51:12.000Z",
        "updatedAt": "2023-01-06T06:51:12.000Z"
    },
    {
        "id": 119,
        "name": "Physiology",
        "college_id": 1111006,
        "program_id": "06",
        "department_id": "02",
        "description": "",
        "status": "ACTIVE",
        "createdAt": "2023-01-06T06:51:38.000Z",
        "updatedAt": "2023-01-06T06:51:38.000Z"
    },
    {
        "id": 120,
        "name": "Biochemistry",
        "college_id": 1111006,
        "program_id": "06",
        "department_id": "03",
        "description": "",
        "status": "ACTIVE",
        "createdAt": "2023-01-06T06:52:05.000Z",
        "updatedAt": "2023-01-06T06:52:05.000Z"
    },
    {
        "id": 121,
        "name": "Forensic Medicine",
        "college_id": 1111006,
        "program_id": "06",
        "department_id": "04",
        "description": "",
        "status": "ACTIVE",
        "createdAt": "2023-01-06T06:53:08.000Z",
        "updatedAt": "2023-01-06T06:53:08.000Z"
    },
    {
        "id": 122,
        "name": "Pharmacology",
        "college_id": 1111006,
        "program_id": "06",
        "department_id": "05",
        "description": "",
        "status": "ACTIVE",
        "createdAt": "2023-01-06T06:53:27.000Z",
        "updatedAt": "2023-01-06T06:53:27.000Z"
    },
    {
        "id": 123,
        "name": "Microbiology",
        "college_id": 1111006,
        "program_id": "04",
        "department_id": "06",
        "description": "",
        "status": "ACTIVE",
        "createdAt": "2023-01-06T06:55:09.000Z",
        "updatedAt": "2023-01-06T06:55:09.000Z"
    },
    {
        "id": 124,
        "name": "General Medicine",
        "college_id": 1111006,
        "program_id": "04",
        "department_id": "7",
        "description": "",
        "status": "ACTIVE",
        "createdAt": "2023-01-06T06:55:33.000Z",
        "updatedAt": "2023-01-06T06:55:33.000Z"
    },
    {
        "id": 125,
        "name": "Pediatrics",
        "college_id": 1111006,
        "program_id": "04",
        "department_id": "8",
        "description": "",
        "status": "ACTIVE",
        "createdAt": "2023-01-06T06:56:14.000Z",
        "updatedAt": "2023-01-06T06:56:14.000Z"
    },
    {
        "id": 126,
        "name": "Tuberculosis & Respiratory Diseases",
        "college_id": 1111006,
        "program_id": "04",
        "department_id": "9",
        "description": "",
        "status": "ACTIVE",
        "createdAt": "2023-01-06T06:56:33.000Z",
        "updatedAt": "2023-01-06T06:56:33.000Z"
    },
    {
        "id": 127,
        "name": "Dermatology, Venereology & Leprosy",
        "college_id": 1111006,
        "program_id": "04",
        "department_id": "10",
        "description": "",
        "status": "ACTIVE",
        "createdAt": "2023-01-06T06:56:52.000Z",
        "updatedAt": "2023-01-06T06:56:52.000Z"
    },
    {
        "id": 128,
        "name": "Pathology",
        "college_id": 1111006,
        "program_id": "04",
        "department_id": "11",
        "description": "",
        "status": "ACTIVE",
        "createdAt": "2023-01-06T06:57:09.000Z",
        "updatedAt": "2023-01-06T06:57:09.000Z"
    },
    {
        "id": 129,
        "name": "Psychiatry",
        "college_id": 1111006,
        "program_id": "04",
        "department_id": "12",
        "description": "",
        "status": "ACTIVE",
        "createdAt": "2023-01-06T06:57:44.000Z",
        "updatedAt": "2023-01-06T06:57:44.000Z"
    },
    {
        "id": 130,
        "name": "General Surgery",
        "college_id": 1111006,
        "program_id": "04",
        "department_id": "13",
        "description": "",
        "status": "ACTIVE",
        "createdAt": "2023-01-06T06:58:03.000Z",
        "updatedAt": "2023-01-06T06:58:03.000Z"
    },
    {
        "id": 131,
        "name": "Orthopaedics",
        "college_id": 1111006,
        "program_id": "04",
        "department_id": "15",
        "description": "",
        "status": "ACTIVE",
        "createdAt": "2023-01-06T06:58:30.000Z",
        "updatedAt": "2023-01-06T06:58:30.000Z"
    },
    {
        "id": 132,
        "name": "Oto-Rhino-Laryngology",
        "college_id": 1111006,
        "program_id": "04",
        "department_id": "16",
        "description": "",
        "status": "ACTIVE",
        "createdAt": "2023-01-06T06:58:47.000Z",
        "updatedAt": "2023-01-06T06:58:47.000Z"
    },
    {
        "id": 133,
        "name": "Ophthalmology",
        "college_id": 1111006,
        "program_id": "04",
        "department_id": "17",
        "description": "",
        "status": "ACTIVE",
        "createdAt": "2023-01-06T06:59:05.000Z",
        "updatedAt": "2023-01-06T06:59:05.000Z"
    },
    {
        "id": 134,
        "name": "Obstetrics & Gynaecology",
        "college_id": 1111006,
        "program_id": "04",
        "department_id": "18",
        "description": "",
        "status": "ACTIVE",
        "createdAt": "2023-01-06T06:59:26.000Z",
        "updatedAt": "2023-01-06T06:59:26.000Z"
    },
    {
        "id": 135,
        "name": "Anesthesiology",
        "college_id": 1111006,
        "program_id": "04",
        "department_id": "19",
        "description": "",
        "status": "ACTIVE",
        "createdAt": "2023-01-06T06:59:51.000Z",
        "updatedAt": "2023-01-06T06:59:51.000Z"
    },
    {
        "id": 136,
        "name": "Radio-Diagnosis",
        "college_id": 1111006,
        "program_id": "04",
        "department_id": "20",
        "description": "",
        "status": "ACTIVE",
        "createdAt": "2023-01-06T07:00:24.000Z",
        "updatedAt": "2023-01-06T07:00:24.000Z"
    },
    {
        "id": 137,
        "name": "Emergency Medicine",
        "college_id": 1111006,
        "program_id": "04",
        "department_id": "21",
        "description": "",
        "status": "ACTIVE",
        "createdAt": "2023-01-06T07:00:46.000Z",
        "updatedAt": "2023-01-06T07:00:46.000Z"
    },
    {
        "id": 138,
        "name": "BSc Microbiology (UG)",
        "college_id": 1111011,
        "program_id": "04",
        "department_id": "MB",
        "description": "",
        "status": "ACTIVE",
        "createdAt": "2023-01-07T03:55:29.000Z",
        "updatedAt": "2023-01-07T04:19:09.000Z"
    },
    {
        "id": 139,
        "name": "MLT",
        "college_id": 1111008,
        "program_id": "01",
        "department_id": "MLT",
        "description": "",
        "status": "ACTIVE",
        "createdAt": "2023-01-12T09:43:48.000Z",
        "updatedAt": "2023-01-12T09:43:48.000Z"
    },
    {
        "id": 140,
        "name": "Physiotherapy",
        "college_id": 1111006,
        "program_id": "04",
        "department_id": "phy",
        "description": "",
        "status": "ACTIVE",
        "createdAt": "2023-01-18T09:52:09.000Z",
        "updatedAt": "2023-01-18T09:52:09.000Z"
    },
    {
        "id": 141,
        "name": "Dentristry",
        "college_id": 1111006,
        "program_id": "04",
        "department_id": "den",
        "description": "",
        "status": "ACTIVE",
        "createdAt": "2023-01-18T10:04:19.000Z",
        "updatedAt": "2023-01-18T10:04:19.000Z"
    }
]
