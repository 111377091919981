export const feeData = [
  {
    faculty: 'FACULTY OF AYURVEDA',
    Program: 'UG',
    Formfee: 100,
    FeeforSemester: 2500,
    college_id: 1111001,
    program_id: '04'
  },
  {
    faculty: 'FACULTY OF AYURVEDA',
    Program: 'PG',
    Formfee: 100,
    FeeforSemester: 2500,
    college_id: 1111001,
    program_id: '05'
  },
  {
    faculty: 'FACULTY OF HOMEOPATHY',
    Program: 'UG',
    Formfee: 100,
    FeeforSemester: 2500,
    college_id: 1111007,
    program_id: '04'
  },
  {
    faculty: 'FACULTY OF HOMEOPATHY',
    Program: 'PG',
    Formfee: 100,
    FeeforSemester: 2500,
    college_id: 1111007,
    program_id: '05'
  },
  {
    faculty: 'FACULTY OF NURSING',
    Program: 'UG',
    Formfee: 100,
    FeeforSemester: 1250,
    college_id: 1111003,
    program_id: '04'
  },
  {
    faculty: 'FACULTY OF NURSING',
    Program: 'PG',
    Formfee: 100,
    FeeforSemester: 1500,
    college_id: 1111003,
    program_id: '05'
  },
  {
    faculty: 'FACULTY OF PHARMACY',
    Program: 'UG',
    Formfee: 100,
    FeeforSemester: 1250,
    college_id: 1111008,
    program_id: '04'
  },
  {
    faculty: 'FACULTY OF PHARMACY',
    Program: 'PG',
    Formfee: 100,
    FeeforSemester: 1500,
    college_id: 1111008,
    program_id: '05'
  },
  {
    faculty: 'FACULTY OF SCIENCE',
    Program: 'UG',
    Formfee: 100,
    FeeforSemester: 1250,
    college_id: 1111011,
    program_id: '04'
  },
  {
    faculty: 'FACULTY OF SCIENCE',
    Program: 'PG',
    Formfee: 100,
    FeeforSemester: 1500,
    college_id: 1111011,
    program_id: '05'
  },
  {
    faculty: 'FACULTY OF LAW',
    Program: 'UG',
    Formfee: 100,
    FeeforSemester: 1250,
    college_id: 1111002,
    program_id: '04'
  },
  {
    faculty: 'FACULTY OF LAW',
    Program: 'PG',
    Formfee: 100,
    FeeforSemester: 1500,
    college_id: 1111002,
    program_id: '05'
  },
  {
    faculty: 'FACULTY OF ENGINEERING',
    Program: 'Diploma',
    Formfee: 100,
    FeeforSemester: 1250,
    college_id: 1111000,
    program_id: '02'
  },
  {
    faculty: 'FACULTY OF ENGINEERING',
    Program: 'UG',
    Formfee: 100,
    FeeforSemester: 1250,
    college_id: 1111000,
    program_id: '04'
  },
  {
    faculty: 'FACULTY OF ENGINEERING',
    Program: 'PG',
    Formfee: 100,
    FeeforSemester: 1500,
    college_id: 1111000,
    program_id: '05'
  },
  {
    faculty: 'FACULTY OF COMMERCE AND MANAGEMENT',
    Program: 'UG',
    Formfee: 100,
    FeeforSemester: 1250,
    college_id: 1111005,
    program_id: '04'
  },
  {
    faculty: 'FACULTY OF COMMERCE AND MANAGEMENT',
    Program: 'PG',
    Formfee: 100,
    FeeforSemester: 1500,
    college_id: 1111005,
    program_id: '05'
  },
  {
    faculty: 'FACULTY OF EDUCATION',
    Program: 'UG',
    Formfee: 100,
    FeeforSemester: 1250,
    college_id: 1111010,
    program_id: '04'
  },
  {
    faculty: 'FACULTY OF ARTS AND HUMANITIES',
    Program: 'UG',
    Formfee: 100,
    FeeforSemester: 1250,
    college_id: 1111004,
    program_id: '04'
  },
  {
    faculty: 'FACULTY OF IT AND COMPUTER SCIENCE',
    Program: 'UG',
    Formfee: 100,
    FeeforSemester: 1250,
    college_id: 1111009,
    program_id: '04'
  }
]