import React from 'react'

function About_us() {
  return (
    <div>

<>
  {/* Start For Content */}
  <div className="container ">
    <div className="row">
      
      <div className="col-md-12">
        <h2 className='text-center'>SHREE SWAMINARAYAN VISHVAMANGAL GURUKUL, KALOL</h2>
       <div className="text-center">
       <img
            src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/faculties/homoeopathy/assets/1637637847_6986-org.jpg"
            alt=""
            className="img-responsive " width="70%"
         
          /></div> <br /><br /><br />
        <p className='text-center' style={{ textAlign: "justify" }}>
          &nbsp;In Gandhinagar district it has been established in 1992. The
          campus spread over more than 25 acres of land with world-class
          infrastructure and more than 3,000 students on campus. The trust is
          fully devoted to the noble cause of spreading education in the region.
          To form an educational trust was a dream of Shastri Swami Shree
          Premswarupdasji, who wanted to do something for the society and
          materialized his dream by providing his huge share of his property for
          this noble cause.
        </p>
        <p  className='text-center'  style={{ textAlign: "justify" }}>
          In Continuation of the true spirit of charity, SHREE SWAMINARAYAN
          VISHVAMANGAL GURUKUL, KALOL is equality and strongly committed to the
          philosophy of offering technology-centered education routed in the
          ideas of charity and philanthropy. The Trust is a distinguished
          organization committed to the multidimensional growth of education
          fraternity. The trust is well aware of the social, administrative and
          technological needs of contemporary society, which it targets to
          fulfill through education.
        </p>
        <p>
          The University prides itself on several initiatives that have given
          the students in the state unique learning opportunities as well as
          skill developments.
        </p>
        <ul>
          <li>Swaminarayan College Of Engineering &amp; Technology(Diploma)</li>
          <li>
            Swaminarayan College Of Engineering &amp; Technology(Degree &amp;
            PG)
          </li>
          <li>Shree Swaminarayan Ayurved College</li>
          <li>Shree Swaminarayan Homoeopathy College</li>
          <li>Shree Swaminarayan Physiotherapy College, Ahmedabad</li>
          <li>Shree Swaminarayan Nursing College (BSC &amp; GNM)</li>
          <li>Shree Swaminarayan College Of Pharmacy</li>
          <li>Shree Swaminarayan Science College</li>
          <li>Shree Swaminarayan B.Ed College</li>
          <li>Shree Swaminarayan BSC College, Bhinmal, Rajasthan</li>
          <li>Shree Swaminarayan International School, Kalol</li>
          <li>Shree Swaminarayan International School, Ranip, Ahmedabdad</li>
          <li>
            Shree Swaminarayan International School(CBSE), Ranip, Ahmedabdad
          </li>
        </ul>
        <p>
          <br />
        </p>
      </div>
    </div>
    <br />
    <br />
  </div>{" "}
  {/* End For Content */}
</>

    </div>
  )
}

export default About_us