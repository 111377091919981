import React from 'react'

function ContactUs() {
  return (
    <div>

<>
  <div className=" asdfghjk jumbotron mt-5 mb-5">
    <div className="container mb-5">
      <h1>Contact us!</h1>
      <p>
        If you want to contact us please use this form below. Or send the email
        to <a href="mailto:sshckalol@gmail.com">sshckalol@gmail.com</a> &amp; call us at <a href="tel:9323691618">+91 9323691618</a>
      </p>
    </div>
  </div>
  <div className="container mb-5">
    <div className="row">
      <div className="col-md-6">
        <h2>Google Map</h2>
        <p>

        <iframe 
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14666.178571179338!2d72.5070575!3d23.2232598!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xaa7ff06f158bad35!2sShree%20Swaminarayan%20Homoeopathic%20College!5e0!3m2!1sen!2sin!4v1665232169537!5m2!1sen!2sin" 
          title="SU Homoeopathy"
          width="600"
          height="450" 
          allowfullscreen="" 
          loading="lazy" 
          referrerpolicy="no-referrer-when-downgrade"></iframe>

        </p>
      </div>
      <div className="col-md-6">
        <h2>Contact Form</h2>
        <p>If you have any question please send this from.</p>
        <p />
        <form
          id="formbuiler1"
          action="#"
          name="contactus_en"
          method="post"
          encType=""
          acceptCharset="utf-8"
        >
          <input
            type="hidden"
            name="csrf_csz"
            id="csrf_csz"
            defaultValue="3584429371ed0083f3ac210312a4433c"
          />
          <div>
            <label className="control-label" htmlFor="name">
              Name <i style={{ color: "red" }}>*</i>
            </label>
            <div className="controls">
              <input
                type="text"
                name="name"
                defaultValue=""
                id="name"
                className="form-control"
                placeholder=""
                required="required"
                maxLength={255}
              />
            </div>
          </div>
          <div>
            <label className="control-label" htmlFor="email">
              Email Address <i style={{ color: "red" }}>*</i>
            </label>
            <div className="controls">
              <input
                type="email"
                name="email"
                defaultValue=""
                id="email"
                className="form-control"
                placeholder=""
                required="required"
                maxLength={255}
              />
            </div>
          </div>
          <div>
            <label className="control-label" htmlFor="contact_type">
              Contact Type <i style={{ color: "red" }}>*</i>
            </label>
            <select
              id="contact_type"
              name="contact_type"
              className="form-control"
              required="required"
            >
              <option value="">-- Choose Type --</option>
              <option value="question">Question</option>
              <option value="contactus">ContactUs</option>
              <option value="service">Service</option>
            </select>
          </div>
          <div>
            <label className="control-label" htmlFor="message">
              Message <i style={{ color: "red" }}>*</i>
            </label>
            <div className="controls">
              <textarea
                name="message"
                id="message"
                className="form-control"
                placeholder=""
                required="required"
                rows={4}
                defaultValue={""}
              />
            </div>
          </div>
          <br />
          <br />
          <div className="form-actions">
            <input
              type="submit"
              name="submit"
              defaultValue="Send now"
              id="submit"
              className="btn btn-primary"
              placeholder=""
            />{" "}
            <input
              type="reset"
              name="reset"
              defaultValue="Reset"
              id="reset"
              className="btn btn-default"
              placeholder=""
            />{" "}
          </div>
        </form>
      </div>
    </div>
  </div>
</>

    </div>
  )
}

export default ContactUs