import React from 'react';
import { useLocation } from "react-router-dom";

function Eventssmuk() {

  const locate = useLocation();
  let images = locate.state.images;
  // console.log(locate.state.data.date.split("T")[0]);
  // let x = locate.state.data.date.split("T")[0];
  let data = locate.state.data;

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-lg-10">
            <div className="events-details-left-content pr-20">
              {images.length > 1 ? (
                <div
                  id="carouselExampleControls"
                  className="carousel slide"
                  data-bs-ride="carousel"
                >
                
                    <div className="carousel-inner">
                      {/* {images &&
                        images?.map((item, key) => { */}
                        {Array.isArray(images) &&
                            images.map((item, key) => {
                            return (
                            <>
                                <div className="carousel-item active">
                                  <img
                                    src={item}
                                    className="img-responsive d-block w-100"
                                    loading="lazy"
                                    width="100%"
                                    alt="Home"
                                  />
                                </div>
                            </>
                            );
                        })}
                    </div>

                    <div className="carousel-inner">
                        {Array.isArray(images) && images.length > 0 ? (
                            images.map((item, key) => (
                                <div key={key} className={`carousel-item ${key == 0 ? 'active' : ''}`}>
                                  <img
                                    src={item}
                                    className="img-responsive d-block w-100"
                                    loading="lazy"
                                    width="100%"
                                    alt={`Image ${key}`}
                                  />
                                </div>
                            ))
                            ) : (
                            <div className="carousel-item active">
                              <img
                                src={images}
                                className="img-responsive d-block w-100"
                                loading="lazy"
                                width="100%"
                                alt="Image"
                              />
                            </div>
                        )}
                    </div>
                  <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target="#carouselExampleControls"
                    data-bs-slide="prev"
                  >
                    <span
                      className="carousel-control-prev-icon"
                      aria-hidden="true"
                    />
                    <span className="visually-hidden">Previous</span>
                  </button>
                  <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-target="#carouselExampleControls"
                    data-bs-slide="next"
                  >
                    <span
                      className="carousel-control-next-icon"
                      aria-hidden="true"
                    />
                    <span className="visually-hidden">Next</span>
                  </button>
                </div>
              ) : (
                <div className="events-image text-center">
                  <img
                    style={{ objectFit: "fit", height: "800px" }}
                    src={images}
                    alt="Image"
                  />
                </div>
              )}
              <div className="meetings mt-3">
                <h2>{data?.title}</h2>
                {/* <p className="text-muted">
                  {x.slice(-2)} - {data?.month} - {data?.year}
                </p> */}
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Eventssmuk;
