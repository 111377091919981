import React from "react";
import axios from "axios";
import { useState, useRef, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, Navigate, useNavigate } from "react-router-dom";
import "./carousel.css";
import { ADD_SLIDERS } from "../../Utils/InfoUploadingConst";
import "../Home/Home.scss";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";

function Home() {
  const navigate = useNavigate();
  const [info, setInfo] = useState([]);

  // State for managing active slide index
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const getData = async () => {
    const config = {
      method: "get",
      url: ADD_SLIDERS,
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        res.data.data.sort((a, b) => b.id - a.id);
        const activeData = res.data.data.filter(
          (element) =>
            element.status === "ACTIVE" && element.college_id == 1111000
        );
        console.log(activeData);
        setInfo(activeData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const PopUpData = info?.filter((element) => element.type == "PopUp");
  // console.log("PopUpData -", PopUpData);

  //main slider
  // useEffect(() => {
  //   // Find the carousel element
  //   const carousel = document.getElementById("carouselExampleCaptions");
  //   let currentIndex = 0;

  //   // Define a function to advance to the next slide
  //   const nextSlide = () => {
  //     const slides = carousel.querySelectorAll(".carousel-item");
  //     const totalSlides = slides.length;

  //     // Remove the 'active' class from the current slide
  //     slides[currentIndex].classList.remove("active");

  //     // Move to the next slide or loop back to the first slide if at the end
  //     currentIndex = (currentIndex + 1) % totalSlides;

  //     // Add the 'active' class to the next slide
  //     slides[currentIndex].classList.add("active");

  //     // Trigger a click event on the next slide button to emulate sliding behavior
  //     const nextButton = carousel.querySelector(".carousel-control-next");
  //     nextButton.click();
  //   };

  //   // Start the carousel sliding automatically
  //   const intervalId = setInterval(nextSlide, 7000); // Slide every 2000 milliseconds (2 seconds)

  //   // Clean up when the component unmounts
  //   return () => clearInterval(intervalId);
  // }, []);

  //popup
  // useEffect(() => {

  //   if (PopUpData && PopUpData.length > 0) {
  //     // Find the carousel element
  //     const popUpcarousel = document.getElementById("popUpcarousel");
  //     let currentIndex = 0;

  //     // Define a function to advance to the next slide
  //     const nextSlide = () => {
  //       const slides = popUpcarousel.querySelectorAll(".carousel-item");
  //       const totalSlides = slides.length;

  //       // Remove the 'active' class from the current slide
  //       slides[currentIndex].classList.remove("active");

  //       // Move to the next slide or loop back to the first slide if at the end
  //       currentIndex = (currentIndex + 1) % totalSlides;

  //       // Add the 'active' class to the next slide
  //       slides[currentIndex].classList.add("active");

  //       // Trigger a click event on the next slide button to emulate sliding behavior
  //       const nextButton = popUpcarousel.querySelector(".carousel-control-next");
  //       nextButton.click();
  //     };

  //     // Start the carousel sliding automatically
  //     const intervalId = setInterval(nextSlide, 7000); // Slide every 7000 milliseconds (7 seconds)

  //     // Clean up when the component unmounts
  //     return () => clearInterval(intervalId);
  //   }
  // }, [PopUpData]);

  // Start automatic sliding when component mounts
  useEffect(() => {
    const interval = setInterval(() => {
      // Increment active slide index
      setActiveSlideIndex((prevIndex) =>
        prevIndex === info.length - 1 ? 0 : prevIndex + 1
      );
    }, 7000); // Change slide every 7 seconds (adjust as needed)

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, [info.length]); // Trigger effect when info length changes

  return (
    <div className="Home">
      <div className="container-fluid">
        <div className="row">
          {/* PopUp */}
          {/* Modal */}
          <div
            className="modal fade"
            id="exampleModal"
            tabIndex={-1}
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div
              // className="modal-dialog modal-xl"
              className="modal-dialog modal-lg modal-dialog-centered"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel" />
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  />
                </div>
                <div className="modal-body">
                  <div className="row">
                    <Swiper
                      modules={[Autoplay, Pagination, Navigation]}
                      autoplay={{ delay: 5000, disableOnInteraction: false }} // Autoplay with 7-second delay
                      slidesPerView={1}
                      navigation
                      pagination={{ clickable: true }}
                      onSlideChange={(swiper) =>
                        setActiveSlideIndex(swiper.realIndex)
                      }
                    >
                      {info
                        .filter((item) => item.type == "PopUp")
                        .map((item, index) => (
                          <SwiperSlide key={index}>
                            <h3 className="text-center blink_me d-none d-sm-block">
                              {item.title}
                            </h3>

                            <img
                              src={item.image.replace(/"/g, "")}
                              className="d-block w-100 img-fluid PopUp"
                              alt="..."
                            />
                          </SwiperSlide>
                        ))}
                    </Swiper>

                    {/* <div id="popUpcarousel" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-indicators">
              {info
                .filter((item) => item.type === "PopUp")
                ?.map((item, index) => (
                  <button
                    key={index}
                    type="button"
                    data-bs-target="#popUpcarousel"
                    data-bs-slide-to={index}
                    className={index === 0 ? "active" : ""}
                    aria-label={`Slide ${index + 1}`}
                  />
                ))}
            </div>
            <div className="carousel-inner">
              {info
                .filter((item) => item.type === "PopUp")
                ?.map((item, index) => (
                  <div key={index} className={`carousel-item ${index === 0 ? "active" : ""}`} data-bs-interval="7000">

                      <h2 className="text-center blink_me d-none d-sm-block">{item.title}</h2>
                      <h5 className="text-center blink_me d-block d-sm-none">{item.title}</h5>
                    <img src={item.image.replace(/"/g, "")} className="d-block w-100 img-fluid h-80 popup" alt="..." />
                    <div className="carousel-caption d-none d-md-block" />
                  </div>
                ))}
            </div>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#popUpcarousel"
              data-bs-slide="prev"
            >
              <span className="carousel-control-prev-icon" aria-hidden="true" />
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#popUpcarousel"
              data-bs-slide="next"
            >
              <span className="carousel-control-next-icon" aria-hidden="true" />
              <span className="visually-hidden">Next</span>
            </button>
          </div> */}
                  </div>
                </div>
                <div className="modal-footer"></div>
              </div>
            </div>
          </div>

          {/* main slider */}

          <div className="banner-area">
            <Swiper
              // Add Autoplay module and set autoplay options
              modules={[Autoplay, Pagination, Navigation]}
              autoplay={{ delay: 7000, disableOnInteraction: false }} // Autoplay with 7-second delay
              slidesPerView={1}
              navigation
              pagination={{ clickable: true }}
              onSlideChange={(swiper) => setActiveSlideIndex(swiper.realIndex)}
            >
              {/* Map through info to create swiper slides */}
              {info
                .filter((item) => item.type == "Main Slider")
                .map((item, index) => (
                  <SwiperSlide key={index}>
                    <img
                      src={item.image.replace(/"/g, "")}
                      className="d-block w-100 img-fluid h-80 main-slider"
                      alt="..."
                    />
                    <div className="carousel-caption d-none d-md-block">
                      <h1 className="text-light">{item.title}</h1>
                    </div>
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>

          {/* <div className="banner-area">
  <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
    <div className="carousel-indicators">
      {info
      .filter((item) => item.type == "Main Slider")
      ?.map((item, index) => (
        <button
          key={index}
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide-to={index}
          className={index === 0 ? "active" : ""}
          aria-label={`Slide ${index + 1}`}
        />
      ))}
    </div>
    <div className="carousel-inner">
      {info
      .filter((item) => item.type == "Main Slider")
      ?.map((item, index) => (
        <div key={index} className={`carousel-item ${index === 0 ? "active" : ""}`} data-bs-interval="7000">
          <img src={item.image.replace(/"/g, '')} className="d-block w-100 img-fluid h-80 main-slider" alt="..." />
          <div className="carousel-caption d-none d-md-block">
            <h1 className="text-light">{item.title}</h1>
          </div>
        </div>
      ))}
    </div>
    <button
      className="carousel-control-prev"
      type="button"
      data-bs-target="#carouselExampleCaptions"
      data-bs-slide="prev"
    >
      <span className="carousel-control-prev-icon" aria-hidden="true" />
      <span className="visually-hidden">Previous</span>
    </button>
    <button
      className="carousel-control-next"
      type="button"
      data-bs-target="#carouselExampleCaptions"
      data-bs-slide="next"
    >
      <span className="carousel-control-next-icon" aria-hidden="true" />
      <span className="visually-hidden">Next</span>
    </button>
  </div>
</div> */}
        </div>

        <div className="campus-information-area pb-70 mt-5">
          <div className="container">
            <div className="row align-items-center">
              <div
                className="col-lg-6 aos-init aos-animate"
                data-aos="fade-right"
                data-aos-easing="ease-in-sine"
                data-aos-duration={1300}
                data-aos-once="true"
              >
                <div className="campus-content pr-20">
                  <div className="campus-title">
                    <h2>Campus Information</h2>
                    <p>
                      Swaminarayan Institute of Enginnering is located at Kalol
                      near Ahmedabad. The region is well connected to all the
                      parts of the country by rail, road and airways.
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-6 aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="ease-in-sine"
                data-aos-duration={1300}
                data-aos-once="true"
              >
                <div className="campus-image pl-20">
                  <img
                    //  src={banner5}
                    src="/assets/engineering/images/campus.jpg"
                    alt="Image"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <br />

        <div className=" description container mt-4">
          <h1 className="text-center">DEPARTMENTS</h1>
          <br />

          <div
            className="nav nav-tabs d-flex justify-content-between mt-2"
            id="nav-tab"
            role="tablist"
          >
            <button
              className="nav-link "
              id="nav-diploma-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-diploma"
              type="button"
              role="tab"
              aria-controls="nav-diploma"
              aria-selected="true"
            >
              Diploma
            </button>
            <button
              className="nav-link active"
              id="nav-instructor-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-instructor"
              type="button"
              role="tab"
              aria-controls="nav-instructor"
              aria-selected="false"
              tabIndex={-1}
            >
              Undergraduate
            </button>
            <button
              className="nav-link"
              id="nav-postgraduate-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-postgraduate"
              type="button"
              role="tab"
              aria-controls="nav-postgraduate"
              aria-selected="false"
              tabIndex={-1}
            >
              Postgraduate / Master
            </button>
            <button
              className="nav-link"
              id="nav-C2D-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-C2D"
              type="button"
              role="tab"
              aria-controls="nav-C2D"
              aria-selected="false"
              tabIndex={-1}
            >
              C2D
            </button>
            <button
              className="nav-link"
              id="nav-D2D-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-D2D"
              type="button"
              role="tab"
              aria-controls="nav-D2D"
              aria-selected="false"
              tabIndex={-1}
            >
              D2D
            </button>
          </div>

          <div class="tab-content" id="nav-tabContent">
            <div
              class="tab-pane fade  "
              id="nav-diploma"
              role="tabpanel"
              aria-labelledby="nav-diploma-tab"
            >
              <div className="row justify-content-center">
                <div
                  className="col-lg-3 col-sm-6"
                  // data-aos="fade-up"
                  // data-aos-easing="ease-in-sine"
                  // data-aos-duration={1300}
                  // data-aos-once="true"
                >
                  <div className="single-academics-card3">
                    <div className="icon">
                      <i className="flaticon-pc" />
                    </div>
                    <Link to={"/Graduate"}>
                      <h3>Automobile Engineering - DIPLOMA</h3>
                    </Link>
                    <Link to={"/Graduate"} className="read-more-btn">
                      Read More
                      <i className="flaticon-next" />
                    </Link>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="single-academics-card3">
                    <div className="icon">
                      <i className="flaticon-pc" />
                    </div>
                    <Link to={"/Graduate"}>
                      <h3>Mechanical Engineering - DIPLOMA</h3>
                    </Link>
                    <Link to={"/Graduate"} className="read-more-btn">
                      Read More
                      <i className="flaticon-next" />
                    </Link>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="single-academics-card3">
                    <div className="icon">
                      <i className="flaticon-pc" />
                    </div>
                    <Link to={"/Graduate"}>
                      <h3>Electrical Engineering - DIPLOMA</h3>
                    </Link>
                    <Link to={"/Graduate"} className="read-more-btn">
                      Read More
                      <i className="flaticon-next" />
                    </Link>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="single-academics-card3">
                    <div className="icon">
                      <i className="flaticon-pc" />
                    </div>
                    <Link to={"/Graduate"}>
                      <h3>Computer Engineering - DIPLOMA</h3>
                    </Link>
                    <Link to={"/Graduate"} className="read-more-btn">
                      Read More
                      <i className="flaticon-next" />
                    </Link>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="single-academics-card3">
                    <div className="icon">
                      <i className="flaticon-pc" />
                    </div>
                    <Link to={"/Graduate"}>
                      <h3>Information Technology - DIPLOMA</h3>
                    </Link>
                    <Link to={"/Graduate"} className="read-more-btn">
                      Read More
                      <i className="flaticon-next" />
                    </Link>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="single-academics-card3">
                    <div className="icon">
                      <i className="flaticon-pc" />
                    </div>
                    <Link to={"/Graduate"}>
                      <h3>Civil Engineering - DIPLOMA</h3>
                    </Link>
                    <Link to={"/Graduate"} className="read-more-btn">
                      Read More
                      <i className="flaticon-next" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="tab-pane fade active show"
              id="nav-instructor"
              role="tabpanel"
              aria-labelledby="nav-instructor-tab"
            >
              <div className="row justify-content-center">
                <div className="col-lg-3 col-sm-6">
                  <div className="single-academics-card3">
                    <div className="icon">
                      <i className="flaticon-pc" />
                    </div>
                    {/* <Link to={'/Graduate'}> */}
                    <h3>Automobile Engineering - DEGREE</h3>
                    {/* </Link>      */}
                    {/* <Link to={'/Graduate'} className="read-more-btn">
              Read More
              <i className="flaticon-next" />
            </Link> */}
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="single-academics-card3">
                    <div className="icon">
                      <i className="flaticon-pc" />
                    </div>

                    <h3>Mechanical Engineering - DEGREE</h3>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="single-academics-card3">
                    <div className="icon">
                      <i className="flaticon-pc" />
                    </div>

                    <h3>Electrical Engineering - DEGREE</h3>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="single-academics-card3">
                    <div className="icon">
                      <i className="flaticon-pc" />
                    </div>

                    <h3>Computer Engineering - DEGREE</h3>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="single-academics-card3">
                    <div className="icon">
                      <i className="flaticon-pc" />
                    </div>

                    <h3>Civil Engineering - DEGREE</h3>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="single-academics-card3">
                    <div className="icon">
                      <i className="flaticon-pc" />
                    </div>

                    <h3>Information Technology - DEGREE</h3>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="tab-pane fade "
              id="nav-postgraduate"
              role="tabpanel"
              aria-labelledby="nav-postgraduate-tab"
            >
              <div className="row justify-content-center">
                <div className="col-lg-3 col-sm-6">
                  <div className="single-academics-card3">
                    <div className="icon">
                      <i className="flaticon-blueprint" />
                    </div>

                    <h3>M.E - Mechanical (CAD/CAM)</h3>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="single-academics-card3">
                    <div className="icon">
                      <i className="flaticon-blueprint" />
                    </div>

                    <h3>M.E - Electrical</h3>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="single-academics-card3">
                    <div className="icon">
                      <i className="flaticon-blueprint" />
                    </div>

                    <h3>M.E - Computer</h3>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="single-academics-card3">
                    <div className="icon">
                      <i className="flaticon-blueprint" />
                    </div>

                    <h3>ME CIVIL (STRUCTURE)</h3>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="tab-pane fade  "
              id="nav-C2D"
              role="tabpanel"
              aria-labelledby="nav-C2D-tab"
            >
              <div className="row justify-content-center">
                <div className="col-lg-3 col-sm-6">
                  <div className="single-academics-card3">
                    <div className="icon">
                      <i className="flaticon-pc" />
                    </div>

                    <h3> Automobile Engineering - C2D </h3>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="single-academics-card3">
                    <div className="icon">
                      <i className="flaticon-pc" />
                    </div>

                    <h3> Mechanical Engineering - C2D </h3>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="single-academics-card3">
                    <div className="icon">
                      <i className="flaticon-pc" />
                    </div>

                    <h3> Electrical Engineering - C2D </h3>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="single-academics-card3">
                    <div className="icon">
                      <i className="flaticon-pc" />
                    </div>

                    <h3> Computer Engineering - C2D </h3>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="single-academics-card3">
                    <div className="icon">
                      <i className="flaticon-pc" />
                    </div>

                    <h3> Civil Engineering - C2D </h3>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="single-academics-card3">
                    <div className="icon">
                      <i className="flaticon-pc" />
                    </div>

                    <h3> Information Technology - C2D </h3>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="tab-pane fade  "
              id="nav-D2D"
              role="tabpanel"
              aria-labelledby="nav-D2D-tab"
            >
              <div className="row justify-content-center">
                <div className="col-lg-3 col-sm-6">
                  <div className="single-academics-card3">
                    <div className="icon">
                      <i className="flaticon-pc" />
                    </div>

                    <h3> Automobile Engineering - D2D </h3>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="single-academics-card3">
                    <div className="icon">
                      <i className="flaticon-pc" />
                    </div>

                    <h3> Mechanical Engineering - D2D </h3>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="single-academics-card3">
                    <div className="icon">
                      <i className="flaticon-pc" />
                    </div>

                    <h3> Electrical Engineering - D2D </h3>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="single-academics-card3">
                    <div className="icon">
                      <i className="flaticon-pc" />
                    </div>

                    <h3> Computer Engineering - D2D </h3>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="single-academics-card3">
                    <div className="icon">
                      <i className="flaticon-pc" />
                    </div>

                    <h3> Civil Engineering - D2D </h3>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="single-academics-card3">
                    <div className="icon">
                      <i className="flaticon-pc" />
                    </div>

                    <h3> Information Technology - D2D </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <br />
        <br />

        <h1 className="text-center mb-5">Campus Life</h1>

        <div className="row">
          <div className="d-flex justify-content-center align-items-center flex-wrap ">
            <div
              className="text-center mx-5 aos-init aos-animate"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="200"
              data-aos-once="true"
            >
              <img
                src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/campus.PNG"
                className="img-fluid icons mb-3"
                width="150"
                alt=""
                srcset=""
              />{" "}
              <br />
              <h3 className="mb-5">LUSH GREEN CAMPUS </h3>
            </div>
            <div
              className="text-center mx-5 aos-init aos-animate"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="200"
              data-aos-once="true"
            >
              <img
                src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/clasroom.PNG"
                className="img-fluid icons mb-3"
                width="70"
                alt=""
                srcset=""
              />{" "}
              <br />
              <h3 className="mb-5">DIGITAL CLASSROOM </h3>
            </div>
            <div
              className="text-center mx-5 aos-init aos-animate"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="200"
              data-aos-once="true"
            >
              <img
                src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/library+%26+labs.PNG"
                className
                icons="img-fluid mb-3"
                width="100"
                alt=""
                srcset=""
              />{" "}
              <br />
              <h3 className="mb-5">LIBRARY & LABS </h3>
            </div>
            <div
              className="text-center mx-5 aos-init aos-animate"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="200"
              data-aos-once="true"
            >
              <img
                src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/sports.PNG"
                className="img-fluid icons mb-3"
                width="100"
                alt=""
                srcset=""
              />{" "}
              <br />
              <h3 className="mb-5">SPORTS GROUND</h3>
            </div>
            <div
              className="text-center mx-5 aos-init aos-animate"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="200"
              data-aos-once="true"
            >
              <img
                src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/hostel.PNG"
                className="img-fluid icons mb-3"
                width="100"
                alt=""
                srcset=""
              />{" "}
              <br />
              <h3 className="mb-5">HOSTEL FACILITY</h3>
            </div>

            <div
              className="text-center mx-5 aos-init aos-animate"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="200"
              data-aos-once="true"
            >
              <img
                src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/bus.PNG"
                className="img-fluid icons mb-3"
                width="100"
                alt=""
                srcset=""
              />{" "}
              <br />
              <h3 className="mb-5">TRANSPORT FACILITY</h3>
            </div>
            <div
              className="text-center mx-5 aos-init aos-animate"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="200"
              data-aos-once="true"
            >
              <img
                src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/placement.PNG"
                className="img-fluid icons mb-3"
                width="90"
                alt=""
                srcset=""
              />{" "}
              <br />
              <h3 className="mb-5">TRAINING AND PLACEMENT</h3>
            </div>
            <div
              className="text-center mx-5 aos-init aos-animate"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="200"
              data-aos-once="true"
            >
              <img
                src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/canteen.PNG"
                className="img-fluid icons mb-3"
                width="140"
                alt=""
                srcset=""
              />{" "}
              <br />
              <h3 className="mb-5">CAFETERIA</h3>
            </div>
            <div
              className="text-center mx-5 aos-init aos-animate"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="200"
              data-aos-once="true"
            >
              <img
                src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/wifi.PNG"
                className="img-fluid icons mb-4"
                width="120"
                alt=""
                srcset=""
              />{" "}
              <br />
              <h3 className="mb-5">WIFI CAMPUS</h3>
            </div>
            <div
              className="text-center mx-5 aos-init aos-animate"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="200"
              data-aos-once="true"
            >
              <img
                src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/gym.PNG"
                className="img-fluid icons mb-3"
                width="80"
                alt=""
                srcset=""
              />{" "}
              <br />
              <h3 className="mb-5">GYM</h3>
            </div>
          </div>
        </div>

        <div className="academic-area pt-100 pb-70">
          <div className="container">
            <div className="section-title">
              <h2>College Feature</h2>
            </div>
            <div className="row justify-content-center">
              <div
                className="col-lg-4 col-md-6 aos-init aos-animate"
                data-aos="fade-up"
                data-aos-duration={1200}
                data-aos-delay={200}
                data-aos-once="true"
              >
                <div className="single-academics-card2 bg-1">
                  <div className="serial">
                    <p>01.</p>
                  </div>
                  <div className="academic-content">
                    <div className="academic-top-content">
                      <i className="flaticon-graduation" />
                      <a href="https://www.swaminarayanuniversity.ac.in/engineering/events">
                        <h3>PHOTO GALLERY</h3>
                      </a>
                    </div>
                    <p>
                      Glimpses of some of the events and campus infrastructure
                    </p>
                    <a
                      href="https://www.swaminarayanuniversity.ac.in/engineering/events"
                      className="read-more-btn white-color"
                    >
                      View More
                      <i className="flaticon-next" />
                    </a>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6 aos-init aos-animate"
                data-aos="fade-up"
                data-aos-duration={1200}
                data-aos-delay={400}
                data-aos-once="true"
              >
                <div className="single-academics-card2 bg-3">
                  <div className="serial">
                    <p>02.</p>
                  </div>
                  <div className="academic-content">
                    <div className="academic-top-content">
                      <i className="flaticon-graduation" />
                      <a href="#">
                        <h3>STUDENT CORNER</h3>
                      </a>
                    </div>
                    <p>
                      Student login space for progress reports, attendance and
                      more
                    </p>
                    <a href="#" className="read-more-btn white-color">
                      Login
                      <i className="flaticon-next" />
                    </a>
                  </div>
                </div>
              </div>
              {/* <div
                className="col-lg-4 col-md-6 aos-init aos-animate"
                data-aos="fade-up"
                data-aos-duration={1200}
                data-aos-delay={600}
                data-aos-once="true"
              >
                <div className="single-academics-card2 bg-3">
                  <div className="serial">
                    <p>03.</p>
                  </div>
                  <div className="academic-content">
                    <div className="academic-top-content">
                      <i className="flaticon-graduation" />
                      <a href="#">
                        <h3>PATIENT CORNER</h3>
                      </a>
                    </div>
                    <p>
                      Login corner for patients consisting all documented
                      details.
                    </p>
                    <a href="#" className="read-more-btn white-color">
                      Login
                      <i className="flaticon-next" />
                    </a>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {PopUpData && PopUpData?.length > 0 && (
        <Helmet>
          <script type="text/javascript">
            $('#exampleModal').modal('show');
          </script>
        </Helmet>
      )}
    </div>
  );
}

export default Home;
