import React from "react";

const principalImage = "/assets/pharmacy/images/ravikumar-patel.jpg";

function PrincipalMessage() {
  return (
    <div>
      <>
        {/* Start For Content */}
        <div className="container mt-5 mb-5">
          <div className="row">
            <div className="col-md-3">
              <img
                src={principalImage}
                alt="pricipal-image"
                className="img-responsive img-thumbnail"
                width={261}
                height={261}
              />
              <br />
              <br />

              <h3>
              Dr. Ravikumar R. Patel (M.Pharm, Ph.D.)
                <br />
              </h3>
              <p style={{}}>
                Dean, Faculty of Pharmacy
                Swaminarayan University, Kalol
                
              </p>
            </div>
            <div className="col-md-9">
              <h2>
                Principal's Message
                <br />
              </h2>
              <p style={{ textAlign: "justify" }}>
              As the Dean of this esteemed institution, it is my pleasure to introduce you to our vibrant and dynamic community of students, faculty, and staff. Our pharmacy college is dedicated to providing an exceptional education and training experience that prepares our graduates for successful careers in pharmacy and related healthcare fields. We offer a rigorous academic curriculum that combines classroom learning with hands-on experiences and clinical rotations, giving students the knowledge, skills, and confidence they need to excel in their careers. Our faculty members are leaders in their fields, bringing a wealth of experience and expertise to the classroom. They are committed to providing our students with personalized attention, mentoring, and support to help them achieve their goals. At our pharmacy college, we believe that diversity and inclusivity are essential to promoting innovation, creativity, and excellence. We welcome students from all backgrounds and cultures, and we are committed to creating a supportive and inclusive learning environment that values diversity and promotes equity and social justice. We also understand that pursuing a pharmacy degree can be a significant investment, both in terms of time and finances. That's why we offer a range of financial aid options, scholarships, and other resources to help our students achieve their academic and career goals without undue financial burden. Our pharmacy college is dedicated to preparing the next generation of healthcare professionals who will make a difference in the lives of their patients and communities. We invite you to explore our programs, connect with our faculty and staff, and learn more about what makes our pharmacy college unique.
              <br />
              <br />
              Thank you for considering our pharmacy college for your educational and professional journey. We look forward to welcoming you to our community!
              </p>
            </div>
          </div>
          <br />
          <br />
        </div>{" "}
        {/* End For Content */}
      </>
    </div>
  );
}

export default PrincipalMessage;
