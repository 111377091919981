export const pgCollegeList = [
  {
    "Merchant_ID": 18,
    "Merchant Name": "SWAMINARAYAN UNIVERSITY"
  },
    {
      "Merchant_ID": 1,
      "Merchant Name": "SHREE SWAMINARAYAN VISHAV MANGAL GURUKUL KALOL (Swaminarayan University)"
    },
    {
      "Merchant_ID": 2,
      "Merchant Name": "SWAMINARAYAN CLG OF ENGG AND TECH DIP (Faculty of Engineering)"
    },
    {
      "Merchant_ID": 3,
      "Merchant Name": "SHREE SWAMINARAYAN LAW COLLEGE (Faculty of LAW)"
    },
    {
      "Merchant_ID": 4,
      "Merchant Name": "SWAMINARAYAN CLG OF ENGG AND TECH DEGREE (Faculty of Engineering)"
    },
    {
      "Merchant_ID": 5,
      "Merchant Name": "PREMSWARUPSWAMI MULTISPECIALITY HOSPITAL (Faculty of Medicine)"
    },
    {
      "Merchant_ID": 6,
      "Merchant Name": "SHREE SWAMI B ED COL (Faculty of Education)"
    },
    {
      "Merchant_ID": 7,
      "Merchant Name": "SWAMI CLG OF ENGG AND TECH PG (Faculty of Engineering)"
    },
    {
      "Merchant_ID": 8,
      "Merchant Name": "SHREE SWAMI COLL PHARMACY (Faculty of Pharmacy)"
    },
    {
      "Merchant_ID": 9,
      "Merchant Name": "SHREE SWAMI COLL NURSING GNM (Faculty of Nursing)"
    },
    {
      "Merchant_ID": 10,
      "Merchant Name": "SHREE SWAMI AYURVEDIC COLL (Faculty of Ayurveda)"
    },
    {
      "Merchant_ID": 11,
      "Merchant Name": "SHREE SWAMI CLG OF NURSING BSC N (Faculty of Nursing)"
    },
    {
      "Merchant_ID": 12,
      "Merchant Name": "SHREE SWAMI CLG OF NURSING MSC N (Faculty of Nursing)"
    },
    {
      "Merchant_ID": 13,
      "Merchant Name": "SHREE SWAMI SCIENCE COLL (Faculty of Science)"
    },
    {
      "Merchant_ID": 14,
      "Merchant Name": "SHREE SWAMI HOMOEOPAHY COLL (Faculty of Homoeopathy)"
    },
    {
      "Merchant_ID": 15,
      "Merchant Name": "SHREE SWAMIN AYURVEDIC COLLEGE P G (Faculty of Ayurveda)"
    },
    {
      "Merchant_ID": 16,
      "Merchant Name": "SHREE SWAMI CLG OF NURSING PBBSC N (Faculty of Nursing)"
    },
    {
      "Merchant_ID": 17,
      "Merchant Name": "SHREE SWAMINARAYN COLLEGE OF NURSING ANM (Faculty of Nursing)"
    }
  ]