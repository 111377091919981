import React from "react";

export default function ExtraFacilities() {
  return (
    <div>
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-md-12">
            <p>
              <span style={{ fontSize: "18pt" }}>
                <strong>Extra Facilities</strong>
              </span>
            </p>

            <div className="card mb-3">
              <div className="row no-gutters">
                <div className="col-md-8">
                  <div className="card-body pt-5 ">
                    <h4 className="card-title mb-3">Teaching Pharmacy</h4>
                    <p className="card-text">
                      Teaching Pharmacy is a part of Department of Rasashastara
                      and Bhaishajya Kalpana, which enables students to learn
                      the concept of Large Scale Manufacturing. The Pharmacy
                      also prepares medicine for Hospital as per the
                      requirement. Different dosage forms like Churna, Vati,
                      Guggulu, Asava-arishta, Sneha Kalp, Kshar and Lavana,
                      Lauh, Avaleha, Kupipakva Rasayana are prepared on regular
                      basis.
                    </p>
                  </div>
                </div>

                <div className="col-md-4">
                  <img
                    src="/assets/ayurveda/images/teaching-pharmacy.jpg"
                    style={{'height':'100%','width':'100%','object-fit':'fill'}}
                    alt="..."
                  />
                </div>
              </div>
            </div>

            <div className="card mb-3">
              <div className="row no-gutters">
                <div className="col-md-4">
                  <img
                    src="/assets/ayurveda/images/quality-control-lab.jpg"
                    style={{'height':'100%','width':'100%','object-fit':'fill'}}
                    alt="..."
                  />
                </div>
                <div className="col-md-8">
                  <div className="card-body pt-5 ">
                    <h4 className="card-title mb-3">Quality Control Lab</h4>
                    <p className="card-text">
                      Quality Control Lab has all the facilities required for
                      testing Ayurvedic Drug manufactured in Teching Pharmacy.
                      The Lab is equipped with state of the art scientific
                      equipment like HPTLC, Colorimeter, Spectrophotometer,
                      Friability tester, Hardness Tester, Muffle furnace etc.
                      The QC Lab has undertaken Quality analysis of around 40
                      Finished products and many raw materials.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="card mb-3">
              <div className="row no-gutters">
                <div className="col-md-8">
                  <div className="card-body pt-5 ">
                    <h4 className="card-title mb-3">Library</h4>
                    <p className="card-text">
                      College library has many books, digital resources, and
                      helpful librarians. Students use it for studying and
                      working together. It has quiet and group spaces,
                      supporting different learning styles. The library is a hub
                      for academic exploration and learning.
                    </p>
                  </div>
                </div>

                <div className="col-md-4">
                  <img
                    src="/assets/ayurveda/images/library.jpg"
                    style={{'height':'100%','width':'100%','object-fit':'fill'}}
                    alt="..."
                  />
                </div>
              </div>
            </div>

            <div className="card mb-3">
              <div className="row no-gutters">
                <div className="col-md-4">
                  <img
                    src="/assets/ayurveda/images/transportation.jpg"
                    style={{'height':'100%','width':'100%','object-fit':'fill'}}
                    alt="..."
                  />
                </div>

                <div className="col-md-8">
                  <div className="card-body pt-5 ">
                    <h4 className="card-title mb-3">Transportation</h4>
                    <p className="card-text">
                      The college offers its own bus transport facility from
                      Ahmedabad City. It's a reliable and convenient service
                      with set routes and schedules, ensuring timely pick-ups
                      and drop-offs for students. This transportation option
                      enhances accessibility for students commuting between home
                      and campus.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
