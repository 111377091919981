import React from "react";

export default function ArtsCourses() {
  return (
    <div>
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-md-12">
            <p>
              <span style={{ fontSize: "18pt" }}>
                <strong>Courses</strong>
              </span>
              <br />
            </p>

            <p>
              <strong>All Courses Details:</strong>
              <br />
            </p>
            <table
              className="table table-bordered"
              style={{ borderCollapse: "collapse", width: "100%", height: 173 }}
              border={1}
            >
              <tbody>
                <tr style={{ height: 20 }}>
                  <td>
                    <strong>Sr No</strong>
                    <br />
                  </td>
                  <td>
                    <strong>Course Name</strong>
                    <br />
                  </td>
                  <td>
                    <strong>Duration</strong>
                  </td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td>
                    1
                    <br />
                  </td>
                  <td>
                    LLB
                    <br />
                  </td>
                  <td>3 Years</td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td>
                    2
                    <br />
                  </td>
                  <td>
                    LLM (Criminal , Business)
                    <br />
                  </td>
                  <td>2 Years</td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td>
                    3
                    <br />
                  </td>
                  <td>
                    LLM (Criminal, International Business - proposed)
                    <br />
                  </td>
                  <td>1 Year</td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td>
                    4
                    <br />
                  </td>
                  <td>
                    Ph.D.
                    <br />
                  </td>
                  <td>----</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
