import React from "react";

export default function Eligibility() {
  return (
    <div>
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-md-12">
            <p>
              <span style={{ fontSize: "18pt" }}>
                <strong>Fee Structure</strong>
              </span>
              <br />
            </p>

            <p>
              <strong>Fee Structure (Education Faculty) Details:</strong>
              <br />
            </p>
            <table
              className="table table-bordered"
              style={{ borderCollapse: "collapse", width: "100%", height: 173 }}
              border={1}
            >
              <tbody>
                <tr style={{ height: 20 }}>
                  <td>
                    <strong>Sr No</strong>
                    
                  </td>
                  <td>
                    <strong>Courses</strong>
                    
                  </td>
                  <td>
                    <strong>Fees</strong>
                    
                  </td>
                  
                </tr>
                <tr style={{ height: 20 }}>
                  <td>1</td>
                  <td>B.Ed</td>
                  <td>32,000/- (Per Year)</td>
                </tr>

                <tr style={{ height: 20 }}>
                  <td>2</td>
                  <td>Ph.D</td>
                  <td>60,000/- (Per Year)</td>
                </tr>
              </tbody>
            </table>

          </div>
        </div>
      </div>
    </div>
  );
}
