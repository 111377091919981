import React, { useRef } from "react";
import { useState, useEffect } from "react";
import "./NewsAnnoucementEvents.css";
import Slider from "react-slick";
import moment from "moment/moment";
import { Link } from "react-router-dom";
import { Tooltip } from "antd";
import ModalAnnouncements from "./ModalAnnouncements";
import { Modal } from "antd";

export default function NewsAnnoncementEventsNew({ events, setLoading }) {
  const [data2, setData2] = useState("");
  const [flag, setFlag] = useState(false);

  const [modalAnno, setModalAnno] = useState(false);
  const [modalData, setModalData] = useState({
    url: "",
    title: "",
    date: "",
  });

  function resetModalData() {
    setModalData({
      url: "",
      title: "",
      date: "",
    });
  }

  const newsSlider = useRef();

  const newsNext = () => {
    newsSlider.current.slickNext();
  };
  const newsPrevious = () => {
    newsSlider.current.slickPrev();
  };

  const announcementSlider = useRef();

  const announcementNext = () => {
    announcementSlider.current.slickNext();
  };
  const announcementPrevious = () => {
    announcementSlider.current.slickPrev();
  };

  const eventsSlider = useRef();

  const eventsNext = () => {
    eventsSlider.current.slickNext();
  };
  const eventsPrevious = () => {
    eventsSlider.current.slickPrev();
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 3000,
    arrows: false,
  };

  console.log("eventdata -", events);

  const contentStyle = {
    height: "160px",
    color: "#fff",
    lineHeight: "160px",
    textAlign: "center",
    background: "#364d79",
  };

  console.log("modalData -", modalData);

  return (
    <>
      <Modal
        title={"Announcements"}
        open={modalAnno}
        onCancel={() => setModalAnno(false)}
        onOk={() => setModalAnno(false)}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
      >
        <div className="row">
          <div className="col-lg-12">
            <div className="events-details-left-content pr-20">
              <div className="meetings mt-2">
                <h5>{modalData.title}</h5>
                <p>{moment(modalData.date).format("DD-MM-YYYY")}</p>
              </div>

              {/* {modalData.url && modalData.url?.length > 1 ? (
                <div
                  id="carouselExampleInterval"
                  className="carousel slide"
                  data-bs-ride="carousel"
                >
                  <div className="carousel-indicators">
                    {modalData.url?.map((item, index) => (
                      <button
                        key={index}
                        type="button"
                        data-bs-target="#carouselExampleInterval"
                        data-bs-slide-to={index}
                        className={index === 0 ? "active" : ""}
                        aria-label={`Slide ${index + 1}`}
                      />
                    ))}
                  </div>
                  <div className="carousel-inner">
                    {modalData.url?.map((image, index) => (
                      <div
                        key={index}
                        className={`carousel-item ${
                          index === 0 ? "active" : ""
                        }`}
                        data-bs-interval="4000"
                      >
                        <img
                          src={image}
                          className="d-block w-100 viewimage"
                          // alt={`Slide ${index + 1}`}
                          alt="..."
                        />
                      </div>
                    ))}
                  </div>
                  <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target="#carouselExampleInterval"
                    data-bs-slide="prev"
                  >
                    <span
                      className="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Previous</span>
                  </button>
                  <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-target="#carouselExampleInterval"
                    data-bs-slide="next"
                  >
                    <span
                      className="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Next</span>
                  </button>
                </div>
              ) : modalData.url && modalData.url.length === 1 ? (
                <div className="events-image text-center">
                  <img
                    style={{ objectFit: "fit", height: "700px" }}
                    src={modalData.url[0]}
                    alt="Image"
                    className="viewimage"
                  />
                </div>
              ) : (
                <p>No image available</p>
              )} */}

              {modalData.url && modalData.url?.length > 0 ? (
                <div
                  id="carouselExampleInterval"
                  className="carousel slide"
                  data-bs-ride="carousel"
                >
                  <div className="carousel-indicators">
                    {modalData.url?.map((item, index) => (
                      <button
                        key={index}
                        type="button"
                        data-bs-target="#carouselExampleInterval"
                        data-bs-slide-to={index}
                        className={index === 0 ? "active" : ""}
                        aria-label={`Slide ${index + 1}`}
                      />
                    ))}
                  </div>
                  <div className="carousel-inner">
                    {modalData.url?.map((item, index) => (
                      <div
                        key={index}
                        className={`carousel-item ${
                          index === 0 ? "active" : ""
                        }`}
                        data-bs-interval="4000"
                      >
                        {item.endsWith(".jpg") ||
                        item.endsWith(".png") ||
                        item.endsWith(".jpeg") ||
                        item.match(/\.\d+$/) !== null ? (
                          <img
                            src={item}
                            className="d-block w-100 viewimage"
                            alt={`Slide ${index + 1}`}
                          />
                        ) : item.endsWith(".pdf") || item.endsWith(".sc") ? (
                          <object
                            data={`${item}?#toolbar=0`}
                            type="application/pdf"
                            width="100%"
                            height="700px"
                          >
                            <embed
                              src={`${item}?#toolbar=0`}
                              type="application/pdf"
                            />
                          </object>
                        ) : (
                          <iframe
                            src={`${item}?#toolbar=0`}
                            title={`Slide ${index + 1}`}
                            className="d-block w-100 viewimage"
                            style={{ height: "700px", border: "none" }}
                          />
                        )}
                      </div>
                    ))}
                  </div>
                  <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target="#carouselExampleInterval"
                    data-bs-slide="prev"
                  >
                    <span
                      className="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Previous</span>
                  </button>
                  <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-target="#carouselExampleInterval"
                    data-bs-slide="next"
                  >
                    <span
                      className="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Next</span>
                  </button>
                </div>
              ) : (
                <p>No image, PDF, or supported file type available</p>
              )}
            </div>
          </div>
        </div>
      </Modal>

      <div className="">
        <div className="container-fluid">
          <div className="text-center">
            <h2>News, Announcements and Events</h2>
            <hr />
          </div>

          <div className="row">
            <section
              className="two-column-list mb-sm-5 container-fluid"
              id="two-column-list"
            >
              <div className="container-fluid">
                <div className="row">
                  {/* News  */}
                  <div className="col-lg-4 pr-0">
                    <section
                      aria-label="Announcements"
                      className="announcements"
                      style={{ padding: "0px" }}
                    >
                      <h2 className="section-heading bg-amber font-weight-bold border-bottom pb-3 mt-3 mb-0 pr-5">
                        News
                      </h2>
                      <div className="announcement-slider border-r-xs-0 border-r position-relative">
                        <div>
                          <Slider
                            style={{ height: "26em" }}
                            ref={(c) => (newsSlider.current = c)}
                            {...settings}
                          >
                            {events
                              .filter((item) => item.type === "News")
                              .reduce((acc, d, index) => {
                                const chunkIndex = Math.floor(index / 4);
                                if (!acc[chunkIndex]) {
                                  acc[chunkIndex] = [];
                                }
                                acc[chunkIndex].push(
                                  <li
                                    key={index}
                                    className="border-bottom pb-3 mt-3"
                                  >
                                    <h6 className="font-weight-bold mt-0">
                                      <a href={d.attachments}>
                                        <Tooltip title={d.title} zIndex={999}>
                                          {d.title.length > 50
                                            ? d.title.substring(0, 50) + "..."
                                            : d.title}
                                        </Tooltip>
                                      </a>
                                    </h6>
                                  </li>
                                );
                                return acc;
                              }, [])
                              .map((chunk, index) => (
                                <ul
                                  key={index}
                                  className="nolist list-unstyled position-relative mb-0 px-lg-1 pt-lg-3"
                                >
                                  {chunk}
                                </ul>
                              ))}
                          </Slider>

                          <div className="row d-flex align-items-center justify-content-center">
                            <div className="col-md-6">
                              <Link
                                className="all pos-stat text-uppercase ml-lg-5"
                                to="/university-notifications/News"
                              >
                                All News &nbsp;
                                <i
                                  className="fa fa-caret-right"
                                  aria-hidden="true"
                                />
                              </Link>
                            </div>
                            <div className="col-md-6">
                              <div className="left-right-arrows pr-lg-5">
                                <button
                                  className="prev-arrow-announcement"
                                  type="button"
                                  onClick={newsPrevious}
                                >
                                  <i className="fa fa-chevron-left" />
                                </button>
                                <button
                                  className="next-arrow-announcement"
                                  type="button"
                                  onClick={newsNext}
                                >
                                  <i className="fa fa-chevron-right" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                  {/** Announcements */}
                  <div className="col-lg-4 pr-0">
                    <section
                      aria-label="Announcements"
                      className="announcements"
                      style={{ padding: "0px" }}
                    >
                      <h3 className="section-heading bg-amber font-weight-bold border-bottom pb-3 mt-3 mb-0 pr-5">
                        Announcements
                      </h3>
                      <div className="announcement-slider border-r-xs-0 border-r position-relative">
                        <div>
                          <Slider
                            style={{ height: "26em" }}
                            ref={(c) => (announcementSlider.current = c)}
                            {...settings}
                          >
                            {events
                              .filter((item) => item.type === "Announcements")
                              .reduce((acc, d, index) => {
                                const chunkIndex = Math.floor(index / 4);
                                if (!acc[chunkIndex]) {
                                  acc[chunkIndex] = [];
                                }
                                acc[chunkIndex].push(
                                  <li
                                    key={index}
                                    className="border-bottom pb-3 mt-3"
                                  >
                                    <h6 className="font-weight-bold mt-0">
                                      <a
                                      // href={d.attachments}
                                      // className="btn btn-primary btn-sm my-1"
                                      >
                                        <Tooltip title={d.title}>
                                          <span
                                            onClick={() => {
                                              setModalData({
                                                title: d.title,
                                                url: d.attachments,
                                                date: d.date,
                                              });
                                              setModalAnno(true);
                                              console.log(
                                                "Modal Data URL:",
                                                d.attachments
                                              );
                                            }}
                                          >
                                            {d.title.length > 50
                                              ? d.title.substring(0, 50) + "..."
                                              : d.title}
                                          </span>
                                          {/* {d.title.length > 50
                                            ? d.title.substring(0, 50) + "..."
                                            : d.title} */}
                                        </Tooltip>
                                      </a>
                                    </h6>
                                  </li>
                                );
                                return acc;
                              }, [])
                              .map((chunk, index) => (
                                <ul
                                  key={index}
                                  className="nolist list-unstyled position-relative mb-0 px-lg-1 pt-lg-3"
                                  style={{ height: "26em" }}
                                >
                                  {chunk}
                                </ul>
                              ))}
                          </Slider>
                          <div className="row d-flex align-items-center justify-content-center ">
                            <div className="col-md-6">
                              <Link
                                className="all pos-stat text-uppercase ml-lg-3"
                                to="/university-notifications/Announcements"
                              >
                                All Announcements &nbsp;
                                <i
                                  className="fa fa-caret-right"
                                  aria-hidden="true"
                                />
                              </Link>
                            </div>
                            <div className="col-md-6">
                              <div className="left-right-arrows pr-lg-5">
                                <button
                                  className="prev-arrow-announcement"
                                  type="button"
                                  onClick={announcementPrevious}
                                >
                                  <i className="fa fa-chevron-left" />
                                </button>
                                <button
                                  className="next-arrow-announcement"
                                  type="button"
                                  onClick={announcementNext}
                                >
                                  <i className="fa fa-chevron-right" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>

                  {/* EVENTS */}

                  <div className="col-lg-4 pl-0">
                    <section
                      className="events-section pl-lg-3"
                      aria-label="Events"
                      style={{ padding: "0px" }}
                    >
                      <h2 className="section-heading font-weight-bold border-bottom pb-3 mt-3 pl-lg-5 mb-0">
                        Events
                      </h2>
                      <div className="events">
                        <div className="events-block">
                          <Slider
                            style={{ height: "26em" }}
                            ref={(c) => (eventsSlider.current = c)}
                            {...settings}
                          >
                            {events
                              .filter((item) => item.type === "Events")
                              .reduce((acc, d, index) => {
                                const chunkIndex = Math.floor(index / 4);
                                if (!acc[chunkIndex]) {
                                  acc[chunkIndex] = [];
                                }
                                acc[chunkIndex].push(
                                  <li
                                    key={index}
                                    className="border-bottom d-flex align-items-center"
                                  >
                                    <div className="events-date text-uppercase text-center">
                                      <a
                                        className="text-white"
                                        href={d.attachments}
                                      >
                                        {moment(d.date).format("MMM")}
                                        <span>
                                          {moment(d.date).format("DD")}
                                        </span>
                                      </a>
                                    </div>
                                    <div className="d-inline-block pl-3 event-li">
                                      <h6 className="font-weight-bold mt-0">
                                        <a
                                          href={d.attachments}
                                          without
                                          rel="noreferrer"
                                          target="_blank"
                                        >
                                          <Tooltip title={d.title}>
                                            {d.title.length > 50
                                              ? d.title.substring(0, 50) + "..."
                                              : d.title}
                                          </Tooltip>
                                        </a>
                                      </h6>
                                    </div>
                                  </li>
                                );
                                return acc;
                              }, [])
                              .map((chunk, index) => (
                                <ul
                                  key={index}
                                  className="nolist list-unstyled position-relative mb-0 px-lg-1"
                                  style={{ height: "26em" }}
                                >
                                  {chunk}
                                </ul>
                              ))}
                            <div></div>
                          </Slider>
                          <div className="row d-flex align-items-center justify-content-center">
                            <div className="col-md-6">
                              <Link
                                className="all pos-stat text-uppercase ml-lg-5"
                                to="/university-notifications/Events"
                              >
                                All Events &nbsp;
                                <i
                                  className="fa fa-caret-right"
                                  aria-hidden="true"
                                />
                              </Link>
                            </div>
                            <div className="col-md-6">
                              <div className="left-right-arrows second">
                                <button
                                  className="prev-arrow-events"
                                  type="button"
                                  onClick={eventsPrevious}
                                >
                                  <i className="fa fa-chevron-left" />
                                </button>
                                <button
                                  className="next-arrow-events"
                                  type="button"
                                  onClick={eventsNext}
                                >
                                  <i className="fa fa-chevron-right" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
}
