import React, { useState } from "react";
import { Helmet } from "react-helmet";
import "./Admission.scss";
import {
  facultyData,
  programData,
  specializationData,
} from "./../../Data/admissionData";
import OTP_GENRATE, { VERIFY_OTP } from "../../Utils/apiConst";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "../../Components/Loader/Loader";

const AdmissionEnquiry = () => {
  const [program, setProgram] = useState("");
  const [college, setCollege] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [collegeId, setCollegeId] = useState("");

  const [loading, setLoading] = useState(0);

  const [programOptions, setProgramOptions] = useState(programData);

  const [collegeOptions, setCollegeOptions] = useState([]);

  const [department, setDepartment] = useState();

  const clearData = () => {
    setProgram("");
    setCollege("");
    setName("");
    setPhone("");
    setEmail("");
    setOtp("");
    setCollegeId("");
  };

  const selectProgram = (id) => {
    setProgram(id);

    const collegeType = specializationData.filter(
      (item) => item.program_id == id
    );
    const collegeTypeId = collegeType.map((ff) => ff.specialization_id);
    setCollegeOptions(
      facultyData.filter((ct) => collegeTypeId.includes(ct.college_type_id))
    );
  };

  const selectCollege = (id) => {
    const idArr = id.split("|");

    setCollege(id);
    setCollegeId(idArr[0]);

    idArr[1] ? setDepartment(idArr[1]) : setDepartment(null);
  };

  // SEND_OTP

  const otpSender = async () => {
    const otpObj = {
      phone: phone,
    };

    var config = {
      method: "post",
      url: OTP_GENRATE,
      headers: {
        "Content-Type": "application/json",
      },
      data: otpObj,
    };
    setLoading(1);
    await axios(config)
      .then((res) => {
        setLoading(0);
        console.log(res);
        toast.success(`otp sent successfully`);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error(err.response.data.message);
      });
  };

  const sendOtp = (e) => {
    if (!phone) {
      toast.error("phone is required");
      alert("Phone number is Required");
      return;
    }

    otpSender();
  };

  const verifyOtp = async () => {
    const otpObj = {
      name,
      email,
      otp,
      program_id: program,
      college_id: collegeId,
      department_id: department,
      phone,
    };

    var config = {
      method: "post",
      url: VERIFY_OTP,
      headers: {
        "Content-Type": "application/json",
      },
      data: otpObj,
    };

    // setLoading(1)

    await axios(config)
      .then((res) => {
        // setLoading(0)
        console.log(res);
        alert(`Application successfully submitted :)`);
        clearData();
      })
      .catch((err) => {
        // setLoading(0)
        console.log(err);
        alert(err.response.data.message);
      });
  };

  return (
    <div className="AdmissionEnquiry">
      <Loader loading={loading} />
      <Helmet>
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/css/intlTelInput.css"
        />
        <script src="https://code.jquery.com/jquery-3.5.1.min.js"></script>

        <script src="https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/intlTelInput.min.js"></script>
        <script src="cities.js"></script>
      </Helmet>

      <div className="register-area mt-2 pb-70 ">
        <div className="container">
          <div className="register mw-sm-75 mw-xl-50">
            <h3>Admission Enquiry Form</h3>
            <div className="row">
              <div className="col-md-12 form-group auth-form-group-custom mb-4">
                <i className="ri-user-2-line auti-custom-input-icon" />
                <label htmlFor="username">Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="username"
                  name="name"
                  placeholder="Enter Name"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </div>
              <div className="col-md-6 form-group auth-form-group-custom mb-4">
                <i class="ri-mail-line auti-custom-input-icon"></i>
                <label htmlFor="username">Email</label>
                <input
                  type="email"
                  className="form-control"
                  id="username"
                  name="username"
                  placeholder="Enter Email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </div>
              <div className="col-md-6 form-group auth-form-group-custom mb-3">
                <i className="ri-phone-line auti-custom-input-icon" />
                <label htmlFor="username">Phone</label>
                <input
                  type="tel"
                  className="form-control"
                  id="username"
                  name="username"
                  placeholder="Enter Phone"
                  value={phone}
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                />
                <button className="btn otp-btn" onClick={sendOtp}>
                  GET OTP
                </button>
                {/* <i className="ri-phone-line auti-custom-input-icon" /> */}
              </div>

              {/* {phone.length >= 10 ? */}
              {/* <div className={`col-12 mt-0 p-0 mb-3 d-flex justify-content-end`}>
                  <a
                    href="javascript:void(0)"
                    // onClick={(e) => sendOtp(e)}
                    className={`mt-1 p-2 btn btn-primary`}
                  >
                    GET OTP
                  </a>
                </div> */}
              {/* : null} */}

              <div className="col-12 form-group auth-form-group-custom mb-4">
                <i className="ri-smartphone-line auti-custom-input-icon" />
                <label htmlFor="username">OTP</label>
                <input
                  type="text"
                  className="form-control"
                  id="username"
                  name="username"
                  placeholder="Enter OTP"
                  value={otp}
                  onChange={(e) => {
                    setOtp(e.target.value);
                  }}
                />
              </div>

              <div className="col-12 form-group auth-form-group-custom mb-4">
                <i className="ri-briefcase-3-line auti-custom-input-icon" />
                <label htmlFor="username">Select Program</label>
                <select
                  id="role"
                  name="role"
                  className="form-control"
                  autoComplete="off"
                  value={program}
                  onChange={(e) => selectProgram(e.target.value)}
                >
                  <option value="">Select</option>
                  {programOptions?.map((data) => {
                    return <option value={data.id}>{data.name}</option>;
                  })}
                </select>
              </div>

              <div className="col-12 form-group auth-form-group-custom mb-4">
                <i className="ri-briefcase-3-line auti-custom-input-icon" />
                <label htmlFor="username">Select Faculty</label>
                <select
                  id="role"
                  name="role"
                  className="form-control"
                  autoComplete="off"
                  value={college}
                  onChange={(e) => selectCollege(e.target.value)}
                >
                  <option value="">Select</option>
                  {collegeOptions?.map((data) => {
                    if (data.id == 1111003 && program == "04") {
                      return (
                        <>
                          <option value={"1111003|35"}>
                            FACULTY OF NURSING (BSC)
                          </option>
                          <option value={"1111003|36"}>
                            FACULTY OF NURSING (Post Basic BSC)
                          </option>
                        </>
                      );
                    }
                    return <option value={data.id}>{data.name}</option>;
                  })}
                </select>
              </div>

              <h6>
                *You'll receive an e-Mail once your enquiry is verified by
                admissions team, Please keep the email active.
              </h6>

              <div className="mt-4 text-center">
                <button
                  className="btn btn-primary w-md waves-effect waves-light"
                  name="submit"
                  value="login"
                  onClick={(e) => verifyOtp(e)}
                >
                  Submit Application
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Helmet>
        <script language="javascript">print_state("sts");</script>
      </Helmet>
    </div>
  );
};

export default AdmissionEnquiry;
