import React from 'react'
import '../Arts/Profiles.scss'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../Router/RouterConfig'
// import {profileData} from './../AutoritiesOfUniversity/ProfileData'

function VicePresidentsHomeo() {
  const navigate = useNavigate()

  const changeDir = (i) =>{
    navigate(ROUTES.Profile,{state:{data:i}});
  }
  return (
    <div className='VicePresidents'>
        <div className="container">
  <div className="row">
    <div className="col-md-12">
      <h1 className="text-center">Vice Presidents</h1>
      <div className="card border-0 shadow-sm">
        <div className="card-body">
          <div className="tab-content" id="v-pills-tabContent">
            <div
              className="tab-pane fade show active"
              id="v-pills-home"
              role="tabpanel"
              aria-labelledby="v-pills-home-tab"
            >
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-12 col-sm-8 col-lg-6">
                    {/* Section Heading*/}
                    <div
                      className="section_heading text-center wow fadeInUp"
                      data-wow-delay="0.2s"
                      style={{
                        visibility: "visible",
                        animationDelay: "0.2s",
                        animationName: "fadeInUp"
                      }}
                    >
                      {/* <h3>Engineering</h3> */}
                      <div className="line" />
                    </div>
                  </div>
                </div>
                <div className="row">
                  {/* Single Advisor*/}
                  <div className="col-12 col-sm-6 col-lg-4">
                <div 
                // onClick={()=>{changeDir(profileData[1])}}
                onClick={()=>{navigate(ROUTES.VicePresidentHomeo)}}
                >
                <div
                    className="single_advisor_profile wow fadeInUp"
                    data-wow-delay="0.3s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.3s",
                      animationName: "fadeInUp"
                    }}
                  >
                    {/* Team Thumb*/}
                    <div className="advisor_thumb">
                      <img
                        src="../assets/images/academics/vicepresident3.jpg"
                        alt="vicepresident"
                        width="100%"
                      />
                      {/* Social Info*/}
                    </div>
                    {/* Team Details*/}
                    <div className="single_advisor_details_info">
                      <h6>Swami Harikrishnaswarupdasji</h6>
                      <p className="designation">
                        Vice President
                        {/*Shree Swaminarayan Vishvamangal Gurukul</p>*/}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-lg-4">
                <div 
                // onClick={()=>{changeDir(profileData[2])}}
                onClick={()=>{navigate(ROUTES.VicePresident2Homeo)}}
                >
                  <div
                    className="single_advisor_profile wow fadeInUp"
                    data-wow-delay="0.3s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.3s",
                      animationName: "fadeInUp"
                    }}
                  >
                    {/* Team Thumb*/}
                    <div className="advisor_thumb">
                      <img
                        src="../assets/images/academics/vicepresident-1.jpg"
                        alt="vicepresident"
                        width="100%"
                      />
                      {/* Social Info*/}
                    </div>
                    {/* Team Details*/}
                    <div className="single_advisor_details_info">
                      <h6>
                        {" "}
                        Swami
                        <p /> Bhaktavatsaldasji
                      </h6>
                      <p className="designation">
                        Vice President
                        {/*Shree Swaminarayan Vishvamangal Gurukul</p>*/}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-lg-4">
                <div 
                // onClick={()=>{changeDir(profileData[3])}}
                onClick={()=>{navigate(ROUTES.VicePresident3Homeo)}}
                >
                <div
                    className="single_advisor_profile wow fadeInUp"
                    data-wow-delay="0.2s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.2s",
                      animationName: "fadeInUp"
                    }}
                  >
                    {/* Team Thumb*/}
                    <div className="advisor_thumb">
                      <img
                        src="../assets/images/academics/vicepresident-2.jpg"
                        alt="vicepresident"
                        width="100%"
                      />
                      {/* Social Info*/}
                    </div>
                    {/* Team Details*/}
                    <div className="single_advisor_details_info">
                      <h6> Swami Bhaktinandandasji</h6>
                      <p className="designation">
                        {" "}
                        Vice President
                        {/*Shree Swaminarayan Vishvamangal Gurukul</p>*/}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    </div>
  )
}

export default VicePresidentsHomeo;