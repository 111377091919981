import React from "react";
import axios from "axios";
import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../Router/RouterConfig";
import "../Connect/Downloads/Brochure.scss";
import { LOCAL_DEPARTMENT, LOCAL_EMPLOYEE, LOCAL_PROGRAM } from "../../Utils/LocalStorageConstants";
import { STAFF_DATA } from "../../Utils/apiConst";

function TeachingStaffArts() {
  const navigate = useNavigate();

  const [data, setData] = useState([]);

  const ProgramTypeNames = JSON.parse(localStorage.getItem(LOCAL_PROGRAM)) || [];

  const [employeeData, setEmployeeData] = useState([]);

  async function fetchData() {
    const college_id = "1111004";
    await axios
      .get(`${STAFF_DATA}?college_id=${college_id}&teaching_type=TEACHING&status=ACTIVE`)
      .then((res) => {
        setEmployeeData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  useEffect(() => {
    fetchData();
  }, []);

  function formatDate(dateString) {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    const formattedDate = new Date(dateString).toLocaleDateString(
      "en-US",
      options
    );
    return formattedDate;
  }

  const roleMapping = {
    ASSTPR: "Assistant Professor",
    ASPR: "Associate Professor",
    ASSTLIB: "Assistant Librarian",
    Attend: "Attender",
    AdmCoun: "Admission Counsellor",
    BME: "BioMedical Engineer",
    COE: "Controller Of Exams",
    CLERK: "Clerk",
    DMS: "Deputy Medical Superintendent",
    DOFAC: "Dean Of Faculty (Diploma)",
    DOFUGPG: "Dean Of faculty (UG & PG)",
    JR: "Junior Resident",
    JRF: "Junior Research Fellow",
    LA: "Lab Assistant",
    MO: "Medical Officer",
    PROF: "Professor",
    Phy: "Physiotherapist",
    PA: "Panchakarma Assistant",
    Recep: "Receptionist",
    REG: "Registrar",
    SR: "Senior Resident",
    Tut: "Tutor",
    READ: "Reader",
    OFFE: "Office Executive",
    VP: "Vice Principal",
    PRO: "Provost",
    LIB: "Librarian",
    ACCNT: "Accountant",
    CC: "Clerk Cum Cashier",
    P: "Peon",
    HOD: "Head of The Department",

    SUACC: "SUACC",
    IT: "IT",

    Dean: "Dean/Principal",
    Mas: "Massure",
    "IT Cell": "Website Coordinator",
    Amission: "Admission Officer",
    SACC: "Sr Accountant",
    NET: "Network Administrator",
    "Exe-HR": "Executive-Human Resources",
    "H-HR": "Assistant Manager-Human Resource",
    SECOR: "Security Officer",
    PROC: "Proctor",
    SOPS: "Section Officer-Purchase & Stores",
    DIRP: "Director Placements",
    CWAR: "Chief Warden",
    DENIQSC: "Dean IQSC",
    DENSW: "Dean Student Welfare",
    DERES: "Dean Research",
    DIA: "Director International Affairs",
    DREGAC: "Deputy Registrar(Accreditation & Compliance)",
    DREGAD: "Deputy Registrar(Admissions and Admin)",
    EXAM: "Examiner",
    FOP: "Finance Officer",
    HITS: "Head IT Services",
    MANTAIN: "Mantainance Engineer",
  };

  const programData = ["04", "05", "02", "03", "07","08", "06", "01"];

  // Group employee data by program_type and department_id
  const groupedData = {};
  employeeData.forEach((item) => {
    const { program_type, department_id } = item;
    if (!groupedData[program_type]) {
      groupedData[program_type] = {};
    }
    if (!groupedData[program_type][department_id]) {
      groupedData[program_type][department_id] = [];
    }
    groupedData[program_type][department_id].push(item);
  });

  return (
    <div className="">
      <div className="container register" style={{maxWidth:"90%"}}>
        <div className="row">
          <div className="col-md-12">
            <h1 className="text-center mt-5">Teaching Staff</h1>
            {programData
            ?.map((programId) => {
              const program = ProgramTypeNames.find((item) => item.id === programId);
              if (!groupedData[program.id]) {
                return null; // Skip rendering if there's no data for this program type
              }
              return (
              <div key={program.id}>
                <h2>
                  {program.name}                 
                </h2>
                <br/>
                {Object.entries(groupedData[program.id] || {}).map(([departmentId, employees]) => (
                  <div key={departmentId}>
                    {/* <h3 className='mt-2'>{`Department : ${departmentId}`}</h3> */}
                    <h3 className='mt-2'>{`${departmentId}`}</h3>
                    <br/>
                    <div className="row">
                      {employees
                      ?.map((employee, index) => (
                        <div key={index} className="col-sm-3 mb-5 mt-2">
                          <div className="card border-0 shadow-sm" >
                            <img src={employee?.photo ? employee?.photo : "/assets/images/white-logo.png"} className="card-img-top" alt="..." style={{height:"350px",objectFit:"cover",objectPosition:"top"}} />
                            <div className="card-body">
                              <h5 className="card-title">Name: {employee.name || "---"}</h5>
                              <h6 className="text-dark">
                                Designation: {roleMapping[employee.role] || employee.role || "--"}
                              </h6>
                              <h6 className="text-dark">         
                                Registration No : {employee.reg_no || "--"}{" "}                                        
                              </h6>
                              {employee?.staff_id ? (
                              <h6 className="text-dark">                                        
                                Staff ID : {employee.staff_id || "--"}{" "}                                        
                              </h6>
                              ) : <></>
                              }
                              {employee?.date_of_birth ? (
                                <h6 className="text-dark">
                                  Date of Birth : {new Date(employee.date_of_birth).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }).replace(/\//g, '-')}{" "}
                                </h6>
                              ) : <></>
                              }          
                              {employee?.qualification ? (
                              <h6 className="text-dark">
                                Qualification : {employee?.qualification || "--"}{" "}
                              </h6>
                              ) : <></>
                              } 
                              <h6 className="text-dark">
                                Date of Joining: {employee.date_of_joining ? new Date(employee.date_of_joining).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }).replace(/\//g, '-') : "--"}{" "}                                        
                                {/* Date of Joining : {employee.date_of_joining ? new Date(employee.date_of_joining).toLocaleDateString('en-GB') : "--"}{" "}                                       */}
                              </h6>
                                                                    
                              <h6 className="text-dark">                                        
                                Experience: {employee.experience || "--"}                                        
                              </h6>   
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <br/>
                    <br/>
                    <br/>
                  </div>
                ))}
                <br/>
                <br/>
              </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TeachingStaffArts;
