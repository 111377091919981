import React from 'react'
import {useEffect} from 'react';

function SimsRedirect() {

    useEffect(() => {
        window.location.href = "https://docs.google.com/forms/d/e/1FAIpQLScuKP27SUO0XPX4CV98hJNBJ038YAJK3d-XJ9Im_Utcrn4rDg/viewform?pli=1";
      }, '_blank');

  return (
    <div>
        <h3>Redirecting.. Please Wait</h3>
    </div>
  )
}

export default SimsRedirect