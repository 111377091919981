import React from 'react'
import { Link } from 'react-router-dom'

function AfterPG() {
  return (
    <div className='AfterPG '>
<div class="page-banner-area bg-2">
<div class="container">
<div class="page-banner-content">
<h1>After PG</h1>
<ul>
<li> <Link to={'./../'}>  Home </Link></li>


<li>After PG</li>
</ul>
</div>
</div>
</div>
<div className="container mt-5">
    <div className="row">
        <div className="col-md-9">
        <div className="register" style={{maxWidth:'1320px'}} >
<div className="container-fluid">
   <div className="row">
    <h3>AfterPG</h3>
   
    <br/>

    <h2 className='text-center text-light' style={{backgroundColor: "#FC7F03"}}>Ph.D. (Doctor of  
        Philosophy)
     </h2>
     <br/>
     <br/>
     <ul>
     <br/>
        <li>Ph.D. Programme shall be for a minimum duration of three (3) years, including  coursework, and a maximum duration of six (6) years from the date of admission to the  Ph.D. programme.</li><br/>
        <li>A maximum of an additional two (2) years can be given through a process of re registration as per the Statute/Ordinance of the Higher Educational Institution concerned;  provided, however, that the total period for completion of a Ph.D. programme should not  exceed eight (8) years from the date of admission in the Ph.D. programme. Provided further  that, female Ph.D. scholars and Persons with Disabilities (having more than 40% disability)  may be allowed an additional relaxation of two (2) years; however, the total period for  completion of a Ph.D. programme in such cases should not exceed ten (10) years from the  date of admission in the Ph.D. programme.</li><br/>
        <li>Female Ph.D. Scholars may be provided Maternity Leave/Child Care Leave for up to 240days in the entire duration of the Ph.D. programme. </li><br/>
     </ul>
    <br/>
    <ol>
        <li>
        Ph.D. program is offered in Faculty of Ayurveda, Homoeopathy, Medical Science,  Arts & Humanities, commerce & Management, Education, Law, Pharmacy,  Science, Engineering, IT & Computer Science. 

        </li><br/>
        <li>Notification for admission along with subject specification will be posted on  the Website of Swaminarayan University ((www.swaminarayanuniversity.ac.in) ,  indicating the number of pre-determined seats in each Department of  Swaminarayan University along with all relevant information and Ph.D.  Regulations 
</li><br/>
<li><b>Eligibility Criteria:</b> The Post graduate qualifying degree should be recognized by  the UGC / Association of Indian Universities / Central Council of Indian Medicine  / Central Council of Homoeopathy /Indian Nursing Council / Council of  Architecture / Pharmacy Council of India / All India Council for Technical  Education or any other approved and recognized Scientific Bodies of the  Government of India and approved by the Academic Council / Board of  Management of the University for Provisional Registration for the PhD Degree. </li>
<br/>
<li><b>Intake :</b> As per vacancy declared under research supervisor
</li><br/>
<li><b> Duration:</b>The minimum period of Ph.D. Program for a Ph.D. scholar  registered in the Faculty of Health Sciences/Others for a minimum period  of three years / four years respectively from the date of joining.  </li>
    <br/>
    <li>A maximum of two extensions, each of six months’ duration, beyond the  maximum period of research, may be considered by the Vice Chancellor on  the specific recommendation</li><br/>
    <li> Women Candidates and Persons with Disability (more than 40% disability)  may be allowed a relaxation of two years for Ph.D. In addition, women  candidates may be provided Maternity Leave / Child Care Leave once in the  entire duration of Ph.D. as per the current leave rules.</li> 
   
</ol>
   </div>

</div>
</div>
        </div>
        <div class="col-lg-3">
<div class="additional-information">
<h3>What ?? </h3>
<div class="list">
<ul>
<li><Link to={'/after10th'} className="nav-link">After 10<sup>th</sup> </Link></li>
<li><Link to={'/after12th'} className="nav-link">
                        AFTER 12th
                        </Link></li>
<li><Link to={'/afterUG'} className="nav-link">
                        AFTER UG
                        </Link></li>
<li><Link to={'/afterPG'} className="nav-link">
                        AFTER PG 
                        </Link></li>

</ul>
</div>
</div>
</div>
    </div>
</div>


    </div>
  )
}

export default AfterPG