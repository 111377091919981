import React from "react";

export default function FeeStructure() {
  return (
    <div>
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-md-12">
            <p>
              <span style={{ fontSize: "18pt" }}>
                <strong>Fee Structure</strong>
              </span>
            </p>

            <p>
              <strong>Fee Structure Details:</strong>
            </p>
            <table
              className="table table-bordered"
              style={{ borderCollapse: "collapse", width: "100%", height: 173 }}
              border={1}
            >
              <tbody>
                <tr style={{ height: 20 }}>
                  <td>
                    <strong>Sr No</strong>
                  </td>
                  <td>
                    <strong>Course Name</strong>
                  </td>
                  <td>
                    <strong>Tution Fees</strong>
                  </td>
                  <td>
                    <strong>Exam Fees</strong>
                  </td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td>1</td>
                  <td>LLB</td>
                  <td>25,000/-</td>
                  <td>2,700/- </td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td>2</td>
                  <td>LLM</td>
                  <td>29,000/-</td>
                  <td>3,200/-</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
