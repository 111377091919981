import React from "react";

export default function DedicatedCovidHealthCareCentre() {
  return (
    <div>
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-md-12">
            <p>
              <span style={{ fontSize: "18pt" }}>
                <strong>Dedicated Covid Healthcare Centre</strong>
              </span>
            </p>

            <object
              height={1000}
              data="/assets/ayurveda/documents/covid-centre-order.pdf"
              type="application/pdf"
              width="100%"
            >
              <p>
                It appears you don't have a PDF plugin for this browser. No
                biggie... you can{" "}
                <a href="/assets/ayurveda/documents/covid-centre-order.pdf">
                  click here to download the PDF file.
                </a>
              </p>
            </object>
            
          </div>
        </div>
      </div>
    </div>
  );
}
