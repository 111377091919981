import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "./../../Router/RouterConfig";
import { profileData } from "./../../Pages/About/AutoritiesOfUniversity/ProfileData";
import "./Navbar.scss";
import { ADD_COMMITTEES } from "../../Utils/InfoUploadingConst";

function Navbar() {
  const navigate = useNavigate();

  const changeDir = (i) => {
    navigate(ROUTES.Profile, { state: { data: i } });
  };

  const [info, setInfo] = useState([]);

  const getData = async () => {
    const config = {
      method: "get",
      url: ADD_COMMITTEES,
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        res.data.data.forEach((element) => {
          element.attachment = JSON.parse(element.attachment);
        });
        res.data.data.sort((a, b) => b.id - a.id);
        const activeData = res.data.data.filter(
          (element) =>
            element.status === "ACTIVE" && element.college_id === 1111012
        );

        setInfo(activeData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="Navbar d-print-none">
      <>
        <div className="top-header-area">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-lg-4 col-md-4">
                <div className="header-left-content">
                  <marquee className="scroll-text text-white d-flex align-items-center">
                    Latest Updates: &nbsp;&nbsp;&nbsp;
                    <span className="ri-arrow-right-circle-line mr-2"></span>
                    &nbsp;&nbsp;
                    <a
                      href="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/Selected+List+PhD+24-25+(Batch+1).pdf"
                      target="_blank"
                      className="text-white mr-3"
                    >
                      PHD BATCH 1 24-25 SELECTED CANDIDATES LIST
                      <span className="blink text-primary"> (New) </span>
                    </a>
                    <span className="ri-arrow-right-circle-line mr-2"></span>
                    &nbsp;&nbsp;
                    <a
                      href="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/PhD+Batch+1+24-25+Eligible+list.pdf"
                      target="_blank"
                      className="text-white mr-3"
                    >
                      Ph.D. BATCH I 24-25 ELIGIBLE LIST
                      <span className="blink text-primary"> (New) </span>
                    </a>
                    &nbsp;&nbsp;&nbsp;
                    <span className="ri-arrow-right-circle-line mr-2"></span>
                    &nbsp;&nbsp;
                    <a
                      href="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/PhD+Batch+1+24-25+Eligible+list.pdf"
                      target="_blank"
                      className="text-white mr-3"
                    >
                      Ph.D. ENTERNCE TEST SYLLABUS
                      <span className="blink text-primary"> (New) </span>
                    </a>
                    &nbsp;&nbsp;&nbsp;
                  </marquee>
                </div>
              </div>
              <div className="col-lg-8 col-md-8">
                <div className="header-right-content">
                  <div className="list">
                    <ul>
                      {/* <li>
                  <Link to={'ExamTimeTable'} target="_blank" className="nav-link">
                          Univeristy Exam Time Table <span className="blink text-warning"> New</span>{" "}
                        </Link>
                </li> */}

                      <li className="nav-item">
                        <Link to={"/Nep"} className="nav-link">
                          NEP 2020
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link to={"/Media"} className="nav-link">
                          Media Coverage
                        </Link>
                      </li>
                      <li>
                        <a href="#">Research</a>
                      </li>
                      <li>
                        <Link to={"/nad"}>ABC Program</Link>
                      </li>
                      <li>
                        <a
                          href="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/abc/NAD+for+Website.pdf"
                          target="_blank"
                        >
                          NAD
                        </a>
                      </li>

                      <li>
                        <Link to={"/Recruitment"} className="nav-link">
                          Recruitment
                        </Link>
                      </li>
                      <li className="nav-item">
                        <a
                          href="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/Brochure_2024-25+Final.pdf"
                          target="_blank"
                        >
                          Brochure
                        </a>
                      </li>
                      <li>
                        <a href="tel:1800-843-2900">Admission Helpline</a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-bs-toggle="modal"
                          data-bs-target="#sidebarModal"
                        >
                          Contact us
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://ums.swaminarayanuniversity.ac.in/"
                          target="_blank"
                        >
                          UMS Login
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="navbar-area nav-bg-1">
          <div className="mobile-responsive-nav">
            <div className="container">
              <div className="mobile-responsive-menu">
                <div className="logo">
                  <a href="/">
                    <img
                      src="/assets/images/logo.png"
                      className="main-logo"
                      lt="logo"
                    />
                    <img
                      src="/assets/images/white-logo.png"
                      className="white-logo"
                      alt="logo"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="desktop-nav">
            <div className="container-fluid">
              <nav className="navbar navbar-expand-md navbar-light">
                <a className="navbar-brand" href="/">
                  <img
                    src="/assets/images/white-logo.png"
                    width="250px"
                    alt="logo"
                  />
                </a>
                <div
                  className="collapse navbar-collapse mean-menu"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav ms-auto">
                    <li className="nav-item">
                      <a href="/" className="nav-link active">
                        Home
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="#" className="nav-link dropdown-toggle">
                        About Us
                      </a>
                      <ul className="dropdown-menu">
                        <li className="nav-item">
                          <a href="#" className="nav-link dropdown-toggle">
                            Swaminarayan University
                          </a>
                          <ul className="dropdown-menu">
                            <li className="nav-item">
                              <Link to={"/About"} className="nav-link">
                                {" "}
                                About Swaminarayan University
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link to={"/MissionVision"} className="nav-link">
                                Vision &amp; Mission
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link to={"/Objectives"} className="nav-link">
                                Objectives
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link to={"/Milestones"} className="nav-link">
                                {" "}
                                Milestones
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link to={"/SankalpVision"} className="nav-link">
                                SANKALP-VISION - 2030
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link to={"/Chart"} className="nav-link">
                                Organizational Structure
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link to={"/Act"} className="nav-link">
                                Act, Statutes &amp; Ordinances
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link to={"/Approval"} className="nav-link">
                                Approvals &amp; Recognitions
                              </Link>
                            </li>

                            <li className="nav-item">
                              <a
                                href="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/UGC+Reduced.pdf#toolbar=0"
                                target="_blank"
                              >
                                Information Submitted to UGC
                              </a>
                              {/* <Link to={'/ugcInformation'} className="nav-link">
                        Information Submitted to UGC
                        </Link> */}
                            </li>
                            {/*<li class="nav-item"><a href="#" target="_blank" class="nav-link">MOUs</a></li>*/}
                          </ul>
                        </li>

                        <li className="nav-item">
                          <a href="#" className="nav-link dropdown-toggle">
                            Authorities of University
                          </a>
                          <ul className="dropdown-menu">
                            <li className="nav-item">
                              <Link to={"/GoverningBody"} className="nav-link">
                                {" "}
                                Governing Body
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                to={"/Board-Of-Management"}
                                className="nav-link"
                              >
                                Board of Management
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                to={"/AcademicCouncil"}
                                className="nav-link"
                              >
                                Academic Council
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link to={"/BoardOfStudies"} className="nav-link">
                                Board of Studies
                              </Link>
                            </li>
                          </ul>
                        </li>
                        <li className="nav-item">
                          <a href="#" className="nav-link dropdown-toggle">
                            Key Management
                          </a>
                          <ul className="dropdown-menu">
                            <li className="nav-item">
                              <div
                                onClick={() => {
                                  changeDir(profileData[0]);
                                }}
                                className="nav-link custom-div-link"
                              >
                                President
                              </div>
                            </li>
                            <li className="nav-item">
                              <Link to={"/VicePresidents"} className="nav-link">
                                Vice Presidents
                              </Link>
                            </li>
                            <li className="nav-item">
                              <div
                                onClick={() => {
                                  changeDir(profileData[4]);
                                }}
                                className="nav-link custom-div-link"
                              >
                                Provost (Vice-Chancellor)
                              </div>
                            </li>
                            <li className="nav-item">
                              <div
                                onClick={() => {
                                  changeDir(profileData[7]);
                                }}
                                className="nav-link custom-div-link"
                              >
                                Registrar
                              </div>
                            </li>
                            <li className="nav-item">
                              <div
                                onClick={() => {
                                  changeDir(profileData[6]);
                                }}
                                className="nav-link custom-div-link"
                              >
                                Advisor
                              </div>
                            </li>
                            <li className="nav-item">
                              <Link to={"/Deans"} className="nav-link">
                                Deans
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                to={"/OtherUniversityOfficers"}
                                className="nav-link"
                              >
                                Other University Officers
                              </Link>
                            </li>
                          </ul>
                        </li>
                        <li className="nav-item">
                          <Link to={"/Ombudsperson"} className="nav-link">
                            Ombudsperson
                          </Link>
                        </li>
                      </ul>
                    </li>

                    <li className="nav-item">
                      <a href="#" className="nav-link dropdown-toggle">
                        Program 2024-25
                      </a>
                      <ul className="dropdown-menu">
                        <li className="nav-item">
                          <Link to={"/Graduate"} className="nav-link">
                            Graduate
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to={"/PostGraduate"} className="nav-link">
                            Postgraduate
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to={"/PhdSubjects"} className="nav-link">
                            Ph.D Subjects
                          </Link>
                        </li>
                        <li className="nav-item">
                          <a href="#" className="nav-link dropdown-toggle">
                            Doctoral (Ph.D)
                          </a>
                          <ul className="dropdown-menu">
                            <li className="nav-item">
                              <Link to={"/AboutPrograms"} className="nav-link">
                                {" "}
                                About Ph.D Programmes &amp; Eligiblity
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                to={"/AcademicRegulations"}
                                className="nav-link"
                              >
                                Academics Regulations
                              </Link>
                            </li>
                            {/* <li className="nav-item">
                              <Link to={"/PhdSubjects"} className="nav-link">
                                Ph.D Subjects
                              </Link>
                            </li> */}
                            <li className="nav-item">
                              <Link
                                to={"/PhdNotifications"}
                                className="nav-link"
                              >
                                Ph.D Notifications
                              </Link>
                            </li>
                            <li class="nav-item">
                              <Link
                                to={"/PhdAdmissionForm"}
                                className="nav-link"
                              >
                                Application for Ph.D
                              </Link>
                            </li>
                          </ul>
                        </li>
                        <li className="nav-item">
                          <Link to={"/Diploma"} className="nav-link">
                            Diploma
                          </Link>
                        </li>
                        {/*<li class="nav-item">*/}
                        {/*<a href="#" class="nav-link">Integrated</a>*/}
                        {/*</li>*/}
                        <li className="nav-item">
                          <Link to={"/Certificate"} className="nav-link">
                            Certificate
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item">
                      <a href="#" className="nav-link dropdown-toggle">
                        Admission
                      </a>
                      <ul className="dropdown-menu">
                        <li className="nav-item">
                          <a href="#" className="nav-link dropdown-toggle">
                            Programmes
                          </a>
                          <ul className="dropdown-menu">
                            <li className="nav-item">
                              <Link to={"/after10th"} className="nav-link">
                                {" "}
                                AFTER 10th
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link to={"/after12th"} className="nav-link">
                                AFTER 12th
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link to={"/afterUG"} className="nav-link">
                                AFTER UG
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link to={"/afterPG"} className="nav-link">
                                AFTER PG
                              </Link>
                            </li>
                            {/*<li class="nav-item"><a href="#" class="nav-link">Application for Ph.D</a></li>*/}
                          </ul>
                        </li>
                        <li className="nav-item">
                          <a
                            href="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/Brochure_2024-25+Final.pdf"
                            target="_blank"
                            className="nav-link"
                          >
                            Brochure
                          </a>
                        </li>

                        {/* <li className="nav-item">
                    <a href="#" className="nav-link">
                      Admission Criteria{" "}
                    </a>
                  </li> */}
                        {/* <li className="nav-item">
                  <Link
                      to="/admissionForms"
                      className="nav-link"
                    
                    >
                      Admission Forms
                    </Link>
                  </li> */}
                        {/* <li className="nav-item">
                    <a href="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/Swaminarayan+University+website+admission+(1).pdf" target='_blank' className="nav-link">
                      Admission Flow
                    </a>
                  </li> */}

                        <li className="nav-item">
                          <Link to={"/Transportation"} className="nav-link">
                            Transportation
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to={"/ResidentialFacilities"}
                            className="nav-link"
                          >
                            Hostel Facilities
                          </Link>
                        </li>
                        {/* <li className="nav-item">
                    <a href="#" className="nav-link">
                      Scholarship
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#" className="nav-link">
                      Education Loan Assistance
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#" className="nav-link">
                      How to Apply
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#" className="nav-link">
                      Admission Offices
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#" className="nav-link">
                      Payment Procedure
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#" className="nav-link">
                      Refund Policy
                    </a>
                  </li> */}
                        {/*<li class="nav-item"><a href="#" class="nav-link">Lateral Entry/Migration</a></li>*/}

                        {/* <li className="nav-item">
                    <a href="#" className="nav-link">
                      FAQs
                    </a>
                  </li> */}
                      </ul>
                    </li>
                    <li className="nav-item">
                      <a href="#" className="nav-link dropdown-toggle">
                        Faculties
                      </a>
                      <ul className="dropdown-menu">
                        <li className="nav-item">
                          <a
                            // href="http://degree.ssvgkalol.com/"
                            href="/engineering"
                            className="nav-link"
                            target="_blank"
                          >
                            Faculty of Engineering &amp; Technology
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            // href="http://degree.ssvgkalol.com/"
                            href="https://www.swaminarayanuniversity.ac.in/sims"
                            className="nav-link"
                            target="_blank"
                          >
                            Faculty of Medicine
                          </a>
                        </li>
                        <li className="nav-item">
                          <Link to="/ayurveda" className="nav-link">
                            Faculty of Ayurveda
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to="/law" className="nav-link">
                            Faculty of Law
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to="/nursing" className="nav-link">
                            Faculty of Nursing
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to="/arts" className="nav-link">
                            Faculty of Arts and Humanities
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to="/commerce" className="nav-link">
                            Faculty of Commerce and Management
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="/Homoeopathy"
                            className="nav-link"
                            target="_blank"
                          >
                            Faculty of Homoeopathy
                          </Link>
                        </li>
                        <li className="nav-item">
                          <a
                            // href="http://pharmacy.ssvgkalol.com/"
                            href="pharmacy"
                            className="nav-link"
                            target="_blank"
                          >
                            Faculty of Pharmacy
                          </a>
                        </li>
                        <li className="nav-item">
                          <Link to="/it-cs" className="nav-link">
                            Faculty of IT and Computer Science
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to="/education" className="nav-link">
                            Faculty of Education
                          </Link>
                        </li>
                        <li className="nav-item">
                          <a
                            // href="http://ssvgkalol.com/shree-swaminarayan-science-college/"
                            href="/science"
                            className="nav-link"
                            target="_blank"
                          >
                            Faculty of Science
                          </a>
                        </li>
                        <li className="nav-item">
                          <Link to="/Physiotherapy">
                            Faculty of Physiotherapy
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to="/Design">
                            Faculty of Design and Communication
                          </Link>
                        </li>
                        <li className="nav-item">
                          <a href="#" className="nav-link" target="_blank">
                            Faculty of Paramedical Sciences
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item">
                      <a href="#" className="nav-link dropdown-toggle">
                        Examination Center
                      </a>
                      <ul className="dropdown-menu">
                        <li className="nav-item">
                          <Link to={"/Notifications"} className="nav-link">
                            Notifications
                          </Link>
                        </li>
                        <li className="nav-item">
                          <a
                            target="_blank"
                            href="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/examination/SU-+Notification-2024-391-+Revised+Examination+fee+tariff+submitted+by+CoE+(2).pdf"
                            className="nav-link"
                          >
                            Fees
                          </a>
                        </li>
                        <li className="nav-item">
                          <Link to={"/ExamTimeTable"} className="nav-link">
                            Time Table
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to={"/results"} className="nav-link">
                            Results
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to={"/Results-eng"} className="nav-link">
                            Engineering Results
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to={"/Results-pharmacy"} className="nav-link">
                            Pharmacy Results
                          </Link>
                        </li>

                        <li className="nav-item">
                          <Link to={"/reExamResults"} className="nav-link">
                            Re-Exam Results
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to={"/prevresults"} className="nav-link">
                            Previous Results
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to={"/prevYearQuestionPapers"}
                            className="nav-link"
                          >
                            Previous Year Question Papers
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item">
                      <a href="#" className="nav-link dropdown-toggle">
                        Students Corner
                      </a>
                      <ul className="dropdown-menu">
                        <li className="nav-item">
                          <Link
                            to={"/prevention-of-caste-based-discrimination"}
                            className="nav-link"
                          >
                            Prevention of Caste-based Discrimination
                          </Link>
                        </li>
                        <li className="nav-item">
                          <a href="#" className="nav-link dropdown-toggle">
                            Committees
                          </a>
                          <ul className="dropdown-menu">
                            {info.filter((d) => d.type === "College Council")
                              .length !== 0 ? (
                              info
                                .filter((d) => d.type === "College Council")
                                .map((item, index) => (
                                  <li className="nav-item" key={index}>
                                    <a
                                      href={item?.attachment}
                                      target="_blank"
                                      className="nav-link"
                                    >
                                      College Council
                                    </a>
                                  </li>
                                ))
                            ) : (
                              <li className="nav-item">
                                <a href="" target="_blank" className="nav-link">
                                  College Council
                                </a>
                              </li>
                            )}

                            {info.filter(
                              (d) => d.type === "Internal Complaints Committee"
                            ).length !== 0 ? (
                              info
                                .filter(
                                  (d) =>
                                    d.type === "Internal Complaints Committee"
                                )
                                ?.map((item, index) => (
                                  <li className="nav-item" key={index}>
                                    <a
                                      href={item?.attachment}
                                      target="_blank"
                                      className="nav-link"
                                    >
                                      Internal Complaints Committee
                                    </a>
                                  </li>
                                ))
                            ) : (
                              <li className="nav-item">
                                <a href="" target="_blank" className="nav-link">
                                  Internal Complaint Committee
                                </a>
                              </li>
                            )}

                            {info.filter(
                              (d) => d.type === "Anti-Ragging Committee"
                            ).length !== 0 ? (
                              info
                                .filter(
                                  (d) => d.type === "Anti-Ragging Committee"
                                )
                                ?.map((item, index) => (
                                  <li className="nav-item" key={index}>
                                    <a
                                      href={item?.attachment}
                                      target="_blank"
                                      className="nav-link"
                                    >
                                      Anti-Ragging Committee
                                    </a>
                                  </li>
                                ))
                            ) : (
                              <li className="nav-item">
                                <a href="" target="_blank" className="nav-link">
                                  Anti-Ragging Committee
                                </a>
                              </li>
                            )}

                            {info.filter((d) => d.type === "Ethics Committee")
                              .length !== 0 ? (
                              info
                                .filter((d) => d.type === "Ethics Committee")
                                ?.map((item, index) => (
                                  <li className="nav-item" key={index}>
                                    <a
                                      href={item?.attachment}
                                      target="_blank"
                                      className="nav-link"
                                    >
                                      Ethics Committee
                                    </a>
                                  </li>
                                ))
                            ) : (
                              <li className="nav-item">
                                <a href="" target="_blank" className="nav-link">
                                  Ethics Committee
                                </a>
                              </li>
                            )}

                            {info.filter(
                              (d) => d.type === "Medical Education Unit"
                            ).length !== 0 ? (
                              info
                                .filter(
                                  (d) => d.type === "Medical Education Unit"
                                )
                                ?.map((item, index) => (
                                  <li className="nav-item" key={index}>
                                    <a
                                      href={item?.attachment}
                                      target="_blank"
                                      className="nav-link"
                                    >
                                      Medical Education Unit
                                    </a>
                                  </li>
                                ))
                            ) : (
                              <li className="nav-item">
                                <a href="" target="_blank" className="nav-link">
                                  Medical Education Unit
                                </a>
                              </li>
                            )}

                            {info.filter(
                              (d) => d.type === "Curriculum Committee"
                            ).length !== 0 ? (
                              info
                                .filter(
                                  (d) => d.type === "Curriculum Committee"
                                )
                                ?.map((item, index) => (
                                  <li className="nav-item" key={index}>
                                    <a
                                      href={item?.attachment}
                                      target="_blank"
                                      className="nav-link"
                                    >
                                      Curriculum Committee
                                    </a>
                                  </li>
                                ))
                            ) : (
                              <li className="nav-item">
                                <a href="" target="_blank" className="nav-link">
                                  Curriculum Committee
                                </a>
                              </li>
                            )}

                            {info.filter(
                              (d) =>
                                d.type ===
                                "Hospital Infection Prevention & Control Committee"
                            ).length !== 0 ? (
                              info
                                .filter(
                                  (d) =>
                                    d.type ===
                                    "Hospital Infection Prevention & Control Committee"
                                )
                                ?.map((item, index) => (
                                  <li className="nav-item" key={index}>
                                    <a
                                      href={item?.attachment}
                                      target="_blank"
                                      className="nav-link"
                                    >
                                      Hospital Infection Prevention & Control
                                      Committee
                                    </a>
                                  </li>
                                ))
                            ) : (
                              <li className="nav-item">
                                <a href="" target="_blank" className="nav-link">
                                  Hospital Infection Prevention & Control
                                  Committee
                                </a>
                              </li>
                            )}

                            {info.filter(
                              (d) => d.type === "Pharmacovigilance Committee"
                            ).length !== 0 ? (
                              info
                                .filter(
                                  (d) =>
                                    d.type === "Pharmacovigilance Committee"
                                )
                                ?.map((item, index) => (
                                  <li className="nav-item" key={index}>
                                    <a
                                      href={item?.attachment}
                                      target="_blank"
                                      className="nav-link"
                                    >
                                      Pharmacovigilance Committee
                                    </a>
                                  </li>
                                ))
                            ) : (
                              <li className="nav-item">
                                <a href="" target="_blank" className="nav-link">
                                  Pharmacovigilance Committee
                                </a>
                              </li>
                            )}
                          </ul>
                        </li>
                      </ul>
                    </li>

                    <li className="nav-item">
                      <a href="#" className="nav-link dropdown-toggle">
                        Campus Facilities
                      </a>
                      <ul className="dropdown-menu">
                        <li className="nav-item">
                          <Link to={"/Infrastructure"} className="nav-link">
                            Infrastructure
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to={"/AcademicFacilities"} className="nav-link">
                            Academic Facilities
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to={"/CampusFacilities"} className="nav-link">
                            Campus Facilities
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to={"/Transportation"} className="nav-link">
                            Transportation
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to={"/ResidentialFacilities"}
                            className="nav-link"
                          >
                            Residential Facilities
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to={"/Library"} className="nav-link">
                            Library
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to={"/Schools"} className="nav-link">
                            Schools
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to={"/Auditorium"} className="nav-link">
                            Auditorium
                          </Link>
                        </li>
                        <li className="nav-item">
                          <a href="#" className="nav-link dropdown-toggle">
                            Academic Cells
                          </a>
                          <ul className="dropdown-menu">
                            <li className="nav-item">
                              <a href="#" className="nav-link">
                                Student Welfare
                              </a>
                            </li>
                            <li className="nav-item">
                              <a href="#" className="nav-link">
                                International Relations
                              </a>
                            </li>
                            <li className="nav-item">
                              <a href="#" className="nav-link">
                                Training &amp; Placement
                              </a>
                            </li>
                            <li className="nav-item">
                              <a href="#" className="nav-link">
                                Entrepreneurship
                              </a>
                            </li>
                            <li className="nav-item">
                              <a href="#" className="nav-link">
                                Women Development
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li className="nav-item">
                          <a href="#" className="nav-link">
                            IT Support Cell
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item">
                      <a href="#" className="nav-link dropdown-toggle">
                        International
                      </a>
                      <ul className="dropdown-menu">
                        <li className="nav-item">
                          <a href="#" className="nav-link dropdown-toggle">
                            International Admissions
                          </a>
                          <ul className="dropdown-menu">
                            <li className="nav-item">
                              <a href="#" className="nav-link">
                                {" "}
                                Why Choose Swaminarayan University
                              </a>
                            </li>
                            <li className="nav-item">
                              <a href="#" className="nav-link">
                                Programme Fee
                              </a>
                            </li>
                            <li className="nav-item">
                              <a className="nav-link" href="#">
                                Accommodation Fee
                              </a>
                            </li>
                            <li className="nav-item">
                              <a href="#" className="nav-link">
                                How to apply
                              </a>
                            </li>
                            <li className="nav-item">
                              <a href="#" className="nav-link">
                                Admission Rules
                              </a>
                            </li>
                            <li className="nav-item">
                              <a href="#" className="nav-link">
                                Payment Procedure
                              </a>
                            </li>
                            <li className="nav-item">
                              <a href="#" className="nav-link">
                                Scholarship
                              </a>
                            </li>
                            <li className="nav-item">
                              <a href="#" className="nav-link">
                                FAQs
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li className="nav-item">
                          <a href="#" className="nav-link dropdown-toggle">
                            Student Information
                          </a>
                          <ul className="dropdown-menu">
                            <li className="nav-item">
                              <a href="#" className="nav-link">
                                Student Notification
                              </a>
                            </li>
                            <li className="nav-item">
                              <a href="#" className="nav-link">
                                Pre-Arrival Information
                              </a>
                            </li>
                            <li className="nav-item">
                              <a href="#" className="nav-link">
                                Post-Arrival Information
                              </a>
                            </li>
                            <li className="nav-item">
                              <a href="#" className="nav-link">
                                Guidelines for Visa and FRO/FRRO
                              </a>
                            </li>
                            <li className="nav-item">
                              <a href="#" className="nav-link">
                                Guidelines for Medical Insurance
                              </a>
                            </li>
                            <li className="nav-item">
                              <a href="#" className="nav-link">
                                Examination Instructions
                              </a>
                            </li>
                            <li className="nav-item">
                              <a href="#" className="nav-link">
                                Hostel Rules Manual
                              </a>
                            </li>
                            <li className="nav-item">
                              <a href="#" className="nav-link">
                                FAQs
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li className="nav-item">
                          <a href="#" className="nav-link dropdown-toggle">
                            Study Abroad
                          </a>
                          <ul className="dropdown-menu">
                            <li className="nav-item">
                              <a href="#" className="nav-link">
                                Overview
                              </a>
                            </li>
                            <li className="nav-item">
                              <a href="#" className="nav-link">
                                Programs
                              </a>
                            </li>
                            <li className="nav-item">
                              <a href="#" className="nav-link">
                                Application Procedure
                              </a>
                            </li>
                            <li className="nav-item">
                              <a href="#" className="nav-link">
                                International Tie-ups
                              </a>
                            </li>
                            <li className="nav-item">
                              <a href="#" className="nav-link">
                                Newsletter
                              </a>
                            </li>
                            <li className="nav-item">
                              <a href="#" className="nav-link">
                                Study Abroad Office
                              </a>
                            </li>
                            <li className="nav-item">
                              <a href="#" className="nav-link">
                                FAQs
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>

                    <li className="nav-item">
                      <a href="#" className="nav-link dropdown-toggle">
                        Connect
                      </a>
                      <ul className="dropdown-menu">
                        <li className="nav-item">
                          <a href="#" className="nav-link dropdown-toggle">
                            What's Happening
                          </a>
                          <ul className="dropdown-menu">
                            <li className="nav-item">
                              <Link
                                to="/universityNotifications"
                                className="nav-link"
                              >
                                {" "}
                                University Notifications
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link to="/EventCalendar" className="nav-link">
                                {" "}
                                Event Calendar
                              </Link>
                            </li>

                            <li className="nav-item">
                              <Link to="/Events" className="nav-link">
                                {" "}
                                Events
                              </Link>
                            </li>
                            <li className="nav-item">
                              <a href="#" className="nav-link">
                                Announcements
                              </a>
                            </li>
                            <li className="nav-item">
                              <Link to="/News" className="nav-link">
                                {" "}
                                New
                              </Link>
                            </li>
                            <li className="nav-item">
                              <a href="#" className="nav-link">
                                Distinctive Features
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li className="nav-item">
                          <a href="#" className="nav-link dropdown-toggle">
                            Downloads
                          </a>
                          <ul className="dropdown-menu">
                            <li className="nav-item">
                              <a
                                href="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/SU+Brochure+2023.pdf"
                                target="_blank"
                                className="nav-link"
                              >
                                Brochure
                              </a>
                            </li>
                            <li className="nav-item">
                              <a href="#" className="nav-link">
                                Registration Form
                              </a>
                            </li>
                            <li className="nav-item">
                              <a href="#" className="nav-link">
                                Guidelines
                              </a>
                            </li>
                            <li className="nav-item">
                              <a href="#" className="nav-link">
                                Academic Progression Rule, 2022-23
                              </a>
                            </li>
                            <li className="nav-item">
                              <a href="#" className="nav-link">
                                Newsletter
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li className="nav-item">
                          <a href="#" className="nav-link dropdown-toggle">
                            Alumini
                          </a>
                          <ul className="dropdown-menu">
                            <li className="nav-item">
                              <a href="#" className="nav-link">
                                Alumni Connect
                              </a>
                            </li>
                            <li className="nav-item">
                              <a href="#" className="nav-link">
                                Alumni Overview
                              </a>
                            </li>
                            <li className="nav-item">
                              <a href="#" className="nav-link">
                                Become a Mentor
                              </a>
                            </li>
                            <li className="nav-item">
                              <a href="#" className="nav-link">
                                Student Database
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li className="nav-item">
                          {/* <a
                            href="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/media/undefined_1723102624732.pdf"
                            target="_blank"
                            className="nav-link"
                          >
                            IDP
                          </a> */}
                          <Link to={"/IDP"} className="nav-link">
                            {/* IDP (Institutional Development Plan) */}
                            IDP
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to={"/Prospectus"} className="nav-link">
                            Prospectus
                          </Link>
                        </li>
                      </ul>
                    </li>
                    {/* <li className="nav-item">
                <a href="#" className="nav-link dropdown-toggle">
                  International
                </a>
                <ul className="dropdown-menu">
                  <li className="nav-item">
                    <a href="#" className="nav-link dropdown-toggle">
                      International Admissions
                    </a>
                    <ul className="dropdown-menu">
                      <li className="nav-item">
                        <a href="#" className="nav-link">
                          {" "}
                          Why Choose Swaminarayan University
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="#" className="nav-link">
                          Programme Fee
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="#">
                          Accommodation Fee
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="#" className="nav-link">
                          How to apply
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="#" className="nav-link">
                          Admission Rules
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="#" className="nav-link">
                          Payment Procedure
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="#" className="nav-link">
                          Scholarship
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="#" className="nav-link">
                          FAQs
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item">
                    <a href="#" className="nav-link dropdown-toggle">
                      Student Information
                    </a>
                    <ul className="dropdown-menu">
                      <li className="nav-item">
                        <a href="#" className="nav-link">
                          Student Notification
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="#" className="nav-link">
                          Pre-Arrival Information
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="#" className="nav-link">
                          Post-Arrival Information
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="#" className="nav-link">
                          Guidelines for Visa and FRO/FRRO
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="#" className="nav-link">
                          Guidelines for Medical Insurance
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="#" className="nav-link">
                          Examination Instructions
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="#" className="nav-link">
                          Hostel Rules Manual
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="#" className="nav-link">
                          FAQs
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item">
                    <a href="#" className="nav-link dropdown-toggle">
                      Study Abroad
                    </a>
                    <ul className="dropdown-menu">
                      <li className="nav-item">
                        <a href="#" className="nav-link">
                          Overview
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="#" className="nav-link">
                          Programs
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="#" className="nav-link">
                          Application Procedure
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="#" className="nav-link">
                          International Tie-ups
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="#" className="nav-link">
                          Newsletter
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="#" className="nav-link">
                          Study Abroad Office
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="#" className="nav-link">
                          FAQs
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <a href="#" className="nav-link dropdown-toggle">
                  IQAC
                </a>
                <ul className="dropdown-menu">
                  <li className="nav-item">
                    <a href="#" className="nav-link">
                      About IQAC
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#" className="nav-link">
                      Minutes of Meetings
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#" className="nav-link">
                      Report of Achievements
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#" className="nav-link">
                      IIQA
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#" className="nav-link">
                      SSR - Self Study Report
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#" className="nav-link">
                      Best Practices
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#" className="nav-link">
                      Feedback Analysis
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#" className="nav-link">
                      Institutional Distinguishment
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#" className="nav-link dropdown-toggle">
                      University Policies
                    </a>
                    <ul className="dropdown-menu">
                      <li className="nav-item">
                        <a href="#" className="nav-link">
                          NIRF
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="#" className="nav-link">
                          Related Links
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="#" className="nav-link">
                          Notifications
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="#" className="nav-link">
                          Academic Cells
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="#" className="nav-link">
                          Recognitions &amp; Accreditation
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="#" className="nav-link">
                          Awards and Achievements
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item">
                    <a href="#" className="nav-link">
                      IT Support Cell
                    </a>
                  </li>
                </ul>
              </li> */}
                    {/* <li className="nav-item">
                <a href="https://ums.swaminarayanuniversity.ac.in/" target="_blank" className="nav-link">
                  UMS Login
                </a>
              </li> */}
                  </ul>
                  <div className="others-options">
                    <div className="icon">
                      <i
                        className="ri-menu-3-fill"
                        data-bs-toggle="modal"
                        data-bs-target="#sidebarModal"
                      />
                    </div>
                  </div>
                </div>
              </nav>
            </div>
          </div>
          <div className="others-option-for-responsive">
            <div className="container">
              <div className="dot-menu">
                <div className="inner">
                  <div className="icon">
                    <i
                      className="ri-menu-3-fill"
                      data-bs-toggle="modal"
                      data-bs-target="#sidebarModal"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="sidebarModal modal right fade"
          id="sidebarModal"
          tabIndex={-1}
          role="dialog"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <button type="button" className="close" data-bs-dismiss="modal">
                <i className="ri-close-line" />
              </button>
              <div className="modal-body">
                <Link to={"/"}>
                  <img
                    src="/assets/images/logo.png"
                    className="main-logo"
                    alt="logo"
                  />
                  <img
                    src="/assets/images/white-logo.png"
                    className="white-logo"
                    alt="logo"
                  />
                </Link>
                <div className="sidebar-content">
                  <h3>About Us</h3>
                  <p>
                    Shastri Swami Premswaroopdasji,a follower of Bhakti and Seva
                    is the prime founder of Shree Swaminarayan Vishvamangal
                    Gurukul, Kalol. Swamiji Started his seva as a saint in the
                    Swaminarayan Mandir located at Bhuj. Swamiji spent a period
                    of 20 years in Satsang at the place.
                    <span id="dots">...</span>
                    <span id="more" style={{ display: "none" }}>
                      <br />
                      <br />
                      One Gujarati proverb says “Sadhu to Chalta Bhala”. Swamiji
                      left swaminarayan Mandir, Bhuj and arrived at Swaminarayan
                      Mandir, Kalupur in 1985 where he arranged Satsang Khathas
                      in the surrounding towns and villages. By humble order of
                      Acharya Maharajshri, Swamiji accepted the services as the
                      Mahant(Head) of Swaminarayan Mandir, situated at Siddhpur
                      and stayed over there for the place Kalol, an entrance of
                      North Gujarat Region and acquired a piece of barren land
                      and dreamed to establish a gurukul providing education,
                      shelter to helpless people and a Goshala.
                      <br />
                      <br />
                      The first foundation stone was laid down by Param Pujya
                      Dharm Dhurandhar 1008 Acharyashri Tejendraprasadji in the
                      year 1993. Afterwards Swamiji continued Satsang, Kathas
                      and religious meetings in the nearby Towns and Villages.A
                      school was started in the year 1995, with primary first
                      and then secondary, Higher Secondary, general section,
                      Science section and school was flourished.
                      <br />
                      <br />
                      Swamiji then started B.Ed, PTC, Degree, Pharmacy, Diploma
                      Engineering and Degree Engineering College and M.E. at
                      Kalol Gurukul campus. In 2012, a completely modernized
                      Shree Swaminarayan International School was Shunya(Zero),
                      a new world of temples of education was created by this
                      visionary and missionary Sadhu. The temples which has no
                      boundary of religion and caste, where only Goddess Maa
                      Saraswati is worshiped.
                      <br />
                      <br />
                      Best,
                      <br />
                      <br />
                      <b>
                        Shastri Swami Premswarupdasji
                        <br />
                        Managing Trustee
                      </b>
                    </span>
                  </p>
                  <Link to={"/About"}>
                    <div className="sidebar-btn">
                      <button
                        onclick="myFunction()"
                        id="myBtn"
                        className="default-btn"
                      >
                        Read more
                      </button>
                    </div>
                  </Link>
                </div>
                <div className="sidebar-contact-info">
                  <h3>India Contact Information</h3>
                  <ul className="info-list">
                    <li>
                      <i className="ri-phone-fill" />{" "}
                      <a href="tel:+916357675561">+91 6357675561</a>
                    </li>
                    <li>
                      <i className="ri-phone-fill" />{" "}
                      <a href="tel:18008432900">1800-843-2900</a>
                    </li>
                    <li>
                      <i className="ri-mail-line" />
                      <a href="mailto:admission@swaminarayanuniversity.ac.in">
                        admission@swaminarayanuniversity.ac.in
                      </a>
                    </li>
                    <li>
                      <i className="ri-map-pin-line" />
                      <a
                        href="https://goo.gl/maps/yM5MKb2VHGw3D4QT9"
                        target="_blank"
                      >
                        Swaminarayan University,
                        <br />
                        Shree Swaminarayan Vishvamangal Gurukul,
                        <br />
                        Ahmedabad-Mehsana Highway,
                        <br /> At&amp;Po-Saij, Tal.-Kalol
                        <br /> Dist-Gandhinagar-382725,Gujarat,India.
                      </a>
                    </li>
                  </ul>
                </div>
                <ul className="sidebar-social-list">
                  <li>
                    <a href="https://www.facebook.com" target="_blank">
                      <i className="flaticon-facebook" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.twitter.com" target="_blank">
                      <i className="flaticon-twitter" />
                    </a>
                  </li>
                  <li>
                    <a href="https://linkedin.com/?lang=en" target="_blank">
                      <i className="flaticon-linkedin" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://instagram.com/?lang=en"
                      target="https://www.instagram.com/swaminarayan_university/"
                    >
                      <i className="flaticon-instagram" />
                    </a>
                  </li>
                </ul>

                <div className="sidebar-contact-info">
                  <h3>International Admissions Contact Information</h3>
                  <ul className="info-list">
                    {/* <li>
              Abdullah Alsadeg
              </li> */}
                    <li>
                      <i className="ri-phone-fill" />{" "}
                      <a href="tel:+91-9686363111">+91-9686363111</a>
                    </li>

                    <li>
                      <i className="ri-mail-line" />
                      <a href="mailto:internationalaffairs@swaminarayanuniversity.ac.in">
                        internationalaffairs@swaminarayanuniversity.ac.in
                      </a>
                    </li>
                    {/* <li>
             <b> Prof (Dr.) Priyadarshini Nambiar</b> <br/>
Director (International Relations) <br/>
Swaminarayan University
              </li> */}
                  </ul>
                </div>
                <div className="sidebar-contact-info">
                  <h3>Ph.D. Admissions Contact Information</h3>
                  <ul className="info-list">
                    <li>Dean Research (I/C): Dr. Ravikumar Patel</li>
                    <li>
                      <i className="ri-phone-fill" />{" "}
                      <a href="tel:+91-9428114678">+91-9428114678</a>
                    </li>

                    <li>
                      <i className="ri-mail-line" />
                      <a href="mailto:rd@swaminarayanuniversity.ac.in">
                        rd@swaminarayanuniversity.ac.in
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="sidebar-contact-info">
                  <h3> For HR Department Contact </h3>
                  <ul className="info-list">
                    <li>Ms. Monali Prajapati</li>
                    <li>
                      <i className="ri-phone-fill" />{" "}
                      <a href="tel:+9737001727">+91-9737001727</a>
                    </li>

                    <li>
                      <i className="ri-mail-line" />
                      <a href="mailto:office.registrar@swaminarayanuniversity.ac.in">
                        office.registrar@swaminarayanuniversity.ac.in
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="contact-form">
                  <h3>Ready to Get Started?</h3>
                  <form id="contactForm">
                    <div className="row">
                      <div className="col-lg-12 col-md-6">
                        <div className="form-group">
                          <input
                            type="text"
                            name="name"
                            className="form-control"
                            required=""
                            data-error="Please enter your name"
                            placeholder="Your name"
                          />
                          <div className="help-block with-errors" />
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-6">
                        <div className="form-group">
                          <input
                            type="email"
                            name="email"
                            className="form-control"
                            required=""
                            data-error="Please enter your email"
                            placeholder="Your email address"
                          />
                          <div className="help-block with-errors" />
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                          <input
                            type="text"
                            name="phone_number"
                            className="form-control"
                            required=""
                            data-error="Please enter your phone number"
                            placeholder="Your phone number"
                          />
                          <div className="help-block with-errors" />
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                          <textarea
                            name="message"
                            className="form-control"
                            cols={30}
                            rows={6}
                            required=""
                            data-error="Please enter your message"
                            placeholder="Write your message..."
                            defaultValue={""}
                          />
                          <div className="help-block with-errors" />
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12">
                        <button type="submit" className="default-btn">
                          Send Message
                          <span />
                        </button>
                        <div id="msgSubmit" className="h3 text-center hidden" />
                        <div className="clearfix" />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

export default Navbar;
