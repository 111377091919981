import React from 'react'
import './Graduate'

function Certificate() {
  return (
    <div className='Certificate Graduate'>
        <>
  {/* Ayurveda Modal */}
  <div
    className="modal fade"
    id="yoga"
    tabIndex={-1}
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog modal-xl">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel" />
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          />
        </div>
        <div className="modal-body">
          <div className="d-flex align-items-center">
            <img
              src="assets/images/icon/yoga.png"
              className="img-fluid modal_icon"
              alt="engineering icon"
            />
            <h2> Yoga (Ayurveda)</h2>
          </div>
          <div className="row mt-5">
            <div className="col-md-4">
              <ul>
                <li className="crs">
                  <a href="course_details.php?course_name=Yoga&course=Cerificate&intake=120&duration=6%20months&fees=6000&contact=%2B91-9449231730&adcontact=%2B91-9449231730&ademail=ssackalol@gmail.com&head=Dr. Rohit Gokarn&headname&heademail=ssackalol@gmail.com">
                    <h5>Yoga (Ayurveda) </h5>
                  </a>
                </li>
              </ul>
              <a href="course_details.php?course_name=Yoga&course=Cerificate&intake=120&duration=6%20months&fees=6000&contact=%2B91-9449231730&adcontact=%2B91-9449231730&ademail=ssackalol@gmail.com&head=Dr. Rohit Gokarn&headname&heademail=ssackalol@gmail.com"></a>
              <ul style={{ listStyle: "none" }}>
                <a href="course_details.php?course_name=Yoga&course=Cerificate&intake=120&duration=6%20months&fees=6000&contact=%2B91-9449231730&adcontact=%2B91-9449231730&ademail=ssackalol@gmail.com&head=Dr. Rohit Gokarn&headname&heademail=ssackalol@gmail.com">
                  <h5 className="text-danger mt-4">Details</h5>
                  <li className="crs">
                    <p>Course Type : Certificate</p>
                  </li>
                  <li className="crs">
                    <p>Duration of Course : 6 Months</p>
                  </li>
                  <li className="crs">
                    <p>Course Intake : 120</p>
                  </li>
                  <li className="crs">
                    <p>Fees : 6000</p>
                  </li>
                </a>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Panchakarma Modal */}
  <div
    className="modal fade"
    id="pancha"
    tabIndex={-1}
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog modal-xl">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel" />
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          />
        </div>
        <div className="modal-body">
          <div className="d-flex align-items-center">
            <img
              src="assets/images/icon/panchakarma.png"
              className="img-fluid modal_icon"
              alt="engineering icon"
            />
            <h2> Panchakarma (Ayurveda)</h2>
          </div>
          <div className="row mt-5">
            <div className="col-md-4">
              <ul>
                <li className="crs">
                  <a
                    href="course_details.php?course_name=Panchakarma%20Therapist&course=Cerificate&intake=30&contact=%2B91-9449231730&adcontact=%2B91-9449231730&ademail=ssackalol@gmail.com&head=Dr. Rohit Gokarn&headname&heademail=ssackalol@gmail.com
&fees=8000&duration=6%20months&intake=30"
                  >
                    <h5>Panchakarma (Ayurveda) </h5>
                  </a>
                </li>
              </ul>
              <a
                href="course_details.php?course_name=Panchakarma%20Therapist&course=Cerificate&intake=30&contact=%2B91-9449231730&adcontact=%2B91-9449231730&ademail=ssackalol@gmail.com&head=Dr. Rohit Gokarn&headname&heademail=ssackalol@gmail.com
&fees=8000&duration=6%20months&intake=30"
              ></a>
              <ul style={{ listStyle: "none" }}>
                <a
                  href="course_details.php?course_name=Panchakarma%20Therapist&course=Cerificate&intake=30&contact=%2B91-9449231730&adcontact=%2B91-9449231730&ademail=ssackalol@gmail.com&head=Dr. Rohit Gokarn&headname&heademail=ssackalol@gmail.com
&fees=8000&duration=6%20months&intake=30"
                >
                  <h5 className="text-danger mt-4">Details</h5>
                  <li className="crs">
                    <p>Course Type : Certificate</p>
                  </li>
                  <li className="crs">
                    <p>Duration of Course : 6 Months</p>
                  </li>
                  <li className="crs">
                    <p>Course Intake : 30</p>
                  </li>
                  <li className="crs">
                    <p>Fees : 8000</p>
                  </li>
                </a>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="description">
    <div className="container-fluid">
      <div className="register" style={{ maxWidth: "90%" }}>
        <h3>Certificate Programme 2023-24</h3>
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col-md-3">
            <div className="card mb-3">
              <div className="d-flex flex-row-reverse card-body p-2 align-items-center  ">
                <div className="p-2 w-100 ">
                  <h4>Panchakarma Therapist (Ayurveda)</h4>
                  <p>Study of Panchakarma Therapist </p>
                </div>
                <div className="p-2 flex-shrink-1 ">
                  {" "}
                  <img
                    src="assets/images/icon/panchakarma.png"
                    className="img-fluid icon "
                    alt="Panchakarma"
                  />
                </div>
              </div>
              <hr />
              <div className="d-flex justify-content-around">
                <p className="card-text">
                  <small className="text-muted ">1 Programme </small>
                </p>
                <div className=" d-flex justify-content-center align-item-center ">
                  <a href="" data-bs-toggle="modal" data-bs-target="#pancha">
                    {" "}
                    <b>
                      More Info <i className="ri-arrow-right-line" />{" "}
                    </b>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card mb-3">
              <div className="d-flex flex-row-reverse card-body p-2 align-items-center  ">
                <div className="p-2 w-100 ">
                  <h4>Yoga</h4>
                  <p>Study of Yoga </p>
                </div>
                <div className="p-2 flex-shrink-1 ">
                  {" "}
                  <img
                    src="assets/images/icon/yoga.png"
                    className="img-fluid icon "
                    alt="science"
                  />
                </div>
              </div>
              <hr />
              <div className="d-flex justify-content-around">
                <p className="card-text">
                  <small className="text-muted ">1 Programme </small>
                </p>
                <div className=" d-flex justify-content-center align-item-center ">
                  <a href="" data-bs-toggle="modal" data-bs-target="#yoga">
                    {" "}
                    <b>
                      More Info <i className="ri-arrow-right-line" />{" "}
                    </b>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</>

    </div>
  )
}

export default Certificate