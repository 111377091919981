import React from 'react'
import axios from 'axios';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { RESEARCH_PUBLICATION_GET } from '../../Utils/InfoUploadingConst';

function PharmacyWorkshopPublications() {

  const [data, setData] = useState([]);

  const navigate = useNavigate();

  const getData = async () => {
      const config = {
          method: "get",
          url: RESEARCH_PUBLICATION_GET,
          headers: {
              "Content-Type": "application/json",
          },
      };

      await axios(config)
          .then((res) => {
              res.data.data.sort((a, b) => b.id - a.id);
              res.data.data.forEach((element) => {
                  element.attachments = JSON.parse(element.attachments);
              });
              console.log(res.data.data)
              setData(res.data.data);

          })
          .catch((err) => {
              console.log(err);
          });
  };

  useEffect(() => {
      getData();
  }, []);

  const next = async (id, data1, images) => {
      console.log("error here");
      navigate(`/pharmacy/PharmacyWorkshopPublications/${id}`, {
          state: {
              images: images,
              data: data1,
          },
      });
  };

  return (
    <div>

<div className="container mt-5 mb-5">
  <div className="row">
    <div className='col-md-12'>
      <h3> Research and Publications </h3>
    </div>


    <div className="row">

    {data.map((item, key) => {
      return (
      item.status === "ACTIVE" && item.college_id == "1111008"  &&(

    <div className='col-md-6 pt-4'>   
    <div className='card border-danger shadow-reguler' key={item.id} style={{ cursor: "pointer" }}>

    <a href={item?.attachments} target='_blank'>

      <div className="card-body">    
        <h5>{item?.title}</h5>
      
      <div className="d-flex justify-content-between">    
        <p>{item?.researcher}</p>
        <p> {item?.month} - {item?.publishdate.split('-')[0]}</p>
      </div>

      </div>

    </a>

    </div>                                        
  </div>

)
)
})
}
  
  </div>
   
  </div>
  <br />
  <br />
</div>



    </div>
  )
}

export default PharmacyWorkshopPublications;



// export default function Workshop() {
//   return (
//     <div>
//       <div className="container mt-5 mb-5">
//         <div className="row">
//           <div className="col-md-12">
//             <p>
//               <strong>
//                 Conferences, Workshop and Seminar organized by Institute
//               </strong>
//               <br />
//             </p>

//             <table
//               className="table table-bordered"
//               style={{ borderCollapse: "collapse", width: "100%", height: 40 }}
//               border={1}
//             >
//               <tbody>
//                 <tr style={{ height: 20 }}>
//                   <td style={{ width: "25%", height: 20, textAlign: "center" }}>
//                     <span style={{ color: "#000000", textAlign: "center" }}>
//                       <strong>SL NO</strong>
//                     </span>
//                     <br />
//                   </td>
//                   <td style={{ width: "25%", height: 20, textAlign: "center" }}>
//                     <span style={{ color: "#000000" }}>
//                       <strong>Year</strong>
//                     </span>
//                     <br />
//                   </td>
//                   <td style={{ width: "25%", height: 20, textAlign: "center" }}>
//                     <span style={{ color: "#000000" }}>
//                       <strong>Name of the Conferences/Workshop/Seminar</strong>
//                     </span>
//                     <br />
//                   </td>
//                   <td style={{ width: "25%", height: 20, textAlign: "center" }}>
//                     <span style={{ color: "#000000" }}>
//                       <strong>Date</strong>
//                     </span>
//                     <br />
//                   </td>
//                   <td style={{ width: "25%", height: 20, textAlign: "center" }}>
//                     <span style={{ color: "#000000" }}>
//                       <strong>Venue</strong>
//                     </span>
//                     <br />
//                   </td>
//                   <td style={{ width: "25%", height: 20, textAlign: "center" }}>
//                     <span style={{ color: "#000000" }}>
//                       <strong>Sponsored By</strong>
//                     </span>
//                     <br />
//                   </td>
//                 </tr>

//                 <tr style={{ height: 20 }}>
//                   <td style={{ textAlign: "center" }}>1</td>
//                   <td style={{ textAlign: "center" }}>2022</td>
//                   <td style={{ textAlign: "center" }}>
//                     Aspects of oversea education in foreign country
//                   </td>
//                   <td style={{ textAlign: "center" }}>26/08/2022</td>
//                   <td style={{ textAlign: "center" }}>
//                     Swaminarayan college of pharmacy, saij, Kalol
//                   </td>
//                   <td style={{ textAlign: "center" }}>
//                     Swaminarayan college of pharmacy
//                   </td>
//                 </tr>

//                 <tr style={{ height: 20 }}>
//                   <td style={{ textAlign: "center" }}>2</td>
//                   <td style={{ textAlign: "center" }}>2022</td>
//                   <td style={{ textAlign: "center" }}>Women Empowerment</td>
//                   <td style={{ textAlign: "center" }}>08/08/2022</td>
//                   <td style={{ textAlign: "center" }}>
//                     Prathama hall, Swaminarayan university
//                   </td>
//                   <td style={{ textAlign: "center" }}>
//                     Swaminarayan college of pharmacySwaminarayan college of
//                     pharmacy
//                   </td>
//                 </tr>

//                 <tr style={{ height: 20 }}>
//                   <td style={{ textAlign: "center" }}>3</td>
//                   <td style={{ textAlign: "center" }}>2022</td>
//                   <td style={{ textAlign: "center" }}>
//                     Poster presentation On current affair in pharma
//                   </td>
//                   <td style={{ textAlign: "center" }}>23/09/2022</td>
//                   <td style={{ textAlign: "center" }}>
//                     Swaminarayan college of pharmacy
//                   </td>
//                   <td style={{ textAlign: "center" }}>
//                     Swaminarayan college of pharmacy
//                   </td>
//                 </tr>

//                 <tr style={{ height: 20 }}>
//                   <td style={{ textAlign: "center" }}>4</td>
//                   <td style={{ textAlign: "center" }}>2022</td>
//                   <td style={{ textAlign: "center" }}>Metabolism of body</td>
//                   <td style={{ textAlign: "center" }}>15/03/2022</td>
//                   <td style={{ textAlign: "center" }}>
//                     Swaminarayan college of pharmacy
//                   </td>
//                   <td style={{ textAlign: "center" }}>
//                     Swaminarayan college of pharmacy
//                   </td>
//                 </tr>

//                 <tr style={{ height: 20 }}>
//                   <td style={{ textAlign: "center" }}>5</td>
//                   <td style={{ textAlign: "center" }}>2022</td>
//                   <td style={{ textAlign: "center" }}>Life science</td>
//                   <td style={{ textAlign: "center" }}>09/09/2022</td>
//                   <td style={{ textAlign: "center" }}>
//                     Swaminarayan college of pharmacy
//                   </td>
//                   <td style={{ textAlign: "center" }}>
//                     Swaminarayan college of pharmacy
//                   </td>
//                 </tr>

//                 <tr style={{ height: 20 }}>
//                   <td style={{ textAlign: "center" }}>6</td>
//                   <td style={{ textAlign: "center" }}>2022</td>
//                   <td style={{ textAlign: "center" }}>
//                     Refresher course for registered pharmacist
//                   </td>
//                   <td style={{ textAlign: "center" }}>
//                     5/08/2022
//                     <br />
//                     To
//                     <br />
//                     06/08/2022
//                   </td>
//                   <td style={{ textAlign: "center" }}>
//                     Swaminarayan college of pharmacy
//                   </td>
//                   <td style={{ textAlign: "center" }}>GSPC</td>
//                 </tr>
//               </tbody>
//             </table>

//             <br />

//             <p>
//               <strong>Faculty Paper Published in journals</strong>
//               <br />
//             </p>

//             <table
//               className="table table-bordered"
//               style={{ borderCollapse: "collapse", width: "100%", height: 40 }}
//               border={1}
//             >
//               <tbody>
//                 <tr style={{ height: 20 }}>
//                   <td style={{ width: "25%", height: 20, textAlign: "center" }}>
//                     <span style={{ color: "#000000", textAlign: "center" }}>
//                       <strong>SL NO</strong>
//                     </span>
//                     <br />
//                   </td>
//                   <td style={{ width: "25%", height: 20, textAlign: "center" }}>
//                     <span style={{ color: "#000000" }}>
//                       <strong>Year</strong>
//                     </span>
//                     <br />
//                   </td>
//                   <td style={{ width: "25%", height: 20, textAlign: "center" }}>
//                     <span style={{ color: "#000000" }}>
//                       <strong>Name of Faculty</strong>
//                     </span>
//                     <br />
//                   </td>
//                   <td style={{ width: "25%", height: 20, textAlign: "center" }}>
//                     <span style={{ color: "#000000" }}>
//                       <strong>Title</strong>
//                     </span>
//                     <br />
//                   </td>
//                   <td style={{ width: "25%", height: 20, textAlign: "center" }}>
//                     <span style={{ color: "#000000" }}>
//                       <strong>Journal Name</strong>
//                     </span>
//                     <br />
//                   </td>
//                   <td style={{ width: "25%", height: 20, textAlign: "center" }}>
//                     <span style={{ color: "#000000" }}>
//                       <strong>Volume/Year/ISSN No.</strong>
//                     </span>
//                     <br />
//                   </td>
//                 </tr>

//                 <tr style={{ height: 20 }}>
//                   <td style={{ textAlign: "center" }}>1</td>
//                   <td style={{ textAlign: "center" }}>2023</td>
//                   <td style={{ textAlign: "center" }}>Dr. Ravikumar Patel</td>
//                   <td style={{ textAlign: "center" }}>
//                     Validation and standardization of test methods and
//                     evaluators for testing of hair care
//                   </td>
//                   <td style={{ textAlign: "center" }}>
//                     Indian Journal of Research
//                   </td>
//                   <td style={{ textAlign: "center" }}>
//                     Volume 12/Issue-06/June -2023
//                   </td>
//                 </tr>

//                 <tr style={{ height: 20 }}>
//                   <td style={{ textAlign: "center" }}>2</td>
//                   <td style={{ textAlign: "center" }}>2022</td>
//                   <td style={{ textAlign: "center" }}>
//                     Mr. Tapankumar N Patel
//                   </td>
//                   <td style={{ textAlign: "center" }}>
//                     VALIDATED RP-HPLC DIODE ARRAY DETECTOR METHOD FOR
//                     SIMULTANEOUS ESTIMATION OF OXICONAZOLE NITRATE AND BENZOIC
//                     ACID IN CREAM
//                   </td>
//                   <td style={{ textAlign: "center" }}>
//                     International Journal of Research and Analytical Reviews
//                   </td>
//                   <td style={{ textAlign: "center" }}>
//                     Volume 9/Issue 3 September 2022, Date of Publication 19
//                     September 2022/E-ISSN: 2348-1269, P-ISSN: 2349-5138
//                   </td>
//                 </tr>

//                 <tr style={{ height: 20 }}>
//                   <td style={{ textAlign: "center" }}>3</td>
//                   <td style={{ textAlign: "center" }}>2022</td>
//                   <td style={{ textAlign: "center" }}>Ms. Bhumika Patel</td>
//                   <td style={{ textAlign: "center" }}>
//                     AN EXHAUSTIVE STUDY OF MULTIFUNCTIONAL PLANT CRATAEGUS
//                     CRENULATA: IN VOGUE FINDINGS ACCOMPANIED BY ITS
//                     THERAPEUTICAL AND PHARMACOLOGICAL IMPORTANCE
//                   </td>
//                   <td style={{ textAlign: "center" }}>
//                     International Journal of Pharmaceutical Science Invention
//                   </td>
//                   <td style={{ textAlign: "center" }}>
//                     Volume: 11/Issue: 01 Date of Publication: January 2022
//                     e-ISSN: 2319-6718, p-ISSN: 2319-670X
//                   </td>
//                 </tr>

//                 <tr style={{ height: 20 }}>
//                   <td style={{ textAlign: "center" }}>4</td>
//                   <td style={{ textAlign: "center" }}>2022</td>
//                   <td style={{ textAlign: "center" }}>Ms. Anjali Luhana</td>
//                   <td style={{ textAlign: "center" }}>
//                     EXHAUSTIVE FACTS OF FEATURE OF A WHOLE LOT OF BIOMARKER IN
//                     BREAST CANCER.
//                   </td>
//                   <td style={{ textAlign: "center" }}>
//                     International journal of creative research thoughts
//                   </td>
//                   <td style={{ textAlign: "center" }}>
//                     Volume 10/issue 06 June 2022, issn-2320-2882
//                   </td>
//                 </tr>

//                 <tr style={{ height: 20 }}>
//                   <td style={{ textAlign: "center" }}>5</td>
//                   <td style={{ textAlign: "center" }}>2022</td>
//                   <td style={{ textAlign: "center" }}>Ms. Vandana Chaudhary</td>
//                   <td style={{ textAlign: "center" }}>
//                     PREPARTION AND EVALUTION OF ACACIA NELOTICA GUM MICROSPHERE
//                     USING VARIOUS CROSS LINKING AGENTS
//                   </td>
//                   <td style={{ textAlign: "center" }}>
//                     International Journal of novel research and development
//                   </td>
//                   <td style={{ textAlign: "center" }}>
//                     Volume 7/ issue 07 july 2022, issn 2456-4184
//                   </td>
//                 </tr>

//                 <tr style={{ height: 20 }}>
//                   <td style={{ textAlign: "center" }}>6</td>
//                   <td style={{ textAlign: "center" }}>2022</td>
//                   <td style={{ textAlign: "center" }}>Mr. Kalubha Zala</td>
//                   <td style={{ textAlign: "center" }}>
//                     CONCOCTION AND ASSESSMENT OF ANTIACNE CUM
//                     MULTIPURPOSECREAMVIA EMPLOYING AVARIETY OF HERBAL EXTRACT
//                   </td>
//                   <td style={{ textAlign: "center" }}>
//                     World Journal of Pharmacy And Pharmaceutical Sciences
//                   </td>
//                   <td style={{ textAlign: "center" }}>
//                     Volume 11, issue ISSN 1614-1622 , Feb 2022
//                   </td>
//                 </tr>

//                 <tr style={{ height: 20 }}>
//                   <td style={{ textAlign: "center" }}>7</td>
//                   <td style={{ textAlign: "center" }}>2021</td>
//                   <td style={{ textAlign: "center" }}>Ms. Ishita Basera</td>
//                   <td style={{ textAlign: "center" }}>
//                     AN OVERVIEW ON THE VERACITY OF MUCORMYCOSIS ASSOCIATED WITH
//                     COVID-19
//                   </td>
//                   <td style={{ textAlign: "center" }}>
//                     International Journal of Pharmaceutical Research and
//                     Applications
//                   </td>
//                   <td style={{ textAlign: "center" }}>
//                     Volume 6, Issue 4 July-Aug 2021, pp: 1360-1372, DOI:
//                     10.20959/wjpps20225-21907
//                   </td>
//                 </tr>

//                 <tr style={{ height: 20 }}>
//                   <td style={{ textAlign: "center" }}>8</td>
//                   <td style={{ textAlign: "center" }}>2021</td>
//                   <td style={{ textAlign: "center" }}>Ms. Vandana Chaudhary</td>
//                   <td style={{ textAlign: "center" }}>
//                     PURIFICATION, CHARACTERIZATION AND EVALUTION OF BABOOL GUM
//                     AS PHARMACEUTICAL EXCIPIENTS.
//                   </td>
//                   <td style={{ textAlign: "center" }}>
//                     International journal of science and research
//                   </td>
//                   <td style={{ textAlign: "center" }}>
//                     Volume 10/issue 02, feb 2021 ISSN-2319-7064
//                   </td>
//                 </tr>
//               </tbody>
//             </table>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }
