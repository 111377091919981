import React from "react";
import axios from "axios";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { UNIVERSITY_NOTIFICATIONS_GET } from "../../Utils/InfoUploadingConst";


function UniversityNotifications() {

    const [data, setData] = useState([]);

    const navigate = useNavigate();

    const getData = async () => {
        const config = {
            method: "get",
            url: UNIVERSITY_NOTIFICATIONS_GET,
            headers: {
                "Content-Type": "application/json",
            },
        };

        await axios(config)
            .then((res) => {
                res.data.data.sort((a, b) => b.id - a.id);
                res.data.data.forEach((element) => {
                    element.attachments = JSON.parse(element.attachments);
                });
                console.log(res.data.data)
                setData(res.data.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        getData();
    }, []);

    const next = async (id, data1, images) => {
        console.log("error here");
        navigate(`/Homoeopathy/UniversityNotifications/${id}`, {
            state: {
                images: images,
                data: data1,
            },
        });
    };

    return (
        <div>
            <div className="container register" style={{maxWidth:"1200px"}} >
                <div className="row">
                    <div class="card-header">
                        <h1 class="text-danger"> University Notifications </h1>
                    </div>
                    <table className="table table-bordered table-hover">
                       <thead>
                       <tr>
                            <th>Sl No</th>
                            <th>Date</th>
                            <th>Notification</th>
                            <th>Action</th>
                            {/* <th><h4>Status</h4></th> */}
                        </tr>
                       </thead>
                        {/* <tbody>

                            {
                                data && data?.map((item, key) => {
                                    return (


                                        <tr>
                                           
                                            <td>{item?.title}</td>
                                            <td>{item?.date}</td>
                                            <td>{item?.description}</td>
                                            <td>{item?.attachments}</td>
                                            <td>{item?.status== "ACTIVE" ? <span class="text-success"> Apply Now  </span> : <span class="text-danger"> Closed  </span> }</td>
                                            
                                        </tr>

                                    )
                                })
                            }
                        </tbody> */}

<tbody>
  {data.map((item, key) => {
    return (
      item.status === "ACTIVE" && (
        <tr key={item.id}>
            <td width="2%">{key+1}</td>
          <td width="5%">
            {/* {item.date} */}
            {new Date(item?.date).toISOString().split('T')[0]}
          </td>
          <td width="25%">{item.title}</td>
          <td width="3%"><a href={item?.attachments} target="_blank"><button className="btn btn-success mt-2">View</button></a></td>
          {/* Display status based on item.status */}
          {/* <td>{item.status === "ACTIVE" ? <span className="text-success">Apply Now</span> : <span className="text-danger">Closed</span>}</td> */}
         
        </tr>
      )
    );
  })}
</tbody>

                    </table>



                </div>
            </div>
        </div>
    )
}

export default UniversityNotifications;
