import React from 'react'

function AboutMBBSHospital() {

  
  const banner1 = 'https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/images/DJI_0449.JPG'
  const banner2 = 'https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/images/DJI_0373.JPG'
 
  return (
    <div className='Infrastructure'>
        <div className="academics-details-area pt-90 pb-70">
  <div className="container">
    <div className="row">
      <div className="col-lg-12">
        <div className="academics-details">
          <div className="details">
            <h2 >About Hospital</h2>
            <hr />
            <p style={{ textAlign: "justify" }}>
            PSM Hospital is attached to Swaminarayan Institute of Medical Sciences and
Research is a 390+ bed Multispeciality Hospital. PSM Hospital is a tertiary care
hospital offers medical services for General Medicine, General Surgery,
Paediatric, Obstetrics &amp; Gynecology, ENT, Orthopaedics, Paediatrics, Skin,
Pulmonology, Psychiatry, Dialysis and Emergency.
            </p>
            
            <div className="details-images">
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <img
                    src={banner1}
                    alt="Gurukul Top View"
                    height="100%"
                  />
                </div>
                <div className="col-lg-6 col-md-6">
                  <img
                    src={banner2}
                    alt="Universiry Front View"
                    height="100%"
                  />
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
         
        </div>
      </div>
    </div>
  </div>
</div>

    </div>
  )
}

export default AboutMBBSHospital