import React from 'react'
import axios from 'axios';
import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { COLLEGE_AFFILIATION_GET, COLLEGE_PERMISSION_GET } from '../../Utils/InfoUploadingConst';

function Affiliation() {

  const [data, setData] = useState([]);
  
  const navigate = useNavigate();

  const getData = async () => {
    const config = {
        method: "get",
        url: COLLEGE_PERMISSION_GET,
        headers: {
            "Content-Type": "application/json",
        },
    };

    await axios(config)
        .then((res) => {
            res.data.data.sort((a, b) => b.id - a.id);
            res.data.data.forEach((element) => {
                element.attachments = JSON.parse(element.attachments);
            });
            console.log(res.data.data)
            setData(res.data.data);

        })
        .catch((err) => {
            console.log(err);
        });
  };

  useEffect(() => {
    getData();
  }, []);


  return (
    <div>

<div className="container mt-3">
        <div className="row register" style={{ maxWidth: "100%" }}>
          <div className="col-md-12">
            <h3>
              AFFILIATION           
            </h3>
            
          </div>

        
<div className="container">

  
<div className="alumni-area">
<div className="row">

{data
    ?.filter((item) => item.type === "College Affiliation")
    ?.map((item, key) => {
      return (
      item.status === "ACTIVE" && item.college_id == "1111007"  &&( 
<div className="col-md-4">
<a href={item?.attachments} target="_blank">
  <div className="single-alumoni-updates-card">
  <div className=" d-flex flex-row flex-wrap align-items-center ">
    <div className=" bg-danger text-center p-3 me-3">
    <i class="ri-file-download-line text-light " style={{fontSize:"20px"}}></i>
    </div>
   <div className="">
    <h5>
      {item?.title} Affiliation
    </h5>
   </div>
  </div>
</div>
</a>
  </div>
  )
  )
  })
  }

</div>

</div>
</div>
         
        </div>
      </div>

    </div>
  )
}

export default Affiliation;