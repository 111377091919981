import React from 'react'

function MissionVision() {
  return (
    <div className='MissionVision'>
        <div className="health-care-area ptb-100 bg-f4f6f9">
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-lg-6 col-md-6">
        <div className="single-health-care-card">
          <div className="card"></div>
          <div className="health-care-content">
            <a href="#">
              <h3>Mission</h3>
            </a>
            <p>
              <i
                className="ri-checkbox-circle-line"
                style={{ color: "#e32845" }}
              />{" "}
              Our mission is to promote participation in higher education and to
              assess our progress through Strategic Plan with farsighted
              fortune.
              <br />
              <i
                className="ri-checkbox-circle-line"
                style={{ color: "#e32845" }}
              />{" "}
              We will pursue continuous development of infrastructure, enhance
              state-of-the-art equipment and facilities for students.
              <br />
              <i
                className="ri-checkbox-circle-line"
                style={{ color: "#e32845" }}
              />{" "}
              We will provide a technologically up-to-date and intellectually
              inspiring environment of learning, research, creativity,
              innovation and professional activities and inculcate ethics and
              moral values in our students.
              <br />
              <i
                className="ri-checkbox-circle-line"
                style={{ color: "#e32845" }}
              />{" "}
              We will create a conducive environment for students to excel in
              innovative ideas and startups.
            </p>
          </div>
        </div>
      </div>
      <div className="col-lg-6 col-md-6">
        <div className="single-health-care-card">
          <div className="img">
            <a href="#">
              <img src="assets/images/academics/mission.jpg" alt="Image" />
            </a>
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-lg-6 col-md-6">
          <div className="single-health-care-card">
            <div className="card">
              <a href="#">
                <img src="assets/images/academics/vision.jpg" alt="Image" />
              </a>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6">
          <div className="single-health-care-card">
            <div className="card"></div>
            <div className="health-care-content">
              <a href="#">
                <h3>Vision</h3>
              </a>
              <p>
                <i
                  className="ri-checkbox-circle-line"
                  style={{ color: "#e32845" }}
                />{" "}
                To emerge as a “Centre for excellence” offering Education and
                Research
                <br />
                <i
                  className="ri-checkbox-circle-line"
                  style={{ color: "#e32845" }}
                />{" "}
                To provide opportunities to students to appear as groomed
                individuals, and instil high levels of discipline and strive to
                set global standards.
                <br />
                <i
                  className="ri-checkbox-circle-line"
                  style={{ color: "#e32845" }}
                />{" "}
                To build our scholars technologically supercilious and virtuous,
                who in turn shall contribute to the advancement of society and
                humankind.
                <br />
                <i
                  className="ri-checkbox-circle-line"
                  style={{ color: "#e32845" }}
                />{" "}
                We dedicate and commit ourselves to achieve, sustain and foster
                unmatched excellence in Education.
                <br />
                <i
                  className="ri-checkbox-circle-line"
                  style={{ color: "#e32845" }}
                />{" "}
                To nurture and promote education with संस्कार (value based
                education)
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


    </div>
  )
}

export default MissionVision