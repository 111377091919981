import React from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../Router/RouterConfig";

export default function HospitalAdministration() {
  const navigate = useNavigate();

  return (
    <div className="GoverningBody">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <br />
            <br />
            <br />
            <h1 className="text-center">Hospital Administration</h1>
            <br />
            <br />
            <div className="card border-0 shadow-sm">
              <div className="card-body">
                <div className="container">
                  <div className="row">
                    {/* CARD START HERE  */}
                    {/* <div className="col-12 col-sm-6 col-lg-3">
                      <div
                        className="single_advisor_profile wow fadeInUp"
                        data-wow-delay="0.3s"
                      >
                        <div className="advisor_thumb">
                          <img
                            src="https://i.pravatar.cc/150?img=12"
                            alt="vicepresident"
                            width="100%"
                          />
                        </div>
                        <div className="single_advisor_details_info">
                          <h6>Name Here</h6>
                          <p className="designation">
                            <strong>Role Here</strong>
                          </p>

                          <p className="designation">
                            <strong>Employee Code: </strong>
                          </p>

                          <br />
                          <p className="text-center">
                            <strong>Department:</strong>
                          </p>
                          <p className="text-center">
                            <strong>Qualifications:</strong>
                          </p>

                          <p className="text-center">
                            <strong>Date of Joing:</strong>
                          </p>

                          <p className="text-center">
                            <strong>Experience:</strong>
                          </p>
                        </div>
                      </div>
                    </div> */}
                    {/* CARD END HERE  */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


