import React from "react";

function Courses() {
  return (
    <div>
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-md-12">
            <p>
              <span style={{ fontSize: "18pt" }}>
                <strong>Courses</strong>
              </span>
              <br />
            </p>
            <p>
              The following are the courses conducted by Shree Swaminarayan
              Engineering for the Academic Year 2022-2023 and 2023-2024
            </p>

            <p>
              <strong>All Courses Details:</strong>
              <br />
            </p>
            <table
              className="table table-bordered"
              style={{ borderCollapse: "collapse", width: "100%", height: 173 }}
              border={1}
            >
              <tbody>
                <tr style={{ height: 20 }}>
                  <td
                    style={{
                      width: "15%",
                      height: 20,
                      textAlign: "center",
                    }}
                  >
                    <strong>Level</strong>
                    <br />
                  </td>
                  <td style={{ width: "15%", height: 20, textAlign: "center" }}>
                    <strong>Program</strong>
                    <br />
                  </td>
                  <td style={{ width: "35%", height: 20 }}>
                    <strong>Courses</strong>
                    <br />
                  </td>
                  <td style={{ width: "25%", height: 20 }}>
                    <strong>Intake 2023-24</strong>
                  </td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td
                    style={{
                      width: "5.17544%",
                      height: 20,
                      textAlign: "center",
                    }}
                    rowSpan={6}
                  >
                    Diploma
                    <br />
                  </td>
                  <td
                    style={{
                      width: "10.4386%",
                      height: 20,
                      textAlign: "center",
                    }}
                    rowSpan={6}
                  >
                    Engineering and Technology
                    <br />
                  </td>
                  <td>Mechanical Engineering</td>
                  <td>60</td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td>Automobile Engineering</td>
                  <td>30</td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td>Computer Engineering</td>
                  <td>120</td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td>Information Technology</td>
                  <td>60</td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td>Electrical Engineering</td>
                  <td>60</td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td>Civil Engineering</td>
                  <td>30</td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td
                    style={{
                      width: "5.17544%",
                      height: 20,
                      textAlign: "center",
                    }}
                    rowSpan={6}
                  >
                    Undergraduate (Degree)
                    <br />
                  </td>
                  <td
                    style={{
                      width: "10.4386%",
                      height: 20,
                      textAlign: "center",
                    }}
                    rowSpan={6}
                  >
                    Engineering and Technology
                    <br />
                  </td>
                  <td>Mechanical Engineering</td>
                  <td>60</td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td>Automobile Engineering</td>
                  <td>30</td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td>Computer Engineering</td>
                  <td>120</td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td>Information Technology</td>
                  <td>60</td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td>Electrical Engineering</td>
                  <td>60</td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td>Civil Engineering</td>
                  <td>30</td>
                </tr>

                <tr style={{ height: 20 }}>
                  <td
                    style={{
                      width: "5.17544%",
                      height: 20,
                      textAlign: "center",
                    }}
                    rowSpan={6}
                  >
                    Postgraduate (ME)
                    <br />
                  </td>
                  <td
                    style={{
                      width: "10.4386%",
                      height: 20,
                      textAlign: "center",
                    }}
                    rowSpan={6}
                  >
                    Engineering and Technology
                    <br />
                  </td>
                  <td>Mechanical Engineering (CAD/CAM)</td>
                  <td>18</td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td>Computer (Software Enginnering)</td>
                  <td>18</td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td>Electrical Engineering</td>
                  <td>18</td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td>Civil (Structural Engineering)</td>
                  <td>18</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Courses;
