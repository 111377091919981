import React from "react";

export default function Therapies() {
  return (
    <div>
      <div className="container mt-5 mb-5">
        <div className="row register" style={{maxWidth: "100%"}}>
          <div className="col-md-12">
            <p>
              <span style={{ fontSize: "18pt" }}>
                <h3>Therapies</h3>
              </span>
              <br />
            </p>

            <p>
              <strong>
                DETAILS OF KARMAS CARRIED OUT from 1st Jan 2022 to 31st Dec 2022
              </strong>
            </p>

            {/* HERE  */}

            <table
              className="table table-bordered"
              style={{ borderCollapse: "collapse", width: "100%", height: 173 }}
              border={1}
            >
              <tbody>
                <tr>
                  <td>
                    <div>
                      <strong>S. No.</strong>
                    </div>
                  </td>
                  <td>
                    <div>
                      <strong>&nbsp; Patients</strong>
                    </div>
                  </td>
                  <td>
                    <div>
                      <strong>
                        &nbsp;&nbsp;&nbsp;&nbsp; Snehan&nbsp;&nbsp; <br />
                      </strong>
                    </div>
                  </td>
                  <td>
                    <div>
                      <strong>
                        &nbsp;&nbsp;&nbsp;&nbsp; Swedan&nbsp;&nbsp; <br />
                      </strong>
                    </div>
                  </td>
                  <td>
                    <div>
                      <strong>
                        &nbsp;&nbsp;&nbsp;&nbsp; Vaman&nbsp;&nbsp; <br />
                      </strong>
                    </div>
                  </td>
                  <td>
                    <div>
                      <strong>
                        &nbsp;&nbsp;&nbsp;&nbsp; Virechan&nbsp;&nbsp; <br />
                      </strong>
                    </div>
                  </td>
                  <td>
                    <div>
                      <strong>
                        &nbsp;&nbsp;&nbsp;&nbsp; Basti&nbsp;&nbsp; <br />
                      </strong>
                    </div>
                  </td>
                  <td>
                    <div>
                      <strong>
                        &nbsp;&nbsp;&nbsp;&nbsp; Nasya&nbsp;&nbsp; <br />
                      </strong>
                    </div>
                  </td>
                  <td>
                    <div>
                      <strong>
                        &nbsp;&nbsp; Raktmokshan&nbsp;&nbsp; <br />
                      </strong>
                    </div>
                  </td>
                  <td>
                    <div>
                      <strong>
                        &nbsp;&nbsp; Shirodhara &nbsp; &nbsp; <br />
                      </strong>
                    </div>
                  </td>
                  <td>
                    <div>
                      <strong>
                        &nbsp; Shirobasti&nbsp;&nbsp;&nbsp; <br />
                      </strong>
                    </div>
                  </td>
                  <td>
                    <div>
                      <strong>
                        &nbsp;&nbsp;&nbsp; Others&nbsp;&nbsp; <br />
                      </strong>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>1.</div>
                  </td>
                  <td>
                    <div>From O.P.D.</div>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <div>118</div>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <div>230</div>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <div>0</div>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <div>16</div>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <div>352</div>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <div>133</div>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <div>94</div>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <div>63</div>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <div>0</div>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <div>347</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>2.</div>
                  </td>
                  <td>
                    <div>From I.P.D.</div>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <div>6447</div>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <div>6629</div>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <div>20</div>
                  </td>
                  <td style={{ textAlign: "center" }}>76</td>
                  <td style={{ textAlign: "center" }}>
                    <div>3529</div>
                  </td>
                  <td style={{ textAlign: "center" }}>782</td>
                  <td style={{ textAlign: "center" }}>
                    <div>111</div>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <div>534</div>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <div>3</div>
                  </td>
                  <td style={{ textAlign: "center" }}>2929</td>
                </tr>
                <tr>
                  <td>
                    <div>
                      <strong>&nbsp;</strong>
                    </div>
                  </td>
                  <td>
                    <div>
                      <strong>Total </strong>
                    </div>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <div>
                      <strong>6565</strong>
                    </div>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <div>
                      <strong>6859</strong>
                    </div>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <div>
                      <strong>20</strong>
                    </div>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <div>
                      <strong>92</strong>
                    </div>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <div>
                      <strong>3881</strong>
                    </div>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <div>
                      <b>915</b>
                    </div>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <div>
                      <strong>205</strong>
                    </div>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <div>
                      <strong>597</strong>
                    </div>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <div>
                      <strong>3</strong>
                    </div>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <div>
                      <strong>3276</strong>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
