import React from "react";
import { useLocation } from "react-router-dom";
import { MEDIA_GET, MEDIA_GET1, MEDIA_UPDATE } from "../../../Utils/InfoUploadingConst";
import { useState, useEffect } from "react";
import { Http } from "../../../Services/Services";
import Loader from "../../../Components/Loader/Loader";

function Media() {
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState([]);
  const [years, setYears] = useState([]);
  const [months, setMonths] = useState([]);

  const [yearData, setYearData] = useState("");
  const [monthData, setMonthData] = useState("");

  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");

  function compareByMonth(a, b) {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const monthA = months.indexOf(a.month);
    const monthB = months.indexOf(b.month);

    return monthB - monthA;
  }

  const getData = async () => {
    setLoading(1);
    await Http.get(MEDIA_GET)
      .then(async (res) => {
        console.log(res.data.data);

       // Filter data where type is "Media"
      const mediaData = res.data.data.filter(element => element.type == "Media");

      let z = new Set();
      mediaData.forEach((element) => {
        z.add(element.year);
      });
      let a = Array.from(z);
      await setYears(a);
      console.log(a);
      mediaData.sort((a, b) => b.year - a.year);
      setYear(mediaData[0].year);
      setData(mediaData);

      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(0);
  };

  useEffect(() => {
    getData();
  }, []);

  const filterYearData = async () => {
    let x = data?.filter((s) => s.year == year);
    x.sort(compareByMonth);
    let monthSet = new Set();
    x.forEach((element) => {
      monthSet.add(element.month);
    });
    let xyz = Array.from(monthSet);
    setMonths(xyz);
    setMonth(x[0].month);
    setYearData(x);
  };

  const filterMonthData = async () => {
    let y = yearData?.filter((s) => s.month == month);
    y.sort((a, b) => new Date(b.date) - new Date(a.date));
    setMonthData(y);
  };

  useEffect(() => {
    filterYearData();
  }, [year]);

  useEffect(() => {
    filterMonthData();
  }, [month]);

  return (
    <>
      <div className="Media">
        <Loader loading={loading} />
        <div className="events-area pb-70">
          <div className="container">
            <div className="row ">
              <div className="col-md-12">
                <div className=" description mt-5 ">
                  <nav>
                    <div className="nav nav-tabs " id="nav-tab" role="tablist">
                      {years &&
                        years?.map((item, key) => {
                          return (
                            <button
                              className={`nav-link ${
                                year == item ? "active" : ""
                              }`}
                              id="nav-2023-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#nav-2023"
                              type="button"
                              role="tab"
                              onClick={() => setYear(item)}
                              aria-controls="nav-2023"
                              value="2023"
                              aria-selected="true"
                            >
                              {item}
                            </button>
                          );
                        })}
                    </div>
                  </nav>

                  <div className="tab-content" id="nav-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="nav-2023"
                      role="tabpanel"
                      aria-labelledby="nav-2023-tab"
                    >
                      <hr />
                      <ul
                        className="nav nav-pills mb-3 p-3"
                        id="pills-tab"
                        role="tablist"
                      >
                        {months &&
                          months.map((item, key) => {
                            return (
                              <li className="nav-item" role="presentation">
                                <button
                                  className={`nav-link ${
                                    month == item ? "active" : ""
                                  }`}
                                  id="pills-nov-tab"
                                  data-bs-toggle="pill"
                                  onClick={() => setMonth(item)}
                                  data-bs-target="#pills-june-23"
                                  type="button"
                                  role="tab"
                                  aria-controls="pills-nov"
                                  aria-selected="false"
                                >
                                  {item}
                                </button>
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </div>

                  <div className="tab-content" id="pills-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="pills-june-23"
                      role="tabpanel"
                      aria-labelledby="pills-june-tab"
                    >
                      <div className="row p-4">
                        {monthData &&
                          monthData.map((item, key) => {
                            return (
                              <div className="col-md-4">
                                <div className="row p-3">
                                  <div className="col-md-3 bg-danger rounded text-white text-center">
                                    <h4 className="mt-3 text-white">
                                      {item?.date.split("T")[0].split("-")[2]}
                                      <br />{" "}
                                      <span style={{ fontSize: 15 }}>
                                        {item?.month} {item?.year}
                                      </span>{" "}
                                    </h4>
                                    <p />
                                  </div>
                                  <div className="col-md-9">
                                    {/* <a href={item?.attachment} target="_blank">
                                      {" "}
                                      <h6>{item?.title}</h6>
                                      <b>View More</b>
                                    </a> */}
                                    <div className="col-md-9">
                                      <a href={item?.attachment || item?.video} target="_blank">
                                        <h6>{item?.title}</h6>
                                        <b>View More</b>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Media;
