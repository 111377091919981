import React from 'react'

function LibraryPhysio() {
  return (
    <div className='Library'>

    <div className="row register" style={{ maxWidth: "100%" }}>

    <div className="row justify-content-center mt-2">
          <h3 className="text-center mb-4 ">LIBRARY</h3>
          
          <div className="col-lg-5 col-md-6 mt-4">
            <div className="single-card">
              <div className="img">
                <a href="">
                  <img src="../assets/physiotherapy/library1.jpg" alt="Image" height={380} width={650}/>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-6 mt-4">
            <div className="single-card">
              <div className="img">
                <a href="">
                  <img src="../assets/physiotherapy/library2.jpg" alt="Image" height={380} width={650}/>
                </a>
              </div>
            </div>
          </div>
    </div>

    <div className="row mt-2">
    <br/>
    {/* <h3 className="text-center mb-4 ">Library</h3> */}
    <p className="text-justify">
      Separate libraries have been established for all the colleges of the
      group. Libraries with ample space are available in all the colleges of the
      group. It has a huge reading hall &amp; a well-furnished section
      containing sufficient books and allied subjects with Digital Library.
      Details of Journal and International Journal and books available in all
      the colleges of the group are given below.
    </p>
    <p className="text-justify">
      Computers are available for faculty, staff; and are also installed in
      hostels and the library. There is dedicated ERP section which deals all
      intuitions, students, faculty related data and information. The attendance
      of faculty and staff is monitored through a biometric system.
    </p>

    </div>

    <div className="row">
        <blockquote className="otro-blockquotes">
          <h5 className="ml-5">" All knowledge that the world has ever received comes from the mind; the
            infinite library of the universe is in our own mind."</h5>
          <h5 className="text-danger text-end">- "Swami Vivekananda"</h5>
        </blockquote>
    </div>

    {/* <div className="row justify-content-center mt-5">
      <table className="table table-bordered">
        <tbody>
          <tr>
            <th>Sr. No.</th>
            <th>Name of Institute</th>
            <th>No. Book/Titles</th>
            <th>National</th>
            <th>Inter-National</th>
          </tr>
          <tr>
            <td>1</td>
            <td>
              Shree Swaminarayan college of Engineering &amp; Technology
              (DIPLOMA)
            </td>
            <td>1475/8625</td>
            <td>4</td>
            <td>2</td>
          </tr>
          <tr>
            <td>2</td>
            <td>
              Shree Swaminarayan college of Engineering &amp; Technology
              (Degree)
            </td>
            <td>3550/14350</td>
            <td>45</td>
            <td>2</td>
          </tr>
          <tr>
            <td>3</td>
            <td>Shree Swaminarayan college of pharmacy Nursing</td>
            <td>3151</td>
            <td>9</td>
            <td>4</td>
          </tr>
          <tr>
            <td>4</td>
            <td>Shree Swaminarayan college of pharmacy</td>
            <td>5460/544</td>
            <td>30</td>
            <td>20</td>
          </tr>
          <tr>
            <td>5</td>
            <td>Shree Swaminarayan Physiotherapy College</td>
            <td>980</td>
            <td>24</td>
            <td>3</td>
          </tr>
          <tr>
            <td>6</td>
            <td>Shree Swaminarayan Science College</td>
            <td>150</td>
            <td>0</td>
            <td>0</td>
          </tr>
          <tr>
            <td>7</td>
            <td>Shree Swaminarayan Ayurvedic College</td>
            <td>10172/1252</td>
            <td>1</td>
            <td>1</td>
          </tr>
          <tr>
            <td>8</td>
            <td>Shree Swaminarayan B. Ed College</td>
            <td>3818</td>
            <td>13</td>
            <td>15</td>
          </tr>
        </tbody>
      </table>
    </div> */}

    </div>  

    </div>
  )
}

export default LibraryPhysio;