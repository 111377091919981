import React from 'react'

function Footer2() {
  return (
    <div className='Footer'>
        <>
  
  <div className="copyright-area">
    <div className="container">
      <div className="copyright">
        <div className="row">
          <div className="col-lg-6 col-md-4">
            <div className="social-content">
              <ul>
                <li>
                  <span>Follow Us On</span>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/swaminarayanuniversity"
                    target="_blank"
                  >
                    <i className="fa fa-facebook-square" />
                  </a>
                </li>
                <li>
                  {/*<a href="https://www.twitter.com" target="_blank"><i class="ri-twitter-fill"></i></a>*/}
                </li>
                <li>
                  {/*<a href="https://instagram.com/?lang=en" target="https://www.instagram.com/swaminarayan_university/"><i class="ri-instagram-line"></i></a>*/}
                </li>
                <li>
                  {/*<a href="https://linkedin.com/?lang=en" target="_blank"><i class="ri-linkedin-fill"></i></a>*/}
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-6 col-md-8">
            <div className="copy">
              <p>
                © {/*?= date("Y")?*/} Swaminarayan Institute of Medical Sciences and Research, Kalol. Crafted with{" "}
                <i className="ri-heart-3-fill text-danger" /> by{" "}
                <a href="https://nexenstial.com/" target="_blank">
                  Nexenstial LLP
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="go-top">
    <i className="ri-arrow-up-s-line" />
    <i className="ri-arrow-up-s-line" />
  </div>
</>

    </div>
  )
}

export default Footer2