import React from 'react'

function AdmissionsPhysiotherapy() {
  return (
    <div>

      <div className="container mt-2 mb-5">

        <br/>
        <h2 className="text-center">Admissions Details with Eligibility Criteria</h2>
        <br/>
        <br/>

        <div className="row register" style={{maxWidth: "100%"}}>
          <div className="col-md-12">
            <p>
              <span style={{ fontSize: "18pt" }}>
                <h3 className="text-center">General Admission Criteria:</h3>
              </span>
              <br />
            </p>
            <p>
              For admission to the First B. Physiotherapy course, a candidate –
            </p>
            <p>
              (a) Shall have passed the Higher Secondary Certificate Examination with science subjects (Physics, Chemistry and Biology) conducted by the Gujarat Higher Secondary Education Board or an examination considered as equivalent by the Gujarat Government and Admission Committee with at least 45% marks in Physics, Chemistry, Biology & English.
            </p>
            <p>
              (b) Shall have completed the age of 17 years on or before 31st December of the year in which he/she is be admitted. 
            </p>
            <p>
              (c) Institution shall have to adopt procedure laid down by the Admission Committee for Professional Medical / Paramedical educational courses for admission managed by Gujarat State Government. (The name subject to change as per the State Government discretion) 
            </p>
            <p>
              (d) Self finance Institution shall follow the rules laid down by the Gujarat Government for admission in management and NRI quota.
            </p>
            <br/>
            <br/>
          </div>

          <div className="col-md-12 mt-5">
            <p>
              <span style={{ fontSize: "18pt" }}>
                <h3 className="text-center">Criteria for International students:</h3>
              </span>
              <br />
            </p>
            <p>
              1)  Only 25% of the total seat should be enroll to the International students
            </p>
            <p>
              2) Delhi human resource ministry to get equivalent certificate as per the standards and norms of higher secondary board India
            </p>
            <p>
              3) Details of programme followed including syllabi and transcripts 
            </p>
            <p>
              4) A request letter/application 
            </p>
            <p>
              5) Academic Transcript for the last Class passed
            </p>
            <p>
              6) Transfer Certificate/letter from the Principal of the School confirming the last examination passed.
            </p>
          </div>

          <div className="col-md-12">  
            {/* <p>
              For admissions and inquiry:
              <br />
              Contact Us:
            </p>
            <p>
              Dr. Jayadatt Mehta,&nbsp; Contact - 8487878000,&nbsp; Dr Dinesh Rao,
              Contact - 9323691618
            </p>
            <p>
              Email: sshckalol@gmail.com
              <br />
            </p> */}
          </div>
        </div>
      </div>

    </div>
  )
}

export default AdmissionsPhysiotherapy;