import React from "react";
import { useState, useEffect } from "react";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../Router/RouterConfig";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { ALL_STUDENTS } from "../../Utils/apiConst.js";
import { sessionOpt } from "../../Data/sessionData.js";
import "react-toastify/dist/ReactToastify.css";
import {
  EXAM_FORM,
  GET_EXAM_FORM,
  UPDATE_EXAM_FORM,
} from "../../Utils/PHDapiconst.js";
import Loader from "../../Components/Loader/Loader.jsx";
import ProgressBar from "../../Components/ProgressBar/ProgressBar.jsx";

function PhdExamForm() {
  const date = new Date();
  const { state } = useLocation();

  let Phdid = localStorage.getItem("Phdid");

  const [user, setUser] = useState({
    year: 2023,
    course: "PHD",
    name: "",
    regNo: "",
    discipline: "",
    subject: "",
    session: "",
    address: "",
    contact: "",
    supervisor_name: "",
    supervisor_desig: "",
    supervisor_address: "",
    research_methodology: "",
    core_subject_paper: "",
    email: "",
    gender: "",
    place: "",
    date: date.toISOString()?.split("T")[0],
    dob: "",
    payment_status: "PENDING",
  });

  const navigate = useNavigate();
  const [loading, setLoading] = useState(0);

  const [pid, setPid] = useState("06");

  const [id, setId] = useState();

  const [flag1, setFlag1] = useState(false);

  const [flag2, setFlag2] = useState(false);

  const [flag3, setFlag3] = useState(true);

  const [flag4, setFlag4] = useState(true);

  let date1 = new Date();
  let date2 = `${date1.getFullYear()}-${
    date1.getMonth() + 1
  }-${date1.getDate()}`;

  const [details, setDetails] = useState([]);

  const [flag, setFlag] = useState(false);

  const [value, setValue] = useState();

  const [submitted, setSubmitted] = useState(false);

  const printRef = useRef();

  const clearData = () => {
    setUser({
      year: "",
      course: "",
      name: "",
      regNo: "",
      discipline: "",
      subject: "",
      session: "",
      address: "",
      contact: "",
      supervisor_name: "",
      supervisor_desig: "",
      supervisor_address: "",
      research_methodology: "",
      core_subject_paper: "",
      email: "",
      date: "",
      gender: "",
      place: "",
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({
      ...user,
      [name]: value,
    });
  };

  const handleSubmit = async (e, regNo) => {
    if (
      !user.address ||
      !user.name ||
      (!user.core_subject_paper && !user.research_methodology) ||
      !user.contact ||
      !user.email ||
      !user.discipline ||
      !user.place ||
      !user.regNo ||
      !user.subject ||
      !user.gender
    ) {
      toast.error("Please Enter Required Details");
      return;
    }

    localStorage.setItem("Phdid", regNo);

    if (!flag1) {
      alert("Please check your details once again");
      setFlag1(true);
      return;
    }

    setLoading(1);

    const config = {
      url: EXAM_FORM,
      method: "post",
      data: user,
    };

    await axios(config)
      .then((res) => {
        console.log(res);
        toast.success("Successfully Submitted Exam Form");
        setSubmitted(true);
        setLoading(0);
        setValue(2);
        setFlag2(true);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong");
      });
  };

  const handleSubmit1 = async (e, uid) => {
    if (
      !user.address ||
      !user.name ||
      (!user.core_subject_paper && !user.research_methodology) ||
      !user.contact ||
      !user.email ||
      !user.discipline ||
      !user.place ||
      !user.regNo ||
      !user.subject ||
      !user.gender
    ) {
      toast.error("Please Enter Required Details");
      return;
    }

    if (!flag1) {
      alert("Please check your details once again");
      setFlag1(true);
      return;
    }

    if (flag2) {
      navigate(ROUTES.PhdExamPayment, {
        state: { step2Completed: true, data1: user },
      });
      return;
    }
    setLoading(1);

    const config = {
      url: EXAM_FORM,
      method: "post",
      data: user,
    };

    if (!flag2) {
      await axios(config)
        .then((res) => {
          console.log(res);
          if (!flag2) {
            toast.success("Successfully Submitted Exam Form");
          }
          navigate(ROUTES.PhdExamPayment, {
            state: { step2Completed: true, data1: user },
          });
          setSubmitted(true);
          clearData();
          setLoading(0);
          setValue(2);
          setFlag2(true);
        })
        .catch((err) => {
          console.log(err);
          toast.error("Something went wrong");
        });
    }
  };

  let data = [];

  const handleSearch = (e) => {
    if (!user?.regNo) {
      return toast.error("Please Enter Registration Number");
    }

    localStorage.setItem("Phdid", user?.regNo);

    setFlag3(false);

    setLoading(1);

    const config = {
      url: `${GET_EXAM_FORM}?regNo=${user?.regNo}`,
      method: "get",
      Headers: {
        "content-type": "application/json",
      },
    };

    axios(config)
      .then((res) => {
        console.log(res.data.data);
        user.email = res.data.data[0].email;
        user.address = res.data.data[0].address;
        user.place = res.data.data[0].place;
        user.name = res.data.data[0].name;
        if (
          res.data.data[0].payment_status == "PENDING" ||
          res.data.data[0].payment_status == "Pending"
        ) {
          setLoading(0);
          navigate(ROUTES.PhdExamPayment, {
            state: { step2Completed: true, data1: user },
          });
        } else if (
          res.data.data[0].payment_status == "Success" ||
          res.data.data[0].payment_status == "SUCCESS"
        ) {
          navigate(ROUTES.PhdFormPrint, {
            state: { step2Completed: true, data1: user },
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });

    const config1 = {
      url: `${ALL_STUDENTS}?program_id=${pid}`,
      method: "get",
      Headers: {
        "content-type": "application/json",
      },
    };

    axios(config1)
      .then((res) => {
        try {
          data =
            res.data.data &&
            res.data.data.filter((s) => s.user_id == user?.regNo);
          setDetails(data);
          user.address = data[0]?.address;
          user.gender = data[0]?.gender;
          user.name = data[0]?.name;
          user.contact = data[0]?.phone;
          user.place = data[0]?.city ? data[0]?.city : "";
          user.email = data[0]?.email;
          setFlag(true);
          if (data.length == 0) {
            setLoading(0);
            toast.error("Please Enter Valid Registration Number");
            return;
          }
          setLoading(0);
          setValue(1);
        } catch (err) {
          console.log(err);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSearch1 = async () => {
    setFlag3(false);

    setLoading(1);

    const config = {
      url: `${GET_EXAM_FORM}?regNo=${Phdid}`,
      method: "get",
      Headers: {
        "content-type": "application/json",
      },
    };
    await axios(config)
      .then(async (res) => {
        console.log(res.data.data);
        setId(res.data.data[0].id);
        res.data.data[0].dob = res.data.data[0].dob.split("T")[0];
        res.data.data[0].date = res.data.data[0].date.split("T")[0];
        await setDetails(res.data.data);
        await setUser((prevUser) => ({
          ...prevUser,
          ...res.data.data[0],
        }));
        user.dob = res.data.data[0].dob.split("T")[0];
        user.date = res.data.data[0].date.split("T")[0];
        setLoading(0);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (state) {
      const { getData, id } = state;
      user.regNo = state.id;
      setValue(1);
      handleSearch1();
      setFlag(false);
      setFlag4(false);
    }
  }, []);

  const route = () => {
    setValue(0);
    setFlag(false);
    setFlag3(true);
    setDetails([]);
  };

  const Update = async () => {
    const config = {
      url: `${UPDATE_EXAM_FORM}/${id}`,
      method: "put",
      data: user,
    };

    await axios(config)
      .then((res) => {
        console.log(res);
        toast.success("Successfully Updated Exam Form");
        setSubmitted(true);
        setLoading(0);
        setValue(2);
        setFlag2(true);
        navigate(ROUTES.PhdExamPayment, {
          state: { step2Completed: true, data1: user },
        });
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong");
      });
  };

  return (
    <div className="container-fluid">
      <Loader loading={loading} />
      <div className="card-body" ref={printRef}>
        <div className="row">
          <div className="col-12 d-flex justify-content-center mb-5">
            <img
              src="/assets/images/white-logo.png"
              style={{ width: "300px", margin: "auto" }}
              alt=""
            />
          </div>
          <div className="col-md-12 d-flex justify-content-center ">
            <h1>PhD Examination Form 2023-24</h1>
          </div>
          <hr />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <ProgressBar value={value} />
          </div>
          <div
            className=" 
          mt-4 mx-2 mb-4 d-flex justify-content-center align-items-bottom"
            style={{ gap: "20px" }}
          >
            <div className="form-group" style={{ marginTop: "2rem" }}>
              <label htmlFor="">
                Enrollment Number <small className="text-danger">*</small>{" "}
              </label>
              <input
                type="text"
                readOnly={flag ? true : false}
                name="regNo"
                placeholder="Starts with SUK.."
                className="form-control"
                value={user?.regNo}
                onChange={handleChange}
              />
            </div>

            {flag3 == true ? (
              <div className="mt-auto ml-4">
                <button
                  className=" btn btn-danger btn-lg"
                  type="submit"
                  name="submit"
                  value="collect"
                  onClick={(e) => handleSearch(e)}
                >
                  <i className="fa fa-search" aria-hidden="true" /> Search
                </button>
              </div>
            ) : null}
          </div>
        </div>

        {details.length > 0
          ? details &&
            details?.map((item, key) => {
              return (
                <div>
                  <div className="row mx-auto mt-5">
                    <h4>PERSONAL INFORMATION</h4>
                  </div>

                  <div className="">
                    <div className="row mx-auto mt-3">
                      <div className="col-md-4 mb-3">
                        <div className="form-group">
                          <label htmlFor="">
                            {" "}
                            Year/Session Of Examination{" "}
                            <small className="text-danger">*</small>
                          </label>
                          <select
                            className="form-control"
                            value={user?.application_job_type}
                            name="application_job_type"
                            disabled="true"
                            onChange={handleChange}
                          >
                            <option value="">2023</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">
                            Name of Candidate{" "}
                            <small className="text-danger">*</small>
                          </label>
                          <input
                            type="text"
                            name="name"
                            className="form-control"
                            value={user?.name}
                            readOnly={user?.name && flag ? true : false}
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">
                            Phone number{" "}
                            <small className="text-danger">*</small>
                          </label>
                          <input
                            type="number"
                            name="contact"
                            className="form-control"
                            value={user?.contact}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="mx-auto row flex">
                      <div className="col-md-4 mt-4">
                        <div className="form-group">
                          <label htmlFor="">
                            Email <small className="text-danger">*</small>
                          </label>
                          <input
                            type="text"
                            name="email"
                            className="form-control"
                            value={user?.email}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-4 mt-4">
                        <div className="form-group">
                          <label htmlFor="">
                            Address <small className="text-danger">*</small>
                          </label>
                          <textarea
                            name="address"
                            className="form-control"
                            value={user?.address}
                            onChange={(e) => handleChange(e)}
                          />
                        </div>
                      </div>
                      <div className="col-md-4 mt-4">
                        <div className="form-group">
                          <label htmlFor="">
                            City <small className="text-danger">*</small>
                          </label>
                          <input
                            type="text"
                            name="place"
                            className="form-control"
                            value={user?.place}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="mx-auto row flex">
                      <div className="col-md-4 mt-4">
                        <div className="form-group">
                          <label htmlFor="">Research Methodology</label>
                          <input
                            type="text"
                            name="research_methodology"
                            className="form-control"
                            value={user?.research_methodology}
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div className="col-md-4 mt-4">
                        <div className="form-group">
                          <label htmlFor="">Core Subject Paper</label>
                          <input
                            type="text"
                            name="core_subject_paper"
                            className="form-control"
                            value={user?.core_subject_paper}
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div className="col-md-4 mt-4">
                        <div className="form-group">
                          <label htmlFor="">DOB (DD/MM/YYYY) </label>
                          <input
                            type="date"
                            name="dob"
                            className="form-control"
                            defaultValue={date2}
                            value={user?.dob || item?.dob}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="mx-auto flex row">
                      <div className="col-md-4 mt-4">
                        <div className="form-group">
                          <label htmlFor="">
                            Gender <small className="text-danger">*</small>
                          </label>
                          <select
                            name="gender"
                            className="form-control"
                            defaultValue={item?.gender}
                            value={user?.gender}
                            onChange={handleChange}
                          >
                            <option value="">Select Gender</option>
                            <option value={"Male"}>Male</option>
                            <option value="Female">Female</option>
                            <option value="Other">Other</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4 mt-4">
                        <div className="form-group">
                          <label htmlFor="">
                            Subject <small className="text-danger">*</small>
                          </label>
                          <input
                            name="subject"
                            className="form-control"
                            value={user?.subject}
                            type="text"
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div className="col-md-4 mt-4">
                        <div className="form-group">
                          <label htmlFor="">
                            Discipline <small className="text-danger">*</small>
                          </label>
                          <input
                            type="text"
                            name="discipline"
                            className="form-control"
                            value={user?.discipline}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="mx-auto flex row">
                      <div className="col-md-4 mt-4">
                        <div className="form-group">
                          <label htmlFor="">
                            Session <small className="text-danger">*</small>
                          </label>
                          <select
                            name="session"
                            className="form-control"
                            value={user?.session}
                            onChange={handleChange}
                          >
                            <option value="">Select Session</option>
                            {sessionOpt &&
                              sessionOpt.map((item, key) => (
                                <option value={item.id} key={key}>
                                  {item?.name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>

                      <div className="col-md-4 mt-4">
                        <div className="form-group">
                          <label htmlFor="">
                            Date Of Submission (DD/MM/YYYY){" "}
                          </label>
                          <input
                            type="date"
                            name="date"
                            className="form-control"
                            readOnly={flag ? true : false}
                            value={user?.date}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row mx-auto mt-5">
                      <h4>SUPERVISOR INFORMATION</h4>
                    </div>

                    <div className="mx-auto flex row">
                      <div className="col-md-4 mt-4">
                        <div className="form-group">
                          <label htmlFor="">
                            {" "}
                            Name <small className="text-danger">*</small>
                          </label>
                          <input
                            className="form-control"
                            name="supervisor_name"
                            value={user?.supervisor_name}
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div className="col-md-4 mt-4">
                        <div className="form-group">
                          <label htmlFor="">
                            {" "}
                            Designation <small className="text-danger">*</small>
                          </label>
                          <input
                            className="form-control"
                            value={user?.supervisor_desig}
                            name="supervisor_desig"
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div className="col-md-4 mt-4">
                        <div className="form-group">
                          <label htmlFor="">
                            {" "}
                            Address <small className="text-danger">*</small>
                          </label>
                          <input
                            className="form-control"
                            value={user?.supervisor_address}
                            name="supervisor_address"
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="d-flex my-3 mx-2" style={{ gap: "10px" }}>
                      {flag4 ? (
                        <>
                          <button
                            className="btn btn-danger btn-lg float-end"
                            onClick={(e) => handleSubmit(e, user?.regNo)}
                          >
                            Save
                          </button>
                          <button
                            className="mr-3 btn btn-danger btn-lg float-end"
                            onClick={(e) => handleSubmit1(e, user?.regNo)}
                          >
                            Save & Continue
                          </button>

                          <button
                            className="mr-3 btn btn-danger btn-lg float-end"
                            onClick={() => route()}
                          >
                            Go Back
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            className="mr-3 btn btn-danger btn-lg float-end"
                            onClick={() => Update()}
                          >
                            Update
                          </button>

                          <button
                            className="mr-3 btn btn-danger btn-lg float-end"
                            onClick={() => route()}
                          >
                            Go Back
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
}

export default PhdExamForm;
