import React from "react";

function FeeStructure() {
  return (
    <div>
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-md-12">
            <p>
              <strong>FEE STRUCTURE</strong>
              <br />
            </p>

            <table
              className="table table-bordered"
              style={{ borderCollapse: "collapse", width: "100%", height: 40 }}
              border={1}
            >
              <tbody>
                <tr style={{ height: 20 }}>
                  <td style={{ width: "25%", height: 20, textAlign: "center" }}>
                    <span style={{ color: "#000000", textAlign: "center" }}>
                      <strong>Level</strong>
                    </span>
                    <br />
                  </td>
                  <td style={{ width: "25%", height: 20 }}>
                    <span style={{ color: "#000000" }}>
                      <strong>Courses</strong>
                    </span>
                    <br />
                  </td>
                  <td style={{ width: "25%", height: 20, textAlign: "center" }}>
                    <span style={{ color: "#000000" }}>
                      <strong>Duration</strong>
                    </span>
                    <br />
                  </td>
                  <td style={{ width: "25%", height: 20, textAlign: "center" }}>
                    <span style={{ color: "#000000" }}>
                      <strong>Fees per Year</strong>
                    </span>
                    <br />
                  </td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td rowSpan={6} style={{ textAlign: "center" }}>
                    Diploma
                  </td>
                  <td>
                    Mechanical Engineering
                    <br />
                  </td>
                  <td rowSpan={6} style={{ textAlign: "center" }}>
                    3 Years
                  </td>
                  <td rowSpan={6} style={{ textAlign: "center" }}>
                    37,000/-
                  </td>
                </tr>
                <tr>
                  <td>Automobile Engineering</td>
                </tr>
                <tr>
                  <td>Computer Engineering</td>
                </tr>
                <tr>
                  <td>Information Technology</td>
                </tr>
                <tr>
                  <td>Electrical Engineering</td>
                </tr>
                <tr>
                  <td>Civil Engineering</td>
                </tr>

                <tr style={{ height: 20 }}>
                  <td rowSpan={6} style={{ textAlign: "center" }}>
                    Degree
                  </td>
                  <td>
                    Mechanical Engineering
                    <br />
                  </td>
                  <td rowSpan={6} style={{ textAlign: "center" }}>
                    4 Years
                  </td>
                  <td rowSpan={6} style={{ textAlign: "center" }}>
                    58,000/-
                  </td>
                </tr>
                <tr>
                  <td>Automobile Engineering</td>
                </tr>
                <tr>
                  <td>Computer Engineering</td>
                </tr>
                <tr>
                  <td>Information Technology</td>
                </tr>
                <tr>
                  <td>Electrical Engineering</td>
                </tr>
                <tr>
                  <td>Civil Engineering</td>
                </tr>

                <tr style={{ height: 20 }}>
                  <td rowSpan={6} style={{ textAlign: "center" }}>
                    ME
                  </td>
                  <td>
                    Mechanical (CAD/CAM)
                    <br />
                  </td>
                  <td rowSpan={6} style={{ textAlign: "center" }}>
                    2 Years
                  </td>
                  <td rowSpan={6} style={{ textAlign: "center" }}>
                    87,000/-
                  </td>
                </tr>
                <tr>
                  <td>Computer (Software Engineering)</td>
                </tr>
                <tr>
                  <td>Electrical Engineering</td>
                </tr>
                <tr>
                  <td>Civil (Structural Engineering)</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FeeStructure;
