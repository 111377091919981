import React from "react";

export default function Courses() {
  return (
    <div>
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-md-12">
            <p>
              <span style={{ fontSize: "18pt" }}>
                <strong>Courses</strong>
              </span>
              <br />
            </p>

            <p>
              <strong>All Courses Details:</strong>
              <br />
            </p>
            <table
              className="table table-bordered"
              style={{ borderCollapse: "collapse", width: "100%", height: 173 }}
              border={1}
            >
              <tbody>
                <tr style={{ height: 20 }}>
                  <td>
                    <strong>Sr No</strong>
                    <br />
                  </td>
                  <td>
                    <strong>Course Name</strong>
                    <br />
                  </td>
                  <td>
                    <strong>Duration</strong>
                    <br />
                  </td>
                  <td>
                    <strong>Seats</strong>
                    <br />
                  </td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td>1</td>
                  <td>B.Ed</td>
                  <td>2 Years</td>
                  <td>100 Seats</td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td>2</td>
                  <td>Ph.D</td>
                  <td>---</td>
                  <td>As per vacancy declared under research supervisor</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
