import React from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { Http } from "../../Services/Services";
import { SYLLABUS_GET3, SYLLABUS_GET4 } from "../../Utils/InfoUploadingConst";
import { LOCAL_DEPARTMENT } from "../../Utils/LocalStorageConstants";
import { ACADEMICS_ADD_CLASS, ACADEMICS_ADD_SEMESTER } from "../../Utils/Academics.apiConst";


function ArtsCommerceSyllabusDocs({ setLoading }) {
  const [data, setData] = useState([]);
  const [classData, setClassData] = useState([]);
  const [semesterData, setSemesterData] = useState([]);

  const navigate = useNavigate();

  const { year, semester } = useParams(); // Get the departmentId from the URL

  console.log("classId",year);

  const departmentOpt = JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT));

  const getData1 = async () => {
    const config = {
      method: "get",
      url: ACADEMICS_ADD_SEMESTER,
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios(config);
      setSemesterData(res.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  const getData = async () => {
    const config = {
      method: "get",
      url: SYLLABUS_GET4,
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios(config);
      res.data.data.sort((a, b) => b.id - a.id);
      res.data.data.forEach((element) => {
        element.attachments = JSON.parse(element.attachments);
    });
      setData(res.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  const getData2 = async () => {
    const config = {
      method: "get",
      url: ACADEMICS_ADD_CLASS,
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios(config);
      setClassData(res.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  console.log("classData", classData);

  useEffect(() => {
    getData();
    getData2();
  }, []);

  useEffect(() => {
    getData1();
  }, []);

  // Filter classData based on the departmentId
  const filteredClassData = data.filter((item) => item.year === year);

  return (
    <div>
      <div className="container mt-5 mb-5">
        <div className="row">
          <section className="course-section">
            <div className="container">
              <div className="section-title">
                <h2>Syllabus</h2>
              </div>
              <div className="sub-section-title">
                <h2>All Documents</h2>
              </div>  

<div className="row mbr-justify-content-center">
{filteredClassData.map((item, key) => (
  <div className="col-lg-6 mbr-col-md-10" key={item.id}>
      <div className="wrap">
        <div className="ico-wrap">
          <span
            className="mbr-iconfont fa fa-graduation-cap"
            style={{ color: "#fff" }}
          ></span>
        </div>
        <div className="text-wrap vcenter" key={item.id}>
          <h2
            className="mbr-fonts-style mbr-bold mbr-section-title3 display-5"
            style={{ color: "#fff" }}
          >
            {
              semesterData?.find(
                (s) => s.id == item.semester
              )?.name
            }
          </h2>

          <p
            className="mbr-fonts-style text1 mbr-text display-6" key={item.id}            
          >          
           <a href={item?.attachments} target="_blank" ><span><h5 className=" text-white">To get more details on Syllabus details,<span><h5 className=" text-primary">Click here</h5></span> </h5></span></a>
          </p>

        </div>
      </div>
  </div>
))}
</div>

            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default ArtsCommerceSyllabusDocs;