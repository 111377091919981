import React from 'react'

function Courses() {
  return (
    <div>

<div className="container mt-5 mb-5">


  <div className="row">
    <div className="col-md-12">
      <p>
        <span style={{ fontSize: "18pt" }}>
          <strong>
            B.H.M.S. (BACHELOR OF HOMOEOPATHIC MEDICINE AND SURGERY)
          </strong>
        </span>
        <br />
      </p>
      <p>
        Shree Swaminarayan Homoeopathy College, Kalol offers BACHELOR OF
        HOMOEOPAHTIC MEDICINE AND SURGERY (B.H.M.S.). This course is a regular
        full time course of 05 years and 06 months duration including 01 year
        compulsory rotatory internship. It is in English medium. The college is
        affiliated to the Gujarat University, Ahmedabad.
      </p>
      <p>
        The BHMS course is divided into four and a half years. At the end of
        each year the University conducts annual examination. The examinations
        include theory and viva / practical exams. The minimum passing marks in
        each subject is 50%. Students who pass in all the subjects promoted to
        the next year. In addition to the university exams, the college conducts
        periodic terminal and preliminary exams to evaluate the students'
        abilities. The results of such exams are also conveyed to the parents of
        the concerned student.
      </p>
      <p>
        <strong>B.H.M.S. Course Details:</strong>
        <br />
      </p>
      <table className="table table-bordered"
        style={{ borderCollapse: "collapse", width: "100%", height: 173 }}
        border={1}
      >
        <tbody>
          <tr style={{ height: 20 }}>
            <td style={{ width: "5.17544%", height: 20, textAlign: "center" }}>
              Sr. No.
              <br />
            </td>
            <td style={{ width: "10.4386%", height: 20 }}>
              BHMS
              <br />
            </td>
            <td style={{ width: "10.1755%", height: 20 }}>
              Duration
              <br />
            </td>
            <td style={{ width: "74.2105%", height: 20 }}>Subjects</td>
          </tr>
          <tr style={{ height: 73 }}>
            <td style={{ width: "5.17544%", height: 73, textAlign: "center" }}>
              1<br />
            </td>
            <td style={{ width: "10.4386%", height: 73 }}>
              BHMS - I<br />
            </td>
            <td style={{ width: "10.1755%", height: 73 }}>
              18 Months <br />(1 year & 6 Months)
              <br />
            </td>
            <td style={{ width: "74.2105%", height: 73 }}>
              <p>
                Anatomy <br />
                Physiology including&nbsp; Biochemistry <br />
                Homoeopathic Pharmacy <br />
                Repertory (no exam) <br />
                Homeopathic Materia Medica (Exam) <br />
                Organon Of Medicine (Exam)
                <br />
                Modern Pharmacology (no exam)
                <br />
              </p>
            </td>
          </tr>
          <tr style={{ height: 20 }}>
            <td style={{ width: "5.17544%", height: 20, textAlign: "center" }}>
              2<br />
            </td>
            <td style={{ width: "10.4386%", height: 20 }}>
              BHMS - II
              <br />
            </td>
            <td style={{ width: "10.1755%", height: 20 }}>12 Months <br />(1 year)</td>
            <td style={{ width: "74.2105%", height: 20 }}>
              Pathology and Microbiology <br />
              Forensic Medicine and Toxicolog <br />
              Organon of Medicine <br />
              Homoeopathic Materia Medica <br />
              Gynaecology & obstetrics (no exam) <br />
              Surgery (no exam) <br />
              Repertory (no exam)
            </td>
          </tr>
          <tr style={{ height: 20 }}>
            <td style={{ width: "5.17544%", height: 20, textAlign: "center" }}>
              3<br />
            </td>
            <td style={{ width: "10.4386%", height: 20 }}>
              BHMS - III
              <br />
            </td>
            <td style={{ width: "10.1755%", height: 20 }}>12 Months <br />(1 year)</td>
            <td style={{ width: "74.2105%", height: 20 }}>
              Surgery
              <br />
              Gynaecology and Obstetrics <br />
              Organon of Medicine <br />
              Homoeopathic Materia Medica <br />
              Community Medicine (no exam) <br />
              Practice of Medicine (no exam) <br />
              Repertory (no Exam)
            </td>
          </tr>
          <tr style={{ height: 20 }}>
            <td style={{ width: "5.17544%", height: 20, textAlign: "center" }}>
              4<br />
            </td>
            <td style={{ width: "10.4386%", height: 20 }}>
              BHMS - IV
              <br />
            </td>
            <td style={{ width: "10.1755%", height: 20 }}>
              12 Months <br />(1 Year)
              <br />
            </td>
            <td style={{ width: "74.2105%", height: 20 }}>
              Practice of Medicine <br />
              Community Medicine <br />
              Repertory <br />
              Organon of Medicine <br />
              Homoeopathic Materia Medica <br />
              Research Methodology & Biostatics <br />
              Yoga
            </td>
          </tr>
          <tr style={{ height: 20 }}>
            <td style={{ width: "5.17544%", height: 20, textAlign: "center" }}>
              5<br />
            </td>
            <td style={{ width: "10.4386%", height: 20 }}>
              Internship
              <br />
            </td>
            <td style={{ width: "10.1755%", height: 20 }}>12 Months <br />(1 year)</td>
            <td style={{ width: "74.2105%", height: 20 }}>
              Hospital / Clinical Training
              <br />
            </td>
          </tr>
        </tbody>
      </table>
      <p>
        <br />
      </p>
      <p>
        College provides hospital training at the PSM Homoeopathy Hospital
        &amp;&nbsp; PSM Hospital in the campus.
        <br />
      </p>
      <p>
        The students after passing the final year examination are supposed to
        undergo one year compulsory internship. This internship exposes them to
        various patients and prepare them for medical profession. It also
        provides them the much needed exposure and experience in the field of
        practice of Homoeopathy.
        <br />
      </p>
    </div>
  </div>
</div>




    </div>
  )
}

export default Courses