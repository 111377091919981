import React from 'react'

function Pgseats() {
  return (
   <div className="container mt-5" >
    <div className="row">
       
      
        <div className="col md-12 mt-5 mb-5">
            <h2 className='text-center'><u> PG Seats, In Progress......</u> </h2> <br></br>
        <img src='../assets/images/progress.svg' className='img-fluid' alt=""  srcset="" />
        </div>
    </div>
    </div>
  )
}

export default Pgseats