import React from 'react'

function StudentsList() {
  const banner1 = 'https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/1.jpeg'

  return (
    <div> <div className="container mt-5" >
    <div className="row">
       
        <div className="col-md-12 mb-5 mt-5">

            <h3>Applied for establishment of new medical college for MBBS course for the intake of 150 students</h3>

        </div>
        <div className="col md-12 mt-5 mb-5">
        <img src={banner1} alt="" srcset="" />
        </div>
    </div>
    </div></div>
  )
}

export default StudentsList