import React from "react";
import { Link, useParams } from "react-router-dom";

export default function Syllabus() {
  return (
    <div>
      <div className="container mt-5 mb-5">
        <div className="row">
          <section className="course-section">
            <div class="container">
              <div className="section-title">
                <h2>Syllabus</h2>
              </div>
              <div className="sub-section-title">
                <h2>All Programs</h2>
              </div>

              <div class="row mbr-justify-content-center">
                {/* card start */}

                <div className="col-lg-6 mbr-col-md-10">
                  <Link to="/nursing/Syllabus/degree-name/dept-name">
                    <div className="wrap">
                      <div className="ico-wrap">
                        <span
                          className="mbr-iconfont fa fa-graduation-cap"
                          style={{ color: "#fff" }}
                        ></span>
                      </div>
                      <div className="text-wrap vcenter">
                        <h2
                          className="mbr-fonts-style mbr-bold mbr-section-title3 display-5"
                          style={{ color: "#fff" }}
                        >
                          Degree Name Here
                        </h2>
                        <p
                          className="mbr-fonts-style text1 mbr-text display-6"
                          style={{ color: "#fff" }}
                        >
                          To get more details on Syllabus details, Click here
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>

                {/* card End  */}
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
