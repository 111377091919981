import React from 'react';
import axios from 'axios';
import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { HOSPITAL_STAFF_ATTENDANCE_GET } from '../../Utils/InfoUploadingConst';

function HospitalStaffAttendanceHomeo() {

    const [sessions, setSessions] = useState([]);
    const navigate = useNavigate();

    const getData = async () => {
        try {
            const response = await axios.get(HOSPITAL_STAFF_ATTENDANCE_GET, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const data = response.data.data.map(item => ({
                ...item,
                attachments: JSON.parse(item.attachments)
            }));
            // Group data by session
            const groupedData = data.reduce((acc, item) => {
                acc[item.session] = acc[item.session] || [];
                acc[item.session].push(item);
                return acc;
            }, {});

            setSessions(groupedData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        getData();
    }, []);

    const handleMonthClick = (attachments) => {
        if (attachments) {
            window.open(attachments, '_blank');
        }
    };

    return (
        <div>
            <br />
            <br />
            
            <div className="row">
              <div className="col-md-12">
                <h3 className="text-center text-primary">HOSPITAL STAFF ATTENDANCE</h3>
              </div>    
            </div>
            
            <div className="container mt-5 mb-5">
                {Object.keys(sessions)
                
                ?.map(session => {
                    const filteredSessions = sessions[session].filter(item => item.status === "ACTIVE" && item.college_id === 1111007);
                    if (filteredSessions.length > 0) {
                        return (
                    <div key={session}>
                        {/* <h4>Year : {session}</h4> */}
                        <h3>Year : <span><h3 style={{ color: 'rgb(0,0,146)', display: 'inline' }}>{session}</h3></span></h3>
                        <br />
                        <div className="row">
                            {Array.from({ length: 12 }, (_, index) => index + 1).map(month => (
                                <div key={month} className="col-2">
                                    {/* <div className="single-alumoni-updates-card"> */}
                                    <div 
                                    // className="single-alumoni-card bg-danger" 
                                    className={sessions[session]?.find(item => item.month.toUpperCase() === new Date(0, month - 1).toLocaleString('en-US', { month: 'long' }).toUpperCase())?.attachments ? "single-alumoni-card bg-danger" : "single-alumoni-card"}
                                    >
                                    <div className="align-items-center">
                                        <div>
                                            <h5>

                                                <a
                                                  href={sessions[session]?.find(item => item.month.toUpperCase() === new Date(0, month - 1).toLocaleString('en-US', { month: 'long' }).toUpperCase())?.attachments || "#"}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                >
                                                    <h4 className={sessions[session]?.find(item => item.month.toUpperCase() === new Date(0, month - 1).toLocaleString('en-US', { month: 'long' }).toUpperCase())?.attachments ? "text-light text-center" : "text-muted text-center"}>
                                                        {new Date(0, month - 1).toLocaleString('default', { month: 'long' })}
                                                    </h4>
                                                </a>

                                            </h5>
                                        </div>
                                    </div>
                                    </div>
                                    <br/>
                                    
                                </div>
                            ))}
                        </div>
                    </div>
               );
            } else {
                return null;
            }
        })}
            </div>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
        </div>
    );
}

export default HospitalStaffAttendanceHomeo;