import React from 'react';
import axios from 'axios';
import { useState,useEffect,useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { PCI_SIF_GET } from '../../Utils/InfoUploadingConst';

function PCI_SIF_Pharmacy() {

  const [data, setData] = useState([]);

  const navigate = useNavigate();

  const getData = async () => {
      const config = {
          method: "get",
          url: PCI_SIF_GET,
          headers: {
              "Content-Type": "application/json",
          },
      };

      await axios(config)
          .then((res) => {
              res.data.data.sort((a, b) => b.id - a.id);
              res.data.data.forEach((element) => {
                  element.attachments = JSON.parse(element.attachments);
              });
              console.log(res.data.data)
              setData(res.data.data);
          })
          .catch((err) => {
              console.log(err);
          });
  };

  useEffect(() => {
      getData();
  }, []);

  function formatSession(session) {
    // Assuming session is in the format "YYYY"
    const academicYear = `${session}-${parseInt(session, 10) + 1}`;
    return academicYear;
  }

  return (
    <div>

<div className="container mt-5 mb-5">
        <div className="row register" style={{ maxWidth: "100%" }}>
          <div className="col-md-12">
            <h3>
              PCI-SIF            
            </h3>
            <p>PCI-SIF documents Link are below</p>
          </div>

        
<div className="container">

  
<div className="alumni-area pt-100 pb-70">
<div className="row">

{data
        .filter((item) => item.status == 'ACTIVE' && item.college_id == 1111008)
        .map((item, key) => (
<div className="col-md-4">
<a href={item?.attachments} target="_blank">
  <div className="single-alumoni-updates-card">
  <div className=" d-flex flex-row flex-wrap align-items-center ">
    <div className=" bg-danger text-center p-3 me-3">
    <i class="ri-file-download-line text-light " style={{fontSize:"20px"}}></i>
    </div>
   <div className="">
    <p>
      {formatSession(item?.session)} PCI-SIF
    </p>
   </div>
  </div>
</div>
</a>
  </div>
)
)
}
  

</div>

</div>
</div>
         
        </div>
        <br />
        <br />
      </div>
      





{/* <div className="container register mb-5" style={{maxWidth:'1300px'}}>
  <div className="row">

  <div className="col-md-12">
      <h3> PCI-SIF List</h3>
  </div>

  <div className="row">

    {data
        .filter((item) => item.status == 'ACTIVE' && item.college_id == 1111008)
        .map((item, key) => (
            <div className="col-md-3 mt-5" key={key}>
              <h5>
              <a href={item?.attachments} target="_blank">
                {formatSession(item?.session)}
              </a>
              </h5>
            </div>
        ))
    }

    </div>
    
  </div>
</div> */}


    </div>
  )
}

export default PCI_SIF_Pharmacy;
