import React from "react";
import "./ExamCornerStyle.scss";
import axios from "axios";
import { useState, useEffect } from "react";
import { EXAM_NOTY_GET, EXAM_NOTY_GET1 } from "../../Utils/InfoUploadingConst";
import { Http } from "../../Services/Services";
import Loader from "../../Components/Loader/Loader.jsx";

function Notifications() {
  const [data, setData] = useState([]);
  const [facultyData, setfacultyData] = useState([]);

  const [flag, setFlag] = useState(false);

  const [faculty, setFaculty] = useState();

  const [loading, setLoading] = useState(0);

  // const getData = async () => {
  //   setLoading(1);
  //   await Http.get(EXAM_NOTY_GET1)
  //     .then((res) => {
  //       console.log(res.data.data);
  //       setFaculty(res.data.data[0].faculty);
  //       let x = new Set();
  //       res.data.data.forEach((element) => {
  //         x.add(element.faculty);
  //       });
  //       let y = Array.from(x);
  //       setfacultyData(y);
  //       setData(res.data.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  //   setLoading(0);
  // };

  const getData = async () => {
  setLoading(1);
  await Http.get(EXAM_NOTY_GET1)
    .then((res) => {
      // Sort the data array by date in descending order
      const sortedData = res.data.data.sort((a, b) => {
        return new Date(b.date) - new Date(a.date);
      });

      // Set the faculty and facultyData states
      setFaculty(sortedData[0]?.faculty);
      const faculties = [...new Set(sortedData.map(item => item.faculty))];
      setfacultyData(faculties);

      // Set the sorted data array in the state
      setData(sortedData);
    })
    .catch((err) => {
      console.log(err);
    });
  setLoading(0);
};

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="Notifications px-5">
      <Loader loading={loading} />
      <div className="courses-details-area pt-100 pb-70">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="courses-details">
                <div className="courses-card">
                  <h2>Exam Notifications</h2>
                </div>
                <div className="description">
                  <div className="container-fluid p-0">
                    <nav>
                      <div
                        className="nav nav-tabs d-flex justify-content-between"
                        id="nav-tab"
                        role="tablist"
                      >
                        {facultyData &&
                          facultyData?.map((item, key) => {
                            return (
                              <button
                                className={`nav-link ${
                                  faculty == item ? "active" : ""
                                }`}
                                id="nav-science-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#nav-science"
                                type="button"
                                role="tab"
                                onClick={() => {
                                  console.log(item);
                                  setFaculty(item);
                                  setFlag(true);
                                }}
                                aria-controls="nav-science"
                                aria-selected="false"
                              >
                                {item}
                              </button>
                            );
                          })}
                      </div>
                    </nav>
                    <div className="tab-content" id="nav-tabContent">
                      <div className="">
                        <div className="row align-items-center">
                          <div className="col-lg-12 table-responsive">
                            <table className="table notification">
                              <thead>
                                <tr>
                                  <th width="30%">Date</th>
                                  <th>Notification</th>
                                </tr>
                                {data &&
                                  data
                                    ?.filter((s) => s.faculty == faculty)
                                    ?.map((item, key) => {
                                      console.log(item.faculty);
                                      return (
                                        <tr>
                                          <td>{item?.date.split("T")[0]}</td>
                                          <td>
                                            {" "}
                                            <a
                                              href={item?.attachment}
                                              target="_blank"
                                            >
                                              {item?.title}
                                            </a>
                                          </td>
                                        </tr>
                                      );
                                    })}
                              </thead>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Notifications;
