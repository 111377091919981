import React, { useState } from "react";
import { Helmet } from "react-helmet";
import "./Payment.scss";
import {
  newFacultyData,
  newProgramData,
  specializationData,
  departmentData
} from "./../../Data/admissionData";
import   OTP_GENRATE,{ COLLEGE, VERIFY_OTP } from "../../Utils/apiConst";
import axios from 'axios'
import { toast } from 'react-toastify'
import Loader from "../../Components/Loader/Loader";
import { useEffect } from "react";

const Payment = () => {

  // const facultyData = newFacultyData
  const programData = newProgramData
  const [program, setProgram] = useState('')
  const [college, setCollege] = useState('')
  const [department, setDepartment] = useState('')
  const [name, setName] = useState('')
  const [usn, setUsn] = useState('')
  const [amount, setAmount] = useState(0)
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [otp, setOtp] = useState('')
  const [collegeId, setCollegeId] = useState('')

  const [loading, setLoading] = useState(0)

  const [programOptions, setProgramOptions] = useState(programData);



  const [collegeOptions, setCollegeOptions] = useState([]);

  const [departmentOptions, setDepartmentOptions] = useState([])



  const clearData = () => {
    setProgram('')
    setCollege('')
    setName('')
    setPhone('')
    setEmail('')
    setOtp('')
    setCollegeId('')
  }

  const getData = async () => {
    const config = {
      method: 'get',
      url: COLLEGE,
      headers: {
        'Content-Type': 'application/json'
      },
    }

    await axios(config)
      .then(res => {
        console.log(res);
        setCollegeOptions(res.data.data)
      })
      .catch(err => {
        console.log(err);
      })
  }


  // SEND_OTP

  const otpSender = async () => {

    const otpObj = {
      "phone": phone
    };

    var config = {
      method: 'post',
      url: OTP_GENRATE,
      headers: {
        'Content-Type': 'application/json'
      },
      data: otpObj
    };
    setLoading(1)
    await axios(config)
      .then(res => {
        setLoading(0)
        console.log(res);
        toast.success(`otp sent successfully`)
      })
      .catch(err => {
        setLoading(0)
        console.log(err);
        toast.error(err.response.data.message)
      })
  }


  const sendOtp = (e) => {
    if (!phone) {
      toast.error("phone is required");
      alert("Phone number is Required")
      return;
    }
    otpSender();

  }



  const generateRandomCode = () => {
    const characters = 'abcdefghijklmnopqrstuvwxyz12345678';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < 8; i++) {
      result +=
        characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result
  }

  const [randomCode, setRandomCode] = useState(generateRandomCode())

  const [transactionId, setTransactionId] = useState(`SUKALOL-${phone}-${randomCode}`)

  useEffect(() => {
    setTransactionId(`SUKALOL-${phone}-${randomCode}`)
  }, [phone, randomCode])


  const getPaymentFolder = () => {

    switch (college) {
      case '1111000':
        console.log("in college")
        if (program == "02") {
          return "diploma"
        }
        else if (program == "04") {
          return "engineering"
        }
        else if (program == "05") {
          return "master_eng"
        }
      case '1111001': return "ayurveda"
      case '1111002': return "law"
      case '1111003':
        if (program == "04") {
          if (department == '36') {
            return "pbbsc"
          }
          return "bsc"
        } else if (program == "02") {
          if (department == '37') {
            return "gnm"
          } else if (department == '38') {
            return "anm"
          }
        }
      case '1111005': return "education"
      case '1111007': return "homeopathy"
      case '1111008': return "pharmacy"
      case '1111011': return "science"
      case '1111012': return "university"
      // case 1111013: return "doctoral"





    }

    return null
  }

  const handlePay = () => {

    const url = `https://umsapi.nexenstial.com/${getPaymentFolder()}/meTrnPay.php?OrderId=${transactionId}&amount=${parseFloat(amount) + "00.00"}&usn=${usn}&program=${program}&college=${college}&department=${department}&phone=${phone}&fee_id=01&fee_type=aa`
    setTransactionId(`SUKALOL-${phone}-${randomCode}`)
    sessionStorage.setItem('trx_id', transactionId)
    console.log(JSON.stringify(url))
    window.open(url)
    // setFinalTransactionId(transactionId)
  }

  useEffect(() => {
    getData()
  }, [])


  useEffect(() => {
    if (program) {

      setCollegeOptions(newFacultyData)
    }
  }, [program])

  return (
    <div className="AdmissionEnquiry">
      <Loader loading={loading} />
      <Helmet>
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/css/intlTelInput.css"
        />
        <script src="https://code.jquery.com/jquery-3.5.1.min.js"></script>

        <script src="https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/intlTelInput.min.js"></script>
        <script src="cities.js"></script>
      </Helmet>

      <div className="register-area mt-2 pb-70 ">
        <div className="container">
          <div className="register mw-sm-75 mw-xl-50">
            <h3>Online Fees Payment</h3>
            <div className="row">
              <div className="col-md-12 form-group auth-form-group-custom mb-4">
                <i className="ri-user-2-line auti-custom-input-icon" />
                <label htmlFor="username">Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="username"
                  name="name"
                  placeholder="Enter Name"
                  value={name}
                  onChange={(e) => { setName(e.target.value) }}
                />
              </div>
              <div className="col-md-12 form-group auth-form-group-custom mb-4">
                <i className="ri-account-pin-circle-line auti-custom-input-icon" />
                <label htmlFor="username">Enrollment Number</label>
                <input
                  type="text"
                  className="form-control"
                  id="username"
                  name="name"
                  placeholder="Enter Enrollment Number"
                  value={usn}
                  onChange={(e) => { setUsn(e.target.value) }}
                />
              </div>
              {/* <div className="col-md-6 form-group auth-form-group-custom mb-4">
                <i class="ri-mail-line auti-custom-input-icon"></i>
                <label htmlFor="username">Email</label>
                <input
                  type="email"
                  className="form-control"
                  id="username"
                  name="username"
                  placeholder="Enter Email"
                  value={email}
                  onChange={(e) => { setEmail(e.target.value) }}
                />
              </div> */}
              <div className="col-md-12 form-group auth-form-group-custom mb-3">
                <i className="ri-phone-line auti-custom-input-icon" />
                <label htmlFor="username">Phone</label>
                <input
                  type="tel"
                  className="form-control"
                  id="username"
                  name="username"
                  placeholder="Enter Phone"
                  value={phone}
                  onChange={(e) => { setPhone(e.target.value) }}
                />
                {/* <i className="ri-phone-line auti-custom-input-icon" /> */}
              </div>

              {/* {phone.length >= 10 ? */}
              {/* <div className={`col-12 mt-0 p-0 mb-3 d-flex justify-content-end`}>
                  <a
                    href="javascript:void(0)"
                    // onClick={(e) => sendOtp(e)}
                    className={`mt-1 p-2 btn btn-primary`}
                  >
                    GET OTP
                  </a>
                </div> */}
              {/* : null} */}


              <div className="col-12 form-group auth-form-group-custom mb-4">
                <i className="ri-briefcase-3-line auti-custom-input-icon" />
                <label htmlFor="username">Select Program</label>
                <select
                  id="role"
                  name="role"
                  className="form-control"
                  autoComplete="off"
                  value={program}
                  onChange={(e) => { setProgram(e.target.value) }}
                >
                  <option value="">Select</option>
                  {
                    programOptions?.map((i, key) => (
                      <option value={i?.id}>{i?.name}</option>
                    ))
                  }
                </select>
              </div>

              <div className="col-12 form-group auth-form-group-custom mb-4">
                <i className="ri-briefcase-3-line auti-custom-input-icon" />
                <label htmlFor="username">Select Faculty</label>
                <select
                  id="role"
                  name="role"
                  className="form-control"
                  autoComplete="off"
                  value={college}
                  onChange={(e) => { setCollege(e.target.value) }}
                >
                  <option value="">Select</option>
                  {
                    program && collegeOptions.map((i, key) => (
                      <option value={i?.id}>{i?.name}</option>
                    ))
                  }
                </select>
              </div>

              <div className="col-12 form-group auth-form-group-custom mb-4">
                <i className="ri-briefcase-3-line auti-custom-input-icon" />
                <label htmlFor="username">Select Department</label>
                <select
                  id="role"
                  name="role"
                  className="form-control"
                  autoComplete="off"
                  value={department}
                  onChange={(e) => { setDepartment(e.target.value) }}
                >
                  <option value="">Select</option>
                  {
                    college && departmentData.filter(item => item.college_id == college && item.program_id == program)?.map((i, key) => (
                      <option value={i?.id}>{i?.name}</option>
                    ))
                  }
                </select>
              </div>



              <div className="col-12 form-group auth-form-group-custom mb-4">
                <i className="ri-briefcase-3-line auti-custom-input-icon" />
                <label htmlFor="username">Class / Year</label>
                <input
                  id="role"
                  name="role"
                  className="form-control"
                  placeholder="Class / Year"
                />

              </div>


              <div className="col-12 form-group auth-form-group-custom mb-4">
                <i className="ri-briefcase-3-line auti-custom-input-icon" />
                <label htmlFor="username">Amount</label>
                <input
                  id="role"
                  name="role"
                  type="number"
                  className="form-control"
                  placeholder="Enter Amount to be paid"
                  onChange={(e) => setAmount(e.target.value)}
                />

              </div>

              <div className="col-12 form-group auth-form-group-custom mb-4">
                <i className="ri-briefcase-3-line auti-custom-input-icon" />
                <label htmlFor="username">Enter Fee Type and remarks</label>
                <textarea
                  id="role"
                  name="role"
                  className="form-control"
                  placeholder="Note / Remarks"
                  rows={4}
                />

              </div>

              <div className="mt-4 text-center">
                <button
                  className="btn btn-primary w-md waves-effect waves-light"
                  name="submit"
                  value="login"
                  onClick={() => {
                    handlePay();
                  }}
                >
                  Pay Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Helmet>
        <script language="javascript">print_state("sts");</script>
      </Helmet>
    </div>
  );
};

export default Payment;
