import React from "react";
import { useLocation } from "react-router-dom";
// import { useState, useEffect } from "react";

// function ResearchDetails() {
//   const locate = useLocation();

//   let images = locate.state.images;
//   let data = locate.state.data;

//   console.log("images",images)

//   return (
//     <div>
//       <div className="container">
//         <div className="row">
//           <div className="col-lg-10">
//             <div className="events-details-left-content pr-20">
//               {images.length > 1 ? (
//                 <div
//                   id="carouselExampleControls"
//                   className="carousel slide"
//                   data-bs-ride="carousel"
//                 >

//                   <div className="carousel-inner">
//                     {images &&
//                       images?.map((item, key) => {
//                         return (
//                           <>
//                             <div className="carousel-item active">
//                               <img
//                                 src={item}
//                                 className="img-responsive d-block w-100"
//                                 loading="lazy"
//                                 width="100%"
//                                 alt="Home"
//                               />
//                             </div>
//                           </>
//                         );
//                       })}
//                   </div>
                
//                 </div>
//               ) : (
//                 <div className="events-image text-center">
//                   {/* <img
//                     style={{ objectFit: "fit", height: "800px" }}
//                     src={images[0]}
//                     alt="Image"
//                   /> */}
//                 </div>
//               )}
//               <div className="meetings mt-3">
//                 <h2>{data?.title}</h2>
              
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

function ResearchDetails() {
  const locate = useLocation();
  const images = Array.isArray(locate.state.images) ? locate.state.images : [];
  const data = locate.state.data;
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-lg-10">
            <div className="events-details-left-content pr-20">
              {images.length > 1 ? (
                <div
                  id="carouselExampleControls"
                  className="carousel slide"
                  data-bs-ride="carousel"
                >
                  <div className="carousel-inner">
                    {images.map((item, key) => (
                      <div
                        className={`carousel-item ${key === 0 ? "active" : ""}`}
                        key={key}
                      >
                        <img
                          src={item}
                          className="img-responsive d-block w-100"
                          loading="lazy"
                          width="100%"
                          alt={`Image ${key + 1}`}
                        />
                      </div>
                    ))}
                  </div>
                  <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target="#carouselExampleControls"
                    data-bs-slide="prev"
                  >
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                  </button>
                  <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-target="#carouselExampleControls"
                    data-bs-slide="next"
                  >
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                  </button>
                </div>
              ) : images.length === 1 ? (
                <div className="events-image text-center">
                  <img
                    style={{ objectFit: "fit", height: "800px" }}
                    src={images[0]}
                    alt="Image"
                  />
                </div>
              ) : null}
              <div className="meetings mt-3">
                <h2>{data.title}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResearchDetails;
