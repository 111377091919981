import React from 'react'
import './OfficerProfile.scss'
import { useLocation } from 'react-router-dom'

const OfficerProfile = () => {
    const location = useLocation()
    const data = location?.state?.data
    console.log(data);
  return (
    <div className='OfficerProfile'>
  <h1 className="text-center">Profile</h1>
  <div className="container p-4">
    <div className="row">
      <div className="col-md-4 ">
        <img src={data?.photo} className="rounded" alt="" />
      </div>
      <div className="col-md-8">
        <h3 className="names">{data?.name}</h3>
        <h6>{data?.role}</h6>
         <p>{data?.details?.map((i,key)=>(
          <>
            {i}
            <br></br>
            <br></br>
          </>
         ))}
         </p>
      </div>
    </div>
    {
      data?.quote&&
    <div className="row justify-content-center align-content-center mt-5">
      <div className="col-md-12">
        <blockquote className="otro-blockquote">
            {data?.quote}
          <span>- {data?.name}</span>
        </blockquote>
      </div>
    </div>
    }
    {/*?php } ?*/}
  </div>

    </div>
  )
}

export default OfficerProfile