import React from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../Router/RouterConfig";
import { profileData } from "../../Pages/About/AutoritiesOfUniversity/ProfileData";
import "./Navbar.scss";
import axios from "axios";
import { useState, useRef, useEffect } from "react";
import { toast } from "react-toastify";
import {
  ACADEMIC_CALENDAR_GET,
  ADD_COMMITTEES,
  STAFF_DETAILS_NEW_GET,
} from "../../Utils/InfoUploadingConst";

function Navbar_nursing() {
  const navigate = useNavigate();

  const changeDir = (i) => {
    navigate(ROUTES.Profile, { state: { data: i } });
  };

  const [info, setInfo] = useState([]);
  const [data, setData] = useState([]);
  const [staffdata, setStaffData] = useState([]);

  const getData = async () => {
    const config = {
      method: "get",
      url: ADD_COMMITTEES,
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        res.data.data.forEach((element) => {
          element.attachment = JSON.parse(element.attachment);
        });
        res.data.data.sort((a, b) => b.id - a.id);
        const activeData = res.data.data.filter(
          (element) =>
            element.status === "ACTIVE" && element.college_id === 1111003
        );
        // console.log("activeData -", activeData);
        setInfo(activeData);
      })
      .catch((err) => {
        console.log(err);
      });

    const config2 = {
      method: "get",
      url: ACADEMIC_CALENDAR_GET,
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios(config2)
      .then((res) => {
        // res.data.data.sort((a, b) => b.session - a.session);
        res.data.data.forEach((element) => {
          element.attachments = JSON.parse(element.attachments);
        });
        res.data.data.sort((a, b) => b.id - a.id);
        // console.log(res.data.data)
        const feeData = res.data.data.filter(
          (element) =>
            element.status === "ACTIVE" && element.college_id === 1111003
        );
        setData(feeData);
      })
      .catch((err) => {
        console.log(err);
      });

    const config3 = {
      method: "get",
      url: STAFF_DETAILS_NEW_GET,
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios(config3)
      .then((res) => {
        res.data.data.forEach((element) => {
          element.attachment = JSON.parse(element.attachment);
        });
        res.data.data.sort((a, b) => b.id - a.id);
        // console.log(res.data.data)
        const feeData = res.data.data.filter(
          (element) =>
            element.status === "ACTIVE" && element.college_id === 1111003
        );
        setStaffData(feeData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="Navbar-nursing">
      <div className="top-header-area">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-4 col-sm-4">
              <div className="header-right-content">
                <marquee className="scroll-text text-white">
                  <p className="text-white">
                    Toll free number to report ragging :{" "}
                    <a href="tel:1800 843 2900" className="text-light">
                      1800 843 2900
                    </a>{" "}
                  </p>
                </marquee>
              </div>
            </div>

            <div className="col-lg-8 col-sm-8">
              <div className="header-right-content">
                <div className="list">
                  <ul>
                    <li className="nav-item">
                      <Link to={"/nursing/Recruitment"} className="nav-link">
                        Recruitment
                      </Link>
                    </li>
                    <li>
                      <a
                        href="https://ums.swaminarayanuniversity.ac.in/"
                        target="_blank"
                      >
                        UMS Login
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <>
        <div className="navbar-area nav-bg-1">
          <div className="mobile-responsive-nav">
            <div className="container">
              <div className="mobile-responsive-menu">
                <div className="logo">
                  <a href="/nursing">
                    <img
                      src="/assets/images/white-logo.png"
                      className="main-logo"
                      alt="logo"
                    />
                    <img
                      src="/assets/images/white-logo.png"
                      className="white-logo"
                      alt="logo"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="desktop-nav">
            <div className="container-fluid">
              <nav className="navbar navbar-expand-md navbar-light">
                <a className="navbar-brand" href="/nursing">
                  <img
                    src="/assets/images/white-logo.png"
                    width="250px"
                    alt="logo"
                  />
                </a>
                <h5 class="text-left">
                  Shree Swaminarayan College of Nursing , Kalol
                </h5>
                <div
                  className="collapse navbar-collapse mean-menu"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav ms-auto">
                    <li className="nav-item">
                      <Link to={"/nursing"}>Home</Link>
                    </li>
                    <li className="dropdown nav-item">
                      <a
                        aria-expanded="true"
                        aria-haspopup="true"
                        role="button"
                        data-toggle="dropdown"
                        className="dropdown-toggle"
                        href="#"
                        title="Overview"
                      >
                        Overview <span className="caret" />
                      </a>
                      <ul className="dropdown-menu nav-item">
                        <li className="nav-item">
                          <Link to={"/nursing/AboutUs"}>About SSVG</Link>
                        </li>
                        <li className="nav-item">
                          <Link to={"/nursing/FoundersMessage"}>
                            Founder Message
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to={"/nursing/PrincipalMessage"}>
                            Principal Message
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to={"/nursing/MissionVision"}>
                            Mission Vision
                          </Link>
                        </li>
                        <li className="dropdown nav-item">
                          <a
                            aria-expanded="true"
                            aria-haspopup="true"
                            role="button"
                            data-toggle="dropdown"
                            className="dropdown-toggle"
                            href="#"
                            title="Overview"
                          >
                            Office Bearers <span className="caret" />
                          </a>
                          <ul className="dropdown-menu nav-item">
                            <li className="nav-item">
                              {/* <a
                                onClick={() => {
                                  changeDir(profileData[0]);
                                }}
                                className="nav-link custom-div-link"
                              >
                                President
                              </a> */}
                              <Link
                                to={"/nursing/President"}
                                className="nav-link"
                              >
                                President
                              </Link>
                            </li>
                            <li className="nav-item">
                              {/* <Link to={"/VicePresidents"} className="nav-link">
                                Vice Presidents
                              </Link> */}
                              <Link
                                to={"/nursing/VicePresidentMsg"}
                                className="nav-link"
                              >
                                Vice Presidents
                              </Link>
                            </li>
                            <li className="nav-item">
                              {/* <a
                                onClick={() => {
                                  changeDir(profileData[4]);
                                }}
                                className="nav-link"
                              >
                                Provost (Vice-Chancellor)
                              </a> */}
                              <Link
                                to={"/nursing/Provost"}
                                className="nav-link"
                              >
                                Provost (Vice-Chancellor)
                              </Link>
                            </li>
                            <li className="nav-item">
                              {/* <a
                                onClick={() => {
                                  changeDir(profileData[7]);
                                }}
                                className="nav-link custom-div-link"
                              >
                                Registrar
                              </a> */}
                              <Link
                                to="/nursing/Registrar-Profile"
                                className="nav-link"
                              >
                                Registrar
                              </Link>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li className="dropdown nav-item">
                      <a
                        aria-expanded="true"
                        aria-haspopup="true"
                        role="button"
                        data-toggle="dropdown"
                        className="dropdown-toggle"
                        href="#"
                        title="College"
                      >
                        College <span className="caret" />
                      </a>
                      <ul className="dropdown-menu nav-item">
                        <li className="nav-item">
                          <Link to={"/nursing/AboutCollege"}>
                            About College
                          </Link>
                        </li>

                        {/* <li className="dropdown nav-item">
                          <a
                            aria-expanded="true"
                            aria-haspopup="true"
                            role="button"
                            data-toggle="dropdown"
                            className="dropdown-toggle"
                            href="#"
                            title="Overview"
                          >
                            Courses <span className="caret" />
                          </a>
                          <ul className="dropdown-menu nav-item">
                            <li className="nav-item">
                              <a
                                href="/assets/nursing/documents/bsc-course-of-instruction.pdf"
                                target="_blank"
                                className="nav-link custom-div-link"
                              >
                                BSC Course of Instruction
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                href="/assets/nursing/documents/msc-course-of-instruction.pdf"
                                target="_blank"
                                className="nav-link custom-div-link"
                              >
                                MSC Course of Instruction
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                href="/assets/nursing/documents/post-basic-course-of-instruction.pdf"
                                target="_blank"
                                className="nav-link custom-div-link"
                              >
                                Post Basic Course of Instruction
                              </a>
                            </li>
                          </ul>
                        </li> */}

                        <li className="nav-item">
                          <Link to="/nursing/Permission" title="Permission">
                            Permission
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to="/nursing/Affiliation" title="Affiliation">
                            Affiliation
                          </Link>
                        </li>
                        {/* <li className="nav-item">
              <a href="#" title="Facilities">
                Facilities
              </a>
            </li>
         <li className="nav-item nav-item">
              <a href="#" title="Departments">
                Departments
              </a>
            </li> */}
                      </ul>
                    </li>
                    <li className="dropdown nav-item">
                      <a
                        aria-expanded="true"
                        aria-haspopup="true"
                        role="button"
                        data-toggle="dropdown"
                        className="dropdown-toggle"
                        href="#"
                        title="Academics"
                      >
                        Academics <span className="caret" />
                      </a>
                      <ul className="dropdown-menu">
                        <li className="nav-item">
                          <Link to={"/nursing/Eligibility"}>
                            {/* Eligibility */}
                            Admission details with Eligibility Criteria
                          </Link>
                        </li>

                        {data?.filter((d) => d.type === "Fee Structure")
                          .length !== 0 ? (
                          data
                            ?.filter((d) => d.type === "Fee Structure")
                            ?.map((item, index) => (
                              <li className="nav-item" key={index}>
                                <a
                                  href={item?.attachments}
                                  target="_blank"
                                  className="nav-link"
                                >
                                  Fee Structure
                                </a>
                              </li>
                            ))
                        ) : (
                          <li className="nav-item">
                            <a href="#" target="_blank" className="nav-link">
                              Fee Structure
                            </a>
                          </li>
                        )}

                        {/* <li className="nav-item">
                          <Link to={"/nursing/FeeStructure"}>
                            {" "}
                            Fee Structure
                          </Link>
                        </li> */}

                        {data?.filter((d) => d.type === "Courses Details")
                          .length !== 0 ? (
                          data
                            ?.filter((d) => d.type === "Courses Details")
                            ?.map((item, index) => (
                              <li className="nav-item" key={index}>
                                <a
                                  href={item?.attachments}
                                  target="_blank"
                                  className="nav-link"
                                >
                                  Courses
                                </a>
                              </li>
                            ))
                        ) : (
                          <li className="nav-item">
                            <a href="#" target="_blank" className="nav-link">
                              Courses
                            </a>
                          </li>
                        )}

                        <li className="nav-item">
                          <Link to="/nursing/RulesandRegulations">
                            Rules & Regulations
                          </Link>

                          {/* <a href="../assets/nursing/documents/rules-and-regulations.pdf">
                            {" "}
                            Rules & Regulations{" "}
                          </a> */}
                        </li>

                        {/* <li className="nav-item">
                          <Link to={"/nursing/Achievements-Awards-Medals"}>Achievements</Link>
                        </li> */}

                        <li className="nav-item">
                          <Link to={"/nursing/AcademicCalendar"}>
                            {" "}
                            Academic Calendar
                          </Link>
                        </li>

                        <li className="nav-item">
                          <a href="#" className="nav-link dropdown-toggle">
                            Committees
                          </a>
                          <ul className="dropdown-menu">
                            {info.filter(
                              (d) => d.type === "Anti-Ragging Committee"
                            ).length !== 0 ? (
                              info
                                .filter(
                                  (d) => d.type === "Anti-Ragging Committee"
                                )
                                ?.map((item, index) => (
                                  <li className="nav-item" key={index}>
                                    <a
                                      href={item?.attachment}
                                      target="_blank"
                                      className="nav-link"
                                    >
                                      Anti-Ragging Committee
                                    </a>
                                  </li>
                                ))
                            ) : (
                              <li className="nav-item">
                                <a href="" target="_blank" className="nav-link">
                                  Anti-Ragging Committee
                                </a>
                              </li>
                            )}

                            {info.filter(
                              (d) => d.type === "Admission Committee"
                            ).length !== 0 ? (
                              info
                                .filter((d) => d.type === "Admission Committee")
                                ?.map((item, index) => (
                                  <li className="nav-item" key={index}>
                                    <a
                                      href={item?.attachment}
                                      target="_blank"
                                      className="nav-link"
                                    >
                                      Admission Committee
                                    </a>
                                  </li>
                                ))
                            ) : (
                              <li className="nav-item">
                                <a href="" target="_blank" className="nav-link">
                                  Admission Committee
                                </a>
                              </li>
                            )}

                            {info.filter(
                              (d) => d.type === "Curriculum Committee"
                            ).length !== 0 ? (
                              info
                                .filter(
                                  (d) => d.type === "Curriculum Committee"
                                )
                                ?.map((item, index) => (
                                  <li className="nav-item" key={index}>
                                    <a
                                      href={item?.attachment}
                                      target="_blank"
                                      className="nav-link"
                                    >
                                      Curriculum Committee
                                    </a>
                                  </li>
                                ))
                            ) : (
                              <li className="nav-item">
                                <a href="" target="_blank" className="nav-link">
                                  Curriculum Committee
                                </a>
                              </li>
                            )}

                            {info.filter((d) => d.type === "Library Committee")
                              .length !== 0 ? (
                              info
                                .filter((d) => d.type === "Library Committee")
                                ?.map((item, index) => (
                                  <li className="nav-item" key={index}>
                                    <a
                                      href={item?.attachment}
                                      target="_blank"
                                      className="nav-link"
                                    >
                                      Library Committee
                                    </a>
                                  </li>
                                ))
                            ) : (
                              <li className="nav-item">
                                <a href="" target="_blank" className="nav-link">
                                  Library Committee
                                </a>
                              </li>
                            )}

                            {info.filter(
                              (d) => d.type === "Discipline Committee"
                            ).length !== 0 ? (
                              info
                                .filter(
                                  (d) => d.type === "Discipline Committee"
                                )
                                ?.map((item, index) => (
                                  <li className="nav-item" key={index}>
                                    <a
                                      href={item?.attachment}
                                      target="_blank"
                                      className="nav-link"
                                    >
                                      Discipline Committee
                                    </a>
                                  </li>
                                ))
                            ) : (
                              <li className="nav-item">
                                <a href="" target="_blank" className="nav-link">
                                  Discipline Committee
                                </a>
                              </li>
                            )}

                            {info.filter((d) => d.type === "Advisory Committee")
                              .length !== 0 ? (
                              info
                                .filter((d) => d.type === "Advisory Committee")
                                ?.map((item, index) => (
                                  <li className="nav-item" key={index}>
                                    <a
                                      href={item?.attachment}
                                      target="_blank"
                                      className="nav-link"
                                    >
                                      Advisory Committee
                                    </a>
                                  </li>
                                ))
                            ) : (
                              <li className="nav-item">
                                <a href="" target="_blank" className="nav-link">
                                  Advisory Committee
                                </a>
                              </li>
                            )}

                            {info.filter((d) => d.type === "SNA Committee")
                              .length !== 0 ? (
                              info
                                .filter((d) => d.type === "SNA Committee")
                                ?.map((item, index) => (
                                  <li className="nav-item" key={index}>
                                    <a
                                      href={item?.attachment}
                                      target="_blank"
                                      className="nav-link"
                                    >
                                      SNA Committee
                                    </a>
                                  </li>
                                ))
                            ) : (
                              <li className="nav-item">
                                <a href="" target="_blank" className="nav-link">
                                  SNA Committee
                                </a>
                              </li>
                            )}
                          </ul>
                        </li>

                        {/* <li className="nav-item">
                          <Link to={"/nursing/Hospital-Staff-Attendance"}>
                            Hospital Staff Attendance
                          </Link>
                        </li> */}

                        {/* <li className="nav-item">
                          <Link to={"/nursing/Placements"}>Placements</Link>
                        </li> */}
                        {/* <li className="dropdown nav-item">
                          <a
                            aria-expanded="true"
                            aria-haspopup="true"
                            role="button"
                            data-toggle="dropdown"
                            className="dropdown-toggle"
                            href="#"
                            title="Overview"
                          >
                            Research Activity
                            <span className="caret" />
                          </a>
                          <ul className="dropdown-menu nav-item">
                            <li className="nav-item">
                              <a
                                href="/assets/nursing/documents/SSCON-Articles published.pdf"
                                target="_blank"
                                className="nav-link custom-div-link"
                              >
                                SSCON-Articles published
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                href="/assets/nursing/documents/SSCON-book published.pdf"
                                target="_blank"
                                className="nav-link custom-div-link"
                              >
                                SSCON-book published
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                href="/assets/nursing/documents/SSCON-FDP 2023.pdf"
                                target="_blank"
                                className="nav-link custom-div-link"
                              >
                                SSCON-FDP 2023
                              </a>
                            </li>
                          </ul>
                        </li> */}

                        <li className="nav-item">
                          <Link to={"/nursing/NursingResearchandPublication"}>
                            {" "}
                            Research Activity
                          </Link>
                        </li>

                        {/* <li className="dropdown nav-item">
                          <a
                            aria-expanded="true"
                            aria-haspopup="true"
                            role="button"
                            data-toggle="dropdown"
                            className="dropdown-toggle"
                            href="#"
                            title="Overview"
                          >
                            Syllabus
                            <span className="caret" />
                          </a>
                          <ul className="dropdown-menu nav-item">
                            <li className="nav-item">
                              <Link
                                to="/nursing/Syllabus/bsc"
                                className="nav-link custom-div-link"
                              >
                                B.Sc. Nursing
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                to="/nursing/Syllabus/post-basic-bsc"
                                className="nav-link custom-div-link"
                              >
                                Post Basic B.Sc. Nursing
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                to="/nursing/Syllabus/msc"
                                className="nav-link custom-div-link"
                              >
                                M.Sc. Nursing
                              </Link>
                            </li>
                          </ul>
                        </li> */}

                        <li className="nav-item">
                          <Link to={"/nursing/Syllabus"}> Syllabus</Link>
                        </li>
                        {/* <li className="nav-item">
                          <Link to={"/nursing/studentList"}>
                            Students Lists
                          </Link>
                        </li> */}
                        {/* <li className="nav-item">
                          <Link to={"/nursing/resultsList"}>
                            Students Results
                          </Link>
                        </li> */}
                      </ul>
                    </li>
                    {/* <li className="dropdown nav-item">
                      <a
                        aria-expanded="true"
                        aria-haspopup="true"
                        role="button"
                        data-toggle="dropdown"
                        className="dropdown-toggle"
                        href="#"
                        title="Staff"
                      >
                        Staff <span className="caret" />
                      </a>
                      <ul className="dropdown-menu">
                        
                        <li className="nav-item">
                          <Link to="/nursing/TeachingStaff">
                            {" "}
                            Teaching Staff
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to="/nursing/non-teachingStaff">
                            {" "}
                            Non-Teaching Staff
                          </Link>
                        </li>

                       
                      </ul>
                    </li> */}

                    {/* <li className="nav-item">
                          <Link to={"/nursing/PrincipalMessage"}>
                            Principal
                          </Link>
                        </li> */}

                    {/* <li className="nav-item">
         <Link to={"/nursing/AdminStaff"}>Admin Staff</Link>

            </li> */}
                    <li className="nav-item">
                      <Link to={"/nursing/events"}>Events</Link>
                    </li>
                    <li className="nav-item">
                      <Link to={"/nursing/ContactUs"}>Contact Us</Link>
                    </li>
                    <li className="nav-item">
                      <a href="#" title="Member Menu">
                        <i className="glyphicon glyphicon-user" />
                      </a>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

export default Navbar_nursing;
