import React from 'react'

function UGCinformation() {
  return (
    <div>
  <div className="courses-details-area pt-100 pb-70">
  <div className="container">
    <div className="row">
      <div className="col-lg-12">
        <div className="courses-details">
          <div className="courses-card">
            <h2>Information Submitted to UGC</h2>
          </div>
          <div className="description">

          <object
                          height={1000}
                          data="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/UGC+Reduced.pdf#toolbar=0"
                          type="application/pdf"
                          width="100%"
                        >
                          <p>
                            It appears you don't have a PDF plugin for this
                            browser. No biggie... you can{" "}
                            <a href="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/UGC+Reduced.pdf#toolbar=0">
                              click here to download the PDF file.
                            </a>
                          </p>
                        </object>
          </div>

          </div>
          </div>
          </div>
          </div>
          </div>    
    </div>
  )
}

export default UGCinformation