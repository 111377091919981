import React from 'react'

function Schools() {
  return (
    <div className='Schools'>
        <>
  <div className="campus-life-area pt-100 pb-70">
    <div className="container">
      <h3 className="text-center mb-4 ">School</h3>
      <p className="text-center">
        Swaminarayan University is associated with following schools with state
        of art facilities for the children.
      </p>
      <ol>
        <li>Shree Swaminarayan International School,Kalol</li>
        <li>Shree Swaminarayan International School,Ranip, Ahmedabad</li>
        <li>Shree Swaminarayan International School (CBSE),Ranip, Ahmedabad</li>
      </ol>
      <p />
    </div>
  </div>
  <div className="admisssion-area bg-f4f6f9 ptb-100">
    <div className="container-fluid">
      <div className="admission-content">
        <div className="section-title">
          <h2>Take School Tour</h2>
        </div>
        <div className="admission-image  mx-auto">
          <img
            src="assets/images/school logo.jpg"
            className="mw-100 m-100"
            alt="Image"
          />
          <div className="icon">
            <a
              className="popup-youtube play-btn"
              href="https://www.youtube.com/watch?v=QQEBVOoiJ1g"
            >
              <i className="ri-play-fill" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</>

    </div>
  )
}

export default Schools