import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { APPROVALS } from "../../../Utils/InfoUploadingConst";
import "./Approvals.scss";

function Approval() {
  const [data, setData] = useState([]);
  const [groupedData, setGroupedData] = useState({});
  const [activeTab, setActiveTab] = useState("AISHE Codes");
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const getData = async () => {
    const config = {
      method: "get",
      url: APPROVALS,
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios(config);
      const sortedData = res.data.data.sort((a, b) => b.id - a.id);
      const approvalData = sortedData.filter((s) => s.status === "ACTIVE");
      setData(approvalData);
      groupByType(approvalData);
    } catch (err) {
      setError(err.message);
    }
  };

  const groupByType = (data) => {
    const grouped = data.reduce((acc, item) => {
      if (!acc[item.type]) {
        acc[item.type] = [];
      }
      acc[item.type].push(item);
      return acc;
    }, {});
    setGroupedData(grouped);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="Approval">
      <div className="courses-details-area pt-60 pb-10">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="courses-details">
                <div className="courses-card">
                  <h2>Approvals &amp; Recognition</h2>
                </div>
                <div className="description">
                  <div className="container-fluid">
                    <nav>
                      <div
                        className="nav nav-tabs d-flex justify-content-start"
                        id="nav-tab"
                        role="tablist"
                      >
                        <button
                          className={`nav-link ${
                            activeTab === "AISHE Codes" ? "active" : ""
                          }`}
                          id="nav-aishe-code-tab"
                          type="button"
                          role="tab"
                          aria-controls="nav-aishe-code"
                          aria-selected={activeTab === "AISHE Codes"}
                          onClick={() => setActiveTab("AISHE Codes")}
                        >
                          AISHE Code
                        </button>
                        {Object.keys(groupedData).map(
                          (type) =>
                            type !== "AISHE Codes" && (
                              <button
                                key={type}
                                className={`nav-link ${
                                  activeTab === type ? "active" : ""
                                }`}
                                id={`nav-${type
                                  .toLowerCase()
                                  .replace(/ /g, "-")}-tab`}
                                type="button"
                                role="tab"
                                aria-controls={`nav-${type
                                  .toLowerCase()
                                  .replace(/ /g, "-")}`}
                                aria-selected={activeTab === type}
                                onClick={() => setActiveTab(type)}
                              >
                                {type}
                              </button>
                            )
                        )}
                      </div>
                    </nav>
                    <div className="tab-content" id="nav-tabContent">
                      {error && <p className="error">{error}</p>}
                      {activeTab === "AISHE Codes" && (
                        <div
                          className="tab-pane fade show active"
                          id="nav-aishe-code"
                          role="tabpanel"
                          aria-labelledby="nav-aishe-code-tab"
                        >
                          <div className="row align-items-center">
                            <div className="col-lg-12">
                              <div className="faq-image pr-20">
                                <h2>AISHE Codes</h2>
                                <hr />
                                <div className="row">
                                  <div className="col-12">
                                    <div className="page-title-box d-flex align-items-center px-5 justify-content-between">
                                      <div className="advisor_thumb">
                                        <div className="logo">
                                          <img
                                            src="/assets/images/white-logo.png"
                                            className="white-logo"
                                            alt="logo"
                                            height={150}
                                            width={380}
                                          />
                                        </div>
                                      </div>
                                      <h4>
                                        List Of Constituent College Of
                                        Swaminarayan University along with AISHE
                                        Code
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                                <br />
                                <div className="row">
                                  <div className="col-12 container justify-content-center">
                                    <div className="card">
                                      <div className="card-body">
                                        <br />
                                        <table
                                          id="datatable"
                                          className="table table-bordered dt-responsive nowrap table-hover AISHE Codes"
                                          style={{
                                            borderCollapse: "collapse",
                                            borderSpacing: 0,
                                            width: "100%",
                                          }}
                                        >
                                          <thead>
                                            <tr>
                                              <th>Sl.No.</th>
                                              <th>
                                                Name Of University and
                                                Constituent College Of
                                                Swaminarayan University
                                              </th>
                                              <th>Year Of Establishment</th>
                                              <th>AISHE Code</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td>1</td>
                                              <td>Swaminarayan University</td>
                                              <td>2022</td>
                                              <td>U-1211</td>
                                            </tr>
                                            <tr>
                                              <td>2</td>
                                              <td>
                                                Faculty Of Education
                                                <p>
                                                  Shree Swaminarayan B.Ed
                                                  College
                                                </p>
                                              </td>
                                              <td>2005</td>
                                              <td>C-50556</td>
                                            </tr>
                                            <tr>
                                              <td>3</td>
                                              <td>
                                                Faculty Of Pharmacy
                                                <p>
                                                  Shree Swaminarayan College Of
                                                  Pharmacy
                                                </p>
                                              </td>
                                              <td>2007</td>
                                              <td>C-218</td>
                                            </tr>
                                            <tr>
                                              <td>4</td>
                                              <td>
                                                Faculty Of Engineering and
                                                Technology
                                                <p>
                                                  Swaminarayan College Of
                                                  Engineering (Diploma)
                                                </p>
                                                Swaminarayan College Of
                                                Engineering & Technology
                                                (Degree)
                                              </td>
                                              <td>
                                                <p>2009</p>
                                                <p>2012</p>
                                              </td>
                                              <td>
                                                <p>C-363</p>
                                                <p>C-48669</p>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>5</td>
                                              <td>
                                                Faculty Of Ayurveda
                                                <p>
                                                  Shree Swaminarayan College Of
                                                  Ayurveda
                                                </p>
                                              </td>
                                              <td>2016</td>
                                              <td>C-56118</td>
                                            </tr>
                                            <tr>
                                              <td>6</td>
                                              <td>
                                                Faculty Of Homoeopathy
                                                <p>
                                                  Shree Swaminarayan College Of
                                                  Homoeopathy
                                                </p>
                                              </td>
                                              <td>2017</td>
                                              <td>C-58748</td>
                                            </tr>
                                            <tr>
                                              <td>7</td>
                                              <td>
                                                Faculty Of Nursing
                                                <p>
                                                  Shree Swaminarayan College Of
                                                  Nursing
                                                </p>
                                              </td>
                                              <td>2017</td>
                                              <td>C-58714</td>
                                            </tr>
                                            <tr>
                                              <td>8</td>
                                              <td>
                                                Faculty Of Science
                                                <p>
                                                  Shree Swaminarayan College Of
                                                  Science
                                                </p>
                                              </td>
                                              <td>2019</td>
                                              <td>C-61456</td>
                                            </tr>
                                            <tr>
                                              <td>9</td>
                                              <td>
                                                Faculty Of Law
                                                <p>
                                                  Shree Swaminarayan College Of
                                                  Law
                                                </p>
                                              </td>
                                              <td>2022</td>
                                              <td>C-66681</td>
                                            </tr>
                                            <tr>
                                              <td>10</td>
                                              <td>
                                                Faculty Of Arts and Humanities
                                                <p>
                                                  Shree Swaminarayan College Of
                                                  Arts
                                                </p>
                                              </td>
                                              <td>2022</td>
                                              <td>C-68006</td>
                                            </tr>
                                            <tr>
                                              <td>11</td>
                                              <td>
                                                Faculty Of Commerce & Management
                                                <p>
                                                  Shree Swaminarayan College Of
                                                  Commerce & Management
                                                </p>
                                              </td>
                                              <td>2022</td>
                                              <td>C-68007</td>
                                            </tr>
                                            <tr>
                                              <td>13</td>
                                              <td>
                                                Faculty Of Medicine
                                                <p>
                                                  Swaminarayan Institute Of
                                                  Medical Sciences and Research
                                                </p>
                                              </td>
                                              <td>2023</td>
                                              <td>C-70107</td>
                                            </tr>
                                            <tr>
                                              <td>14</td>
                                              <td>
                                                Faculty Of Physiotherapy
                                                <p>
                                                Shree Swaminarayan Physiotherapy College
                                                </p>
                                              </td>
                                              <td>2023</td>
                                              <td>C-70583</td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {Object.entries(groupedData).map(([type, items]) => (
                        <div
                          key={type}
                          className={`tab-pane fade ${
                            activeTab === type ? "show active" : ""
                          }`}
                          id={`nav-${type.toLowerCase().replace(/ /g, "-")}`}
                          role="tabpanel"
                          aria-labelledby={`nav-${type
                            .toLowerCase()
                            .replace(/ /g, "-")}-tab`}
                        >
                          {activeTab === type && (
                            <div className="row align-items-center">
                              {items.length > 5 ? (
                                <div className="col-lg-12 mt-5">
                                  <div className="extra-attachments">
                                    <table>
                                      <thead>
                                        <tr>
                                          <th>Title</th>
                                          <th>Attachments</th>
                                        </tr>
                                      </thead>
                                      {items.map((item) => (
                                        <tbody>
                                          <tr key={item.id}>
                                            <td>{item?.title}</td>
                                            <td>
                                              <a
                                                href={item.attachments.replace(
                                                  /(^"|"$)/g,
                                                  ""
                                                )}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                              >
                                                Click Here
                                              </a>
                                            </td>
                                          </tr>
                                        </tbody>
                                      ))}
                                    </table>
                                  </div>
                                </div>
                              ) : (
                                items.slice(0, 5).map((item, index) => (
                                  <div
                                    key={item.id}
                                    className={`mt-5 ${
                                      items.length === 1
                                        ? "col-lg-12"
                                        : "col-lg-6"
                                    }`}
                                  >
                                    <div className="faq-image pr-20">
                                      <h2>{item.title}</h2>
                                      <hr />
                                      <object
                                        height={800}
                                        data={item.attachments.replace(
                                          /(^"|"$)/g,
                                          ""
                                        )}
                                        type="application/pdf"
                                        width="100%"
                                      >
                                        <p>
                                          It appears you don't have a PDF plugin
                                          for this browser. No biggie... you can{" "}
                                          <a
                                            href={item.attachments.replace(
                                              /(^"|"$)/g,
                                              ""
                                            )}
                                          >
                                            click here to download the PDF file.
                                          </a>
                                        </p>
                                      </object>
                                    </div>
                                  </div>
                                ))
                              )}
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="event-share">
                  <div className="share-info"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Approval;
