import axios from "axios";
import React, { useEffect, useState } from "react";
import { sessionOpt } from "../../Data/sessionData";

import {
  REMEDIAL_RESULTS,
  CALCULATE_PREVIOUS_RE_EXAM,
} from "../../Utils/apiConst";
import { ACADEMICS_ADD_SEMESTER } from "../../Utils/Academics.apiConst";
import Loader from "../../Components/Loader/Loader";
import { toast } from "react-toastify";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import "./style.scss";
import { Link, useNavigate } from "react-router-dom";

function ReexamResults() {
  const navigate = useNavigate();
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const getData = async () => {
    const config = {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
      url: `${ACADEMICS_ADD_SEMESTER}`,
    };
    await axios(config)
      .then((res) => {
        setSemesters(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const [semesters, setSemesters] = useState([]);

  const calculateGrade = (p) => {
    if (p >= 85) return "O+";
    if (p >= 70 && p <= 84.99) return "O";
    if (p >= 60 && p <= 69.99) return "A";
    if (p >= 55 && p <= 59.99) return "B+";
    if (p >= 48 && p <= 54.99) return "B";
    if (p >= 36 && p <= 47.99) return "C";
    if (p < 36) return "D";
  };

  const calculateGradePoint = (p) => {
    // if(p>=85) return "O+"
    // if (p>=70 && p<=84.99 ) return "O"
    // if (p>=60    && p<=69.99 ) return "A"
    // if (p>=55 && p<=59.99 ) return "B+"
    // if (p>=48 && p<=54.99 ) return "B"
    // if (p>=36 && p<=47.99 ) return "C"
    // if (p<36) return "D"

    // console.log(p)

    return parseFloat(p * 10).toFixed(2);
  };

  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  const facultyData = [
    {
      id: 1111000,
      name: "FACULTY OF ENGINEERING AND TECHNOLOGY",
      college_type_id: "ENG",
      status: "ACTIVE",
      colloge_name:
        "Shree Swaminarayan College Engineering and Technology,Kalol",
    },
    {
      id: 1111001,
      name: "FACULTY OF AYURVEDA",
      college_type_id: "AYU",
      status: "ACTIVE",
      colloge_name: "Shree Swaminarayan Ayurvedic College,Kalol",
    },
    {
      id: 1111002,
      name: "FACULTY OF LAW",
      college_type_id: "LAW",
      status: "ACTIVE",
      colloge_name: "Shree Swaminarayan Law College, Kalol",
    },
    {
      id: 1111003,
      name: "FACULTY OF NURSING",
      college_type_id: "NUR",
      status: "ACTIVE",
      colloge_name: "Shree Swaminarayan  College of Nursing, Kalol",
    },
    {
      id: 1111004,
      name: "FACULTY OF ARTS AND HUMANITIES ",
      college_type_id: "NUR",
      status: "ACTIVE",
      colloge_name: "Shree Swaminarayan Arts  College, Kalol",
    },
    {
      id: 1111005,
      name: "FACULTY OF COMMERCE AND MANAGEMENT",
      college_type_id: "COM",
      status: "ACTIVE",
      colloge_name: "FACULTY OF COMMERCE AND MANAGEMENT,Kalol",
    },
    {
      id: 1111010,
      name: "Faculty of Education",
      college_type_id: "EDU",
      status: "ACTIVE",
      colloge_name: "Shree Swaminarayan B.Ed College, Kalol",
      program_name: "Bachelor of Education ",
    },
    {
      id: 1111011,
      name: "FACULTY OF SCIENCE",
      college_type_id: "SCI",
      status: "ACTIVE",
      colloge_name: "Shree Swaminarayan  Science College, Kalol",

      program_name: "Bachelor of Science ",
    },
    {
      id: 1111007,
      name: "FACULTY OF HOMEOPATHY",
      college_type_id: "HOMEOPATHY",
      status: "ACTIVE",
      colloge_name: "Shree Swaminarayan Homoeopathy College, Kalol",
    },
  ];

  const [studentId, setStudentId] = useState("");
  const [sessionId, setSessionId] = useState("");
  const [loading, setLoading] = useState(0);

  const [flag, setFlag] = useState(0);

  const [examResults, setExamResults] = useState([]);
  const [examData, setExamData] = useState();

  const [totalsum, setTotalSum] = useState();
  const [totalsum_emin, setTotalSumEmin] = useState();
  const [info, setInfo] = useState("");
  const [info1, setInfo1] = useState("");
  const [isPass, setIsPass] = useState(0);
  const [totalsum_obt, setTotalSumobt] = useState();
  const [totalabsent, setTotalAbsent] = useState();
  const [totalcredits, setTotalCredits] = useState();

  const [cnt, setCnt] = useState();
  const [prevExamData, setPrevExamData] = useState();

  const getResults = async () => {
    if (!studentId || !sessionId)
      return toast.error("Please fill Student Id and Session");
    setLoading(1);
    const config = {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
      url: `${REMEDIAL_RESULTS}?student_id=${studentId}&session_id=${sessionId}`,
    };

    await axios(config)
      .then(async (res) => {
        console.log(res.data.data.exam);
        if (res.data.data.exam[0].college_id == 1111008) {
          await reExamNav(res.data.data.exam[0].college_id);
          return;
        }
        if (res.data.data.marks?.length === 0)
          return toast.error("No Data Found");
        const result = Object.values(
          res.data.data.marks.reduce((acc, curr) => {
            const subject = curr.subject;
            if (!acc[subject]) {
              acc[subject] = { ...curr };
            } else {
              acc[subject].marks += curr.marks;
              acc[subject].max += curr.max;
              acc[subject].min += curr.min;
              acc[subject].grace_marks += Number(curr.grace_marks);
            }
            return acc;
          }, {})
        );

        setExamResults({ ...res.data.data.marks, data: result });
        console.log(examResults);

        const result2 = Object.values(
          res.data.data.prevExamDetails.reduce((acc, curr) => {
            const subject_name = curr.subject_name;
            if (!acc[subject_name]) {
              acc[subject_name] = { ...curr };
            } else {
              acc[subject_name].marks += curr.marks;
              acc[subject_name].max += curr.max;
              acc[subject_name].min += curr.min;
              acc[subject_name].grace_marks += Number(curr.grace_marks);
            }
            return acc;
          }, {})
        );
        setPrevExamData(result2);

        setExamData(
          res.data.data?.exam?.[res.data.data?.exam?.length - 1 || 0]
        );

        var sum = 0;
        result?.forEach((item) => {
          if (item.is_not_countable) sum = sum;
          else sum += item.max;
        });
        console.log("Total" + sum);
        setTotalSum({ emax: sum });

        var sum_min = 0;
        result?.forEach((item) => {
          if (item.is_not_countable) sum_min = sum_min;
          else sum_min += item.min;
        });
        console.log(sum_min);
        setTotalSumEmin({ emin: sum_min });

        var sum_obt = 0;
        result?.forEach((item) => {
          if (item.is_not_countable) sum_obt = sum_obt;
          else sum_obt += item.marks;
        });
        console.log("OBT" + sum_obt);
        setTotalSumobt({ obt: sum_obt });

        var sum_credits = 0;
        result?.forEach((item) => {
          if (item.is_not_countable) sum_credits = sum_credits;
          else sum_credits += item.credits;
        });
        console.log(sum_credits);
        setTotalCredits({ credits: sum_credits });

        var absent = 0;
        result?.forEach((item) => {
          if (item.absent == 1 || item.absent == 1) {
            absent++;
          }
        });

        var flag = 0;
        for (const item of result) {
          console.log("result item", item);
          if (item.absent) {
            flag = 1;
            break;
          }
          if (item.min > Number(item.grace_marks) + item.marks) {
            flag = 1;
            console.log(" min " + item.min);
            console.log(" marks " + item.marks);
            console.log("grace " + item.subject);
            console.log("grace mark " + item.grace_marks);
            console.log("grace flag " + flag);
            break;
          }
        }
        if (!flag) {
          setIsPass(1);
        } else {
          setIsPass(0);
        }
        // if(!flag) setIsPass(1)

        console.log(absent);
        setTotalAbsent({ absent: absent });

        setFlag(1);
      })
      .catch((err) => {
        console.log(err);
      });

    const config1 = {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
      url: `${CALCULATE_PREVIOUS_RE_EXAM}?student_id=${studentId}`,
    };

    await axios(config1)
      .then((res) => {
        let y = res.data.data + 1;
        setCnt(y);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went Wrong");
      });
    setLoading(0);
  };

  const Rechecking = () => {
    navigate("/reCheckingForm");
  };

  const reExamNav = (id) => {
    if (id == 1111008) {
      setInfo(
        "https://www.swaminarayanuniversity.ac.in/re-exam-Results-pharmacy"
      );
      setInfo1("Pharmacy");
    }
    if (id == 1111000) {
      setInfo("");
      setInfo1("Engineering");
    }
  };

  const toInputUppercase = (e) => {
    e.target.value = ("" + e.target.value).toUpperCase();
  };
  const Reassessment = () => {
    navigate("/reAssessmentForm");
  };

  return (
    <div className="Enrollment-area mt-5 pb-70 ">
      <Loader />

      {!flag ? (
        <div className="container">
          <div className="d-flex justify-content-center align-items-center">
            <img
              src="/assets/images/favicon.png"
              className="mr-5"
              width="150"
              alt="logo"
            />
            <div className="ml-5 text-center">
              <h2 class="text-uppercase mx-5"> Swaminarayan University</h2>
            </div>
          </div>
          <div className="row">
            <div className="card shadow-sm border-0">
              <h3 className="card-header text-center">
                REMEDIAL EXAMINATION RESULTS
                <br />
                ઉપચારાત્મક પરીક્ષાના પરિણામો
              </h3>

              <div className="card-body">
                <div className="row d-flex justify-content-center p-4">
                  <div className="col-md-12 mt-4">
                    <div className="form-group">
                      {info ? (
                        <label className="alert alert-warning">
                          Please{" "}
                          <a href={info} className="text-danger">
                            Check Here
                          </a>{" "}
                          for {info1} Remedial Results
                        </label>
                      ) : null}
                      <br />
                      <label htmlFor="">Select Academic Year</label>
                      <select
                        name="year"
                        id="year"
                        className="form-control"
                        value={sessionId}
                        onChange={(e) => setSessionId(e.target.value)}
                      >
                        <option value="">Select Academic Year</option>
                        {sessionOpt.map((i, key) => (
                          <option value={i.id}>{i.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-12 mt-4">
                    <div className="form-group">
                      <label htmlFor="">Enrollment Number</label>
                      <input
                        type="text"
                        name="reg_no"
                        id="reg_no"
                        className="form-control"
                        placeholder="Enter Enrollment Number"
                        value={studentId}
                        onChange={(e) => setStudentId(e.target.value)}
                        onInput={toInputUppercase}
                      />
                    </div>
                  </div>
                  <br />
                  <div className="col-md-12 mt-4">
                    <button
                      className="btn btn-primary d-print-none float-right"
                      onClick={getResults}
                    >
                      Show Result
                    </button>
                  </div>

                  {/* <div className="col-md-6 mt-4">
                                            <div className="form-group">
                                                <label htmlFor="">Enter Captcha Code</label>
                                                <input type="text" name="captcha" id="captcha" className="form-control" placeholder='Enter Captcha Code' />

                                            </div>

                                        </div> */}

                  {/* <div className="col-md-12 mt-3 ">
                                            <button onClick={getResults} className="btn btn-success float-end ">Submit</button>
                                        </div> */}
                </div>
                <div className="row "></div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container p-5" ref={componentRef}>
          <div className="d-flex justify-content-center align-items-center">
            <img
              src="/assets/images/favicon.png"
              className="mr-5"
              width="150"
              alt="logo"
            />
            <div className="mx-3 text-center">
              {/* <h4>સ્વામિનારાયણ યુનિવર્સિટી, કલોલ</h4> */}
              <h2 class="text-uppercase"> Swaminarayan University</h2>
            </div>
          </div>

          <br />

          <h5 class="text-uppercase text-center">
            Provisional Statement of Marks and Grades of{" "}
            {examResults?.data[0]?.program || "UG"} Suppleymentry Examination
            &nbsp;
            {months[Number(examData?.month) - 1]}-{examData?.year || "2023"}
          </h5>

          <br />

          {examResults?.status == "DIFF" ? (
            <div>
              {examResults?.data[0]?.college_id == 1111008 ? (
                <div className="text-center alert alert-danger">
                  <h5>
                    Please Check Pharmacy Results{" "}
                    <Link
                      to={"/re-exam-Results-pharmacy"}
                      className="text-danger"
                    >
                      here
                    </Link>
                  </h5>{" "}
                </div>
              ) : examResults?.data[0]?.college_id == 1111000 ? (
                <div className="text-center alert alert-danger">
                  <h5>
                    Please Check Engineering Results{" "}
                    <Link to={"/re-exam-results-eng"} className="text-danger">
                      here
                    </Link>
                  </h5>{" "}
                </div>
              ) : examResults?.data[0]?.college_id == 1111009 ? (
                <div className="text-center alert alert-danger">
                  <h5>
                    Please Check BCA Results{" "}
                    <Link to={"/re-exam-results-eng"} className="text-danger">
                      here
                    </Link>
                  </h5>{" "}
                </div>
              ) : null}
            </div>
          ) : examData?.is_published != "PUBLISHED" ? (
            <h3 className="text-center alert alert-danger">
              Your exam Result is not yet published
            </h3>
          ) : (
            <div className="table-responsive ">
              <table className="table table-bordered result ">
                <thead>
                  <tr>
                    <th>Enrollment Number</th>
                    <td>{examResults?.data?.[0]?.student_id}</td>
                    <th>Student Name </th>
                    <td>{examResults?.data?.[0]?.name}</td>
                  </tr>
                  <tr>
                    <th>Faculty Name</th>
                    <td>{examData?.college}</td>

                    <th>Program</th>
                    <td>{examData?.department}</td>
                  </tr>
                  <tr>
                    <th>College Name</th>
                    <td>
                      {
                        facultyData.find(
                          (item) => item.id == examData?.college_id
                        )?.colloge_name
                      }
                    </td>

                    <th>
                      {examData?.department == "B.H.M.S."
                        ? "Year"
                        : examData?.department == "B.Sc. Nursing"
                        ? "Year"
                        : examData?.department == "Post Basic B.Sc. Nursing"
                        ? "Year"
                        : "Semester"}
                    </th>

                    <td>
                      {" "}
                      {examData?.department == "B.H.M.S."
                        ? "1st Year"
                        : examData?.department == "B.Sc. Nursing"
                        ? "1st Year"
                        : examData?.department == "Post Basic B.Sc. Nursing"
                        ? "1st Year"
                        : semesters?.find((s) => s.id == examData?.semester_id)
                            ?.name}{" "}
                    </td>
                  </tr>
                  <tr>
                    <th>No of Attempts</th>
                    <th>
                      {cnt}
                      {cnt == 1
                        ? "st"
                        : cnt == 2
                        ? "nd"
                        : cnt == 3
                        ? "rd"
                        : "th"}
                    </th>
                  </tr>
                </thead>
              </table>

              <table className="table result table-bordered ">
                <thead>
                  <tr>
                    <th rowSpan="2" className="text-center">
                      Course Name
                    </th>

                    {totalcredits?.credits != 0 ||
                    totalcredits?.credits == null ? (
                      <th rowSpan="2" className="text-center">
                        Credit
                      </th>
                    ) : (
                      ""
                    )}

                    <th className="text-center" colSpan="3">
                      {" "}
                      {examData?.department == "B.H.M.S." ? (
                        "University"
                      ) : (
                        <span> University Exam</span>
                      )}
                    </th>

                    {totalcredits?.credits != 0 ? (
                      <th rowSpan="2" className="text-center">
                        {" "}
                        Grade
                      </th>
                    ) : (
                      ""
                    )}
                    {totalcredits?.credits != 0 ? (
                      <th rowSpan="2" className="text-center">
                        {" "}
                        GP
                      </th>
                    ) : (
                      ""
                    )}
                    {totalcredits?.credits != 0 ? (
                      <th rowSpan="2" className="text-center">
                        {" "}
                        CGP
                      </th>
                    ) : (
                      ""
                    )}
                    <th rowSpan="2" className="text-center">
                      {" "}
                      Result
                    </th>
                  </tr>
                  <tr>
                    <th className="text-center">Max</th>
                    <th className="text-center">Min</th>
                    <th className="text-center">Obtained</th>
                  </tr>
                </thead>
                <tbody>
                  {examResults &&
                    examResults?.data?.map((i, key) => (
                      <tr key={key}>
                        <td>
                          {i?.sub_code} - {i?.subject}
                        </td>
                        {totalcredits?.credits != 0 ? (
                          <td className="text-center">{i?.credit} </td>
                        ) : (
                          ""
                        )}

                        <td className="text-center">
                          {i?.max == 0 ? "-" : i?.max}
                        </td>

                        <td className="text-center">
                          {i?.min == 0 ? "-" : i?.min}
                        </td>

                        <td className="text-center">
                          {i?.e_absent == 1 ? (
                            <span className="text-danger">
                              <b>A</b>
                            </span>
                          ) : i?.marks == 0 ? (
                            "-"
                          ) : i?.marks == null ? (
                            "-"
                          ) : i?.marks == "" ? (
                            "-"
                          ) : i?.grace_marks == null || i?.grace_marks == 0 ? (
                            i?.marks
                          ) : (
                            i?.marks + "+" + i?.grace_marks
                          )}{" "}
                          <br />
                        </td>
                        {totalcredits?.credits != 0 ? (
                          <td className="text-center">
                            {i?.e_grace == null
                              ? Number(i?.marks)
                              : Number(i?.marks) + Number(i?.grace_marks)}
                          </td>
                        ) : (
                          ""
                        )}
                        {totalcredits?.credits != 0 ? (
                          <td className="text-center">
                            {i?.e_grace == null
                              ? calculateGrade(
                                  ((Number(i?.marks) + Number(i?.grace_marks)) /
                                    Number(i?.max)) *
                                    100
                                )
                              : calculateGrade(
                                  ((Number(i?.marks) + Number(i?.grace_marks)) /
                                    Number(i?.max)) *
                                    100
                                )}
                          </td>
                        ) : (
                          ""
                        )}

                        {totalcredits?.credits != 0 ? (
                          <td className="text-center">
                            {i?.e_grace == null
                              ? calculateGradePoint(
                                  (Number(i?.marks) + Number(i?.grace_marks)) /
                                    Number(i?.max)
                                )
                              : calculateGradePoint(
                                  (Number(i?.marks) + Number(i?.grace_marks)) /
                                    Number(i?.max)
                                )}{" "}
                          </td>
                        ) : (
                          ""
                        )}

                        {totalcredits?.credits != 0 ? (
                          <td className="text-center">
                            {i?.e_grace == null
                              ? calculateGradePoint(
                                  ((Number(i?.marks) + Number(i?.grace_marks)) /
                                    Number(i?.max)) *
                                    Number(i?.credit).toFixed(2)
                                )
                              : calculateGradePoint(
                                  ((Number(i?.marks) + Number(i?.grace_marks)) /
                                    Number(i?.max)) *
                                    Number(i?.credit).toFixed(2)
                                )}{" "}
                          </td>
                        ) : (
                          ""
                        )}

                        <td className="text-center">
                          {i?.data?.result}{" "}
                          {Number(i?.marks) + Number(i?.grace_marks) >=
                            i?.min || i?.marks == null ? (
                            <p className="text-success">
                              <b> P </b>{" "}
                            </p>
                          ) : Number(i?.marks) + Number(i?.grace_marks) >=
                              i?.min || i?.marks == null ? (
                            <p className="text-success">
                              <b> P </b>{" "}
                            </p>
                          ) : (
                            <p className="text-danger">
                              <b>F </b>
                            </p>
                          )}{" "}
                        </td>
                      </tr>
                    ))}
                  {prevExamData &&
                    prevExamData?.map((i, key) => (
                      <tr key={key}>
                        <td>
                          {i?.sub_code} - {i?.subject_name}
                        </td>
                        {totalcredits?.credits != 0 ? (
                          <td className="text-center">{i?.credit} </td>
                        ) : (
                          ""
                        )}

                        <td className="text-center">
                          {i?.max == 0 ? "-" : i?.max}
                        </td>

                        <td className="text-center">
                          {i?.min == 0 ? "-" : i?.min}
                        </td>

                        <td className="text-center">E</td>

                        <td className="text-center">E</td>
                      </tr>
                    ))}

                  <tr>
                    <th className="text-center">Total</th>
                    {totalcredits?.credits != 0 ? (
                      <td className="text-center"> {totalcredits?.credits} </td>
                    ) : (
                      ""
                    )}

                    <td className="text-center">
                      {totalsum?.emax} <br />
                    </td>
                    {totalcredits?.credits == 0 ? (
                      <td className="text-center">
                        {examData?.department == "B.H.M.S."
                          ? totalsum_emin?.emin
                          : " "}
                      </td>
                    ) : (
                      <td className="text-center">
                        - <br /> -
                      </td>
                    )}
                    {/* <td className="text-center">
                      {totalsum_emarks?.marks <= 0 ? (
                        <span className="text-danger">
                          <b>F</b>
                        </span>
                      ) : (
                        totalsum_emarks?.marks
                      )}{" "}
                      <br />
                     
                    </td> */}
                    <th className="text-center">{totalsum_obt?.obt}</th>
                    <td></td>
                    {totalcredits?.credits != 0 ? <th colSpan={2}></th> : ""}

                    {totalcredits?.credits != 0 ? (
                      <th className="text-center">
                        {totalabsent?.absent >= 1
                          ? "-"
                          : examResults?.data
                              ?.reduce(
                                (total, i) =>
                                  i?.is_not_countable
                                    ? total + 0
                                    : total +
                                      calculateGradePoint(
                                        (Number(i?.marks) +
                                          Number(i?.grace_marks)) /
                                          Number(i?.max)
                                      ) *
                                        Number(i?.credit),
                                0
                              )
                              .toFixed(2)}
                      </th>
                    ) : (
                      ""
                    )}
                    {totalcredits?.credits != 0 ? <th></th> : ""}
                  </tr>
                  <tr className="text-center">
                    <th className="text-center"> Result </th>
                    <td colSpan={5} className="text-center">
                      {totalabsent?.absent >= 1 ||
                      examResults?.data?.reduce(
                        (total, i) =>
                          total +
                          (calculateGradePoint(
                            (Number(i?.marks) + Number(i?.grace_marks)) /
                              Number(i?.max)
                          ) <
                            3.6),
                        0
                      ) ||
                      !isPass ? (
                        <p className="text-danger">
                          <b> FAIL </b>{" "}
                        </p>
                      ) : (
                        <p className="text-success">
                          <b>PASS </b>
                        </p>
                      )}
                    </td>

                    {totalcredits?.credits != 0 ? <th>SGPA:</th> : ""}

                    {totalcredits?.credits != 0 ? (
                      <th colSpan={3} className="text-center">
                        {totalabsent?.absent >= 1 ||
                        examResults?.data?.reduce(
                          (total, i) =>
                            i?.is_not_countable
                              ? total + 0
                              : total +
                                (calculateGradePoint(
                                  (Number(i?.i_marks) +
                                    Number(i?.e_marks) +
                                    Number(i?.e_grace)) /
                                    (Number(i?.e_max) + Number(i?.i_max))
                                ) <
                                  3.6),
                          0
                        )
                          ? "-"
                          : totalabsent?.absent >= 1
                          ? "-"
                          : (
                              examResults?.data?.reduce(
                                (total, i) =>
                                  i?.is_not_countable
                                    ? total + 0
                                    : total +
                                      calculateGradePoint(
                                        (Number(i?.marks) +
                                          Number(i?.grace_marks)) /
                                          Number(i?.max)
                                      ) *
                                        Number(i?.credits),
                                0
                              ) / totalcredits?.credits
                            ).toFixed(2)}
                      </th>
                    ) : (
                      ""
                    )}
                  </tr>
                </tbody>
              </table>
            </div>
          )}

          {examResults?.status == "HOLD" ? null : (
            <>
              <div className="text-center mt-4">
                <h6>Nomenclature / Abbreviations</h6>
              </div>
              <div className="d-flex justify-content-around px-5 mt-4">
                <h6> P {"->"} Pass</h6>
                <h6> F {"->"} Fail</h6>
                <h6> A {"->"} Absent</h6>
                <h6> NE {"->"} Not Eligible</h6>
                <h6> E {"->"} Exempted</h6>
              </div>
            </>
          )}

          <h5 className="text-danger mt-4">Note *</h5>

          <p>
            <b>This is Computer Generate Statement of Marks</b>
          </p>

          <div className="row mt-3">
            <div className="col-md-12">
              <div className="float-end text-center mb-5 mt-4"></div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <button
                onClick={() => setFlag(0)}
                className="btn btn-primary d-print-none mx-3"
              >
                Back
              </button>

              {examResults?.status == "HOLD" ? null : (
                <button
                  className="btn btn-primary  float-right"
                  onClick={handlePrint}
                >
                  Print
                </button>
              )}
            </div>
            {examResults?.status == "HOLD" ? null : (
              <>
                <div className="col-md-3 float-right">
                  <button
                    className="btn btn-danger "
                    onClick={Rechecking}
                    type="button"
                  >
                    Rechecking
                  </button>
                </div>
                <div className="col-md-3">
                  <button
                    className="btn btn-danger "
                    onClick={Reassessment}
                    type="button"
                  >
                    Re-Assessment
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default ReexamResults;
