import React from "react";

export default function Eligibility() {
  return (
    <div>
      <div className="container mt-5 mb-5">
        <div className="row register" style={{maxWidth: "100%"}}>
          <div className="col-md-12">
            <p>
              <span style={{ fontSize: "18pt" }}>
                <h3>Eligibility</h3>
              </span>
              <br />
            </p>

            <p>
              <strong>Eligibility Details:</strong>
              <br />
            </p>
            <table
              className="table table-bordered"
              style={{ borderCollapse: "collapse", width: "100%", height: 173 }}
              border={1}
            >
              <tbody>
                <tr style={{ height: 20 }}>
                  <td>
                    <strong>Sr No</strong>
                    <br />
                  </td>
                  <td>
                    <strong>Course Name</strong>
                    <br />
                  </td>

                  <td>
                    <strong>Eligibility</strong>
                  </td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td>1</td>
                  <td>B.Ed</td>

                  <td>
                    <ol>
                      <li>
                        <p>
                          A candidate seeks admission in Bachelor of Education
                          (B. Ed.) must be a graduate from the Gujarat
                          University or from any University Grants Commission
                          (UGC) recognized university or from any other
                          University recognized as an equivalent for the
                          purpose.
                        </p>
                      </li>
                      <li>
                        <p>
                          The candidate must have completed his graduation in 10
                          + 2 + 3 or 11 + 4 pattern of educational structure. If
                          he / she has completed graduation under 10 + 2 + 2
                          pattern of education or under any other pattern in
                          which graduation can be completed in less than 15
                          years, including school education, he must have
                          master’s degree (Post Graduate degree) in the subject
                          related to his / her subjects at Under Graduate level.
                        </p>
                      </li>
                      <li>
                        <p>
                          The candidate must have 50 percent marks in Bachelor’s
                          degree and / or in Master’s degree in Science/Social
                          Science/Humanities/Commerce or the candidate having
                          Bachelor’s in Engineering or Technology with
                          specialization in Science and Mathematics with minimum
                          55 % marks or any other qualification equivalent
                          thereto, are eligible for admission to the program.
                        </p>
                      </li>
                      <li>
                        <p>
                          Relaxation for SC/ST/OBC/PWD and other applicable
                          categories will be as per the rules of the State
                          Government.
                        </p>
                      </li>
                      <li>
                        <p>
                          A candidate admitted to the B.Ed. program cannot do
                          any other Course during his study of B. Ed.
                        </p>
                      </li>
                    </ol>
                  </td>
                </tr>

                <tr style={{ height: 20 }}>
                  <td>2</td>
                  <td>Ph.D</td>

                  <td>
                    <ol>
                      <li>
                        <p>
                          The Post graduate qualifying degree should be
                          recognized by the UGC / Association of Indian
                          Universities any other approved and recognized
                          Scientific Bodies of the Government of India and
                          approved by the Academic Council / Board of Management
                          of the University for Provisional Registration for the
                          PhD Degree.
                        </p>
                      </li>
                      <li>
                        <p>
                          Master degree or its equivalent in the subject of
                          Education with at least 55% marks (or equivalent
                          grade) of any University/Higher Learning Institution
                          recognized by UGC and/or DEC, in the relevant
                          discipline.
                        </p>
                      </li>
                      <li>
                        <p>
                          Must have Passed Entrance Test or NET/JRF/GSET/M.Phil.
                        </p>
                      </li>
                      <li>
                        <p>
                          A maximum of two extensions, each of six months’
                          duration, beyond the maximum period of research, may
                          be considered by the Vice Chancellor on the specific
                          recommendation.
                        </p>
                      </li>
                      <li>
                        <p>
                          Women Candidates and Persons with Disability (more
                          than 40% disability) may be allowed a relaxation of
                          two years for Ph.D. In addition, women candidates may
                          be provided Maternity Leave / Child Care Leave once in
                          the entire duration of Ph.D. as per the current leave
                          rules.
                        </p>
                      </li>
                    </ol>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
