import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "./BOS.scss";

const url =
  "https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/Notification_of_BoS.pdf";

export default function Test() {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  /*To Prevent right click on screen*/
  document.addEventListener("contextmenu", (event) => {
    event.preventDefault();
  });

  /*When document gets loaded successfully*/
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  return (
    <div className="BoardOfStudies">
      <>
        {/* <div className="main container">

          <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
            <Page
              pageNumber={pageNumber}
              scale={2.0} // Adjust the scale factor as needed
              className="pdf-page" // Add a custom class name for styling
            />
          </Document>

          <div className="container">
            <div className="pagec my-2">
              Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
            </div>
            <div className="buttonc d-flex justify-content-between">
              <button
                type="button"
                disabled={pageNumber <= 1}
                onClick={previousPage}
                className="Pre icon "
              >
                <i class="ri-arrow-left-s-line"></i>
              </button>
              <button
                type="button"
                disabled={pageNumber >= numPages}
                onClick={nextPage}
                className="icon"
              >
                <i class="ri-arrow-right-s-line"></i>
              </button>
            </div>
          </div>
        </div> */}

        <br />
        <div className="courses-details-area pt-60 pb-10">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="courses-details">
                  <div className="courses-card">
                    <h2 className="text-center">Board of Studies</h2>
                  </div>
                  <div className="description">
                    <div className="container-fluid ">
                      <nav>
                        <div
                          className="nav nav-tabs d-flex justify-content-between"
                          id="nav-tab"
                          role="tablist"
                        >
                          <button
                            className="nav-link active"
                            id="nav-educate-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-educate"
                            type="button"
                            role="tab"
                            aria-controls="nav-educate"
                            aria-selected="true"
                          >
                            Education
                          </button>
                          <button
                            className="nav-link"
                            id="nav-homeo-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-homeo"
                            type="button"
                            role="tab"
                            aria-controls="nav-homeo"
                            aria-selected="false"
                          >
                            Homoeopathy
                          </button>
                          <button
                            className="nav-link"
                            id="nav-ayu-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-ayu"
                            type="button"
                            role="tab"
                            aria-controls="nav-ayu"
                            aria-selected="false"
                          >
                            Ayurveda
                          </button>
                          <button
                            className="nav-link"
                            id="nav-nursing-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-nursing"
                            type="button"
                            role="tab"
                            aria-controls="nav-nursing"
                            aria-selected="false"
                          >
                            Nursing
                          </button>
                          <button
                            className="nav-link"
                            id="nav-science-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-science"
                            type="button"
                            role="tab"
                            aria-controls="nav-science"
                            aria-selected="false"
                          >
                            Science
                          </button>
                          <button
                            className="nav-link"
                            id="nav-law-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-law"
                            type="button"
                            role="tab"
                            aria-controls="nav-law"
                            aria-selected="false"
                          >
                            LAW
                          </button>
                        </div>
                      </nav>

                      <div className="tab-content" id="nav-tabContent">
                        <div
                          className="tab-pane fade show active"
                          id="nav-educate"
                          role="tabpanel"
                          aria-labelledby="nav-educate-tab"
                        >
                          <div className="row align-items-center">
                            <div className="col-lg-12">
                              <div className="faq-image pr-20">
                                <hr />

                                <div className="row">
                                  <div className="col-11">
                                    <div className="page-title-box d-flex align-items-center px-5 justify-content-between">
                                      <div className="advisor_thumb">
                                        <div className="logo">
                                          <img
                                            src="/assets/images/white-logo.png"
                                            className="white-logo"
                                            alt="logo"
                                            height={112}
                                            width={380}
                                          />
                                        </div>
                                      </div>

                                      <h4>
                                        {" "}
                                        Board of studies of the Faculty Of
                                        Education
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                                <br />

                                <div className="row">
                                  <div className="col-12">
                                    <div className="card">
                                      <div className="card-body">
                                        <br />

                                        <table
                                          id="datatable"
                                          className="table table-bordered dt-responsive nowrap table-hover "
                                          style={{
                                            borderCollapse: "collapse",
                                            borderSpacing: 0,
                                            width: "100%",
                                          }}
                                        >
                                          <thead>
                                            <tr>
                                              <th> Sl.No. </th>
                                              <th> Name Of Member </th>
                                              <th>
                                                {" "}
                                                Designation and Organisation{" "}
                                              </th>
                                              <th> Position </th>
                                            </tr>
                                          </thead>

                                          <tbody>
                                            <tr>
                                              <td colSpan="4">
                                                <h5 className="text-center">
                                                  Chairperson
                                                </h5>
                                              </td>
                                            </tr>
                                            <tr className="ml-2">
                                              <td> 1 </td>
                                              <td> Dr. Gunjan U. Shah </td>
                                              <td>
                                                {" "}
                                                Principal, Faculty of Education
                                                Swaminarayan University{" "}
                                              </td>
                                              <td> Chairperson </td>
                                            </tr>

                                            <tr>
                                              <td colSpan="4">
                                                <h5 className="text-center">
                                                  Members
                                                </h5>
                                              </td>
                                            </tr>
                                            <tr className="ml-2">
                                              <td> 2 </td>
                                              <td>Dr. Nitin Raval</td>
                                              <td>
                                                Assistant Professor Swaminarayan
                                                University
                                              </td>
                                              <td>Member</td>
                                            </tr>

                                            <tr className="ml-2">
                                              <td> 3 </td>
                                              <td>Miss. Sonal Khant</td>
                                              <td>
                                                Assistant Professor Swaminarayan
                                                University
                                              </td>
                                              <td>Member</td>
                                            </tr>

                                            <tr className="ml-2">
                                              <td> 4 </td>
                                              <td>Dr. Nileshkumar B. Gajjar</td>
                                              <td>
                                                Assistant Professor Swaminarayan
                                                University
                                              </td>
                                              <td>Member</td>
                                            </tr>

                                            <tr className="ml-2">
                                              <td> 5 </td>
                                              <td>Mr. Azad Patel</td>
                                              <td>
                                                {" "}
                                                Assistant Teacher IIT ,
                                                Gandhinagar.{" "}
                                              </td>
                                              <td> Member </td>
                                            </tr>

                                            <tr className="ml-2">
                                              <td> 6 </td>
                                              <td>Dr.Ramjibhai N. Patel</td>
                                              <td>
                                                {" "}
                                                Professor & HeadUmiya B.Ed.
                                                College , Gujarat University{" "}
                                              </td>
                                              <td> Member </td>
                                            </tr>

                                            <tr className="ml-2">
                                              <td> 7 </td>
                                              <td>Mr.Priyesh Vyas</td>
                                              <td>
                                                {" "}
                                                Assistant Teacher M.Ed. College
                                                ,Vadu{" "}
                                              </td>
                                              <td> Member </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className="tab-pane fade"
                          id="nav-homeo"
                          role="tabpanel"
                          aria-labelledby="nav-homeo-tab"
                        >
                          <div className="row align-items-center">
                            <div className="col-lg-12">
                              <div className="faq-image pr-20">
                                <hr />

                                <div className="row">
                                  <div className="col-11">
                                    <div className="page-title-box d-flex align-items-center px-5 justify-content-between">
                                      <div className="advisor_thumb">
                                        <div className="logo">
                                          <img
                                            src="/assets/images/white-logo.png"
                                            className="white-logo"
                                            alt="logo"
                                            height={112}
                                            width={380}
                                          />
                                        </div>
                                      </div>

                                      <h4>
                                        {" "}
                                        Board of studies of the Faculty Of
                                        Homoeopathy
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                                <br />

                                <div className="row">
                                  <div className="col-12">
                                    <div className="card">
                                      <div className="card-body">
                                        <br />

                                        <table
                                          id="datatable"
                                          className="table table-bordered dt-responsive nowrap table-hover "
                                          style={{
                                            borderCollapse: "collapse",
                                            borderSpacing: 0,
                                            width: "100%",
                                          }}
                                        >
                                          <thead>
                                            <tr>
                                              <th> Sl.No. </th>
                                              <th> Name Of Member </th>
                                              <th>
                                                {" "}
                                                Designation and Organisation{" "}
                                              </th>
                                              <th> Position </th>
                                            </tr>
                                          </thead>

                                          <tbody>
                                            <tr>
                                              <td colSpan="4">
                                                <h5 className="text-center">
                                                  Chairperson
                                                </h5>
                                              </td>
                                            </tr>
                                            <tr className="ml-2">
                                              <td> 1 </td>
                                              <td> Dr. Priyanka Vyas </td>
                                              <td>
                                                Professor,Faculty of Homoeopathy
                                                Swaminarayan University
                                              </td>
                                              <td> Chairperson </td>
                                            </tr>

                                            <tr>
                                              <td colSpan="4">
                                                <h5 className="text-center">
                                                  Members
                                                </h5>
                                              </td>
                                            </tr>
                                            <tr className="ml-2">
                                              <td> 2 </td>
                                              <td>Dr Jatin Dhagat</td>
                                              <td>
                                                Professor Swaminarayan
                                                University
                                              </td>
                                              <td>Member</td>
                                            </tr>

                                            <tr className="ml-2">
                                              <td> 3 </td>
                                              <td>Dr. Ramnaresh Kumar</td>
                                              <td>
                                                Professor Swaminarayan
                                                University
                                              </td>
                                              <td>Member</td>
                                            </tr>

                                            <tr className="ml-2">
                                              <td> 4 </td>
                                              <td>Dr. Kalpit Sanghavi</td>
                                              <td>
                                                Principal, member of council of
                                                homoeopathic system Shreev H.N.
                                                Shukla Homoeopathic Medical
                                                College
                                              </td>
                                              <td>Member</td>
                                            </tr>

                                            <tr className="ml-2">
                                              <td> 5 </td>
                                              <td>Dr. Tarun Dave</td>
                                              <td>
                                                Professor Dr. V.H. Dave
                                                Homoeopathic college, S. P.
                                                University
                                              </td>
                                              <td> Member </td>
                                            </tr>

                                            <tr className="ml-2">
                                              <td> 6 </td>
                                              <td>Dr. Hardik Khamar</td>
                                              <td>
                                                Clinical researcher, Visiting
                                                Professor Dr. V.H. Dave
                                                Homoeopathic college, S.
                                                P.University
                                              </td>
                                              <td> Member </td>
                                            </tr>

                                            <tr className="ml-2">
                                              <td> 7 </td>
                                              <td>Dr. Munjal Thakar</td>
                                              <td>
                                                Clinical researcher, Visiting
                                                Professor Ahmadabad Homoeopathic
                                                Medical College
                                              </td>
                                              <td> Member </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className="tab-pane fade"
                          id="nav-ayu"
                          role="tabpanel"
                          aria-labelledby="nav-ayu-tab"
                        >
                          <div className="row align-items-center">
                            <div className="col-lg-12">
                              <div className="faq-image pr-20">
                                <hr />

                                <div className="row">
                                  <div className="col-11">
                                    <div className="page-title-box d-flex align-items-center px-5 justify-content-between">
                                      <div className="advisor_thumb">
                                        <div className="logo">
                                          <img
                                            src="/assets/images/white-logo.png"
                                            className="white-logo"
                                            alt="logo"
                                            height={112}
                                            width={380}
                                          />
                                        </div>
                                      </div>

                                      <h4>
                                        {" "}
                                        Board of studies of the Faculty Of
                                        Ayurveda
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                                <br />

                                <div className="row">
                                  <div className="col-12">
                                    <div className="card">
                                      <div className="card-body">
                                        <br />

                                        <table
                                          id="datatable"
                                          className="table table-bordered dt-responsive nowrap table-hover "
                                          style={{
                                            borderCollapse: "collapse",
                                            borderSpacing: 0,
                                            width: "100%",
                                          }}
                                        >
                                          <thead>
                                            <tr>
                                              <th> Sl.No. </th>
                                              <th> Name Of Member </th>
                                              <th>
                                                {" "}
                                                Designation and Organisation{" "}
                                              </th>
                                              <th> Position </th>
                                            </tr>
                                          </thead>

                                          <tbody>
                                            <tr>
                                              <td colSpan="4">
                                                <h5 className="text-center">
                                                  Chairperson
                                                </h5>
                                              </td>
                                            </tr>
                                            <tr className="ml-2">
                                              <td> 1 </td>
                                              <td> Dr. Rohit Gokarn </td>
                                              <td>
                                                Dean, Faculty of Ayurveda
                                                Swaminarayan University
                                              </td>
                                              <td> Chairperson </td>
                                            </tr>

                                            <tr>
                                              <td colSpan="4">
                                                <h5 className="text-center">
                                                  Members
                                                </h5>
                                              </td>
                                            </tr>
                                            <tr className="ml-2">
                                              <td> 2 </td>
                                              <td>Dr. Hrishikesh Gaitonde</td>
                                              <td>
                                                Principal, KJIT, SavliGujarat
                                                Ayurveda university Jamnagar
                                              </td>
                                              <td>Member</td>
                                            </tr>

                                            <tr className="ml-2">
                                              <td> 3 </td>
                                              <td>Dr.Rajesh Barwaliya</td>
                                              <td>
                                                Professor, Akhandanand Ayurved
                                                college, Ahmedabad
                                              </td>
                                              <td>Member</td>
                                            </tr>

                                            <tr className="ml-2">
                                              <td> 4 </td>
                                              <td>Dr. Pravin Hirpara</td>
                                              <td>
                                                Founder member,A for Ayurveda
                                                ,Institute
                                              </td>
                                              <td>Member</td>
                                            </tr>

                                            <tr className="ml-2">
                                              <td> 5 </td>
                                              <td>Dr.Anil Avhad</td>
                                              <td>
                                                Research officer,CCRAS,
                                                Ahmedabad
                                              </td>
                                              <td> Member </td>
                                            </tr>

                                            <tr className="ml-2">
                                              <td> 6 </td>
                                              <td>Dr. Anita Ratnwat</td>
                                              <td>
                                                Associate Professor Swaminarayan
                                                University
                                              </td>
                                              <td> Member </td>
                                            </tr>

                                            <tr className="ml-2">
                                              <td> 7 </td>
                                              <td>Dr.Zalak Patel</td>
                                              <td>
                                                Assistant Professor Swaminarayan
                                                University
                                              </td>
                                              <td> Member </td>
                                            </tr>

                                            <tr className="ml-2">
                                              <td> 8 </td>
                                              <td>Dharmik Kachchhi</td>
                                              <td>
                                                Alumni Swaminarayan University
                                              </td>
                                              <td> Member </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className="tab-pane fade"
                          id="nav-nursing"
                          role="tabpanel"
                          aria-labelledby="nav-nursing-tab"
                        >
                          <div className="row align-items-center">
                            <div className="col-lg-12">
                              <div className="faq-image pr-20">
                                <hr />

                                <div className="row">
                                  <div className="col-11">
                                    <div className="page-title-box d-flex align-items-center px-5 justify-content-between">
                                      <div className="advisor_thumb">
                                        <div className="logo">
                                          <img
                                            src="/assets/images/white-logo.png"
                                            className="white-logo"
                                            alt="logo"
                                            height={112}
                                            width={380}
                                          />
                                        </div>
                                      </div>

                                      <h4>
                                        {" "}
                                        Board of studies of the Faculty Of
                                        Nursing
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                                <br />

                                <div className="row">
                                  <div className="col-12">
                                    <div className="card">
                                      <div className="card-body">
                                        <br />

                                        <table
                                          id="datatable"
                                          className="table table-bordered dt-responsive nowrap table-hover "
                                          style={{
                                            borderCollapse: "collapse",
                                            borderSpacing: 0,
                                            width: "100%",
                                          }}
                                        >
                                          <thead>
                                            <tr>
                                              <th> Sl.No. </th>
                                              <th> Name Of Member </th>
                                              <th>
                                                {" "}
                                                Designation and Organisation{" "}
                                              </th>
                                              <th> Position </th>
                                            </tr>
                                          </thead>

                                          <tbody>
                                            <tr>
                                              <td colSpan="4">
                                                <h5 className="text-center">
                                                  Chairperson
                                                </h5>
                                              </td>
                                            </tr>
                                            <tr className="ml-2">
                                              <td> 1 </td>
                                              <td> Mrs. Kalpana S. Patel </td>
                                              <td>
                                                Professor ,Dean Faculty of
                                                NURSING Swaminarayan University
                                              </td>
                                              <td> Chairperson </td>
                                            </tr>

                                            <tr>
                                              <td colSpan="4">
                                                <h5 className="text-center">
                                                  Members
                                                </h5>
                                              </td>
                                            </tr>
                                            <tr className="ml-2">
                                              <td> 2 </td>
                                              <td>Mrs. Sona Modi</td>
                                              <td>
                                                Professor Swaminarayan
                                                University
                                              </td>
                                              <td>Member</td>
                                            </tr>

                                            <tr className="ml-2">
                                              <td> 3 </td>
                                              <td>Mrs. Asha Patel</td>
                                              <td>
                                                Principal GINERA College of
                                                Nursing Ahmedabad
                                              </td>
                                              <td>Member</td>
                                            </tr>

                                            <tr className="ml-2">
                                              <td> 4 </td>
                                              <td>Mrs. Jalpa Ghodasara</td>
                                              <td>
                                                Principal Government college of
                                                nursing Rajkot
                                              </td>
                                              <td>Member</td>
                                            </tr>

                                            <tr className="ml-2">
                                              <td> 5 </td>
                                              <td>Mr. S. Prem Kumar</td>
                                              <td>
                                                Professor C.M.PATEL NURSING
                                                COLLEGE
                                              </td>
                                              <td> Member </td>
                                            </tr>

                                            <tr className="ml-2">
                                              <td> 6 </td>
                                              <td>Ms. Amee Menon (OBG)</td>
                                              <td>
                                                V. Principal Government college
                                                of nursing Dharpur
                                              </td>
                                              <td> Member </td>
                                            </tr>

                                            <tr className="ml-2">
                                              <td> 7 </td>
                                              <td>Mrs. Daxa Patel</td>
                                              <td>
                                                Professor Sankalchand Patel
                                                University
                                              </td>
                                              <td> Member </td>
                                            </tr>

                                            <tr className="ml-2">
                                              <td> 8 </td>
                                              <td>Ms. Tejal Gajjar (CHN)</td>
                                              <td>
                                                Professor GINERA College of
                                                Nursing Ahmedabad
                                              </td>
                                              <td> Member </td>
                                            </tr>

                                            <tr className="ml-2">
                                              <td> 9 </td>
                                              <td>Mrs. Jagruti Prajapati</td>
                                              <td>
                                                Nursing superintendent GMERS
                                                GANDHINAGAR
                                              </td>
                                              <td> Member </td>
                                            </tr>

                                            <tr className="ml-2">
                                              <td> 10 </td>
                                              <td>Mr. Naresh Devanshi</td>
                                              <td>
                                                Staff nurse PSM Hospital kalol
                                              </td>
                                              <td> Member </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className="tab-pane fade"
                          id="nav-science"
                          role="tabpanel"
                          aria-labelledby="nav-science-tab"
                        >
                          <div className="row align-items-center">
                            <div className="col-lg-12">
                              <div className="faq-image pr-20">
                                <hr />

                                <div className="row">
                                  <div className="col-11">
                                    <div className="page-title-box d-flex align-items-center px-5 justify-content-between">
                                      <div className="advisor_thumb">
                                        <div className="logo">
                                          <img
                                            src="/assets/images/white-logo.png"
                                            className="white-logo"
                                            alt="logo"
                                            height={112}
                                            width={380}
                                          />
                                        </div>
                                      </div>

                                      <h4>
                                        {" "}
                                        Board of studies of the Faculty Of
                                        Science
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                                <br />

                                <div className="row">
                                  <div className="col-12">
                                    <div className="card">
                                      <div className="card-body">
                                        <br />

                                        <table
                                          id="datatable"
                                          className="table table-bordered dt-responsive nowrap table-hover "
                                          style={{
                                            borderCollapse: "collapse",
                                            borderSpacing: 0,
                                            width: "100%",
                                          }}
                                        >
                                          <thead>
                                            <tr>
                                              <th> Sl.No. </th>
                                              <th> Name Of Member </th>
                                              <th>
                                                {" "}
                                                Designation and Organisation{" "}
                                              </th>
                                              <th> Position </th>
                                            </tr>
                                          </thead>

                                          <tbody>
                                            <tr>
                                              <td colSpan="4">
                                                <h5 className="text-center">
                                                  Chairperson
                                                </h5>
                                              </td>
                                            </tr>
                                            <tr className="ml-2">
                                              <td> 1 </td>
                                              <td> Dr. Pradeep U. Verma </td>
                                              <td>
                                                Professor ,Dean Faculty of
                                                Science Swaminarayan University
                                              </td>
                                              <td> Chairperson </td>
                                            </tr>

                                            <tr>
                                              <td colSpan="4">
                                                <h5 className="text-center">
                                                  Members
                                                </h5>
                                              </td>
                                            </tr>
                                            <tr className="ml-2">
                                              <td> 2 </td>
                                              <td>Ms. Priyanshi Sharma</td>
                                              <td>
                                                Assistant Professor Swaminarayan
                                                University
                                              </td>
                                              <td>Member</td>
                                            </tr>

                                            <tr className="ml-2">
                                              <td> 3 </td>
                                              <td>Mr. Hetul Mistry</td>
                                              <td>
                                                Assistant Professor Swaminarayan
                                                University
                                              </td>
                                              <td>Member</td>
                                            </tr>

                                            <tr className="ml-2">
                                              <td> 4 </td>
                                              <td>Dr .Roshni Adiyecha</td>
                                              <td>
                                                Assistant Professo rIITE-
                                                Gandhinagar
                                              </td>
                                              <td>Member</td>
                                            </tr>

                                            <tr className="ml-2">
                                              <td> 5 </td>
                                              <td>Dr. Manishkumar B. Patel</td>
                                              <td>
                                                Assistant Professor Ganpat
                                                University
                                              </td>
                                              <td> Member </td>
                                            </tr>

                                            <tr className="ml-2">
                                              <td> 6 </td>
                                              <td>Dr .Edwin Aspi Pithawala</td>
                                              <td>
                                                Associate Professor Silver Oak
                                                University
                                              </td>
                                              <td> Member </td>
                                            </tr>

                                            <tr className="ml-2">
                                              <td> 7 </td>
                                              <td>Dr. Kunvar Yadav</td>
                                              <td>
                                                Assistant Professor Ganpat
                                                University
                                              </td>
                                              <td> Member </td>
                                            </tr>

                                            <tr className="ml-2">
                                              <td> 8 </td>
                                              <td>Dr. Tapan Patel</td>
                                              <td>
                                                Assistant Professor CHARUSAT
                                                University
                                              </td>
                                              <td> Member </td>
                                            </tr>

                                            <tr className="ml-2">
                                              <td> 9 </td>
                                              <td>Dr .Shivangi Mathur</td>
                                              <td>
                                                Principal President Science
                                                College
                                              </td>
                                              <td> Member </td>
                                            </tr>

                                            <tr className="ml-2">
                                              <td> 10 </td>
                                              <td>Dr. Dhiren Pandit</td>
                                              <td>
                                                Assistant Professor Nirma
                                                University
                                              </td>
                                              <td> Member </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className="tab-pane fade"
                          id="nav-law"
                          role="tabpanel"
                          aria-labelledby="nav-law-tab"
                        >
                          <div className="row align-items-center">
                            <div className="col-lg-12">
                              <div className="faq-image pr-20">
                                <hr />

                                <div className="row">
                                  <div className="col-11">
                                    <div className="page-title-box d-flex align-items-center px-5 justify-content-between">
                                      <div className="advisor_thumb">
                                        <div className="logo">
                                          <img
                                            src="/assets/images/white-logo.png"
                                            className="white-logo"
                                            alt="logo"
                                            height={112}
                                            width={380}
                                          />
                                        </div>
                                      </div>

                                      <h4>
                                        {" "}
                                        Board of studies of the Faculty Of Law
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                                <br />

                                <div className="row">
                                  <div className="col-12">
                                    <div className="card">
                                      <div className="card-body">
                                        <br />

                                        <table
                                          id="datatable"
                                          className="table table-bordered dt-responsive nowrap table-hover "
                                          style={{
                                            borderCollapse: "collapse",
                                            borderSpacing: 0,
                                            width: "100%",
                                          }}
                                        >
                                          <thead>
                                            <tr>
                                              <th> Sl.No. </th>
                                              <th> Name Of Member </th>
                                              <th>
                                                {" "}
                                                Designation and Organisation{" "}
                                              </th>
                                              <th> Position </th>
                                            </tr>
                                          </thead>

                                          <tbody>
                                            <tr>
                                              <td colSpan="4">
                                                <h5 className="text-center">
                                                  Chairperson
                                                </h5>
                                              </td>
                                            </tr>
                                            <tr className="ml-2">
                                              <td> 1 </td>
                                              <td>Dr. Dixit Patel </td>
                                              <td>
                                                Assistant Professor, Faculty of
                                                Law Swaminarayan University
                                              </td>
                                              <td> Chairperson </td>
                                            </tr>

                                            <tr>
                                              <td colSpan="4">
                                                <h5 className="text-center">
                                                  Members
                                                </h5>
                                              </td>
                                            </tr>
                                            <tr className="ml-2">
                                              <td> 2 </td>
                                              <td>Dr. Bhargavi Rao</td>
                                              <td>
                                                Assistant Professor, Faculty of
                                                Law Swaminarayan University
                                              </td>
                                              <td>Member</td>
                                            </tr>

                                            <tr className="ml-2">
                                              <td> 3 </td>
                                              <td>Dr. Mahesh Patel</td>
                                              <td>
                                                Principal Shree S.M Shah Law
                                                college,Mehsana
                                              </td>
                                              <td>Member</td>
                                            </tr>

                                            <tr className="ml-2">
                                              <td> 4 </td>
                                              <td>Dr. K.C Bharatiya</td>
                                              <td>
                                                Assistant Professor Anand Law
                                                College, Anand
                                              </td>
                                              <td>Member</td>
                                            </tr>

                                            <tr className="ml-2">
                                              <td> 5 </td>
                                              <td>Dr. Pankaj J. Modi</td>
                                              <td>
                                                Assistant Professor Govt Law
                                                College, Ahmedabad
                                              </td>
                                              <td> Member </td>
                                            </tr>

                                            <tr className="ml-2">
                                              <td> 6 </td>
                                              <td>Dr. Arundhani Dasani</td>
                                              <td>
                                                Assistant ProfessorGovt Law
                                                College, Ahmedabad
                                              </td>
                                              <td> Member </td>
                                            </tr>

                                            <tr className="ml-2">
                                              <td> 7 </td>
                                              <td>Dr. Paresh Dobaiya</td>
                                              <td>
                                                Assistant Professor Law College,
                                                Surendranagar
                                              </td>
                                              <td> Member </td>
                                            </tr>

                                            <tr className="ml-2">
                                              <td> 8 </td>
                                              <td>Dr. Ram Niwas Sharma</td>
                                              <td>
                                                Assistant Professor.Sr. Advocate
                                                Maritime Law,Bombay
                                              </td>
                                              <td> Member </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}
