import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { HDFC_PAYMENT_GATEWAY } from '../../Utils/apiConst'
import { toast } from 'react-toastify'
import { pgCollegeList } from '../../Data/pgCollege'

const PaymentGateway = () => {
    const [amount, setAmount] = useState()
    const [name, setName] = useState()
    const [email, setEmail] = useState()
    const [usn, setUsn] = useState()
    const [address,setAddress] = useState('')
    const [city,setCity] = useState('')
    const [state,setState] = useState('Gujarat')
    const [country,setCountry] = useState('India')
    const [pincode,setPincode] = useState('')
    const [merchant_faculty,setMerchantFaculty] = useState('')

    const ref= useRef();

    const [data, setData] = useState()

    const handleSubmit = async() => {
        if(!amount || !name ||!email ||!address ||!city ||!state ||!country ||!pincode ||!merchant_faculty){ toast.error("Please fill all the fields"); return }
        const config = {
            headers: {
                'Content-Type': 'application/json'
            },
            url:HDFC_PAYMENT_GATEWAY,
            data: { 
                amount,
                merchant_param1: name,
                merchant_param2: usn,
                merchant_param3: email,
                merchant_param4: "UMS_ERP",
                billing_address: address,
                billing_city: city,
                billing_state: state,
                billing_country: country,
                billing_zip: pincode,
                merchant_faculty,
                m_id:merchant_faculty
            },
            method:'post'
        }
        await axios(config)
        .then( res=>{
            console.log(res.data);
            setData(res.data)
        })
        .catch(err=>{
            console.log(err);
        })

    }

    useEffect(() => {
        if (data && data.encRequest && data.accessCode) {
            ref.current.click();
        }
    }, [data])

    return (
        <div style={{minHeight:'500px'}} className='container'>
            <div className="row">
                <div className="col-md-3"></div>
                <div className="col-md-6">

                    <h5>Pay through HDFC Payment Gateway</h5>
                    <div className="form-group">
                        <label>Enter Amount in Rs</label>
                        <input value={amount} onChange={e=>setAmount(e.target.value)} type="number" className="form-control" placeholder='Enter amount here'/>
                    </div>
                    <div className="row mt-2">
                        <div className="col-6">
                            <div className="form-group">
                                <label>Name</label>
                                <input value={name} onChange={e=>setName(e.target.value)} type="text" className="form-control" placeholder='Enter Full Name here'/>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group">

                                <label>Enrollment Number (optional)</label>
                                <input value={usn} onChange={e=>setUsn(e.target.value)} type="text" className="form-control" placeholder='Enter USN here'/>

                         

                            </div>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="form-group">
                            <label>Email</label>
                            <input type="email" value={email} onChange={e=>{setEmail(e.target.value)}} className="form-control" placeholder='Enter Email here'/>
                            <p><strong className="text-danger"> Note: </strong> You will recieve a email with the reciept of payment</p>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="form-group">
                            <label>Faculty/College</label>
                            <select 
                            name="" id="" className='form-control'
                            value={merchant_faculty}
                            onChange={(e)=>{
                                setMerchantFaculty(e.target.value)
                            }}>
                                <option value="">Select Faculty/College</option>
                                {pgCollegeList.map((i,key)=>(
                                    <option value={i.Merchant_ID}>{i['Merchant Name']}</option>
                                ))}
                            </select>
                           </div>
                    </div>
                    <div className="row mt-2">
                        <div className="form-group">
                            <label>Address</label>
                            <input type="text" value={address} onChange={e=>{setAddress(e.target.value)}} className="form-control" placeholder='Enter Address here'/>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-6">
                            <div className="form-group">
                                <label>City </label>
                                <input value={city} onChange={e=>setCity(e.target.value)} type="text" className="form-control" placeholder='Enter Address here'/>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <label>State</label>
                                <input value={state} onChange={e=>setState(e.target.value)} type="text" className="form-control" placeholder='Enter State here'/>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-6">
                            <div className="form-group">
                                <label>Country</label>
                                <input value={country} onChange={e=>setCountry(e.target.value)} type="text" className="form-control" placeholder='Enter Country here'/>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <label>Pincode</label>
                                <input value={pincode} onChange={e=>setPincode(e.target.value)} type="text" className="form-control" placeholder='Enter Pincode here'/>
                            </div>
                        </div>
                    </div>
                     <button onClick={()=>handleSubmit()} className="btn btn-danger mt-3 mb-5">
                        Pay Now
                     </button>
                </div>
                <div className="col-md-3">
                </div>
            </div>
            <div >
            {data && data.encRequest && data.accessCode && (
                  <form method="post" action="https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction"> 
                      <input type="hidden" id="encRequest" name="encRequest" value={data.encRequest} />
                      <input type="hidden" name="access_code" id="access_code" value={data.accessCode} />
                      <input ref={ref} type="submit" className="btn btn-danger" value="Pay Now" />
                  </form>
                )}
            </div>
        </div>
    )
}

export default PaymentGateway