import React from "react";
import axios from 'axios';
import { useState,useEffect,useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { ACADEMIC_CALENDAR_GET } from '../../Utils/InfoUploadingConst';

export default function AcademicCalenderEducation({  setLoading }) {

  const [data, setData] = useState([]);

  const navigate = useNavigate();  

  const getData = async () => {
      const config = {
          method: "get",
          url: ACADEMIC_CALENDAR_GET,
          headers: {
              "Content-Type": "application/json",
          },
      };

      await axios(config)
          .then((res) => {
            // setLoading(0);
              res.data.data.sort((a, b) => b.session - a.session);
              res.data.data.forEach((element) => {
                  element.attachments = JSON.parse(element.attachments);
              });
              console.log(res.data.data)
              setData(res.data.data);
          })
          .catch((err) => {
            // setLoading(0);
              console.log(err);
          });
  };

  useEffect(() => {
      getData();
  }, []);

  function formatSession(session) {
    // Assuming session is in the format "YYYY"
    const academicYear = `${session}-${parseInt(session, 10) + 1}`;
    return academicYear;
  }

  const sessionformat = {
    2025: "2025-26" ,
    2024: "2024-25" ,
    2023: "2023-24" ,
    2022: "2022-23" ,
    2021: "2021-22" ,
    2020: "2020-21" ,
    2019: "2019-20" ,
    2018: "2018-19" ,
    2017: "2017-18" ,
    2016: "2016-17" ,
    2015: "2015-16" ,
    2014: "2014-15" ,
    2013: "2013-14" ,
  }


  return (
    <div>

<div className="container mt-5">
        <div className="row register" style={{ maxWidth: "100%" }}>
          <div className="col-md-12">
            <h3>
              ACADEMIC CALENDAR            
            </h3>
            <p>Academic Calendar documents Links are below</p>
          </div>

        
<div className="container">

  
<div className="alumni-area mt-4">
<div className="row">

{
      data
      .filter((item) => item.status == 'ACTIVE' && item.college_id == 1111010 && item.type === "Academic Calendar")
      .map((item, key) => ( 
<div className="col-md-4">
<a href={item?.attachments} target="_blank">
  <div className="single-alumoni-updates-card">
  <div className=" d-flex flex-row flex-wrap align-items-center ">
    <div className=" bg-danger text-center p-3 me-3">
    <i class="ri-file-download-line text-light " style={{fontSize:"20px"}}></i>
    </div>
   <div className="">
    <h5>
      {sessionformat[item?.session] || item?.session} Academic Calendar
    </h5>
   </div>
  </div>
</div>
</a>
  </div>
  ))

}

</div>

</div>
</div>
         
        </div>
        <br />
        <br />
      </div>












      {/* <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-md-12">
            <p>
              <strong>ACADEMIC CALENDER</strong>
              <br />
            </p>

            <table
              className="table table-bordered"
              style={{ borderCollapse: "collapse", width: "100%", height: 40 }}
              border={1}
            >
              <tbody>
                <tr style={{ height: 20 }}>
                  <td style={{ width: "25%", height: 20, textAlign: "center" }}>
                    <span style={{ color: "#000000", textAlign: "center" }}>
                      <strong>Events</strong>
                    </span>
                    <br />
                  </td>
                  <td style={{ width: "25%", height: 20, textAlign: "center" }}>
                    <span style={{ color: "#000000" }}>
                      <strong>Odd Semester</strong>
                    </span>
                    <br />
                  </td>
                  <td style={{ width: "25%", height: 20, textAlign: "center" }}>
                    <span style={{ color: "#000000" }}>
                      <strong>Even Semester</strong>
                    </span>
                    <br />
                  </td>
                  
                </tr>

                <tr style={{ height: 20 }}>
                  <td style={{ textAlign: "left" }}>Start of Semester</td>
                  <td style={{ textAlign: "center" }}>24/7/2023</td>
                  <td style={{ textAlign: "center" }}>1/1/2024</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "left" }}>Class Test(from)</td>
                  <td style={{ textAlign: "center" }}>As per time table</td>
                  <td style={{ textAlign: "center" }}>As per time table</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "left" }}>Assignment submission (previous semester)</td>
                  <td style={{ textAlign: "center" }}>----</td>
                  <td style={{ textAlign: "center" }}>----</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "left" }}>Result of Remedial midterm exam of previous semester</td>
                  <td style={{ textAlign: "center" }}>----</td>
                  <td style={{ textAlign: "center" }}>----</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "left" }}>Midterm exam</td>
                  <td style={{ textAlign: "center" }}>2/10/2023</td>
                  <td style={{ textAlign: "center" }}>11/3/2024</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "left" }}>Result of Midterm exam</td>
                  <td style={{ textAlign: "center" }}>12/10/2023</td>
                  <td style={{ textAlign: "center" }}>21/3/2024</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "left" }}>Assignment submission</td>
                  <td style={{ textAlign: "center" }}>4/12/2023</td>
                  <td style={{ textAlign: "center" }}>22/4/2024</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "left" }}>End Of the semester and Last date for submission of Marks- continuous evaluation/internal evaluation</td>
                  <td style={{ textAlign: "center" }}>16/12/2023</td>
                  <td style={{ textAlign: "center" }}>4/5/2024</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "left" }}>Preparatory Holidays</td>
                  <td style={{ textAlign: "center" }}>----</td>
                  <td style={{ textAlign: "center" }}>----</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "left" }}>End Semester Examination (Practical)</td>
                  <td style={{ textAlign: "center" }}>----</td>
                  <td style={{ textAlign: "center" }}>----</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "left" }}>End Semester Examination (Theory) and Supplementary Examination (practical)</td>
                  <td style={{ textAlign: "center" }}>18/12/2023</td>
                  <td style={{ textAlign: "center" }}>6/5/2024</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "left" }}>Supplementary Exam previous semester (Theory) From</td>
                  <td style={{ textAlign: "center" }}>----</td>
                  <td style={{ textAlign: "center" }}>6/5/2024</td>
                </tr>

                
              </tbody>
            </table>
          </div>
        </div>
      </div> */}

    </div>
  );
}
