import React from 'react'
import axios from "axios";
import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../Router/RouterConfig";
import "../Connect/Downloads/Brochure.scss";
import { LOCAL_EMPLOYEE } from "../../Utils/LocalStorageConstants";

function TeachingStaffMedical() {
    const navigate = useNavigate();

    const [data, setData] = useState([]);
  
    const [employeedata, setEmployeeData] = useState(
      JSON.parse(localStorage.getItem(LOCAL_EMPLOYEE))
    );
  
    useEffect(() => {
      setEmployeeData(
        JSON.parse(localStorage.getItem(LOCAL_EMPLOYEE))
      );
    }, [localStorage.getItem(LOCAL_EMPLOYEE)]);
  
    console.log("Employee Data",localStorage.getItem(LOCAL_EMPLOYEE));
  
  
    return (
      <div className="GoverningBody">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <br />
              <br />
              <br />
              <h1 className="text-center">Teaching Staff</h1>
              <br />
              <br />
              <div className="card border-0 shadow-sm">
                <div className="card-body">
                  <div className="container">
                    <div className="row">  
                
        <div className="events-area pt-100 pb-70">
          <div className="container d-flex">
            <div className="row justify-content-center">
              {employeedata
              .filter((item) => item.college_id == "1111006")
              .map((item, key) => {
                  return (
                    <>
                      <div className="col-12 col-sm-6 col-lg-3">
                        <div
                          className="single_advisor_profile wow fadeInUp"
                          data-wow-delay="0.3s"
                        >
                          <div className="single_advisor_details_info">
                            <h6>Name: {item.first_name}</h6>
                            <p className="designation">
                              <strong>Role: {item.role}</strong>
                            </p>
  
                            <p className="designation">
                              <strong>Employee Id: {item.user_id} </strong>
                            </p>
  
                            <br />
                            <p className="text-center">
                              <strong>Department:{item.department_id}</strong>
                            </p>
                            <p className="text-center">
                              <strong>Qualifications: {item.qualification}</strong>
                            </p>
  
                            <p className="text-center">
                              <strong>Date of Joing: {item.date_of_joining}</strong>
                            </p>
  
                            <p className="text-center">
                              <strong>Experience: {item.work_experience}</strong>
                            </p>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
  
              
            </div>
          </div>
        </div>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default TeachingStaffMedical;
