import React from 'react'

function Rules() {
  return (
    <div>
<div className="container register" style={{maxWidth:'1300px'}}>
  <div className="row">
    <div className="col-md-12">
      <h3>
        <strong>RULES AND REGULATIONS OF SSHC</strong></h3>
        <br />
        <ol>
          <li>A Student must get at least 75% attendance in Lecture, Demonstration,
        Practical and Clinical classes of each subject</li>
       <br />
        <li>A student must pass in all subjects in the Tests, Terminal and
        Preliminary Examinations. The passing mark is 50%.</li>
        <br />
        <li>A student failing to procure the required attendance and the passing
        marks (i.e. 50%) shall not be sent up for the University Examination
        till he/she fulfils the required attendance and obtains the required
        marks.</li>
        <br />
        <li>Students must possess the prescribed Text Books, Laboratory Journals,
        Medical Equipments, Apron etc.</li>
        <br />
        <li>In case of sickness an application muse be presented to the Academic
        Incharge and copy to Principal for leave or absence giving the reason by
        the Parents (Whose  specimen signature will be with the concerned office)</li>
        <br />
      <li> No Union or Association of the students shall be formed in the
        college</li>
        <br />
        <li>Students must be neat and tidy in their dress.</li>
        <br />
        <li>Wearing of Aprons and Name Badges / Identity Cards during work in the
        College or in the Hospital is essential.</li>
        <br />
        <li>The college office must be informed with change of address (if any)
        whether local or permanent.</li>
        <br />
        <li>Every student on admission must take out his/her Identity Card which
        he or she must wear. It is necessary for every student to show Identity
        card whenever demanded. No student is eligible for the Library facility
        unless he / she possess the authorised Identity card.</li>
        <br />
        <li>Students must not attend classes other than their own without the
        permission or the lecturer concerned.</li>
        <br />
        <li>Smoking and Tobacco chewing is strictly prohibited in the College
        and Hospital Campus.</li>
        <br />
      <li> In the college debates and other meetings the chair would be taken
        by the Principal or by a responsible person approved by the Principal
        and the subject to the debates should also be approved In advance.</li>
        <br />
        <li>Students applying for scholarships or free-ship or concessions in
        fees should note that such scholarships or freeship concessions are
        recommended only</li>
        <br />
     <li>   on the following conditions:
<ol>
  <li>That the student is regular in attendance and appears at periodical
        tests and examinations.</li>
        <br />
        <li>That his/her conduct and progress is satisfactory according to the
        principal.</li>
</ol>

     </li>
      <br />
       <li> Students are expected to behave with dignity and decorum and in
        conformity with the discipline of the Institution.</li>
        <br />
        <li>Any breach of discipline shall be suitably dealt with. Serious
        lapses of discipline and character shall render the student’s name
        liable to be struck of</li>
        <br />
      <li>the college at the discretion of the Principal.</li>
        <br />
      <li>If In the opinion of the principal, a student is not likely to
        benefit by his/her conduct in the college or if his/her continuance is
        considered to be detrimental to the best interest of the institution, the Principal may
        order such as students to leave the college. His decision in this regard
        shall be   final.</li>
      
      
        <br />
      <li>Every student is required to familiarize himself/herself with the
        rules as mentioned herewith.</li>
        <br />
        <li> Any student admitted to Shree Swaminarayan Homoeopathy College,
        Kalol shall not get transfer to any other Homoeopathic College if not
        permitted by University and NCH, New Delhi.</li>
        <br />
        <li>Absence without leave or participation in any agitation will be
        considered as breach of discipline and a student guilty of these shall
        not be granted any  progress certificate or recommended for any financial help</li>
              
        <br />
        <li>In all matters whether covered or not by the existing rules the
        decision of the Principal shall be final.</li>
        <br />
        <li>The management reserves the right of making addition to or omission
        from or alternation in the above rules and regulation without prior
        notice.</li>
      </ol>
    </div>
  </div>
</div>


        
    </div>
  )
}

export default Rules