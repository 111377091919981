import React from 'react'

function FeeStructure() {
  return (
    <div>

<div className="container mt-5 mb-5">


  <div className="row">
    <div className="col-md-12">
      <p>
        <strong>FEE STRUCTURE</strong>&nbsp; - Govt. Fee Regulation
        Committee(FRC) Approved - Academic year (2022-2023)
        <br />
      </p>
      <table className="table table-bordered"
        style={{ borderCollapse: "collapse", width: "100%", height: 40 }}
        border={1}
      >
        <tbody>
          <tr style={{ height: 20 }}>
            <td style={{ width: "20%", height: 20 }}>
              <span style={{ color: "#000000" }}>
                <strong>Seats</strong>
              </span>
              <br />
            </td>
            <td style={{ width: "20%", height: 20 }}>
              <span style={{ color: "#000000" }}>
                <strong>BHMS - I (01 year &amp; 06 Months / 18 Months)</strong>
              </span>
              <br />
            </td>
            <td style={{ width: "20%", height: 20 }}>
              <span style={{ color: "#000000" }}>
                <strong>BHMS - II (01 year)</strong>
              </span>
              <br />
            </td>
            <td style={{ width: "20%", height: 20 }}>
              <span style={{ color: "#000000" }}>
                <strong>BHMS - III (01 year)</strong>
              </span>
              <br />
            </td>
            <td style={{ width: "20%", height: 20 }}>
              <span style={{ color: "#000000" }}>
                <strong>BHMS - IV (01 year)</strong>
              </span>
              <br />
            </td>
          </tr>
          <tr style={{ height: 20 }}>
            <td style={{ width: "20%", height: 20 }}>
              Govt. Quota (GQ)
              <br />
            </td>
            <td style={{ width: "20%", height: 20 }}>
              Rs. 1,15,000
              <br />
            </td>
            <td style={{ width: "20%", height: 20 }}>
              Rs. 1,15,000
              <br />
            </td>
            <td style={{ width: "20%", height: 20 }}>
              Rs. 1,15,000
              <br />
            </td>
            <td style={{ width: "20%", height: 20 }}>Rs. 1,72,500</td>
          </tr>
          <tr>
            <td style={{ width: "20%" }}>
              Management Quota(MQ)
              <br />
            </td>
            <td style={{ width: "20%" }}>
              Rs. 1,55,000
              <br />
            </td>
            <td style={{ width: "20%" }}>
              Rs. 1,55,000
              <br />
            </td>
            <td style={{ width: "20%" }}>
              Rs. 1,55,000
              <br />
            </td>
            <td style={{ width: "20%" }}>
              <p>Rs. 2,32,500</p>
            </td>
          </tr>
        </tbody>
      </table>
      <p>
        <br />
      </p>
      <p>
        <strong>OTHER FEE ( One Time Only)</strong>
        <br />
      </p>
      <p>University Development Fee: Rs. 5,000</p>
      <p>Caution Money/Security Fee: Rs. 15000 (Refundable)</p>
      <p>
        <br />
      </p>
      <p>
        <strong>HOSTEL FEE ( GIRLS &amp; BOYS)</strong>
      </p>
      <p>Annual: Rs. 70,000 ( Fooding &amp; Lodging)</p>
    </div>
  </div>
</div>





    </div>
  )
}

export default FeeStructure