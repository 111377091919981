import React from "react";

function Mission_Vision() {
  return (
    <div>
      <>
        <div className="MissionVision">
          <div className="health-care-area ptb-100 bg-f4f6f9">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-6 col-md-6">
                  <div className="single-health-care-card">
                    <div className="card"></div>
                    <div className="health-care-content">
                      <a href="#">
                        <h3>Mission</h3>
                      </a>
                      <p>
                        <i
                          className="ri-checkbox-circle-line"
                          style={{ color: "#e32845" }}
                        />{" "}
                        Our Law College aims to be a premier institution of
                        legal education and research, acknowledged for its
                        dedication to academic excellence, pioneering legal
                        insights, and the cultivation of critical thinking and
                        problem-solving abilities. We endeavor to foster a
                        culture of legal inquisitiveness, cooperation, and
                        ethical practice, empowering our students to make
                        significant contributions to legal knowledge and tackle
                        the urgent issues of our time.
                        <br />
                        <br />
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="single-health-care-card">
                    <div className="img">
                      <a href="#">
                        <img
                          src="../assets/images/academics/mission.jpg"
                          alt="Image"
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-lg-6 col-md-6">
                    <div className="single-health-care-card">
                      <div className="card">
                        <a href="#">
                          <img
                            src="../assets/images/academics/vision.jpg"
                            alt="Image"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="single-health-care-card">
                      <div className="card"></div>
                      <div className="health-care-content">
                        <a href="#">
                          <h3>Vision</h3>
                        </a>
                        <p>
                          <i
                            className="ri-checkbox-circle-line"
                            style={{ color: "#e32845" }}
                          />{" "}
                          To offer outstanding education, encourage legal
                          scholarship, and facilitate innovative thinking within
                          the field of law, equipping students to become
                          informed and responsible global citizens who
                          contribute to the progress of society.
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

export default Mission_Vision;
