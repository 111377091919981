import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Http } from "../../Services/Services";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { MEDIA_GET, MEDIA_UPDATE } from "../../Utils/InfoUploadingConst";

function UniversityEvents() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  
  const getData = async () => {
    setLoading(1);
    await Http.get(MEDIA_GET)
      .then(async (res) => {

        // Filter data where type is "Events"
        const mediaData = res.data.data.filter(element => element.type == "Events" && element.status == "ACTIVE");

        mediaData.sort((a, b) => b.id - a.id);
        console.log(res.data.data);
        setData(mediaData);

      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(0);
  };

  useEffect(() => {
    getData();
  }, []);

  const next = async (id, data1, images) => {
    console.log("error here");
    navigate(`/eventsDetails/${id}`, {
      state: {
        // images: images,
        images: Array.isArray(images) ? images : [images],
        data: data1,
      },
    });
  };

  return (
    <div className="Events ">
      <div class="page-banner-area bg-3">
        <div class="container">
          <div class="page-banner-content">
            <h1>Events </h1>
            <ul>
              <li>
                {" "}
                <Link to={"./../"}> Home </Link>
              </li>

              <li>Events</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="events-area pt-100 pb-70">
        <div className="container d-flex">
          <div className="row justify-content-center">
            {/* {data &&
              data?.map((item, key) => {
                return (
                  <>
                    <div
                      className="col-lg-3 col-md-4"
                      style={{ cursor: "pointer" }}
                    >
                      <a
                        onClick={() => next(item?.id, item, item?.attachment)}
                      >
                        <div className="single-events-card style-4">
                          <div className="events-image">
                            <img
                              className="eventimage"
                              src={item?.attachment}
                              alt="attachment"
                            />
                            <div className="date">
                              <span>{item?.date.split("T")[0].slice(-2)}</span>
                              <p>{item?.month}</p>
                            </div>
                          </div>
                          <div className="events-content mt-4">
                            <h4>{item?.title}</h4>
                          </div>
                        </div>
                      </a>
                    </div>
                  </>
                );
              })} */}

            {data &&
              data?.map((item, key) => {
                return (
                  <div
                    key={key}
                    className="col-lg-3 col-md-4 single-events-card style-4"
                    style={{ cursor: "pointer" }}
                    onClick={() => next(item?.id, item, item?.attachment)}
                  >
                    <div className="events-image">
                      {item?.attachment && (
                        <img
                          className="eventimage"
                          src={item?.attachment}
                          alt="attachment"
                        />
                      )}
                      <div className="date">
                        <span>{item?.date.split("T")[0].slice(-2)}</span>
                        <p>{item?.month}</p>
                      </div>
                    </div>
                    <div className="events-content mt-4">
                      <h4>{item?.title}</h4>
                      {/* <h6>
                        <a href={item?.attachment} target="_blank">
                          Click Here
                        </a>
                      </h6> */}
                      {item?.video ? (
                        <p className="text-primary">
                          Click here to watch the video
                          <span
                            style={{
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                            onClick={() => window.open(item?.video, "_blank")}
                          >
                            {" "}
                            here
                          </span>
                        </p>
                      ) : null}
                    </div>
                  </div>
                );
              })}

           
          </div>
        </div>
      </div>

      
    </div>
  );
}

export default UniversityEvents;