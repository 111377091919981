import React from 'react'
import '../Arts/OfficerProfile.scss'

function VicePresident2ArtsCommerce() {

    const data = {
        quote : "Creativity is just connecting things. When you ask creative people how they did something, they feel a little guilty because they didn't really do it, they just saw something. It seemed obvious to them after a while. That's because they were able to connect experiences they've had and synthesize new things."
    }

  return (
    <div>
      <>
      <div className="container mt-5 mb-5">
      <h1 className="text-center">Profile</h1>
      <br/>
      <div className="row">
        <div className="col-md-4">
            <br/>
            <img
              src="../assets/images/academics/vicepresident-1.jpg"
              alt=""
              className="img-responsive img-thumbnail"
              width={400}
              height={220}
            />
            <br />
            <br />
       
        </div>
        <div className="col-md-8">
          {/* <h2>
          Vice President
            <br />
          </h2> */}
          <h4 className="text-danger text-bold">Shastri Swami Bhaktavatsaldasji</h4>
          <h6 className="text-dark">Vice President</h6>
          <p style={{ textAlign: "justify" }}>
          A Civil Engineer from Gujarat Technical University Ahmedabad directs and controls the work and resources of the institutes and hospitals managed by Shree Swaminarayan Vishvamangal Gurukul, Kalol. His role and responsibility presently in the Trust and University include the recruitment and retention of the required numbers of staff to achieve the mission and objectives of the University. He reviews the performances of the constituent colleges/schools of the University and takes care of the financial matters including financial planning and sustainability, resource allocation, identification of new sources of income, the monitoring of expenditure to ensure that it is within appropriate levels, ensuring the linking of resource allocation to strategic and operational planning.
          </p>
          <p style={{ textAlign: "justify" }}>
          He also ensures compliance with institute’s approved financial policies; ensuring all staff are aware of the existence and extent of the University’s financial regulations. He is a member of the Governing Body of more than 10 Institutes run by Shree Swaminarayan Vidhyamandal Gurukul Trust.
          </p>
          <var></var>
        </div>
      </div>
      {/* <br /> */}
      {
      data?.quote&&
    <div className="row justify-content-center align-content-center mt-5">
      <div className="col-md-12">
        <blockquote className="otro-blockquotes">
            <h4> Message -</h4>
            <h6 className="ml-5">{data?.quote}</h6>
          <span className="text-dark">- "Shastri Swami Bhaktavatsaldasji"</span>
        </blockquote>
      </div>
    </div>
    }
      
      </div>
      </>
    </div>
  )
}

export default VicePresident2ArtsCommerce;
