import React from "react";

const principalImage = "/assets/ayurveda/images/rohit-gokarn.jpg";

function PrincipalMessage() {
  return (
    <div>
      <>
        {/* Start For Content */}
        <div className="container mt-5 mb-5">
          <div className="row">
            <div className="col-md-3">
              <img
                src={principalImage}
                alt="pricipal-image"
                className="img-responsive img-thumbnail"
                width={261}
                height={261}
              />
              <br />
              <br />

              <h3>
              Prof (Dr.) Rohit Gokarn
                <br />
              </h3>
              <p style={{}}>
                Dean, Faculty of Ayurveda Swaminarayan University, Kalol
              </p>
              <p style={{}}>
              Email : ssackalol@gmail.com
              </p>
            </div>
            <div className="col-md-9">
              <h2>
                Principal's Message
                <br />
              </h2>
              <p style={{ textAlign: "justify" }}>
                Ayurveda occupies a large bulk of the health care force.
                Healthcare industry is transforming itself day by day. The
                professional Ayurveda doctor provides holistic care with
                compassion, empathy and respect for clients, dignity while
                educating them to achieve maximum level of health. He/She also
                participates in those activities contributing to prevention of
                illness and promotion of positive health. Therefore it is
                necessary to educate our students in the context of sound
                educational principles. As a Science, Ayurveda is based on a
                pefect blend of traditional and scientific knowledge which is
                empirical and diversifying into speciality areas.
                <br />
                <br />
                The opportunities for an Ayurveda graduate are varied and
                limitless. A new professional Ayurveda doctor may choose between
                clinical practice, education, research, management,
                administration, occupational and industrial sector.
                <br />
                <br />
                As the Principal of Shree Swaminarayan Ayurveda College, I would
                like to write a few words to express my impression and
                expectation on the institution. As an academician, clinician,
                researcher and administrator in the past, I know that Shree
                Swaminarayan Ayurveda College students are good in not only
                academic area but also sports, leadership and organizing
                activities. I highly appreciate the institution’s great effort
                and commitment to provide all-round education to students. Apart
                from striking for advancement in academic achievement, the
                college also nurtures students with good and proper attitude and
                values. The formal and informal curricula on humanity,
                aesthetics and physical development have developed students’
                multiple intelligence and aptitude for life-long learning. The
                college offers countless opportunities for students to take part
                in all sorts of co-curricular activities and community service
                to broaden their exposure and insight. The objective of every
                student of this institution is to have 3 A’s – Able to learn,
                Able to do and Able to engage. We at the institute nurture our
                students to elite in various aspects.
                <br />
                <br />
                Being a principal, I have a strong belief to promote harmony,
                dedication and a win-win scenario for everyone in institute.
                Harmony can be interpreted as ‘team cohesion’. You may expect
                changes in the future which I believe to be the sources of
                momentum for improvement.
                <br />
                <br />I also believe on life-long learning. With a great team of
                teaching staff and excellent students, I expect a continuous and
                sustainable advancement in learning and teaching in Shree
                Swaminarayan Ayurveda College. It is my responsibility to
                continue the excellent culture of Shree Swaminarayan Ayurveda
                College and maintain sustainable advancement in the future.
              </p>
            </div>
          </div>
          <br />
          <br />
        </div>{" "}
        {/* End For Content */}
      </>
    </div>
  );
}

export default PrincipalMessage;
