import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import "./Home.scss";
import { Helmet } from "react-helmet";
import { Link, Navigate, useNavigate } from "react-router-dom";
import CountUp from "react-countup";
import {
  ADD_SLIDERS,
  UNIVERSITY_NOTIFICATIONS_GET,
} from "../../Utils/InfoUploadingConst";
// import NewsAnnouncementEvents from "./NewsAnnouncementEvents";
import NewsAnnouncementEventsNew from "./NewsAnnouncementEventsNew";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";

function Home({ setLoading }) {
  const sliderRef = useRef();
  const navigate = useNavigate();

  const data =
    ' <div id="lsq-portal-widget-a706c5d6-0412-11ee-8d02-062290c66066-0429d711-5875-48d0-ba00-4bf45f117212"class="lsq-portal-widget lsq-form-widget"><script src="https://portal-widgets.lsqportal.com/assets/bootstrap-widget.js"data-widget-id="a706c5d6-0412-11ee-8d02-062290c66066"data-version-id="0429d711-5875-48d0-ba00-4bf45f117212"data-formjs="https://dhx9mmhpfsala.cloudfront.net/cdn/externalforms/r21/js/lsq.form.js"data-URL="https://portal-widgets.lsqportal.com"onload="windo["___lsq-portal-widget-helpers___"].bootstrapLSQWidget(event)"charset="utf-8"> </script></div> ';

  useEffect(() => {
    console.log(sliderRef.current.className);
    if (sliderRef.current.className.includes("aos-animate")) {
    }
  }, [sliderRef]);

  const [info, setInfo] = useState([]);
  const [events, setEvents] = useState([]);

  // State for managing active slide index
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const getData = async () => {
    const config = {
      method: "get",
      url: ADD_SLIDERS,
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        res.data.data.sort((a, b) => b.id - a.id);
        const activeData = res.data.data.filter(
          (element) =>
            element.status === "ACTIVE" && element.college_id == 1111012
        );
        console.log("info -", activeData);
        setInfo(activeData);
        // console.log("data -", info);
      })
      .catch((err) => {
        console.log(err);
      });

    const config1 = {
      method: "get",
      url: UNIVERSITY_NOTIFICATIONS_GET,
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios(config1)
      .then((res) => {
        res.data.data.forEach((element) => {
          element.attachments = JSON.parse(element.attachments);
        });
        res.data.data.sort((a, b) => b.id - a.id);
        const eventsData = res.data.data.filter(
          (element) => element.status === "ACTIVE"
        );
        console.log(eventsData);
        setEvents(eventsData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const PopUpData = info?.filter((element) => element.type == "PopUp");
  console.log("PopUpData -", PopUpData);

  // Start automatic sliding when component mounts
  useEffect(() => {
    const interval = setInterval(() => {
      // Increment active slide index
      setActiveSlideIndex((prevIndex) =>
        prevIndex === info.length - 1 ? 0 : prevIndex + 1
      );
    }, 7000); // Change slide every 7 seconds (adjust as needed)

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, [info.length]); // Trigger effect when info length changes

  return (
    <div>
      <div className="Home">
        <>
          {/* popup */}
          <div className="container-fluid">
            <div
              className="modal fade"
              id="exampleModal"
              tabIndex={-1}
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div
                // className="modal-dialog modal-xl"
                className="modal-dialog modal-lg modal-dialog-centered"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel" />
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                  </div>
                  <div className="modal-body">
                    <div className="row">
                      <Swiper
                        modules={[Autoplay, Pagination, Navigation]}
                        autoplay={{ delay: 5000, disableOnInteraction: false }} // Autoplay with 7-second delay
                        slidesPerView={1}
                        navigation
                        pagination={{ clickable: true }}
                        onSlideChange={(swiper) =>
                          setActiveSlideIndex(swiper.realIndex)
                        }
                      >
                        {info
                          .filter((item) => item.type == "PopUp")
                          .map((item, index) => (
                            <SwiperSlide key={index}>
                              <h3 className="text-center blink_me d-none d-sm-block">
                                {item.title}
                              </h3>

                              <img
                                src={item.image.replace(/"/g, "")}
                                className="d-block w-100 img-fluid PopUp"
                                alt="..."
                              />
                            </SwiperSlide>
                          ))}
                      </Swiper>
                    </div>
                  </div>
                  <div className="modal-footer"></div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="preloader-area">
          <div className="spinner">
            <div className="inner">
              <img className="" src="assets/images/favicon.png" />
            </div>
          </div>
        </div> */}
          <div
            className="sidebarModal modal right fade"
            id="sidebarModal"
            tabIndex={-1}
            role="dialog"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <button type="button" className="close" data-bs-dismiss="modal">
                  <i className="ri-close-line" />
                </button>
                <div className="modal-body">
                  <a href="index.php">
                    <img
                      src="assets/images/logo.png"
                      className="main-logo"
                      alt="logo"
                    />
                    <img
                      src="assets/images/white-logo.png"
                      className="white-logo"
                      alt="logo"
                    />
                  </a>
                  <div className="sidebar-content">
                    <h3>About Us</h3>
                    <p>
                      Shastri Swami Premswaroopdasji,a follower of Bhakti and
                      Seva is the prime founder of Shree Swaminarayan
                      Vishvamangal Gurukul, Kalol. Swamiji Started his seva as a
                      saint in the Swaminarayan Mandir located at Bhuj. Swamiji
                      spent a period of 20 years in Satsang at the place.
                      <span id="dots">...</span>
                      <span id="more" style={{ display: "none" }}>
                        <br />
                        <br />
                        One Gujarati proverb says “Sadhu to Chalta Bhala”.
                        Swamiji left swaminarayan Mandir, Bhuj and arrived at
                        Swaminarayan Mandir, Kalupur in 1985 where he arranged
                        Satsang Khathas in the surrounding towns and villages.
                        By humble order of Acharya Maharajshri, Swamiji accepted
                        the services as the Mahant(Head) of Swaminarayan Mandir,
                        situated at Siddhpur and stayed over there for the place
                        Kalol, an entrance of North Gujarat Region and acquired
                        a piece of barren land and dreamed to establish a
                        gurukul providing education, shelter to helpless people
                        and a Goshala.
                        <br />
                        <br />
                        The first foundation stone was laid down by Param Pujya
                        Dharm Dhurandhar 1008 Acharyashri Tejendraprasadji in
                        the year 1993. Afterwards Swamiji continued Satsang,
                        Kathas and religious meetings in the nearby Towns and
                        Villages.A school was started in the year 1995, with
                        primary first and then secondary, Higher Secondary,
                        general section, Science section and school was
                        flourished.
                        <br />
                        <br />
                        Swamiji then started B.Ed, PTC, Degree, Pharmacy,
                        Diploma Engineering and Degree Engineering College and
                        M.E. at Kalol Gurukul campus. In 2012, a completely
                        modernized Shree Swaminarayan International School was
                        Shunya(Zero), a new world of temples of education was
                        created by this visionary and missionary Sadhu. The
                        temples which has no boundary of religion and caste,
                        where only Goddess Maa Saraswati is worshiped.
                        <br />
                        <br />
                        Best,
                        <br />
                        <br />
                        <b>
                          Shastri Swami Premswarupdasji
                          <br />
                          Managing Trustee
                        </b>
                      </span>
                    </p>
                    <div className="sidebar-btn">
                      <button id="myBtn" className="default-btn">
                        Read more
                      </button>
                    </div>
                  </div>
                  <div className="sidebar-contact-info">
                    <h3>Contact Information</h3>
                    <ul className="info-list">
                      <li>
                        <i className="ri-phone-fill" />{" "}
                        <a href="tel:+919879614190">+91 9879614190</a>
                      </li>
                      <li>
                        <i className="ri-phone-fill" />{" "}
                        <a href="tel:18008432900">1800-843-2900</a>
                      </li>
                      <li>
                        <i className="ri-mail-line" />
                        <a href="mailto:info@swaminarayanuniversity.ac.in">
                          info@swaminarayanuniversity.ac.in
                        </a>
                      </li>
                      <li>
                        <i className="ri-map-pin-line" />
                        <a
                          href="https://goo.gl/maps/yM5MKb2VHGw3D4QT9"
                          target="_blank"
                        >
                          Swaminarayan University,
                          <br />
                          Shree Swaminarayan Vishvamangal Gurukul,
                          <br />
                          Ahmedabad-Mehsana Highway,
                          <br /> At&amp;Po-Saij, Tal.-Kalol
                          <br /> Dist-Gandhinagar-382725,Gujarat,India.
                        </a>
                      </li>
                    </ul>
                  </div>
                  <ul className="sidebar-social-list">
                    <li>
                      <a href="https://www.facebook.com" target="_blank">
                        <i className="flaticon-facebook" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.twitter.com" target="_blank">
                        <i className="flaticon-twitter" />
                      </a>
                    </li>
                    <li>
                      <a href="https://linkedin.com/?lang=en" target="_blank">
                        <i className="flaticon-linkedin" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://instagram.com/?lang=en"
                        target="https://www.instagram.com/swaminarayan_university/"
                      >
                        <i className="flaticon-instagram" />
                      </a>
                    </li>
                  </ul>
                  <div className="contact-form">
                    <h3>Ready to Get Started?</h3>
                    <form
                      id="contactForm"
                      noValidate="true"
                      wtx-context="16737BB8-E061-4149-A1B8-B43D2EDE6832"
                    >
                      <div className="row">
                        <div className="col-lg-12 col-md-6">
                          <div className="form-group">
                            <input
                              type="text"
                              name="name"
                              className="form-control"
                              required=""
                              data-error="Please enter your name"
                              placeholder="Your name"
                              wtx-context="76A19C9C-3ED2-4A46-B2DC-15F7ADAE81B8"
                            />
                            <div className="help-block with-errors" />
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-6">
                          <div className="form-group">
                            <input
                              type="email"
                              name="email"
                              className="form-control"
                              required=""
                              data-error="Please enter your email"
                              placeholder="Your email address"
                              wtx-context="1AFB6A15-BE04-4D6E-810D-2DF40928B5EE"
                            />
                            <div className="help-block with-errors" />
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12">
                          <div className="form-group">
                            <input
                              type="text"
                              name="phone_number"
                              className="form-control"
                              required=""
                              data-error="Please enter your phone number"
                              placeholder="Your phone number"
                              wtx-context="005E9ECF-3368-4592-B547-65F64ECDF669"
                            />
                            <div className="help-block with-errors" />
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12">
                          <div className="form-group">
                            <textarea
                              name="message"
                              className="form-control"
                              cols={30}
                              rows={6}
                              required=""
                              data-error="Please enter your message"
                              placeholder="Write your message..."
                              defaultValue={""}
                            />
                            <div className="help-block with-errors" />
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12">
                          <button
                            type="submit"
                            className="default-btn"
                            style={{ pointerEvents: "all", cursor: "pointer" }}
                          >
                            Send Message
                            <span />
                          </button>
                          <div
                            id="msgSubmit"
                            className="h3 text-center hidden"
                          />
                          <div className="clearfix" />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="banner-area">
            <Swiper
              // Add Autoplay module and set autoplay options
              modules={[Autoplay, Pagination, Navigation]}
              autoplay={{ delay: 7000, disableOnInteraction: false }} // Autoplay with 7-second delay
              slidesPerView={1}
              navigation
              pagination={{ clickable: true }}
              onSlideChange={(swiper) => setActiveSlideIndex(swiper.realIndex)}
            >
              {/* Map through info to create swiper slides */}
              {info
                .filter((item) => item.type == "Main Slider")
                .map((item, index) => (
                  <SwiperSlide key={index}>
                    <img
                      src={item.image.replace(/"/g, "")}
                      className="d-block w-100 img-fluid h-80 main-slider"
                      alt="..."
                    />
                    <div className="carousel-caption d-none d-md-block">
                      <h1 className="text-light">{item.title}</h1>
                    </div>
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>

          {/* <div id="lsq-portal-widget-a706c5d6-0412-11ee-8d02-062290c66066-0429d711-5875-48d0-ba00-4bf45f117212"
    className="lsq-portal-widget lsq-form-widget"> */}
          <div id="admission">
            <a
              href="https://admission.swaminarayanuniversity.ac.in/"
              target="_blank"
              className="nav-link"
            >
              Admissions Enquiry
            </a>
          </div>
          {/* </div> */}

          {/* <div id="admission2" >
          <a href="https://leads.swaminarayanuniversity.ac.in/" target="_blank" className="nav-link">
          Application Form
          </a>
        </div> */}
          {/* <div dangerouslySetInnerHTML={{__html:data }}>

       </div> */}

          <div id="feedback">
            <Link to={"/PhdAdmissionForm"} target="_blank" className="nav-link">
              Ph.D Admissions
            </Link>
          </div>
          <Helmet>
            <link
              rel="stylesheet"
              href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css"
            />
          </Helmet>
          <a
            href="https://api.whatsapp.com/send?phone=916357675561&text=Hi,%20I'd%20like%20to%20know%20more%20about%20your%20University"
            className="float"
            target="_blank"
          >
            <i className="fa fa-whatsapp my-float" />
          </a>

          <div className="campus-information-area pb-70 mt-5">
            <div className="container">
              <div className="row align-items-center">
                <div
                  className="col-lg-6 aos-init"
                  // data-aos="fade-right"
                  // data-aos-easing="ease-in-sine"
                  // data-aos-duration={1300}
                  // data-aos-once="true"
                  ref={sliderRef}
                >
                  <div className="campus-content pr-20 slide-in-left">
                    <div className="campus-title">
                      <h2>Campus Information</h2>
                      <p>
                        Swaminarayana University is loacted at Kalol near
                        Ahmedabad, spread over 60 acres of greenery <br />
                        <br />
                        The college is approved by UGC and Estalished under
                        Gujarat Private Universities Act 2009. <br />
                        <br />
                        The region is well connected to all the parts of the
                        country by rail, road and airways.
                      </p>
                    </div>
                    <div className="list">
                      <div className="row">
                        <div className="col-lg-6 col-md-6">
                          <ul>
                            <li>
                              <i className="ri-check-fill" />
                              <p>Graduate Programs</p>
                            </li>
                            <li>
                              <i className="ri-check-fill" />
                              <p>Programs</p>
                            </li>
                            <li>
                              <i className="ri-check-fill" />
                              <p>Doctoral Degrees</p>
                            </li>
                            <li>
                              <i className="ri-check-fill" />
                              <p>Alumni &amp; Giving</p>
                            </li>
                          </ul>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <ul>
                            <li>
                              <i className="ri-check-fill" />
                              <p>Undergraduate</p>
                            </li>
                            <li>
                              <i className="ri-check-fill" />
                              <p>International Hubs</p>
                            </li>
                            <li>
                              <i className="ri-check-fill" />
                              <p>Doctoral Degrees</p>
                            </li>
                            <li>
                              <i className="ri-check-fill" />
                              <p>Global Students</p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="counter slide-in-left">
                      <div className="row">
                        <div className="col-lg-4 col-4">
                          <div className="counter-card">
                            <h1 style={{ display: "flex" }}>
                              <CountUp
                                start={0}
                                end={30}
                                delay={0}
                                duration={2}
                                enableScrollSpy
                              >
                                {({ countUpRef }) => (
                                  <div>
                                    <span ref={countUpRef} />
                                  </div>
                                )}
                              </CountUp>
                              <span className="target">+</span>
                            </h1>
                            <p>Years Of Experience</p>
                          </div>
                        </div>
                        <div className="col-lg-4 col-4">
                          <div className="counter-card">
                            <h1 style={{ display: "flex" }}>
                              <CountUp
                                start={0}
                                end={800}
                                delay={0}
                                duration={2}
                                enableScrollSpy
                              >
                                {({ countUpRef }) => (
                                  <div>
                                    <span ref={countUpRef} />
                                  </div>
                                )}
                              </CountUp>
                              <span className="target">+</span>
                            </h1>
                            <p>Staff</p>
                          </div>
                        </div>
                        <div className="col-lg-4 col-4">
                          <div className="counter-card">
                            <h1
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <CountUp
                                start={0}
                                end={10}
                                delay={0}
                                duration={2}
                                enableScrollSpy
                              >
                                {({ countUpRef }) => (
                                  <div>
                                    <span ref={countUpRef} />
                                  </div>
                                )}
                              </CountUp>
                              <span className="target heading-color">K</span>
                              <span className="target">+</span>
                            </h1>
                            <p>Students</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <a href="#" className="default-btn btn">
                    Start your application
                    <i className="flaticon-next" />
                  </a> */}
                  </div>
                </div>
                <div
                  className="col-lg-6 aos-init slide-in-right"
                  // data-aos="fade-up"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration={1300}
                  data-aos-once="true"
                >
                  <div className="campus-image pl-20">
                    <img
                      // src="/assets/images/campus-information/campus-3.jpg"
                      src="/assets/physiotherapy/Campus Image.jpg"
                      alt="Image"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* add program details here */}

          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <div className="row">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/university+images/engg.PNG"
                    className="img-fluid p-0"
                    alt=""
                  />
                  <div className="col-sm-12 p-0 ">
                    <h4
                      className="text-uppercase text-light p-3 mb-0"
                      style={{ backgroundColor: "#FC7F03" }}
                    >
                      FACULTY OF ENGINEERING
                    </h4>
                  </div>
                  <div className="col-sm-12 p-0 ">
                    <div className="bg-amber p-3">
                      <div className="d-flex justify-content-end">
                        <p className="text-light mx-4">Duration</p>
                        {/* <p className="text-light mx-2">Fees/Year</p> */}
                      </div>
                      {/* course details */}
                      <div className="d-flex   ">
                        <div className="flex-grow-1">
                          <h4 className="text-light"> Diploma</h4>
                        </div>
                        <div className=" ">
                          <h5 className=" bg-white rounded-pill px-2 d-flex align-items-center ">
                            {" "}
                            <i class="ri-history-fill mx-1 mt-1"></i> 3 Years
                          </h5>
                        </div>
                        {/* <div className="">
                          <h5 className="bg-white rounded-pill px-2 d-flex align-items-center mx-1 ">
                            {" "}
                            ₹ 37,000
                          </h5>
                        </div> */}
                      </div>
                      <div className="col-md-12 ">
                        <p className="text-light ">
                          {" "}
                          Automobile Engineering | Mechanical Engineering |
                          Electrical Enginerring <br /> Computer Science
                          Engineering | Information Engineering
                          <br /> Civil Engineering
                        </p>
                      </div>
                      <br />
                      <div className="d-flex  ">
                        <div className="flex-grow-1">
                          <h4 className="text-light"> BE</h4>
                        </div>
                        <div className=" ">
                          <h5 className=" bg-white rounded-pill px-2 d-flex align-items-center ">
                            {" "}
                            <i class="ri-history-fill mx-1 mt-1"></i> 4 Years
                          </h5>
                        </div>
                        {/* <div className="">
                          <h5 className="bg-white rounded-pill px-2 d-flex align-items-center mx-1 ">
                            {" "}
                            ₹ 58,000
                          </h5>
                        </div> */}
                      </div>
                      <div className="col-md-12">
                        <p className="text-light">
                          {" "}
                          Automobile Engineering | Mechanical Engineering |
                          Electrical Enginerring <br /> Computer Science
                          Engineering | Information Engineering
                          <br /> Civil Engineering
                        </p>
                      </div>
                      <br />
                      <div className="d-flex  ">
                        <div className="flex-grow-1">
                          <h4 className="text-light"> ME</h4>
                        </div>
                        <div className=" ">
                          <h5 className=" bg-white rounded-pill px-2 d-flex align-items-center ">
                            {" "}
                            <i class="ri-history-fill mx-1 mt-1"></i> 2 Years
                          </h5>
                        </div>
                        {/* <div className="">
                          <h5 className="bg-white rounded-pill px-2 d-flex align-items-center mx-1 ">
                            {" "}
                            ₹ 87,000
                          </h5>
                        </div> */}
                      </div>
                      <div className="col-md-12">
                        <p className="text-light">
                          {" "}
                          Mechanical Engineering | Computer Science Engineering{" "}
                          <br /> Electrical Engineering | Civil (Structural
                          Engineering)
                        </p>
                      </div>
                      <br />
                      <div className="d-flex  ">
                        <div className="flex-grow-1">
                          <h4 className="text-light"> Ph.D.</h4>
                        </div>
                        {/* <div className="">
                          <h5 className="bg-white rounded-pill px-2 d-flex align-items-center mx-1 ">
                            {" "}
                            ₹ 60,000
                          </h5>
                        </div> */}
                      </div>
                      <br />
                      <br />
                      <br />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-6">
                <div className="row">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/university+images/design.PNG"
                    className="img-fluid p-0"
                    alt=""
                  />
                  <div className="col-sm-12 p-0 ">
                    <h4 className="text-uppercase text-light bg-amber p-3 mb-0">
                      FACULTY OF Design
                    </h4>
                  </div>
                  <div className="col-sm-12 p-0 ">
                    <div className=" p-3">
                      <div className="d-flex justify-content-end ">
                        <p className=" mx-4">Duration</p>
                        {/* <p className=" mx-2">Fees/Year</p> */}
                      </div>
                      {/* course details */}
                      <div className="d-flex   ">
                        <div className="flex-grow-1">
                          <h4 className=""> D. Design</h4>
                        </div>
                        <div className=" ">
                          <h5 className=" bg-dark text-light rounded-pill px-2 d-flex align-items-center ">
                            {" "}
                            <i class="ri-history-fill mx-1 mt-1"></i> 3 Years
                          </h5>
                        </div>
                        {/* <div className="">
                          <h5 className="bg-dark text-light rounded-pill px-2 d-flex align-items-center mx-1 ">
                            {" "}
                            ₹ 50,000
                          </h5>
                        </div> */}
                      </div>
                      <div className="col-md-12 ">
                        <p className=" ">
                          {" "}
                          Fashion & Communication | Graphics & Animation
                        </p>
                      </div>
                      <br />
                      <div className="d-flex  ">
                        <div className="flex-grow-1">
                          <h4 className=""> B.Design</h4>
                        </div>
                        <div className=" ">
                          <h5 className=" bg-dark text-light rounded-pill px-2 d-flex align-items-center ">
                            {" "}
                            <i class="ri-history-fill mx-1 mt-1"></i> 4 Years
                          </h5>
                        </div>
                        {/* <div className="">
                          <h5 className="bg-dark text-light rounded-pill px-2 d-flex align-items-center mx-1 ">
                            {" "}
                            ₹ 2,25,000
                          </h5>
                        </div> */}
                      </div>
                      <div className="col-md-12">
                        <p className="">
                          {" "}
                          Fashion & Communication | Product & Industrial <br />{" "}
                          Graphics & Animation
                        </p>
                      </div>
                      <br />
                      <div className="d-flex  ">
                        <div className="flex-grow-1">
                          <h4 className=""> M.Design</h4>
                        </div>
                        <div className=" ">
                          <h5 className=" bg-dark text-light rounded-pill px-2 d-flex align-items-center ">
                            {" "}
                            <i class="ri-history-fill mx-1 mt-1"></i> 2 Years
                          </h5>
                        </div>
                        {/* <div className="">
                          <h5 className="bg-dark text-light rounded-pill px-2 d-flex align-items-center mx-1 ">
                            {" "}
                            ₹ 2,25,000
                          </h5>
                        </div> */}
                      </div>
                      <div className="col-md-12">
                        <p className=""> Fashion Design | UI / UX Design</p>
                      </div>
                      <br />
                      <div className="d-flex  ">
                        <div className="flex-grow-1">
                          <h4 className=""> B.A.</h4>
                        </div>
                        <div className=" ">
                          <h5 className=" bg-dark text-light rounded-pill px-2 d-flex align-items-center ">
                            {" "}
                            <i class="ri-history-fill mx-1 mt-1"></i> 4 Years
                          </h5>
                        </div>
                        {/* <div className="">
                          <h5 className="bg-dark text-light rounded-pill px-2 d-flex align-items-center mx-1 ">
                            {" "}
                            ₹ 1,25,000
                          </h5>
                        </div> */}
                      </div>
                      <div className="col-md-12">
                        <p className=""> Journalisam & Mass Communication</p>
                      </div>
                      <br />
                      <div className="d-flex  ">
                        <div className="flex-grow-1">
                          <h4 className=""> M.A.</h4>
                        </div>
                        <div className=" ">
                          <h5 className=" bg-dark text-light rounded-pill px-2 d-flex align-items-center ">
                            {" "}
                            <i class="ri-history-fill mx-1 mt-1"></i> 2 Years
                          </h5>
                        </div>
                        {/* <div className="">
                          <h5 className="bg-dark text-light rounded-pill px-2 d-flex align-items-center mx-1 ">
                            {" "}
                            ₹ 1,25,000
                          </h5>
                        </div> */}
                      </div>
                      <div className="col-md-12">
                        <p className=""> Journalisam & Mass Communication</p>
                      </div>
                      <br />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-6">
                <div className="row">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/university+images/commerce.png"
                    className="img-fluid p-0"
                    alt=""
                  />
                  <div className="col-sm-12 p-0 ">
                    <h4 className="text-uppercase text-light bg-amber p-3 mb-0">
                      FACULTY OF Commerce and Management
                    </h4>
                  </div>
                  <div className="col-sm-12 p-0 ">
                    <div className=" p-3">
                      <div className="d-flex justify-content-end ">
                        <p className=" mx-4">Duration</p>
                        {/* <p className=" mx-2">Fees/Year</p> */}
                      </div>
                      {/* course details */}
                      <div className="d-flex   ">
                        <div className="flex-grow-1">
                          <h4 className=""> BBA (Hons.)</h4>
                        </div>
                        <div className=" ">
                          <h5 className=" bg-dark text-light rounded-pill px-2 d-flex align-items-center ">
                            {" "}
                            <i class="ri-history-fill mx-1 mt-1"></i> 4 Years
                          </h5>
                        </div>
                        {/* <div className="">
                          <h5 className="bg-dark text-light rounded-pill px-2 d-flex align-items-center mx-1 ">
                            {" "}
                            ₹ 20,000
                          </h5>
                        </div> */}
                      </div>
                      <br />
                      <div className="d-flex  ">
                        <div className="flex-grow-1">
                          <h4 className=""> MBA</h4>
                        </div>
                        <div className=" ">
                          <h5 className=" bg-dark text-light rounded-pill px-2 d-flex align-items-center ">
                            {" "}
                            <i class="ri-history-fill mx-1 mt-1"></i> 2 Years
                          </h5>
                        </div>
                        {/* <div className="">
                          <h5 className="bg-dark text-light rounded-pill px-2 d-flex align-items-center mx-1 ">
                            {" "}
                            ₹ 70,000
                          </h5>
                        </div> */}
                      </div>
                      <div className="col-md-12">
                        <p className="">
                          {" "}
                          Finance | Marketing | Hospital Management | Healthcare
                          Management
                          <br />
                          Digital Marketing
                        </p>
                      </div>
                      <br />
                      <div className="d-flex  ">
                        <div className="flex-grow-1">
                          <h4 className=""> B.Com (Hons.)</h4>
                        </div>
                        <div className=" ">
                          <h5 className=" bg-dark text-light rounded-pill px-2 d-flex align-items-center ">
                            {" "}
                            <i class="ri-history-fill mx-1 mt-1"></i> 4 Years
                          </h5>
                        </div>
                        {/* <div className="">
                          <h5 className="bg-dark text-light rounded-pill px-2 d-flex align-items-center mx-1 ">
                            {" "}
                            ₹ 15,000
                          </h5>
                        </div> */}
                      </div>
                      <br />
                      <div className="d-flex  ">
                        <div className="flex-grow-1">
                          <h4 className=""> M.Com</h4>
                        </div>
                        <div className=" ">
                          <h5 className=" bg-dark text-light rounded-pill px-2 d-flex align-items-center ">
                            {" "}
                            <i class="ri-history-fill mx-1 mt-1"></i> 2 Years
                          </h5>
                        </div>
                        {/* <div className="">
                          <h5 className="bg-dark text-light rounded-pill px-2 d-flex align-items-center mx-1 ">
                            {" "}
                            ₹ 25,000
                          </h5>
                        </div> */}
                      </div>
                      <br />
                      <div className="d-flex  ">
                        <div className="flex-grow-1">
                          <h4 className=""> Ph.D.</h4>
                        </div>
                        <div className=" ">
                          <h5 className=" bg-dark text-light rounded-pill px-2 d-flex align-items-center ">
                            {" "}
                          </h5>
                        </div>
                        {/* <div className="">
                          <h5 className="bg-dark text-light rounded-pill px-2 d-flex align-items-center mx-1 ">
                            {" "}
                            ₹ 60,000
                          </h5>
                        </div> */}
                      </div>
                      <br />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-6">
                <div className="row">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/university+images/arts.png"
                    className="img-fluid p-0"
                    alt=""
                  />
                  <div className="col-sm-12 p-0 ">
                    <h4
                      className="text-uppercase text-light p-3 mb-0"
                      style={{ backgroundColor: "#FC7F03" }}
                    >
                      FACULTY OF ARTS & HUMANITIES
                    </h4>
                  </div>
                  <div className="col-sm-12 p-0  ">
                    <div className="bg-amber p-3">
                      <div className="d-flex justify-content-end ">
                        <p className="text-light mx-4">Duration</p>
                        {/* <p className="text-light mx-2">Fees/Year</p> */}
                      </div>
                      {/* course details */}
                      <div className="d-flex   ">
                        <div className="flex-grow-1">
                          <h4 className="text-light"> B.A. (Hons.)</h4>
                        </div>
                        <div className=" ">
                          <h5 className=" bg-white rounded-pill px-2 d-flex align-items-center ">
                            {" "}
                            <i class="ri-history-fill mx-1 mt-1"></i> 4 Years
                          </h5>
                        </div>
                        {/* <div className="">
                          <h5 className="bg-white rounded-pill px-2 d-flex align-items-center mx-1 ">
                            {" "}
                            ₹ 15,000
                          </h5>
                        </div> */}
                      </div>
                      <div className="col-md-12 ">
                        <p className="text-light ">
                          {" "}
                          English | Gujarati | Sanskrit | Economics <br /> Hindi
                          | History
                        </p>
                      </div>
                      <br />
                      <div className="d-flex  ">
                        <div className="flex-grow-1">
                          <h4 className="text-light"> M.A.</h4>
                        </div>
                        <div className=" ">
                          <h5 className=" bg-white rounded-pill px-2 d-flex align-items-center ">
                            {" "}
                            <i class="ri-history-fill mx-1 mt-1"></i> 2 Years
                          </h5>
                        </div>
                        {/* <div className="">
                          <h5 className="bg-white rounded-pill px-2 d-flex align-items-center mx-1 ">
                            {" "}
                            ₹ 25,000
                          </h5>
                        </div> */}
                      </div>
                      <div className="col-md-12">
                        <p className="text-light">
                          {" "}
                          English | Gujarati | Sanskrit | Economics <br /> Hindi
                          | History
                        </p>
                      </div>
                      <br />
                      <div className="d-flex  ">
                        <div className="flex-grow-1">
                          <h4 className="text-light"> Ph.D.</h4>
                        </div>
                        <div className=" ">
                          <h5 className=" bg-white rounded-pill px-2 d-flex align-items-center ">
                            {" "}
                          </h5>
                        </div>
                        {/* <div className="">
                          <h5 className="bg-white rounded-pill px-2 d-flex align-items-center mx-1 ">
                            {" "}
                            ₹ 60,000
                          </h5>
                        </div> */}
                      </div>
                      <br />
                      <br />
                      <br />
                      <br />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-6">
                <div className="row">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/university+images/law.png"
                    className="img-fluid p-0"
                    alt=""
                  />
                  <div className="col-sm-12 p-0 ">
                    <h4
                      className="text-uppercase text-light p-3 mb-0"
                      style={{ backgroundColor: "#FC7F03" }}
                    >
                      FACULTY OF Law
                    </h4>
                  </div>
                  <div className="col-sm-12 p-0  ">
                    <div className="bg-amber p-3">
                      <div className="d-flex justify-content-end ">
                        <p className="text-light mx-4">Duration</p>
                        {/* <p className="text-light mx-2">Fees/Year</p> */}
                      </div>
                      {/* course details */}
                      <div className="d-flex   ">
                        <div className="flex-grow-1">
                          <h4 className="text-light"> LL.B</h4>
                        </div>
                        <div className=" ">
                          <h5 className=" bg-white rounded-pill px-2 d-flex align-items-center ">
                            {" "}
                            <i class="ri-history-fill mx-1 mt-1"></i> 3 Years
                          </h5>
                        </div>
                        {/* <div className="">
                          <h5 className="bg-white rounded-pill px-2 d-flex align-items-center mx-1 ">
                            {" "}
                            ₹ 25,000
                          </h5>
                        </div> */}
                      </div>
                      <br />
                      <div className="d-flex  ">
                        <div className="flex-grow-1">
                          <h4 className="text-light"> LL.M</h4>
                        </div>
                        <div className=" ">
                          <h5 className=" bg-white rounded-pill px-2 d-flex align-items-center ">
                            {" "}
                            <i class="ri-history-fill mx-1 mt-1"></i> 1 Year
                          </h5>
                        </div>
                        {/* <div className="">
                          <h5 className="bg-white rounded-pill px-2 d-flex align-items-center mx-1 ">
                            {" "}
                            ₹ 50,000
                          </h5>
                        </div> */}
                      </div>
                      <div className="col-md-12">
                        <p className="text-light ">
                          {" "}
                          Business & Corporate law | Criminal & Security Law{" "}
                          <br /> Intellectual Property Law
                          {/* | IPR & Cyber Law */}
                        </p>
                      </div>
                      <br />
                      <div className="d-flex  ">
                        <div className="flex-grow-1">
                          <h4 className="text-light"> LL.M</h4>
                        </div>
                        <div className=" ">
                          <h5 className=" bg-white rounded-pill px-2 d-flex align-items-center ">
                            <i class="ri-history-fill mx-1 mt-1"></i> 2 Years{" "}
                          </h5>
                        </div>
                        {/* <div className="">
                          <h5 className="bg-white rounded-pill px-2 d-flex align-items-center mx-1 ">
                            {" "}
                            ₹ 29,000
                          </h5>
                        </div> */}
                      </div>
                      <div className="col-md-12">
                        <p className="text-light ">
                          {" "}
                          Business law | Criminal Law
                        </p>
                      </div>
                      <br />
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <h4 className="text-light"> Ph.D.</h4>
                        </div>
                        <div className=" ">
                          <h5 className=" bg-white rounded-pill px-2 d-flex align-items-center ">
                            {" "}
                          </h5>
                        </div>
                        {/* <div className="">
                          <h5 className="bg-white rounded-pill px-2 d-flex align-items-center mx-1 ">
                            {" "}
                            ₹ 60,000
                          </h5>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-6">
                <div className="row">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/university+images/edu.png"
                    className="img-fluid p-0"
                    alt=""
                  />
                  <div className="col-sm-12 p-0 ">
                    <h4 className="text-uppercase text-light bg-amber p-3 mb-0">
                      FACULTY OF Education
                    </h4>
                  </div>
                  <div className="col-sm-12 p-0 ">
                    <div className=" p-3">
                      <div className="d-flex justify-content-end ">
                        <p className=" mx-4">Duration</p>
                        {/* <p className=" mx-2">Fees/Year</p> */}
                      </div>
                      {/* course details */}
                      <div className="d-flex   ">
                        <div className="flex-grow-1">
                          <h4 className=""> B.Ed.</h4>
                        </div>
                        <div className=" ">
                          <h5 className=" bg-dark text-light rounded-pill px-2 d-flex align-items-center ">
                            {" "}
                            <i class="ri-history-fill mx-1 mt-1"></i> 2 Years
                          </h5>
                        </div>
                        {/* <div className="">
                          <h5 className="bg-dark text-light rounded-pill px-2 d-flex align-items-center mx-1 ">
                            {" "}
                            ₹ 32,000
                          </h5>
                        </div> */}
                      </div>
                      <br />
                      <div className="d-flex  ">
                        <div className="flex-grow-1">
                          <h4 className=""> Ph.D.</h4>
                        </div>
                        <div className=" ">
                          <h5 className=" bg-dark text-light rounded-pill px-2 d-flex align-items-center ">
                            {" "}
                          </h5>
                        </div>
                        {/* <div className="">
                          <h5 className="bg-dark text-light rounded-pill px-2 d-flex align-items-center mx-1 ">
                            {" "}
                            ₹ 60,000
                          </h5>
                        </div> */}
                      </div>
                      <br />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-6">
                <div className="row">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/university+images/itcs.png"
                    className="img-fluid p-0"
                    alt=""
                  />
                  <div className="col-sm-12 p-0 ">
                    <h4 className="text-uppercase text-light bg-amber p-3 mb-0">
                      FACULTY OF IT and Computer Science
                    </h4>
                  </div>
                  <div className="col-sm-12 p-0 ">
                    <div className=" p-3">
                      <div className="d-flex justify-content-end ">
                        <p className=" mx-4">Duration</p>
                        {/* <p className=" mx-2">Fees/Year</p> */}
                      </div>
                      {/* course details */}
                      <div className="d-flex   ">
                        <div className="flex-grow-1">
                          <h4 className=""> BCA (Hons.)</h4>
                        </div>
                        <div className=" ">
                          <h5 className=" bg-dark text-light rounded-pill px-2 d-flex align-items-center ">
                            {" "}
                            <i class="ri-history-fill mx-1 mt-1"></i> 4 Years
                          </h5>
                        </div>
                        {/* <div className="">
                          <h5 className="bg-dark text-light rounded-pill px-2 d-flex align-items-center mx-1 ">
                            {" "}
                            ₹ 25,000
                          </h5>
                        </div> */}
                      </div>
                      <br />
                      <div className="d-flex  ">
                        <div className="flex-grow-1">
                          <h4 className=""> B.Sc.IT (Hons.)</h4>
                        </div>
                        <div className=" ">
                          <h5 className=" bg-dark text-light rounded-pill px-2 d-flex align-items-center ">
                            {" "}
                            <i class="ri-history-fill mx-1 mt-1"></i> 4 Years
                          </h5>
                        </div>
                        {/* <div className="">
                          <h5 className="bg-dark text-light rounded-pill px-2 d-flex align-items-center mx-1 ">
                            {" "}
                            ₹ 25,000
                          </h5>
                        </div> */}
                      </div>
                      <br />
                      <div className="d-flex  ">
                        <div className="flex-grow-1">
                          <h4 className=""> MCA</h4>
                        </div>
                        <div className=" ">
                          <h5 className=" bg-dark text-light rounded-pill px-2 d-flex align-items-center ">
                            {" "}
                            <i class="ri-history-fill mx-1 mt-1"></i> 2 Years
                          </h5>
                        </div>
                        {/* <div className="">
                          <h5 className="bg-dark text-light rounded-pill px-2 d-flex align-items-center mx-1 ">
                            {" "}
                            ₹ 66,000
                          </h5>
                        </div> */}
                      </div>
                      <br />
                      <div className="d-flex  ">
                        <div className="flex-grow-1">
                          <h4 className=""> M.Sc.IT </h4>
                        </div>
                        <div className=" ">
                          <h5 className=" bg-dark text-light rounded-pill px-2 d-flex align-items-center ">
                            {" "}
                            <i class="ri-history-fill mx-1 mt-1"></i> 2 Years
                          </h5>
                        </div>
                        {/* <div className="">
                          <h5 className="bg-dark text-light rounded-pill px-2 d-flex align-items-center mx-1 ">
                            {" "}
                            ₹ 50,000
                          </h5>
                        </div> */}
                      </div>
                      <br />
                      <div className="d-flex  ">
                        <div className="flex-grow-1">
                          <h4 className=""> Ph.D.</h4>
                        </div>
                        <div className=" ">
                          <h5 className=" bg-dark text-light rounded-pill px-2 d-flex align-items-center ">
                            {" "}
                          </h5>
                        </div>
                        {/* <div className="">
                          <h5 className="bg-dark text-light rounded-pill px-2 d-flex align-items-center mx-1 ">
                            {" "}
                            ₹ 60,000
                          </h5>
                        </div> */}
                      </div>
                      <br />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-6">
                <div className="row">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/university+images/medicine.png"
                    className="img-fluid p-0"
                    alt=""
                  />
                  <div className="col-sm-12 p-0 ">
                    <h4
                      className="text-uppercase text-light p-3 mb-0"
                      style={{ backgroundColor: "#FC7F03" }}
                    >
                      FACULTY OF MEDICINE
                    </h4>
                  </div>
                  <div className="col-sm-12 p-0 ">
                    <div className="bg-amber p-3">
                      <div className="d-flex justify-content-end ">
                        <p className="text-light mx-4">Duration</p>
                        {/* <p className="text-light mx-2">Fees/Year</p> */}
                      </div>
                      {/* course details */}
                      <div className="d-flex   ">
                        <div className="flex-grow-1">
                          <h4 className="text-light"> MBBS</h4>
                        </div>
                        <div className=" ">
                          <h5 className=" bg-white  rounded-pill px-2 d-flex align-items-center ">
                            {" "}
                            <i class="ri-history-fill mx-1 mt-1"></i> 5.5 Years
                          </h5>
                        </div>
                        {/* <div className="">
                          <h5 className="bg-white rounded-pill px-2 d-flex align-items-center mx-1 ">
                            {" "}
                            ₹ 8,25,000*
                          </h5>
                        </div> */}
                      </div>
                      {/* <div className="col-md-12">
                        <p className="text-light text-right">
                          {" "}
                          MQ - 15,75,000/Year*
                        </p>
                      </div> */}
                      <br />
                      <div className="d-flex  ">
                        <div className="flex-grow-1">
                          <h4 className="text-light"> M.Sc.</h4>
                        </div>
                        <div className=" ">
                          <h5 className=" bg-white rounded-pill px-2 d-flex align-items-center ">
                            {" "}
                            <i class="ri-history-fill mx-1 mt-1"></i> 3 Years
                          </h5>
                        </div>
                        {/* <div className="">
                          <h5 className="bg-white rounded-pill px-2 d-flex align-items-center mx-1 ">
                            {" "}
                            ₹ 65,000*
                          </h5>
                        </div> */}
                      </div>
                      <div className="col-md-12">
                        <p className="text-light">
                          {" "}
                          Anatomy | Physiology | Biochemistry | Pharmacology{" "}
                          <br />
                          Microbiology
                        </p>
                      </div>
                      <br />
                      <div className="d-flex  ">
                        <div className="flex-grow-1">
                          <h4 className="text-light"> Ph.D.</h4>
                        </div>
                        <div className=" ">
                          <h5 className=" bg-white rounded-pill px-2 d-flex align-items-center ">
                            {" "}
                          </h5>
                        </div>
                        {/* <div className="">
                          <h5 className="bg-white rounded-pill px-2 d-flex align-items-center mx-1 ">
                            {" "}
                            ₹ 1,20,000
                          </h5>
                        </div> */}
                      </div>
                      <br />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-6">
                <div className="row">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/university+images/pharmacy.png"
                    className="img-fluid p-0"
                    alt=""
                  />
                  <div className="col-sm-12 p-0 ">
                    <h4
                      className="text-uppercase text-light p-3 mb-0"
                      style={{ backgroundColor: "#FC7F03" }}
                    >
                      FACULTY OF pharmacy
                    </h4>
                  </div>
                  <div className="col-sm-12 p-0  ">
                    <div className="bg-amber p-3">
                      <div className="d-flex justify-content-end ">
                        <p className="text-light mx-4">Duration</p>
                        {/* <p className="text-light mx-2">Fees/Year</p> */}
                      </div>
                      {/* course details */}
                      <div className="d-flex   ">
                        <div className="flex-grow-1">
                          <h4 className="text-light"> Pharm D.</h4>
                        </div>
                        <div className=" ">
                          <h5 className=" bg-white rounded-pill px-2 d-flex align-items-center ">
                            {" "}
                            <i class="ri-history-fill mx-1 mt-1"></i> 6 Years
                          </h5>
                        </div>
                        {/* <div className="">
                          <h5 className="bg-white rounded-pill px-2 d-flex align-items-center mx-1 ">
                            {" "}
                            ₹ 1,30,000
                          </h5>
                        </div> */}
                      </div>
                      <br />
                      <div className="d-flex  ">
                        <div className="flex-grow-1">
                          <h4 className="text-light"> D.Pharm*</h4>
                        </div>
                        <div className=" ">
                          <h5 className=" bg-white rounded-pill px-2 d-flex align-items-center ">
                            {" "}
                            <i class="ri-history-fill mx-1 mt-1"></i> 2 Years
                          </h5>
                        </div>
                        {/* <div className="">
                          <h5 className="bg-white rounded-pill px-2 d-flex align-items-center mx-1 ">
                            {" "}
                            ₹ 50,000*
                          </h5>
                        </div> */}
                      </div>
                      <br />
                      <div className="d-flex  ">
                        <div className="flex-grow-1">
                          <h4 className="text-light"> B.Pharm</h4>
                        </div>
                        <div className=" ">
                          <h5 className=" bg-white rounded-pill px-2 d-flex align-items-center ">
                            {" "}
                            <i class="ri-history-fill mx-1 mt-1"></i> 4 Years
                          </h5>
                        </div>
                        {/* <div className="">
                          <h5 className="bg-white rounded-pill px-2 d-flex align-items-center mx-1 ">
                            {" "}
                            ₹ 64,900
                          </h5>
                        </div> */}
                      </div>
                      <br />
                      <div className="d-flex  ">
                        <div className="flex-grow-1">
                          <h4 className="text-light"> M.Pharm</h4>
                        </div>
                        <div className=" ">
                          <h5 className=" bg-white rounded-pill px-2 d-flex align-items-center ">
                            {" "}
                            <i class="ri-history-fill mx-1 mt-1"></i> 2 Years
                          </h5>
                        </div>
                        {/* <div className="">
                          <h5 className="bg-white rounded-pill px-2 d-flex align-items-center mx-1 ">
                            {" "}
                            ₹ 1,01,400
                          </h5>
                        </div> */}
                      </div>
                      <div className="col-md-12">
                        <p className="text-light ">
                          {" "}
                          Pharmaceutics | Pharmaceutical Quality Assurance
                        </p>
                      </div>
                      <br />
                      <div className="d-flex  ">
                        <div className="flex-grow-1">
                          <h4 className="text-light"> Ph.D.</h4>
                        </div>
                        <div className=" ">
                          <h5 className=" bg-white rounded-pill px-2 d-flex align-items-center ">
                            {" "}
                          </h5>
                        </div>
                        {/* <div className="">
                          <h5 className="bg-white rounded-pill px-2 d-flex align-items-center mx-1 ">
                            {" "}
                            ₹ 60,000
                          </h5>
                        </div> */}
                      </div>
                      <br />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-6">
                <div className="row">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/university+images/science.png"
                    className="img-fluid p-0"
                    alt=""
                  />
                  <div className="col-sm-12 p-0 ">
                    <h4 className="text-uppercase text-light bg-amber p-3 mb-0">
                      FACULTY OF SCIENCE
                    </h4>
                  </div>
                  <div className="col-sm-12 p-0 ">
                    <div className=" p-3">
                      <div className="d-flex justify-content-end ">
                        <p className=" mx-4">Duration</p>
                        {/* <p className=" mx-2">Fees/Year</p> */}
                      </div>
                      {/* course details */}
                      <div className="d-flex   ">
                        <div className="flex-grow-1">
                          <h4 className=""> B.Sc. (Hons.)</h4>
                        </div>
                        <div className=" ">
                          <h5 className=" bg-dark text-light rounded-pill px-2 d-flex align-items-center ">
                            {" "}
                            <i class="ri-history-fill mx-1 mt-1"></i> 4 Years
                          </h5>
                        </div>
                        {/* <div className="">
                          <h5 className="bg-dark text-light rounded-pill px-2 d-flex align-items-center mx-1 ">
                            {" "}
                            ₹ 25,000
                          </h5>
                        </div> */}
                      </div>
                      <div className="col-md-12">
                        <p className=" ">
                          {" "}
                          Chemistry | Microbiology | Botany | Zoology |
                          Mathematics | Physics
                          <br />
                          Nutrition & Dietetics | Food Technology
                        </p>
                      </div>
                      <br />
                      <div className="d-flex  ">
                        <div className="flex-grow-1">
                          <h4 className=""> M.Sc.</h4>
                        </div>
                        <div className=" ">
                          <h5 className=" bg-dark text-light rounded-pill px-2 d-flex align-items-center ">
                            {" "}
                            <i class="ri-history-fill mx-1 mt-1"></i> 2 Years
                          </h5>
                        </div>
                        {/* <div className="">
                          <h5 className="bg-dark text-light rounded-pill px-2 d-flex align-items-center mx-1 ">
                            {" "}
                            ₹ 40,000
                          </h5>
                        </div> */}
                      </div>
                      <div className="col-md-12">
                        <p className=" ">
                          {" "}
                          Organic Chemistry | Inorganic Chemistry | Physical
                          Chemistry
                          <br /> Analytical Chemistry | Microbiology | Botany |
                          Biochemistry | Zoology
                          <br />
                          Mathematics | Biotechnology | Physics | Environment
                          Science
                          <br /> Pharmaceutical Chemistry
                        </p>
                      </div>
                      <br />
                      <div className="d-flex  ">
                        <div className="flex-grow-1">
                          <h4 className=""> Ph.D.</h4>
                        </div>
                        <div className=" ">
                          <h5 className=" bg-dark text-light rounded-pill px-2 d-flex align-items-center ">
                            {" "}
                          </h5>
                        </div>
                        {/* <div className="">
                          <h5 className="bg-dark text-light rounded-pill px-2 d-flex align-items-center mx-1 ">
                            {" "}
                            ₹ 60,000
                          </h5>
                        </div> */}
                      </div>
                      <br />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-6">
                <div className="row">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/university+images/homeo.png"
                    className="img-fluid p-0"
                    alt=""
                  />
                  <div className="col-sm-12 p-0 ">
                    <h4 className="text-uppercase text-light bg-amber p-3 mb-0">
                      FACULTY OF Homoeopathy
                    </h4>
                  </div>
                  <div className="col-sm-12 p-0 ">
                    <div className=" p-3">
                      <div className="d-flex justify-content-end ">
                        <p className=" mx-4">Duration</p>
                        {/* <p className=" mx-2">Fees/Year</p> */}
                      </div>
                      {/* course details */}
                      <div className="d-flex   ">
                        <div className="flex-grow-1">
                          <h4 className=""> B.H.M.S.</h4>
                        </div>
                        <div className=" ">
                          <h5 className=" bg-dark text-light rounded-pill px-2 d-flex align-items-center ">
                            {" "}
                            <i class="ri-history-fill mx-1 mt-1"></i> 5.5 Years
                          </h5>
                        </div>
                        {/* <div className="">
                          <h5 className="bg-dark text-light rounded-pill px-2 d-flex align-items-center mx-1 ">
                            {" "}
                            ₹ 1,15,000*
                          </h5>
                        </div> */}
                      </div>
                      {/* <div className="col-md-12">
                        <p className="text-right"> MQ - 1,55,000/Year*</p>
                      </div> */}
                      <br />
                      <div className="d-flex  ">
                        <div className="flex-grow-1">
                          <h4 className=""> M.D.</h4>
                        </div>
                        <div className=" ">
                          <h5 className=" bg-dark text-light rounded-pill px-2 d-flex align-items-center ">
                            {" "}
                            <i class="ri-history-fill mx-1 mt-1"></i> 3 Years
                          </h5>
                        </div>
                        {/* <div className="">
                          <h5 className="bg-dark text-light rounded-pill px-2 d-flex align-items-center mx-1 ">
                            {" "}
                            ₹ 1,56,000*
                          </h5>
                        </div> */}
                      </div>
                      {/* <div className="col-md-12">
                        <p className="text-right"> MQ - 1,85,000/Year*</p>
                      </div> */}
                      <div className="col-md-12">
                        <p className="">
                          {" "}
                          Materia Medica | Organon of Medicine | Repertory
                        </p>
                      </div>
                      <br />
                      <div className="d-flex  ">
                        <div className="flex-grow-1">
                          <h4 className=""> Ph.D.</h4>
                        </div>
                        <div className=" ">
                          <h5 className=" bg-dark text-light rounded-pill px-2 d-flex align-items-center ">
                            {" "}
                          </h5>
                        </div>
                        {/* <div className="">
                          <h5 className="bg-dark text-light rounded-pill px-2 d-flex align-items-center mx-1 ">
                            {" "}
                            ₹ 1,20,000
                          </h5>
                        </div> */}
                      </div>
                      <br />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-6">
                <div className="row">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/university+images/publichealth.png"
                    className="img-fluid p-0"
                    alt=""
                  />
                  <div className="col-sm-12 p-0 ">
                    <h4
                      className="text-uppercase text-light p-3 mb-0"
                      style={{ backgroundColor: "#FC7F03" }}
                    >
                      FACULTY OF PUBLIC HEALTH
                    </h4>
                  </div>
                  <div className="col-sm-12 p-0  ">
                    <div className="bg-amber p-3">
                      <div className="d-flex justify-content-end ">
                        <p className="text-light mx-4">Duration</p>
                        {/* <p className="text-light mx-2">Fees/Year</p> */}
                      </div>
                      {/* course details */}
                      <div className="d-flex   ">
                        <div className="flex-grow-1">
                          <h4 className="text-light"> B.Sc. (Hons.)</h4>
                        </div>
                        <div className=" ">
                          <h5 className=" bg-white rounded-pill px-2 d-flex align-items-center ">
                            {" "}
                            <i class="ri-history-fill mx-1 mt-1"></i> 4 Years
                          </h5>
                        </div>
                        {/* <div className="">
                          <h5 className="bg-white rounded-pill px-2 d-flex align-items-center mx-1 ">
                            {" "}
                            ₹ 60,000
                          </h5>
                        </div> */}
                      </div>
                      <div className="col-md-12">
                        <p className="text-light "> Public Health</p>
                      </div>
                      <br />
                      <div className="d-flex  ">
                        <div className="flex-grow-1">
                          <h4 className="text-light"> M.Sc.</h4>
                        </div>
                        <div className=" ">
                          <h5 className=" bg-white rounded-pill px-2 d-flex align-items-center ">
                            {" "}
                            <i class="ri-history-fill mx-1 mt-1"></i> 2 Years
                          </h5>
                        </div>
                        {/* <div className="">
                          <h5 className="bg-white rounded-pill px-2 d-flex align-items-center mx-1 ">
                            {" "}
                            ₹ 60,000
                          </h5>
                        </div> */}
                      </div>
                      <div className="col-md-12">
                        <p className="text-light "> Public Health</p>
                      </div>
                      <br />
                      <div className="d-flex  ">
                        <div className="flex-grow-1">
                          <h4 className="text-light"> MHA</h4>
                        </div>
                        <div className=" ">
                          <h5 className=" bg-white rounded-pill px-2 d-flex align-items-center ">
                            {" "}
                            <i class="ri-history-fill mx-1 mt-1"></i> 2 Years
                          </h5>
                        </div>
                        {/* <div className="">
                          <h5 className="bg-white rounded-pill px-2 d-flex align-items-center mx-1 ">
                            {" "}
                            ₹ 60,000
                          </h5>
                        </div> */}
                      </div>
                      <div className="col-md-12">
                        <p className="text-light ">
                          {" "}
                          Master of Hospital Administration
                        </p>
                      </div>
                      <br />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-6">
                <div className="row">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/university+images/ayurveda.png"
                    className="img-fluid p-0"
                    alt=""
                  />
                  <div className="col-sm-12 p-0 ">
                    <h4
                      className="text-uppercase text-light p-3 mb-0"
                      style={{ backgroundColor: "#FC7F03" }}
                    >
                      FACULTY OF Ayurveda
                    </h4>
                  </div>
                  <div className="col-sm-12 p-0  ">
                    <div className="bg-amber p-3">
                      <div className="d-flex justify-content-end ">
                        <p className="text-light mx-4">Duration</p>
                        {/* <p className="text-light mx-2">Fees/Year</p> */}
                      </div>
                      {/* course details */}
                      <div className="d-flex   ">
                        <div className="flex-grow-1">
                          <h4 className="text-light"> B.A.M.S.</h4>
                        </div>
                        <div className=" ">
                          <h5 className=" bg-white rounded-pill px-2 d-flex align-items-center ">
                            {" "}
                            <i class="ri-history-fill mx-1 mt-1"></i> 5.5 Years
                          </h5>
                        </div>
                        {/* <div className="">
                          <h5 className="bg-white rounded-pill px-2 d-flex align-items-center mx-1 ">
                            {" "}
                            ₹ 2,71,000*
                          </h5>
                        </div> */}
                      </div>
                      <div className="col-md-12">
                        <p className="text-light text-right">
                          {" "}
                          MQ - 3,85,000/Year*
                        </p>
                      </div>
                      <br />
                      <div className="d-flex  ">
                        <div className="flex-grow-1">
                          <h4 className="text-light"> M.D.</h4>
                        </div>
                        <div className=" ">
                          <h5 className=" bg-white rounded-pill px-2 d-flex align-items-center ">
                            {" "}
                            <i class="ri-history-fill mx-1 mt-1"></i> 3 Years
                          </h5>
                        </div>
                        {/* <div className="">
                          <h5 className="bg-white rounded-pill px-2 d-flex align-items-center mx-1 ">
                            {" "}
                            ₹ 4,25,000*
                          </h5>
                        </div> */}
                      </div>
                      {/* <div className="col-md-12">
                        <p className="text-light text-right">
                          {" "}
                          MQ - 6,50,000/Year*
                        </p>
                      </div> */}
                      <div className="col-md-12">
                        <p className="text-light ">
                          {" "}
                          Ayurveda Samhita Evam Siddhant | Kayachikitsa <br />
                          Roga Nidana Evam Vikrita Vigyana
                        </p>
                      </div>

                      <br />
                      <div className="d-flex  ">
                        <div className="flex-grow-1">
                          <h4 className="text-light"> M.S.</h4>
                        </div>
                        <div className=" ">
                          <h5 className=" bg-white rounded-pill px-2 d-flex align-items-center ">
                            {" "}
                            <i class="ri-history-fill mx-1 mt-1"></i> 3 Years
                          </h5>
                        </div>
                        {/* <div className="">
                          <h5 className="bg-white rounded-pill px-2 d-flex align-items-center mx-1 ">
                            {" "}
                            ₹ 4,25,000*
                          </h5>
                        </div> */}
                      </div>
                      {/* <div className="col-md-12">
                        <p className="text-light text-right">
                          {" "}
                          MQ - 6,50,000/Year*
                        </p>
                      </div> */}
                      <div className="col-md-12">
                        <p className="text-light ">
                          {" "}
                          Prasuti Evam Stri Roga | Shailya Tantra
                        </p>
                      </div>
                      <br />
                      <div className="d-flex  ">
                        <div className="flex-grow-1">
                          <h4 className="text-light"> Ph.D.</h4>
                        </div>
                        <div className=" ">
                          <h5 className=" bg-white rounded-pill px-2 d-flex align-items-center ">
                            {" "}
                          </h5>
                        </div>
                        {/* <div className="">
                          <h5 className="bg-white rounded-pill px-2 d-flex align-items-center mx-1 ">
                            {" "}
                            ₹ 1,20,000
                          </h5>
                        </div> */}
                      </div>
                      <br />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-6">
                <div className="row">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/university+images/nursing.png"
                    className="img-fluid p-0"
                    alt=""
                  />
                  <div className="col-sm-12 p-0 ">
                    <h4 className="text-uppercase text-light bg-amber p-3 mb-0">
                      FACULTY OF NURSING
                    </h4>
                  </div>
                  <div className="col-sm-12 p-0 ">
                    <div className=" p-3">
                      <div className="d-flex justify-content-end ">
                        <p className=" mx-4">Duration</p>
                        {/* <p className=" mx-2">Fees/Year</p> */}
                      </div>
                      {/* course details */}
                      <div className="d-flex   ">
                        <div className="flex-grow-1">
                          <h4 className=""> B.Sc. Nursing</h4>
                        </div>
                        <div className=" ">
                          <h5 className=" bg-dark text-light rounded-pill px-2 d-flex align-items-center ">
                            {" "}
                            <i class="ri-history-fill mx-1 mt-1"></i> 4 Years
                          </h5>
                        </div>
                        {/* <div className="">
                          <h5 className="bg-dark text-light rounded-pill px-2 d-flex align-items-center mx-1 ">
                            {" "}
                            ₹ 1,06,000*
                          </h5>
                        </div> */}
                      </div>
                      {/* <div className="col-md-12">
                        <p className="text-right"> MQ - 1,50,000/Year*</p>
                      </div> */}
                      <br />
                      <div className="d-flex  ">
                        <div className="flex-grow-1">
                          <h4 className=""> G.N.M. Nursing</h4>
                        </div>
                        <div className=" ">
                          <h5 className=" bg-dark text-light rounded-pill px-2 d-flex align-items-center ">
                            {" "}
                            <i class="ri-history-fill mx-1 mt-1"></i> 3 Years
                          </h5>
                        </div>
                        {/* <div className="">
                          <h5 className="bg-dark text-light rounded-pill px-2 d-flex align-items-center mx-1 ">
                            {" "}
                            ₹ 94,000*
                          </h5>
                        </div> */}
                      </div>
                      {/* <div className="col-md-12">
                        <p className="text-right"> MQ - 1,10,000/Year*</p>
                      </div> */}
                      <br />
                      <div className="d-flex  ">
                        <div className="flex-grow-1">
                          <h4 className=""> Post Basic B.Sc. Nursing</h4>
                        </div>
                        <div className=" ">
                          <h5 className=" bg-dark text-light rounded-pill px-2 d-flex align-items-center ">
                            {" "}
                            <i class="ri-history-fill mx-1 mt-1"></i> 2 Years
                          </h5>
                        </div>
                        {/* <div className="">
                          <h5 className="bg-dark text-light rounded-pill px-2 d-flex align-items-center mx-1 ">
                            {" "}
                            ₹ 70,000*
                          </h5>
                        </div> */}
                      </div>
                      {/* <div className="col-md-12">
                        <p className="text-right"> MQ - 1,10,000/Year*</p>
                      </div> */}
                      <br />
                      <div className="d-flex  ">
                        <div className="flex-grow-1">
                          <h4 className=""> A.N.M. Nursing</h4>
                        </div>
                        <div className=" ">
                          <h5 className=" bg-dark text-light rounded-pill px-2 d-flex align-items-center ">
                            {" "}
                            <i class="ri-history-fill mx-1 mt-1"></i> 2 Years
                          </h5>
                        </div>
                        {/* <div className="">
                          <h5 className="bg-dark text-light rounded-pill px-2 d-flex align-items-center mx-1 ">
                            {" "}
                            ₹ 38,000*
                          </h5>
                        </div> */}
                      </div>
                      {/* <div className="col-md-12">
                        <p className="text-right"> MQ - 40,000/Year*</p>
                      </div> */}
                      <br />
                      <div className="d-flex  ">
                        <div className="flex-grow-1">
                          <h4 className=""> M.Sc. Nursing</h4>
                        </div>
                        <div className=" ">
                          <h5 className=" bg-dark text-light rounded-pill px-2 d-flex align-items-center ">
                            {" "}
                            <i class="ri-history-fill mx-1 mt-1"></i> 2 Years
                          </h5>
                        </div>
                        {/* <div className="">
                          <h5 className="bg-dark text-light rounded-pill px-2 d-flex align-items-center mx-1 ">
                            {" "}
                            ₹ 1,20,000
                          </h5>
                        </div> */}
                      </div>
                      <div className="col-md-12">
                        <p className="">
                          {" "}
                          Community Health Nursing | Medical Surgical Nursing |{" "}
                          <br />
                          Obstetric and Gynecological Nursing <br />
                          Child Health Nursing | Mental Health Nursing
                        </p>
                      </div>
                      <br />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-6">
                <div className="row">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/university+images/physiotherapy.png"
                    className="img-fluid p-0"
                    alt=""
                  />
                  <div className="col-sm-12 p-0 ">
                    <h4
                      className="text-uppercase text-light p-3 mb-0"
                      style={{ backgroundColor: "#FC7F03" }}
                    >
                      FACULTY OF PHYSIOTHERAPY
                    </h4>
                  </div>
                  <div className="col-sm-12 p-0  ">
                    <div className="bg-amber p-3">
                      <div className="d-flex justify-content-end ">
                        <p className="text-light mx-4">Duration</p>
                        {/* <p className="text-light mx-2">Fees/Year</p> */}
                      </div>
                      {/* course details */}
                      <div className="d-flex   ">
                        <div className="flex-grow-1">
                          <h4 className="text-light">
                            {" "}
                            Bachelor of Physiotherapy
                          </h4>
                        </div>
                        <div className=" ">
                          <h5 className=" bg-white rounded-pill px-2 d-flex align-items-center ">
                            {" "}
                            <i class="ri-history-fill mx-1 mt-1"></i> 4.5 Years
                          </h5>
                        </div>
                        {/* <div className="">
                          <h5 className="bg-white rounded-pill px-2 d-flex align-items-center mx-1 ">
                            {" "}
                            ₹ 77,000*
                          </h5>
                        </div> */}
                      </div>
                      {/* <div className="col-md-12">
                        <p className="text-light text-right">
                          {" "}
                          MQ - 80,000/Year*
                        </p>
                      </div> */}
                      <br />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-6">
                <div className="row">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/university+images/socialwork.png"
                    className="img-fluid p-0"
                    alt=""
                  />
                  <div className="col-sm-12 p-0 ">
                    <h4 className="text-uppercase text-light bg-amber p-3 mb-0">
                      FACULTY OF Social Work
                    </h4>
                  </div>
                  <div className="col-sm-12 p-0  ">
                    <div className=" p-3">
                      <div className="d-flex justify-content-end ">
                        <p className="mx-4">Duration</p>
                        {/* <p className="mx-2">Fees/Year</p> */}
                      </div>
                      {/* course details */}
                      <div className="d-flex   ">
                        <div className="flex-grow-1">
                          <h4 className=""> BSW (Hons.)</h4>
                        </div>
                        <div className=" ">
                          <h5 className=" bg-dark text-light rounded-pill px-2 d-flex align-items-center ">
                            {" "}
                            <i class="ri-history-fill mx-1 mt-1"></i> 4 Years
                          </h5>
                        </div>
                        {/* <div className="">
                          <h5 className="bg-dark text-light rounded-pill px-2 d-flex align-items-center mx-1 ">
                            {" "}
                            ₹ 25,000
                          </h5>
                        </div> */}
                      </div>
                      <br />
                      <div className="d-flex  ">
                        <div className="flex-grow-1">
                          <h4 className=""> MSW</h4>
                        </div>
                        <div className=" ">
                          <h5 className=" bg-dark text-light rounded-pill px-2 d-flex align-items-center ">
                            {" "}
                            <i class="ri-history-fill mx-1 mt-1"></i> 2 Year
                          </h5>
                        </div>
                        {/* <div className="">
                          <h5 className="bg-dark text-light rounded-pill px-2 d-flex align-items-center mx-1 ">
                            {" "}
                            ₹ 30,000
                          </h5>
                        </div> */}
                      </div>
                      <div className="col-md-12">
                        <p className=""> HRM</p>
                      </div>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />

          <div className="container-fluid mt-2">
            {events.length > 0 && <NewsAnnouncementEventsNew events={events} />}
          </div>

          <div className=" description container-fluid mt-4">
            <div
              className="nav nav-tabs d-flex justify-content-between"
              id="nav-tab"
              role="tablist"
            >
              <button
                className="nav-link "
                id="nav-diploma-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-diploma"
                type="button"
                role="tab"
                aria-controls="nav-diploma"
                aria-selected="true"
              >
                Diploma
              </button>

              <button
                className="nav-link active"
                id="nav-instructor-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-instructor"
                type="button"
                role="tab"
                aria-controls="nav-instructor"
                aria-selected="false"
                tabIndex={-1}
              >
                Undergraduate
              </button>
              <button
                className="nav-link"
                id="nav-postgraduate-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-postgraduate"
                type="button"
                role="tab"
                aria-controls="nav-postgraduate"
                aria-selected="false"
                tabIndex={-1}
              >
                Postgraduate
              </button>
              <button
                className="nav-link"
                id="nav-certificate-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-certificate"
                type="button"
                role="tab"
                aria-controls="nav-certificate"
                aria-selected="false"
                tabIndex={-1}
              >
                Certificate
              </button>
              <button
                className="nav-link"
                id="nav-phd-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-phd"
                type="button"
                role="tab"
                aria-controls="nav-phd"
                aria-selected="false"
                tabIndex={-1}
              >
                Ph.D.
              </button>
            </div>

            <div class="tab-content" id="nav-tabContent">
              <div
                class="tab-pane fade  "
                id="nav-diploma"
                role="tabpanel"
                aria-labelledby="nav-diploma-tab"
              >
                <div className="row justify-content-center">
                  <div
                    className="col-lg-3 col-sm-6 aos-init"
                    data-aos="fade-up"
                    data-aos-easing="ease-in-sine"
                    data-aos-duration={1300}
                    data-aos-once="true"
                  >
                    <div className="single-academics-card3">
                      <div className="icon">
                        <i className="flaticon-pc" />
                      </div>
                      <Link to={"/engineering"}>
                        <h3>Engineering</h3>
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6">
                    <div className="single-academics-card3">
                      <div className="icon">
                        <i class="ri-nurse-line"></i>
                      </div>
                      <Link to={"/nursing"}>
                        <h3>Nursing</h3>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade active show"
                id="nav-instructor"
                role="tabpanel"
                aria-labelledby="nav-instructor-tab"
              >
                <div className="row justify-content-center">
                  <div className="col-lg-3 col-sm-6">
                    <div className="single-academics-card3">
                      <div className="icon">
                        <i className="flaticon-pc" />
                      </div>
                      <Link to={"/engineering"}>
                        <h3>Engineering</h3>
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6">
                    <div className="single-academics-card3">
                      <div className="icon">
                        <i class="ri-nurse-line"></i>
                      </div>
                      <Link to={"/nursing"}>
                        <h3>Nursing</h3>
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6">
                    <div className="single-academics-card3">
                      <div className="icon">
                        <i class="ri-nurse-line"></i>
                      </div>
                      <Link to={"/science"}>
                        <h3> Science</h3>
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6">
                    <div className="single-academics-card3">
                      <div className="icon">
                        <i className="flaticon-money-exchange" />
                      </div>
                      <Link to={"/commerce"}>
                        <h3>Commerce and Management</h3>
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6">
                    <div className="single-academics-card3">
                      <div className="icon">
                        <i class="ri-stethoscope-line"></i>
                      </div>
                      <Link to={"/sims"}>
                        <h3>MBBS</h3>
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6">
                    <div className="single-academics-card3">
                      <div className="icon">
                        <i className="flaticon-heartbeat" />
                      </div>
                      <Link to={"/ayurveda"}>
                        <h3>Ayurveda</h3>
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6">
                    <div className="single-academics-card3">
                      <div className="icon">
                        <i class="ri-empathize-line"></i>
                      </div>
                      <Link to={"/Homoeopathy"}>
                        <h3>Homoeopathy</h3>
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6">
                    <div className="single-academics-card3">
                      <div className="icon">
                        <i class="ri-medicine-bottle-fill"></i>
                      </div>
                      <Link to={"/pharmacy"}>
                        <h3>Pharmacy</h3>
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6">
                    <div className="single-academics-card3">
                      <div className="icon">
                        <i className="flaticon-auction" />
                      </div>
                      <Link to={"/law"}>
                        <h3>Law </h3>
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6">
                    <div className="single-academics-card3">
                      <div className="icon">
                        <i className="flaticon-blueprint" />
                      </div>
                      <Link to={"/arts"}>
                        <h3>Arts </h3>
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6">
                    <div className="single-academics-card3">
                      <div className="icon">
                        <i className="flaticon-profile" />
                      </div>
                      <Link to={"/it-cs"}>
                        <h3>IT and CS</h3>
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6">
                    <div className="single-academics-card3">
                      <div className="icon">
                        <i className="flaticon-connection" />
                      </div>
                      <Link to={"/education"}>
                        <h3>Education </h3>
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6">
                    <div className="single-academics-card3">
                      <div className="icon">
                        <i className="flaticon-connection" />
                      </div>
                      <Link to={"/Physiotherapy"}>
                        <h3>Physiotherapy </h3>
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6">
                    <div className="single-academics-card3">
                      <div className="icon">
                        <i className="flaticon-connection" />
                      </div>
                      <Link to={"/Design"}>
                        <h3>Design and Communication</h3>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade "
                id="nav-postgraduate"
                role="tabpanel"
                aria-labelledby="nav-postgraduate-tab"
              >
                <div className="row justify-content-center">
                  <div className="col-lg-3 col-sm-6">
                    <div className="single-academics-card3">
                      <div className="icon">
                        <i className="flaticon-pc" />
                      </div>
                      <Link to={"/engineering"}>
                        <h3>Engineering</h3>
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6">
                    <div className="single-academics-card3">
                      <div className="icon">
                        <i class="ri-nurse-line"></i>
                      </div>
                      <Link to={"/nursing"}>
                        <h3>Nursing</h3>
                      </Link>
                    </div>
                  </div>

                  <div className="col-lg-3 col-sm-6">
                    <div className="single-academics-card3">
                      <div className="icon">
                        <i className="flaticon-money-exchange" />
                      </div>
                      <Link to={"/commerce"}>
                        <h3>Commerce and Management</h3>
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6">
                    <div className="single-academics-card3">
                      <div className="icon">
                        <i class="ri-stethoscope-line"></i>
                      </div>
                      <Link to={"/sims"}>
                        <h3>MBBS</h3>
                      </Link>
                    </div>
                  </div>

                  <div className="col-lg-3 col-sm-6">
                    <div className="single-academics-card3">
                      <div className="icon">
                        <i class="ri-medicine-bottle-fill"></i>
                      </div>
                      <Link to={"/pharmacy"}>
                        <h3>Pharmacy</h3>
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6">
                    <div className="single-academics-card3">
                      <div className="icon">
                        <i className="flaticon-auction" />
                      </div>
                      <Link to={"/law"}>
                        <h3>Law </h3>
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6">
                    <div className="single-academics-card3">
                      <div className="icon">
                        <i className="flaticon-blueprint" />
                      </div>
                      <Link to={"/arts"}>
                        <h3>Arts and Humanities</h3>
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6">
                    <div className="single-academics-card3">
                      <div className="icon">
                        <i className="flaticon-profile" />
                      </div>
                      <Link to={"/it-cs"}>
                        <h3>IT </h3>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade  "
                id="nav-certificate"
                role="tabpanel"
                aria-labelledby="nav-certificate-tab"
              >
                <div className="row justify-content-center">
                  <div className="col-lg-3 col-sm-6">
                    <div className="single-academics-card3">
                      <div className="icon">
                        <i class="ri-psychotherapy-line"></i>
                      </div>
                      <Link to={"/Graduate"}>
                        <h3>Panchakarma Therapist </h3>
                      </Link>

                      <Link to={"/Graduate"} className="read-more-btn">
                        Read More
                        <i className="flaticon-next" />
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6">
                    <div className="single-academics-card3">
                      <div className="icon">
                        <i class="ri-open-arm-line"></i>
                      </div>
                      <Link to={"/Graduate"}>
                        <h3>Yoga</h3>
                      </Link>

                      <Link to={"/Graduate"} className="read-more-btn">
                        Read More
                        <i className="flaticon-next" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="container-fluid">
  <div className="row">
    <div className="col-md-12 text-center">
      <img src="/assets/images/acridation.PNG" alt="" className="img-fluid" srcset="" />
    </div>
  </div>
</div> */}

          <div className="admisssion-area ptb-100 admission-bg mb-4">
            <div className="container-fluid px-4 admission-left-content">
              <h1 className="text-center text-light">
                ACCREDITATION AND RECOGNITION
              </h1>
              <div className=" d-flex  justify-content-center align-items-center  flex-wrap flex-row">
                <div className="mx-3 mt-5">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/accredation/1.png"
                    alt=""
                    width="150"
                    srcset=""
                    className="img-fluid"
                  />
                </div>
                <div className="mx-3 mt-5">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/accredation/2.png"
                    alt=""
                    width="170"
                    srcset=""
                    className="img-fluid"
                  />
                </div>
                <div className="mx-3 mt-5">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/accredation/3.png"
                    alt=""
                    width="170"
                    srcset=""
                    className="img-fluid"
                  />
                </div>

                <div className="mx-3 mt-5">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/accredation/4.png"
                    alt=""
                    width="150"
                    srcset=""
                    className="img-fluid"
                  />
                </div>

                <div className="mx-3 mt-5">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/accredation/5.png"
                    alt=""
                    width="150"
                    srcset=""
                    className="img-fluid"
                  />
                </div>

                <div className="mx-3 mt-5">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/accredation/6.png"
                    alt=""
                    width="140"
                    srcset=""
                    className="img-fluid"
                  />
                </div>
                <div className="mx-3 mt-5">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/accredation/7+(2).png"
                    alt=""
                    width="140"
                    srcset=""
                    className="img-fluid"
                  />
                </div>
                <div className="mx-3 mt-5">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/accredation/8.png"
                    alt=""
                    width="180"
                    srcset=""
                    className="img-fluid"
                  />
                </div>
                <div className="mx-3 mt-5">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/accredation/9.png"
                    alt=""
                    width="150"
                    srcset=""
                    className="img-fluid"
                  />
                </div>
                <div className="mx-3 mt-5">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/accredation/10.png"
                    alt=""
                    width="150"
                    srcset=""
                    className="img-fluid"
                  />
                </div>
                <div className="mx-3 mt-5">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/accredation/11.png"
                    alt=""
                    width="150"
                    srcset=""
                    className="img-fluid"
                  />
                </div>

                <div className="mx-3 mt-5">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/accredation/12.png"
                    alt=""
                    width="150"
                    srcset=""
                    className="img-fluid"
                  />
                </div>
                <div className="mx-3 mt-5">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/accredation/13.png"
                    alt=""
                    width="160"
                    srcset=""
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>

          <h1 className="text-center mb-5">Campus Life</h1>

          <div className="row">
            <div className="d-flex justify-content-center align-items-center flex-wrap ">
              <div
                className="text-center mx-5 aos-init aos-animate"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="200"
                data-aos-once="true"
              >
                <img
                  src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/campus.PNG"
                  className="img-fluid icons mb-3"
                  width="150"
                  alt=""
                  srcset=""
                />{" "}
                <br />
                <h3 className="mb-5">LUSH GREEN CAMPUS </h3>
              </div>
              <div
                className="text-center mx-5 aos-init aos-animate"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="200"
                data-aos-once="true"
              >
                <img
                  src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/clasroom.PNG"
                  className="img-fluid icons mb-3"
                  width="70"
                  alt=""
                  srcset=""
                />{" "}
                <br />
                <h3 className="mb-5">DIGITAL CLASSROOM </h3>
              </div>
              <div
                className="text-center mx-5 aos-init aos-animate"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="200"
                data-aos-once="true"
              >
                <img
                  src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/library+%26+labs.PNG"
                  className
                  icons="img-fluid mb-3"
                  width="100"
                  alt=""
                  srcset=""
                />{" "}
                <br />
                <h3 className="mb-5">LIBRARY & LABS </h3>
              </div>
              <div
                className="text-center mx-5 aos-init aos-animate"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="200"
                data-aos-once="true"
              >
                <img
                  src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/sports.PNG"
                  className="img-fluid icons mb-3"
                  width="100"
                  alt=""
                  srcset=""
                />{" "}
                <br />
                <h3 className="mb-5">SPORTS GROUND</h3>
              </div>
              <div
                className="text-center mx-5 aos-init aos-animate"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="200"
                data-aos-once="true"
              >
                <img
                  src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/hostel.PNG"
                  className="img-fluid icons mb-3"
                  width="100"
                  alt=""
                  srcset=""
                />{" "}
                <br />
                <h3 className="mb-5">HOSTEL FACILITY</h3>
              </div>

              <div
                className="text-center mx-5 aos-init aos-animate"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="200"
                data-aos-once="true"
              >
                <img
                  src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/bus.PNG"
                  className="img-fluid icons mb-3"
                  width="100"
                  alt=""
                  srcset=""
                />{" "}
                <br />
                <h3 className="mb-5">TRANSPORT FACILITY</h3>
              </div>
              <div
                className="text-center mx-5 aos-init aos-animate"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="200"
                data-aos-once="true"
              >
                <img
                  src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/placement.PNG"
                  className="img-fluid icons mb-3"
                  width="90"
                  alt=""
                  srcset=""
                />{" "}
                <br />
                <h3 className="mb-5">TRAINING AND PLACEMENT</h3>
              </div>
              <div
                className="text-center mx-5 aos-init aos-animate"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="200"
                data-aos-once="true"
              >
                <img
                  src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/canteen.PNG"
                  className="img-fluid icons mb-3"
                  width="140"
                  alt=""
                  srcset=""
                />{" "}
                <br />
                <h3 className="mb-5">CAFETERIA</h3>
              </div>
              <div
                className="text-center mx-5 aos-init aos-animate"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="200"
                data-aos-once="true"
              >
                <img
                  src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/wifi.PNG"
                  className="img-fluid icons mb-4"
                  width="120"
                  alt=""
                  srcset=""
                />{" "}
                <br />
                <h3 className="mb-5">WIFI CAMPUS</h3>
              </div>
              <div
                className="text-center mx-5 aos-init aos-animate"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="200"
                data-aos-once="true"
              >
                <img
                  src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/gym.PNG"
                  className="img-fluid icons mb-3"
                  width="80"
                  alt=""
                  srcset=""
                />{" "}
                <br />
                <h3 className="mb-5">GYM</h3>
              </div>
            </div>
          </div>
          {/* <div className="admisssion-area ptb-100 admission-bg">
  <div className="container">
    <div className="row align-items-center">
   
      
         <div className="d-flex justify-content-center">
        <div className="">
          
        
     
        </div>
      </div>
    
    </div>
  </div>
</div> */}

          <div className="admisssion-area bg-f4f6f9 ptb-100">
            <div className="container-fluid">
              <div className="admission-content">
                <div className="section-title">
                  <h2>
                    Swaminarayan University <br />
                    {/* Admissions Open */}
                  </h2>
                  {/* <p>Lorem ipsum dolor sit amet consectetur adipiscing elit ut elit tellus luctus nec ullamcorper mattis </p> */}
                </div>
                {/* <div class="admission-image">
              <img src="assets/images/logo.png" alt="Image" />
              <div class="icon">
              <a class="popup-youtube play-btn" href="https://www.youtube.com/watch?v=CerAEXGBhpc"><i class="ri-play-fill"></i></a>
              </div>
             </div> */}
                <div className="container-fluid">
                  <div className="row d-flex justify-content-center">
                    <div className="col-md-6">
                      <iframe
                        width="100%"
                        height="350"
                        src="https://www.youtube.com/embed/zZLP0rhheFM?si=B4a-wcM5nlXGDMvj"
                        title="Admission Open"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen=""
                      ></iframe>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <iframe
                        className="demo cursor"
                        width="100%"
                        height={350}
                        src="https://www.youtube.com/embed/BpKXPRQ0YXc"
                        title="Prof. (Dr.) M. S. Rao | Vice-Chancellor (Provost) | Swaminarayan University"
                        frameBorder={0}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen=""
                      />
                    </div>
                    <div className="col-md-6">
                      <iframe
                        className="demo cursor"
                        width="100%"
                        height={350}
                        src="https://youtube.com/embed/tpzwUTU9K0Y"
                        title="YouTube video player"
                        frameBorder={0}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen=""
                      />
                    </div>{" "}
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <iframe
                        className="demo cursor"
                        width="100%"
                        height={350}
                        src="https://www.youtube.com/embed/CerAEXGBhpc"
                        title="YouTube video player"
                        frameBorder={0}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen=""
                      />
                    </div>
                    <div className="col-md-6">
                      <iframe
                        className="demo cursor"
                        width="100%"
                        height={350}
                        src="https://youtube.com/embed/E0F87B_84G8"
                        title="YouTube video player"
                        frameBorder={0}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen=""
                      />
                    </div>
                  </div>
                </div>
                <div className="query text-center mt-5">
                  <p>
                    If you've got any Query or facing any Problem Please Contact
                    Us Via Email
                  </p>
                  <Link to={"AdmissionEnquiry"} className="default-btn btn">
                    More on admission
                    <i className="flaticon-next" />
                  </Link>
                </div>
              </div>
            </div>
          </div>

          {/* <div
          className="modal fade"
          id="exampleModal"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          style={{ display: "none" }}
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h2
                  className="modal-title blink_me  text-danger"
                  id="exampleModalLabel"
                >
                  Admission Open
                </h2>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <div className="row">                  
                  <div className="col-md-6">
                    <img
                      className="img-fluid"
                      src="https://s3.ap-south-1.amazonaws.com/verification.nexenstial.com/university-assets/admission+assets/admission+poster+diploma+2.PNG"
                      alt="admission Banner"
                    />
                  </div>
                  
                 
                </div>
              </div>
            </div>
          </div>
        </div> */}
        </>
        <Helmet>
          <link rel="stylesheet" href="/assets/css/counter.css" />

          <script></script>
          <script
            data-cfasync="false"
            src="/cdn-cgi/scripts/5c5dd728/cloudflare-static/email-decode.min.js"
          ></script>
          <script src="/assets/js/jquery.min.js"></script>

          <script src="/assets/js/bootstrap.bundle.min.js"></script>

          <script src="/assets/js/jquery.meanmenu.js"></script>

          <script src="/assets/js/owl.carousel.min.js"></script>

          <script src="/assets/js/carousel-thumbs.min.js"></script>

          <script src="/assets/js/jquery.magnific-popup.js"></script>

          <script src="/assets/js/aos.js"></script>

          <script src="/assets/js/odometer.min.js"></script>

          <script src="/assets/js/appear.min.js"></script>

          <script src="/assets/js/form-validator.min.js"></script>

          <script src="/assets/js/contact-form-script.js"></script>

          <script src="/assets/js/ajaxchimp.min.js"></script>

          <script src="/assets/js/custom.js"></script>
        </Helmet>

        {PopUpData && PopUpData?.length > 0 && (
          <Helmet>
            <script type="text/javascript">
              $('#exampleModal').modal('show');
            </script>
          </Helmet>
        )}
      </div>
    </div>
  );
}

export default Home;
