import React from 'react';

function Status() {
  return (
    <div>
      <>
      <div className="campus-life-area pt-100 pb-70">
        <div className="container">
          <h3 className="text-center mb-4 ">Status</h3>
          <p className="text-center">
            “Achieving recognition for 150 MBBS seats signifies an important milestone for a medical institution, 
            indicating its commitment to providing high-quality medical education and producing competent 
            healthcare professionals. It reflects positively on the institution's reputation and contributes 
            to the overall advancement of medical education and healthcare delivery.”
          </p>
          <object
            height={800}
            data="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/media/undefined_1708919374578.pdf"
            type="application/pdf"
            width="100%"
          >        
          </object>
        </div>
      <div>

    </div>
  </div>
      </>
    </div>
  )
}

export default Status;

