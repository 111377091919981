import React from "react";

function Courses() {
  return (
    <div>
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-md-12">
            <p>
              <span style={{ fontSize: "18pt" }}>
                <strong>Courses</strong>
              </span>
              <br />
            </p>
            <p>
              The following are the courses conducted by Shree Swaminarayan
              Science College for the Academic Year 2022-2023 and 2023-2024
            </p>

            <p>
              <strong>All Courses Details:</strong>
              <br />
            </p>
            <table
              className="table table-bordered"
              style={{ borderCollapse: "collapse", width: "100%", height: 173 }}
              border={1}
            >
              <tbody>
                <tr style={{ height: 20 }}>
                  <td
                    style={{
                      width: "5.17544%",
                      height: 20,
                      textAlign: "center",
                    }}
                  >
                    <strong>Sr. No.</strong>
                    <br />
                  </td>
                  <td style={{ width: "10.4386%", height: 20 }}>
                  <strong>Courses</strong>
                    <br />
                  </td>
                  <td style={{ width: "10.1755%", height: 20 }}>
                  <strong>Duration</strong>
                    <br />
                  </td>
                  <td colSpan="7" style={{ width: "74.2105%", height: 20 }}>
                    <strong>Subjects</strong>
                  </td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td
                    style={{
                      width: "5.17544%",
                      height: 20,
                      textAlign: "center",
                    }}
                  >
                    1<br />
                  </td>
                  <td style={{ width: "10.4386%", height: 20 }}>
                    B.Sc
                    <br />
                  </td>
                  <td style={{ width: "10.1755%", height: 20 }}>4 Years</td>
                  <td>Chemistry</td>
                  <td>Physics</td>
                  <td>Maths</td>
                  <td>Botany</td>
                  <td>Zoology</td>
                  <td colSpan={2}>Microbiology</td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td
                    style={{
                      width: "5.17544%",
                      height: 20,
                      textAlign: "center",
                    }}
                  >
                    2<br />
                  </td>
                  <td style={{ width: "10.4386%", height: 20 }}>
                    M.Sc
                    <br />
                  </td>
                  <td style={{ width: "10.1755%", height: 20 }}>2 Years</td>
                  <td>Chemistry</td>
                  <td>Botany</td>
                  <td>Maths</td>
                  <td>Physics</td>
                  <td>Microbiology</td>
                  <td>Biotechnology</td>
                  <td>Zoology</td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td
                    style={{
                      width: "5.17544%",
                      height: 20,
                      textAlign: "center",
                    }}
                  >
                    3<br />
                  </td>
                  <td style={{ width: "10.4386%", height: 20 }}>
                    PhD
                    <br />
                  </td>
                  <td style={{ width: "10.1755%", height: 20 }}>3 Years</td>
                  <td>Chemistry</td>
                  <td>Botany</td>
                  <td>Maths</td>
                  <td>Physics</td>
                  <td>Microbiology</td>
                  <td>Biotechnology</td>
                  <td>Zoology</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Courses;
