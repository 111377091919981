import React from "react";
import { Link } from "react-router-dom";

// Image Urls
//Classroom
const classroom1 = "../assets/science/Infrastructure/classroom-1.jpg";
const classroom2 = "../assets/science/Infrastructure/classroom-2.jpg";
const classroom3 = "../assets/science/Infrastructure/classroom-3.jpg";
const classroom4 = "../assets/science/Infrastructure/classroom-4.jpg";

//Analytic Lab
const AnalyticLab1 = "/assets/science/Infrastructure/analytic-lab-1.jpg";
const AnalyticLab2 = "/assets/science/Infrastructure/analytic-lab-2.jpg";

//Biology Lab
const BiologyLab1 = "/assets/science/Infrastructure/biology-lab-1.jpg";

//Botany Lab
const BotanyLab1 = "/assets/science/Infrastructure/botany-lab-1.jpg";

//Chemistry Lab
const ChemistryLab1 = "/assets/science/Infrastructure/chemistry-lab-1.jpg";
const ChemistryLab2 = "/assets/science/Infrastructure/chemistry-lab-2.jpg";
const ChemistryLab3 = "/assets/science/Infrastructure/chemistry-lab-3.jpg";

//Microbilogy Lab
const MicrobiologyLab1 =
  "/assets/science/Infrastructure/microbiology-lab-1.jpg";

//Physics Lab
const PhysicsLab1 = "/assets/science/Infrastructure/physics-lab-1.jpg";
const PhysicsLab2 = "/assets/science/Infrastructure/physics-lab-2.jpg";

//Zoology Lab
const ZoologyLab1 = "/assets/science/Infrastructure/chemistry-lab-1.jpg";

function Infrastructure() {
  return (
    <div className="ResidentialFacilities">
      <>
        <div className="campus-life-area pt-100 pb-70">
          <div className="container">
            <h3 className="text-center mb-4 ">Infrastructure Details</h3>
            <p className="text-center">
              Our college is well equipped with the state-of-the-art facilities
              for classrooms and laboratories in all departments.
            </p>
          </div>
        </div>

        <div className="row justify-content-center mt-5">
          <h3 className="text-center mb-4 ">Classrooms</h3>
          <div className="col-lg-4 col-md-6">
            <div className="single-campus-card">
              <div className="img">
                <a href="">
                  <img src={classroom1} alt="Image" />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single-campus-card">
              <div className="img">
                <a href="">
                  <img src={classroom2} alt="Image" />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single-campus-card">
              <div className="img">
                <a href="">
                  <img src={classroom3} alt="Image" />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single-campus-card">
              <div className="img">
                <a href="">
                  <img src={classroom4} alt="Image" />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center mt-5">
          <h3 className="text-center mb-4 ">Analytic Lab</h3>
          <div className="col-lg-4 col-md-6">
            <div className="single-campus-card">
              <div className="img">
                <a href="">
                  <img src={AnalyticLab1} alt="Image" />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single-campus-card">
              <div className="img">
                <a href="">
                  <img src={AnalyticLab2} alt="Image" />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center mt-5">
          <h3 className="text-center mb-4 ">Biology Lab</h3>
          <div className="col-lg-4 col-md-6">
            <div className="single-campus-card">
              <div className="img">
                <a href="">
                  <img src={BiologyLab1} alt="Image" />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center mt-5">
          <h3 className="text-center mb-4 ">Botany Lab</h3>
          <div className="col-lg-4 col-md-6">
            <div className="single-campus-card">
              <div className="img">
                <a href="">
                  <img src={BotanyLab1} alt="Image" />
                </a>
              </div>
            </div>
          </div>
        </div>
        
        <div className="row justify-content-center mt-5">
          <h3 className="text-center mb-4 ">Chemistry Lab</h3>
          <div className="col-lg-4 col-md-6">
            <div className="single-campus-card">
              <div className="img">
                <a href="">
                  <img src={ChemistryLab1} alt="Image" />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single-campus-card">
              <div className="img">
                <a href="">
                  <img src={ChemistryLab2} alt="Image" />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single-campus-card">
              <div className="img">
                <a href="">
                  <img src={ChemistryLab3} alt="Image" />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center mt-5">
          <h3 className="text-center mb-4 ">Microbiology Lab</h3>
          <div className="col-lg-4 col-md-6">
            <div className="single-campus-card">
              <div className="img">
                <a href="">
                  <img src={MicrobiologyLab1} alt="Image" />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center mt-5">
          <h3 className="text-center mb-4 ">Physics Lab</h3>
          <div className="col-lg-4 col-md-6">
            <div className="single-campus-card">
              <div className="img">
                <a href="">
                  <img src={PhysicsLab1} alt="Image" />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single-campus-card">
              <div className="img">
                <a href="">
                  <img src={PhysicsLab2} alt="Image" />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center mt-5">
          <h3 className="text-center mb-4 ">Zoology Lab</h3>
          <div className="col-lg-4 col-md-6">
            <div className="single-campus-card">
              <div className="img">
                <a href="">
                  <img src={ZoologyLab1} alt="Image" />
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="register" style={{maxWidth:'1320px'}}>
 <div className="container-fluid">
 <div className="row justify-content-center mt-2  p-0">
    <div className="col-lg-6 col-md-6">
        <h3>Girls Hostel</h3>
     <table className="table table-bordered text-center">
        <thead className='bg-danger text-light p-3'>
            <tr>
                <th>Students</th>
                <th>Facility</th>
                <th>Fees/Annum</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>3 Students</td>
                <td rowSpan="3">Non AC Room, <br/> Attached Bathroom</td>
                <td>₹ 85,000/-</td>
            </tr>
            <tr>
                <td>4 Students</td>
               
                <td>₹ 80,000/-</td>
            </tr>
            <tr>
                <td>8 Students</td>
               
                <td>₹ 70,000/-</td>
            </tr>
        </tbody>
     </table>
    </div>
    <div className="col-lg-6 col-md-6">
        <h3>Boys Hostel</h3>
     <table className="table table-bordered text-center">
        <thead className='bg-danger text-light'>
            <tr>
                <th>Students</th>
                <th>Facility</th>
                <th>Fees/Annum</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>5 Students</td>
                <td rowSpan="3">Non AC Room, <br/> Attached Bathroom</td>
                <td>₹ 70,000/-</td>
            </tr>
            
        </tbody>
     </table>
    </div>

    <div className="col-md-12 mt-3 mb-5">
        <h4 className='text-center'>Transportation Facility From Ahmedabad & Gandhinagar <Link className='text-danger' to="/Transportation">Click here</Link> Check the Rout</h4>
    </div>
  </div>
 </div>
  </div> */}
      </>
    </div>
  );
}

export default Infrastructure;
