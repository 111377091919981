import React from 'react'

function InfrastructureDesign() {
  // Image Urls
//Classroom
const classroom1 = "../assets/science/Infrastructure/classroom-1.jpg";
const classroom2 = "../assets/science/Infrastructure/classroom-2.jpg";
const classroom3 = "../assets/science/Infrastructure/classroom-3.jpg";
const classroom4 = "../assets/science/Infrastructure/classroom-4.jpg";

  return (
    <div className="Infrastructure-Physiotherapy">

      <>
        <div className="campus-life-area pt-100 pb-70">
          <div className="container">
            <h1 className="text-center mb-4 ">Infrastructure Details</h1>
            <p className="text-center">
              Our college is well equipped with the state-of-the-art facilities
              for classrooms and laboratories in all departments.
            </p>
          </div>
        </div>

        <div className="row justify-content-center mt-3">
          <h3 className="text-center mb-4 ">CLASSROOMS</h3>
          <div className="col-lg-4 col-md-6">
            <div className="single-card">
              <div className="img">
                <a href="">
                  <img src={classroom1} alt="Image" height={400}/>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single-card">
              <div className="img">
                <a href="">
                  <img src={classroom2} alt="Image" height={400}/>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single-card">
              <div className="img">
                <a href="">
                  <img src={classroom3} alt="Image" />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mt-2">
           <br/>
            <div className="single-card">
              <div className="img">
                <a href="">
                  <img src={classroom4} alt="Image" height={380}/>
                </a>
              </div>
            </div>
          </div>
        </div>
        <br/>
        <br/>
        
      </>
      
    </div>
  )
}

export default InfrastructureDesign;
