import React from "react";

function Eligibility() {
  return (
    <div className="description">
      <div className="container   mt-5 mb-5">
        <div className="row register" style={{ maxWidth: "90%" }}>
          <div className="col-md-12">
            <h3>
              
               Eligibility Details
          
            </h3>
            <p>
              The following are the course eligibility conducted by Shree
              Swaminarayan Science College
            </p>

            <p>
              <strong>All Courses Eligibility Details:</strong>
              <br />
            </p>
            <table
              className="table table-bordered"
              style={{ borderCollapse: "collapse", width: "100%", height: 173 }}
              border={1}
            >
              <tbody>
                <tr style={{ height: 20 }}>
                  <td
                    style={{
                      width: "5.17544%",
                      height: 20,
                      textAlign: "center",
                    }}
                  >
                    <strong>S.No</strong>
                    <br />
                  </td>
                  <td style={{ width: "10.4386%", height: 20 }}>
                    <strong>Course</strong>
                    <br />
                  </td>
                  <td style={{ width: "10.1755%", height: 20 }}>
                    <strong>Subjects</strong>
                    <br />
                  </td>
                  <td style={{ width: "74.2105%", height: 20 }}>
                    <strong>Eligibility</strong>
                  </td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td
                    style={{
                      width: "5.17544%",
                      height: 20,
                      textAlign: "center",
                    }}
                    rowSpan={6}
                  >
                    1<br />
                  </td>
                  <td rowSpan={6} style={{ width: "10.4386%", height: 20 }}>
                    B.Sc
                    <br />
                  </td>
                  <td style={{ width: "10.1755%", height: 20 }}>Chemistry</td>
                  <td>
                    Cleared 10+2 with Science subject having Group A or Group B
                  </td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td
                    style={{
                      width: "5.17544%",
                      height: 20,
                      textAlign: "left",
                    }}
                  >
                    Physics
                    <br />
                  </td>

                  <td style={{ width: "10.1755%", height: 20 }}>
                    Cleared 10+2 with Science subject having Group A or Group B
                  </td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td
                    style={{
                      width: "5.17544%",
                      height: 20,
                      textAlign: "left",
                    }}
                  >
                    Maths
                    <br />
                  </td>

                  <td style={{ width: "10.1755%", height: 20 }}>
                    Cleared 10+2 with Science subject having Group A
                  </td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td
                    style={{
                      width: "5.17544%",
                      height: 20,
                      textAlign: "left",
                    }}
                  >
                    Botany
                    <br />
                  </td>

                  <td style={{ width: "10.1755%", height: 20 }}>
                    Cleared 10+2 with Science subject having Group B
                  </td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td
                    style={{
                      width: "5.17544%",
                      height: 20,
                      textAlign: "left",
                    }}
                  >
                    Zoology
                    <br />
                  </td>

                  <td style={{ width: "10.1755%", height: 20 }}>
                    Cleared 10+2 with Science subject having Group B
                  </td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td
                    style={{
                      width: "5.17544%",
                      height: 20,
                      textAlign: "left",
                    }}
                  >
                    Microbiology
                    <br />
                  </td>

                  <td style={{ width: "10.1755%", height: 20 }}>
                    Cleared 10+2 with Science subject having Group B
                  </td>
                </tr>

                <tr style={{ height: 20 }}>
                  <td
                    style={{
                      width: "5.17544%",
                      height: 20,
                      textAlign: "center",
                    }}
                    rowSpan={7}
                  >
                    2<br />
                  </td>
                  <td rowSpan={7} style={{ width: "10.4386%", height: 20 }}>
                    M.Sc
                    <br />
                  </td>
                  <td style={{ width: "10.1755%", height: 20 }}>Chemistry</td>
                  <td>
                    A pass in BSc Chemistry or equivalent degree from a
                    recognized University
                  </td>
                </tr>

                <tr style={{ height: 20 }}>
                  <td
                    style={{
                      width: "5.17544%",
                      height: 20,
                      textAlign: "left",
                    }}
                  >
                    Botany
                    <br />
                  </td>

                  <td style={{ width: "10.1755%", height: 20 }}>
                    A pass in BSc Botany or equivalent degree from a recognized
                    University
                  </td>
                </tr>

                <tr style={{ height: 20 }}>
                  <td
                    style={{
                      width: "5.17544%",
                      height: 20,
                      textAlign: "left",
                    }}
                  >
                    Maths
                    <br />
                  </td>

                  <td style={{ width: "10.1755%", height: 20 }}>
                    A pass in BSc Mathematics or equivalent degree from a
                    recognized University
                  </td>
                </tr>

                <tr style={{ height: 20 }}>
                  <td
                    style={{
                      width: "5.17544%",
                      height: 20,
                      textAlign: "left",
                    }}
                  >
                    Physics
                    <br />
                  </td>

                  <td style={{ width: "10.1755%", height: 20 }}>
                    A pass in BSc Physics or equivalent degree from a recognized
                    University
                  </td>
                </tr>

                <tr style={{ height: 20 }}>
                  <td
                    style={{
                      width: "5.17544%",
                      height: 20,
                      textAlign: "left",
                    }}
                  >
                    Microbiology
                    <br />
                  </td>

                  <td style={{ width: "10.1755%", height: 20 }}>
                    A pass in BSc Microbiology or equivalent degree from a
                    recognized University
                  </td>
                </tr>

                <tr style={{ height: 20 }}>
                  <td
                    style={{
                      width: "5.17544%",
                      height: 20,
                      textAlign: "left",
                    }}
                  >
                    Biotechnology
                    <br />
                  </td>

                  <td style={{ width: "10.1755%", height: 20 }}>
                    A pass in BSc Biotechnology or equivalent degree from a
                    recognized University
                  </td>
                </tr>

                <tr style={{ height: 20 }}>
                  <td
                    style={{
                      width: "5.17544%",
                      height: 20,
                      textAlign: "left",
                    }}
                  >
                    Zoology
                    <br />
                  </td>

                  <td style={{ width: "10.1755%", height: 20 }}>
                    A pass in BSc Zoology or equivalent degree from a recognized
                    University
                  </td>
                </tr>

                <tr style={{ height: 20 }}>
                  <td
                    style={{
                      width: "5.17544%",
                      height: 20,
                      textAlign: "center",
                    }}
                    rowSpan={7}
                  >
                    3<br />
                  </td>
                  <td rowSpan={7} style={{ width: "10.4386%", height: 20 }}>
                    PhD
                    <br />
                  </td>
                  <td style={{ width: "10.1755%", height: 20 }}>Chemistry</td>
                  <td rowSpan={7}>
                    Post-graduation degree/ master’s degree in any one of the
                    allied subjects pertaining to the department awarded by
                    college/university recognized by UGC with a minimum of 55%
                  </td>
                </tr>

                <tr style={{ height: 20 }}>
                  <td
                    style={{
                      width: "5.17544%",
                      height: 20,
                      textAlign: "left",
                    }}
                  >
                    Botany
                    <br />
                  </td>
                </tr>

                <tr style={{ height: 20 }}>
                  <td
                    style={{
                      width: "5.17544%",
                      height: 20,
                      textAlign: "left",
                    }}
                  >
                    Maths
                    <br />
                  </td>
                </tr>

                <tr style={{ height: 20 }}>
                  <td
                    style={{
                      width: "5.17544%",
                      height: 20,
                      textAlign: "left",
                    }}
                  >
                    Physics
                    <br />
                  </td>
                </tr>

                <tr style={{ height: 20 }}>
                  <td
                    style={{
                      width: "5.17544%",
                      height: 20,
                      textAlign: "left",
                    }}
                  >
                    Microbiology
                    <br />
                  </td>
                </tr>

                <tr style={{ height: 20 }}>
                  <td
                    style={{
                      width: "5.17544%",
                      height: 20,
                      textAlign: "left",
                    }}
                  >
                    Biotechnology
                    <br />
                  </td>
                </tr>

                <tr style={{ height: 20 }}>
                  <td
                    style={{
                      width: "5.17544%",
                      height: 20,
                      textAlign: "left",
                    }}
                  >
                    Zoology
                    <br />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Eligibility;
