import React from "react";

export default function Eligibility() {
  return (
    <div>
      <div className="container mt-5 mb-5">
        <div className="row register" style={{ maxWidth: "100%" }}>
          <div className="col-md-12">
            <p>
              <span style={{ fontSize: "18pt" }}>
                <h3>Eligibility</h3>
              </span>
              <br />
            </p>

            <p>
              <strong>Eligibility Criteria for BAMS:</strong>
              <br />
              <br />
              Applicant should have passed Higher Secondary (10+2) or an
              equivalent examination with Physics, Chemistry, Biology(Botany &
              Zoology) both theory & Practical with an aggregate mark of 50%.
              <br />
              <br />
              Applicant should fulfil the NEET eligibility criteria in current
              academic year 2022-23.
              <br />
              <br />
              <strong>NEET UG 2022 Cutoff Percentile For BAMS</strong>
            </p>

            <table
              className="table table-bordered"
              style={{ borderCollapse: "collapse", width: "100%", height: 173 }}
              border={1}
            >
              <tbody>
                <tr style={{ height: 20 }}>
                  <td>
                    <strong>Category</strong>
                  </td>
                  <td>
                    <strong>NEET Cutoff Percentile</strong>
                  </td>
                  <td>
                    <strong>NEET 2021 Cutoff Scores</strong>
                  </td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td>General</td>
                  <td>50th</td>
                  <td>715-117</td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td>General-PH</td>
                  <td>45th</td>
                  <td>116-105</td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td>SC/ST/OBC</td>
                  <td>40th</td>
                  <td>116-93</td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td>SC/ST/OBC-PH</td>
                  <td>40th</td>
                  <td>104-93</td>
                </tr>
              </tbody>
            </table>

            <p>
              Applicant should have Completed 17 Years of age at the time of
              Admission and not more than 25 Years.
              <br />
              <br />
              For NRI Student, any other equivalent qualification approved by
              university will be allowed
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
