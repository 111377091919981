import React, { useState, useRef, useEffect } from 'react'
import axios from 'axios';
import { ADD_SLIDERS } from '../../../Utils/InfoUploadingConst';

function AboutSwaminarayanUniversity() {

  const [info, setInfo] = useState([]);

  const getData = async () => {
    const config = {
        method: "get",
        url: ADD_SLIDERS,
        headers: {
            "Content-Type": "application/json",
        },
    };

    await axios(config)
        .then((res) => {
            const activeData = res.data.data.filter(element => element.status === "ACTIVE");
            console.log(activeData);
            setInfo(activeData);
        })
        .catch((err) => {
            console.log(err);
        });

};

useEffect(() => {
    getData();
}, []);

  return (
    <div className='AboutSwaminarayanUniversity'>
      {/* <div> */}
      <div className="banner-area">
  <div id="carouselExampleCaptions" className="carousel slide">
    {/* <div className="carousel-indicators">
      {info.map((item, index) => (
        <button
          key={index}
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide-to={index}
          className={index === 0 ? "active" : ""}
          aria-label={`Slide ${index + 1}`}
        />
      ))}
    </div> */}
    <div className="carousel-inner">
      {info
      .filter((item) => item.type == "About")
      ?.map((item, index) => (
        <div key={index} className={`carousel-item ${index === 0 ? "active" : ""}`}>
          <img src={item.image.replace(/"/g, '')} className="d-block w-100" alt="..."  style={{ width: "500px", height: "600px" }}/>
          {/* <div className="carousel-caption d-none d-md-block">
            <h1 className="text-light">{item.title}</h1>
          </div> */}
        </div>
      ))}
    </div>
    <button
      className="carousel-control-prev"
      type="button"
      data-bs-target="#carouselExampleCaptions"
      data-bs-slide="prev"
    >
      <span className="carousel-control-prev-icon" aria-hidden="true" />
      <span className="visually-hidden">Previous</span>
    </button>
    <button
      className="carousel-control-next"
      type="button"
      data-bs-target="#carouselExampleCaptions"
      data-bs-slide="next"
    >
      <span className="carousel-control-next-icon" aria-hidden="true" />
      <span className="visually-hidden">Next</span>
    </button>
  </div>
      </div>
      {/* </div> */}

      <div className="graduate-admission pt-100 pb-70">
  <div className="container">
    <div className="row">
      <div className="col-lg-12">
        <div className="row">
          <div className="col-lg-6">
            <div className="how-to-apply">
              <div className="submit">
                <h3>Genesis</h3>
                <p>
                  Government of Gujarat announced the establishment of Swaminarayan
                  University under the Gujarat Private Universities Act No.7 of
                  2022(further to amend the Gujarat Private Univerities Act, 2009)
                  which received the assent of the Governor, in the Gujarat
                  Government Gazetter”, on the 7th April, 2022 for the establishment
                  of Swaminarayan University at Kalol amongst others
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="how-to-apply">
              <div className="financial">
                <h3>Establishment</h3>
                <p>
                  The Swaminarayan University at Kalol is established and enacted by
                  the Gujarat Government Gazette (Extraordinary) Notification No.
                  GH/SH/15/EPU/2022/2803/KH-1 dated 1st June 2022 under sub section
                  (2) of section 1 of the Gujarat Private Universities (Amendment)
                  Act, 2022 (Guj.7 of 2022). The University is sponsored by Shree
                  Swaminarayan Vishvamangal Gurukul Trust, Kalol and regulated by
                  the University Grants Commission (UGC). The University became
                  functional with the assumption of charge by the first Vice
                  Chancellor on 20th May, 2022.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    </div>
  )
}

export default AboutSwaminarayanUniversity