import React from 'react'

function Reassesment() {

    const doc1="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/examination/Appli.+form+for+Reassessment(Theory).pdf"
  return (
    <div className='Infrastructure'>
    <div className="academics-details-area pt-90 pb-70">
<div className="container">
<div className="row">
  <div className="col-lg-12">
    <div className="academics-details">
      <div className="details">
       <div className='text-center'> <img src="../assets/images/logo.png" width="500" alt="" /></div>
        <hr />
        <div className="row">
            <div className="col-md-12">
               
             
                <h3 className="text-center">Guideline for Reassessment</h3><br/>
<ol>
    <li>For reassessment students must apply within 5 working days after the declaration of the results.</li><br/>
    <li>
    If the Student who has applied for reassessment is Pass in Main assessment then “During first
reassessment if difference is less than <b>10%</b> of max. Marks of subject (i.e. if total marks are 70 marks, then
less than <b>07</b> marks of difference and if the paper is of 80 marks then less than <b>08</b> marks of difference) then
no second reassessment will be done and no change of marks will be declared. But if the differenceis <b></b>, second reassessment will be done and the result will be the average of marks
obtained in Reassessment and Second Reassessment. <br/>
If the Student who has applied for reassessment is Fail in Main assessment then “During first reassessment
if the Student is Pass or if the difference is <b>more than or equal to (≥) 10%</b>,secondreassessment will be done
and the result will be the average of marks obtained in Reassessment and Second Reassessment. During
first reassessment if the Student is Fail and if the difference is <b> less than 10%</b> of max. Marks of subject, then
no second reassessment will be done. But if during first reassessment if the Student is Fail and if the
difference is <b>more than or equal to 10% </b> of max. Marks of subject, then second reassessment will be done
and the result will be the average of marks obtained in Reassessment and Second Reassessment.”
However, Result shall not be revised if it is adverse.
Provided that fraction, if any, in calculation shall be rounded off to next higher integer.”
    </li>
     <br/>
     <li>Reassessment is permitted only in University Theory Exam.</li> <br/>
     <li>Reassessment is not allowed for Internal, Viva, practical, Mid-Sem Exam and Term Work component of
the subject.</li> <br/>
<li>Reassessment for 2 subjects is allowed irrespective of the number of the backlogs of the student.</li> <br/>
<li>The result of the student revised on account of reassessment shall not entitle the student for
medal/prize.</li><br/>
<li>Re-Assessment and Rechecking is permitted in all semesters of all courses.</li> <br/>
<li>A student can apply for rechecking &reassessment of their own answer books only.</li><br/>
<li>For reassessment, no fee shall be refunded irrespective of whether the marks are changed or not.</li> <br/>
<li>For rechecking, in case there is a change of marks, the rechecking fee shall be refunded.</li><br/>
</ol>

            </div>
            <div className="col-md-12 text-right mr-5">
            -Sd- <br/>
Registrar
            </div>
            <br/>
            <div className="col-md-12 text-center">
                <a href={doc1} className="btn btn-success" download target="_blank">Download Reassessment Form</a>
            </div>
        </div>
      
      </div>
      <br />
      <br />
     
    </div>
  </div>
</div>
</div>
</div>

</div>
  )
}

export default Reassesment