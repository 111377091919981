import React from "react";

function Mission_VisionEng() {
  return (
    <div>
      <>
        {/* <div className="container mt-5 mb-5">
      <div className="row">
        <div className="col-md-3">
        
            <img
              src="../assets/images/academics/vicepresident-1.jpg"
              alt=""
              className="img-responsive img-thumbnail"
              width={261}
              height={261}
            />
            <br />
            <br />

       
          <p style={{  }}>
            <strong>
            Swami Bhaktvatsal Das Ji
              <br />
            </strong>
           Managing Trustee
          </p>
        </div>
        <div className="col-md-9">
          <h2>
          Vision and Mission
            <br />
          </h2>
          <p style={{ textAlign: "justify" }}>
            <h5 className='mt-5'>Our Vision</h5>
            To provide exceptional education, foster scientific inquiry, and promote innovation in the field of science, preparing students to become knowledgeable and responsible global citizens who contribute to the advancement of society.
          </p>
          <p style={{ textAlign: "justify" }}>
            <h5 className='mt-5'>Our Mission</h5>
            Our Science College aims to be a leading institution of scientific education and research, recognized for its commitment to academic excellence, cutting-edge discoveries, and the development of critical thinking and problem-solving skills. We strive to cultivate a culture of scientific curiosity, collaboration, and ethical conduct, empowering our students to make meaningful contributions to scientific knowledge and address the pressing challenges of our time.
          </p>
        </div>
      </div>
      <br />
      <br />
      </div>{" "} */}
        <div className="MissionVision">
          <div className="health-care-area ptb-100 bg-f4f6f9">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-6 col-md-6">
                  <div className="single-health-care-card">
                    <div className="card"></div>
                    <div className="health-care-content">
                      <a href="#">
                        <h3>Mission</h3>
                      </a>
                      <p>
                        <i
                          className="ri-checkbox-circle-line"
                          style={{ color: "#e32845" }}
                        />{" "}
                        Our Engineering College aims to be a leading institution
                        of engineering education and research, recognized for
                        its commitment to academic excellence, innovative
                        solutions, and the development of critical thinking and
                        problem-solving skills. We strive to cultivate a culture
                        of technological curiosity, collaboration, and ethical
                        conduct, empowering our students to make meaningful
                        contributions to engineering advancements and address
                        the pressing challenges of our time.
                        <br />
                        <br />
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="single-health-care-card">
                    <div className="img">
                      <a href="#">
                        <img
                          src="../assets/images/academics/mission.jpg"
                          alt="Image"
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-lg-6 col-md-6">
                    <div className="single-health-care-card">
                      <div className="card">
                        <a href="#">
                          <img
                            src="../assets/images/academics/vision.jpg"
                            alt="Image"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="single-health-care-card">
                      <div className="card"></div>
                      <div className="health-care-content">
                        <a href="#">
                          <h3>Vision</h3>
                        </a>
                        <p>
                          <i
                            className="ri-checkbox-circle-line"
                            style={{ color: "#e32845" }}
                          />{" "}
                          To provide exceptional education, and promote
                          innovation in the field of Engineering, preparing
                          students to become knowledgeable and responsible
                          global citizens who contribute to the advancement of
                          society.
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

export default Mission_VisionEng;
