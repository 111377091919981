import React from 'react'
import axios from 'axios';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { RESEARCH_PUBLICATION_GET } from '../../Utils/InfoUploadingConst';

function EducationPublications() {

  const [data, setData] = useState([]);

  const navigate = useNavigate();

  const getData = async () => {
      const config = {
          method: "get",
          url: RESEARCH_PUBLICATION_GET,
          headers: {
              "Content-Type": "application/json",
          },
      };

      await axios(config)
          .then((res) => {
              res.data.data.sort((a, b) => b.id - a.id);
              res.data.data.forEach((element) => {
                  element.attachments = JSON.parse(element.attachments);
              });
              console.log(res.data.data)
              setData(res.data.data);

          })
          .catch((err) => {
              console.log(err);
          });
  };

  useEffect(() => {
      getData();
  }, []);

  const next = async (id, data1, images) => {
      console.log("error here");
      navigate(`/education/EducationPublications/${id}`, {
          state: {
              images: images,
              data: data1,
          },
      });
  };

  return (
    <div>

<div className="container mt-5 mb-5">
  <div className="row">
    <div className='col-md-12'>
      <h3> Research and Publications </h3>
    </div>


    <div className="row">

    {data.map((item, key) => {
      return (
      item.status === "ACTIVE" && item.college_id == "1111010"  &&(

    <div className='col-md-6 pt-4'>   
    <div className='card border-danger shadow-reguler' key={item.id} style={{ cursor: "pointer" }}>

    <a href={item?.attachments} target='_blank'>

      <div className="card-body">    
        <h5>{item?.title}</h5>
      
      <div className="d-flex justify-content-between">    
        <p>{item?.researcher}</p>
        <p> {item?.month} - {item?.publishdate.split('-')[0]}</p>
      </div>

      </div>

    </a>

    </div>                                        
  </div>

)
)
})
}
  
  </div>
   
  </div>
  <br />
  <br />
</div>



    </div>
  )
}

export default EducationPublications;



// export default function EducationPublications() {
//   return (
//     <div>
//       <div className="container mt-5 mb-5">
//         <div className="row">
//           <div className="col-md-12">
//             <p>
//               <span style={{ fontSize: "18pt" }}>
//                 <strong>Publications</strong>
//               </span>
//               <br />
//             </p>

//             <p>
//               <strong>Publications By Faculty of Education :</strong>
//               <br />
//             </p>
//             <table
//               className="table table-bordered"
//               style={{ borderCollapse: "collapse", width: "100%", height: 173 }}
//               border={1}
//             >
//               <tbody>
//                 <tr style={{ height: 20 }}>
//                   <td>
//                     <strong>Sr No</strong>
//                   </td>
//                   <td>
//                     <strong>Name of Topic</strong>
//                   </td>
//                   <td>
//                     <strong>Presented By</strong>
//                   </td>
//                   <td>
//                     <strong>Details of Issue</strong>
//                   </td>
//                 </tr>
//                 <tr style={{ height: 20 }}>
//                   <td>1</td>
//                   <td>
//                     A Study of Performance Management of Teacher Trainees of B.
//                     Ed. College
//                   </td>

//                   <td>Dr. NITIN M. RAVAL</td>
//                   <td>
//                     Vol. 9, Issue: 6, June: 2021 (IJRSML) ISSN: 2321 - 2853
//                   </td>
//                 </tr>
//                 <tr style={{ height: 20 }}>
//                   <td>2</td>
//                   <td>
//                     Educational Leadership in Relation to Graduate & Post
//                     Graduate Student of Arts, Commerce & Science
//                   </td>

//                   <td>Dr. NITIN M. RAVAL</td>
//                   <td>
//                     Vol. 10, Issue: 5, May: 2022 (IJRSML) ISSN: 2321 - 2853
//                   </td>
//                 </tr>
//                 <tr style={{ height: 20 }}>
//                   <td>3</td>
//                   <td>
//                     Construction and tryout of Multimedia Package for the
//                     selected topics in the Subject of Elements of Book-Keeping
//                     and Accountancy of Standard 11
//                   </td>

//                   <td>Dr. NITIN M. RAVAL</td>
//                   <td>
//                     Vol. 4, Issue:4, May :2015 (IJRE) ISSN: (P) 2347-5412 ISSN:
//                     (O) 2320-091X
//                   </td>
//                 </tr>
//                 <tr style={{ height: 20 }}>
//                   <td>4</td>
//                   <td>Impact of Self Esteem on Personality and Adjustment</td>
//                   <td>Dr. NITIN M. RAVAL</td>
//                   <td>
//                     Vol. 9, Issue: 4, April: 2021 (IJRSML) ISSN: 2321 - 2853
//                   </td>
//                 </tr>
//               </tbody>
//             </table>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }
