import axios from "axios";
import React from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import InputDoc from "../../../Components/InputDoc/InputDoc";
import Loader from "../../../Components/Loader/Loader";
import { getFileUrl } from "../../../Helper/Helper";
import {
  HDFC_PAYMENT_GATEWAY,
  PHD_ADMISSION,
  PHD_CHECK,
} from "../../../Utils/apiConst";
import { useReactToPrint } from "react-to-print";
import "./PhdAdmissionForm.css";
import "./PhdNotifications.scss";
import Stepper from "react-stepper-horizontal";

function PhdAdmissionForm() {
  const [loading, setLoading] = useState(0);

  const [amount, setAmount] = useState(2500);

  const [activeStep, setActiveStep] = useState(0);

  const stpes = [
    {
      title: "Application For",
    },
    {
      title: "Personal Details",
    },
    {
      title: "Academic Details",
    },
    {
      title: "Employment Details",
    },
    {
      title: "Publication",
    },
    {
      title: "In service Canditate",
    },
    {
      title: "Documents",
    },
    {
      title: "Decalration",
    },
    {
      title: "Payment",
    },
  ];

  const [finalTransactionID, setFinalTransactionId] = useState(
    sessionStorage.getItem("trx_id")
  );

  useEffect(() => {
    setFinalTransactionId(sessionStorage.getItem("trx_id"));
  }, [sessionStorage.getItem("trx_id")]);

  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const date = new Date();

  const [data, setData] = useState();

  const ref = useRef();

  const [user, setUser] = useState({
    user_id: "",
    application_job_type: "",
    application_for: "",
    area_of_specialization: "",
    candidate_name: "",
    guardian_name: "",
    dob: "",
    payment_status: "",
    gender: "",
    category: "",
    nationality: "",
    marital_status: "",
    blood_group: "",
    pan: "",
    aadhaar_no: "",
    address: "",
    city: "",
    state: "",
    phone: "",
    mobile: "",
    email: "",
    academic_details_ssc_college_name: "",
    academic_details_ssc_university_board: "",
    academic_details_ssc_subject: "",
    academic_details_ssc_year_of_passing: "",
    academic_details_ssc_percentage_division: "",
    academic_details_hsc_college_name: "",
    academic_details_hsc_university_board: "",
    academic_details_hsc_subject: "",
    academic_details_hsc_year_of_passing: "",
    academic_details_hsc_percentage_division: "",
    academic_details_ug_college_name: "",
    academic_details_ug_university_board: "",
    academic_details_ug_subject: "",
    academic_details_ug_year_of_passing: "",
    academic_details_ug_percentage_division: "",
    academic_details_pg_college_name: "",
    academic_details_pg_university_board: "",
    academic_details_pg_subject: "",
    academic_details_pg_year_of_passing: "",
    academic_details_pg_percentage_division: "",
    employment_1_organization: "",
    employment_1_duration: "",
    employment_1_regular_temporary: "",
    employment_1_nature_of_duties: "",
    employment_2_organization: "",
    employment_2_duration: "",
    employment_2_regular_temporary: "",
    employment_2_nature_of_duties: "",
    employment_3_organization: "",
    employment_3_duration: "",
    employment_3_regular_temporary: "",
    employment_3_nature_of_duties: "",
    research_publication_1_title: "",
    research_publication_1_no_of_journal_published: "",
    research_publication_1_no_vol_edition_date: "",
    research_publication_1_national_international: "",
    research_publication_2_title: "",
    research_publication_2_no_of_journal_published: "",
    research_publication_2_no_vol_edition_date: "",
    research_publication_2_national_international: "",
    research_publication_3_title: "",
    research_publication_3_no_of_journal_published: "",
    research_publication_3_no_vol_edition_date: "",
    research_publication_3_national_international: "",
    in_service_department: "",
    in_service_name_of_constituent_college: "",
    in_service_date_of_appointment: "",
    in_service_present_designation: "",
    in_service_period_of_service: "",
    in_service_noc: "",
    ug_degree_certificate: "",
    pg_degree_certificate: "",
    id_proof: "",
    photograph: "",
    student_tans_id: "",
    student_tans_date: "",
    student_tans_bank: "",
    student_tans_bank_reciept: "",
    application_submission_date: date.toISOString()?.split("T")[0],
    application_submission_place: "",
    application_checkbox: false,
  });

  const addAttachment = async (e, str) => {
    try {
      const d = await getFileUrl(
        "EMPLOYEE_IMAGE",
        `PHD ADMISSION`,
        e.target.value.split(".")[1],
        setLoading,
        e.target.files[0]
      );
      setUser((prev) => ({
        ...prev,
        [e.target.name]: d ? d : "",
      }));
    } catch (error) {
      console.log(error);
    }
  };

  let validatingObj = {
    application_job_type: "Type of Job Application",
    application_for: "Purpose of Application",
    candidate_name: "Candidate's Name",
    guardian_name: "Guardian's Name",
    dob: "Date of Birth",
    gender: "Gender",
    category: "Category",
    nationality: "Nationality",
    marital_status: "Marital Status",
    blood_group: "Blood Group",
    pan: "PAN (Permanent Account Number)",
    aadhaar_no: "Aadhaar Number",
    address: "Address",
    city: "City",
    state: "State",
    phone: "Phone Number",
    email: "Email Address",
    academic_details_ssc_college_name: "SSC (10th) College Name",
    academic_details_ssc_university_board: "SSC (10th) University/Board",
    academic_details_ssc_subject: "SSC (10th) Subject",
    academic_details_ssc_year_of_passing: "SSC (10th) Year of Passing",
    academic_details_ssc_percentage_division: "SSC (10th) Percentage/Division",
    academic_details_hsc_college_name: "HSC (12th) College Name",
    academic_details_hsc_university_board: "HSC (12th) University/Board",
    academic_details_hsc_subject: "HSC (12th) Subject",
    academic_details_hsc_year_of_passing: "HSC (12th) Year of Passing",
    academic_details_hsc_percentage_division: "HSC (12th) Percentage/Division",
    academic_details_ug_college_name: "UG (Undergraduate) College Name",
    academic_details_ug_university_board: "UG (Undergraduate) University/Board",
    academic_details_ug_subject: "UG (Undergraduate) Subject",
    academic_details_ug_year_of_passing: "UG (Undergraduate) Year of Passing",
    academic_details_ug_percentage_division:
      "UG (Undergraduate) Percentage/Division",
    academic_details_pg_college_name: "PG (Postgraduate) College Name",
    academic_details_pg_university_board: "PG (Postgraduate) University/Board",
    academic_details_pg_subject: "PG (Postgraduate) Subject",
    academic_details_pg_year_of_passing: "PG (Postgraduate) Year of Passing",
    academic_details_pg_percentage_division:
      "PG (Postgraduate) Percentage/Division",
    ug_degree_certificate: "UG Degree Certificate",
    pg_degree_certificate: "PG Degree Certificate",
    id_proof: "ID Proof",
    photograph: "Photograph",
    application_submission_date: "Application Submission Date",
    application_submission_place: "Application Submission Place",
    application_checkbox: "Application Checkbox",
  };

  const clearData = () => {
    setUser({
      user_id: "",
      application_job_type: "",
      application_for: "",
      area_of_specialization: "",
      candidate_name: "",
      guardian_name: "",
      dob: "",
      gender: "",
      category: "",
      nationality: "",
      marital_status: "",
      blood_group: "",
      pan: "",
      aadhaar_no: "",
      address: "",
      city: "",
      state: "",
      phone: "",
      mobile: "",
      email: "",
      academic_details_ssc_college_name: "",
      academic_details_ssc_university_board: "",
      academic_details_ssc_subject: "",
      academic_details_ssc_year_of_passing: "",
      academic_details_ssc_percentage_division: "",
      academic_details_hsc_college_name: "",
      academic_details_hsc_university_board: "",
      academic_details_hsc_subject: "",
      academic_details_hsc_year_of_passing: "",
      academic_details_hsc_percentage_division: "",
      academic_details_ug_college_name: "",
      academic_details_ug_university_board: "",
      academic_details_ug_subject: "",
      academic_details_ug_year_of_passing: "",
      academic_details_ug_percentage_division: "",
      academic_details_pg_college_name: "",
      academic_details_pg_university_board: "",
      academic_details_pg_subject: "",
      academic_details_pg_year_of_passing: "",
      academic_details_pg_percentage_division: "",
      employment_1_organization: "",
      employment_1_duration: "",
      employment_1_regular_temporary: "",
      employment_1_nature_of_duties: "",
      employment_2_organization: "",
      employment_2_duration: "",
      employment_2_regular_temporary: "",
      employment_2_nature_of_duties: "",
      employment_3_organization: "",
      employment_3_duration: "",
      employment_3_regular_temporary: "",
      employment_3_nature_of_duties: "",
      research_publication_1_title: "",
      research_publication_1_no_of_journal_published: "",
      research_publication_1_no_vol_edition_date: "",
      research_publication_1_national_international: "",
      research_publication_2_title: "",
      research_publication_2_no_of_journal_published: "",
      research_publication_2_no_vol_edition_date: "",
      research_publication_2_national_international: "",
      research_publication_3_title: "",
      research_publication_3_no_of_journal_published: "",
      research_publication_3_no_vol_edition_date: "",
      research_publication_3_national_international: "",
      in_service_department: "",
      in_service_name_of_constituent_college: "",
      in_service_date_of_appointment: "",
      in_service_present_designation: "",
      in_service_period_of_service: "",
      in_service_noc: "",
      ug_degree_certificate: "",
      pg_degree_certificate: "",
      id_proof: "",
      photograph: "",
      student_tans_id: "",
      student_tans_date: "",
      student_tans_bank: "",
      student_tans_bank_reciept: "",
      application_submission_date: date.toISOString()?.split("T")[0],
      application_submission_place: "",
      application_checkbox: "",
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (data && data.encRequest && data.accessCode) {
      ref.current.click();
    }
  }, [data]);

  const applicationStatus = "closed";

  const handleSubmit = async () => {
    // if (!validateForm()) return toast.error("Mandatory fields are required");

    let keys = Object.keys(validatingObj);
    keys?.map((item, key) => {
      if (!user[item] || user[item] == "") {
        return toast.error(`${validatingObj[item]} is required`);
      }
    });

    if (user?.payment_status) {
      setLoading(1);
      const config1 = {
        headers: {
          "Content-Type": "application/json",
        },
        url: `${PHD_ADMISSION}/${user?.id}`,
        data: user,
        method: "put",
      };
      await axios(config1)
        .then(async (res) => {
          console.log(res);
          setLoading(0);
          setActiveStep(7);
          const obj = {
            type: "PHD_ADMISSION",
            data: {
              admission_id: res.data.data.id,
              payment_for: "PHD ADMISSION FORM",
            },
          };
          const str = JSON.stringify(obj);
          const config2 = {
            headers: {
              "Content-Type": "application/json",
            },
            url: HDFC_PAYMENT_GATEWAY,
            data: {
              amount: amount,
              merchant_param1: user.candidate_name,
              merchant_param2: user.aadhaar_no,
              merchant_param3: user.email,
              merchant_param4: "UMS_ERP",
              merchant_param5: str,
              merchant_param6: "PHD ADMISSION FORM",
              billing_address: user.address,
              billing_city: user.city,
              billing_state: user.state,
              billing_country: user.nationality,
              billing_zip: user.phone,
              merchant_faculty: 1111012,
            },
            method: "post",
          };
          await axios(config2)
            .then((res) => {
              console.log(res.data);
              setData(res.data);
              clearData();
              setLoading(0);
            })
            .catch((err) => {
              console.log(err);
              setLoading(0);
            });
        })
        .catch((err) => {
          console.log(err);
          setLoading(0);
        });
    } else {
      setLoading(1);
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        url: PHD_ADMISSION,
        data: user,
        method: "POST",
      };
      await axios(config)
        .then(async (res) => {
          console.log(res.data.data);
          setLoading(0);
          setTimeout(() => {
            handlePrint();
          }, 1000);
          const obj = {
            type: "PHD_ADMISSION",
            data: {
              admission_id: res.data.data.id,
              payment_for: "PHD ADMISSION FORM",
            },
          };
          const str = JSON.stringify(obj);
          const config2 = {
            headers: {
              "Content-Type": "application/json",
            },
            url: HDFC_PAYMENT_GATEWAY,
            data: {
              amount: amount,
              merchant_param1: user.candidate_name,
              merchant_param2: user.aadhaar_no,
              merchant_param3: user.email,
              merchant_param4: "UMS_ERP",
              merchant_param5: str,
              merchant_param6: "PHD ADMISSION FORM",
              billing_address: user.address,
              billing_city: user.city,
              billing_state: user.state,
              billing_country: user.nationality,
              billing_zip: user.phone,
              merchant_faculty: 1111012,
            },
            method: "post",
          };
          await axios(config2)
            .then((res) => {
              console.log(res.data);
              setData(res.data);
              clearData();
              setLoading(0);
            })
            .catch((err) => {
              setLoading(0);
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
          setLoading(0);
        });
    }
  };

  let obj1;

  const checkApplicationAndNext = async () => {
    console.log(user);
    if (!user?.application_for || !user?.application_job_type || !user?.phone)
      return toast.error("Mandatory fields are required");
    const config = {
      method: "post",
      url: PHD_CHECK,
      data: {
        application_for: user.application_for,
        phone: user.phone,
        application_job_type: user.application_job_type,
      },
    };

    await axios(config)
      .then(async (res) => {
        if (res.data.isPrev && res.data.data.payment_status == "Success") {
          obj1 = res.data.data;
          await setUser(obj1);
          toast.warning("Application is already submitted");
          setTimeout(() => {
            handlePrint();
          }, 1000);
        } else if (
          res.data.isPrev &&
          res.data.data.payment_status != "Success"
        ) {
          toast.warning(
            "Application is already submitted but payment is pending"
          );
          setUser({
            ...res.data.data,
            application_submission_date: date.toISOString()?.split("T")[0],
          });
          setActiveStep(1);
        } else {
          setActiveStep(1);
        }
      })
      .catch((err) => {
        toast.error("Something went wrong while fetching previous details");
      });
  };

  //Functions to validate Form

  const checkCertificates = () => {
    let obj = {
      ug_degree_certificate: "User's UG Degree Certificate",
      pg_degree_certificate: "User's PG Degree Certificate",
      id_proof: "User's ID Proof",
      photograph: "User's Photograph",
    };

    let keys = Object.keys(obj);
    let flag = true;
    keys?.map((item, key) => {
      if (!user[item]) {
        flag = false;
        return toast.error(`${obj[item]} is required`);
      }
    });
    return flag;
  };

  const checkBasicDetails = () => {
    let obj = {
      candidate_name: "Your Candidate Name",
      email: "Your Email",
      address: "Your Address",
      guardian_name: "Your Guardian Name",
      city: "Your City",
      state: "Your State",
      dob: "Your Date of Birth",
      gender: "Your Gender",
      category: "Your Category",
      aadhaar_no: "Your Aadhaar Number",
      pan: "Your PAN (Permanent Account Number)",
      blood_group: "Your Blood Group",
      marital_status: "Your Marital Status",
      nationality: "Your Nationality",
    };

    let keys = Object.keys(obj);
    let flag = true;
    keys?.map((item, key) => {
      if (!user[item]) {
        flag = false;
        return toast.error(`${obj[item]} is required`);
      }
    });
    return flag;
  };

  const checkEducationalDetails = () => {
    let obj = {
      academic_details_ssc_college_name: "SSC (10th) College Name",
      academic_details_ssc_university_board: "SSC (10th) University/Board",
      academic_details_ssc_subject: "SSC (10th) Subject",
      academic_details_ssc_year_of_passing: "SSC (10th) Year of Passing",
      academic_details_ssc_percentage_division:
        "SSC (10th) Percentage/Division",
      academic_details_hsc_college_name: "HSC (12th) College Name",
      academic_details_hsc_university_board: "HSC (12th) University/Board",
      academic_details_hsc_subject: "HSC (12th) Subject",
      academic_details_hsc_year_of_passing: "HSC (12th) Year of Passing",
      academic_details_hsc_percentage_division:
        "HSC (12th) Percentage/Division",
      academic_details_ug_college_name: "UG (Undergraduate) College Name",
      academic_details_ug_university_board:
        "UG (Undergraduate) University/Board",
      academic_details_ug_subject: "UG (Undergraduate) Subject",
      academic_details_ug_year_of_passing: "UG (Undergraduate) Year of Passing",
      academic_details_ug_percentage_division:
        "UG (Undergraduate) Percentage/Division",
      academic_details_pg_college_name: "PG (Postgraduate) College Name",
      academic_details_pg_university_board:
        "PG (Postgraduate) University/Board",
      academic_details_pg_subject: "PG (Postgraduate) Subject",
      academic_details_pg_year_of_passing: "PG (Postgraduate) Year of Passing",
      academic_details_pg_percentage_division:
        "PG (Postgraduate) Percentage/Division",
    };

    let keys = Object.keys(obj);
    let flag = true;
    keys?.map((item, key) => {
      if (!user[item]) {
        flag = false;
        return toast.error(`${obj[item]} is required`);
      }
    });
    return flag;
  };

  useEffect(() => {
    if (data && data.encRequest && data.accessCode) {
      ref.current.click();
    }
  }, [data]);

  return (
    <div className="PhdAdmissionForm">
      <Loader loading={loading} />
      {applicationStatus == "closed" ? (
        <div className="container">
          <div className="row">
            <div className="col-12 d-flex justify-content-center mb-5">
              <img
                src="/assets/images/white-logo.png"
                style={{ width: "500px", margin: "auto" }}
                alt=""
              />
            </div>
            <div className="col-md-12 d-flex justify-content-center ">
              <h5 className="alert alert-danger">
                Online PhD Application 2024-25 (Batch I) is Closed. Please
                Contact University For Further Details
              </h5>
            </div>
          </div>
          <hr />

          <h2>Contact for more info</h2>
          <p>
            **Department of Research:** <br />
            - Email Address: rd@swaminarayanuniversity.ac.in <br />
            - Mobile Numbers: 9176945090, 9345891984 <br />
            - Dean Research (I/C): Dr. Anita Patel <br />
          </p>
        </div>
      ) : (
        <div className="container-fluid mb-3  p-4">
          <div className="row">
            <div className="col-md-12">
              <div className="card border-0 shadow-sm p-3">
                <Stepper
                  activeColor="#dc3545"
                  completeColor="#dc3545"
                  steps={stpes}
                  activeStep={activeStep}
                />
                <div className="card-body p-sm-5">
                  <div className="row">
                    <div className="col-12 d-flex justify-content-center mb-5">
                      <img
                        src="/assets/images/white-logo.png"
                        style={{ width: "300px", margin: "auto" }}
                        alt=""
                      />
                    </div>
                    <div className="col-md-12 d-flex justify-content-center ">
                      <h1>PhD Application Form 2024-25 (Batch I)</h1>
                    </div>
                    <hr />
                    {activeStep == 0 && (
                      <>
                        <div className="col-md-12 mt-4">
                          <h4>Application For</h4>
                        </div>
                        <div className="col-md-4 mb-3">
                          <div className="form-group">
                            <label htmlFor="">
                              {" "}
                              Admission Type{" "}
                              <small className="text-danger">*</small>
                            </label>
                            <select
                              className="form-control"
                              value={user?.application_job_type}
                              name="application_job_type"
                              onChange={handleChange}
                            >
                              <option value="">Select Admission Type</option>
                              <option value="Fulltime">Fulltime</option>
                              <option value="Internal">Inter Service</option>
                              <option value="External">
                                External (Part Time)
                              </option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="">
                              Specialization{" "}
                              <small className="text-danger">*</small>
                            </label>
                            <select
                              name="application_for"
                              id=""
                              className="form-control"
                              value={user?.application_for}
                              onChange={handleChange}
                            >
                              <option value="">Select Specialization</option>

                              <option value="Ayurveda">Ayurveda</option>
                              <option value="Homoeopathy">Homoeopathy</option>
                              <option value="Commerce Management">
                                Commerce and Management
                              </option>
                              <option value="Science">Science</option>
                              <option value="Arts Humanities">
                                Arts & Humanities
                              </option>
                              <option value="Education">Education</option>
                              <option value="IT Computer Science">
                                IT & Computer Science
                              </option>
                              <option value="Engineering">Engineering</option>
                              <option value="Pharmacy">Pharmacy</option>
                              <option value="Medicine">Medicine</option>
                              <option value="Nursing">Nursing</option>
                              {/* <option value="Law">Law</option> */}
                              {/* <option value="Medical Science">Medical Science</option> */}
                              <option value="Sanskrit">Sanskrit</option>
                              <option value="Science">Science</option>
                            </select>
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-md-8">
                            <div className="form-group">
                              <label
                                htmlFor="
    "
                              >
                                Specify area of Specialization / Research
                              </label>
                              <textarea
                                name="area_of_specialization"
                                className="form-control"
                                id=""
                                cols="3"
                                rows="2"
                                value={user?.area_of_specialization}
                                onChange={handleChange}
                              ></textarea>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="">
                                Phone number{" "}
                                <small className="text-danger">*</small>
                              </label>
                              <input
                                type="number"
                                name="phone"
                                className="form-control"
                                value={user?.phone}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 mt-3">
                          <button
                            onClick={() => {
                              checkApplicationAndNext();
                            }}
                            className="btn btn-danger"
                          >
                            Next
                          </button>
                        </div>
                      </>
                    )}
                  </div>

                  {activeStep == 1 && (
                    <>
                      <div className="row mt-5">
                        <h4>PERSONAL INFORMATION</h4>
                      </div>
                      <div className="row mt-3">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="">
                              Name of Candidate (In the format mentioned in Last
                              Marksheet){" "}
                              <small className="text-danger">*</small>
                            </label>
                            <input
                              type="text"
                              name="candidate_name"
                              className="form-control"
                              value={user?.candidate_name}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="">
                              Email <small className="text-danger">*</small>
                            </label>
                            <input
                              type="text"
                              name="email"
                              className="form-control"
                              value={user?.email}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="">
                              Address <small className="text-danger">*</small>
                            </label>
                            <textarea
                              name="address"
                              className="form-control"
                              value={user?.address}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="">
                              City <small className="text-danger">*</small>
                            </label>
                            <input
                              type="text"
                              name="city"
                              className="form-control"
                              value={user?.city}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="">
                              State <small className="text-danger">*</small>
                            </label>
                            <input
                              type="text"
                              name="state"
                              className="form-control"
                              value={user?.state}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="">
                              Father Name{" "}
                              <small className="text-danger">*</small>
                            </label>
                            <input
                              type="text"
                              name="guardian_name"
                              className="form-control"
                              value={user?.guardian_name}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="">
                              Father Mobile{" "}
                              {/* <small className="text-danger">*</small> */}
                            </label>
                            <input
                              type="text"
                              name="mobile"
                              className="form-control"
                              value={user?.mobile}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="">
                              Date of Birth (DD/MM/YYYY){" "}
                              <small className="text-danger">*</small>
                            </label>
                            <input
                              type="date"
                              name="dob"
                              className="form-control"
                              value={user?.dob}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label htmlFor="">
                              Gender <small className="text-danger">*</small>
                            </label>
                            <select
                              name="gender"
                              id=""
                              className="form-control"
                              value={user?.gender}
                              onChange={handleChange}
                            >
                              <option value="">Select Gender</option>
                              <option value="Male">Male</option>
                              <option value="Female">Female</option>
                              <option value="Other">Other</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label htmlFor="">
                              Category <small className="text-danger">*</small>
                            </label>
                            <select
                              name="category"
                              className="form-control"
                              value={user?.category}
                              onChange={handleChange}
                            >
                              <option value="">Select Category </option>
                              <option value="GEN">GEN</option>
                              <option value="SEBC/OBC">SEBC/OBC</option>
                              <option value="SC">SC</option>
                              <option value="ST">ST</option>
                              <option value="PH">PH</option>
                              <option value="Other">Other</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label htmlFor="">
                              Nationality{" "}
                              <small className="text-danger">*</small>{" "}
                            </label>
                            <input
                              type="text"
                              name="nationality"
                              className="form-control"
                              value={user?.nationality}
                              onChange={handleChange}
                            />
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="form-group">
                            <label htmlFor="">
                              Marital Status{" "}
                              <small className="text-danger">*</small>
                            </label>
                            <select
                              name="marital_status"
                              id=""
                              className="form-control"
                              value={user?.marital_status}
                              onChange={handleChange}
                            >
                              <option value="">Select Merital Status</option>
                              <option value="Married">Married</option>
                              <option value="Unmarried">Unmarried</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label htmlFor="">
                              Blood Group{" "}
                              <small className="text-danger">*</small>{" "}
                            </label>
                            <input
                              type="text"
                              name="blood_group"
                              className="form-control"
                              value={user?.blood_group}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label htmlFor="">
                              PAN Card No.{" "}
                              <small className="text-danger">*</small>{" "}
                            </label>
                            <input
                              type="text"
                              name="pan"
                              className="form-control"
                              value={user?.pan}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label htmlFor="">
                              Aadhar Card No.{" "}
                              <small className="text-danger">*</small>{" "}
                            </label>
                            <input
                              type="text"
                              name="aadhaar_no"
                              className="form-control"
                              value={user?.aadhaar_no}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="mt-3 d-flex justify-content-between">
                        <button
                          onClick={() => {
                            setActiveStep(0);
                          }}
                          className="btn btn-danger"
                        >
                          Prev
                        </button>
                        <button
                          onClick={() => {
                            if (!checkBasicDetails()) {
                              return;
                            }
                            setActiveStep(2);
                          }}
                          className="btn btn-danger"
                        >
                          Next
                        </button>
                      </div>
                    </>
                  )}

                  {activeStep == 2 && (
                    <>
                      <div className="row mt-5">
                        <div className="col-md-12">
                          <h5>ACADEMIC DETAILS</h5>
                          <div className="table-responsive employment-details">
                            <table className="table table-bordered ">
                              <thead>
                                <tr>
                                  <th width={10}>Sr. No.</th>
                                  <th>Exam Passed</th>
                                  <th>School / College Name</th>
                                  <th>University / Board</th>
                                  <th>Subject(Specialization)</th>
                                  <th>Year of Passing</th>
                                  <th>Percentage &amp; Division</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>1</td>
                                  <td>
                                    Secondary (SSC){" "}
                                    <span className="required">*</span>
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="academic_details_ssc_college_name"
                                      value={
                                        user?.academic_details_ssc_college_name
                                      }
                                      onChange={handleChange}
                                      maxLength={100}
                                      required=""
                                      wtx-context="2E84417F-C5D7-4EE9-8827-C0C8C47D7279"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="academic_details_ssc_university_board"
                                      value={
                                        user?.academic_details_ssc_university_board
                                      }
                                      onChange={handleChange}
                                      maxLength={255}
                                      required=""
                                      wtx-context="F9EBA00B-30EF-44BE-B552-3C5C2C23B675"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="academic_details_ssc_subject"
                                      value={user?.academic_details_ssc_subject}
                                      onChange={handleChange}
                                      maxLength={255}
                                      required=""
                                      wtx-context="21D40576-3017-4734-851A-1F9325F430CB"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="academic_details_ssc_year_of_passing"
                                      value={
                                        user?.academic_details_ssc_year_of_passing
                                      }
                                      onChange={handleChange}
                                      maxLength={50}
                                      required=""
                                      wtx-context="D9C5B39C-1F01-4648-8532-522DFE43CFAB"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="academic_details_ssc_percentage_division"
                                      value={
                                        user?.academic_details_ssc_percentage_division
                                      }
                                      onChange={handleChange}
                                      maxLength={50}
                                      required=""
                                      wtx-context="A90416D2-5DDA-487B-8E55-1AAE856FC334"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>2</td>
                                  <td>
                                    Higher Secondary (HSC){" "}
                                    <span className="required">*</span>
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="academic_details_hsc_college_name"
                                      value={
                                        user?.academic_details_hsc_college_name
                                      }
                                      onChange={handleChange}
                                      maxLength={100}
                                      required=""
                                      wtx-context="D6F6069E-6AE1-4EA0-89A4-7E6613B67C4B"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="academic_details_hsc_university_board"
                                      value={
                                        user?.academic_details_hsc_university_board
                                      }
                                      onChange={handleChange}
                                      maxLength={255}
                                      required=""
                                      wtx-context="A91D156B-7FBD-431C-8389-128FE47814BA"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="academic_details_hsc_subject"
                                      value={user?.academic_details_hsc_subject}
                                      onChange={handleChange}
                                      maxLength={255}
                                      required=""
                                      wtx-context="43998E34-6EB8-490F-8A9E-7D53C235FFE1"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="academic_details_hsc_year_of_passing"
                                      value={
                                        user?.academic_details_hsc_year_of_passing
                                      }
                                      onChange={handleChange}
                                      maxLength={50}
                                      required=""
                                      wtx-context="3FFDFC92-93BF-4D98-942D-997A7A22DC75"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="academic_details_hsc_percentage_division"
                                      value={
                                        user?.academic_details_hsc_percentage_division
                                      }
                                      onChange={handleChange}
                                      maxLength={50}
                                      required=""
                                      wtx-context="7BCA029B-CA15-44F2-A2E8-71D405E56E80"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>3</td>
                                  <td>
                                    Under – Graduate{" "}
                                    <span className="required">*</span>
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="academic_details_ug_college_name"
                                      value={
                                        user?.academic_details_ug_college_name
                                      }
                                      onChange={handleChange}
                                      maxLength={100}
                                      required=""
                                      wtx-context="78022A11-41E4-4FFE-87D5-48410BDB1BC0"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="academic_details_ug_university_board"
                                      value={
                                        user?.academic_details_ug_university_board
                                      }
                                      onChange={handleChange}
                                      maxLength={255}
                                      required=""
                                      wtx-context="231D5A17-7F96-47F9-B09F-7E35598B2E62"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="academic_details_ug_subject"
                                      value={user?.academic_details_ug_subject}
                                      onChange={handleChange}
                                      maxLength={255}
                                      required=""
                                      wtx-context="6983A1AF-A04D-40C9-8D7B-FA2AAEB6FCB9"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="academic_details_ug_year_of_passing"
                                      value={
                                        user?.academic_details_ug_year_of_passing
                                      }
                                      onChange={handleChange}
                                      maxLength={50}
                                      required=""
                                      wtx-context="31B94287-16B0-4B15-80C3-89E782966A9E"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="academic_details_ug_percentage_division"
                                      value={
                                        user?.academic_details_ug_percentage_division
                                      }
                                      onChange={handleChange}
                                      maxLength={50}
                                      required=""
                                      wtx-context="A8E66BE3-4E11-4158-8E60-485D45363FAD"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>4</td>
                                  <td>
                                    Post – Graduate{" "}
                                    <span className="required">*</span>
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="academic_details_pg_college_name"
                                      value={
                                        user?.academic_details_pg_college_name
                                      }
                                      onChange={handleChange}
                                      maxLength={100}
                                      required=""
                                      wtx-context="2DBBBD16-59F4-4342-82D7-A9E0D6270CD5"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="academic_details_pg_university_board"
                                      value={
                                        user?.academic_details_pg_university_board
                                      }
                                      onChange={handleChange}
                                      maxLength={255}
                                      required=""
                                      wtx-context="D5067B64-EC8E-4A1D-818E-CBE54B6C80C2"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="academic_details_pg_subject"
                                      value={user?.academic_details_pg_subject}
                                      onChange={handleChange}
                                      maxLength={255}
                                      required=""
                                      wtx-context="47691E52-50C2-44A6-8DF0-C0CEEFB0AA5B"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="academic_details_pg_year_of_passing"
                                      value={
                                        user?.academic_details_pg_year_of_passing
                                      }
                                      onChange={handleChange}
                                      maxLength={50}
                                      required=""
                                      wtx-context="53928B80-9306-4809-8FB8-8701B33CAD9F"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="academic_details_pg_percentage_division"
                                      value={
                                        user?.academic_details_pg_percentage_division
                                      }
                                      onChange={handleChange}
                                      maxLength={50}
                                      required=""
                                      wtx-context="116D97FB-CC99-47BA-8ED3-222411471094"
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className="mt-3 d-flex justify-content-between">
                        <button
                          onClick={() => {
                            setActiveStep(1);
                          }}
                          className="btn btn-danger"
                        >
                          Prev
                        </button>
                        <button
                          onClick={() => {
                            if (!checkEducationalDetails()) {
                              return;
                            }
                            setActiveStep(3);
                          }}
                          className="btn btn-danger"
                        >
                          Next
                        </button>
                      </div>
                    </>
                  )}

                  {activeStep == 3 && (
                    <>
                      <div className="row mt-5">
                        <div className="col-md-12">
                          <h5>EMPLOYMENT DETAILS (RECENT THREE)</h5>
                          <div className="table-responsive employment-details">
                            <table className="table table-bordered ">
                              <thead>
                                <tr>
                                  <th width={10}>Sr. No.</th>
                                  <th>Organization</th>
                                  <th>Duration</th>
                                  <th>Position Regular / Temporary</th>
                                  <th>Nature of Duties</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>1</td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="employment_1_organization"
                                      value={user?.employment_1_organization}
                                      onChange={handleChange}
                                      maxLength={100}
                                      wtx-context="61D6FB3E-4291-4EAC-822D-0679D3D28478"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="employment_1_duration"
                                      value={user?.employment_1_duration}
                                      onChange={handleChange}
                                      maxLength={50}
                                      wtx-context="AAAF8D56-92AD-4497-80F3-865F7C748C51"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="employment_1_regular_temporary"
                                      value={
                                        user?.employment_1_regular_temporary
                                      }
                                      onChange={handleChange}
                                      maxLength={100}
                                      wtx-context="3B4FA417-7831-4CEA-8E06-66863FC6042B"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="employment_1_nature_of_duties"
                                      value={
                                        user?.employment_1_nature_of_duties
                                      }
                                      onChange={handleChange}
                                      maxLength={255}
                                      wtx-context="23411D83-DCEA-403D-89DF-A52B3BE023A1"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>2</td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="employment_2_organization"
                                      value={user?.employment_2_organization}
                                      onChange={handleChange}
                                      maxLength={200}
                                      wtx-context="4A66641B-AEE0-495D-B365-A794787B4EB3"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="employment_2_duration"
                                      value={user?.employment_2_duration}
                                      onChange={handleChange}
                                      maxLength={50}
                                      wtx-context="4EF65585-1C07-46E1-AF0E-D1ADC4C8541C"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="employment_2_regular_temporary"
                                      value={
                                        user?.employment_2_regular_temporary
                                      }
                                      onChange={handleChange}
                                      maxLength={200}
                                      wtx-context="3CE7A3DE-FF79-4565-958F-F13E2FB34F28"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="employment_2_nature_of_duties"
                                      value={
                                        user?.employment_2_nature_of_duties
                                      }
                                      onChange={handleChange}
                                      maxLength={255}
                                      wtx-context="B2DD0198-C4FF-4D6B-8EED-2E50C6FE093D"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>3</td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="employment_3_organization"
                                      value={user?.employment_3_organization}
                                      onChange={handleChange}
                                      maxLength={300}
                                      wtx-context="1F0116BD-54B1-4CBE-8B38-69558EE82781"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="employment_3_duration"
                                      value={user?.employment_3_duration}
                                      onChange={handleChange}
                                      maxLength={50}
                                      wtx-context="880C2AB0-3EE5-4C7B-8A20-DC71AE17A30D"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="employment_3_regular_temporary"
                                      value={
                                        user?.employment_3_regular_temporary
                                      }
                                      onChange={handleChange}
                                      maxLength={300}
                                      wtx-context="94EE504D-DD9E-4F0E-8D4E-F040F96D0577"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="employment_3_nature_of_duties"
                                      value={
                                        user?.employment_3_nature_of_duties
                                      }
                                      onChange={handleChange}
                                      maxLength={355}
                                      wtx-context="3E258618-BE66-4865-8E4E-0F5441119D7C"
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className="mt-3 d-flex justify-content-between">
                        <button
                          onClick={() => {
                            setActiveStep(2);
                          }}
                          className="btn btn-danger"
                        >
                          Prev
                        </button>
                        <button
                          onClick={() => {
                            // if ( !user?.employment_1_organization ||
                            //      !user?.employment_1_duration ||
                            //      !user?.employment_1_regular_temporary ||
                            //      !user?.employment_1_nature_of_duties ||
                            //      !user?.employment_2_organization ||
                            //      !user?.employment_2_duration ||
                            //      !user?.employment_2_regular_temporary ||
                            //      !user?.employment_2_nature_of_duties ||
                            //      !user?.employment_3_organization ||
                            //      !user?.employment_3_duration ||
                            //      !user?.employment_3_regular_temporary ||
                            //      !user?.employment_3_nature_of_duties
                            //    ) {
                            //      return toast.error("Mandatory Fields are Required");
                            // }
                            setActiveStep(4);
                          }}
                          className="btn btn-danger"
                        >
                          Next
                        </button>
                      </div>
                    </>
                  )}
                  {activeStep == 4 && (
                    <>
                      <div className="row mt-5">
                        <div className="col-md-12">
                          <h5>
                            GIVE DETAILS OF RESEARCH PUBLICATION (IN SCOPUS /
                            WOS / UGC CARE)(TOP THREE)
                          </h5>
                          <div className="table-responsive employment-details">
                            <table className="table table-bordered ">
                              <thead>
                                <tr>
                                  <th width={10}>Sr. No.</th>
                                  <th>Title of Publication</th>
                                  <th>Name of Journal/Published</th>
                                  <th>No./Vol./Edition/Date </th>
                                  <th>National/International</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>1</td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="research_publication_1_title"
                                      value={user?.research_publication_1_title}
                                      onChange={handleChange}
                                      maxLength={255}
                                      wtx-context="310AD393-6489-46B5-8D08-4BC026AD803B"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="research_publication_1_no_of_journal_published"
                                      value={
                                        user?.research_publication_1_no_of_journal_published
                                      }
                                      onChange={handleChange}
                                      maxLength={100}
                                      wtx-context="EDD3EC01-4911-4E63-AD1E-71DFA4AC86C7"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="research_publication_1_no_vol_edition_date"
                                      value={
                                        user?.research_publication_1_no_vol_edition_date
                                      }
                                      onChange={handleChange}
                                      maxLength={100}
                                      wtx-context="5B36729C-ED77-4174-8F05-5150C57235CF"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="research_publication_1_national_international"
                                      value={
                                        user?.research_publication_1_national_international
                                      }
                                      onChange={handleChange}
                                      maxLength={100}
                                      wtx-context="BBD7CA0A-8491-431E-8256-3004B0E6F767"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>2</td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="research_publication_2_title"
                                      value={user?.research_publication_2_title}
                                      onChange={handleChange}
                                      maxLength={255}
                                      wtx-context="DC20DD15-8B42-4425-8355-56269CC788A8"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="research_publication_2_no_of_journal_published"
                                      value={
                                        user?.research_publication_2_no_of_journal_published
                                      }
                                      onChange={handleChange}
                                      maxLength={200}
                                      wtx-context="E8884CEA-DCAD-4224-8A2B-E3263579147F"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="research_publication_2_no_vol_edition_date"
                                      value={
                                        user?.research_publication_2_no_vol_edition_date
                                      }
                                      onChange={handleChange}
                                      maxLength={200}
                                      wtx-context="FCA35548-BAF0-4EBC-864C-BF566F02D662"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="research_publication_2_national_international"
                                      value={
                                        user?.research_publication_2_national_international
                                      }
                                      onChange={handleChange}
                                      maxLength={200}
                                      wtx-context="DE998606-6771-4F62-AD06-5D214A1710D7"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>3</td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="research_publication_3_title"
                                      value={user?.research_publication_3_title}
                                      onChange={handleChange}
                                      maxLength={355}
                                      wtx-context="863F62B5-3730-4CA9-9A9C-63ABA41B13AA"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="research_publication_3_no_of_journal_published"
                                      value={
                                        user?.research_publication_3_no_of_journal_published
                                      }
                                      onChange={handleChange}
                                      maxLength={300}
                                      wtx-context="EC8E9DD6-73C8-48FA-A97A-52B15B42D477"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="research_publication_3_no_vol_edition_date"
                                      value={
                                        user?.research_publication_3_no_vol_edition_date
                                      }
                                      onChange={handleChange}
                                      maxLength={300}
                                      wtx-context="8D53EB7A-0AE4-4B85-9BBC-BA85198A0054"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="research_publication_3_national_international"
                                      value={
                                        user?.research_publication_3_national_international
                                      }
                                      onChange={handleChange}
                                      maxLength={300}
                                      wtx-context="1E8DDA76-0EE1-429F-839A-722EC4E299EB"
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className="mt-3 d-flex justify-content-between">
                        <button
                          onClick={() => {
                            setActiveStep(3);
                          }}
                          className="btn btn-danger"
                        >
                          Prev
                        </button>
                        <button
                          onClick={() => {
                            // if ( !user?.research_publication_1_title ||
                            //      !user?.research_publication_1_no_of_journal_published ||
                            //      !user?.research_publication_1_no_vol_edition_date ||
                            //      !user?.research_publication_1_national_international ||
                            //      !user?.research_publication_2_title ||
                            //      !user?.research_publication_2_no_of_journal_published ||
                            //      !user?.research_publication_2_no_vol_edition_date ||
                            //      !user?.research_publication_2_national_international ||
                            //      !user?.research_publication_3_title ||
                            //      !user?.research_publication_3_no_of_journal_published ||
                            //      !user?.research_publication_3_no_vol_edition_date ||
                            //      !user?.research_publication_3_national_international
                            // ) {
                            //   return toast.error("Mandatory Fields are Required");
                            // }
                            setActiveStep(5);
                          }}
                          className="btn btn-danger"
                        >
                          Next
                        </button>
                      </div>
                    </>
                  )}

                  {activeStep == 5 && (
                    <>
                      <div className="row mt-5">
                        <div className="col-md-12">
                          <h4>DETAILS OF IN-SERVICE CANDIDATES</h4>
                        </div>
                        <div className="personal-information">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Name of the Department:</label>

                                <input
                                  type="text"
                                  className="form-control"
                                  name="in_service_department"
                                  value={user?.in_service_department}
                                  onChange={handleChange}
                                  maxLength={100}
                                  wtx-context="67D93433-5D99-40AD-882C-05E39ECF2841"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Name of the Constituent College:</label>

                                <input
                                  type="text"
                                  className="form-control"
                                  name="in_service_name_of_constituent_college"
                                  value={
                                    user?.in_service_name_of_constituent_college
                                  }
                                  onChange={handleChange}
                                  maxLength={100}
                                  wtx-context="5D522BD1-A59B-48F5-8DD9-46B3598C764D"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Date of Appointment:</label>

                                <input
                                  type="date"
                                  className="form-control"
                                  id="name"
                                  name="in_service_date_of_appointment"
                                  value={user?.in_service_date_of_appointment}
                                  onChange={handleChange}
                                  maxLength={50}
                                  wtx-context="59EA2D18-15A8-42BC-9391-DCC81CA3824B"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label> Present Designation:</label>

                                <input
                                  type="text"
                                  className="form-control"
                                  name="in_service_present_designation"
                                  value={user?.in_service_present_designation}
                                  onChange={handleChange}
                                  maxLength={150}
                                  id="name"
                                  wtx-context="5A29235D-E577-4C68-B14B-3530C4192176"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Period of Service:</label>

                                <input
                                  type="text"
                                  className="form-control"
                                  name="in_service_period_of_service"
                                  value={user?.in_service_period_of_service}
                                  onChange={handleChange}
                                  maxLength={50}
                                  id="name"
                                  wtx-context="C8A5EB6F-380E-4AC8-827F-E9632CCF19EF"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>NOC Received: Yes / No</label>

                                <select
                                  className="form-control"
                                  id="name"
                                  name="in_service_noc"
                                  value={user?.in_service_noc}
                                  onChange={handleChange}
                                  maxLength={50}
                                  wtx-context="01C63179-24F8-4D31-BEBE-1DC9BDD976B6"
                                >
                                  <option value=""> -- Select -- </option>
                                  <option value="yes">Yes</option>
                                  <option value="no">No</option>
                                </select>
                              </div>
                            </div>
                          </div>{" "}
                          <br /> <br />
                        </div>
                      </div>
                      <div className="mt-3 d-flex justify-content-between">
                        <button
                          onClick={() => {
                            setActiveStep(4);
                          }}
                          className="btn btn-danger"
                        >
                          Prev
                        </button>
                        <button
                          onClick={() => {
                            // if ( !user?.in_service_department ||
                            //      !user?.in_service_name_of_constituent_college ||
                            //      !user?.in_service_date_of_appointment ||
                            //      !user?.in_service_present_designation ||
                            //      !user?.in_service_period_of_service ||
                            //      !user?.in_service_noc
                            // ) {
                            //  return toast.error("Mandatory Fields are Required");
                            // }
                            setActiveStep(6);
                          }}
                          className="btn btn-danger"
                        >
                          Next
                        </button>
                      </div>
                    </>
                  )}

                  {activeStep == 6 && (
                    <>
                      <div className="row mt-5">
                        <div className="personal-information d-print-none">
                          <h5>
                            Please attach attested proof for the documents
                          </h5>
                          <h5>
                            Attachments
                            <span>to be uploaded in .jpg less than 1Mb</span>
                          </h5>
                          <div className="row">
                            <div className="col-md-6">
                              <InputDoc
                                lable={"UG Degree"}
                                value={user?.ug_degree_certificate}
                                important={true}
                                name={"ug_degree_certificate"}
                                onChange={(e) => {
                                  addAttachment(e, "ug_degree_certificate");
                                }}
                              />
                            </div>

                            <div className="col-md-6">
                              <InputDoc
                                lable={"PG Degree"}
                                value={user?.pg_degree_certificate}
                                important={true}
                                name={"pg_degree_certificate"}
                                onChange={(e) => {
                                  addAttachment(e, "pg_degree_certificate");
                                }}
                              />
                            </div>
                            <div className="col-md-6">
                              <InputDoc
                                lable={"ID Proof"}
                                value={user?.id_proof}
                                important={true}
                                name={"id_proof"}
                                onChange={(e) => {
                                  addAttachment(e, "id_proof");
                                }}
                              />
                            </div>
                            <div className="col-md-6">
                              <InputDoc
                                lable={"Photograph"}
                                value={user?.photograph}
                                important={true}
                                name={"photograph"}
                                onChange={(e) => {
                                  addAttachment(e, "photograph");
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-3 d-flex justify-content-between">
                        <button
                          onClick={() => {
                            setActiveStep(5);
                          }}
                          className="btn btn-danger"
                        >
                          Prev
                        </button>
                        <button
                          onClick={() => {
                            if (!checkCertificates()) {
                              return;
                            }
                            setActiveStep(7);
                          }}
                          className="btn btn-danger"
                        >
                          Next
                        </button>
                      </div>
                    </>
                  )}

                  {activeStep == 7 && (
                    <>
                      <div className="row mt-4">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="">Date:</label>

                            <input
                              type="date"
                              className="form-control"
                              name="application_submission_date"
                              value={new Date().toISOString().split("T")[0]}
                              onChange={handleChange}
                              readOnly={true}
                              disabled={true}
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="">
                              Place: <small className="text-danger">*</small>
                            </label>

                            <input
                              type="text"
                              className="form-control"
                              name="application_submission_place"
                              value={user?.application_submission_place}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                      {/* <div className="row mt-5">
                        <h3>PAYMENT DETAILS</h3>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="">Transaction ID</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Transaction ID"
                              name="student_tans_id"
                              value={user?.student_tans_id}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="">Date</label>
                            <input
                              type="date"
                              className="form-control"
                              name="student_tans_date"
                              value={user?.student_tans_date}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="">Name of the Bank</label>
                            <input
                              type="input"
                              className="form-control"
                              placeholder="Enter Bank Name"
                              name="student_tans_bank"
                              value={user?.student_tans_bank}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <InputDoc
                            lable={"Upload Bank Reciept"}
                            value={user?.student_tans_bank_reciept}
                            important={true}
                            name={"student_tans_bank_reciept"}
                            onChange={(e) => {
                              addAttachment(e, "student_tans_bank_reciept");
                            }}
                          />
                        </div>
                        <label className="col-md-12 mt-4 fs-5">
                          Application Fees : Rs 2,500/-
                        </label>
                        <label className="col-md-12 fs-5">
                          <div>Pay Offline at :</div>
                          <div>Account Name: Swaminarayan University</div>
                          <div>Savings account no :- 59218008432900</div>
                          <div>Bank: HDFC Bank( Kalol branch)</div>
                          <div>IFSC CODE:- HDFC0000894</div>
                        </label>
                      </div> */}
                      <div className="row mt-4">
                        <div className="col-md-12">
                          <p>
                            <label class="application-left-form">
                              <input
                                type="checkbox"
                                name="application_checkbox"
                                value={user?.application_checkbox}
                                onChange={(e) => {
                                  setUser((prev) => ({
                                    ...prev,
                                    application_checkbox: e.target.checked,
                                  }));
                                }}
                              />
                              <span class="checkmark"></span>{" "}
                              <span class="required">*</span> I hereby declare
                              that all the details furnished above in this
                              application are true, complete and correct to the
                              best of my knowledge and belief. In the event of
                              any of the information being found false or
                              incorrect or any ineligibility being detected
                              before or after the test / admission my
                              candidature is liable to cancelled and action will
                              be initiated against me. Further, I shall abide by
                              all the Swaminarayan University Rules and
                              regulations of PhD program that are applicable to
                              Full time / Part time / In service candidates.{" "}
                            </label>
                          </p>
                        </div>
                        <h5 className="alert alert-warning">
                          After clicking on "Submit," you will be redirected to
                          the Payment Page. This process may take some time, so
                          please wait.
                        </h5>
                      </div>
                      {/* <div className="row mt-5 d-print-none">
                    <div className="col-md-12 d-flex justify-content-between">
                      {isSubmitted ? (
                        <button
                          onClick={handlePrint}
                          className="btn btn-success px-4"
                        >
                          Print
                        </button>
                      ) : (
                        <button
                          className="btn btn-danger btn-lg float-end"
                          onClick={handleSubmit}
                        >
                          Submit
                        </button>
                      )}
                    </div>
                  </div> */}
                      <div className="mt-3 d-flex justify-content-between">
                        <button
                          onClick={() => {
                            if (
                              !user?.application_checkbox == true ||
                              !user?.application_submission_date ||
                              !user?.application_submission_place
                            ) {
                              return toast.error(
                                "Mandatory Fields are Required"
                              );
                            }
                            setActiveStep(6);
                          }}
                          className="btn btn-danger"
                        >
                          Prev
                        </button>
                        <button
                          onClick={handleSubmit}
                          className="btn btn-danger"
                        >
                          Submit
                        </button>
                      </div>
                    </>
                  )}

                  {/* <div style={{display:'none'}}>
                        <div className="" ref={printRef} >
                          transaction Id = {transactionId}
                        </div>
                      </div> */}

                  {data && data.encRequest && data.accessCode && (
                    <form
                      method="post"
                      action="https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction"
                    >
                      <input
                        type="hidden"
                        id="encRequest"
                        name="encRequest"
                        value={data.encRequest}
                      />
                      <input
                        type="hidden"
                        name="access_code"
                        id="access_code"
                        value={data.accessCode}
                      />
                      <input
                        ref={ref}
                        type="submit"
                        className="btn btn-danger"
                        value="Pay Now"
                      />
                    </form>
                  )}
                </div>

                {/* Print */}
                <div style={{ display: "none" }}>
                  <section ref={printRef}>
                    <div className="col-sm-12">
                      <table className="table">
                        <tbody>
                          <tr className="row">
                            <td className="col-sm-2">
                              <img src="/assets/images/favicon.png" alt="" />
                            </td>
                            <td
                              className="col-sm-10 text-center"
                              style={{ verticalAlign: "center" }}
                            >
                              <h2>Swaminarayan University,Kalol</h2>

                              <h4>PhD Application Form 2024-25 (Batch I)</h4>
                            </td>
                          </tr>
                          <tr className="row">
                            <td className=" col-sm-10">
                              <tr className="row">
                                <td className="col-sm-6">
                                  <p style={{ margin: "0 0 0 14px" }}>
                                    1. Application Type:{" "}
                                    <strong>
                                      {" "}
                                      {user?.application_job_type}{" "}
                                    </strong>
                                  </p>
                                </td>
                                <td className="col-sm-6">
                                  <p style={{ margin: "0 0 0 14px" }}>
                                    2. Specialization:{" "}
                                    <strong> {user?.application_for}</strong>
                                  </p>
                                </td>
                              </tr>

                              <p style={{ margin: "0 0 0 14px" }}>
                                3. Phone Number: <strong> {user?.phone}</strong>
                              </p>

                              <tr className="row">
                                <td className="col-sm-6">
                                  <p style={{ margin: "0 0 0 14px" }}>
                                    4. Specify area of specialization /
                                    Research:{" "}
                                    <strong>
                                      {" "}
                                      {user?.area_of_specialization}
                                    </strong>
                                  </p>
                                </td>
                              </tr>
                            </td>
                            <td className="col-sm-2">
                              <div className="image-container">
                                <img
                                  src={user?.photograph}
                                  alt="Candidate Image HERE"
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p>
                                5. Name of Candidate (In the format mentioned in
                                Last Marksheet):{" "}
                                <strong>{user?.candidate_name}</strong>
                              </p>

                              <tr className="row">
                                <td className="col-sm-6">
                                  <p>
                                    6. Email:<strong> {user?.email}</strong>
                                  </p>
                                </td>
                                <td className="col-sm-6">
                                  <p>
                                    7. Address: <strong>{user?.address}</strong>
                                  </p>
                                </td>
                              </tr>

                              <tr className="row">
                                <td className="col-sm-6">
                                  <p>
                                    8. City: <strong>{user?.city}</strong>
                                  </p>
                                </td>
                                <td className="col-sm-6">
                                  <p>
                                    9. State: <strong>{user?.state}</strong>
                                  </p>
                                </td>
                              </tr>

                              <tr className="row">
                                <td className="col-sm-6">
                                  <p>
                                    10. Father's Name:{" "}
                                    <strong>{user?.guardian_name}</strong>
                                  </p>
                                </td>
                                <td className="col-sm-6">
                                  <p>11. Father's Mobile: </p>
                                </td>
                              </tr>

                              <tr className="row">
                                <td className="col-sm-6">
                                  <p>
                                    12. Date of Birth:{" "}
                                    <strong>{user?.dob?.split("T")[0]}</strong>
                                  </p>
                                </td>
                                <td className="col-sm-6">
                                  <p>
                                    13. Gender: <strong>{user?.gender} </strong>
                                  </p>
                                </td>
                              </tr>

                              <tr className="row">
                                <td className="col-sm-6">
                                  <p>
                                    14. Category:{" "}
                                    <strong>{user?.category} </strong>
                                  </p>
                                </td>
                                <td className="col-sm-6">
                                  <p>
                                    15. Nationality:{" "}
                                    <strong>{user?.nationality}</strong>
                                  </p>
                                </td>
                              </tr>

                              <tr className="row">
                                <td className="col-sm-6">
                                  <p>
                                    16. Marital Status:{" "}
                                    <strong>{user?.marital_status} </strong>
                                  </p>
                                </td>
                                <td className="col-sm-6">
                                  <p>
                                    17. Blood Group:
                                    <strong> {user?.blood_group} </strong>
                                  </p>
                                </td>
                              </tr>

                              <tr className="row">
                                <td className="col-sm-6">
                                  <p>
                                    18. PAN Card No:{" "}
                                    <strong>{user?.pan} </strong>
                                  </p>
                                </td>
                                <td className="col-sm-6">
                                  <p>
                                    19. Aadhar Card No:
                                    <strong> {user?.aadhaar_no} </strong>
                                  </p>
                                </td>
                              </tr>

                              <tr className="row">
                                <td className="col-sm-6"></td>
                                <td className="col-sm-6"></td>
                              </tr>

                              <h5 className="text-center my-3">
                                Academic Details
                              </h5>
                              <table className="table table-bordered">
                                <thead>
                                  <tr>
                                    <th>SL. NO</th>
                                    <th>Exam Passed</th>
                                    <th>School / College Name</th>
                                    <th>University / Board</th>
                                    <th>Subject (Specialization)</th>
                                    <th>Year of Passing</th>
                                    <th>Percentage & Division</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>1</td>
                                    <td>Secondary (SSC)</td>
                                    <td>
                                      {user?.academic_details_ssc_college_name}
                                    </td>
                                    <td>
                                      {
                                        user?.academic_details_ssc_university_board
                                      }
                                    </td>
                                    <td>
                                      {user?.academic_details_ssc_subject}
                                    </td>
                                    <td>
                                      {
                                        user?.academic_details_ssc_year_of_passing
                                      }
                                    </td>
                                    <td>
                                      {
                                        user?.academic_details_ssc_percentage_division
                                      }
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>2</td>
                                    <td>Higher Secondary (HSC)</td>
                                    <td>
                                      {user?.academic_details_hsc_college_name}
                                    </td>
                                    <td>
                                      {
                                        user?.academic_details_hsc_university_board
                                      }
                                    </td>
                                    <td>
                                      {user?.academic_details_hsc_subject}
                                    </td>
                                    <td>
                                      {
                                        user?.academic_details_hsc_year_of_passing
                                      }
                                    </td>
                                    <td>
                                      {
                                        user?.academic_details_hsc_percentage_division
                                      }
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>3</td>
                                    <td>Under Graduate</td>
                                    <td>
                                      {user?.academic_details_ug_college_name}
                                    </td>
                                    <td>
                                      {
                                        user?.academic_details_ug_university_board
                                      }
                                    </td>
                                    <td>{user?.academic_details_ug_subject}</td>
                                    <td>
                                      {
                                        user?.academic_details_ug_year_of_passing
                                      }
                                    </td>
                                    <td>
                                      {
                                        user?.academic_details_ug_percentage_division
                                      }
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>4</td>
                                    <td>Post Graduate</td>
                                    <td>
                                      {user?.academic_details_pg_college_name}
                                    </td>
                                    <td>
                                      {
                                        user?.academic_details_pg_university_board
                                      }
                                    </td>
                                    <td>{user?.academic_details_pg_subject}</td>
                                    <td>
                                      {
                                        user?.academic_details_pg_year_of_passing
                                      }
                                    </td>
                                    <td>
                                      {
                                        user?.academic_details_pg_percentage_division
                                      }
                                    </td>
                                  </tr>
                                </tbody>
                              </table>

                              <h5 className="text-center my-3">
                                Employment details (Recent Three)
                              </h5>
                              <table className="table table-bordered">
                                <thead>
                                  <tr>
                                    <th>SL. NO</th>
                                    <th>Organization</th>
                                    <th>Duration</th>
                                    <th>Position Regular / Temporary</th>
                                    <th>Nature of Duties</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>1</td>
                                    <td>{user?.employment_1_organization}</td>
                                    <td>{user?.employment_1_duration}</td>
                                    <td>
                                      {user?.employment_1_regular_temporary}
                                    </td>
                                    <td>
                                      {user?.employment_1_nature_of_duties}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>2</td>
                                    <td>{user?.employment_2_organization}</td>
                                    <td>{user?.employment_2_duration}</td>
                                    <td>
                                      {user?.employment_2_regular_temporary}
                                    </td>
                                    <td>
                                      {user?.employment_2_nature_of_duties}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>3</td>
                                    <td>{user?.employment_3_organization}</td>
                                    <td>{user?.employment_3_duration}</td>
                                    <td>
                                      {user?.employment_3_regular_temporary}
                                    </td>
                                    <td>
                                      {user?.employment_3_nature_of_duties}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>

                              <br />
                              <br />
                              <h5 className="text-center my-5">
                                DETAILS OF RESEARCH PUBLICATION (IN SCOPUS / WOS
                                / UGC CARE)(TOP THREE)
                              </h5>
                              <table className="table table-bordered">
                                <thead>
                                  <tr>
                                    <th>SL. NO</th>
                                    <th>Title of Publication</th>
                                    <th>Name of Journal/Published</th>
                                    <th>No./Vol./Edition/Date</th>
                                    <th>National/International</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>1</td>
                                    <td>
                                      {user?.research_publication_1_title}
                                    </td>
                                    <td>
                                      {
                                        user?.research_publication_1_no_of_journal_published
                                      }
                                    </td>
                                    <td>
                                      {
                                        user?.research_publication_1_no_vol_edition_date?.split(
                                          "T"
                                        )[0]
                                      }
                                    </td>
                                    <td>
                                      {
                                        user?.research_publication_1_national_international
                                      }
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>2</td>
                                    <td>
                                      {user?.research_publication_2_title}
                                    </td>
                                    <td>
                                      {
                                        user?.research_publication_2_no_of_journal_published
                                      }
                                    </td>
                                    <td>
                                      {
                                        user?.research_publication_2_no_vol_edition_date?.split(
                                          "T"
                                        )[0]
                                      }
                                    </td>
                                    <td>
                                      {
                                        user?.research_publication_2_national_international
                                      }
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>3</td>
                                    <td>
                                      {user?.research_publication_3_title}
                                    </td>
                                    <td>
                                      {
                                        user?.research_publication_3_no_of_journal_published
                                      }
                                    </td>
                                    <td>
                                      {
                                        user?.research_publication_3_no_vol_edition_date?.split(
                                          "T"
                                        )[0]
                                      }
                                    </td>
                                    <td>
                                      {
                                        user?.research_publication_3_national_international
                                      }
                                    </td>
                                  </tr>
                                </tbody>
                              </table>

                              <h5 className="text-center my-3">
                                DETAILS OF IN-SERVICE CANDIDATES
                              </h5>

                              <tr className="row">
                                <td className="col-sm-6">
                                  <p>
                                    Name of the Department:{" "}
                                    {user?.in_service_department}
                                  </p>
                                </td>
                                <td className="col-sm-6">
                                  <p>
                                    Name of the Constituent College:{" "}
                                    {
                                      user?.in_service_name_of_constituent_college
                                    }
                                  </p>
                                </td>
                              </tr>

                              <tr className="row">
                                <td className="col-sm-6">
                                  <p>
                                    Date of Appointment:{" "}
                                    {
                                      user?.in_service_date_of_appointment?.split(
                                        "T"
                                      )[0]
                                    }
                                  </p>
                                </td>
                                <td className="col-sm-6">
                                  <p>
                                    Present Designation:{" "}
                                    {user?.in_service_present_designation}
                                  </p>
                                </td>
                              </tr>

                              <tr className="row">
                                <td className="col-sm-6">
                                  <p>
                                    Period of Service:{" "}
                                    {user?.in_service_period_of_service}
                                  </p>
                                </td>
                                <td className="col-sm-6">
                                  <p>
                                    NOC Received:{" "}
                                    {user?.in_service_noc == "yes"
                                      ? "Yes"
                                      : "No"}
                                  </p>
                                </td>
                              </tr>

                              <tr className="row">
                                <td className="col-sm-6">
                                  <p>UG Degree: Yes (Uploaded)</p>
                                </td>
                                <td className="col-sm-6">
                                  <p>PG Degree: Yes (Uploaded)</p>
                                </td>
                              </tr>

                              <tr className="row">
                                <td className="col-sm-6">
                                  <p>ID Proof : Yes (Uploaded)</p>
                                </td>
                                <td className="col-sm-6">
                                  <p>Photograph: Yes (Uploaded)</p>
                                </td>
                              </tr>

                              <h5 className="text-center my-3 ">
                                Payment Details
                              </h5>
                              <tr className="row">
                                <td className="col-sm-6">
                                  <p>Application Fees : Rs 2,500/-:</p>
                                </td>
                                <td className="col-sm-6">
                                  <p>Transaction ID: {user?.student_tans_id}</p>
                                </td>
                              </tr>

                              <p>
                                <strong>Declaration:&nbsp;</strong>
                                <br />
                                <input type="checkbox" checked />
                                &nbsp;I hereby declare that all the details
                                furnished above in this application are true,
                                complete and correct to the best of my knowledge
                                and belief. In the event of any of the
                                information being found false or incorrect or
                                any ineligibility being detected before or after
                                the test / admission my candidature is liable to
                                cancelled and action will be initiated against
                                me. Further, I shall abide by all the
                                Swaminarayan University Rules and regulations of
                                PhD program that are applicable to Full time /
                                Part time / In service candidates.
                              </p>

                              <tr className="row">
                                <td className="col-sm-6">
                                  <p>
                                    Date:{" "}
                                    {user?.student_tans_date?.split("T")[0]}
                                  </p>
                                </td>
                                <td className="col-sm-6">
                                  <p>
                                    Name of the Bank: {user?.student_tans_bank}
                                  </p>
                                </td>
                              </tr>

                              <hr />
                              <p>Place: {user?.application_submission_place}</p>
                              <p>
                                Date:{" "}
                                {
                                  user?.application_submission_date.split(
                                    "T"
                                  )[0]
                                }
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default PhdAdmissionForm;
