import React from "react";

const principalImage = "/assets/law/images/dixit-patel.jpg";

function PrincipalMessage() {
  return (
    <div>
      <>
        {/* Start For Content */}
        <div className="container mt-5 mb-5">
          <div className="row">
            <div className="col-md-3">
              <img
                src={principalImage}
                alt="pricipal-image"
                className="img-responsive img-thumbnail"
                width={261}
                height={261}
              />
              <br />
              <br />

              <h3>
                Dr. Dixit Patel
                <br />
              </h3>
              <p style={{}}>
                Dean, Faculty of Law Swaminarayan University, Kalol
              </p>
            </div>
            <div className="col-md-9">
              <h2>
                Principal's Message
                <br />
              </h2>
              <p style={{ textAlign: "justify" }}>
                Dean, Faculty of Law invite you all at Swaminarayan university.
                It has been preparing students to be responsible and dedicated
                members of the legal profession. We are a law with a proud
                tradition of world-class teaching and scholarship, academic
                excellence, experiential learning and community service. The
                knowledge and skills our students gain over three years in Green
                Hall form the foundation of their legal careers. At SU Law,
                students can be assured that the foundation they build will
                support their professional goals, whatever they might be.
                <br />
                <br />
                The learning environment at SU Law is challenging, yet
                collegial. The diversity of the student body’s backgrounds and
                opinions creates a rich classroom environment. That environment
                is supported by faculty members who are outstanding lawyers and
                who are known, nationally and internationally, as outstanding
                scholars in their fields. What makes the learning environment at
                SU Law unique, however, are the relationships between the
                students and the faculty. SU Law professors are open and
                accessible to students – and truly committed to teaching, both
                in and out of the classroom. An extensive experiential learning
                program allows students to begin their legal training by working
                in a prosecutor's office or representing inmates, serving in a
                judge’s chambers, or providing legal assistance to the
                underserved. We are committed to providing these educational
                opportunities at a reasonable rate of tuition. We encourage you
                to explore our website to learn more about our programs and all
                the ways you can connect with SU Law.
              </p>
            </div>
          </div>
          <br />
          <br />
        </div>{" "}
        {/* End For Content */}
      </>
    </div>
  );
}

export default PrincipalMessage;
