import React from 'react';

function RulesEng() {
  return (
    <div>

<div className="container mt-5 mb-5">
        <div className="row register" style={{ maxWidth: "100%" }}>
          <div className="col-md-12">
            <h3>
              Rules & Regulations
            
            </h3>
            <p>Rules and Rugulations documents Link are below</p>
          </div>

        
<div className="container">

  
<div className="alumni-area pt-100 pb-70">
<div className="row">

{/* <div className="col-md-3">
<a href="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/rules/bsc-rules.pdf" target="_blank">
  <div className="single-alumoni-updates-card">
  <div className=" d-flex flex-row flex-wrap align-items-center justify-content-around">
    <div className=" bg-danger text-center p-3 ">
    <i class="ri-file-download-line text-light " style={{fontSize:"20px"}}></i>
    </div>
   <div className="">
    <p>
      B.Sc. Rules & Regulations
    </p>
   </div>
  </div>
</div>
</a>
  </div>
  <div className="col-md-3">
<a href="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/rules/msc-rules.pdf" target="_blank">
  <div className="single-alumoni-updates-card">
  <div className=" d-flex flex-row flex-wrap align-items-center justify-content-around">
    <div className=" bg-danger text-center p-3 ">
    <i class="ri-file-download-line text-light " style={{fontSize:"20px"}}></i>
    </div>
   <div className="">
    <p>
      M.Sc. Rules & Regulations
    </p>
   </div>
  </div>
</div>
</a>
  </div> */}

</div>

</div>
</div>
         
        </div>
        <br />
        <br />
      </div>
      
    </div>
  )
}

export default RulesEng;
