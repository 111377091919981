import React from 'react'

function CampusFacilities() {
  return (
    <div className='CampusFacilities'>
        <>
  <div className="campus-life-area pt-100 pb-70">
    <div className="container">
      <h3 className="text-center mb-4 ">Campus Facilities</h3>
      <p>
        The campus is endowed with buildings constructed to provide all the
        basic amenities which are required for effective teaching- learning
        process, health care and research. The constituent college have
        established with their own separate and self contained buildings with
        administration area, teaching departments, patient wards, classrooms,
        seminar hall, library, laboratories.
      </p>
      <p className="text-justify">
        The prolific green campus of the proposed Swaminarayan University is
        situated on Ahmedabad-Mehsana National Highway, Kalol about 25 km from
        Ahmedabad in perfect harmony with nature. Being away from the city, the
        scenic location provides a revitalizing environment in which students
        can pursue their studies peacefully and without interruption. It is a
        place where tranquility acquires a new dimension and where modern
        architecture blends with traditional values to amplify the spirit of
        continuous learning.
      </p>
      <p>
        Encompassed a land area more than 21 Acres, it has state-of-the-art
        educational infrastructure, Hospital, Play-ground, well equipped
        computer labs, spacious class-rooms, huge library, air-conditioned
        conference hall, open air theatre, gigantic assembly hall, comfortable
        staff rooms, hygienic medical room and hygienic lavatories separate for
        boys and girls. On top of it, well maintained lavish recreation ground
        with flowering trees and bushes all around, making it one of the finest
        lush green campuses in town.
      </p>
      <p>
        The entire campus is covered under CCTV surveillance and 24x7 security
        service &amp; fire safety.{" "}
      </p>
    </div>
  </div>
  <div className="admisssion-area bg-f4f6f9 ptb-100">
    <div className="container-fluid">
      <div className="admission-content">
        <div className="section-title">
          <h2>Take Campus Tour</h2>
          {/* <p>Lorem ipsum dolor sit amet consectetur adipiscing elit ut elit tellus luctus nec ullamcorper mattis </p> */}
        </div>
        <div className="admission-image">
          <img src="assets/images/logo.png" alt="Image" />
          <div className="icon">
            <a
              className="popup-youtube play-btn"
              href="https://www.youtube.com/watch?v=QQEBVOoiJ1g"
            >
              <i className="ri-play-fill" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</>

    </div>
  )
}

export default CampusFacilities