export const studentData = [

    {
      "sl_no": 1,
      "name": "Vikalp Pankajbhai Kubavat",
      "college": "Ayurveda",
      "department": "Samhita & Sidhanta",
      "mobile_number": 9998258656,
      "email": "vikalp312@gmail.com",
      "usn": "PSUK230206DR030",
    },
    {
      "sl_no": 2,
      "name": "Sidheshwar Sathua",
      "college": "Ayurveda",
      "department": "Prasuti Tantra & Stree roga",
      "mobile_number": 7566514077,
      "email": "ssathua8@gmail.com",
      "usn": "PSUK230206DR031",
    },
    {
      "sl_no": 3,
      "name": "Nitin Kumar",
      "college": "Ayurveda",
      "department": "Kayachikitsa",
      "mobile_number": 9449587901,
      "email": "dr.ndy24@gmail.com",
      "usn": "PSUK230206DR032",
    },
    {
      "sl_no": 4,
      "name": "Abhitha A",
      "college": "Ayurveda",
      "department": "Roganidana & Vikruti Vigyan",
      "mobile_number": 8281473928,
      "email": "abhithaa0@gmail.com",
      "usn": "PSUK230206DR033",
    },
    {
      "sl_no": 5,
      "name": "Vyas Ruchika Shashikantbhai",
      "college": "Education",
      "department": "Education",
      "mobile_number": 7984991164,
      "email": "ruchikapandya712@gmail.com",
      "usn": "PSUK230406DR021",
    },
    {
      "sl_no": 6,
      "name": "Vankar Dineshbhai Devabhai",
      "college": "Education",
      "department": "Education",
      "mobile_number": 9979929394,
      "email": "dineshbhaivankar01@gmail.com",
      "usn": "PSUK230406DR022",
    },
    {
      "sl_no": 7,
      "name": "Prajapati Ashvinkumar Karsanbhai",
      "college": "Education",
      "department": "Education",
      "mobile_number": 9909316492,
      "email": "prajapatiashwinbhai@gmail.com",
      "usn": "PSUK230406DR023",
    },
    {
      "sl_no": 8,
      "name": "Jani Shivangiben Rameshchandra",
      "college": "Education",
      "department": "Education",
      "mobile_number": 9328491337,
      "email": "shivjani1987@gmail.com",
      "usn": "PSUK230406DR024",
    },
    {
      "sl_no": 9,
      "name": "Vadher Sumitkumar Mansukhbhai",
      "college": "Education",
      "department": "Education",
      "mobile_number": 9638814984,
      "email": "jsn.sumit@gmail.com",
      "usn": "PSUK230406DR025",
    },
    {
      "sl_no": 10,
      "name": "S.G.S.Chakravarthy",
      "college": "Homoeopathy",
      "department": "Practice of Medicine",
      "mobile_number": 9908019739,
      "email": "sgschakravarthy@gmail.com",
      "usn": "PSUK230606DR006",
    },
    {
      "sl_no": 11,
      "name": "Neeraj Tiwari",
      "college": "Homoeopathy",
      "department": "Homoeopathic Materia Medica",
      "mobile_number": 9826984956,
      "email": "drnmtiwari@gmail.com",
      "usn": "PSUK230606DR007",
    },
    {
      "sl_no": 12,
      "name": "Mansi Chanpuria",
      "college": "Homoeopathy",
      "department": "Homoeopathic Materia Medica",
      "mobile_number": 8057067895,
      "email": "mansi.chanpuria@gmail.com",
      "usn": "PSUK230606DR008",
    },
    {
      "sl_no": 13,
      "name": "Rubeena Jameel",
      "college": "Homoeopathy",
      "department": "Homoeopathic Materia Medica",
      "mobile_number": 9893955411,
      "email": "19rubeenajameel75@gmail.com",
      "usn": "PSUK230606DR009",
    },
    {
      "sl_no": 14,
      "name": "Neha Rajput",
      "college": "Homoeopathy",
      "department": "Homoeopathic Pharmacy",
      "mobile_number": 9770735494,
      "email": "drneharajput1@gmail.com",
      "usn": "PSUK230606DR010",
    },
    {
      "sl_no": 15,
      "name": "Shukla Mansi Nareshbhai",
      "college": "Homoeopathy",
      "department": "Homeopathic Materia Medica",
      "mobile_number": 9408942353,
      "email": "dr.mansishukla1987@gmail.com",
      "usn": "PSUK230606DR011",
    },
    {
      "sl_no": 16,
      "name": "Vora Rahulkumar",
      "college": "Pharmacy",
      "department": "Quality Assurance",
      "mobile_number": 9558683242,
      "email": "prvora6@gamil.com",
      "usn": "PSUK231106DR021",
    },
    {
      "sl_no": 17,
      "name": "Bhaktiben Maheshkumar Shah",
      "college": "PHARMACEUTICAL SCIENCE",
      "department": "Pharmacy",
      "mobile_number": "9099576302",
      "email": "bhaktishah011@gmail.com",
      "usn": "PSUK231106DR022",
    },
    {
      "sl_no": 18,
      "name": "Patel Manaliben Sunilbhai",
      "college": "Pharmacy",
      "department": "Pharmaceutical Quality Assurance",
      "mobile_number": 7043080085,
      "email": "krprajapati7477@gmail.com",
      "usn": "PSUK231106DR023",
    },
    {
      "sl_no": 19,
      "name": "Prajapati Krupali Rajeshbhai",
      "college": "Pharmacy",
      "department": "Pharmaceutics",
      "mobile_number": 9023294921,
      "email": "nvyas912@gmail.com",
      "usn": "PSUK231106DR024",
    },
    {
      "sl_no": 20,
      "name": "Vyas Namrata Amrishkumar",
      "college": "Pharmacy",
      "department": "Pharmaceutical Quality Assurance",
      "mobile_number": 6351481707,
      "email": "drdhara23@gmail.com",
      "usn": "PSUK231106DR025",
    },
    {
      "sl_no": 21,
      "name": "Dhara Mohanbhai Jadav",
      "college": "Pharmacy",
      "department": "Pharmacology",
      "mobile_number": 8980021600,
      "email": "hardik7487@gmail.com",
      "usn": "PSUK231106DR026",
    },
    {
      "sl_no": 22,
      "name": "Patel Hardikkumar Girishbhai",
      "college": "Pharmacy",
      "department": "Pharmaceutics",
      "mobile_number": 8766862925,
      "email": "swatihpawar92@gmail.com",
      "usn": "PSUK231106DR027",
    },
    {
      "sl_no": 23,
      "name": "Pawar Swati Haridas",
      "college": "Pharmacy",
      "department": "Pharmaceutical Chemistry",
      "mobile_number": 9825238730,
      "email": "bhavesh27586@gmail.com",
      "usn": "PSUK231106DR028",
    },
    {
      "sl_no": 24,
      "name": "Bhaveshkumar Anilkumar Patel",
      "college": "Pharmacy",
      "department": "Pharmaceutical Science",
      "mobile_number": 7016387070,
      "email": "pjanand63@gmail.com",
      "usn": "PSUK231106DR029",
    },
    {
      "sl_no": 25,
      "name": "Anand Jeel Jitendrakumar",
      "college": "Science",
      "department": "MICROBIOLOGY",
      "mobile_number": 9828792107,
      "email": "namratachoudhary2107@gmail.com",
      "usn": "PSUK231206DR034",
    },
    {
      "sl_no": 26,
      "name": "Namrata Chaudhary",
      "college": "Science",
      "department": "BOTANY",
      "mobile_number": 9723292217,
      "email": "MK.MITHA1995@GMAIL.COM",
      "usn": "PSUK231206DR035",
    },
    {
      "sl_no": 27,
      "name": "Patel Mayankkumar Kanubhai",
      "college": "Science",
      "department": "MATHS",
      "mobile_number": 9974685758,
      "email": "palakthephotographer@gmail.com",
      "usn": "PSUK231206DR036",
    },
    {
      "sl_no": 19,
      "name": "Prajapati Krupali Rajeshbhai",
      "college": "Pharmacy",
      "department": "Pharmaceutics",
      "mobile_number":7043080085,
      "email":"krprajapati7477@gmail.com",
      "usn": "PSUK231106DR024",
    },
    {
      "sl_no": 20,
      "name": "Vyas Namrata Amrishkumar",
      "college": "Pharmacy",
      "department": "Pharmaceutical Quality Assurance",
      "mobile_number":9023294921,
      "email":"nvyas912@gmail.com",
      "usn": "PSUK231106DR025",
    },
    {
      "sl_no": 21,
      "name": "Dhara Mohanbhai Jadav",
      "college": "Pharmacy",
      "department": "Pharmacology",
      "mobile_number":6351481707,
      "email":"drdhara23@gmail.com",
      "usn": "PSUK231106DR026",
    },
    {
      "sl_no": 22,
      "name": "Patel Hardikkumar Girishbhai",
      "college": "Pharmacy",
      "department": "Pharmaceutics",
      "mobile_number":8980021600,
      "email":"hardik7487@gmail.com",
      "usn": "PSUK231106DR027",
    },
    {
      "sl_no": 23,
      "name": "Pawar Swati Haridas",
      "college": "Pharmacy",
      "department": "Pharmaceutical Chemistry",
      "mobile_number":8766862925,
      "email":"swatihpawar92@gmail.com",
      "usn": "PSUK231106DR028",
    },
    {
      "sl_no": 24,
      "name": "Bhaveshkumar Anilkumar Patel",
      "college": "Pharmacy",
      "department": "Pharmaceutical Science",
      "mobile_number":9825238730,
      "email":"bhavesh27586@gmail.com",
      "usn": "PSUK231106DR029",
    },
    {
      "sl_no": 25,
      "name": "Anand Jeel Jitendrakumar",
      "college": "Science",
      "department": "MICROBIOLOGY",
      "mobile_number":7016387070,
      "email":"pjanand63@gmail.com",
      "usn": "PSUK231206DR034",
    },
    {
      "sl_no": 26,
      "name": "Namrata Chaudhary",
      "college": "Science",
      "department": "BOTANY",
      "mobile_number":9828792107,
      "email":"namratachoudhary2107@gmail.com",
      "usn": "PSUK231206DR035",
    },
    {
      "sl_no": 27,
      "name": "Patel Mayankkumar Kanubhai",
      "college": "Science",
      "department": "MATHS",
      "mobile_number":9723292217,
      "email":"MK.MITHA1995@GMAIL.COM",
      "usn": "PSUK231206DR036",
    },
    {
      "sl_no": 28,
      "name": "Patel Palakkumar Jasvantbhai",
      "college": "Science",
      "department": "ORGANIC CHEMISTRY",
      "mobile_number":9974685758,
      "email":"palakthephotographer@gmail.com",
      "usn": "PSUK231206DR037",
    },
    {
      "sl_no": 29,
      "name": "Fadiya Ripalben Jagdishkumar",
      "college": "Science",
      "department": "Microbiology",
      "mobile_number":9687518488,
      "email":"fripal.idar@gmail.com",
      "usn": "PSUK231206DR038",
    },
    {
      "sl_no": 30,
      "name": "Rathod Kavita Pradipbhai",
      "college": "Science",
      "department": "Organic chemistry",
      "mobile_number":8780271192,
      "email":"kavitarathod2905@gmail.com",
      "usn": "PSUK231206DR039",
    },
    {
      "sl_no": 31,
      "name": "Parmar Bhargavkumar Amrutlal",
      "college": "Science",
      "department": "Organic chemistry",
      "mobile_number":8160492188,
      "email":"bhargavparmar359@gmail.com",
      "usn": "PSUK231206DR040",
    },
    {
      "sl_no": 32,
      "name": "Naik Deval Dolatrai",
      "college": "Science",
      "department": "Chemistry",
      "mobile_number":8238623443,
      "email":"deval.naik@gmail.com",
      "usn": "PSUK231206DR041",
    },
    {
      "sl_no": 33,
      "name": "Hiteshkumar Vinodbhai Vaghasiya",
      "college": "Science",
      "department": "ORGANIC CHEMISTRY",
      "mobile_number":9033966843,
      "email":"vaghasiya8@gmail.com",
      "usn": "PSUK231206DR042",
    },
    {
      "sl_no": 34,
      "name": "Prachi Patel",
      "college": "Engineering",
      "department": "Computer Engineering",
      "mobile_number":8401861255,
      "email":"prachip855@gmail.com",
      "usn": "PSUK230506DR011",
    },
    {
      "sl_no": 35,
      "name": "Jigul Mukeshbhai Nimavat",
      "college": "Engineering",
      "department": "Information Technology",
      "mobile_number":8160985714,
      "email":"jigulnimavat1987@gmail.com",
      "usn": "PSUK230506DR012",
    },
    {
      "sl_no": 36,
      "name": "Suthar Dhruvi Pankajkumar",
      "college": "Engineering",
      "department": "Computer Engineering",
      "mobile_number":7096166299,
      "email":"dhruvi9suthar@gmail.com",
      "usn": "PSUK230506DR013",
    },
    {
      "sl_no": 37,
      "name": "Shah Vaidehi Dipakkumar",
      "college": "Engineering",
      "department": "Computer Engineering",
      "mobile_number":9173098129,
      "email":"vaidehishah16@gmail.com",
      "usn": "PSUK230506DR014",
    },
    {
      "sl_no": 38,
      "name": "Maheshwari Jashodaji Premji",
      "college": "Arts Humanities",
      "department": "Sanskrit",
      "mobile_number":8200543287,
      "email":"maheshwari.jashoda@gmail.com",
      "usn": "PSUK231406DR017",
    },
    {
      "sl_no": 39,
      "name": "Vyas Jagrutiben Navinchandra",
      "college": "Arts Humanities",
      "department": "Sanskrit",
      "mobile_number":9409423605,
      "email":"ravalhimanshi1@gmail.com",
      "usn": "PSUK231406DR018",
    },
    {
      "sl_no": 40,
      "name": "Kathiya Shivangiba Kirtisinh",
      "college": "Arts Humanities",
      "department": "English",
      "mobile_number":8401025696,
      "email":"kathiya4124@gmail.com",
      "usn": "PSUK231406DR019",
    },
    {
      "sl_no": 41,
      "name": "Pathak Ketan Gautambhai",
      "college": "Arts Humanities",
      "department": "Sanskrit",
      "mobile_number":9998037961,
      "email":"pathakketan1988@gmail.com",
      "usn": "PSUK231406DR020",
    },
    {
      "sl_no": 42,
      "name": "Parmar Jayesh Boghajibhai",
      "college": "Arts Humanities",
      "department": "Sanskrit",
      "mobile_number":9723535174,
      "email":"jayesh71parmar@gmail.com",
      "usn": "PSUK231406DR021",
    },
    {
      "sl_no": 43,
      "name": "Parmar Kinjalben Amrutlal",
      "college": "Arts Humanities",
      "department": "English",
      "mobile_number":8160492188,
      "email":"bhargavparmar359@gmail.com",
      "usn": "PSUK231406DR022",
    },
    {
      "sl_no": 44,
      "name": "Chavda Ranjanbahen Dilipsinh",
      "college": "Arts Humanities",
      "department": "Sanskrit",
      "mobile_number":9428852201,
      "email":"ranjanrathod85@gmail.com",
      "usn": "PSUK231406DR023",
    },
    {
      "sl_no": 45,
      "name": "Rajeshkumar Kabhabhai Jadav",
      "college": "IT Computer Science",
      "department": "IT Computer Science",
      "mobile_number":9426316155,
      "email":"rajeshjadav@yahoo.com",
      "usn": "PSUK230706DR008",
    },
    {
      "sl_no": 46,
      "name": "Yadav Dimple Premchand",
      "college": "Medical Science",
      "department": "Medical Microbiology",
      "mobile_number":9510669657,
      "email":"yadikku13@gmail.com",
      "usn": "PSUK231306DR005",
    },
  ];

