import React from 'react'

function President() {
  return (
    <div className='President'>
        
    </div>
  )
}

export default President