export const HallTicketData = [
    {
      "Roll Number": 2206221,
      "Candidate Name": "Provost",
      "Father Name": "Provost",
      "Gender": "Male",
      "Category": "GEN",
      "Faculty": "ARTS & HUMANITIES",
      "Specialization": "SANSKRIT",
      "Email": "sreeayush@gmail.com"
    },
    {
      "Roll Number": 2206015,
      "Candidate Name": "BHATT GHANSHYAM ARVINDBHAI",
      "Father Name": "Arvindbhai bhatt",
      "Gender": "Male",
      "Category": "GEN",
      "Faculty": "ARTS & HUMANITIES",
      "Specialization": "SANSKRIT",
      "Email": "ghanashyambhatt@gmail.com"
    },
    {
      "Roll Number": 2206018,
      "Candidate Name": "BHATT TEJASH RAJUBHAI",
      "Father Name": "RAJUBHAI",
      "Gender": "Male",
      "Category": "GEN",
      "Faculty": "ARTS & HUMANITIES",
      "Specialization": "SANSKRIT",
      "Email": "tezz.bhatt@gamil.com"
    },
    {
      "Roll Number": 2206016,
      "Candidate Name": "BHATT VIPULKUMAR KISHORBHAI",
      "Father Name": "KISHORBHAI",
      "Gender": "Male",
      "Category": "GEN",
      "Faculty": "ARTS & HUMANITIES",
      "Specialization": "SANSKRIT",
      "Email": "BHATTVIPUL474747@GMAIL.COM"
    },
    {
      "Roll Number": 2206014,
      "Candidate Name": "PARMAR PARESHKUMAR CHHAGANBHAI",
      "Father Name": "PARMAR CHHAGANBHAI SHAMALBHAI",
      "Gender": "Male",
      "Category": "SC",
      "Faculty": "ARTS & HUMANITIES",
      "Specialization": "ENGLISH",
      "Email": "prlparesh404@gmail.com"
    },
    {
      "Roll Number": 2206013,
      "Candidate Name": "SADHU DIVTESHBHAI ARVINDBHAI",
      "Father Name": "ARVINDBHAI",
      "Gender": "Male",
      "Category": "SEBC/OBC",
      "Faculty": "ARTS & HUMANITIES",
      "Specialization": "SANSKRIT",
      "Email": "sadhudivyesh1@gmail.com"
    },
    {
      "Roll Number": 2206017,
      "Candidate Name": "VEKARIYA ASHISH HASMUKHBHAI",
      "Father Name": "HASMUKHBHAI",
      "Gender": "Male",
      "Category": "GEN",
      "Faculty": "ARTS & HUMANITIES",
      "Specialization": "ENGLISH",
      "Email": "ashishvekariya9222@gmail.com"
    },
    {
      "Roll Number": 2206019,
      "Candidate Name": "JANI MAYUR RAJESHBHAI",
      "Father Name": "rajeshbhai",
      "Gender": "Male",
      "Category": "GEN",
      "Faculty": "ARTS & HUMANITIES",
      "Specialization": "SANSKRIT",
      "Email": "janimayur108@gmail.com"
    },
    {
      "Roll Number": 2206003,
      "Candidate Name": "DESAI SHILPA RAOSAHEB",
      "Father Name": "LATE SHREE RAOSAHEB D DESAI",
      "Gender": "Female",
      "Category": "GEN",
      "Faculty": "AYURVEDA",
      "Specialization": "PTSR",
      "Email": "drshilpadesai81@gmail.com"
    },
    {
      "Roll Number": 2206007,
      "Candidate Name": "KRISHNENDU O NAMBIAR",
      "Father Name": "Balachandran P",
      "Gender": "Female",
      "Category": "GEN",
      "Faculty": "AYURVEDA",
      "Specialization": "RSBK",
      "Email": "krishnmbiar@gmail.com"
    },
    {
      "Roll Number": 2206001,
      "Candidate Name": "PURVI VIJESH RAJPUT",
      "Father Name": "Dr. Subhash Joshi",
      "Gender": "Female",
      "Category": "GEN",
      "Faculty": "AYURVEDA",
      "Specialization": "KAYACHIKITSA",
      "Email": "pvr19912@gmail.com"
    },
    {
      "Roll Number": 2206006,
      "Candidate Name": "RAVI KANT ARORA",
      "Father Name": "SH PAWAN KUMAR ARORA",
      "Gender": "Male",
      "Category": "GEN",
      "Faculty": "AYURVEDA",
      "Specialization": "SAMHITA",
      "Email": "DRHEALTHIN76@GMAIL.COM"
    },
    {
      "Roll Number": 2206008,
      "Candidate Name": "SAYANTAN CHAKRABORTY",
      "Father Name": "subhas chakraborty",
      "Gender": "Male",
      "Category": "GEN",
      "Faculty": "AYURVEDA",
      "Specialization": "SHALYATANTRA",
      "Email": "s.chakraborty8287@gmail.com"
    },
    {
      "Roll Number": 2206005,
      "Candidate Name": "SAYEED AMEEN",
      "Father Name": "muslim khan",
      "Gender": "Male",
      "Category": "GEN",
      "Faculty": "AYURVEDA",
      "Specialization": "RSBK",
      "Email": "drameensayeed4583@gmail.com"
    },
    {
      "Roll Number": 2206004,
      "Candidate Name": "SURINDER KUMAR",
      "Father Name": "SH. HARI CHAND",
      "Gender": "Male",
      "Category": "GEN",
      "Faculty": "AYURVEDA",
      "Specialization": "RSBK",
      "Email": "drsurinderkmr@gmail.com"
    },
    {
      "Roll Number": 2206002,
      "Candidate Name": "THAKOR NARENDRASINH MUKESHJI",
      "Father Name": "Mukeshji",
      "Gender": "Male",
      "Category": "SEBC/OBC",
      "Faculty": "AYURVEDA",
      "Specialization": "SHALYATANTRA",
      "Email": "mgthakor14@gmail.com"
    },
    {
      "Roll Number": 2206062,
      "Candidate Name": "BHATIA KAMALPREET KAUR NARINDER PAL",
      "Father Name": "Narinder pal singh Bhatia",
      "Gender": "Female",
      "Category": "GEN",
      "Faculty": "COMMERCE & MANAGEMENT",
      "Specialization": "COMMERCE & MANAGEMENT",
      "Email": "riyabhatia70@gmail.com"
    },
    {
      "Roll Number": 2206059,
      "Candidate Name": "CHAITALIMUKESHKUMAR PATEL",
      "Father Name": "Mukesh Patel",
      "Gender": "Female",
      "Category": "GEN",
      "Faculty": "COMMERCE & MANAGEMENT",
      "Specialization": "COMMERCE & MANAGEMENT",
      "Email": "patelchaitali18@gmail.com"
    },
    {
      "Roll Number": 2206065,
      "Candidate Name": "DOSHI SHIVANI TARUNBHAI",
      "Father Name": "Tarun chandulal doshi",
      "Gender": "Female",
      "Category": "GEN",
      "Faculty": "COMMERCE & MANAGEMENT",
      "Specialization": "COMMERCE & MANAGEMENT",
      "Email": "shivanidoshi1992@gmail.com"
    },
    {
      "Roll Number": 2206057,
      "Candidate Name": "KUSHAL BIPIN SHAH",
      "Father Name": "Bipin K Shah",
      "Gender": "Male",
      "Category": "GEN",
      "Faculty": "COMMERCE & MANAGEMENT",
      "Specialization": "COMMERCE & MANAGEMENT",
      "Email": "kushalbshah7@gmail.com"
    },
    {
      "Roll Number": 2206063,
      "Candidate Name": "PARUL SUNIL DHINGRA",
      "Father Name": "Sunil dhingra",
      "Gender": "Female",
      "Category": "GEN",
      "Faculty": "COMMERCE & MANAGEMENT",
      "Specialization": "COMMERCE & MANAGEMENT",
      "Email": "parulchhabra95@gmail.com"
    },
    {
      "Roll Number": 2206060,
      "Candidate Name": "PATEL MANISHKUMAR KESHAVLAL",
      "Father Name": "PATEL KESHAVLAL VIRAMDAS",
      "Gender": "Male",
      "Category": "GEN",
      "Faculty": "COMMERCE & MANAGEMENT",
      "Specialization": "COMMERCE & MANAGEMENT",
      "Email": "mkpatel9111982@gmail.com"
    },
    {
      "Roll Number": 2206058,
      "Candidate Name": "PATEL NAITIK JAYKISHANBHAI",
      "Father Name": "JAYKISHANBHAI PATEL",
      "Gender": "Male",
      "Category": "GEN",
      "Faculty": "COMMERCE & MANAGEMENT",
      "Specialization": "COMMERCE & MANAGEMENT",
      "Email": "naitik.patel008@gmail.com"
    },
    {
      "Roll Number": 2206061,
      "Candidate Name": "THAKKAR UMANGI YOGESHKUMAR",
      "Father Name": "YOGESHKUMAR THAKKAR",
      "Gender": "Female",
      "Category": "GEN",
      "Faculty": "COMMERCE & MANAGEMENT",
      "Specialization": "COMMERCE & MANAGEMENT",
      "Email": "umangithakkar96@gmail.com"
    },
    {
      "Roll Number": 2206064,
      "Candidate Name": "VAISHNAV ARCHITA GORDHANBHAI",
      "Father Name": "GORDHANBHAI",
      "Gender": "Female",
      "Category": "GEN",
      "Faculty": "COMMERCE & MANAGEMENT",
      "Specialization": "COMMERCE & MANAGEMENT",
      "Email": "archita3499@gmail.com"
    },
    {
      "Roll Number": 2206070,
      "Candidate Name": "ABHINAV GUPTA",
      "Father Name": "Pramod Gupta",
      "Gender": "Male",
      "Category": "GEN",
      "Faculty": "COMPUTER SCIENCE",
      "Specialization": "COMPUTER SCIENCE",
      "Email": "kecabhinav@gmail.com"
    },
    {
      "Roll Number": 2206069,
      "Candidate Name": "KALYANI RAJENDRA PATIL",
      "Father Name": "Rajendra Badrinath Patil",
      "Gender": "Female",
      "Category": "GEN",
      "Faculty": "COMPUTER SCIENCE",
      "Specialization": "COMPUTER SCIENCE",
      "Email": "patil.kalyani611993@gmail.com"
    },
    {
      "Roll Number": 2206026,
      "Candidate Name": "BISWANATH MANDAL",
      "Father Name": "SIBA PRASAD MANDAL",
      "Gender": "Male",
      "Category": "GEN",
      "Faculty": "EDUCATION",
      "Specialization": "EDUCATION",
      "Email": "biswanathmandalsanskrit17@gmail.com"
    },
    {
      "Roll Number": 2206022,
      "Candidate Name": "BRAHMBHATT RAJDEEP BHASKARBHAI",
      "Father Name": "BHASKARBHAI J BRAHMBHATT",
      "Gender": "Male",
      "Category": "GEN",
      "Faculty": "EDUCATION",
      "Specialization": "EDUCATION",
      "Email": "rajdeepsir@yahoo.com"
    },
    {
      "Roll Number": 2206025,
      "Candidate Name": "LUSTE NIKITABEN PRAKASHBHAI",
      "Father Name": "Prakashbhai",
      "Gender": "Female",
      "Category": "GEN",
      "Faculty": "EDUCATION",
      "Specialization": "EDUCATION",
      "Email": "Nikitalustekadam@gmail.com"
    },
    {
      "Roll Number": 2206029,
      "Candidate Name": "PARMAR MADHVI HARISHBHAI",
      "Father Name": "HARISHBHAI",
      "Gender": "Female",
      "Category": "SEBC/OBC",
      "Faculty": "EDUCATION",
      "Specialization": "EDUCATION",
      "Email": "madhviparmar9222@gmail.com"
    },
    {
      "Roll Number": 2206032,
      "Candidate Name": "PATEL NEHABEN RAMAN BHAI",
      "Father Name": "Patel Raman Bhai Mohan bhai",
      "Gender": "Female",
      "Category": "SEBC/OBC",
      "Faculty": "EDUCATION",
      "Specialization": "EDUCATION",
      "Email": "nehapatel26686@gmail.com"
    },
    {
      "Roll Number": 2206031,
      "Candidate Name": "PATEL RITABEN VAGHAJIBHAI",
      "Father Name": "YAGHAJIBHAI GALBHABHAI PATEL",
      "Gender": "Female",
      "Category": "GEN",
      "Faculty": "EDUCATION",
      "Specialization": "EDUCATION",
      "Email": "ritachaudhary377@gmail.com"
    },
    {
      "Roll Number": 2206034,
      "Candidate Name": "PATEL SANKETKUMAR BABULAL",
      "Father Name": "BABULAL",
      "Gender": "Male",
      "Category": "GEN",
      "Faculty": "EDUCATION",
      "Specialization": "EDUCATION",
      "Email": "SANKETBHAI.PATEL@GMAIL.COM"
    },
    {
      "Roll Number": 2206030,
      "Candidate Name": "PATEL SHARDABEN AMRUTALAL",
      "Father Name": "AMRUTLAL KALIDAS PATEL",
      "Gender": "Female",
      "Category": "GEN",
      "Faculty": "EDUCATION",
      "Specialization": "EDUCATION",
      "Email": "sharda.rkv@gmail.com"
    },
    {
      "Roll Number": 2206027,
      "Candidate Name": "PINKAL KIRITBHAI PATEL",
      "Father Name": "KIRITBHAI PRAHALADBHAI PATEL",
      "Gender": "Female",
      "Category": "GEN",
      "Faculty": "EDUCATION",
      "Specialization": "EDUCATION",
      "Email": "pinkalp19@gmail.com"
    },
    {
      "Roll Number": 2206028,
      "Candidate Name": "PIYUSHBHAI MAHENDRABHAI GANVIT",
      "Father Name": "MAHENDRABHAI CHANDUBHAI GANVIT",
      "Gender": "Male",
      "Category": "ST",
      "Faculty": "EDUCATION",
      "Specialization": "EDUCATION",
      "Email": "piyushganvit113@gmail.com"
    },
    {
      "Roll Number": 2206023,
      "Candidate Name": "PRAJAPATI ASHISHKUMAR KANTIBHAI",
      "Father Name": "Kantibhai",
      "Gender": "Male",
      "Category": "SEBC/OBC",
      "Faculty": "EDUCATION",
      "Specialization": "EDUCATION",
      "Email": "ashishprajapati982@gmail.com"
    },
    {
      "Roll Number": 2206033,
      "Candidate Name": "SUTHAR ASHA SHANKARLAL",
      "Father Name": "Suthar Shankarlal",
      "Gender": "Female",
      "Category": "SEBC/OBC",
      "Faculty": "EDUCATION",
      "Specialization": "EDUCATION",
      "Email": "asha.suthar8604@gmail.com"
    },
    {
      "Roll Number": 2206024,
      "Candidate Name": "TIWARI NAMITA SHIVENDRA",
      "Father Name": "Shivendra Tiwari",
      "Gender": "Female",
      "Category": "GEN",
      "Faculty": "EDUCATION",
      "Specialization": "EDUCATION",
      "Email": "tiwarin31@gmail.com"
    },
    {
      "Roll Number": 2206021,
      "Candidate Name": "TUSHAR DAVE",
      "Father Name": "omdatt rajaram dave",
      "Gender": "Male",
      "Category": "GEN",
      "Faculty": "EDUCATION",
      "Specialization": "EDUCATION",
      "Email": "davetushar14390@gmail.com"
    },
    {
      "Roll Number": 2206012,
      "Candidate Name": "AMI DAVE",
      "Father Name": "Arunbhai parekh",
      "Gender": "Female",
      "Category": "GEN",
      "Faculty": "HOMOEPATHY",
      "Specialization": "REPERTORY",
      "Email": "drnirav0369@gmail.com"
    },
    {
      "Roll Number": 2206010,
      "Candidate Name": "ANITA CHOUDHARY",
      "Father Name": "G L AHIRWAL",
      "Gender": "Female",
      "Category": "SC",
      "Faculty": "HOMOEPATHY",
      "Specialization": "ORGANON OF MEDICINE",
      "Email": "KRISHNA.CH108@GMAIL.COM"
    },
    {
      "Roll Number": 2206009,
      "Candidate Name": "CHATUR PARSHOTTAMBHAI DOBARIYA",
      "Father Name": "Parshottambhai Popatbhai dobariya",
      "Gender": "Male",
      "Category": "GEN",
      "Faculty": "HOMOEPATHY",
      "Specialization": "PRACTICE OF MEDICINE",
      "Email": "dr.chaturdobariya@ymail.com"
    },
    {
      "Roll Number": 2206011,
      "Candidate Name": "NIRAJ DAVE",
      "Father Name": "Dilipbhai Dave",
      "Gender": "Male",
      "Category": "GEN",
      "Faculty": "HOMOEPATHY",
      "Specialization": "PRACTICE OF MEDICINE",
      "Email": "drnirav0369@gmail.com"
    },
    {
      "Roll Number": 2206045,
      "Candidate Name": "ADARSHKUMAR DHARMENDRAKUMAR PATEL",
      "Father Name": "Dharmendrakumar Babubhai Patel",
      "Gender": "Male",
      "Category": "GEN",
      "Faculty": "LAW",
      "Specialization": "LAW",
      "Email": "adarshpatel3110@yahoo.com"
    },
    {
      "Roll Number": 2206051,
      "Candidate Name": "AGJA CHANDRAKANTBHAI AMRUTLAL",
      "Father Name": "AGAJA AMRUTLAL BHIKHABHAI",
      "Gender": "Male",
      "Category": "SC",
      "Faculty": "LAW",
      "Specialization": "LAW",
      "Email": "agajachandrkant995@gmail.com"
    },
    {
      "Roll Number": 2206054,
      "Candidate Name": "BHAGAT SHYAMLAL SHREENATHPRASAD",
      "Father Name": "SHREENATHPRASAD",
      "Gender": "Male",
      "Category": "GEN",
      "Faculty": "LAW",
      "Specialization": "LAW",
      "Email": "SHYAMLALBHAGAT@HOTMAIL.COM"
    },
    {
      "Roll Number": 2206042,
      "Candidate Name": "BHAGWATI VIJAY SINGH RAJPUROHIT",
      "Father Name": "VIJAY SINGH HIMMATSINGH RAJPUROHIT",
      "Gender": "Female",
      "Category": "GEN",
      "Faculty": "LAW",
      "Specialization": "LAW",
      "Email": "rajpurohitbhagawati171@gmail.com"
    },
    {
      "Roll Number": 2206046,
      "Candidate Name": "BIRANJKUMAR BHARATBHAI PATEL",
      "Father Name": "Bharatbhai Ranchhodbhai Patel",
      "Gender": "Male",
      "Category": "GEN",
      "Faculty": "LAW",
      "Specialization": "LAW",
      "Email": "biranj_21884@yahoo.co.in"
    },
    {
      "Roll Number": 2206053,
      "Candidate Name": "DESAI HETALBEN MAGANBHAI",
      "Father Name": "MAGANBHAI",
      "Gender": "Female",
      "Category": "SEBC/OBC",
      "Faculty": "LAW",
      "Specialization": "LAW",
      "Email": "desaihetal400@gmail.com"
    },
    {
      "Roll Number": 2206037,
      "Candidate Name": "GEETA KESHUBHAIKATARIYA",
      "Father Name": "Keshubhai Bhikhabhai Katariya",
      "Gender": "Female",
      "Category": "SEBC/OBC",
      "Faculty": "LAW",
      "Specialization": "LAW",
      "Email": "geetakatariya6@gmail.com"
    },
    {
      "Roll Number": 2206049,
      "Candidate Name": "HARDIK P MEHTA",
      "Father Name": "PINAKIN MEHTA",
      "Gender": "Male",
      "Category": "GEN",
      "Faculty": "LAW",
      "Specialization": "LAW",
      "Email": "hpmehta75@yahoo.com"
    },
    {
      "Roll Number": 2206039,
      "Candidate Name": "JIGISHUKUMAR DHRUVEBHAI MEHTA",
      "Father Name": "DHRUVEBHAI MEHTA",
      "Gender": "Male",
      "Category": "GEN",
      "Faculty": "LAW",
      "Specialization": "LAW",
      "Email": "jigishumehta@yahoo.com"
    },
    {
      "Roll Number": 2206040,
      "Candidate Name": "KRUNAL SURESHKUMAR TRIVEDI",
      "Father Name": "sureshkumar trivedi",
      "Gender": "Male",
      "Category": "GEN",
      "Faculty": "LAW",
      "Specialization": "LAW",
      "Email": "krunaltrivedi89@gmail.com"
    },
    {
      "Roll Number": 2206050,
      "Candidate Name": "MANISH PRADHYUMAN PUROHIT",
      "Father Name": "Pradhyuman Purohit",
      "Gender": "Male",
      "Category": "GEN",
      "Faculty": "LAW",
      "Specialization": "LAW",
      "Email": "mppurohit75@gmil.com"
    },
    {
      "Roll Number": 2206048,
      "Candidate Name": "MARAKANAKANTIBHAIBHIKHABH AI",
      "Father Name": "Bhikhbhai Keshabhai Marakana",
      "Gender": "Male",
      "Category": "GEN",
      "Faculty": "LAW",
      "Specialization": "LAW",
      "Email": "kmarakana@yahoo.com"
    },
    {
      "Roll Number": 2206044,
      "Candidate Name": "PANDYA PRATIBHA RASIKLAL",
      "Father Name": "RASIKLAL HARDEV PANDYA",
      "Gender": "Female",
      "Category": "GEN",
      "Faculty": "LAW",
      "Specialization": "LAW",
      "Email": "pratibhapandya773@gmail.com"
    },
    {
      "Roll Number": 2206036,
      "Candidate Name": "PAVRA KALPESHKUMAR PACHANBHAI",
      "Father Name": "PACHANBHAI",
      "Gender": "Male",
      "Category": "SEBC/OBC",
      "Faculty": "LAW",
      "Specialization": "LAW",
      "Email": "KALPESHPAVRA5767@GMAIL.COM"
    },
    {
      "Roll Number": 2206038,
      "Candidate Name": "SHELIYA JAYKISHAN BHARATBHAI",
      "Father Name": "BHARATBHAI",
      "Gender": "Male",
      "Category": "GEN",
      "Faculty": "LAW",
      "Specialization": "LAW",
      "Email": "Sheliyakishan@gmail.com"
    },
    {
      "Roll Number": 2206035,
      "Candidate Name": "SINGH VINOD KUMAR",
      "Father Name": "PREMNATH SINGH",
      "Gender": "Male",
      "Category": "GEN",
      "Faculty": "LAW",
      "Specialization": "LAW",
      "Email": "thakurvinodsingh36@gmail.com"
    },
    {
      "Roll Number": 2206052,
      "Candidate Name": "SONI MANISHABEN KANAIYALAL",
      "Father Name": "KANAIYALAL",
      "Gender": "Female",
      "Category": "GEN",
      "Faculty": "LAW",
      "Specialization": "LAW",
      "Email": "MANU8511516120@GMAIL.COM"
    },
    {
      "Roll Number": 2206043,
      "Candidate Name": "SUKESHKUMAR CHAMPAKLAL NINAMA",
      "Father Name": "CHAMPAKLAL NINAMA",
      "Gender": "Male",
      "Category": "ST",
      "Faculty": "LAW",
      "Specialization": "LAW",
      "Email": "sukeshninama207@gmail.com"
    },
    {
      "Roll Number": 2206047,
      "Candidate Name": "TKARSH N BAROT",
      "Father Name": "NARAYANBHAI BAROT",
      "Gender": "Male",
      "Category": "SEBC/OBC",
      "Faculty": "LAW",
      "Specialization": "LAW",
      "Email": "utkarshbarot84@gmail.com"
    },
    {
      "Roll Number": 2206041,
      "Candidate Name": "TRIVEDI DISHA ARUNBHAI",
      "Father Name": "ARUNBHAI NARHARIPRASAD TRIVEDI",
      "Gender": "Female",
      "Category": "GEN",
      "Faculty": "LAW",
      "Specialization": "LAW",
      "Email": "dishafingerprint@gmail.com"
    },
    {
      "Roll Number": 2206055,
      "Candidate Name": "VALA JAYDEEPBHAI RANJITBHAI",
      "Father Name": "RANJITBHAI PRATAPBHAI VALA",
      "Gender": "Male",
      "Category": "SEBC/OBC",
      "Faculty": "LAW",
      "Specialization": "LAW",
      "Email": "jvala95@gmail.com"
    },
    {
      "Roll Number": 2206056,
      "Candidate Name": "VYAS AKSHAT MILANKUMAR",
      "Father Name": "VYAS MILANKUMAR RAMNIKLAL",
      "Gender": "Male",
      "Category": "GEN",
      "Faculty": "LAW",
      "Specialization": "LAW",
      "Email": "akshatvyass99@gmail.com"
    },
    {
      "Roll Number": 2206068,
      "Candidate Name": "MALI RAVINDRA GANGARAM",
      "Father Name": "GANGARAM DODHU MAHAJAN",
      "Gender": "Male",
      "Category": "SEBC/OBC",
      "Faculty": "PHARMACY",
      "Specialization": "PHARMACOGNOSY",
      "Email": "rgmali1973@gmail.com"
    },
    {
      "Roll Number": 2206067,
      "Candidate Name": "MEHTA MANISHA KANTILAL",
      "Father Name": "KANTILAL PADAMJI MEHTA",
      "Gender": "Female",
      "Category": "GEN",
      "Faculty": "PHARMACY",
      "Specialization": "PHARMACEUTICS",
      "Email": "mrpande2505@gmail.com"
    },
    {
      "Roll Number": 2206066,
      "Candidate Name": "SHUKLA SAURABHKUMAR KAUSHIKKUMAR",
      "Father Name": "Shukla Kaushikumar Bholanath",
      "Gender": "Male",
      "Category": "GEN",
      "Faculty": "PHARMACY",
      "Specialization": "QUALITY ASSURANCE",
      "Email": "saurabhshukla2195@gmail.com"
    },
    {
      "Roll Number": 2206083,
      "Candidate Name": "AYUSHI SINGH",
      "Father Name": "Chandrashekhar singh",
      "Gender": "Female",
      "Category": "GEN",
      "Faculty": "SCIENCE",
      "Specialization": "BOTANY",
      "Email": "as7373500@gmail.com"
    },
    {
      "Roll Number": 2206078,
      "Candidate Name": "BASIYA NILAMBA RAJESHKUMAR",
      "Father Name": "Rajeshkumar Basiya",
      "Gender": "Female",
      "Category": "GEN",
      "Faculty": "SCIENCE",
      "Specialization": "MICROBIOLOGY",
      "Email": "nilambabasiya.5@gmail.com"
    },
    {
      "Roll Number": 2206082,
      "Candidate Name": "HIMADRI ARUNBHAI PATEL",
      "Father Name": "ARUNBHAI NANUBHAI PATEL",
      "Gender": "Female",
      "Category": "SEBC/OBC",
      "Faculty": "SCIENCE",
      "Specialization": "CHEMISTRY",
      "Email": "himadri771238@gmail.com"
    },
    {
      "Roll Number": 2206075,
      "Candidate Name": "KHATRI JEEL ANILBHAI",
      "Father Name": "KHATRI ANILBHAI BHIKHABHAI",
      "Gender": "Female",
      "Category": "Other",
      "Faculty": "SCIENCE",
      "Specialization": "BOTANY",
      "Email": "jeel.khatri8@gmail.com"
    },
    {
      "Roll Number": 2206071,
      "Candidate Name": "KIRANSINH GANPATSINH SOLANKI",
      "Father Name": "Ganpatsinh Himantsinh Solanki",
      "Gender": "Male",
      "Category": "GEN",
      "Faculty": "SCIENCE",
      "Specialization": "CHEMISTRY",
      "Email": "kgs4u1@gmail.com"
    },
    {
      "Roll Number": 2206080,
      "Candidate Name": "MAHESANIYA NIPULKUMAR HASMUKHLAL",
      "Father Name": "HASMUKHLAL",
      "Gender": "Male",
      "Category": "PH",
      "Faculty": "SCIENCE",
      "Specialization": "CHEMISTRY",
      "Email": "NIPUL.MAHESANIYA@GMAIL.COM"
    },
    {
      "Roll Number": 2206081,
      "Candidate Name": "PARMAR DEVENKUMAR JAMNADAS",
      "Father Name": "JAMNADAS HIRALAL PARMAR",
      "Gender": "Male",
      "Category": "GEN",
      "Faculty": "SCIENCE",
      "Specialization": "CHEMISTRY",
      "Email": "devenparmar27@gmail.com"
    },
    {
      "Roll Number": 2206074,
      "Candidate Name": "PATEL DHARA KANAIYALAL",
      "Father Name": "KANAIYALAL",
      "Gender": "Female",
      "Category": "GEN",
      "Faculty": "SCIENCE",
      "Specialization": "BOTANY",
      "Email": "dharapatel7299@gmail.com"
    },
    {
      "Roll Number": 2206072,
      "Candidate Name": "PATEL POOJABEN MAHENDRAKUMAR",
      "Father Name": "Patel Mahendrakumar Narsinhbhai",
      "Gender": "Female",
      "Category": "GEN",
      "Faculty": "SCIENCE",
      "Specialization": "CHEMISTRY",
      "Email": "patelpooja10795@gmail.com"
    },
    {
      "Roll Number": 2206077,
      "Candidate Name": "PRAJAPATI JYOTI RATIRAM",
      "Father Name": "RATIRAM PRAJAPATIT",
      "Gender": "Female",
      "Category": "SEBC/OBC",
      "Faculty": "SCIENCE",
      "Specialization": "BOTANY",
      "Email": "jyotiprajapti98@gmail.com"
    },
    {
      "Roll Number": 2206073,
      "Candidate Name": "SHARMA PRIYANSHI ATISH",
      "Father Name": "Atish Sharma",
      "Gender": "Female",
      "Category": "GEN",
      "Faculty": "SCIENCE",
      "Specialization": "CHEMISTRY",
      "Email": "priyanshi011998@gmail.com"
    },
    {
      "Roll Number": 2206076,
      "Candidate Name": "SONIKA SHEORAN",
      "Father Name": "Ishwarsingh Sheoran",
      "Gender": "Female",
      "Category": "GEN",
      "Faculty": "SCIENCE",
      "Specialization": "MICROBIOLOGY",
      "Email": "sheoran18sonika@gmail.com"
    },
    {
      "Roll Number": 2206079,
      "Candidate Name": "SUPRIYA S KALLIANPUR",
      "Father Name": "Rohit A Gokarn (Husband)",
      "Gender": "Female",
      "Category": "GEN",
      "Faculty": "SCIENCE",
      "Specialization": "BOTANY",
      "Email": "supzkallianpur@gmail.com"
    },
    {
      "Roll Number": 2206020,
      "Candidate Name": "Joshi Aman Rakeshkumar",
      "Father Name": "Joshi Rakeshkumar Ratilal",
      "Gender": "Male",
      "Category": "GEN",
      "Faculty": "Arts Humanities",
      "Specialization": "Arts Humanities",
      "Email": "joshiaman806@gmail.com"
    },
    {
      "Roll Number": 2206084,
      "Candidate Name": "Chinmay Bhuta",
      "Father Name": "Haresh Bhuta",
      "Gender": "Male",
      "Category": "GEN",
      "Faculty": "Commerce Management",
      "Specialization": "Commerce Management",
      "Email": "cbhuta@gmail.com"
    }
   ]