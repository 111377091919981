import React from 'react'

function AdminStaff() {
  return (
<div className="Notifications px-5">
  
  <div className="courses-details-area pt-100 pb-70">
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          <div className="courses-details">
            <div className="courses-card">
              <h2 className="text-center">Admin  Staff</h2>
            </div>
            <div className="description">
             <table className="table table-bordered table-hover">
      <thead>

      <tr>
    <th>Sr. No</th>
    <th>Names</th>
    <th>Department</th>
    <th>Designation</th>
    <th>QUALIFICATION</th>
    <th>WORK EXPERIENCE</th>
    <th>JOINING DATE</th>
    <th>DATE OF BIRTH</th>
  </tr>
      </thead>
<tbody>
    <tr>
        <td>01</td>
        <td>Mr. Prakashbhai</td>
        <td></td>
        <td></td>
        <td></td>

        <td></td>
        <td></td>
        <td></td>

    </tr>
    <tr>
        <td>02</td>
        <td>Mr.Kuldeep Patel</td>
        <td></td>
        <td></td>
        <td></td>

        <td></td>
        <td></td>
        <td></td>

    </tr><tr>
        <td>03</td>
        <td>Ms. Riddhi Shukla</td>
        <td></td>
        <td></td>
        <td></td>

        <td></td>
        <td></td>
        <td></td>

    </tr><tr>
        <td>04</td>
        <td>Mrs.Jeny Dalal</td>
        <td></td>
        <td></td>
        <td></td>

        <td></td>
        <td></td>
        <td></td>

    </tr><tr>
        <td>05</td>
        <td>Ms.Nikita Joshi</td>
        <td></td>
        <td></td>
        <td></td>

        <td></td>
        <td></td>
        <td></td>

    </tr><tr>
        <td>06</td>
        <td>Mrs.Pushpa Vasava</td>
        <td></td>
        <td></td>
        <td></td>

        <td></td>
        <td></td>
        <td></td>

    </tr><tr>
        <td>07</td>
        <td>Mr.Mohan Rabari</td>
        <td></td>
        <td></td>
        <td></td>

        <td></td>
        <td></td>
        <td></td>

    </tr><tr>
        <td>08</td>
        <td>Mr.Sunil Thakor</td>
        <td></td>
        <td></td>
        <td></td>

        <td></td>
        <td></td>
        <td></td>

    </tr><tr>
        <td>09</td>
        <td>Ms.Bharti Tapodhan</td>
        <td></td>
        <td></td>
        <td></td>

        <td></td>
        <td></td>
        <td></td>

    </tr><tr>
        <td>10</td>
        <td>Mrs.Vanita Patel</td>
        <td></td>
        <td></td>
        <td></td>

        <td></td>
        <td></td>
        <td></td>

    </tr><tr>
        <td>11</td>
        <td>Mrs.Himani Patel</td>
        <td></td>
        <td></td>
        <td></td>

        <td></td>
        <td></td>
        <td></td>

    </tr><tr>
        <td>12</td>
        <td>Mr.Paresh </td>
        <td></td>
        <td></td>
        <td></td>

        <td></td>
        <td></td>
        <td></td>

    </tr><tr>
        <td>13</td>
        <td>Mr.Vijay Vaghela</td>
        <td></td>
        <td></td>
        <td></td>

        <td></td>
        <td></td>
        <td></td>

    </tr><tr>
        <td>14</td>
        <td>Mr.Vijay Vaghela</td>
        <td></td>
        <td></td>
        <td></td>

        <td></td>
        <td></td>
        <td></td>

    </tr><tr>
        <td>15</td>
        <td>Mr. Raju Kandoi</td>
        <td></td>
        <td></td>
        <td></td>

        <td></td>
        <td></td>
        <td></td>

    </tr><tr>
        <td>16</td>
        <td>Mr. Pramod Rajput</td>
        <td></td>
        <td></td>
        <td></td>

        <td></td>
        <td></td>
        <td></td>

    </tr><tr>
        <td>17</td>
        <td>Mrs. Jayaben</td>
        <td></td>
        <td></td>
        <td></td>

        <td></td>
        <td></td>
        <td></td>

    </tr><tr>
        <td>18</td>
        <td>Mrs. Chanchiben</td>
        <td></td>
        <td></td>
        <td></td>

        <td></td>
        <td></td>
        <td></td>

    </tr><tr>
        <td>19</td>
        <td>Mr.Nayan  Gadhavi</td>
        <td></td>
        <td></td>
        <td></td>

        <td></td>
        <td></td>
        <td></td>

    </tr><tr>
        <td>20</td>
        <td>MR.Jasmin Patel</td>
        <td></td>
        <td></td>
        <td></td>

        <td></td>
        <td></td>
        <td></td>

    </tr><tr>
        <td>21</td>
        <td>MR. Mohit Vaishnav</td>
        <td></td>
        <td></td>
        <td></td>

        <td></td>
        <td></td>
        <td></td>

    </tr><tr>
        <td>22</td>
        <td>MS.Monali Prajapati</td>
        <td></td>
        <td></td>
        <td></td>

        <td></td>
        <td></td>
        <td></td>

    </tr><tr>
        <td>23</td>
        <td>MR.Ilesh Patel</td>
        <td></td>
        <td></td>
        <td></td>

        <td></td>
        <td></td>
        <td></td>

    </tr>
</tbody>
             </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
  )
}

export default AdminStaff