import React from "react";
import axios from "axios";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ADMISSION_NOTIFICATIONS_GET } from "../../Utils/InfoUploadingConst";

function AdmissionNotifications() {

    const [data, setData] = useState([]);

    const navigate = useNavigate();

    const getData = async () => {
        const config = {
            method: "get",
            url: ADMISSION_NOTIFICATIONS_GET,
            headers: {
                "Content-Type": "application/json",
            },
        };

        await axios(config)
            .then((res) => {
                res.data.data.sort((a, b) => b.id - a.id);
                res.data.data.forEach((element) => {
                    element.attachments = JSON.parse(element.attachments);
                });
                console.log(res.data.data)
                setData(res.data.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        getData();
    }, []);

    const next = async (id, data1, images) => {
        console.log("error here");
        navigate(`/Homoeopathy/AdmissionNotifications/${id}`, {
            state: {
                images: images,
                data: data1,
            },
        });
    };

    return (
        <div>
            <div className="container register" style={{maxWidth:"1200px"}}>
                <div className="row">
                    <div class="card-header">
                        <h1 class="text-danger"> Admission Notifications </h1>
                    </div>
                    <table className="table table-bordered table-hover">
                       <thead>
                       <tr>
                            <th>Sl No</th>
                            <th>Date</th>
                            <th>Faculty</th>
                            <th>Department</th>
                            <th>Notification</th>                           
                            <th>Last Date</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                       </thead>
                        <tbody>

                            {
                                data && data?.map((item, key) => {
                                    return (


                                        <tr>
                                            <td>{key+1}</td>
                                            <td>{item?.date}</td>
                                            <td>{item?.title}</td>
                                            <td>{item?.faculty}</td>
                                            <td>{item?.department}</td>
                                            <td>{item?.lastdate}</td>
                                           
                                            <td>{item?.status== "ACTIVE" ? <span class="text-success blink"> Apply Now  </span> : <span class="text-danger"> Closed  </span> }</td>
                                            <td><a href={item?.attachments} target="_blank"><button className="btn btn-success mt-2">View</button></a></td>
                                        </tr>

                                    )
                                })
                            }
                        </tbody>
                    </table>



                </div>
            </div>
        </div>
    )
}

export default AdmissionNotifications;
