import React from "react";

export default function Eligibility() {
  return (
    <div>
      <div className="container mt-5 mb-5">
        <div className="row register" style={{ maxWidth: "100%" }}>
          <div className="col-md-12">
            <p>
              <span style={{ fontSize: "18pt" }}>
                <h3>Eligibility</h3>
              </span>
              <br />
            </p>

            <p>
              <strong>Eligibility Details:</strong>
              <br />
            </p>
            <table
              className="table table-bordered"
              style={{ borderCollapse: "collapse", width: "100%", height: 173 }}
              border={1}
            >
              <tbody>
                <tr style={{ height: 20 }}>
                  <td>
                    <strong>Sr No</strong>
                    <br />
                  </td>
                  <td>
                    <strong>Course Name</strong>
                    <br />
                  </td>
                  <td>
                    <strong>Eligibility</strong>
                    <br />
                  </td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td>
                    1
                    <br />
                  </td>
                  <td>
                    LLB
                    <br />
                  </td>
                  <td>
                    45% in any Bachelor degree for general caste and EWS , for
                    SC, ST 40%, OBC 42% in any Bachelor degree
                  </td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td>
                    2
                    <br />
                  </td>
                  <td>
                    LLM
                    <br />
                  </td>
                  <td>
                    Candidate Shall have passed his LLB in concerned subject
                    <br />
                  </td>
                </tr>

                <tr style={{ height: 20 }}>
                  <td>3</td>
                  <td>Ph.D.</td>
                  <td>55% in LLM degree for reservation 5% relaxation</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
