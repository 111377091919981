import React from "react";
import { Http } from "../../Services/Services";
import { toast } from "react-toastify";
import Loader from "../../Components/Loader/Loader";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../Router/RouterConfig";
import { STUDENTBYID, STUDENT_SESSION } from "../../Utils/apiConst";
import ProgressBar from "../../Components/ProgressBar/ProgressBar";
import { useState, useEffect } from "react";
import { PREVIOUS_YEAR_DUE_FEES, HOSTEL_FEES } from "../../Utils/feeapiConst";

function StudentDueFees() {
  const navigate = useNavigate();

  const [data, setData] = useState([]);

  const [feetype, setFeetype] = useState();

  const [loading, setLoading] = useState(0);

  const [value, setValue] = useState(0);

  const [flag1, setFlag1] = useState(false);

  const [flag, setFlag] = useState(false);

  const [hostelData, sethostelData] = useState([]);

  const [x, setX] = useState(true);

  const [user, setUser] = useState({
    name: "",
    user_id: "",
    amount: "",
    email: "",
    college_id: "",
    phone: "",
    department_id: "",
    program_id: "",
    city: "",
    address: "",
    state: "Gujarat",
    country: "India",
    pincode: "",
  });

  const clearData = () => {
    setUser({
      name: "",
      user_id: "",
      email: "",
      college_id: "",
      phone: "",
      program_id: "",
      address: "",
      city: "",
      state: "Gujarat",
      country: "India",
      pincode: "",
    });
  };

  const getData = async () => {
    if (user?.user_id == "") {
      toast.error("Please Enter Valid Enrollment Number");
      return;
    }
    setLoading(1);

    await Http.get(`${STUDENTBYID}/${user?.user_id}`)
      .then(async (res) => {
        console.log("Student Info", res.data.data.name);
        const userData = res.data.data;
        await setUser((prev) => ({
          ...prev,
          name: userData.name,
          user_id: userData.user_id,
          email: userData.email,
          college_id: userData.college_id,
          program_id: userData.program_id,
          phone: userData.phone,
          department_id: userData.department_id,
          city: userData.city,
          address: userData.address,
          pincode: userData.pincode,
        }));
        console.log(user);
        setFlag(true);
        setFlag1(true);
        setValue(1);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Please Enter Valid Enrollment Number");
        return;
      });

    await Http.get(`${PREVIOUS_YEAR_DUE_FEES}/${user?.user_id}`)
      .then((res) => {
        console.log("Academic Fees Data", res.data.data);
        setData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });

    await Http.get(`${STUDENT_SESSION}?student_id=${user?.user_id}`)
      .then(async (res) => {
        console.log(res.data.data);
        res.data.data.forEach(async (element) => {
          console.log(element.data.id);
          await Http.get(`${HOSTEL_FEES}?student_id=${element.data.id}`)
            .then((res) => {
              let i = 0;
              res.data.data.forEach((item) => {
                let amt = 0;
                item?.payment.forEach((item1) => {
                  amt += parseInt(item1?.payment_amount);
                });
                res.data.data[i].paid_amount = amt;
                i++;
              });
              console.log("Updated", res.data.data);
              sethostelData(res.data.data);
              console.log(hostelData);
            })
            .catch((err) => {
              console.log(err);
            });
        });
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(0);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const route = () => {
    setFlag(false);
    setValue(0);
    setFlag1(false);
    clearData();
  };

  const handleSubmit = async () => {
    if (!user?.amount) {
      toast.error("Please Enter Valid Amount");
      return;
    }
    navigate(`${ROUTES.StudentFeePayment}`, {
      state: {
        data: user,
      },
    });
  };

  return (
    <div className="container-fluid">
      <Loader loading={loading} />
      <div className="card-body">
        <div className="row">
          <div className="col-12 d-flex justify-content-center mb-5">
            <img
              src="/assets/images/white-logo.png"
              style={{ width: "300px", margin: "auto" }}
              alt=""
            />
          </div>
          <div className="col-md-12 d-flex justify-content-center ">
            <h1>Payment Of Due Fees Year Wise</h1>
          </div>
          <hr />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <ProgressBar value={value} />
          </div>
          <div
            className=" 
          mt-4 mx-2 mb-4 d-flex justify-content-center align-items-bottom"
            style={{ gap: "20px" }}
          >
            <div className="form-group" style={{ marginTop: "2rem" }}>
              <label htmlFor="">
                Enrollment Number <small className="text-danger">*</small>{" "}
              </label>
              <input
                type="text"
                readOnly={flag ? true : false}
                name="user_id"
                placeholder="Starts with SUK.."
                className="form-control"
                value={user?.user_id}
                onChange={handleChange}
              />
            </div>
            {flag1 == false ? (
              <div className="mt-auto ml-4">
                <button
                  className=" btn btn-danger btn-lg"
                  type="submit"
                  name="submit"
                  value="collect"
                  onClick={getData}
                >
                  <i className="fa fa-search" aria-hidden="true" /> Search
                </button>
              </div>
            ) : null}
          </div>

          {flag1 ? (
            <div className="col-md-12 mt-3 p-4">
              <div className="form-group">
                <label>Previous Year Due Fees</label>
              </div>
              <table className="table table-bordered text-center">
                <thead>
                  <tr>
                    <th colSpan={1}>Session</th>
                    <th colSpan={3}>Academic Fees</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th></th>
                    <th>Fee Type</th>
                    <th>Amount</th>
                    <th>Paid</th>
                    <th>Balance</th>
                    <th>Action</th>
                  </tr>
                  {data?.fee &&
                    data?.fee?.map((item, key) => (
                      <tr>
                        <td>{item?.session_id}</td>
                        <td>{item?.fee_type_id}</td>
                        <td>{item?.amount}</td>
                        <td>{item?.paid_amount}</td>
                        <td>{item?.amount - item.paid_amount}</td>
                        <td>
                          <button className="btn btn-primary btn-sm">
                            Pay
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          ) : null}

          {!x ? (
            <>
              <div className="p-4">
                <p className="alert alert-danger">No Fees Pending</p>
              </div>
            </>
          ) : null}

          {flag1 ? (
            <div className="row mt-3 p-4">
              {" "}
              <div className="col-md-4 mt-4">
                <div className="form-group">
                  <label htmlFor=""> Select Type Of Fee</label>
                  <select
                    onChange={(e) => setFeetype(e.target.value)}
                    className="form-control"
                  > 
                    <option value="">Select Type</option>
                    <option value="College">College Fees</option>
                    <option value="Hostel">Hostel Fees</option>
                  </select>
                </div>
              </div>
              {feetype ? (
                <>
                  <div className="col-md-4 mt-4">
                    <div className="form-group">
                      <label htmlFor=""> Total Payable Fee</label>
                      <input
                        className="form-control"
                        value={
                          feetype == "College"
                            ? data?.reduce((acc, it) => acc + it.balance, 0)
                            : feetype == "Hostel"
                              ? hostelData?.reduce(
                                (acc, it) => acc + it.amount - it.paid_amount,
                                0
                              )
                              : null
                        }
                        readOnly={true}
                      />
                    </div>
                  </div>
                  <div className="col-md-4 mt-4">
                    <div className="form-group">
                      <label htmlFor=""> Enter Amount</label>
                      <input
                        className="form-control"
                        name="amount"
                        value={user?.amount}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          ) : null}

          <div className="d-flex my-3 mx-2" style={{ gap: "10px" }}>
            {flag1 ? (
              <>
                <button
                  className="mr-3 btn btn-danger btn-lg float-end"
                  onClick={handleSubmit}
                >
                  Save & Continue
                </button>

                <button
                  className="mr-3 btn btn-danger btn-lg float-end"
                  onClick={() => route()}
                >
                  Go Back
                </button>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudentDueFees;
