import React from 'react'
import {Link} from 'react-router-dom'

function Footer() {
  return (
    <div className='Footer d-print-none'>
        <>
  <div className="footer-area pt-100 pb-70">
    <div className="container">
      <div className="row">
        <div className="col-lg-4 col-sm-6">
          <div className="footer-logo-area">
            <a href="/">
              <img src="../assets/images/logo-1.png" alt="Image" />
            </a>
            <p>
              Shree Swaminarayan Vishvamangal Gurukul,
              <br />
              Ahmedabad-Mehsana Highway,
              <br /> At &amp; Po-Saij, Tal-Kalol,
              <br /> Dist. Gandhinagar - 382725, Gujarat
            </p>
            <div className="contact-list">
              <ul>
                <li>
                  <a href="tel:+916357675561">+91 6357675561</a>&nbsp;{" "}
                  <a href="tel:18008432900">1800-843-2900</a>
                </li>
                <li>
                  <a href="mailto:provost@swaminarayanuniversity.ac.in">
                    info@swaminarayanuniversity.ac.in
                  </a>
                </li>
              </ul>
              <div className="col-lg-6 col-md-4">
            <div className="social-content"><br/>
              <ul>
                
                <li>
                  <a
                    href="https://www.facebook.com/swaminarayanuniversity"
                    target="_blank"  className='mr-5' style={{marginRight:"1rem"}}
                  >
                    <i className="ri-facebook-fill " />
                  </a> 
                  
                  <a
                    href="https://www.instagram.com/swaminarayan_university/"
                    target="_blank" className='mr-5' style={{marginRight:"1rem"}}
                  >
                    <i className="ri-instagram-fill " />
                  </a>
                  
                   
                  <a
                    href="https://www.linkedin.com/in/swaminarayan-university-kalol-gandhinagar-aa46a7274/"
                    target="_blank" className='mr-5' style={{marginRight:"1rem"}}
                  >
                    <i className="ri-linkedin-fill " />
                  </a> 
                </li>
               
                
              </ul>
            </div>
          </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-sm-6">
          <div className="footer-widjet">
            <h3>Quick Links</h3>
            <div className="list">
              <ul>
              <li>
                  <a href="https://ums.swaminarayanuniversity.ac.in/" target="_blank">UMS Login</a>
                </li>
                <li>
                  <a href="https://student.swaminarayanuniversity.ac.in/" target="_blank">Student Login</a>
                </li>
                <li>
                  <a href="#">Alumni</a>
                </li>
                <li>
                  <a href="#">IQAC</a>
                </li>
                <li>
                  <a href="#">Job Opportunities</a>
                </li>
                <li>
                  <a href="#">Fee Submission</a>
                </li>
                <li>
                  <a href="#">Grievance Form</a>
                </li>
                <li>
                  <a href="#">NIRF</a>
                </li>
                <li>
                  <a href="#">Training &amp; Placement</a>
                </li>
                <li>
                  <a href="#">Student Feedback</a>
                </li>
                <li className='nav-item'>
                <Link to={'/PrivacyPolicy'} className="nav-link">
                          Privacy Policy
                </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-lg-2 col-sm-6">
          <div className="footer-widjet">
            <h3>Quick Links</h3>
            <div className="list">
              <ul>
                <li>
                  <a href="#">MHRD</a>
                </li>
                <li>
                <Link to={'/Media'} className="nav-link">
                          Media Coverage
                        </Link>
                </li>
                <li>
                  <a href="#">Blogs</a>
                </li>
                <li>
                <Link to={'/events'} className="nav-link">
                          Events
                        </Link>
                </li>
                <li>
                  <a href="#">Online Resources</a>
                </li>
                <li>
                  <a href="#">Research</a>
                </li>
                <li>
                  <a href="#">Apply Online</a>
                </li>
                <li>
                <Link to={'/PhdNotifications'} className="nav-link">
                        PhD Notifications
                        </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-sm-6">
          <div className="footer-widjet">
            <h3>Our Colleges</h3>
            <div className="list">
              <ul>
              <li>
                  <a href="https://www.swaminarayanuniversity.ac.in/sims" target='_blank'>Faculty of Medicine</a>
                </li>
                <li>
                  <a href="#">Faculty of Ayurveda</a>
                </li>
                <li>
                  <a href="https://www.swaminarayanuniversity.ac.in/homoeopathy" target='_blank'>Faculty of Homoeopathy</a>
                </li>
                <li>
                  <a href="#">Faculty of Nursing</a>
                </li>
                <li>
                  <a href="#">Faculty of Engineering &amp; Technology</a>
                </li>
                <li>
                  <a href="#">Faculty of Pharmacy</a>
                </li>
                <li>
                  <a href="#">Faculty of Education</a>
                </li>
                <li>
                  <a href="#">Faculty of Law</a>
                </li>
                <li>
                  <a href="#">Faculty of Science</a>
                </li>
                <li>
                  <a href="#">Faculty of Commerce &amp; Management</a>
                </li>
                <li>
                  <a href="#">Faculty of IT &amp; Computer Science</a>
                </li>
                <li>
                  <a href="#">Faculty of Arts &amp; Humanity</a>
                </li>
                <li>
                  <a href="#">Faculty of Physiotherapy</a>
                </li>
                <li>
                  <a href="#">Faculty of Paramedical Sciences</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="shape">
        <img src="../assets/images/shape-1.png" alt="Image" />
      </div>
    </div>
  </div>
  <div className="copyright-area">
    <div className="container">
      <div className="copyright">
        <div className="row">
          <div className="col-lg-6 col-md-4">
            
          </div>
          <div className="col-lg-6 col-md-8">
            <div className="copy">
              <p>
                © {/*?= date("Y")?*/} Swaminarayan University. Crafted with{" "}
                <i className="ri-heart-3-fill text-danger" /> by{" "}
                <a href="https://nexenstial.com/" target="_blank">
                  Nexenstial LLP
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="go-top">
    <i className="ri-arrow-up-s-line" />
    <i className="ri-arrow-up-s-line" />
  </div>
</>

    </div>
  )
}

export default Footer