import React from 'react'
import { Link } from 'react-router-dom'


const NotFoundPage = () => {
  return (
    <div className='NotFoundPage'>

<div className="error-area ptb-100">
  <div className="container">
    <div className="top-content">
      <ul>
        <li>4</li>
        <li>0</li>
        <li>4</li>
      </ul>
    </div>
    <h2>Error 404 : Page Not Found</h2>
    <p>
      The page you are looking for might have been removed / It's name got changed / It's temporarily unavailable.
    </p>
    <Link to={"/"} className="btn default-btn">
      Back To Homepage
    </Link>
  </div>
</div>


    </div>
  )
}

export default NotFoundPage