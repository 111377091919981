import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../Components/Loader/Loader";
import { Http } from "../../Services/Services";
import { STUDENT_COMPLAINT } from "../../Utils/apiConst";
import { STUDENTBYID } from "../../Utils/apiConst";

function StudentsComplaitns() {
  const navigate = useNavigate();
  const [studentId, setStudentId] = useState("");
  const { state } = useLocation();
  let date = "";
  const [loading, setLoading] = useState(false);
  const [flag, setFlag] = useState(false);

  const [user, setUser] = useState([]);
  let info = {};
  const [x, setX] = useState();
  const [y, setY] = useState();
  let clgdata = JSON.parse(localStorage.getItem("COLLEGE"));
  const [facultyName, setFacultyName] = useState();

  const clear = () => {
    setUser("");
    setX("");
    setFacultyName("");
    date = "";
    setStudentId("");
    setFlag(!flag);
  };

  const submit = async () => {
    setLoading(1);
    Http.get(`${STUDENTBYID}/${studentId}`)
      .then((res) => {
        console.log(res.data.data.name);
        setUser(res.data.data);
        date = new Date().toISOString().split("T")[0];
        setY(date);
        info.name = res.data.data.name;
        info.student_id = studentId;
        info.faculty = res.data.data.college_id;
        info.dob = res.data.data.dob;
        info.gender = res.data.data.gender;
        info.date = new Date();
        let z = clgdata?.filter((s) => s.id == res.data.data.college_id)[0]
          ?.name;
        setFacultyName(z);
        console.log(facultyName);
        setX(info);
        setFlag(true);
        setLoading(0);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
      });
  };

  const submit1 = async () => {
    if (x.complaint == "" || x.complaint == null) {
      toast.error("Please Enter the Complaint");
      return;
    }
    setLoading(1);

    Http.post(STUDENT_COMPLAINT, x)
      .then((res) => {
        toast.success("Successfully Submitted Complaint");
        clear();
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something Went Wrong");
      });

    setLoading(0);
  };

  const handleChange = (e) => {
    let y = e.target.value;
    x.complaint = y;
  };

  return (
    <div className="register">
      <Loader loading={loading} />
      <div className="row">
        <h3>Caste Based Descrimination Complaint Form</h3>
        <div className="col-sm-12">
          <div className="form-group">
            <label htmlFor="">Student Enrollment Number</label>
            <input
              value={studentId}
              onChange={(e) => {
                setStudentId(e.target.value);
              }}
              type="text"
              className="form-control"
              placeholder="Enter Student Enrollment Number"
            />
          </div>
        </div>
        {!flag ? (
          <div className="col-md-12">
            <button
              className="btn btn-danger pull-right"
              onClick={() => submit()}
            >
              Submit
            </button>
          </div>
        ) : null}

        {flag ? (
          <>
            <div className="row mt-2">
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="">Student Name</label>
                  <input
                    type="text"
                    className="form-control"
                    value={user?.name}
                    name="name"
                    id=""
                    readOnly
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="">Faculty</label>
                  <input
                    type="text"
                    value={facultyName}
                    className="form-control"
                    name="Faculty"
                    id=""
                    readOnly
                  />
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="">Date of Birth</label>
                <input
                  type="date"
                  value={user?.dob}
                  className="form-control"
                  name="dob"
                  readOnly
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="">Gender</label>
                <input
                  type="text"
                  className="form-control"
                  value={user?.gender}
                  name="gender"
                  readOnly
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="">Date</label>
                <input
                  value={y}
                  type="date"
                  className="form-control"
                  name="date"
                  readOnly
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="">Enter Your Complaints</label>
                <textarea
                  name="complaint"
                  className="form-control"
                  value={x.complaint}
                  onChange={(e) => handleChange(e)}
                  id=""
                  cols="3"
                  rows="2"
                ></textarea>
              </div>
            </div>
            <div className="col-md-12">
              <button className="btn btn-danger" onClick={() => submit1()}>
                Submit
              </button>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
}

export default StudentsComplaitns;
