import axios from "axios";
import React, { useState, useEffect } from "react";
import { sessionOpt } from "../../Data/sessionData";
import { PREV_PHARM_EXAM_RESULT } from "../../Utils/apiConst";
import Loader from "../../Components/Loader/Loader";
import { toast } from "react-toastify";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import {
  ACADEMICS_ADD_CLASS,
  ACADEMICS_ADD_SECTION,
  ACADEMICS_ADD_SEMESTER,
} from "../../Utils/Academics.apiConst";
import "./style.scss";
import { Link, useNavigate } from "react-router-dom";

function PrevResultsPharm() {
  const navigate = useNavigate();
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const calculateGradePoint = (p) => {
    return parseFloat(p * 10).toFixed(2);
  };

  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  //All Use States
  const [facultyopt, setFacultyOpt] = useState(
    JSON.parse(localStorage.getItem("COLLEGE"))
  );

  const [program, setProgram] = useState(
    JSON.parse(localStorage.getItem("PROGRAM"))
  );

  const [department, setDepartment] = useState([]);

  const [faculty, setFaculty] = useState();
  const [prog, setProg] = useState();
  const [depart, setDepart] = useState();
  const [currentclass, setCurrentClass] = useState();
  const [currentSemester, setCurrentSemester] = useState();
  const [classopt, setClassOpt] = useState([]);
  const [sectionOpt, setSectionOpt] = useState([]);
  const [semesterOpt, setSemesterOpt] = useState([]);

  useEffect(() => {
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    axios({
      ...config,
      url: `${ACADEMICS_ADD_CLASS}?college_id=${faculty}`,
    })
      .then((res) => {
        setClassOpt(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });

    axios({
      ...config,
      url: `${ACADEMICS_ADD_SECTION}?college_id=${faculty}`,
    })
      .then((res) => {
        setSectionOpt(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });

    axios({
      ...config,
      url: `${ACADEMICS_ADD_SEMESTER}?college_id=${faculty}`,
    })
      .then((res) => {
        setSemesterOpt(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [faculty]);

  //Faculty data
  const facultyData = [
    {
      id: 1111008,
      name: "FACULTY OF PHARMACY",
      college_type_id: "PHARM",
      status: "ACTIVE",
      colloge_name: "Shree Swaminarayan College Of Pharmacy,Kalol",
    },

    {
      id: 1111003,
      name: "FACULTY OF PHARMACY",
      college_type_id: "PHARM",
      status: "ACTIVE",
      colloge_name: "Shree Swaminarayan College Of Nursing,Kalol",
    },
  ];

  useEffect(() => {
    setDepartment(
      JSON.parse(localStorage.getItem("DEPARTMENT"))?.filter(
        (itemt) => itemt.college_id == faculty
      )
    );
  }, [faculty]);

  const [studentId, setStudentId] = useState("");
  const [sessionId, setSessionId] = useState("");
  const [loading, setLoading] = useState(0);

  const [flag, setFlag] = useState(0);

  const [examResults, setExamResults] = useState([]);
  const [totalsum, setTotalSum] = useState();
  const [totalsum_emin, setTotalSumEmin] = useState();
  const [isPass, setIsPass] = useState(0);
  const [failCnt, setFailCnt] = useState(0);
  const [totalsum_imin, setTotalSumImin] = useState();
  const [totalsum_imax, setTotalSumImax] = useState();
  const [totalsum_mtmin, setTotalSumMtmin] = useState();
  const [totalsum_mtmax, setTotalSumMtmax] = useState();
  const [totalsum_vivamin, setTotalSumVivamin] = useState();
  const [totalsum_vivamax, setTotalSumVivamax] = useState();
  const [totalsum_imarks, setTotalSumImarks] = useState();
  const [totalsum_emarks, setTotalSumEmarks] = useState();
  const [totalsum_mtmarks, setTotalSumMtmarks] = useState();
  const [totalsum_vivamarks, setTotalSumVivamarks] = useState();
  const [totalsum_obt, setTotalSumobt] = useState();
  const [sgpaObt, setSgpaObt] = useState(0);
  const [totalabsent, setTotalAbsent] = useState();
  const [totalcredits, setTotalCredits] = useState();
  const [grade, setGrade] = useState();
  const [finalresult, setFinalResult] = useState();

  const gparef = [
    {
      min: 90,
      max: 100,
      grade: "O",
      gp: 10,
    },
    {
      min: 80,
      max: 89.99,
      grade: "A",
      gp: 9,
    },
    {
      min: 70,
      max: 79.99,
      grade: "B",
      gp: 8,
    },
    {
      min: 60,
      max: 69.99,
      grade: "C",
      gp: 7,
    },
    {
      min: 50,
      max: 59.99,
      grade: "D",
      gp: 6,
    },
    {
      min: 0,
      max: 49.99,
      grade: "F",
      gp: 0,
    },
  ];

  const maptoHundred = (num, max) => (100 * Number(num)) / Number(max);

  const calculateGradePointEachSub = (num, max) => {
    //console.log(calculateGradePointEachSub);
    let marks = maptoHundred(Number(num), Number(max));
    // console.log(num, max);
    //console.log(num, max);
    for (const i of gparef) {
      if (i.min <= marks && i.max >= marks) {
        //console.log(i, marks);
        return i;
      }
    }
  };

  const getResults = async () => {
    if (
      !studentId ||
      !sessionId ||
      !faculty ||
      !prog ||
      !depart ||
      !currentclass ||
      !currentSemester ||
      !sessionId
    )
      return toast.error("Please fill Student Id and Session");
    if (faculty == 1111000) {
      navigate("/prevEnggresults");


      if (faculty == 1111009) {
        navigate("/prevEnggresults");
      }
    } else if (faculty == 1111008) {
      navigate("/prevPharmresults");
    }
    setLoading(1);
    const config = {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
      url: `${PREV_PHARM_EXAM_RESULT}?student_id=${studentId}&session_id=${sessionId}&class_id=${currentclass}&semester=${currentSemester}?college=${faculty}`,
    };

    await axios(config)
      .then((res) => {
        const result = Object.values(
          res.data.data.data.reduce((acc, curr) => {
            const subject = curr.subject;
            if (!acc[subject]) {
              acc[subject] = { ...curr };
            } else {
              acc[subject].e_marks += curr.e_marks;
              acc[subject].e_max += curr.e_max;
              acc[subject].e_min += curr.e_min;
              if (acc[subject].e_grace != null || acc[subject].e_grace == 0) {
                acc[subject].e_grace += curr.e_grace;
              }
            }
            // //console.log("hello"+acc)
            return acc;
          }, {})
        );

        if (result.length == 0) {
          toast.error("Please Check Entered Details");
          return;
        }
        setExamResults({ ...res.data.data, data: result });
        // console.log(result);
        var gradep = 0;
        res.data.data.data?.forEach((j) => {
          gradep +=
            calculateGradePointEachSub(
              j.e_marks + j?.i_marks + j.e_grace,
              j.e_max + j?.i_max
            )?.gp * j.credit
              ? calculateGradePointEachSub(
                  j.e_marks + j?.i_marks + j?.e_grace,
                  j.e_max + j?.i_max
                )?.gp * j.credit
              : 0;
        });
        console.log("total gp", gradep);
        setGrade(gradep);
        var sgpagrade = 0;
        var sgpacredit = 0;
        res.data.data.data?.forEach((j) => {
          if (j.is_not_countable || j.e_marks < j.e_min) {
            //console.log('failed here',j);
            sgpagrade = sgpagrade;
            sgpacredit = sgpacredit;
            // console.log(sgpagrade);
          } else {
            sgpagrade +=
              calculateGradePointEachSub(
                j.e_marks + j?.i_marks + j?.mt_marks,
                j.e_max + j?.i_max
              )?.gp * j.credit
                ? calculateGradePointEachSub(
                    j.e_marks + j?.i_marks,
                    j.e_max + j?.i_max
                  )?.gp * j.credit
                : 0;
            sgpacredit += j.credit;
          }
        });
        // console.log('gpppppppppppp', sgpagrade, sgpacredit);
        console.log(sgpagrade);


       
        // setGrade(gradep).
        setSgpaObt({
          grade: sgpagrade,
          credit: sgpacredit,
          sgpa: sgpagrade / sgpacredit,
        });

        
        var sum = 0;
        res.data.data.data?.forEach((item) => {
          if (item.is_not_countable) sum = sum;
          else sum += item.e_max;
        });
        console.log(sum);
        setTotalSum({ emax: sum });

        var passed = true;
        var failed = 0;
        res.data.data.data?.forEach((item) => {
          if (
            item.e_min > item.e_grace + item.e_marks ||
            item.i_min > item.i_marks ||
            item.e_marks + item.e_grace + item.i_marks <
              (item.e_max + item.i_max) / 2
          ) {
            passed = false;
            failed++;
          }
        });
        setFailCnt(failed);
        setIsPass(passed);

        var sum_emin = 0;
        res.data.data.data?.forEach((item) => {
          if (item.is_not_countable) sum_emin = sum_emin;
          else sum_emin += item.e_min;
        });
        //console.log(sum_emin);
        setTotalSumEmin({ emin: sum_emin });

        var sum_imin = 0;
        res.data.data.data?.forEach((item) => {
          if (item.is_not_countable) sum_imin = sum_imin;
          else sum_imin += item.i_min;
        });
        //console.log(sum_imin);
        setTotalSumImin({ imin: sum_imin });

        var sum_imax = 0;
        res.data.data.data?.forEach((item) => {
          if (item.is_not_countable) sum_imax = sum_imax;
          else sum_imax += item.i_max;
        });
        // console.log(sum_imax);
        setTotalSumImax({ imax: sum_imax });

        var sum_imarks = 0;
        res.data.data.data?.forEach((item) => {
          if (item.is_not_countable) sum_imarks = sum_imarks;
          else sum_imarks += item.i_marks;
        });
        // console.log("internal Sum" + sum_imarks);
        setTotalSumImarks({ imarks: sum_imarks });

        var sum_emarks = 0;
        res.data.data.data?.forEach((item) => {
          if (item.is_not_countable) sum_emarks = sum_emarks;
          else {
            sum_emarks = sum_emarks + item.e_marks + item.e_grace;
          }
        });
        // console.log("esum" + sum_emarks);
        setTotalSumEmarks({ emarks: sum_emarks });

        var sum_obt = 0;
        res.data.data.data?.forEach((item) => {
          if (item.is_not_countable) sum_obt = sum_obt;
          else sum_obt += item.e_marks + item.i_marks + item?.e_grace;
        });
        // console.log("obt" + sum_obt);
        setTotalSumobt({ obt: sum_obt });

        setFinalResult(((sum_obt / (sum + sum_imax)) * 100).toFixed(2));
        //    console.log(sum+sum_imax)

        var sum_credits = 0;
        res.data.data.data?.forEach((item) => {
          if (item.is_not_countable) sum_credits = sum_credits;
          else sum_credits += item.credit;
          // console.log("Credits"+sum_credits);
        });
        // console.log("Credits"+sum_credits);
        setTotalCredits({ credits: sum_credits });

        var absent = 0;
        res.data.data.data?.forEach((item) => {
          if (item.i_absent == 1 || item.e_absent == 1) {
            absent++;
          }
        });

        setTotalAbsent({ absent: absent });

        setFlag(1);
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(0);
  };


  const toInputUppercase = e => {
    e.target.value = ("" + e.target.value).toUpperCase();
  };
  return (
    <div className="Enrollment-area mt-5 pb-70 ">
      <Loader />

      {!flag ? (
        <div className="container">
          <div className="d-flex justify-content-center align-items-center">
            <img
              src="/assets/images/favicon.png"
              className="mr-5"
              width="150"
              alt="logo"
            />
            <div className="ml-5 text-center">
              <h2 class="text-uppercase mx-5"> Swaminarayan University</h2>
            </div>
          </div>
          <div className="row">
            <div className="card shadow-sm border-0">
              <h3 className="card-header text-center">
                
               {faculty != "FACULTY OF NURSING" ? " PHARMACY" : "NURSING" }  PREVIOUS PROVISIONAL EXAMINATION RESULTS
                <br />
                કામચલાઉ પરીક્ષાના પરિણામો
              </h3>

              <div className="card-body">
                <div className="row d-flex justify-content-center p-4">
                  <div className="col-md-6 mt-4">
                    <div className="form-group">
                      <label htmlFor="">Program</label>
                      <select
                        value={prog}
                        className="form-control"
                        onChange={(e) => setProg(e.target.value)}
                      >
                        <option value="">Select Program</option>
                        {program &&
                          program?.map((item, key) => {
                            return (
                              <>
                                <option value={item?.id}>{item?.name}</option>
                              </>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6 mt-4">
                    <div className="form-group">
                      <label htmlFor="">Faculty</label>
                      <select
                        className="form-control"
                        value={faculty}
                        onChange={(e) => setFaculty(e.target.value)}
                      >
                        <option value="">Select Faculty</option>
                        {facultyopt &&
                          facultyopt?.map((item, key) => {
                            return (
                              <>
                                <option value={item?.id}>{item?.name}</option>
                              </>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6 mt-4">
                    <div className="form-group">
                      <label htmlFor="">Department</label>
                      <select
                        value={depart}
                        className="form-control"
                        onChange={(e) => setDepart(e.target.value)}
                      >
                        <option value="">Select Department</option>
                        {department &&
                          department?.map((item, key) => {
                            return (
                              <>
                                <option value={item?.id}>{item?.name}</option>
                              </>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6 mt-4">
                    <div className="form-group">
                      <label htmlFor="">Class</label>
                      <select
                        depart={currentclass}
                        className="form-control"
                        onChange={(e) => setCurrentClass(e.target.value)}
                      >
                        <option value="">Select Class</option>
                        {classopt
                          ?.filter((s) => s?.department_id == depart)
                          ?.map((i, key) => (
                            <option value={i?.id}>{i?.name}</option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6 mt-4">
                    <div className="form-group">
                      <label htmlFor="">Semester</label>
                      <select
                        value={currentSemester}
                        className="form-control"
                        onChange={(e) => setCurrentSemester(e.target.value)}
                      >
                        <option value="">Select Semester</option>
                        {semesterOpt
                          ?.filter((s) => s.class_id == currentclass)
                          ?.map((i, key) => (
                            <option value={i?.id}>{i?.name}</option>
                          ))}
                      </select>
                    </div>
                  </div>

                  <div className="col-md-6 mt-4">
                    <div className="form-group">
                      <label htmlFor="">Select Academic Year</label>
                      <select
                        name="year"
                        id="year"
                        className="form-control"
                        value={sessionId}
                        onChange={(e) => setSessionId(e.target.value)}
                      >
                        <option value="">Select Academic Year</option>
                        {sessionOpt.map((i, key) => (
                          <option value={i.id}>{i.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6 mt-4">
                    <div className="form-group">
                      <label htmlFor="">Enrollment Number</label>
                      <input
                        type="text"
                        name="reg_no"
                        id="reg_no"
                        className="form-control"
                        placeholder="Enter Enrollment Number"
                        value={studentId}
                        onChange={(e) => setStudentId(e.target.value)}
                        onInput={toInputUppercase}
                      />
                    </div>
                  </div>

                  <br />
                  <div className="col-md-12 mt-4">
                    <button
                      className="btn btn-primary d-print-none float-right"
                      onClick={getResults}
                    >
                      Show Result
                    </button>
                  </div>
                </div>
                <div className="row "></div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container p-5" ref={componentRef}>
          <div className="d-flex justify-content-center align-items-center">
            <img
              src="/assets/images/favicon.png"
              className="mr-5"
              width="150"
              alt="logo"
            />
            <div className="mx-3 text-center">
              {/* <h4>સ્વામિનારાયણ યુનિવર્સિટી, કલોલ</h4> */}
              <h2 class="text-uppercase"> Swaminarayan University</h2>
            </div>
          </div>

          <br />

          <h5 class="text-uppercase text-center">
          {examResults?.data[0]?.college == "FACULTY OF NURSING" ? "NURSING " : "PHARMACY " }   
             Previous Provisional Statement of Marks and Grades of{" "}
            {examResults?.data[0]?.program || "UG"} Examination &nbsp;
            {months[Number(examResults?.data[0]?.month) - 1] ||
              "JAN" ||
              "MARCH"}
            -{examResults?.data[0]?.year || "2023"}
          </h5>

          <br />

          {examResults?.examStatus == "DIFF" ? (
            <div>
              {examResults?.data[0]?.college_id == 1111008 ? (
                <div className="text-center alert alert-danger">
                  <h5>
                    Please Check Previous Pharmacy Results{" "}
                    <Link to={"/prevPharmresults"} className="text-danger">
                      here
                    </Link>
                  </h5>{" "}
                </div>
              ) : examResults?.data[0]?.college_id == 1111000 ? (
                <div className="text-center alert alert-danger">
                  <h5>
                    Please Check Previous Engineering Results{" "}
                    <Link to={"/prevEnggresults"} className="text-danger">
                      here
                    </Link>
                  </h5>{" "}
                </div>
              ) : null}
            </div>
          ) : examResults?.examStatus == "PUBLISHED" ? (
            <div className="table-responsive ">
              <table className="table table-bordered result ">
                <thead>
                  <tr>
                    <th>Enrollment Number</th>
                    <td>{examResults?.data?.[0]?.student_id}</td>
                    <th>Student Name </th>
                    <td>{examResults?.data?.[0]?.name}</td>
                  </tr>
                  <tr>
                    <th>Faculty Name</th>
                    <td>{examResults?.data[0]?.college}</td>

                    <th>Program</th>
                    <td>{examResults?.data[0]?.department}</td>
                  </tr>
                  <tr>
                    <th>College Name</th>
                    <td>
                      {
                        facultyData.find(
                          (item) => item.id == examResults?.data[0]?.college_id
                        )?.colloge_name
                      }
                    </td>

                    <th>
                      {examResults?.data[0]?.department == "B.H.M.S."
                        ? "Year"
                        : examResults?.data[0]?.department == "B.Sc. Nursing"
                        ? "Year"
                        : examResults?.data[0]?.department ==
                          "Post Basic B.Sc. Nursing"
                        ? "Year"
                        : "Semester"}
                    </th>

                    <td>
                      {" "}
                      {examResults?.data[0]?.department == "B.H.M.S."
                        ? "1st Year"
                        : examResults?.data[0]?.department == "B.Sc. Nursing"
                        ? "1st Year"
                        : examResults?.data[0]?.department ==
                          "Post Basic B.Sc. Nursing"
                        ? "1st Year"
                        : "1"}{" "}
                    </td>
                  </tr>
                </thead>
              </table>

              <table className="table result table-bordered ">
                <thead>
                  <tr>
                    <th className="text-center">Course Code</th>
                    <th className="text-center">Course Name</th>

                    <th className="text-center">AM</th>
                    <th className="text-center">Credit</th>
                    <th className="text-center">EA</th>
                    <th className="text-center">IA</th>
                    <th className="text-center">GP</th>
                    <th className="text-center">Gr</th>

                    <th className="text-center">EGP</th>
                    <th className="text-center">Rmk</th>
                  </tr>
                </thead>
                <tbody>
                  {examResults &&
                    examResults?.data?.map((i, key) => (
                      <tr key={key}>
                        <td className="text-center">{i?.sub_code}</td>
                        <td>{i?.subject}</td>
                        <td className="text-center">
                          {i?.sub_type == "LECTURE" ? "TH" : "PR"}
                        </td>
                        {totalcredits?.credits != 0 ? (
                          <td className="text-center">{i?.credit} </td>
                        ) : (
                          ""
                        )}
                        <td className="text-center">
                          {i?.e_marks == null
                            ? "--"
                            : i?.sub_code == "BP105T"
                            ? "--"
                            : i?.sub_code == "BP111P"
                            ? "--"
                            : i?.sub_code == "BP106RMT"
                            ? "--"
                            : !i?.e_marks && i?.e_absent
                            ? "N"
                            : "E"}
                          {!i?.e_grace == "" || !i?.e_grace == null ? "*" : ""}
                        </td>

                        <td className="text-center">
                          {i?.i_marks == 0 || i?.i_marks == null
                            ? "--"
                            : !i?.i_marks && i?.i_absent
                            ? "N"
                            : "E"}{" "}
                        </td>
                        <td className="text-center">
                          {" "}
                          {
                            calculateGradePointEachSub(
                              i.e_marks + i?.i_marks + i.e_grace,
                              i.e_max + i?.i_max
                            )?.gp
                          }
                        </td>

                        <td className="text-center">
                          {" "}
                          {i?.e_absent ? (
                            "AB"
                          ) : i.e_marks + i.e_grace + i.i_marks <
                            (i.e_max + i.i_max) / 2 ? (
                            <span className="text-danger">F</span>
                          ) : (
                            calculateGradePointEachSub(
                              i.e_marks + i?.i_marks + i.e_grace,
                              i.e_max + i?.i_max
                            )?.grade
                          )}
                        </td>

                        <td className="text-center">
                          {calculateGradePointEachSub(
                            i.e_marks + i?.i_marks + i.e_grace,
                            i.e_max + i?.i_max
                          )?.gp * Number(i?.credit)}
                        </td>
                        <td className="text-center">
                          {i.e_marks + i.e_grace + i.i_marks <
                          (i.e_max + i.i_max) / 2 ? (
                            <span className="text-danger">N,C</span>
                          ) : (
                            "E,C"
                          )}
                        </td>
                      </tr>
                    ))}

                  <tr>
                    <th rowSpan={2} className="text-center">
                      Sem-I
                    </th>
                    <th> Credit :{totalcredits?.credits} </th>
                    <th>EGP: {grade}</th>
                    <th>
                      SGPA :
                      {totalabsent?.absent >= 1 ||
                      examResults?.data?.reduce(
                        (total, i) =>
                          i?.is_not_countable
                            ? total + 0
                            : total +
                              (calculateGradePoint(
                                (Number(i?.i_marks) +
                                  Number(i?.e_marks) +
                                  Number(i?.e_grace)) /
                                  (Number(i?.e_max) + Number(i?.i_max))
                              ) <
                                3.6),
                        0
                      )
                        ? "-"
                        : totalabsent?.absent >= 1
                        ? "-"
                        : (grade / totalcredits?.credits)?.toFixed(2)}
                    </th>

                    <th>
                      Status:{" "}
                      {totalabsent?.absent >= 1 ||
                      examResults?.data?.reduce(
                        (total, i) =>
                          i?.is_not_countable
                            ? total + 0
                            : total +
                              (calculateGradePoint(
                                (Number(i?.i_marks) +
                                  Number(i?.e_marks) +
                                  Number(i?.e_grace)) /
                                  (Number(i?.e_max) + Number(i?.i_max))
                              ) <
                                3.6),
                        0
                      ) ? (
                        <span className="text-danger">Fail</span>
                      ) : totalabsent?.absent >= 1 || !isPass ? (
                        <span className="text-danger">Fail</span>
                      ) : (
                        <span className="text-success">Pass</span>
                      )}{" "}
                    </th>
                    <th colSpan={5}></th>
                  </tr>
                  <tr>
                    <th>
                      Grade:{" "}
                      {totalabsent?.absent >= 1
                        ? "-"
                        : calculateGradePointEachSub(
                            totalsum_imarks?.imarks + totalsum_emarks?.emarks,
                            totalsum_imax?.imax + totalsum?.emax
                          )?.grade}{" "}
                    </th>
                    <th>Backlog : {failCnt}</th>
                    <th colSpan={7}></th>{" "}
                  </tr>

                  <tr>
                    <th rowSpan={2} className="text-center">
                      Cumulative
                    </th>
                    <th> Credit :{totalcredits?.credits} </th>
                    <th>EGP: {grade}</th>
                    <th>CGPA : --</th>

                    <th>
                      Status:{" "}
                      {totalabsent?.absent >= 1 ||
                      examResults?.data?.reduce(
                        (total, i) =>
                          i?.is_not_countable
                            ? total + 0
                            : total +
                              (calculateGradePoint(
                                (Number(i?.i_marks) +
                                  Number(i?.e_marks) +
                                  Number(i?.e_grace)) /
                                  (Number(i?.e_max) + Number(i?.i_max))
                              ) <
                                3.6),
                        0
                      ) ? (
                        <span className="text-danger">Fail</span>
                      ) : totalabsent?.absent >= 1 || !isPass ? (
                        <span className="text-danger">Fail</span>
                      ) : (
                        <span className="text-success">Pass</span>
                      )}{" "}
                    </th>
                    <th colSpan={5}></th>
                  </tr>
                  <tr>
                    <th>Grade: --</th>
                    <th>Backlog : {failCnt}</th>
                    <th colSpan={7}></th>{" "}
                  </tr>
                  <tr>
                    <td colSpan={10}>
                      Abbreviations: Gr: Grade, SGPA: Semester Grade Point
                      Average, CGPA:Cumulative Grade Point Average, GP:Grade
                      Point, EGP: Earned Grade Points, E: Exempted, C:Current
                      Appearence, X:Pass Performance, N:Not Exempte, UM:Unfair
                      Means, AB:Absent, AM:Assessment Method, EA:External
                      Assessment, IA: Internal Assessment, *: Indicate Gracing
                      Rule Applied, MP: Malpractice, M: Performance Cancelled
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          ) : (
            <h3 className="text-center alert alert-danger">
              Your exam Result is{" "}
              {examResults?.examStatus == "HOLD"
                ? "holded back due to some reasons"
                : "not yet published"}
            </h3>
          )}

          {examResults?.examStatus == "HOLD" ? null : (
            <>
              {/* <div className="text-center mt-4">
                                    <h6>Nomenclature / Abbreviations</h6>
                                </div>
                                <div className="d-flex justify-content-around px-5 mt-4">
                                    <h6> P {"->"} Pass</h6>
                                    <h6> F {"->"} Fail</h6>
                                    <h6> A {"->"} Absent</h6>
                                    <h6> NE {"->"} Not Eligible</h6>
                                </div> */}
            </>
          )}

          {/* <h5 className="text-danger mt-4">Note *</h5>
                        <p><b>This is Computer Generate Statement of Marks</b></p> */}

          <div className="row mt-3">
            <div className="col-md-12">
              <div className="float-end text-center mb-5 mt-4"></div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <button
                onClick={() => setFlag(0)}
                className="btn btn-primary d-print-none mx-3"
              >
                Back
              </button>

              {examResults?.examStatus == "HOLD" ? null : (
                <button
                  className="btn btn-primary  float-right"
                  onClick={handlePrint}
                >
                  Print
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default PrevResultsPharm;
