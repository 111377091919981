import React from "react";
import { publications } from "./publications";

export default function Workshop() {
  return (
    <div>
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-md-12">
            <p>
              <strong>Campus Placements</strong>
            </p>

            <div>
              {publications.map((item) => (
                <>
                  <p>
                    {item.id}.&nbsp;{item.description}
                    <br />
                    <a href={item.link} target="_blank">
                      Click here to Read publication
                    </a>
                  </p>
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
