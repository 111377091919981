import React from 'react'
import { Link } from 'react-router-dom'

function AfterUG() {
  return (
    <div className='AfterUG '>
<div class="page-banner-area bg-2">
<div class="container">
<div class="page-banner-content">
<h1>After UG</h1>
<ul>
<li> <Link to={'./../'}>  Home </Link></li>


<li>After UG</li>
</ul>
</div>
</div>
</div>
<div className="container mt-5">
<div className="row">
    <div className="col-md-9">
    <div className="register" style={{maxWidth:'1320px'}} >
<div className="container-fluid">
   <div className="row">
    <h3>AfterUG</h3>
   
    <br/>
    <h2 className='text-center text-light' style={{backgroundColor: "#FC7F03"}}>Ayurveda </h2>
    <table className="table table-bordered text-center">
        <thead>
            
            <tr>
                <th>Name of Program</th>
              
                <th className='text-center'>Eligibility </th>
                <th className='text-center'>Intake </th>
            </tr>
        </thead>
        <tbody >
            <tr>
                <td width="20%">MD (RN,SAMHITA,K C) </td>
            
                <td className='text-center'>A person possessing the degree in Ayurved of a University or Board or  Medical Institution specified in the Second Schedule to the Act shall be eligible  for admission in the post-graduate degree course


</td>
                <td className='text-center'>05</td>
            </tr>
            <tr>
                <td width="20%">MS (SHALYA, PTSR)  </td>
            
                <td className='text-center'>A person possessing the degree in Ayurved of a University or Board or  Medical Institution specified in the Second Schedule to the Act shall be eligible  for admission in the post-graduate degree course


</td>
                <td className='text-center'>05</td>
            </tr>


           
        </tbody>
    </table>
    
    <br/>
    <h2 className='text-center text-light' style={{backgroundColor: "#FC7F03"}}>NURSING  </h2>
   
    <table className="table table-bordered text-center">
        <thead>
            
            <tr>
                <th>Name of Program</th>
              
                <th className='text-center'>Eligibility </th>
                <th className='text-center'>Intake </th>
            </tr>
        </thead>
        <tbody >
            <tr>
                <td width="20%">M.Sc. Nursing </td>
            
                <td className='text-center'>Shall have cleared B.Sc(Nursing)/ B.Sc(Hons.) Nursing/Post Basic B.Sc Nursing with min  55% for general and 50% for SC/ST from an institution recognized by Indian Nursing  Council with below requirements:  <br/>
1. Should be a Registered Nurse/Midwife or equivalent with any State Nursing Registration  Council <br/>
2. Minimum one year work experience after Basic B.Sc Nursing

</td>
                <td className='text-center'>25</td>
            </tr>

        </tbody>
    </table>
    
    <br/>
    <h2 className='text-center text-light' style={{backgroundColor: "#FC7F03"}}>Pharmacy   </h2>
   
    <table className="table table-bordered text-center">
        <thead>
            
            <tr>
                <th>Name of Program</th>
              
                <th className='text-center'>Eligibility </th>
                <th className='text-center'>Intake </th>
            </tr>
        </thead>
        <tbody >
            <tr>
                <td width="20%">M.Pharm 
(Pharmaceutics)
 </td>
            
                <td className='text-center'>The candidate shall have passed B.Pharm with minimum 55% For General  Category (50% for SC/ST/SEBC candidates) at qualifying exam for M.Pharm  Courses



</td>
                <td className='text-center'>06</td>
            </tr>
            <tr>
                <td width="20%">M.Pharm 
(Pharmaceutical  
Quality Assurance)

 </td>
            
                <td className='text-center'>The candidate shall have passed B.Pharm with minimum 55% For General  Category (50% for SC/ST/SEBC candidates) at qualifying exam for M.Pharm  Courses




</td>
                <td className='text-center'>09</td>
            </tr>

        </tbody>
    </table>
    
    <br/>
    <h2 className='text-center text-light' style={{backgroundColor: "#FC7F03"}}>Law   </h2>
   
    <table className="table table-bordered text-center">
        <thead>
            
            <tr>
                <th>Name of Program</th>
              
                <th className='text-center'>Eligibility </th>
                <th className='text-center'>Intake </th>
            </tr>
        </thead>
        <tbody >
            <tr>
                <td width="20%">LLB 
 </td>
            
                <td className='text-center'>Graduation from UGC recognized University with 45% Marks 





</td>
                <td className='text-center'>120</td>
            </tr>
            

        </tbody>
    </table>

    <br/>
    <h2 className='text-center text-light' style={{backgroundColor: "#FC7F03"}}>Engineering    </h2>
   
    <table className="table table-bordered text-center">
        <thead>
            
            <tr>
                <th>Name of Program</th>
                <th>Department</th>
              
                <th className='text-center'>Eligibility </th>
                <th className='text-center'>Intake </th>
            </tr>
        </thead>
        <tbody >
            <tr>
                <td width="20%">M.Tech. (Master of Technology)  
 </td>
 <td>Mechanical  
(CAD/CAM)
</td>
            
                <td className='text-center'>10 + 2 from recognized Board with  Minimum 45% in PCM 

</td>
                <td className='text-center'>18</td>
            </tr>

            <tr>
                <td width="20%">M.Tech. (Master of Technology) 
 </td>
 <td>Computer  
Engineering

</td>
            
                <td className='text-center'>10 + 2 from recognized Board with  Minimum 45% in PCM 

</td>
                <td className='text-center'>18</td>
            </tr>
            
            <tr>
                <td width="20%">M.Tech. (Master of Technology) 
 </td>
 <td>Electrical Engineering
</td>
            
                <td className='text-center'>10 + 2 from recognized Board with  Minimum 45% in PCM 

</td>
                <td className='text-center'>18</td>
            </tr>
            <tr>
                <td width="20%">M.Tech. (Master of Technology) 
 </td>
 <td>Civil (Structural  
Engineering)

</td>
            
                <td className='text-center'>10 + 2 from recognized Board with  Minimum 45% in PCM 

</td>
                <td className='text-center'>18</td>
            </tr>
            

        </tbody>
    </table>
  
    <br/>
    <h2 className='text-center text-light' style={{backgroundColor: "#FC7F03"}}>Commerce    </h2>
   
    <table className="table table-bordered text-center">
        <thead>
            
            <tr>
                <th>Name of Program</th>
              
                <th className='text-center'>Eligibility </th>
                <th className='text-center'>Intake </th>
            </tr>
        </thead>
        <tbody >
            <tr>
                <td width="20%">M.com  
 </td>
            
                <td className='text-center'>10 + 2 from recognized Board 
</td>
                <td className='text-center'>120</td>
            </tr>
            

        </tbody>
    </table>

    <br/>

    <h2 className='text-center text-light' style={{backgroundColor: "#FC7F03"}}>Management     </h2>
   
   <table className="table table-bordered text-center">
       <thead>
           
           <tr>
               <th>Name of Program</th>
             
               <th className='text-center'>Eligibility </th>
               <th className='text-center'>Intake </th>
           </tr>
       </thead>
       <tbody >
           <tr>
               <td width="20%">MBA 
</td>
           
               <td className='text-center'>Graduation from UGC recognized University with 50% Marks
</td>
               <td className='text-center'>120</td>
           </tr>
           

       </tbody>
   </table>

   <br/>

<h2 className='text-center text-light' style={{backgroundColor: "#FC7F03"}}>IT and Computer Science       </h2>

<table className="table table-bordered text-center">
   <thead>
       
       <tr>
           <th>Name of Program</th>
         
           <th className='text-center'>Eligibility </th>
           <th className='text-center'>Intake </th>
       </tr>
   </thead>
   <tbody >
       <tr>
           <td width="20%">MCA  
</td>
       
           <td className='text-center'>The candidate shall have cleared his graduation with Mathematics or Business  Mathematics or Statistics as one of the subjects, if not then in 12th or 10th from a  recognized university  
with 50% for open and 45% for reserved category

</td>
           <td className='text-center'>60</td>
       </tr>
       <tr>
           <td width="20%">M.Sc. Information  
Technology
  
</td>
       
           <td className='text-center'>Any graduate

</td>
           <td className='text-center'>60</td>
       </tr>
       

   </tbody>
</table>


   </div>

</div>
</div>
    </div>
    <div class="col-lg-3">
<div class="additional-information">
<h3>What ?? </h3>
<div class="list">
<ul>
<li><Link to={'/after10th'} className="nav-link">After 10<sup>th</sup> </Link></li>
<li><Link to={'/after12th'} className="nav-link">
                        AFTER 12<sup>th</sup>
                        </Link></li>
<li><Link to={'/afterUG'} className="nav-link">
                        AFTER UG
                        </Link></li>
<li><Link to={'/afterPG'} className="nav-link">
                        AFTER PG 
                        </Link></li>

</ul>
</div>
</div>
</div>
</div>
</div>


    </div>
  )
}

export default AfterUG