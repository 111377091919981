import React from 'react'

function RecheckingForm() {
    const doc2="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/examination/Appli.form+for+Rechecking(Theory).pdf"

  return (
    <div className='Infrastructure'>
    <div className="academics-details-area pt-90 pb-70">
<div className="container">
<div className="row">
  <div className="col-lg-12">
    <div className="academics-details">
      <div className="details">
       <div className='text-center'> <img src="../assets/images/logo.png" width="500" alt="" /></div>
        <hr />
        <div className="row">
            <div className="col-md-12">
             
            
                <h3 className="text-center">Guideline for Rechecking</h3><br/>
<ol>
    <li>For Rechecking student has to apply within five working days after the notification of the result.</li><br/>
    <li>Students are informed to apply for Rechecking online through result link available on
Swaminarayan University website (www.swaminarayanuniversity.ac.in).</li><br/>
<li>Student has to click on apply to Rechecking, which is applicable, given on result page and select
the subjects for applying rechecking.</li><br/>
<li>Print the online application, sign it and submit to Swaminarayan University with requisite fee.</li><br/>
<li>Student has to verify the subject for Rechecking before taking the print. Once the print
commend is given, no addition/alteration will be permitted.</li><br/>
<li>Fee for Rechecking is Rs. 250/- per subject (for Ayurveda and Homoeopathy Rs. 500 for UG &
1000 for PG,).</li><br/>
<li>Rechecking is permitted in all semesters of all courses/subjects.</li><br/>
<li>In Re-Checking, Answer books are checked just to ensure that all the questions attempted by
the candidate have been valued, the marks awarded have been totally corrected and the total
marks have been correctly carried over to the result statement.</li><br/>
</ol>

            </div>
            <div className="col-md-12 text-right mr-5">
            -Sd- <br/>
Registrar
            </div>
            <br/>
            <div className="col-md-12 text-center">
                <a href={doc2} className="btn btn-success" download target="_blank">Download Rechecking Form</a>
            </div>
        </div>
      
      </div>
      <br />
      <br />
     
    </div>
  </div>
</div>
</div>
</div>

</div>
  )
}

export default RecheckingForm