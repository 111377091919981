import React from "react";
import axios from "axios";
import { useState, useRef, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Navigate } from "react-router-dom";
import {
  ADD_SLIDERS,
  UNIVERSITY_NOTIFICATIONS_GET,
} from "../../Utils/InfoUploadingConst";
import "../Home/Home.scss";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";

function Home() {
  const [info, setInfo] = useState([]);
  const [events, setEvents] = useState([]);

  // State for managing active slide index
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const getData = async () => {
    const config = {
      method: "get",
      url: ADD_SLIDERS,
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        const activeData = res.data.data.filter(
          (element) =>
            element.status === "ACTIVE" && element.college_id == 1111006
        );
        console.log("activeData -", activeData);
        setInfo(activeData);
      })
      .catch((err) => {
        console.log(err);
      });

    const config1 = {
      method: "get",
      url: UNIVERSITY_NOTIFICATIONS_GET,
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios(config1)
      .then((res) => {
        res.data.data.forEach((element) => {
          element.attachments = JSON.parse(element.attachments);
        });
        res.data.data.sort((a, b) => b.id - a.id);
        const eventsData = res.data.data.filter(
          (element) => element.status === "ACTIVE"
        );
        console.log(eventsData);
        setEvents(eventsData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const PopUpData = info?.filter((element) => element.type == "PopUp");
  // console.log("PopUpData -", PopUpData);

  // Start automatic sliding when component mounts
  useEffect(() => {
    const interval = setInterval(() => {
      // Increment active slide index
      setActiveSlideIndex((prevIndex) =>
        prevIndex === info.length - 1 ? 0 : prevIndex + 1
      );
    }, 7000); // Change slide every 7 seconds (adjust as needed)

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, [info.length]); // Trigger effect when info length changes

  const banner1 =
    "https://s3.ap-south-1.amazonaws.com/verification.nexenstial.com/university-assets/media/undefined_1710490492836.jpeg";
  const banner2 =
    "https://s3.ap-south-1.amazonaws.com/verification.nexenstial.com/university-assets/media/undefined_1710490513608.jpeg";
  const banner3 =
    "https://s3.ap-south-1.amazonaws.com/verification.nexenstial.com/university-assets/media/undefined_1710490526788.jpeg";
  const banner4 =
    "https://s3.ap-south-1.amazonaws.com/verification.nexenstial.com/university-assets/media/undefined_1710490541981.jpeg";
  const banner5 =
    "https://s3.ap-south-1.amazonaws.com/verification.nexenstial.com/university-assets/media/undefined_1710490564605.jpeg";
  const banner6 =
    "https://s3.ap-south-1.amazonaws.com/verification.nexenstial.com/university-assets/media/undefined_1710490577780.jpeg";
  const banner7 =
    "https://s3.ap-south-1.amazonaws.com/verification.nexenstial.com/university-assets/media/undefined_1710490590160.jpeg";

  const banner8 =
    "https://s3.ap-south-1.amazonaws.com/verification.nexenstial.com/university-assets/media/undefined_1710490601529.jpeg";

  const banner9 =
    "https://s3.ap-south-1.amazonaws.com/verification.nexenstial.com/university-assets/media/undefined_1710490615126.jpeg";
  const banner10 =
    "https://s3.ap-south-1.amazonaws.com/verification.nexenstial.com/university-assets/media/undefined_1710490629331.jpeg";
  const banner11 =
    "https://s3.ap-south-1.amazonaws.com/verification.nexenstial.com/university-assets/media/undefined_1710490640489.jpeg";
  const banner12 =
    "https://s3.ap-south-1.amazonaws.com/verification.nexenstial.com/university-assets/media/undefined_1710490656012.jpeg";

  const hospital1 =
    "https://s3.ap-south-1.amazonaws.com/verification.nexenstial.com/university-assets/media/undefined_1710491251117.47";
  const hospital2 =
    "https://s3.ap-south-1.amazonaws.com/verification.nexenstial.com/university-assets/media/undefined_1710491273441.47";
  const hospital3 =
    "https://s3.ap-south-1.amazonaws.com/verification.nexenstial.com/university-assets/media/undefined_1710491284860.47";

  return (
    <div>
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          // className="modal-dialog modal-xl"
          className="modal-dialog modal-lg modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel" />
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <div className="row">
                <Swiper
                  modules={[Autoplay, Pagination, Navigation]}
                  autoplay={{ delay: 5000, disableOnInteraction: false }} // Autoplay with 7-second delay
                  slidesPerView={1}
                  navigation
                  pagination={{ clickable: true }}
                  onSlideChange={(swiper) =>
                    setActiveSlideIndex(swiper.realIndex)
                  }
                >
                  {info
                    .filter((item) => item.type == "PopUp")
                    .map((item, index) => (
                      <SwiperSlide key={index}>
                        <h3 className="text-center blink_me d-none d-sm-block">
                          {item.title}
                        </h3>

                        <img
                          src={item.image.replace(/"/g, "")}
                          className="d-block w-100 img-fluid PopUp"
                          alt="..."
                        />
                      </SwiperSlide>
                    ))}
                </Swiper>
              </div>
            </div>
            <div className="modal-footer"></div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          {/* main slider */}
          <div className="banner-area">
            <Swiper
              // Add Autoplay module and set autoplay options
              modules={[Autoplay, Pagination, Navigation]}
              autoplay={{ delay: 7000, disableOnInteraction: false }} // Autoplay with 7-second delay
              slidesPerView={1}
              navigation
              pagination={{ clickable: true }}
              onSlideChange={(swiper) => setActiveSlideIndex(swiper.realIndex)}
            >
              {/* Map through info to create swiper slides */}
              {info
                .filter((item) => item.type == "Main Slider")
                .map((item, index) => (
                  <SwiperSlide key={index}>
                    <img
                      src={item.image.replace(/"/g, "")}
                      className="d-block w-100 img-fluid h-80 main-slider"
                      alt="..."
                    />
                    <div className="carousel-caption d-none d-md-block">
                      <h1 className="text-light">{item.title}</h1>
                    </div>
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
        </div>

        <div className="campus-information-area pb-70 mt-5">
          <div className="container">
            <div className="row align-items-center">
              <div
                className="col-lg-12 aos-init aos-animate"
                data-aos="fade-right"
                data-aos-easing="ease-in-sine"
                data-aos-duration={1300}
                data-aos-once="true"
              >
                <div className="campus-content pr-20">
                  <div className="campus-title">
                    <h2>Campus Information</h2>
                    <p>
                      Swaminarayan Institute of Medical Sciences and Research is
                      located at Kalol near Ahmedabad. The region is well
                      connected to all the parts of the country by rail, road
                      and airways. Swaminarayan Institute of Medical Sciences
                      and Research attached to PSM Hospital which is a 390+ bed
                      Multispeciality Hospital.
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-12 aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="ease-in-sine"
                data-aos-duration={1300}
                data-aos-once="true"
              >
                <div className="row">
                  <div className="col-sm-6">
                    <div className="card-header">
                      <h2>Hospital</h2>
                    </div>

                    <div
                      id="carouselExampleIndicators"
                      className="carousel slide"
                      data-bs-ride="carousel"
                    >
                      <ol className="carousel-indicators">
                        <li
                          data-bs-target="#carouselExampleIndicators"
                          data-bs-slide-to={0}
                          className="active"
                        />
                        <li
                          data-bs-target="#carouselExampleIndicators"
                          data-bs-slide-to={1}
                        />
                        <li
                          data-bs-target="#carouselExampleIndicators"
                          data-bs-slide-to={2}
                        />
                        <li
                          data-bs-target="#carouselExampleIndicators"
                          data-bs-slide-to={3}
                        />
                        <li
                          data-bs-target="#carouselExampleIndicators"
                          data-bs-slide-to={4}
                        />
                        <li
                          data-bs-target="#carouselExampleIndicators"
                          data-bs-slide-to={5}
                        />
                        <li
                          data-bs-target="#carouselExampleIndicators"
                          data-bs-slide-to={6}
                        />
                        <li
                          data-bs-target="#carouselExampleIndicators"
                          data-bs-slide-to={7}
                        />
                        <li
                          data-bs-target="#carouselExampleIndicators"
                          data-bs-slide-to={8}
                        />
                        <li
                          data-bs-target="#carouselExampleIndicators"
                          data-bs-slide-to={9}
                        />
                        <li
                          data-bs-target="#carouselExampleIndicators"
                          data-bs-slide-to={10}
                        />
                        <li
                          data-bs-target="#carouselExampleIndicators"
                          data-bs-slide-to={11}
                        />
                      </ol>
                      <div
                        className="carousel-inner"
                        style={{ width: "620px", height: "430px" }}
                      >
                        <div className="carousel-item active">
                          <img
                            src={banner1}
                            className="d-block w-100"
                            alt="..."
                          />
                        </div>
                        <div className="carousel-item">
                          <img
                            src={banner2}
                            className="d-block w-100"
                            alt="..."
                          />
                        </div>
                        <div className="carousel-item">
                          <img
                            src={banner3}
                            className="d-block w-100"
                            alt="..."
                          />
                        </div>
                        <div className="carousel-item">
                          <img
                            src={banner4}
                            className="d-block w-100"
                            alt="..."
                          />
                        </div>
                        <div className="carousel-item">
                          <img
                            src={banner5}
                            className="d-block w-100"
                            alt="..."
                          />
                        </div>
                        <div className="carousel-item">
                          <img
                            src={banner6}
                            className="d-block w-100"
                            alt="..."
                          />
                        </div>
                        <div className="carousel-item">
                          <img
                            src={banner7}
                            className="d-block w-100"
                            alt="..."
                          />
                        </div>
                        <div className="carousel-item">
                          <img
                            src={banner8}
                            className="d-block w-100"
                            alt="..."
                          />
                        </div>
                        <div className="carousel-item">
                          <img
                            src={banner9}
                            className="d-block w-100"
                            alt="..."
                          />
                        </div>
                        <div className="carousel-item">
                          <img
                            src={banner10}
                            className="d-block w-100"
                            alt="..."
                          />
                        </div>
                        <div className="carousel-item">
                          <img
                            src={banner11}
                            className="d-block w-100"
                            alt="..."
                          />
                        </div>
                        <div className="carousel-item">
                          <img
                            src={banner12}
                            className="d-block w-100"
                            alt="..."
                          />
                        </div>
                      </div>
                      <button
                        className="carousel-control-prev"
                        type="button"
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide="prev"
                      >
                        <span
                          className="carousel-control-prev-icon"
                          aria-hidden="true"
                        />
                      </button>
                      <button
                        className="carousel-control-next"
                        type="button"
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide="next"
                      >
                        <span
                          className="carousel-control-next-icon"
                          aria-hidden="true"
                        />
                      </button>
                    </div>

                    {/* <div className="campus-image pl-20">
                  <img src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/sims/clg3.jpeg" alt="Image" />
                </div> */}
                  </div>

                  <div className="col-sm-6">
                    <div className="card-header">
                      <h2>College</h2>
                    </div>

                    <div
                      id="carouselExampleIndicators1"
                      className="carousel slide"
                      data-bs-ride="carousel"
                    >
                      <ol className="carousel-indicators">
                        <li
                          data-bs-target="#carouselExampleIndicators1"
                          data-bs-slide-to={0}
                          className="active"
                        />
                        <li
                          data-bs-target="#carouselExampleIndicators1"
                          data-bs-slide-to={1}
                        />
                        <li
                          data-bs-target="#carouselExampleIndicators1"
                          data-bs-slide-to={2}
                        />
                      </ol>
                      <div
                        className="carousel-inner"
                        style={{ width: "620px", height: "430px" }}
                      >
                        <div className="carousel-item active">
                          <img
                            src={hospital1}
                            className="d-block w-100"
                            alt="..."
                          />
                        </div>
                        <div className="carousel-item">
                          <img
                            src={hospital2}
                            className="d-block w-100"
                            alt="..."
                          />
                        </div>
                        <div className="carousel-item">
                          <img
                            src={hospital3}
                            className="d-block w-100"
                            alt="..."
                          />
                        </div>
                      </div>
                      <button
                        className="carousel-control-prev"
                        type="button"
                        data-bs-target="#carouselExampleIndicators1"
                        data-bs-slide="prev"
                      >
                        <span
                          className="carousel-control-prev-icon"
                          aria-hidden="true"
                        />
                      </button>
                      <button
                        className="carousel-control-next"
                        type="button"
                        data-bs-target="#carouselExampleIndicators1"
                        data-bs-slide="next"
                      >
                        <span
                          className="carousel-control-next-icon"
                          aria-hidden="true"
                        />
                      </button>
                    </div>

                    {/* <div className="campus-image pl-20">
                  <img src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/sims/clg1.jpeg" alt="Image" height="380px" />
                </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <br />

        {/* <div className=" description container mt-4">
          <h1 className="text-center">DEPARTMENTS</h1>
          <br />

          <div
            className="nav nav-tabs d-flex flex-start mt-2"
            id="nav-tab"
            role="tablist"
          >
            <button
              className="nav-link active"
              id="nav-instructor-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-instructor"
              type="button"
              role="tab"
              aria-controls="nav-instructor"
              aria-selected="false"
              tabIndex={-1}
            >
              Undergraduate
            </button>
            
          </div>

          <div class="tab-content" id="nav-tabContent">
            <div
              class="tab-pane fade active show"
              id="nav-instructor"
              role="tabpanel"
              aria-labelledby="nav-instructor-tab"
            >
              <div className="row justify-content-center">
                <div className="col-lg-3 col-sm-6">
                  <div className="single-academics-card3">
                    <div className="icon">
                      <i class="ri-dossier-fill"></i>
                    </div>

                    <h3>Anatomy</h3>
                  </div>
                </div>

                <div className="col-lg-3 col-sm-6">
                  <div className="single-academics-card3">
                    <div className="icon">
                      <i class="ri-dossier-fill"></i>
                    </div>

                    <h3>Phisiology</h3>
                  </div>
                </div>

                <div className="col-lg-3 col-sm-6">
                  <div className="single-academics-card3">
                    <div className="icon">
                      <i class="ri-dossier-fill"></i>
                    </div>

                    <h3>Biochemistry</h3>
                  </div>
                </div>

                <div className="col-lg-3 col-sm-6">
                  <div className="single-academics-card3">
                    <div className="icon">
                      <i class="ri-dossier-fill"></i>
                    </div>

                    <h3>Pharmacology</h3>
                  </div>
                </div>

                <div className="col-lg-3 col-sm-6">
                  <div className="single-academics-card3">
                    <div className="icon">
                      <i class="ri-dossier-fill"></i>
                    </div>

                    <h3>Pathology</h3>
                  </div>
                </div>

                <div className="col-lg-3 col-sm-6">
                  <div className="single-academics-card3">
                    <div className="icon">
                      <i class="ri-dossier-fill"></i>
                    </div>

                    <h3>Microbiology</h3>
                  </div>
                </div>

                <div className="col-lg-3 col-sm-6">
                  <div className="single-academics-card3">
                    <div className="icon">
                      <i class="ri-dossier-fill"></i>
                    </div>

                    <h3>Forensic Medicine</h3>
                  </div>
                </div>

                <div className="col-lg-3 col-sm-6">
                  <div className="single-academics-card3">
                    <div className="icon">
                      <i class="ri-dossier-fill"></i>
                    </div>

                    <h3>Community Medicine</h3>
                  </div>
                </div>

                <div className="col-lg-3 col-sm-6">
                  <div className="single-academics-card3">
                    <div className="icon">
                      <i class="ri-dossier-fill"></i>
                    </div>

                    <h3>General Medicine</h3>
                  </div>
                </div>

                <div className="col-lg-3 col-sm-6">
                  <div className="single-academics-card3">
                    <div className="icon">
                      <i class="ri-dossier-fill"></i>
                    </div>

                    <h3>Pediatrics</h3>
                  </div>
                </div>

                <div className="col-lg-3 col-sm-6">
                  <div className="single-academics-card3">
                    <div className="icon">
                      <i class="ri-dossier-fill"></i>
                    </div>

                    <h3>Dermatology</h3>
                  </div>
                </div>

                <div className="col-lg-3 col-sm-6">
                  <div className="single-academics-card3">
                    <div className="icon">
                      <i class="ri-dossier-fill"></i>
                    </div>

                    <h3>Psychiatry</h3>
                  </div>
                </div>

                <div className="col-lg-3 col-sm-6">
                  <div className="single-academics-card3">
                    <div className="icon">
                      <i class="ri-dossier-fill"></i>
                    </div>

                    <h3>General Surgery</h3>
                  </div>
                </div>

                <div className="col-lg-3 col-sm-6">
                  <div className="single-academics-card3">
                    <div className="icon">
                      <i class="ri-dossier-fill"></i>
                    </div>

                    <h3>Orthopedics</h3>
                  </div>
                </div>

                <div className="col-lg-3 col-sm-6">
                  <div className="single-academics-card3">
                    <div className="icon">
                      <i class="ri-dossier-fill"></i>
                    </div>

                    <h3>Ophthalmology</h3>
                  </div>
                </div>

                <div className="col-lg-3 col-sm-6">
                  <div className="single-academics-card3">
                    <div className="icon">
                      <i class="ri-dossier-fill"></i>
                    </div>

                    <h3>Otorhinolaryngology</h3>
                  </div>
                </div>

                <div className="col-lg-3 col-sm-6">
                  <div className="single-academics-card3">
                    <div className="icon">
                      <i class="ri-dossier-fill"></i>
                    </div>

                    <h3>Obstetrcis & Gynecology</h3>
                  </div>
                </div>

                <div className="col-lg-3 col-sm-6">
                  <div className="single-academics-card3">
                    <div className="icon">
                      <i class="ri-dossier-fill"></i>
                    </div>

                    <h3>Radiodiagnosis</h3>
                  </div>
                </div>

                <div className="col-lg-3 col-sm-6">
                  <div className="single-academics-card3">
                    <div className="icon">
                      <i class="ri-dossier-fill"></i>
                    </div>

                    <h3>Anesthesia</h3>
                  </div>
                </div>

                <div className="col-lg-3 col-sm-6">
                  <div className="single-academics-card3">
                    <div className="icon">
                      <i class="ri-dossier-fill"></i>
                    </div>

                    <h3>Dentistry</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br /> */}

        {/* <h1 className="text-center mb-5">Campus Life</h1>

        <div className="row">
          <div className="d-flex justify-content-center align-items-center flex-wrap ">
            <div
              className="text-center mx-5 aos-init aos-animate"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="200"
              data-aos-once="true"
            >
              <img
                src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/campus.PNG"
                className="img-fluid icons mb-3"
                width="150"
                alt=""
                srcset=""
              />{" "}
              <br />
              <h3 className="mb-5">LUSH GREEN CAMPUS </h3>
            </div>
            <div
              className="text-center mx-5 aos-init aos-animate"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="200"
              data-aos-once="true"
            >
              <img
                src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/clasroom.PNG"
                className="img-fluid icons mb-3"
                width="70"
                alt=""
                srcset=""
              />{" "}
              <br />
              <h3 className="mb-5">DIGITAL CLASSROOM </h3>
            </div>
            <div
              className="text-center mx-5 aos-init aos-animate"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="200"
              data-aos-once="true"
            >
              <img
                src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/library+%26+labs.PNG"
                className
                icons="img-fluid mb-3"
                width="100"
                alt=""
                srcset=""
              />{" "}
              <br />
              <h3 className="mb-5">LIBRARY & LABS </h3>
            </div>
            <div
              className="text-center mx-5 aos-init aos-animate"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="200"
              data-aos-once="true"
            >
              <img
                src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/sports.PNG"
                className="img-fluid icons mb-3"
                width="100"
                alt=""
                srcset=""
              />{" "}
              <br />
              <h3 className="mb-5">SPORTS GROUND</h3>
            </div>
            <div
              className="text-center mx-5 aos-init aos-animate"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="200"
              data-aos-once="true"
            >
              <img
                src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/hostel.PNG"
                className="img-fluid icons mb-3"
                width="100"
                alt=""
                srcset=""
              />{" "}
              <br />
              <h3 className="mb-5">HOSTEL FACILITY</h3>
            </div>

            <div
              className="text-center mx-5 aos-init aos-animate"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="200"
              data-aos-once="true"
            >
              <img
                src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/bus.PNG"
                className="img-fluid icons mb-3"
                width="100"
                alt=""
                srcset=""
              />{" "}
              <br />
              <h3 className="mb-5">TRANSPORT FACILITY</h3>
            </div>
            <div
              className="text-center mx-5 aos-init aos-animate"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="200"
              data-aos-once="true"
            >
              <img
                src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/placement.PNG"
                className="img-fluid icons mb-3"
                width="90"
                alt=""
                srcset=""
              />{" "}
              <br />
              <h3 className="mb-5">TRAINING AND PLACEMENT</h3>
            </div>
            <div
              className="text-center mx-5 aos-init aos-animate"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="200"
              data-aos-once="true"
            >
              <img
                src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/canteen.PNG"
                className="img-fluid icons mb-3"
                width="140"
                alt=""
                srcset=""
              />{" "}
              <br />
              <h3 className="mb-5">CAFETERIA</h3>
            </div>
            <div
              className="text-center mx-5 aos-init aos-animate"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="200"
              data-aos-once="true"
            >
              <img
                src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/wifi.PNG"
                className="img-fluid icons mb-4"
                width="120"
                alt=""
                srcset=""
              />{" "}
              <br />
              <h3 className="mb-5">WIFI CAMPUS</h3>
            </div>
            <div
              className="text-center mx-5 aos-init aos-animate"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="200"
              data-aos-once="true"
            >
              <img
                src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/gym.PNG"
                className="img-fluid icons mb-3"
                width="80"
                alt=""
                srcset=""
              />{" "}
              <br />
              <h3 className="mb-5">GYM</h3>
            </div>
          </div>
        </div> */}

        <div className="academic-area pt-100 pb-70">
          <div className="container">
            <div className="section-title">
              <h2>College Feature</h2>
            </div>
            <div className="row justify-content-center">
              <div
                className="col-lg-4 col-md-6 aos-init aos-animate"
                data-aos="fade-up"
                data-aos-duration={1200}
                data-aos-delay={200}
                data-aos-once="true"
              >
                <div className="single-academics-card2 bg-1">
                  <div className="serial">
                    <p>01.</p>
                  </div>
                  <div className="academic-content">
                    <div className="academic-top-content">
                      <i className="flaticon-graduation" />
                      <a href="https://www.swaminarayanuniversity.ac.in/sims/events">
                        <h3>PHOTO GALLERY</h3>
                      </a>
                    </div>
                    <p>
                      Glimpses of some of the events and campus infrastructure
                    </p>
                    <a
                      href="https://www.swaminarayanuniversity.ac.in/sims/events"
                      className="read-more-btn white-color"
                    >
                      View More
                      <i className="flaticon-next" />
                    </a>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6 aos-init aos-animate"
                data-aos="fade-up"
                data-aos-duration={1200}
                data-aos-delay={400}
                data-aos-once="true"
              >
                <div className="single-academics-card2 bg-3">
                  <div className="serial">
                    <p>02.</p>
                  </div>
                  <div className="academic-content">
                    <div className="academic-top-content">
                      <i className="flaticon-graduation" />
                      <a href="#">
                        <h3>STUDENT CORNER</h3>
                      </a>
                    </div>
                    <p>
                      Student login space for progress reports, attendance and
                      more
                    </p>
                    <a href="#" className="read-more-btn white-color">
                      Login
                      <i className="flaticon-next" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* popup */}

      {PopUpData && PopUpData?.length > 0 && (
        <Helmet>
          <script type="text/javascript">
            $('#exampleModal').modal('show');
          </script>
        </Helmet>
      )}
    </div>
  );
}

export default Home;
