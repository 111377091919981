import React from 'react';
import axios from 'axios';
import { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ACHIEVEMENTS_GET } from '../../Utils/InfoUploadingConst';

function AchievementsPharmacy() {

    const [data, setData] = useState([]);

    const navigate = useNavigate();
    function customSortByDate(item1, item2) {
      return item2.date.localeCompare(item1.date);
    }
  
    const getData = async () => {
      const config = {
        method: "get",
        url: ACHIEVEMENTS_GET,
        headers: {
          "Content-Type": "application/json",
        },
      };
  
      await axios(config)
        .then((res) => {
          const AchievementsData = res.data.data.filter(element => element.college_id == "1111008" && element.status === "ACTIVE");        
          
          AchievementsData.sort(customSortByDate);
          AchievementsData.forEach((element) => {
            element.attachments = JSON.parse(element.attachments);
          });        
          setData(AchievementsData);
        })
        .catch((err) => {
          console.log(err);
        });
    };
  
    useEffect(() => {
      getData();
    }, []);
  
    const next = async (id, data1, images) => {
      console.log("error here");
      navigate(`/pharmacy/Details/${id}`, {
        state: {
          images: images,
          data: data1,
        },
      });
    };
  
    
      return (
        <div className="Achievements">
          <div class="page-banner-area bg-3">
            <div class="container">
              <div class="page-banner-content">
                <h1>Achievements , Awards and Medals </h1>
                <ul>
                  <li>
                    {" "}
                    <Link to={"./../"}> Home </Link>
                  </li>
    
                  <li>Achievements , Awards and Medals</li>
                </ul>
              </div>
            </div>
          </div>
    
          <div className="events-area pt-100 pb-70">
          <div className="container d-flex">
            <div className="row justify-content-center">
              {data &&
                data?.map((item, key) => {
                  return (
                    <>
                      <div
                        className="col-lg-3 col-md-4"
                        style={{ cursor: "pointer" }}
                      >
                        <a
                          onClick={() => next(item?.id, item, item?.attachments)}
                        >
                          <div className="single-events-card style-4">
                            <div className="events-image">
                              <img
                                className="eventimage eventssize"
                                src={item?.attachments[0]}
                                alt="Image"
                              />
                              <div className="date">
                                <span>{item?.date.split("T")[0].slice(-2)}</span>
                                <p>{item?.month}</p>
                              </div>
                            </div>
                            <div className="events-content">
                              <h4>{item?.title}</h4>
                            </div>
                          </div>
                        </a>
                      </div>
                    </>
                  );
                })}
    
            </div>
          </div>
        </div>
    
          
        </div>
    )
  }

export default AchievementsPharmacy;
