import React from "react";
import { Link } from "react-router-dom";

// Image Urls
//Classroom
const classroom1 = "https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/facilities/classroom-1.jpg";
const classroom2 = "https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/facilities/classroom-2.jpg";
const classroom3 = "https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/facilities/classroom-3.jpg";
const classroom4 = "https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/facilities/classroom-4.jpg";

//Analytic Lab
const AnalyticLab1 = "https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/facilities/analytic-lab-1.jpg";
const AnalyticLab2 = "https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/facilities/analytic-lab-2.jpg";

//Biology Lab
const BiologyLab1 = "https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/facilities/biology-lab-1.jpg";

//Botany Lab
const BotanyLab1 = "https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/facilities/botany-lab-1.jpg";

//Chemistry Lab
const ChemistryLab1 = "https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/facilities/chemistry-lab-1.jpg";
const ChemistryLab2 = "https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/facilities/chemistry-lab-2.jpg";
const ChemistryLab3 = "https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/facilities/chemistry-lab-3.jpg";

//Microbilogy Lab
const MicrobiologyLab1 =
  "https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/facilities/microbiology-lab-1.jpg";

//Physics Lab
const PhysicsLab1 = "https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/facilities/physics-lab-1.jpg";
const PhysicsLab2 = "https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/facilities/physics-lab-2.jpg";

//Zoology Lab
const ZoologyLab1 = "https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/facilities/chemistry-lab-1.jpg";

function InfrastructureEngineering() {
  return (
    <div className="ResidentialFacilities">
      <>
        <div className="campus-life-area pt-100 pb-70">
          <div className="container">
            <h3 className="text-center mb-4 ">Infrastructure Details</h3>
            <h4>Corporate Facilities</h4>
                  <p style={{ textAlign: "justify" }}>
                    The institute has developed with adequate general campus
                    facilities such as office space, faculty rooms, library,
                    classrooms, seminar hall, canteen, wash rooms, student
                    amenities and residential facilities for students and
                    faculty to facilitate the conduct of the various academic,
                    research and extension activities associated with the
                    teaching and learning processes.
                    <br /> <br />
                    The entire campus is covered under CCTV surveillance and
                    24x7 security service as well as fire safety. Adequate
                    separate cabins/chambers with essential facilities have been
                    provided to all staff members of corresponding departments
                    including administration office.
                  </p>
          </div>
        </div>

        <div className="row justify-content-center ">
          <h3 className="text-center mb-4 ">Classrooms</h3>
          <div className="col-lg-3 col-md-6">
            <div className="single-campus-card">
              <div className="img">
                <a href="">
                  <img src={classroom1} alt="Image" />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="single-campus-card">
              <div className="img">
                <a href="">
                  <img src={classroom2} alt="Image" />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="single-campus-card">
              <div className="img">
                <a href="">
                  <img src={classroom3} alt="Image" />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="single-campus-card">
              <div className="img">
                <a href="">
                  <img src={classroom4} alt="Image" />
                </a>
              </div>
            </div>
          </div>
          <object
            height={800}
            data="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/facilities/Infrastructural+Details.pdf#toolbar=0"
            type="application/pdf"
            width="100%"
          >
            <p>
              It appears you don't have a PDF plugin for this browser. No
              biggie... you can{" "}
              <a href="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/facilities/Infrastructural+Details.pdf#toolbar=0">
                click here to download the PDF file.
              </a>
            </p>
          </object>

        </div>
        
      </>
    </div>
  );
}

export default InfrastructureEngineering;
