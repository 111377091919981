import React from "react";
import { Link } from "react-router-dom";
import "../Connect/Downloads/Brochure.scss";
import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { PHOTO_GALLERY_GET } from "../../Utils/InfoUploadingConst";

function PhotoGalleryHomeopathy() {
  const [data, setData] = useState([]);

  const navigate = useNavigate();
  function customSortByDate(item1, item2) {
    return item2.date.localeCompare(item1.date);
  }

  const getData = async () => {
    const config = {
      method: "get",
      url: PHOTO_GALLERY_GET,
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        const PhotoGalleryHomeo = res.data.data.filter(
          (element) =>
            element.college_id == 1111007 && element.status === "ACTIVE"
        );

        PhotoGalleryHomeo.sort(customSortByDate);
        PhotoGalleryHomeo.forEach((element) => {
          element.attachments = JSON.parse(element.attachments);
        });
        setData(PhotoGalleryHomeo);
        console.log("data -", PhotoGalleryHomeo);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, [data]);

  const next = async (id, data1, images) => {
    console.log("error here");
    navigate(`/homoeopathy/Gallery-Photos/${id}`, {
      state: {
        images: images,
        data: data1,
      },
    });
  };

  console.log("data Gallery-", data);

  return (
    <div>
      <div className="Events ">
        <div class="page-banner-area bg-3">
          <div class="container">
            <div class="page-banner-content">
              <h1>Gallery Photos</h1>
              <ul>
                <li>
                  {" "}
                  <Link to={"./../"}> Home </Link>
                </li>

                <li>Photo Gallery</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="events-area pt-100">
          <div className="container d-flex">
            <div className="row justify-content-center">
              {data &&
                data?.map((item, key) => {
                  return (
                    <>
                      <div
                        className="col-lg-3 col-md-4"
                        style={{ cursor: "pointer" }}
                      >
                        <a
                          onClick={() =>
                            next(item?.id, item, item?.attachments)
                          }
                        >
                          <div className="single-events-card style-4">
                            <div className="events-image">
                              <img
                                className="eventimage gallerysize"
                                src={item?.attachments}
                                alt="Image"
                              />
                              <br />
                              <h4>{item?.title}</h4>
                            </div>
                            {/* <div className="events-content">
                            <h4>{item?.title}</h4>
                          </div> */}
                          </div>
                        </a>
                        <br />
                      </div>
                    </>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PhotoGalleryHomeopathy;
