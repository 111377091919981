import { BASE_URL } from "./apiConst";

//Media
export const MEDIA_GET = `${BASE_URL}/infoUploading/get`;

export const MEDIA_GET1 = `${BASE_URL}/infoUploading/mediaget`;

export const MEDIA_UPDATE = `${BASE_URL}/infoUploading/update`;

//Exam Notifications
export const EXAM_NOTY_GET = `${BASE_URL}/infoUploading/get_exam_noty`;
export const EXAM_NOTY_GET1 = `${BASE_URL}/infoUploading/get_exam_noty1`;

export const EXAM_NOTY_UPDATE = `${BASE_URL}/infoUploading/update_exam_noty`;

//Time Table Notifications
export const TT_NOTY_GET = `${BASE_URL}/infoUploading/getTTnoty`;
export const TT_NOTY_GET1 = `${BASE_URL}/infoUploading/getTTnoty1`;

export const TT_NOTY_UPDATE = `${BASE_URL}/infoUploading/updateTTnoty`;

//Homeopathy Event Notifications 
export const HOMEO_EVENT_GET = `${BASE_URL}/infoUploading/homeoeventget`;

export const HOMEO_EVENT_UPDATE = `${BASE_URL}/infoUploading/homeoeventupdate`;

//Research and Publication Notifications
export const RESEARCH_PUBLICATION_GET = `${BASE_URL}/infoUploading/getResearchandPublication`;

export const RESEARCH_PUBLICATION_UPDATE = `${BASE_URL}/infoUploading/updateResearchandPublication`;

//Admission Notifications
export const ADMISSION_NOTIFICATIONS_GET = `${BASE_URL}/infoUploading/getAdmissionNotifications`;

//University Notifications
//news, announcements and gallery & events
export const UNIVERSITY_NOTIFICATIONS_GET = `${BASE_URL}/infoUploading/getUniversityNotifications`;

//Event Calendar
export const EVENT_CALENDAR_GET = `${BASE_URL}/infoUploading/getEventCalendar`;

//academic Calendar
export const ACADEMIC_CALENDAR_GET = `${BASE_URL}/infoUploading/getAcademicCalendar`;

export const STAFF_DETAILS_NEW_GET = `${BASE_URL}/infoUploading/getStaffDetailsNew`;

//Recruitment
export const RECRUITMENT_GET = `${BASE_URL}/infoUploading/getRecruitment`;

export const RECRUITMENT1_GET = `${BASE_URL}/infoUploading/getRecruitment1`;

//Homeopathy_StudentsList
export const STUDENTS_LIST_GET = `${BASE_URL}/infoUploading/getHomeopathyStudentsList`;

//Homeopathy_Students_Results
export const STUDENTS_RESULTS_GET = `${BASE_URL}/infoUploading/getHomeopathyResults`;

//College_Permission
export const COLLEGE_PERMISSION_GET = `${BASE_URL}/infoUploading/getCollegePermission`;

//College_Affiliation
export const COLLEGE_AFFILIATION_GET = `${BASE_URL}/infoUploading/getCollegeAffiliation`;

//Syllabus_Upload
export const SYLLABUS_GET = `${BASE_URL}/infoUploading/getSyllabusUpload`;
export const SYLLABUS_GET1 = `${BASE_URL}/infoUploading/getSyllabusUpload1`;  //engineering
export const SYLLABUS_GET2 = `${BASE_URL}/infoUploading/getSyllabusUpload2`;  //law
export const SYLLABUS_GET3 = `${BASE_URL}/infoUploading/getSyllabusUpload3`;  //nursing
export const SYLLABUS_GET4 = `${BASE_URL}/infoUploading/getSyllabusUpload4`;  //arts commerce
export const SYLLABUS_GET5 = `${BASE_URL}/infoUploading/getSyllabusUpload5`;  //homeo
export const SYLLABUS_GET6 = `${BASE_URL}/infoUploading/getSyllabusUpload6`;  //pharmacy
export const SYLLABUS_GET7 = `${BASE_URL}/infoUploading/getSyllabusUpload7`;  //it-cs
export const SYLLABUS_GET8 = `${BASE_URL}/infoUploading/getSyllabusUpload8`;  //education
export const SYLLABUS_GET9 = `${BASE_URL}/infoUploading/getSyllabusUpload9`;  //science
export const SYLLABUS_GET10 = `${BASE_URL}/infoUploading/getSyllabusUpload10`;  //ayurveda
export const SYLLABUS_GET11 = `${BASE_URL}/infoUploading/getSyllabusUpload11`;  //arts
export const SYLLABUS_GET12 = `${BASE_URL}/infoUploading/getSyllabusUpload12`;  //physiotherapy
export const SYLLABUS_GET16 = `${BASE_URL}/infoUploading/getSyllabusUpload16`;  //design

//OPD_Services
export const OPD_SERVICES_GET = `${BASE_URL}/infoUploading/getOPDServices`;

//IPD_Services
export const IPD_SERVICES_GET = `${BASE_URL}/infoUploading/getIPDServices`;

//Companies for Collge Placements
export const COMPANIES_GET = `${BASE_URL}/infoUploading/getCompanies`;
export const COMPANIES_PUT = `${BASE_URL}/infoUploading/updateCompanies`;

//Placements of Students in collage Placements
export const PLACEMENTS_GET = `${BASE_URL}/infoUploading/getPlacements`;
export const PLACEMENTS_GET2 = `${BASE_URL}/infoUploading/getPlacements2`;
export const PLACEMENTS_PUT = `${BASE_URL}/infoUploading/updatePlacements`;

//student_info

export const STUDENT_INFO = `${BASE_URL}/infoUploading/getstudentsinfo`;

//Employee
export const EMPLOYEE1 = `${BASE_URL}/api/employee`;

//NewStaff

export const NEW_WEB_STAFF = `${BASE_URL}/employee/newStaff`;

//sliders

export const ADD_SLIDERS = `${BASE_URL}/infoUploading/sliders`; //create, get, put

//committees

export const ADD_COMMITTEES = `${BASE_URL}/infoUploading/addCommittees`; //create, get, put

//pci_sif

export const PCI_SIF_GET = `${BASE_URL}/infoUploading/getPCISIF`; // get

//achievements

export const ACHIEVEMENTS_GET = `${BASE_URL}/infoUploading/getAchievements`; //get

export const ACHIEVEMENTS_UPDATE = `${BASE_URL}/infoUploading/updateAchievements`; //put

//photo_gallery

export const PHOTO_GALLERY_GET = `${BASE_URL}/infoUploading/getPhotoGallery`;

export const PHOTO_GALLERY_UPDATE = `${BASE_URL}/infoUploading/updatePhotoGallery`;

//hospital_staff_attendance
export const HOSPITAL_STAFF_ATTENDANCE_GET = `${BASE_URL}/infoUploading/getHospitalStaffAttendance`;
export const HOSPITAL_STAFF_ATTENDANCE_UPDATE = `${BASE_URL}/infoUploading/updateHospitalStaffAttendance`;

export const MILESTONES = `${BASE_URL}/infoUploading/milestones`;

export const APPROVALS = `${BASE_URL}/infoUploading/approvals`;
