import VicePresident2Design from "../Pages/Design/VicePresident2";
import VicePresident3Design from "../Pages/Design/VicePresident3";

export const ROUTES = {
    Profile:'/profile',
    PhdExamForm:"/PhdExamForm",
    PhdExamPayment:"/PhdExamPayment",
    PhdFormPrint:"/PhdFormPrint",
    Payment_Success:"/PaymentSuccess",
    MainExamPayment:"/Exampayment",
    PayFees:"/payDueFees",
    StudentFeePayment:"/studentDuePayment",
    VicePresident: "/engineering/Vice-President",
    VicePresident2: "/engineering/VicePresident",
    VicePresident3: "/engineering/vice-President-Profile",

    VicePresidentsims: "/sims/Vice-President",
    VicePresident2sims: "/sims/VicePresident",
    VicePresident3sims: "/sims/vice-President-Profile",

    VicePresidentAyu: "/ayurveda/Vice-President",
    VicePresident2Ayu: "/ayurveda/VicePresident",
    VicePresident3Ayu: "/ayurveda/vice-President-Profile",

    VicePresidentLaw: "/law/Vice-President",
    VicePresident2Law: "/law/VicePresident",
    VicePresident3Law: "/law/vice-President-Profile",

    VicePresidentNursing: "/Nursing/Vice-President",
    VicePresident2Nursing: "/Nursing/VicePresident",
    VicePresident3Nursing: "/Nursing/vice-President-Profile",

    VicePresidentArts: "/arts/Vice-President",
    VicePresident2Arts: "/arts/VicePresident",
    VicePresident3Arts: "/arts/vice-President-Profile",

    VicePresidentArtsCommerce: "/commerce/Vice-President",
    VicePresident2ArtsCommerce: "/commerce/VicePresident",
    VicePresident3ArtsCommerce: "/commerce/vice-President-Profile",

    VicePresidentHomeo: "/Homoeopathy/Vice-President",
    VicePresident2Homeo: "/Homoeopathy/VicePresident",
    VicePresident3Homeo: "/Homoeopathy/vice-President-Profile",

    VicePresidentPharmacy: "/pharmacy/Vice-President",
    VicePresident2Pharmacy: "/pharmacy/VicePresident",
    VicePresident3Pharmacy: "/pharmacy/vice-President-Profile",

    VicePresidentITCS: "/it-cs/Vice-President",
    VicePresident2ITCS: "/it-cs/VicePresident",
    VicePresident3ITCS: "/it-cs/vice-President-Profile",

    VicePresidentEducation: "/education/Vice-President",
    VicePresident2Education: "/education/VicePresident",
    VicePresident3Education: "/education/vice-President-Profile",

    VicePresidentScience: "/science/Vice-President",
    VicePresident2Science: "/science/VicePresident",
    VicePresident3Science: "/science/vice-President-Profile",

    VicePresidentPhysio: "/Physiotherapy/Vice-President",
    VicePresident2Physio: "/Physiotherapy/VicePresident",
    VicePresident3Physio: "/Physiotherapy/vice-President-Profile",

    VicePresidentDesign: "/Design/Vice-President",
    VicePresident2Design: "/Design/VicePresident",
    VicePresident3Design: "/Design/vice-President-Profile",
}