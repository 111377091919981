import React from 'react'

function Admission() {
  return (
    <div>

<div className="container mt-5 mb-5">


  <div className="row register" style={{maxWidth: "100%"}}>
    <div className="col-md-12">
            <p>
              <span style={{ fontSize: "18pt" }}>
                <h3>Admissions Criteria</h3>
              </span>
              <br />
            </p>
      <p>
      The process of Admission to BHMS Course starts after declaration of results of 12th (HSC) Science and NEET. 
      The minimum requirement for admission includes passing marks in Physics, Chemistry. Biology with English and 
      with compulsory NEET.  
      </p>
      <p>
        Every year the admissions are done by Gujarat State Govt. through{" "}
        <a href="#">
        Admission committee for professional medical educational courses (ACPMEC) Admission Committee for Professional Under 
        Graduate Medical Courses (ACPUGMEC)
        </a>
        Constituted under Section 4 of the Gujarat Professional Medical Education 
        Institutions, Regulation of Admission and Fixation of Fees Act., 2007, Guj.3 of 2008 Government of Gujarat, 
        Gandhinagar
      </p>
      <p>
        The Government of Gujarat, in the Legislative Assembly has passed an act, Act No.3 of 2008 called 
        “Gujarat Professional Educational Medical Colleges or Institutions (Regulation of Admission and Fixation 
        of Fees) Act, 2007” to make special provision for regulation of admission in the Professional Medical 
        educational courses in the State and fixation of fees in such colleges or institutions and for matter 
        connected therewith. As per the powers conferred to State Government, in Section 20(1) of the said Act, 
        the Government of Gujarat, by way of a notification had constituted “Admission Committee for 
        Professional Under Graduate Medical Courses (ACPUGMEC)” to regulate the admission of candidates to the 
        Professional Medical/ Dental/ Ayurveda/ Homeopathy/ Naturopathy degree courses. The mission assigned to this 
        Committee is to carry out the counselling process in a fair and transparent manner. The admission process is 
        conducted by this Committee strictly on the basis of merit of the candidate.
      </p>
      <p>
        For admissions to first year B.H.M.S. All India quota is 15 seats, Gujarat State Government quota is 60 seats, 
        NRI quota is 15 seats and Management quota is 10 seats out of total 100 seats each year.
      </p>
      <p>
        For fees according to the Fee Regulatory Committee (Government of Gujarat)
      </p>
      <p>
        For admissions and inquiry:
        <br />
        Contact Us:
      </p>
      <p>
        Dr Dinesh Rao,
        Contact - 9323691618
      </p>
      <p>
        Email: sshckalol@gmail.com
        <br />
      </p>
    </div>
  </div>
</div>





    </div>
  )
}

export default Admission