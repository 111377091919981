import React from 'react'
import axios from 'axios';
import { useState,useEffect,useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { RECRUITMENT1_GET, RECRUITMENT_GET } from '../../Utils/InfoUploadingConst';

function RecruitmentPhysio() {
    const [data, setData] = useState([]);

    const navigate = useNavigate();
  
    const getData = async () => {
      const config = {
        method: 'get',
        url: RECRUITMENT_GET,
        headers: {
          'Content-Type': 'application/json',
        },
        params: {
          multi_clg_id: JSON.stringify([1111014]),
        },
      };
  
      try {
        const res = await axios(config);
        res.data.data.sort((a, b) => b.id - a.id);
        res.data.data.forEach((element) => {
          element.image = JSON.parse(element.image);
        });
        setData(res.data.data);
      } catch (err) {
        console.log(err);
      }
    };
  
    useEffect(() => {
      getData();
    }, []);
  
    const isActiveAndHasMultiClgId = (item) => {
      const multiClgIdArray = JSON.parse(item.multi_clg_id);
      return item.status == 'ACTIVE' && multiClgIdArray.includes(1111014);
    };
  
    const getData1 = async () => {
      const config = {
          method: "get",
          url: RECRUITMENT1_GET,
          headers: {
              "Content-Type": "application/json",
          },
      };
  
      await axios(config)
          .then((res) => {
              
              
  
              res.data.data.sort((a, b) => b.id - a.id);
              res.data.data.forEach((element) => {
                  element.image = JSON.parse(element.image);
              });
              console.log(res.data.data)
              setData(res.data.data);
          })
          .catch((err) => {
              console.log(err);
          });
  };
  
    useEffect(() => {
        // getData();
        getData1();
    }, []);
    
    const next = async (id, data1, images) => {
        console.log("error here");
        navigate(`/Recruitment/${id}`, {
            state: {
                images: images,
                data: data1,
            },
        });
    };
  
  
    return (
      <div className='Recruitment'>
      <br/>
      <br/>  
  
  <div className="events-area pb-70">
    <div className="container">
      <div className="row ">
  
        <div className="row register" style={{ maxWidth: "100%" }}>
          <div className="col-md-12">
            <h3>
              RECRUITMENT 
            </h3>
          </div> 
        </div>
  
        <div className="row photos justify-content-left  zoom-gallery">
        <br/>
        <br/>  
        
        {data.map((item, key) => (
            isActiveAndHasMultiClgId(item) && (
              <div className="col-lg-4 col-md-6" key={item.id} style={{ cursor: 'pointer' }}>
                <a href={item?.image} target="_blank" rel="noopener noreferrer">
                  <div className="single-events-card style-3">
                    <div className="events-image">
                      <img src={item?.image} className="img-fluid" alt="Event" />
                    </div>
                    <div className="events-content">
                      <h6>{item?.date ? item.date.split('T')[0] : null}</h6>
                      <a href={item?.image} target="_blank" rel="noopener noreferrer">
                        <h3>{item?.title}</h3>
                      </a>
                    </div>
                  </div>
                </a>
              </div>
            )
          ))}
   
        </div>
      </div>
    </div>
  </div>
  
  
  
      </div>
  )
}

export default RecruitmentPhysio;
