import React from "react";

export default function ClinicalDepartments() {
  return (
    <div>
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-md-12">
            <p>
              <span style={{ fontSize: "18pt" }}>
                <strong>Clinical Departments</strong>
              </span>
            </p>

            <div className="card mb-3">
              <div className="row no-gutters">
                <div className="col-md-8">
                  <div className="card-body pt-5 ">
                    <h4 className="card-title mb-3">Kaya Chikitsa</h4>
                    <p className="card-text">
                      Dr. Tapankumar Vaidya
                      <br />
                      Dr. Sachin Vanmore
                      <br />
                      Dr. Vishal Solanki
                      <br />
                      Dr Arathy Menon
                      <br />
                      Dr. Merin Jose
                      <br />
                      Dr. Sangita Jadav
                      <br />
                    </p>
                  </div>
                </div>

                <div className="col-md-4">
                  <img
                    src="/assets/ayurveda/images/kaya-chikitsa.jpg"
                    style={{
                      height: "100%",
                      width: "100%",
                      "object-fit": "fill",
                    }}
                    alt="..."
                  />
                </div>
              </div>
            </div>

            <div className="card mb-3">
              <div className="row no-gutters">
                <div className="col-md-4">
                  <img
                    src="/assets/ayurveda/images/shalya-tantra.jpg"
                    style={{
                      height: "100%",
                      width: "100%",
                      "object-fit": "fill",
                    }}
                    alt="..."
                  />
                </div>
                <div className="col-md-8">
                  <div className="card-body pt-5 ">
                    <h4 className="card-title mb-3">Shalya Tantra</h4>
                    <p className="card-text">
                      Dr. Darshana Ramole
                      <br />
                      Dr. Anita Patel
                      <br />
                      Dr Amruta Somani <br />
                      Dr. Neeraj Kumar Sharma
                      <br />
                      Dr. Sneha Suresh <br />
                      Dr. Saurabh Patel
                      <br />
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="card mb-3">
              <div className="row no-gutters">
                <div className="col-md-8">
                  <div className="card-body pt-5 ">
                    <h4 className="card-title mb-3">Prasuti Tantra & Stree Roga</h4>
                    <p className="card-text">
                    Dr. Jamunadevi<br/>
Dr Priya Deshpande<br/>
Dr. Sindhura SVL<br/>
Dr. Divya Sharma<br/>
Dr. Nidhi Ninama <br/>
Dr. Aparna Nath<br/>
                    </p>
                  </div>
                </div>

                <div className="col-md-4">
                  <img
                    src="/assets/ayurveda/images/prasuti-tantra-and-stree-roga.jpg"
                    style={{
                      height: "100%",
                      width: "100%",
                      "object-fit": "fill",
                    }}
                    alt="..."
                  />
                </div>
              </div>
            </div>

            <div className="card mb-3">
              <div className="row no-gutters">
                <div className="col-md-4">
                  <img
                    src="/assets/ayurveda/images/panchakarma.jpg"
                    style={{
                      height: "100%",
                      width: "100%",
                      "object-fit": "fill",
                    }}
                    alt="..."
                  />
                </div>
                <div className="col-md-8">
                  <div className="card-body pt-5 ">
                    <h4 className="card-title mb-3">Panchakarma</h4>
                    <p className="card-text">
                    Dr. J Sivanarayana<br/>
Dr. Jaydatt Mehta<br/>
Dr . Pooja Soni<br/>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="card mb-3">
              <div className="row no-gutters">
                <div className="col-md-8">
                  <div className="card-body pt-5 ">
                    <h4 className="card-title mb-3">Shalakya Tantra</h4>
                    <p className="card-text">
                    Dr. Suresh Prajapati<br/>
Dr. Sreelakshmi S Kartha<br/>
                    </p>
                  </div>
                </div>

                <div className="col-md-4">
                  <img
                    src="/assets/ayurveda/images/shalakya-tantra.jpg"
                    style={{
                      height: "100%",
                      width: "100%",
                      "object-fit": "fill",
                    }}
                    alt="..."
                  />
                </div>
              </div>
            </div>

            <div className="card mb-3">
              <div className="row no-gutters">
                <div className="col-md-4">
                  <img
                    src="/assets/ayurveda/images/kaumarabhritya.jpg"
                    style={{
                      height: "100%",
                      width: "100%",
                      "object-fit": "fill",
                    }}
                    alt="..."
                  />
                </div>
                <div className="col-md-8">
                  <div className="card-body pt-5 ">
                    <h4 className="card-title mb-3">Kaumarabhritya</h4>
                    <p className="card-text">
                    Dr. Chandramouli Raju<br/>
Dr. Caitali Pawar<br/>
Dr. Anagha N<br/>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="card mb-3">
              <div className="row no-gutters">
                <div className="col-md-8">
                  <div className="card-body pt-5 ">
                    <h4 className="card-title mb-3">Swasthavritta & Yoga</h4>
                    <p className="card-text">
                    Dr. Amol Koshti<br/>
Dr. Ayana R<br/>
                    </p>
                  </div>
                </div>

                <div className="col-md-4">
                  <img
                    src="/assets/ayurveda/images/swasthavritta-and-yoga.jpg"
                    style={{
                      height: "100%",
                      width: "100%",
                      "object-fit": "fill",
                    }}
                    alt="..."
                  />
                </div>
              </div>
            </div>

            <div className="card mb-3">
              <div className="row no-gutters">
                <div className="col-md-4">
                  <img
                    src="/assets/ayurveda/images/atyayika-emergency.jpg"
                    style={{
                      height: "100%",
                      width: "100%",
                      "object-fit": "fill",
                    }}
                    alt="..."
                  />
                </div>
                <div className="col-md-8">
                  <div className="card-body pt-5 ">
                    <h4 className="card-title mb-3">Atyayika(Emergency)</h4>
                    <p className="card-text">
                    Dr. Archana Patel
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
