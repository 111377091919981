import React from 'react'
import './OfficerProfile.scss'

function PresidentMsg() {

    const data = {
        quote : "Creativity is just connecting things. When you ask creative people how they did something, they feel a little guilty because they didn't really do it, they just saw something. It seemed obvious to them after a while. That's because they were able to connect experiences they've had and synthesize new things."
    }

  return (
    <div>
      <>
      <div className="container mt-5 mb-5">
      <div className="row">
        <div className="col-md-4">
            <br/>
            <img
              src="../assets/images/academics/academic-2.jpg"
              alt=""
              className="img-responsive img-thumbnail"
              width={400}
              height={220}
            />
            <br />
            <br />
       
        </div>
        <div className="col-md-8">
          <h2>
          President
            <br />
          </h2>
          <h4 className="text-bold">Shastri Swami Premswarupdasji</h4>
          <h6 className="text-dark">President</h6>
          <p style={{ textAlign: "justify" }}>
          Shastri Swami Premswaroopdasji,a follower of Bhakti and Seva is the prime founder of Shree Swaminarayan Vishvamangal Gurukul, Kalol. Swamiji Started his seva as a saint in the Swaminarayan Mandir located at Bhuj. Swamiji spent a period of 20 years in Satsang at the place.
          </p>
          <p style={{ textAlign: "justify" }}>
          One Gujarati proverb says “Sadhu to Chalta Bhala”. Swamiji left swaminarayan Mandir, Bhuj and arrived at Swaminarayan Mandir, Kalupur in 1985 where he arranged Satsang Khathas in the surrounding towns and villages. By humble order of Acharya Maharajshri, Swamiji accepted the services as the Mahant(Head) of Swaminarayan Mandir, situated at Siddhpur and stayed over there for the place Kalol, an entrance of North Gujarat Region and acquired a piece of barren land and dreamed to establish a gurukul providing education, shelter to helpless people and a Goshala.
          </p>
          <p style={{ textAlign: "justify" }}>
          The first foundation stone was laid down by Param Pujya Dharm Dhurandhar 1008 Acharyashri Tejendraprasadji in the year 1993. Afterwards Swamiji continued Satsang, Kathas and religious meetings in the nearby Towns and Villages.A school was started in the year 1995, with primary first and then secondary, Higher Secondary, general section, Science section and school was flourished.
          </p>
          <p style={{ textAlign: "justify" }}>
          Swamiji then started B.Ed, PTC, Degree, Pharmacy, Diploma Engineering and Degree Engineering College and M.E. at Kalol Gurukul campus. In 2012, a completely modernized Shree Swaminarayan International School was Shunya(Zero), a new world of temples of education was created by this visionary and missionary Sadhu. The temples which has no boundary of religion and caste, where only Goddess Maa Saraswati is worshiped.
          </p>
          <var></var>
        </div>
      </div>
      <br />
      {
      data?.quote&&
    <div className="row justify-content-center align-content-center mt-5">
      <div className="col-md-12">
        <blockquote className="otro-blockquotes">
            <h4> Message -</h4>
            <h6 className="ml-5">{data?.quote}</h6>
          <span className="text-dark">- "Shastri Swami Premswarupdasji"</span>
        </blockquote>
      </div>
    </div>
    }
      
      </div>
      </>
    </div>
  )
}

export default PresidentMsg;
