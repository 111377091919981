import React from "react";
import Testimonials from "./Testimonials.jsx";

export default function AlumniMeetings() {
  return (
    <>
      <div>
        <div className="container mt-5 mb-5">
          <div className="row">
            <div className="col-md-12">
              <h2 className="mb-5">
                <strong>Alumni meeting or testimonial given by students</strong>
              </h2>

              <p>
                Alumni are important connections for past students, present
                students, and university itself. Past students use alumni as a
                source of nostalgia, and current Students use alumni as a
                benchmark of how far they can go and even go beyond. plus, the
                mentorship available from the alumni. For the university, it is
                a source of pride as well as resources, and the most important,
                a continuous interface with the industry.
              </p>
              <br />
              <p>
                With a motive to create positive alumni relations that can
                strengthen the bond in our chapter and thereby in the community,
                further provides invaluable advising, create professional
                contacts and thus contribute continuously to bring the change
                –the only constant.
              </p>
              <br />
              <h5>Objectives:</h5>
              <ul>
                <li>
                  To foster continuous engagement of the alumni with their alma
                  mater.
                </li>
                <li>
                  To promote a goodwill and sense of pride to both alumni and
                  Alma Mater.
                </li>
              </ul>
              <br />
              <h2 className="text-center">Alumni 2022</h2>
              <br />
              <img
                src="/assets/engineering/images/alumni-banner.jpg"
                alt="alumni-banner"
              />
              <br />

              {/* <Testimonials /> */}

            </div>
          </div>
        </div>
      </div>
    </>
  );
}
