export const publications = [
    {
      id: 1, 
      description: 'Parametric Optimization of Gas Tungsten Arc Welding Using Activated Flux on Weld Penetration on SS 304, IJRME, Volume 3, Issue 3, May-June 2015 by Mr. Bimal Patel (Mechanical Engineering Department)',
      link: 'https://iaeme.com/MasterAdmin/Journal_uploads/IJRME/VOLUME_3_ISSUE_3/IJRME_03_03_005.pdf'
    },
    {
      id: 2,
      description: 'Optimization of Process Parameter for Friction Stir Welding Process, IJIRSET, Volume 12, Issue 6, June 2023 by Mr. Bimal Patel (Mechanical Engineering Department)', 
      link: 'http://www.ijirset.com/upload/2023/june/72_Optimization_NC.pdf'
    },
    {
      id: 3,
      description: 'Design and Analysis of roll cage of an All-Terrain Vehicle (ATV), National conference on innovative and emerging technologies -2015, ISBN 978-81-925650-0-2, April, 2015 by Mr. Jayendra Patel (Mechanical Engineering Department)',
      link: ''
    },
    {
      id: 4, 
      description: 'Increasing Calorific Value of Biogas using Different Techniques: A Review, International journal for scientific research and development, ISSN (online): 2321-0613, September,2014 by Mr. Jayendra Patel (Mechanical Engineering Department)',
      link: 'https://www.ijsrd.com/articles/IJSRDV2I9189.pdf'
    },
    {
      id: 5,
      description: 'Analysis of the Heat Affected Zone in CO2 Laser Cutting of Stainless Steel, International journal for scientific research and development, ISSN (online): 2321-0613, November,2014 by Mr. Jayendra Patel (Mechanical Engineering Department)',
      link: 'https://www.ijsrd.com/articles/IJSRDV2I11102.pdf'
    },
    {
      id: 6,
      description: 'Experimental Investigation of Centrifugal Pump Regular and Reverse Mode, International journal for scientific research and development, ISSN (online): 2321-0613, November,2014 by Mr. Jayendra Patel (Mechanical Engineering Department)',
      link: 'https://www.ijsrd.com/articles/IJSRDV2I11253.pdf'
    },
    {
      id: 7,
      description: 'A Review on Calorific Value of Biogas Working as Biocng, National conference on innovative and emerging technologies -2015, ISBN 978-81-925650-0-2, April,2015 by Mr. Jayendra Patel (Mechanical Engineering Department)', 
      link: ''
    },
    {
      id: 8,
      description: 'Experimental and Numerical Investigations of Centrifugal Pump Performance in Reverse Mode, International journal of advance technology in engineering and science, ISSN (Online) 2348-7550, March,2015 by Mr. Jayendra Patel (Mechanical Engineering Department)',
      link: 'http://ijates.com/images/short_pdf/1426957091_1094.pdf'
    },
    {
      id: 9, 
      description: 'Design and Analysis of Stenter Chain - A Review, IJIRSTV2I9057,February 2016. by Mr. Sanket Patel (Mechanical Engineering Department)',
      link: 'http://www.ijirst.org/articles/IJIRSTV2I9057.pdf'
    },
    {
      id: 10,
      description: 'Design and Analysis of Stenter Pin Chain, IJAERDV03I0813483, August 2016. by Mr. Sanket Patel (Mechanical Engineering Department)',
      link: ''
    },
    {
      id: 11,
      description: 'Modelling, Investigation and Refinement of Three Stage Helical Gear Case Housing Utilising Numerical Approach Contemplating Various Relevant Substances - ICIMA 2020 (Springer) by Mr. Sanket Patel (Mechanical Engineering Department)',
      link: 'https://www.researchgate.net/publication/342580853_Modelling_Investigation_and_Refinement_of_Three_Stage_Helical_Gearcase_Housing_Utilizing_Numerical_Approach_Contemplating_Various_Relevant_Substances'
    },
    {
      id: 12,
      description: 'Review on the Major Pollution Causes Element of the Atmospheric Air in India, in International Journal for Research in Applied Science & Engineering Technology (IJRASET) ISSN: 2321-9653; IC Value: 45.98; SJ Impact Factor: 7.429 Volume 9 Issue IV Apr 2021 by Mr. Parikh Naitik (Mechanical Engineering Department)',
      link: 'https://www.ijraset.com/fileserve.php?FID=33842'
    },
    {
      id: 13,
      description: 'Published a Paper Entitled, Review on the Material Selection for The Commercial Air Filter For Atmospheric Air .in International Journal of Engineering Applied Science and Technology 2021 Vol. 5, Issue 11, ISSN No. 2455-2143, Pages 138-144 Published Online March 2021 in IJEAST by Mr. Parikh Naitik (Mechanical Engineering Department)',
      link: 'https://www.researchgate.net/publication/352542317_REVIEW_ON_THE_MATERIAL_SELECTION_FOR_THE_COMMERCIAL_AIR_FILTER_FOR_ATMOSPHERIC_AIR'
    },
    {
      id: 14, 
      description: 'A Review Paper on Thermal Spray Coating Machine Process & Advantages, Limitations, Applications, Volume 9, Issue IV, April 2021 by Vaghela Surajkumar (Mechanical Engineering Department)',
      link: 'https://www.researchgate.net/publication/351232465_A_Review_Paper_on_Thermal_Spray_Coating_Machine_Process_Advantages_Limitations_Applications'
    },
    {
      id: 15,
      description: 'Experimental Analysis of Processing Parameter of Thermal Spray Coating Machine, Volume 9, Issue 2, May 2021 by Vaghela Surajkumar (Mechanical Engineering Department)',
      link: 'https://www.ijedr.org/papers/IJEDR2102022.pdf'
    },
    {
      id: 16,
      description: 'Role of DPFC and DIPFC FCATS devices in voltage sag and swell mitigation, International Journal of Applied Research in Science and Engineering, E-ISSN: 2456-124X, Nov-2016 by Mr. Ronak Gandhi (Electrical Engineering Department)',
      link: 'http://ijarse.org/images/scripts/201603.pdf'
    },
    {
      id: 17,
      description: 'Simulation of Distributed power flow controller FACTS device in voltage sag and swell mitigation, International Journal of Electrical and Electronics Engineering Research, ISSSN (online):2278-943x, Vol 7, Issue - 2; Edition APR 2017 by Mr. Ronak Gandhi (Electrical Engineering Department)',
      link: 'http://www.tjprc.org/publishpapers/2-15-1487328080-5.IJEEERAPR20175.pdf'
    },
    {
      id: 18,
      description: 'UPQC with VSI: A Pertinent Tool to Improve Power Quality Using Shunt Compensator, Volume 10, Issue 3, March 2022 by Mr. Krushnakant Patel (Electrical Engineering Department)',
      link: 'https://ijireeice.com/wp-content/uploads/2022/03/IJIREEICE.2022.10306.pdf'
    },
    {
      id: 19,
      description: 'Modelling and Simulation of Interline Power Flow Controller for Power Flow Management in Power System, IJTRE, Volume 3, Issue 7, March 2016 by Mr. Himalay Patel (Electrical Engineering Department)',
      link: 'https://ijtre.com/wp-content/uploads/2021/10/2016030722.pdf'
    },
    {
      id: 20,
      description: 'Mechanical Properties Analysis of Hard Coated Cutting Tool, IJARIIE, Volume 1, Issue 2, Paper Id -- 1141, ISSN(O) -2395-4396 by Mr. Jaimin Prajapati (Automobile Engineering Department)',
      link: 'http://ijariie.com/AdminUploadPdf/MECHANICAL_PROPERTIES_ANALYSIS_OF_HARD_COATED_CUTTING_TOOL_ijariie1141_volume_1_13_page_129_137.pdf'
    },
    {
      id: 21,
      description: 'Forced and Wear Analysis of PVD Coated Cutting Tool, Volume 1, Issue 1, Paper Id -- 1112, ISSN(O) -- 2395-4396 by Mr. Jaimin Prajapati (Automobile Engineering Department)',
      link: 'https://ijariie.com/FormDetails.aspx?MenuScriptId=2' 
    },
    {
      id: 22, 
      description: 'Performance Analysis of Hybrid Moped in City Riding Condition, Paper Id -- 8488, ISSN(O) -- 2395-4396, Volume 4, Issue 3, 2018 by Mr. Pranaysinh Jadeja (Automobile Engineering Department)',
      link: 'http://ijariie.com/AdminUploadPdf/Performance_Analysis_of_Hybrid_Moped_for_City_Riding_Condition_ijariie8488.pdf'
    },
    {
      id: 23,
      description: 'Parametric Optimization of Friction Stir Welding Process parameter on Aluminium alloy 6061 Material using different tool profile, IJIRT, Volume 9, Issue 12, May 2023 by by Mr. Pranaysinh Jadeja, Mr. Manish Patel, Mr. Jaydip Adreja and Mr. Harsh Oza (Automobile Engineering Department)',
      link: 'https://www.ijirt.org/master/publishedpaper/IJIRT159828_PAPER.pdf'
    },
    {
      id: 24,
      description: 'Delay Analysis due to Road side Activities at Urban Arterial Road of Rajkot City, IRJET, Volume 6, Issue 4, April 2019 by Ms. Rita Bhanushali (Civil Engineering Department)',
      link: 'https://www.irjet.net/archives/V6/i4/IRJET-V6I4375.pdf'
    },
    {
      id: 25,
      description: 'Side Friction and side Friction Factor (FARIC) in Ahmedabad Road Link, IJR, Volume 1, Issue 5, May 2012 by Mr. Dushyant Parmar (Civil Engineering Department)',
      link: ''
    },
    {
      id: 26,
      description: 'Effect of Pre-Water Added Fine Marble Waste as A Partial Replacement of Fine Aggregate on M20 And M30 Grade of Concrete, IJSART, Volume 3, Issue 4, April 2017 by Ms. Hiral Purohit (Civil Engineering Department)',
      link: ''
    },
    {
      id: 27,
      description: 'Novel Algorithm for Nutrition Based Food Recommendation, IJSREM, Volume 6, Issue 5, May 2022 by Ms. Shivani Gajjar (Computer Engineering Department)',
      link: 'https://ijsrem.com/download/novel-algorithm-for-nutrition-based-food-recommendation/'
    },
    {
      id: 28,
      description: 'Review on: Introducing Recent Trends in ML, IJRPR, Volume 4, Issue 6, June 2023 by Ms. Shivani Gajjar (Computer Engineering Department)', 
      link: 'https://ijrpr.com/uploads/V4ISSUE6/IJRPR14597.pdf'
    },
    {
      id: 29,
      description: 'Algorithm Using Heterogeneous Probability for Byzantine Fault Tolerence, PRRJ ISSN -- 0972-7175, Voume XXV, No. 7(II), July 2022 by Mr. Nimesh Vaidya (Computer Engineering Department)',
      link: ''
    }
  ]