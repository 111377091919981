import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { IPD_SERVICES_GET } from "../../Utils/InfoUploadingConst";
import { LOCAL_DEPARTMENT } from "../../Utils/LocalStorageConstants";

export default function IpdServices() {

  const [data, setData] = useState([]);

  const departmentOpt = JSON.parse(
    localStorage.getItem(LOCAL_DEPARTMENT)
  )

  const navigate = useNavigate();

  const getData = async () => {
    const config = {
        method: "get",
        url: IPD_SERVICES_GET,
        headers: {
            "Content-Type": "application/json",
        },
    };

    await axios(config)
        .then((res) => {
            // res.data.data.sort((a, b) => b.id - a.id);
            // console.log(res.data.data)
            // setData(res.data.data);
            
            const OPDHomeo = res.data.data.filter(element => element.college_id == "1111001" && element.status == "ACTIVE");               
            setData(OPDHomeo);
            OPDHomeo.sort((a, b) => b.id - a.id);

        })
        .catch((err) => {
            console.log(err);
        });
};

useEffect(() => {
    getData();
}, []);

const uniqueMonths = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
const uniqueDepartments = [...new Set(data.map(item => item.department))];

const years = Array.from({ length: new Date().getFullYear() - 2015 + 1 }, (_, i) => (2015 + i).toString()).reverse();

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const groupDataByYear = () => {
  const groupedData = {};

  data.forEach((item) => {
    const key = item.year; // Assuming 'year' is a property in your data
    if (!groupedData[key]) {
      groupedData[key] = [];
    }
    groupedData[key].push(item);
  });

  return groupedData;
};

const groupedDataByYear = groupDataByYear();

const sortedYears = Object.keys(groupedDataByYear).sort((a, b) => b - a);

// Function to calculate the total for each department
const calculateDepartmentTotal = (departmentId, year) => {
return groupedDataByYear[year].reduce(
  (total, item) => (item.department === departmentId ? total + item.total_number : total),
  0
);
};

const calculateMonthTotal = (month, year, departmentId) => {
return groupedDataByYear[year].reduce(
  (total, item) => (item.month === month && item.department === departmentId ? total + item.total_number : total),
  0
);
};

const calculateYearTotalForMonth = (month, year) => {
return uniqueDepartments.reduce(
  (total, departmentId) => total + calculateMonthTotal(month, year, departmentId),
  0
);
};

// Add this function for the grand total
const calculateGrandTotal = (year) => {
return uniqueMonths.reduce(
  (total, month) => total + calculateYearTotalForMonth(month, year),
  0
);
};

  return (
    <div className='IpdServices'>
      
      <div class="page-banner-area bg-3">
        <div class="container">
          <div class="page-banner-content">
            <h1>IPD DATA</h1>
            <ul>
              <li> <Link to={'./../'}>  Home </Link></li>
              <li>IPD</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="container">
                  <div className="row">

                    <div className='col-12'>

                    <br></br>

                    <div className='card'>
                    <div className='card-body'>

                    <div class="card-header">
                      <br></br>
                      <h3 class="text-primary"> IPD Sevices List </h3>
                      <br></br> 
                      {/* <h5 class="text-primary">DETAILS OF IPD PATIENTS</h5> */}
                    </div>

{sortedYears
.map((year) => (
  <div key={year} className="container">
    <br></br>
    <br></br>
    <br></br>
    <h4 className="text-primary">DETAILS OF IPD PATIENTS: {year}</h4>
    <table className="table table-bordered">
      <thead>
        <tr>
          <th>Month</th>
          {uniqueDepartments.map((departmentId) => (
            <th key={departmentId}>
              {departmentOpt.find((d) => d.id == departmentId)?.name || departmentId}
            </th>
          ))}
          <th>Total</th>
        </tr>
      </thead>
      <tbody>
        {uniqueMonths.map((month, index) => (
          <tr key={index}>
            <td>{month}</td>
            {uniqueDepartments.map((departmentId) => (
              <td key={departmentId}>
                {calculateMonthTotal(month, year, departmentId)}
              </td>
            ))}
            <td>{calculateYearTotalForMonth(month, year)}</td>
          </tr>
        ))}
        <tr>
          <th>Total</th>
          {uniqueDepartments.map((departmentId) => (
            <td key={departmentId}>
              {calculateDepartmentTotal(departmentId, year)}
            </td>
          ))}
          <td className="text-dark">{calculateGrandTotal(year)}</td> {/* this line for the grand total */}
        </tr>
      </tbody>
    </table>
  </div>
))}

                    </div>
                    </div>

                    <br></br>
                    <br></br>

                    </div>

                  </div>
              </div> 

    </div>
  );
}
