import React from 'react'
import './Swaminarayan.scss'

function Objectives() {
  return (
    <div className='Objectives'>
        <>
  <h1 className="text-center">Objectives</h1>
  <br />
  {/*<br>*/}
  {/*<h3 class="text-center">*/}
  {/*Objects of the University as given in <br>The Gujarat Private Universities Act 2009 are as under*/}
  {/*</h3>*/}
  <p className="text-center ml-10">
    The objects of the Universities shall be to create, organize, preserve and
    disseminate knowledge in the fields of science, technology, humanities,
    social sciences, education management, commerce, law, pharmacy, healthcare
    and any other field for the advancement of mankind in particular and other
    objects of the Universities shall be as follows, namely
  </p>
  <br />
  <br />
  <div className="container">
    <div className="list-wrapper ml-10">
      <div className="red-line" />
      <div className="list-item-wrapper">
        <div className="list-bullet">a</div>
        <div className="list-item">
          <div className="list-title">
            To provide for instruction, teaching and training in the University
            in the field of higher education and make provisions for research,
            advancement and dissemination of knowledge
          </div>
          {/*<div class="list-text">Text</div>*/}
        </div>
      </div>
      <br />
      <div className="list-item-wrapper">
        <div className="list-bullet">b</div>
        <div className="list-item">
          <div className="list-title">
            To establish, maintain and manage institutions and centres of
            excellence, to create, organize, preserve and disseminate knowledge
            in the fields of sciences, technology, humanities, social sciences,
            education, management, commerce, law, pharmacy, healthcare, and any
            other field and to provide research higher education, professional
            education, distance learning and e-learning facilities of high
            order, as per their current status or as they may develop in future
          </div>
        </div>
      </div>
      <br />
      <div className="list-item-wrapper">
        <div className="list-bullet">c</div>
        <div className="list-item">
          <div className="list-title">
            To develop infrastructure for research, higher education,
            professional education, teaching, training, extension and outreach,
            including continuing education, distance learning and e-learning, to
            create capabilities for upgrading infrastructure to global standards
          </div>
        </div>
      </div>
      <br />
      <div className="list-item-wrapper">
        <div className="list-bullet">d</div>
        <div className="list-item">
          <div className="list-title">
            To offer the academic programmes of the University through distance
            education, online education, correspondence and any other mode,
            matching with the environmental developments such as technology
            need, after obtaining appropriate approvals from the regulatory
            bodies
          </div>
        </div>
      </div>
      <br />
      <div className="list-item-wrapper">
        <div className="list-bullet">e</div>
        <div className="list-item">
          <div className="list-title">
            To set up off-campus centres, study centres and examination centres
            within the State, subject to the permission of the regulatory bodies
            under any law made by the Parliament and any regulation, rules, etc.
            made by the regulating bodies
          </div>
        </div>
      </div>
      <br />
      <div className="list-item-wrapper">
        <div className="list-bullet">f</div>
        <div className="list-item">
          <div className="list-title">
            To create higher levels of intellectual abilities
          </div>
        </div>
      </div>
      <br />
      <div className="list-item-wrapper">
        <div className="list-bullet">g</div>
        <div className="list-item">
          <div className="list-title">
            To establish state of the art facilities for education and training
          </div>
        </div>
      </div>
      <br />
      <div className="list-item-wrapper">
        <div className="list-bullet">h</div>
        <div className="list-item">
          <div className="list-title">
            To carry out teaching and research and offer continuing education
            programmes
          </div>
        </div>
      </div>
      <br />
      <div className="list-item-wrapper">
        <div className="list-bullet">i</div>
        <div className="list-item">
          <div className="list-title">
            To create centres of excellence for research and development and for
            sharing knowledge and its application
          </div>
        </div>
      </div>
      <br />
      <div className="list-item-wrapper">
        <div className="list-bullet">j</div>
        <div className="list-item">
          <div className="list-title">
            To provide consultancy to the industry and public organisations;
          </div>
        </div>
      </div>
      <br />
      <div className="list-item-wrapper">
        <div className="list-bullet">k</div>
        <div className="list-item">
          <div className="list-title">
            To establish main campus or infrastructure in the State of Gujarat
            necessary for the furtherance of its objects
          </div>
        </div>
      </div>
      <br />
      <div className="list-item-wrapper">
        <div className="list-bullet">l</div>
        <div className="list-item">
          <div className="list-title">To establish examination centres</div>
        </div>
      </div>
      <br />
      <div className="list-item-wrapper">
        <div className="list-bullet">m</div>
        <div className="list-item">
          <div className="list-title">
            To confer degrees, diplomas, grant certificates and other academic
            distinctions on the basis of examination or any other method of
            evaluation subject to the guidelines of the UGC;
          </div>
        </div>
      </div>
      <br />
      <div className="list-item-wrapper">
        <div className="list-bullet">n</div>
        <div className="list-item">
          <div className="list-title">
            To develop training facilities in the field of higher education
          </div>
        </div>
      </div>
      <br />
      <div className="list-item-wrapper">
        <div className="list-bullet">o</div>
        <div className="list-item">
          <div className="list-title">
            To provide for arrangement for national and global participation in
            the field of higher education
          </div>
        </div>
      </div>
      <br />
      <div className="list-item-wrapper">
        <div className="list-bullet">p</div>
        <div className="list-item">
          <div className="list-title">
            To develop education programmes for certificates, diplomas, degrees
            and post-graduates courses, doctorate degrees and post-doctoral
            programmes and to maintain a high standard of education, to
            collaborate with national and global institutions, to offer
            programmes and to create capabilities for upgrading programmes to
            the global standards subject to the guidelines of the UCG
          </div>
        </div>
      </div>
      <br />
      <div className="list-item-wrapper">
        <div className="list-bullet">q</div>
        <div className="list-item">
          <div className="list-title">
            To ensure that the standard of the degrees, diplomas, certificates
            and other academic distinctions are riot lower than those laid down
            by AICTE, NCTE, UGC, MCI and Pharmacy Council, and any other similar
            agency established by the Central Government for regulating the
            standard education;
          </div>
        </div>
      </div>
      <br />
      <div className="list-item-wrapper">
        <div className="list-bullet">r</div>
        <div className="list-item">
          <div className="list-title">
            To establish close linkage with the industry, business, educational
            institutions and other sections of the society to make teaching,
            research, training, documentation, publication, use of various media
            and outreach activities at the University relevant to the needs of
            the University and society, at national and international level
          </div>
        </div>
      </div>
      <br />
      <div className="list-item-wrapper">
        <div className="list-bullet">s</div>
        <div className="list-item">
          <div className="list-title">
            To pursue any other objectives as may be approved by the State
            Government
          </div>
        </div>
        <div className="white-line" />
      </div>
      <br />
    </div>
  </div>
  <br />
  <p className="text-center">
    Provided that notwithstanding anything contained in this Act and save as
    provided in any Central Act, the University shall be eligible to undertake
    the functions of disseminating of knowledge only in the fields for which the
    State Government has issued letter of intent or in the fields subsequently
    approved by the State Government.{" "}
  </p>
</>

    </div>
  )
}

export default Objectives