import React from 'react'
import { useRef } from 'react';
import { useParams, useLocation, useSearchParams } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print';
import { studentData } from '../../Data/phdProvisional'
import { HallTicketData } from '../../Data/phdHallTicktets'
import rdsign from './../../assets/images/rdsign.jpg'
import logoWide from './../../assets/images/logoWide.png'
import { PHD_ADMISSION } from '../../Utils/apiConst';
import axios from 'axios';
import { useEffect } from 'react';
import { useState } from 'react';
import Loader from '../../Components/Loader/Loader';
// import instructions from './../../assets/images/headers/Fill_Circle.PNG'

const PhdHallTickets = () => {

  const instructions = 'https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/Ph.D+Admissions+Assets/Fill_Circle.PNG'


  const [loading, setLoading] = useState(0)

  const { id } = useParams()
  console.log(id);

  const [imageUrl, setImageUrl] = useState('')
  const [imageALt, setImageAlt] = useState('')

  const data = HallTicketData?.find(s => s["Roll Number"] == id)
  console.log(data);


  const date = new Date().toISOString().slice(0, 10).split('-').reverse().join(' / ')

  const refToPrint = useRef()

  const handlePrint = useReactToPrint({
    content: () => refToPrint.current,
    documentTitle: 'Ph.D Admit Card'
  });

  const getData = async() => {
    setLoading(1)
    const config = {
      method:'get',
      url:`${PHD_ADMISSION}?email=${data?.Email}`,
      headers: {
        'Content-Type': 'application/json'
      },
    }

    await axios(config)
    .then(res=>{
      console.log(res?.data?.data[0]?.fetchPhdAdmission?.photograph)
      setImageUrl(res?.data?.data[0]?.fetchPhdAdmission?.photograph)
    })
    .catch(err=>{
      console.log(err);
      setImageAlt('error fetching Image')
    })
    setLoading(0)
  }

  useEffect(()=>{
    if(data){
      getData()
    }
  },[data])

  if (!data) {
    return (
      <div className='py-5 text-center'>
        <h4> No Data available </h4>
      </div>
    )
  }


  return (
    <div className='container mt-5 mb-5' >
      <Loader loading={loading}/>
      <div className="row">
        <div className="col-md-12" style={{overflow:'scroll'}}>
          <div className="card p-1" ref={refToPrint}  style={{minWidth:'1000px'}}>
            <div className="card-body">
              <div className="row mb-2">
                <div className="col-12">
                  <div className="text-center">
                    <img src={logoWide} style={{width:'50%'}} alt="" />
                  </div>
                </div>
                <div className="col-12 mt-3">
                  <h4 className="text-center">Ph.D Entrance Examination Nov-2022 Admit Card</h4>
                </div>
              </div>


              <table className="table table-borderless">
                <tbody>
                  <tr>
                    <th>Roll Number:</th>
                    <th>
                      {data["Roll Number"]}
                    </th>
                    <th>Father Name:</th>
                    <th>
                      {data["Father Name"]}
                    </th>
                  </tr>
                  <tr>
                    <th>Candidate Name:</th>
                    <th>
                      {data["Candidate Name"]}
                    </th>
                    <th>Gender:</th>
                    <th>
                      {data["Gender"]}
                    </th>
                  </tr>
                  <tr>
                    <th>Category:</th>
                    <th>
                      {data["Category"]}
                    </th>
                    {/* <th>Person with Disability(PwD):</th>
                <th>
                  <select name="disable" id="" className="form-control">
                    <option value="No">No</option>
                    <option value="Yes">Yes</option>
                  </select>
                </th> */}
                  </tr>
                </tbody>
              </table>
              <hr />
              <div className="row">
                <div className="col-8">
                  <h5 className="text-center bg-secondary text-white p-2">
                    Examination Details
                  </h5>
                  <table className="table mt-1">
                    <tbody>
                      <tr colSpan={4}>
                        <th>Reporting Time:</th>
                        <td colSpan={2}>9:00 am (IST)</td>
                        <th>Gate Closing Time:</th>
                        <td colSpan={2}>10:00 am (IST) </td>
                      </tr>
                      <tr colSpan={4}>
                        <th>Faculty :</th>
                        <td colSpan={2}>{data["Faculty"]}</td>
                        <th>Specialisation :</th>
                        <td colSpan={2}>{data["Specialization"]}</td>
                      </tr>
                      {/* <tr colSpan={4}>
                    <th>Test Center:</th>
                    <td colSpan={4} />
                  </tr> */}
                      <tr colSpan={4}>
                        <th>Venue of Test:</th>
                        <td colSpan={4} >Swaminarayan University, Kalol, Dist. Gandhinagar - 382725, Gujarat</td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="table table-bordered mt-2">
                    <tbody>
                      <tr>
                        <th>Date of Examination:</th>
                        <td>27/11/2022</td>
                        <td rowSpan={3} width="30%" />
                      </tr>
                      {/* <tr>
                    <th>Shift:</th>
                    <th>First</th>
                  </tr> */}
                      <tr>
                        <th>Timing of Test:</th>
                        <th>10:00 am to 12:00 pm</th>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <div className="row mt-2">
                    <div className="col-5">
                      <div className="d-flex justify-content-center">
                        <img src={rdsign} style={{ width: '80px' }} />
                      </div>
                      <hr />
                      <div className='text-center'>Signature of Dean Research (I/C)</div>
                      <div className='text-center'>Dr. Anita Patel</div>
                    </div>
                  </div>

                </div>
                <div className="col-4 border">
                  <h5 className="text-center mt-3" style={{ height: "22rem" }}>
                    Photograph <br /> <br />
                    <img
                      src={imageUrl}
                      style={{ maxWidth: '200px' }}
                      alt={imageALt} />
                  </h5>
                  <br /><br />
                  <hr />
                  <div className="row">
                    <div className="col-md-12">
                      <p className="text-center">Candidate Signature</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="col-md-12">
              <h5 className="text-center">IMPORTANT INSTRUCTIONS FOR CANDIDATES</h5>
              <ol>
              <li>
                  Please carry One Physical Passport Size Photograph.
                </li>
                <li>
                  Ph.D. applicants have to attend the entrance examination as per schedule.
                </li>
                <li>
                  On the day of Entrance exam, the candidate have to reach the venue 1 hour prior to the exam timing.
                </li>
                <li>
                  Any unfair practices in the examination shall lead to expulsion of the candidate from the Examination.
                </li>
                <li>
                  Candidate have to carry Admit Card and Identity proof and need to produce it upon asking.
                </li>
                <li>
                  Candidate will be allowed to enter the examination hall 15 mins before the
                  commencement of the exam. Once Entrance exam commences no candidate will be allowed in the examination halls.
                </li>
              </ol>
            </div>

            <div className="col-md-12">

              <h5 className="text-center">OMR INSTRUCTIONS</h5>
              <div className='row' style={{paddingLeft:'100px'}}>
                <div className="col-6">
                <ol>
                <li>
                  Use Blue/Black Ball Fine Tip Ball Point Pen Only
                </li>
                <li>
                  Do not make  any stray marks on this sheet
                </li>
                <li>
                Use of Pencil is strictly prohibited.
                </li>
                <li>
                Cutting  and erasing on this sheet is not allowed.
                </li>
                <li>
                Do not use marker or whitener to hide the mark.
                </li>
              </ol>
                </div>
                <div className="col-6 pl-5">
                <div className="" style={{zIndex:1}}>
                <img src={instructions} width={250} alt="Fill Circle" />
              </div>
                </div>
              </div>
              

              
            </div>
            <div className='d-flex justify-content-center my-4 d-print-none'>
              <button className='btn d-flex justify-content-center align-items-center rounded text-light' style={{ background: '#E32845' }} onClick={handlePrint}> <i className="ri-file-download-line"></i> &nbsp;&nbsp; Print / Download</button>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  )
}

export default PhdHallTickets