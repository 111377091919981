import React from 'react';
import axios from "axios";
import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../Router/RouterConfig";
import "../Connect/Downloads/Brochure.scss";
import { LOCAL_DEPARTMENT, LOCAL_EMPLOYEE } from "../../Utils/LocalStorageConstants";
import { STAFF_DATA } from '../../Utils/apiConst';

function HospitalStaffHomeo() {

  const navigate = useNavigate();

  const [data, setData] = useState([]);

  const [employeeData, setEmployeeData] = useState([]);

  async function fetchData() {
    const college_id = "1111007";
    await axios
      .get(`${STAFF_DATA}?college_id=${college_id}&teaching_type=HOSPITAL_STAFF&status=ACTIVE`)
      .then((res) => {
        setEmployeeData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  useEffect(() => {
    fetchData();
  }, []);

  function formatDate(dateString) {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    const formattedDate = new Date(dateString).toLocaleDateString(
      "en-US",
      options
    );
    return formattedDate;
  }

  const roleMapping = {
    ASSTPR: "Assistant Professor",
    ASPR: "Associate Professor",
    ASSTLIB: "Assistant Librarian",
    Attend: "Attender",
    AdmCoun: "Admission Counsellor",
    BME: "BioMedical Engineer",
    COE: "Controller Of Exams",
    CLERK: "Clerk",
    DMS: "Deputy Medical Superintendent",
    DOFAC: "Dean Of Faculty (Diploma)",
    DOFUGPG: "Dean Of faculty (UG & PG)",
    JR: "Junior Resident",
    JRF: "Junior Research Fellow",
    LA: "Lab Assistant",
    MO: "Medical Officer",
    PROF: "Professor",
    Phy: "Physiotherapist",
    PA: "Panchakarma Assistant",
    Recep: "Receptionist",
    REG: "Registrar",
    SR: "Senior Resident",
    Tut: "Tutor",
    READ: "Reader",
    OFFE: "Office Executive",
    VP: "Vice Principal",
    PRO: "Provost",
    LIB: "Librarian",
    ACCNT: "Accountant",
    CC: "Clerk Cum Cashier",
    P: "Peon",
    HOD: "Head of The Department",

    SUACC: "SUACC",
    IT: "IT",

    Dean: "Dean/Principal",
    Mas: "Massure",
    "IT Cell": "Website Coordinator",
    Amission: "Admission Officer",
    SACC: "Sr Accountant",
    NET: "Network Administrator",
    "Exe-HR": "Executive-Human Resources",
    "H-HR": "Assistant Manager-Human Resource",
    SECOR: "Security Officer",
    PROC: "Proctor",
    SOPS: "Section Officer-Purchase & Stores",
    DIRP: "Director Placements",
    CWAR: "Chief Warden",
    DENIQSC: "Dean IQSC",
    DENSW: "Dean Student Welfare",
    DERES: "Dean Research",
    DIA: "Director International Affairs",
    DREGAC: "Deputy Registrar(Accreditation & Compliance)",
    DREGAD: "Deputy Registrar(Admissions and Admin)",
    EXAM: "Examiner",
    FOP: "Finance Officer",
    HITS: "Head IT Services",
    MANTAIN: "Mantainance Engineer",
  };

  return (
    <div>

    <div className="HospitalStaff">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <br />
            <br />
            <br />
            <h1 className="text-center">Hospital Staff</h1>
            <br />
            <br />
            <div className="card border-0 shadow-sm">
              <div className="card-header d-flex justify-content-between ">

              </div>
              <div className="card-body">
                <div className="container-fluid">
                  <div className="row">
                    {/* CARD START HERE  */}

                  { employeeData?.map ((item,key) => {
                     return(                  
                    <div className="col-12 col-sm-6 col-lg-3" key={key}>
                      <div
                        className="single_advisor_profile wow fadeInUp"
                        data-wow-delay="0.3s"
                      >
                        <div className="advisor_thumb">
                          {/* <img
                            src={`${
                              item?.photo
                                ? item?.photo
                                : "https://i.pravatar.cc/150?img=12"
                            }`}
                            alt="hospitalstaff"
                            width="100%"
                          /> */}

                            {item?.photo ? (
                                        <img
                                          className="eventimage hospitalstaff"
                                          src={item.photo}
                                          alt="Image"
                                        />
                                      ) : (
                                        <div className="logo">
                                          <a href="/">
                                            <img
                                              src="/assets/images/white-logo.png"
                                              className="white-logo"
                                              alt="logo"
                                            />
                                          </a>
                                        </div>
                                      )}

                        </div>
                        <br/>
                        <div className="single_advisor_details_info">
                          <h5>Name: {item.name || "---"}</h5>

                                     <p className="designation">
                                        <strong>
                                          Date of Joining : {new Date(item?.date_of_joining).toISOString().split('T')[0] || "--"}{" "}
                                        </strong>
                                      </p>

                                      <br />
                                      <p className="text-center">
                                        <strong>
                                          Department:
                                          {item.department_id || "--"}
                                        </strong>
                                      </p>
                                      <p className="text-center">
                                        <strong>
                                          Experience: {item.experience || "--"}
                                        </strong>
                                      </p>

                        </div>
                      </div>
                    </div>
                     )
                    })
                  }

                    {/* CARD END HERE  */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>   
      
    </div>
  )
}

export default HospitalStaffHomeo;
