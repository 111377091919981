import React from 'react'
import './Graduate'

function Diploma() {
  return (
    <div className='Diploma Graduate'>
        <>
  {/* Modal */}
  <div
    className="modal fade"
    id="engineering"
    tabIndex={-1}
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog modal-xl">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel" />
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          />
        </div>
        <div className="modal-body">
          <div className="d-flex align-items-center">
            <img
              src="assets/images/icon/engineering.png"
              className="img-fluid modal_icon"
              alt="engineering icon"
            />
            <h2>Engineering</h2>
          </div>
          <div className="row mt-5">
            <div className="col-md-4">
              <ul>
                <li className="crs">
                  <a href="course_details.php?course_name=Automobile Engineering &course=Diploma &intake=60&duration=3 years&fees=34,000&contact=%2B91-9588803611&adcontact=%2B91-9023976473/%2B91-9023965729&ademail=ssvgkalol@gmail.com&head=Dr. Geetanjali Amrawat&heademail=sudeanengg@gmail.com">
                    <h5>Automobile Engineering</h5>{" "}
                  </a>
                </li>
                <a href="course_details.php?course_name=Automobile Engineering &course=Diploma &intake=60&duration=3 years&fees=34,000&contact=%2B91-9588803611&adcontact=%2B91-9023976473/%2B91-9023965729&ademail=ssvgkalol@gmail.com&head=Dr. Geetanjali Amrawat&heademail=sudeanengg@gmail.com"></a>
              </ul>
              <a href="course_details.php?course_name=Automobile Engineering &course=Diploma &intake=60&duration=3 years&fees=34,000&contact=%2B91-9588803611&adcontact=%2B91-9023976473/%2B91-9023965729&ademail=ssvgkalol@gmail.com&head=Dr. Geetanjali Amrawat&heademail=sudeanengg@gmail.com">
                <ul style={{ listStyle: "none" }}>
                  <h5 className="text-danger mt-4">Details</h5>
                  <li className="crs">
                    <p>Course Type : Diploma</p>
                  </li>
                  <li className="crs">
                    <p>Duration of Course : 3 years</p>
                  </li>
                  <li className="crs">
                    <p>Course Intake : 60</p>
                  </li>
                  <li className="crs">
                    <p>Fees : 37000*</p>
                  </li>
                </ul>
              </a>
            </div>
            <div className="col-md-4">
              <ul>
                <li className="crs">
                  <a href="course_details.php?course_name=Mechanical Engineering &course=Diploma &intake=60&duration=3 years&fees=34,000&contact=%2B91-9588803611&adcontact=%2B91-9023976473/%2B91-9023965729&ademail=ssvgkalol@gmail.com&head=Dr. Geetanjali Amrawat&heademail=sudeanengg@gmail.com">
                    <h5>Mechanical Engineering</h5>{" "}
                  </a>
                </li>
                <a href="course_details.php?course_name=Mechanical Engineering &course=Diploma &intake=60&duration=3 years&fees=34,000&contact=%2B91-9588803611&adcontact=%2B91-9023976473/%2B91-9023965729&ademail=ssvgkalol@gmail.com&head=Dr. Geetanjali Amrawat&heademail=sudeanengg@gmail.com"></a>
              </ul>
              <a href="course_details.php?course_name=Mechanical Engineering &course=Diploma &intake=60&duration=3 years&fees=34,000&contact=%2B91-9588803611&adcontact=%2B91-9023976473/%2B91-9023965729&ademail=ssvgkalol@gmail.com&head=Dr. Geetanjali Amrawat&heademail=sudeanengg@gmail.com">
                <ul style={{ listStyle: "none" }}>
                  <h5 className="text-danger mt-4">Details</h5>
                  <li className="crs">
                    <p>Course Type : Diploma</p>
                  </li>
                  <li className="crs">
                    <p>Duration of Course : 3 years</p>
                  </li>
                  <li className="crs">
                    <p>Course Intake : 60</p>
                  </li>
                  <li className="crs">
                    <p>Fees : 37000*</p>
                  </li>
                </ul>
              </a>
            </div>
            <div className="col-md-4">
              <ul>
                <li className="crs">
                  <a href="course_details.php?course_name=Electrical Engineering &course=Diploma &intake=60&duration=3 years&fees=34,000&contact=%2B91-9588803611&adcontact=%2B91-9023976473/%2B91-9023965729&ademail=ssvgkalol@gmail.com&head=Dr. Geetanjali Amrawat&heademail=sudeanengg@gmail.com">
                    <h5>Electrical Engineering</h5>{" "}
                  </a>
                </li>
                <a href="course_details.php?course_name=Electrical Engineering &course=Diploma &intake=60&duration=3 years&fees=34,000&contact=%2B91-9588803611&adcontact=%2B91-9023976473/%2B91-9023965729&ademail=ssvgkalol@gmail.com&head=Dr. Geetanjali Amrawat&heademail=sudeanengg@gmail.com"></a>
              </ul>
              <a href="course_details.php?course_name=Electrical Engineering &course=Diploma &intake=60&duration=3 years&fees=34,000&contact=%2B91-9588803611&adcontact=%2B91-9023976473/%2B91-9023965729&ademail=ssvgkalol@gmail.com&head=Dr. Geetanjali Amrawat&heademail=sudeanengg@gmail.com">
                <ul style={{ listStyle: "none" }}>
                  <h5 className="text-danger mt-4">Details</h5>
                  <li className="crs">
                    <p>Course Type : Diploma</p>
                  </li>
                  <li className="crs">
                    <p>Duration of Course : 3 years</p>
                  </li>
                  <li className="crs">
                    <p>Course Intake : 60</p>
                  </li>
                  <li className="crs">
                    <p>Fees : 37000*</p>
                  </li>
                </ul>
              </a>
            </div>
            <div className="col-md-4">
              <ul>
                <li className="crs">
                  <a href="course_details.php?course_name=Computer Engineering &course=Diploma &intake=60&duration=3 years&fees=34,000&contact=%2B91-9588803611&adcontact=%2B91-9023976473/%2B91-9023965729&ademail=ssvgkalol@gmail.com&head=Dr. Geetanjali Amrawat&heademail=sudeanengg@gmail.com">
                    <h5>Computer Engineering</h5>{" "}
                  </a>
                </li>
                <a href="course_details.php?course_name=Computer Engineering &course=Diploma &intake=60&duration=3 years&fees=34,000&contact=%2B91-9588803611&adcontact=%2B91-9023976473/%2B91-9023965729&ademail=ssvgkalol@gmail.com&head=Dr. Geetanjali Amrawat&heademail=sudeanengg@gmail.com"></a>
              </ul>
              <a href="course_details.php?course_name=Computer Engineering &course=Diploma &intake=60&duration=3 years&fees=34,000&contact=%2B91-9588803611&adcontact=%2B91-9023976473/%2B91-9023965729&ademail=ssvgkalol@gmail.com&head=Dr. Geetanjali Amrawat&heademail=sudeanengg@gmail.com">
                <ul style={{ listStyle: "none" }}>
                  <h5 className="text-danger mt-4">Details</h5>
                  <li className="crs">
                    <p>Course Type : Diploma</p>
                  </li>
                  <li className="crs">
                    <p>Duration of Course : 3 years</p>
                  </li>
                  <li className="crs">
                    <p>Course Intake : 60</p>
                  </li>
                  <li className="crs">
                    <p>Fees : 37000*</p>
                  </li>
                </ul>
              </a>
            </div>
            <div className="col-md-4">
              <ul>
                <li className="crs">
                  <a href="course_details.php?course_name=Information Technology &course=Diploma &intake=60&duration=3 years&fees=34,000&contact=%2B91-9588803611&adcontact=%2B91-9023976473/%2B91-9023965729&ademail=ssvgkalol@gmail.com&head=Dr. Geetanjali Amrawat&heademail=sudeanengg@gmail.com">
                    <h5>Information Engineering</h5>{" "}
                  </a>
                </li>
                <a href="course_details.php?course_name=Information Technology &course=Diploma &intake=60&duration=3 years&fees=34,000&contact=%2B91-9588803611&adcontact=%2B91-9023976473/%2B91-9023965729&ademail=ssvgkalol@gmail.com&head=Dr. Geetanjali Amrawat&heademail=sudeanengg@gmail.com"></a>
              </ul>
              <a href="course_details.php?course_name=Information Technology &course=Diploma &intake=60&duration=3 years&fees=34,000&contact=%2B91-9588803611&adcontact=%2B91-9023976473/%2B91-9023965729&ademail=ssvgkalol@gmail.com&head=Dr. Geetanjali Amrawat&heademail=sudeanengg@gmail.com">
                <ul style={{ listStyle: "none" }}>
                  <h5 className="text-danger mt-4">Details</h5>
                  <li className="crs">
                    <p>Course Type : Diploma</p>
                  </li>
                  <li className="crs">
                    <p>Duration of Course : 3 years</p>
                  </li>
                  <li className="crs">
                    <p>Course Intake : 60</p>
                  </li>
                  <li className="crs">
                    <p>Fees : 37000*</p>
                  </li>
                </ul>
              </a>
            </div>
            <div className="col-md-4">
              <ul>
                <li className="crs">
                  <a href="course_details.php?course_name=Civil Engineering &course=Diploma &intake=60&duration=3 years&fees=34,000&contact=%2B91-9588803611&adcontact=%2B91-9023976473/%2B91-9023965729&ademail=ssvgkalol@gmail.com&head=Dr. Geetanjali Amrawat&heademail=sudeanengg@gmail.com">
                    <h5>Civil Engineering</h5>{" "}
                  </a>
                </li>
                <a href="course_details.php?course_name=Civil Engineering &course=Diploma &intake=60&duration=3 years&fees=34,000&contact=%2B91-9588803611&adcontact=%2B91-9023976473/%2B91-9023965729&ademail=ssvgkalol@gmail.com&head=Dr. Geetanjali Amrawat&heademail=sudeanengg@gmail.com"></a>
              </ul>
              <a href="course_details.php?course_name=Civil Engineering &course=Diploma &intake=60&duration=3 years&fees=34,000&contact=%2B91-9588803611&adcontact=%2B91-9023976473/%2B91-9023965729&ademail=ssvgkalol@gmail.com&head=Dr. Geetanjali Amrawat&heademail=sudeanengg@gmail.com">
                <ul style={{ listStyle: "none" }}>
                  <h5 className="text-danger mt-4">Details</h5>
                  <li className="crs">
                    <p>Course Type : Diploma</p>
                  </li>
                  <li className="crs">
                    <p>Duration of Course : 3 years</p>
                  </li>
                  <li className="crs">
                    <p>Course Intake : 60</p>
                  </li>
                  <li className="crs">
                    <p>Fees : 37000*</p>
                  </li>
                </ul>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Nursing Modal */}
  <div
    className="modal fade"
    id="nursing"
    tabIndex={-1}
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog modal-xl">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel" />
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          />
        </div>
        <div className="modal-body">
          <div className="d-flex align-items-center">
            <img
              src="assets/images/icon/nurse.png"
              className="img-fluid modal_icon"
              alt="engineering icon"
            />
            <h2>Nursing</h2>
          </div>
          <div className="row mt-5">
            <div className="col-md-6">
              <ul>
                <li className="crs">
                  <a
                    href="course_details.php?course_name=GNM Nursing &course=Diploma &intake=50&duration=3years&fees=GQ-94000, MQ- 110000 Subject to change from A.Y.2021-22&contact=%2B91-9427483055&adcontact=%2B91-9427483055&ademail=ssnckalol@gmail.com
&head=Mrs. Kalpana Patel&heademail=ssnckalol@gmail.com"
                  >
                    <h5>GNM Nursing</h5>
                  </a>
                </li>
              </ul>
              <a
                href="course_details.php?course_name=GNM Nursing &course=Diploma &intake=50&duration=3years&fees=GQ-94000, MQ- 110000 Subject to change from A.Y.2021-22&contact=%2B91-9427483055&adcontact=%2B91-9427483055&ademail=ssnckalol@gmail.com
&head=Mrs. Kalpana Patel&heademail=ssnckalol@gmail.com"
              >
                <ul style={{ listStyle: "none" }}>
                  <h5 className="text-danger mt-4">Details</h5>
                  <li className="crs">
                    <p>Course Type : Diploma</p>
                  </li>
                  <li className="crs">
                    <p>Duration of Course : 3 years</p>
                  </li>
                  <li className="crs">
                    <p>Course Intake : 50</p>
                  </li>
                  <li className="crs">
                    <p>
                      Fees : GQ-94000, MQ110000 Subject to change from
                      A.Y.2023-24
                    </p>
                  </li>
                </ul>
              </a>
            </div>
            <div className="col-md-6">
              <ul>
                <li className="crs">
                  <a
                    href="course_details.php?course_name=ANM Nursing &course=Diploma &intake=50&duration=2 years&fees=GQ-38000, MQ- 40000 Subject to change from A.Y.2021-22&contact=%2B91-9427483055&adcontact=%2B91-9427483055&ademail=ssnckalol@gmail.com
&head=Mrs. Kalpana Patel&heademail=ssnckalol@gmail.com"
                  >
                    <h5>ANM Nursing</h5>
                  </a>
                </li>
              </ul>
              <a
                href="course_details.php?course_name=ANM Nursing &course=Diploma &intake=50&duration=2 years&fees=GQ-38000, MQ- 40000 Subject to change from A.Y.2021-22&contact=%2B91-9427483055&adcontact=%2B91-9427483055&ademail=ssnckalol@gmail.com
&head=Mrs. Kalpana Patel&heademail=ssnckalol@gmail.com"
              >
                <ul style={{ listStyle: "none" }}>
                  <h5 className="text-danger mt-4">Details</h5>
                  <li className="crs">
                    <p>Course Type : UG</p>
                  </li>
                  <li className="crs">
                    <p>Duration of Course : 2 years</p>
                  </li>
                  <li className="crs">
                    <p>Course Intake : 20</p>
                  </li>
                  <li className="crs">
                    <p>
                      Fee: GQ-38000, MQ40000 Subject to change from A.Y.2023-24
                    </p>
                  </li>
                </ul>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* law Modal */}
  <div
    className="modal fade"
    id="law"
    tabIndex={-1}
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog modal-xl">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel" />
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          />
        </div>
        <div className="modal-body">
          <div className="d-flex align-items-center">
            <img
              src="assets/images/icon/law.png"
              className="img-fluid modal_icon"
              alt="engineering icon"
            />
            <h2>Law</h2>
          </div>
          <div className="row mt-5">
            <div className="col-md-4">
              <ul>
                <li className="crs">
                  <a href="course_details.php?course_name=Labour  Practice  &course=PG Diploma&intake=40&duration=2 years&fees=15,000&contact=%2B91-9427483055&adcontact=%2B91-9726117000&ademail=sslckalol@gmail.com&head=Dr. Dixit Patel&heademail=dr_mahesh@mail.com">
                    <h5>Labour Parctice (Law) </h5>
                  </a>
                </li>
              </ul>
              <a href="course_details.php?course_name=Labour  Practice  &course=PG Diploma&intake=40&duration=2 years&fees=15,000&contact=%2B91-9427483055&adcontact=%2B91-9726117000&ademail=sslckalol@gmail.com&head=Dr. Dixit Patel&heademail=dr_mahesh@mail.com"></a>
              <ul style={{ listStyle: "none" }}>
                <a href="course_details.php?course_name=Labour  Practice  &course=PG Diploma&intake=40&duration=2 years&fees=15,000&contact=%2B91-9427483055&adcontact=%2B91-9726117000&ademail=sslckalol@gmail.com&head=Dr. Dixit Patel&heademail=dr_mahesh@mail.com">
                  <h5 className="text-danger mt-4">Details</h5>
                  <li className="crs">
                    <p>Course Type : UG</p>
                  </li>
                  <li className="crs">
                    <p>Duration of Course : 3 years</p>
                  </li>
                  <li className="crs">
                    <p>Course Intake : 120</p>
                  </li>
                  <li className="crs">
                    <p>Fees : 25000</p>
                  </li>
                </a>
              </ul>
            </div>
            <div className="col-md-6">
              <ul>
                <li className="crs">
                  <a href="course_details.php?course_name=Banking Law&course=PG Diploma&intake=40&duration=2 years&fees=15,000&contact=%2B91-9427483055&adcontact=%2B91-9726117000&ademail=sslckalol@gmail.com&head=Dr. Dixit Patel&heademail=dr_mahesh@mail.com">
                    <h5>Banking Law</h5>
                  </a>
                </li>
              </ul>
              <a href="course_details.php?course_name=Banking Law&course=PG Diploma&intake=40&duration=2 years&fees=15,000&contact=%2B91-9427483055&adcontact=%2B91-9726117000&ademail=sslckalol@gmail.com&head=Dr. Dixit Patel&heademail=dr_mahesh@mail.com"></a>
              <ul style={{ listStyle: "none" }}>
                <a href="course_details.php?course_name=Banking Law&course=PG Diploma&intake=40&duration=2 years&fees=15,000&contact=%2B91-9427483055&adcontact=%2B91-9726117000&ademail=sslckalol@gmail.com&head=Dr. Dixit Patel&heademail=dr_mahesh@mail.com">
                  <h5 className="text-danger mt-4">Details</h5>
                  <li className="crs">
                    <p>Course Type : UG</p>
                  </li>
                  <li className="crs">
                    <p>Duration of Course : 3 years</p>
                  </li>
                  <li className="crs">
                    <p>Course Intake : 120</p>
                  </li>
                  <li className="crs">
                    <p>Fees : 25000</p>
                  </li>
                </a>
              </ul>
            </div>
            <div className="col-md-6">
              <ul>
                <li className="crs">
                  <a href="course_details.php?course_name=Tax Practice  &course=PG Diploma&intake=40&duration=2 years&fees=15,000&contact=%2B91-9427483055&adcontact=%2B91-9726117000&ademail=sslckalol@gmail.com&head=Dr. Dixit Patel&heademail=dr_mahesh@mail.com">
                    <h5>Tax Practice (Law)</h5>
                  </a>
                </li>
              </ul>
              <a href="course_details.php?course_name=Tax Practice  &course=PG Diploma&intake=40&duration=2 years&fees=15,000&contact=%2B91-9427483055&adcontact=%2B91-9726117000&ademail=sslckalol@gmail.com&head=Dr. Dixit Patel&heademail=dr_mahesh@mail.com"></a>
              <ul style={{ listStyle: "none" }}>
                <a href="course_details.php?course_name=Tax Practice  &course=PG Diploma&intake=40&duration=2 years&fees=15,000&contact=%2B91-9427483055&adcontact=%2B91-9726117000&ademail=sslckalol@gmail.com&head=Dr. Dixit Patel&heademail=dr_mahesh@mail.com">
                  <h5 className="text-danger mt-4">Details</h5>
                  <li className="crs">
                    <p>Course Type : UG</p>
                  </li>
                  <li className="crs">
                    <p>Duration of Course : 3 years</p>
                  </li>
                  <li className="crs">
                    <p>Course Intake : 120</p>
                  </li>
                  <li className="crs">
                    <p>Fees : 25000</p>
                  </li>
                </a>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="description">
    <div className="container-fluid">
      <div className="register" style={{ maxWidth: "90%" }}>
        <h3>Diploma Programme 2023-24</h3>
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col-md-3">
            <div className="card mb-3">
              <div className="d-flex flex-row-reverse card-body p-2 align-items-center  ">
                <div className="p-2 w-100 ">
                  <h4>Engineering</h4>
                  <p>Engineering and Technology</p>
                </div>
                <div className="p-2 flex-shrink-1 ">
                  {" "}
                  <img
                    src="assets/images/icon/engineering.png"
                    className="img-fluid icon "
                    alt="engineering icon"
                  />
                </div>
              </div>
              <hr />
              <div className="d-flex justify-content-around">
                <p className="card-text">
                  <small className="text-muted ">6 Programmes </small>
                </p>
                <div className=" d-flex justify-content-center align-item-center ">
                  <a
                    href=""
                    data-bs-toggle="modal"
                    data-bs-target="#engineering"
                  >
                    {" "}
                    <b>
                      More Info <i className="ri-arrow-right-line" />{" "}
                    </b>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card mb-3">
              <div className="d-flex flex-row-reverse card-body p-2 align-items-center  ">
                <div className="p-2 w-100 ">
                  <h4>Nursing</h4>
                  <p>Nursing</p>
                </div>
                <div className="p-2 flex-shrink-1 ">
                  {" "}
                  <img
                    src="assets/images/icon/nurse.png"
                    className="img-fluid icon "
                    alt=""
                  />
                </div>
              </div>
              <hr />
              <div className="d-flex justify-content-around">
                <p className="card-text">
                  <small className="text-muted ">2 Programmes </small>
                </p>
                <div className=" d-flex justify-content-center align-item-center ">
                  <a href="" data-bs-toggle="modal" data-bs-target="#nursing">
                    {" "}
                    <b>
                      More Info <i className="ri-arrow-right-line" />{" "}
                    </b>
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-md-3">
            <div className="card mb-3">
              <div className="d-flex flex-row-reverse card-body p-2 align-items-center  ">
                <div className="p-2 w-100 ">
                  <h4>Law</h4>
                  <p>School of Law</p>
                </div>
                <div className="p-2 flex-shrink-1 ">
                  {" "}
                  <img
                    src="assets/images/icon/science.png"
                    className="img-fluid icon "
                    alt="science"
                  />
                </div>
              </div>
              <hr />
              <div className="d-flex justify-content-around">
                <p className="card-text">
                  <small className="text-muted ">3 Programmes </small>
                </p>
                <div className=" d-flex justify-content-center align-item-center ">
                  <a href="" data-bs-toggle="modal" data-bs-target="#law">
                    {" "}
                    <b>
                      More Info <i className="ri-arrow-right-line" />{" "}
                    </b>
                  </a>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  </div>
</>

    </div>
  )
}

export default Diploma