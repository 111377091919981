import React from "react";
import "./Graduate.scss";
import { Link } from "react-router-dom";

function Graduate() {
  return (
    <div className="Graduate">
      <>
        {/* Modal */}
        <div
          className="modal fade"
          id="engineering"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel" />
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <div className="d-flex align-items-center">
                  <img
                    src="assets/images/icon/engineering.png"
                    className="img-fluid modal_icon"
                    alt="engineering icon"
                  />
                  {/* <h2>Engineering</h2> */}
                  <Link to={"/engineering"}>
                    <h2>Engineering</h2>
                  </Link>
                </div>
                <div className="row mt-5">
                  <div className="col-md-4">
                    <ul>
                      <li className="crs">
                        <a href="course_details.php?course_name=Automobile Engineering  &course=UG &intake=60&duration=4 years&fees=53,000&contact=%2B91-9588803611&adcontact=%2B91-9023976473/ %2B91-9023965729&ademail=ssvgkalol@gmail.com&head=Dr. Geetanjali Amrawat&heademail=sudeanengg@gmail.com">
                          <h5>Automobile Engineering</h5>{" "}
                        </a>
                      </li>
                      <a href="course_details.php?course_name=Automobile Engineering  &course=UG &intake=60&duration=4 years&fees=53,000&contact=%2B91-9588803611&adcontact=%2B91-9023976473/ %2B91-9023965729&ademail=ssvgkalol@gmail.com&head=Dr. Geetanjali Amrawat&heademail=sudeanengg@gmail.com"></a>
                    </ul>
                    <a href="course_details.php?course_name=Automobile Engineering  &course=UG &intake=60&duration=4 years&fees=53,000&contact=%2B91-9588803611&adcontact=%2B91-9023976473/ %2B91-9023965729&ademail=ssvgkalol@gmail.com&head=Dr. Geetanjali Amrawat&heademail=sudeanengg@gmail.com">
                      <ul style={{ listStyle: "none" }}>
                        <h5 className="text-danger mt-4">Details</h5>
                        <li className="crs">
                          <p>Course Type : UG</p>
                        </li>
                        <li className="crs">
                          <p>Duration of Course : 4 years</p>
                        </li>
                        <li className="crs">
                          <p>Course Intake : 60</p>
                        </li>
                        <li className="crs">
                          <p>Fees : 58000*</p>
                        </li>
                      </ul>
                    </a>
                  </div>
                  <div className="col-md-4">
                    <ul>
                      <li className="crs">
                        <a href="course_details.php?course_name=Mechanical Engineering  &course=UG &intake=60&duration=4 years&fees=53,000&contact=%2B91-9588803611&adcontact=%2B91-9023976473/ %2B91-9023965729&ademail=ssvgkalol@gmail.com&head=Dr. Geetanjali Amrawat&heademail=sudeanengg@gmail.com">
                          <h5>Mechanical Engineering</h5>{" "}
                        </a>
                      </li>
                      <a href="course_details.php?course_name=Mechanical Engineering  &course=UG &intake=60&duration=4 years&fees=53,000&contact=%2B91-9588803611&adcontact=%2B91-9023976473/ %2B91-9023965729&ademail=ssvgkalol@gmail.com&head=Dr. Geetanjali Amrawat&heademail=sudeanengg@gmail.com"></a>
                    </ul>
                    <a href="course_details.php?course_name=Mechanical Engineering  &course=UG &intake=60&duration=4 years&fees=53,000&contact=%2B91-9588803611&adcontact=%2B91-9023976473/ %2B91-9023965729&ademail=ssvgkalol@gmail.com&head=Dr. Geetanjali Amrawat&heademail=sudeanengg@gmail.com">
                      <ul style={{ listStyle: "none" }}>
                        <h5 className="text-danger mt-4">Details</h5>
                        <li className="crs">
                          <p>Course Type : UG</p>
                        </li>
                        <li className="crs">
                          <p>Duration of Course : 4 years</p>
                        </li>
                        <li className="crs">
                          <p>Course Intake : 60</p>
                        </li>
                        <li className="crs">
                          <p>Fees : 58000*</p>
                        </li>
                      </ul>
                    </a>
                  </div>
                  <div className="col-md-4">
                    <ul>
                      <li className="crs">
                        <a href="course_details.php?course_name=Electrical Engineering  &course=UG &intake=60&duration=4 years&fees=53,000&contact=%2B91-9588803611&adcontact=%2B91-9023976473/ %2B91-9023965729&ademail=ssvgkalol@gmail.com&head=Dr. Geetanjali Amrawat&heademail=sudeanengg@gmail.com">
                          <h5>Electrical Engineering</h5>{" "}
                        </a>
                      </li>
                      <a href="course_details.php?course_name=Electrical Engineering  &course=UG &intake=60&duration=4 years&fees=53,000&contact=%2B91-9588803611&adcontact=%2B91-9023976473/ %2B91-9023965729&ademail=ssvgkalol@gmail.com&head=Dr. Geetanjali Amrawat&heademail=sudeanengg@gmail.com"></a>
                    </ul>
                    <a href="course_details.php?course_name=Electrical Engineering  &course=UG &intake=60&duration=4 years&fees=53,000&contact=%2B91-9588803611&adcontact=%2B91-9023976473/ %2B91-9023965729&ademail=ssvgkalol@gmail.com&head=Dr. Geetanjali Amrawat&heademail=sudeanengg@gmail.com">
                      <ul style={{ listStyle: "none" }}>
                        <h5 className="text-danger mt-4">Details</h5>
                        <li className="crs">
                          <p>Course Type : UG</p>
                        </li>
                        <li className="crs">
                          <p>Duration of Course : 4 years</p>
                        </li>
                        <li className="crs">
                          <p>Course Intake : 60</p>
                        </li>
                        <li className="crs">
                          <p>Fees : 58000*</p>
                        </li>
                      </ul>
                    </a>
                  </div>
                  <div className="col-md-4">
                    <ul>
                      <li className="crs">
                        <a href="course_details.php?course_name=Computer Engineering  &course=UG &intake=60&duration=4 years&fees=53,000&contact=%2B91-9588803611&adcontact=%2B91-9023976473/ %2B91-9023965729&ademail=ssvgkalol@gmail.com&head=Dr. Geetanjali Amrawat&heademail=sudeanengg@gmail.com">
                          <h5>Computer Engineering</h5>{" "}
                        </a>
                      </li>
                      <a href="course_details.php?course_name=Computer Engineering  &course=UG &intake=60&duration=4 years&fees=53,000&contact=%2B91-9588803611&adcontact=%2B91-9023976473/ %2B91-9023965729&ademail=ssvgkalol@gmail.com&head=Dr. Geetanjali Amrawat&heademail=sudeanengg@gmail.com"></a>
                    </ul>
                    <a href="course_details.php?course_name=Computer Engineering  &course=UG &intake=60&duration=4 years&fees=53,000&contact=%2B91-9588803611&adcontact=%2B91-9023976473/ %2B91-9023965729&ademail=ssvgkalol@gmail.com&head=Dr. Geetanjali Amrawat&heademail=sudeanengg@gmail.com">
                      <ul style={{ listStyle: "none" }}>
                        <h5 className="text-danger mt-4">Details</h5>
                        <li className="crs">
                          <p>Course Type : UG</p>
                        </li>
                        <li className="crs">
                          <p>Duration of Course : 4 years</p>
                        </li>
                        <li className="crs">
                          <p>Course Intake : 60</p>
                        </li>
                        <li className="crs">
                          <p>Fees : 58000*</p>
                        </li>
                      </ul>
                    </a>
                  </div>

                  <div className="col-md-4">
                    <ul>
                      <li className="crs">
                        <a href="course_details.php?course_name=Civil Engineering  &course=UG &intake=60&duration=4 years&fees=53,000&contact=%2B91-9588803611&adcontact=%2B91-9023976473/ %2B91-9023965729&ademail=ssvgkalol@gmail.com&head=Dr. Geetanjali Amrawat&heademail=sudeanengg@gmail.com">
                          <h5>Civil Engineering</h5>{" "}
                        </a>
                      </li>
                      <a href="course_details.php?course_name=Civil Engineering  &course=UG &intake=60&duration=4 years&fees=53,000&contact=%2B91-9588803611&adcontact=%2B91-9023976473/ %2B91-9023965729&ademail=ssvgkalol@gmail.com&head=Dr. Geetanjali Amrawat&heademail=sudeanengg@gmail.com"></a>
                    </ul>
                    <a href="course_details.php?course_name=Civil Engineering  &course=UG &intake=60&duration=4 years&fees=53,000&contact=%2B91-9588803611&adcontact=%2B91-9023976473/ %2B91-9023965729&ademail=ssvgkalol@gmail.com&head=Dr. Geetanjali Amrawat&heademail=sudeanengg@gmail.com">
                      <ul style={{ listStyle: "none" }}>
                        <h5 className="text-danger mt-4">Details</h5>
                        <li className="crs">
                          <p>Course Type : UG</p>
                        </li>
                        <li className="crs">
                          <p>Duration of Course : 4 years</p>
                        </li>
                        <li className="crs">
                          <p>Course Intake : 60</p>
                        </li>
                        <li className="crs">
                          <p>Fees : 58000*</p>
                        </li>
                      </ul>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Nursing Modal */}
        <div
          className="modal fade"
          id="nursing"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel" />
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <div className="d-flex align-items-center">
                  <img
                    src="assets/images/icon/nurse.png"
                    className="img-fluid modal_icon"
                    alt="engineering icon"
                  />
                  {/* <h2>Nursing</h2> */}
                  <Link to={"/nursing"}>
                    <h2>Nursing</h2>
                  </Link>
                </div>
                <div className="row mt-5">
                  <div className="col-md-6">
                    <ul>
                      <li className="crs">
                        <a
                          href="course_details.php?course_name=B.Sc. Nursing &course=UG &intake=60&duration=4 years&fees=GQ-106000/Year, 
MQ- 150000/Year Subject to change from A.Y.2021-22&contact=%2B91-9427483055&adcontact=%2B91-9427483055&ademail=ssnckalol@gmail.com&head=Mrs. Kalpana Patel &heademail=ssnckalol@gmail.com"
                        >
                          <h5>B.Sc. Nursing</h5>
                        </a>
                      </li>
                    </ul>
                    <a
                      href="course_details.php?course_name=B.Sc. Nursing &course=UG &intake=60&duration=4 years&fees=GQ-106000/Year, 
MQ- 150000/Year Subject to change from A.Y.2021-22&contact=%2B91-9427483055&adcontact=%2B91-9427483055&ademail=ssnckalol@gmail.com&head=Mrs. Kalpana Patel &heademail=ssnckalol@gmail.com"
                    >
                      <ul style={{ listStyle: "none" }}>
                        <h5 className="text-danger mt-4">Details</h5>
                        <li className="crs">
                          <p>Course Type : UG</p>
                        </li>
                        <li className="crs">
                          <p>Duration of Course : 4 years</p>
                        </li>
                        <li className="crs">
                          <p>Course Intake : 60</p>
                        </li>
                        <li className="crs">
                          <p>
                            Fees : GQ106000/Year, MQ150000/Year Subject to
                            change from A.Y.2021-22
                          </p>
                        </li>
                      </ul>
                    </a>
                  </div>
                  <div className="col-md-6">
                    <ul>
                      <li className="crs">
                        <a href="course_details.php?course_name=Post Basic B.Sc. Nursing  &course=UG &intake=50&duration=2 years&fees=GQ-70000, MQ-100000     Subject to change from A.Y.2021-22&contact=%2B91-9427483055&adcontact=%2B91-9427483055&ademail=ssnckalol@gmail.com&head=Mrs. Kalpana Patel &heademail=ssnckalol@gmail.com">
                          <h5>Post Basic B.Sc. Nursing</h5>
                        </a>
                      </li>
                    </ul>
                    <a href="course_details.php?course_name=Post Basic B.Sc. Nursing  &course=UG &intake=50&duration=2 years&fees=GQ-70000, MQ-100000     Subject to change from A.Y.2021-22&contact=%2B91-9427483055&adcontact=%2B91-9427483055&ademail=ssnckalol@gmail.com&head=Mrs. Kalpana Patel &heademail=ssnckalol@gmail.com">
                      <ul style={{ listStyle: "none" }}>
                        <h5 className="text-danger mt-4">Details</h5>
                        <li className="crs">
                          <p>Course Type : UG</p>
                        </li>
                        <li className="crs">
                          <p>Duration of Course : 2 years</p>
                        </li>
                        <li className="crs">
                          <p>Course Intake : 60</p>
                        </li>
                        <li className="crs">
                          <p>
                            GQ-70000, MQ80000 Subject to change from A.Y.2021-22
                          </p>
                        </li>
                      </ul>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Basic Science */}
        <div
          className="modal fade"
          id="bs"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel" />
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <div className="d-flex align-items-center">
                  <img
                    src="assets/images/icon/science.png"
                    className="img-fluid modal_icon"
                    alt="engineering icon"
                  />
                  {/* <h2>Basic Science</h2> */}
                  <Link to={"/science"}>
                    <h2>Basic Science</h2>
                  </Link>
                </div>
                <div className="row mt-5">
                  <div className="col-md-4">
                    <ul>
                      <li className="crs">
                        <a href="course_details.php?course_name=B.Sc. Chemistry &course=UG &intake=240&duration=3 years&fees=25,000&contact=%2B91-8320496391&adcontact=%2B91-9033071787&ademail=drpradeepverma0205@gmail.com &head=Dr. Pradeep U. Verma&heademail=drpradeepverma0205@gmail.com">
                          <h4 className="text-center" />
                          <h5>B.Sc. in Chemistry</h5>{" "}
                        </a>
                      </li>
                      <a href="course_details.php?course_name=B.Sc. Chemistry &course=UG &intake=240&duration=3 years&fees=25,000&contact=%2B91-8320496391&adcontact=%2B91-9033071787&ademail=drpradeepverma0205@gmail.com &head=Dr. Pradeep U. Verma&heademail=drpradeepverma0205@gmail.com"></a>
                    </ul>
                    <a href="course_details.php?course_name=B.Sc. Chemistry &course=UG &intake=240&duration=3 years&fees=25,000&contact=%2B91-8320496391&adcontact=%2B91-9033071787&ademail=drpradeepverma0205@gmail.com &head=Dr. Pradeep U. Verma&heademail=drpradeepverma0205@gmail.com">
                      <ul style={{ listStyle: "none" }}>
                        <h5 className="text-danger mt-4">Details</h5>
                        <li className="crs">
                          <p>Course Type : PG</p>
                        </li>
                        <li className="crs">
                          <p>Duration of Course : 3 years</p>
                        </li>
                        <li className="crs">
                          <p>Course Intake : 120</p>
                        </li>
                        <li className="crs">
                          <p>Fees : 40000</p>
                        </li>
                      </ul>
                    </a>
                  </div>
                  <div className="col-md-4">
                    <ul>
                      <li className="crs">
                        <a href="course_details.php?course_name=B.Sc. Microbiology &course=UG &intake=120&duration=3 years&fees=25,000&contact=%2B91-8320496391&adcontact=%2B91-9033071787&ademail=drpradeepverma0205@gmail.com &head=Dr. Pradeep U. Verma&heademail=drpradeepverma0205@gmail.com">
                          <h5>B.Sc. in Microbiology</h5>{" "}
                        </a>
                      </li>
                      <a href="course_details.php?course_name=B.Sc. Microbiology &course=UG &intake=120&duration=3 years&fees=25,000&contact=%2B91-8320496391&adcontact=%2B91-9033071787&ademail=drpradeepverma0205@gmail.com &head=Dr. Pradeep U. Verma&heademail=drpradeepverma0205@gmail.com"></a>
                    </ul>
                    <a href="course_details.php?course_name=B.Sc. Microbiology &course=UG &intake=120&duration=3 years&fees=25,000&contact=%2B91-8320496391&adcontact=%2B91-9033071787&ademail=drpradeepverma0205@gmail.com &head=Dr. Pradeep U. Verma&heademail=drpradeepverma0205@gmail.com">
                      <ul style={{ listStyle: "none" }}>
                        <h5 className="text-danger mt-4">Details</h5>
                        <li className="crs">
                          <p>Course Type : UG</p>
                        </li>
                        <li className="crs">
                          <p>Duration of Course : 3 years</p>
                        </li>
                        <li className="crs">
                          <p>Course Intake : 60</p>
                        </li>
                        <li className="crs">
                          <p>Fees : 25000</p>
                        </li>
                      </ul>
                    </a>
                  </div>
                  <div className="col-md-4">
                    <ul>
                      <li className="crs">
                        <a href="course_details.php?course_name=B.Sc. Botany &course=UG &intake=60&duration=3 years&fees=25,000&contact=%2B91-8320496391&adcontact=%2B91-9033071787&ademail=drpradeepverma0205@gmail.com &head=Dr. Pradeep U. Verma&heademail=drpradeepverma0205@gmail.com">
                          <h5>B.Sc. in Botany</h5>{" "}
                        </a>
                      </li>
                      <a href="course_details.php?course_name=B.Sc. Botany &course=UG &intake=60&duration=3 years&fees=25,000&contact=%2B91-8320496391&adcontact=%2B91-9033071787&ademail=drpradeepverma0205@gmail.com &head=Dr. Pradeep U. Verma&heademail=drpradeepverma0205@gmail.com"></a>
                    </ul>
                    <a href="course_details.php?course_name=B.Sc. Botany &course=UG &intake=60&duration=3 years&fees=25,000&contact=%2B91-8320496391&adcontact=%2B91-9033071787&ademail=drpradeepverma0205@gmail.com &head=Dr. Pradeep U. Verma&heademail=drpradeepverma0205@gmail.com">
                      <ul style={{ listStyle: "none" }}>
                        <h5 className="text-danger mt-4">Details</h5>
                        <li className="crs">
                          <p>Course Type : UG</p>
                        </li>
                        <li className="crs">
                          <p>Duration of Course : 3 years</p>
                        </li>
                        <li className="crs">
                          <p>Course Intake : 30</p>
                        </li>
                        <li className="crs">
                          <p>Fees : 25000</p>
                        </li>
                      </ul>
                    </a>
                  </div>
                  <div className="col-md-4">
                    <ul>
                      <li className="crs">
                        <a href="course_details.php?course_name=B.Sc. Zoology &course=UG &intake=60&duration=3 years&fees=25,000&contact=%2B91-8320496391&adcontact=%2B91-9033071787&ademail=drpradeepverma0205@gmail.com &head=Dr. Pradeep U. Verma&heademail=drpradeepverma0205@gmail.com">
                          <h5>B.Sc. in Zoology</h5>{" "}
                        </a>
                      </li>
                      <a href="course_details.php?course_name=B.Sc. Zoology &course=UG &intake=60&duration=3 years&fees=25,000&contact=%2B91-8320496391&adcontact=%2B91-9033071787&ademail=drpradeepverma0205@gmail.com &head=Dr. Pradeep U. Verma&heademail=drpradeepverma0205@gmail.com"></a>
                    </ul>
                    <a href="course_details.php?course_name=B.Sc. Zoology &course=UG &intake=60&duration=3 years&fees=25,000&contact=%2B91-8320496391&adcontact=%2B91-9033071787&ademail=drpradeepverma0205@gmail.com &head=Dr. Pradeep U. Verma&heademail=drpradeepverma0205@gmail.com">
                      <ul style={{ listStyle: "none" }}>
                        <h5 className="text-danger mt-4">Details</h5>
                        <li className="crs">
                          <p>Course Type : UG</p>
                        </li>
                        <li className="crs">
                          <p>Duration of Course : 3 years</p>
                        </li>
                        <li className="crs">
                          <p>Course Intake : 30</p>
                        </li>
                        <li className="crs">
                          <p>Fees : 25000</p>
                        </li>
                      </ul>
                    </a>
                  </div>
                  <div className="col-md-4">
                    <ul>
                      <li className="crs">
                        <a href="course_details.php?course_name=BSc in Mathematics  &course=UG &intake=60&duration=3 years&fees=25,000&contact=%2B91-8320496391&adcontact=%2B91-9033071787&ademail=drpradeepverma0205@gmail.com &head=Dr. Pradeep U. Verma&heademail=drpradeepverma0205@gmail.com">
                          <h5>B.Sc. in Mathematics</h5>{" "}
                        </a>
                      </li>
                      <a href="course_details.php?course_name=BSc in Mathematics  &course=UG &intake=60&duration=3 years&fees=25,000&contact=%2B91-8320496391&adcontact=%2B91-9033071787&ademail=drpradeepverma0205@gmail.com &head=Dr. Pradeep U. Verma&heademail=drpradeepverma0205@gmail.com"></a>
                    </ul>
                    <a href="course_details.php?course_name=BSc in Mathematics  &course=UG &intake=60&duration=3 years&fees=25,000&contact=%2B91-8320496391&adcontact=%2B91-9033071787&ademail=drpradeepverma0205@gmail.com &head=Dr. Pradeep U. Verma&heademail=drpradeepverma0205@gmail.com">
                      <ul style={{ listStyle: "none" }}>
                        <h5 className="text-danger mt-4">Details</h5>
                        <li className="crs">
                          <p>Course Type : UG</p>
                        </li>
                        <li className="crs">
                          <p>Duration of Course : 3 years</p>
                        </li>
                        <li className="crs">
                          <p>Course Intake : 30</p>
                        </li>
                        <li className="crs">
                          <p>Fees : 25000</p>
                        </li>
                      </ul>
                    </a>
                  </div>
                  <div className="col-md-4">
                    <ul>
                      <li className="crs">
                        <a href="course_details.php?course_name=BSc in Biotechnology  &course=UG &intake=60&duration=3 years&fees=25,000&contact=%2B91-8320496391&adcontact=%2B91-9033071787&ademail=drpradeepverma0205@gmail.com &head=Dr. Pradeep U. Verma&heademail=drpradeepverma0205@gmail.com">
                          <h5>B.Sc. in Biotechnology</h5>{" "}
                        </a>
                      </li>
                      <a href="course_details.php?course_name=BSc in Biotechnology  &course=UG &intake=60&duration=3 years&fees=25,000&contact=%2B91-8320496391&adcontact=%2B91-9033071787&ademail=drpradeepverma0205@gmail.com &head=Dr. Pradeep U. Verma&heademail=drpradeepverma0205@gmail.com"></a>
                    </ul>
                    <a href="course_details.php?course_name=BSc in Biotechnology  &course=UG &intake=60&duration=3 years&fees=25,000&contact=%2B91-8320496391&adcontact=%2B91-9033071787&ademail=drpradeepverma0205@gmail.com &head=Dr. Pradeep U. Verma&heademail=drpradeepverma0205@gmail.com">
                      <ul style={{ listStyle: "none" }}>
                        <h5 className="text-danger mt-4">Details</h5>
                        <li className="crs">
                          <p>Course Type : UG</p>
                        </li>
                        <li className="crs">
                          <p>Duration of Course : 3 years</p>
                        </li>
                        <li className="crs">
                          <p>Course Intake : 30</p>
                        </li>
                        <li className="crs">
                          <p>Fees : 25000</p>
                        </li>
                      </ul>
                    </a>
                  </div>
                  <div className="col-md-4">
                    <ul>
                      <li className="crs">
                        <a href="course_details.php?course_name=BSc in Physics  &course=UG &intake=60&duration=3 years&fees=25,000&contact=%2B91-8320496391&adcontact=%2B91-9033071787&ademail=drpradeepverma0205@gmail.com &head=Dr. Pradeep U. Verma&heademail=drpradeepverma0205@gmail.com">
                          <h5>B.Sc. in Physics</h5>{" "}
                        </a>
                      </li>
                      <a href="course_details.php?course_name=BSc in Physics  &course=UG &intake=60&duration=3 years&fees=25,000&contact=%2B91-8320496391&adcontact=%2B91-9033071787&ademail=drpradeepverma0205@gmail.com &head=Dr. Pradeep U. Verma&heademail=drpradeepverma0205@gmail.com"></a>
                    </ul>
                    <a href="course_details.php?course_name=BSc in Physics  &course=UG &intake=60&duration=3 years&fees=25,000&contact=%2B91-8320496391&adcontact=%2B91-9033071787&ademail=drpradeepverma0205@gmail.com &head=Dr. Pradeep U. Verma&heademail=drpradeepverma0205@gmail.com">
                      <ul style={{ listStyle: "none" }}>
                        <h5 className="text-danger mt-4">Details</h5>
                        <li className="crs">
                          <p>Course Type : UG</p>
                        </li>
                        <li className="crs">
                          <p>Duration of Course : 3 years</p>
                        </li>
                        <li className="crs">
                          <p>Course Intake : 30</p>
                        </li>
                        <li className="crs">
                          <p>Fees : 25000</p>
                        </li>
                      </ul>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*Management */}
        <div
          className="modal fade"
          id="management"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel" />
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <div className="d-flex align-items-center">
                  <img
                    src="assets/images/icon/management.png"
                    className="img-fluid modal_icon"
                    alt="engineering icon"
                  />
                  {/* <h2>Commerce and Management</h2> */}
                  <Link to={"/commerce"}>
                    <h2>Commerce and Management</h2>
                  </Link>
                </div>
                <div className="row mt-5">
                  <div className="col-md-6">
                    <ul>
                      <li className="crs">
                        <a href="course_details.php?course_name= B.Com (Commerce)  &course=UG &intake=120&duration=3 years&fees=25,000&contact=%2B91-9879488309 / %2B91-9824804530&adcontact=%2B91-9879488309 / %2B91-9824804530&ademail=commerce.swaminarayanuni@gmail.com&head=Mr. Dhruv S. Patel&heademail=commerce.swaminarayanuni@gmail.com">
                          <h5>B. Com(H)</h5>
                        </a>
                      </li>
                    </ul>
                    <a href="course_details.php?course_name= B.Com (Commerce)  &course=UG &intake=120&duration=3 years&fees=25,000&contact=%2B91-9879488309 / %2B91-9824804530&adcontact=%2B91-9879488309 / %2B91-9824804530&ademail=commerce.swaminarayanuni@gmail.com&head=Mr. Dhruv S. Patel&heademail=commerce.swaminarayanuni@gmail.com"></a>
                    <ul style={{ listStyle: "none" }}>
                      <a href="course_details.php?course_name= B.Com (Commerce)  &course=UG &intake=120&duration=3 years&fees=25,000&contact=%2B91-9879488309 / %2B91-9824804530&adcontact=%2B91-9879488309 / %2B91-9824804530&ademail=commerce.swaminarayanuni@gmail.com&head=Mr. Dhruv S. Patel&heademail=commerce.swaminarayanuni@gmail.com">
                        <h5 className="text-danger mt-4">Details</h5>
                        <li className="crs">
                          <p>Course Type : UG</p>
                        </li>
                        <li className="crs">
                          <p>Duration of Course : 4 years</p>
                        </li>
                        <li className="crs">
                          <p>Course Intake : 120</p>
                        </li>
                        <li className="crs">
                          <p>Fees : 15,000/- fee per annum</p>
                        </li>
                      </a>
                      {/* <a
                  href="commerce_application.php"
                  className="btn btn-sm btn-danger mt-3"
                >
                  Apply Now
                </a> */}
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <ul>
                      <li className="crs">
                        <a href="course_details.php?course_name= BBA (Management)  &course=UG &intake=60&duration=3 years&fees=25,000&contact=%2B91-9879488309 / %2B91-9824804530&adcontact=%2B91-9879488309 / %2B91-9824804530&ademail=commerce.swaminarayanuni@gmail.com&head=Mr. Dhruv S. Patel&heademail=commerce.swaminarayanuni@gmail.com">
                          <h5>BBA(H)</h5>
                        </a>
                      </li>
                    </ul>
                    <a href="course_details.php?course_name= BBA (Management)  &course=UG &intake=60&duration=3 years&fees=25,000&contact=%2B91-9879488309 / %2B91-9824804530&adcontact=%2B91-9879488309 / %2B91-9824804530&ademail=commerce.swaminarayanuni@gmail.com&head=Mr. Dhruv S. Patel&heademail=commerce.swaminarayanuni@gmail.com"></a>
                    <ul style={{ listStyle: "none" }}>
                      <a href="course_details.php?course_name= BBA (Management)  &course=UG &intake=60&duration=3 years&fees=25,000&contact=%2B91-9879488309 / %2B91-9824804530&adcontact=%2B91-9879488309 / %2B91-9824804530&ademail=commerce.swaminarayanuni@gmail.com&head=Mr. Dhruv S. Patel&heademail=commerce.swaminarayanuni@gmail.com">
                        <h5 className="text-danger mt-4">Details</h5>
                        <li className="crs">
                          <p>Course Type : UG</p>
                        </li>
                        <li className="crs">
                          <p>Duration of Course : 4 years</p>
                        </li>
                        <li className="crs">
                          <p>Course Intake : 120</p>
                        </li>
                        <li className="crs">
                          <p>Fees : 20,000/- fee per annum</p>
                        </li>
                      </a>
                      {/* <a
                  href="commerce_application.php"
                  className="btn btn-sm btn-danger mt-3"
                >
                  Apply Now
                </a> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Education */}
        <div
          className="modal fade"
          id="education"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel" />
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <div className="d-flex align-items-center">
                  <img
                    src="assets/images/icon/education.png"
                    className="img-fluid modal_icon"
                    alt="engineering icon"
                  />
                  {/* <h2>Education</h2> */}
                  <Link to={"/education"}>
                    <h2>Education</h2>
                  </Link>
                </div>
                <div className="row mt-5">
                  <div className="col-md-6">
                    <ul>
                      <li className="crs">
                        <a href="course_details.php?course_name= B.Ed.  &course=UG &intake=110&duration=2 years&fees=32,000&contact=%2B91-9879010744&adcontact=%2B91-8264980730 / %2B91-9998561386&ademail=bed.ssvgkalol@gmail.com&head=Dr Gunjan U Shah&heademail=gunjanushah@yahoo.com">
                          <h5>B.Ed.</h5>
                        </a>
                      </li>
                    </ul>
                    <a href="course_details.php?course_name= B.Ed.  &course=UG &intake=110&duration=2 years&fees=32,000&contact=%2B91-9879010744&adcontact=%2B91-8264980730 / %2B91-9998561386&ademail=bed.ssvgkalol@gmail.com&head=Dr Gunjan U Shah&heademail=gunjanushah@yahoo.com"></a>
                    <ul style={{ listStyle: "none" }}>
                      <a href="course_details.php?course_name= B.Ed.  &course=UG &intake=110&duration=2 years&fees=32,000&contact=%2B91-9879010744&adcontact=%2B91-8264980730 / %2B91-9998561386&ademail=bed.ssvgkalol@gmail.com&head=Dr Gunjan U Shah&heademail=gunjanushah@yahoo.com">
                        <h5 className="text-danger mt-4">Details</h5>
                        <li className="crs">
                          <p>Course Type : UG</p>
                        </li>
                        <li className="crs">
                          <p>Duration of Course : 2 years</p>
                        </li>
                        <li className="crs">
                          <p>Course Intake : 110</p>
                        </li>
                        <li className="crs">
                          <p>Fees : 32000</p>
                        </li>
                      </a>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Physiotherapy */}
        <div
          className="modal fade"
          id="physiotherapy"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel" />
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <div className="d-flex align-items-center">
                  <img
                    src="assets/images/icon/physiotherapy.png"
                    className="img-fluid modal_icon"
                    alt="physiotherapy icon"
                  />
                  {/* <h2>Physiotherapy</h2> */}
                  <Link to={"/Physiotherapy"}>
                    <h2>Physiotherapy</h2>
                  </Link>
                </div>
                <div className="row mt-5">
                  <div className="col-md-6">
                    <ul>
                      <li className="crs">
                        <a href="course_details.php?course_name= B.Ed.  &course=UG &intake=110&duration=2 years&fees=32,000&contact=%2B91-9879010744&adcontact=%2B91-8264980730 / %2B91-9998561386&ademail=bed.ssvgkalol@gmail.com&head=Dr Gunjan U Shah&heademail=gunjanushah@yahoo.com">
                          <h5>Bachelor of Physiotherapy (BPT)</h5>
                        </a>
                      </li>
                    </ul>
                    <a href="course_details.php?course_name= B.Ed.  &course=UG &intake=110&duration=2 years&fees=32,000&contact=%2B91-9879010744&adcontact=%2B91-8264980730 / %2B91-9998561386&ademail=bed.ssvgkalol@gmail.com&head=Dr Gunjan U Shah&heademail=gunjanushah@yahoo.com"></a>
                    <ul style={{ listStyle: "none" }}>
                      <a href="course_details.php?course_name= B.Ed.  &course=UG &intake=110&duration=2 years&fees=32,000&contact=%2B91-9879010744&adcontact=%2B91-8264980730 / %2B91-9998561386&ademail=bed.ssvgkalol@gmail.com&head=Dr Gunjan U Shah&heademail=gunjanushah@yahoo.com">
                        <h5 className="text-danger mt-4">Details</h5>
                        <li className="crs">
                          <p>Course Type : UG</p>
                        </li>
                        <li className="crs">
                          <p>Duration of Course : 4 years</p>
                        </li>
                        <li className="crs">
                          <p>Course Intake : 50</p>
                        </li>
                        <li className="crs">
                          <p>Fees : 77000 /-</p>
                        </li>
                      </a>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Pharmacy Modal */}
        <div
          className="modal fade"
          id="pharmacy"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel" />
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <div className="d-flex align-items-center">
                  <img
                    src="assets/images/icon/pharmacy.png"
                    className="img-fluid modal_icon"
                    alt="engineering icon"
                  />
                  {/* <h2>Pharmacy</h2> */}
                  <Link to={"/pharmacy"}>
                    <h2>Pharmacy</h2>
                  </Link>
                </div>
                <div className="row mt-5">
                  <div className="col-md-6">
                    <ul>
                      <li className="crs">
                        <a href="course_details.php?course_name=B.Pharm &course=UG &intake=60&duration=4 years&fees=59,000&contact=%2B91-9428114678&adcontact=%2B91-9099063158&ademail=bph258owner@gtu.edu.in&head=Dr. Ravi Patel&heademail= bph258owner@gtu.edu.in">
                          <h5>B. Pharm</h5>
                        </a>
                      </li>
                    </ul>
                    <a href="course_details.php?course_name=B.Pharm &course=UG &intake=60&duration=4 years&fees=59,000&contact=%2B91-9428114678&adcontact=%2B91-9099063158&ademail=bph258owner@gtu.edu.in&head=Dr. Ravi Patel&heademail= bph258owner@gtu.edu.in"></a>
                    <ul style={{ listStyle: "none" }}>
                      <a href="course_details.php?course_name=B.Pharm &course=UG &intake=60&duration=4 years&fees=59,000&contact=%2B91-9428114678&adcontact=%2B91-9099063158&ademail=bph258owner@gtu.edu.in&head=Dr. Ravi Patel&heademail= bph258owner@gtu.edu.in">
                        <h5 className="text-danger mt-4">Details</h5>
                        <li className="crs">
                          <p>Course Type : UG</p>
                        </li>
                        <li className="crs">
                          <p>Duration of Course : 4 years</p>
                        </li>
                        <li className="crs">
                          <p>Course Intake : 60</p>
                        </li>
                        <li className="crs">
                          <p>Fees : 64900</p>
                        </li>
                      </a>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Medical Modal */}
        <div
          className="modal fade"
          id="medical"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel" />
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <div className="d-flex align-items-center">
                  <img
                    src="assets/images/icon/doctor.png"
                    className="img-fluid modal_icon"
                    alt="engineering icon"
                  />
                  {/* <h2>Medical</h2> */}
                  <Link to={"/sims"}>
                    <h2>Medical</h2>
                  </Link>
                </div>
                <div className="row mt-5">
                  <div className="col-md-6">
                    <ul>
                      <li className="crs">
                        <a href="course_details.php?course_name=MD (RN,SAMHITA,KC) &course=PG &intake=Each 5&duration=3 years&fees=3,00,000/Year* Subject to Approve from FRC&contact=%2B91-9449231730&adcontact=%2B91-8487878000&ademail=ssackalol@gmail.com&head=Dr. Rohit Gokarn&heademail=ssackalol@gmail.com">
                          <h5>MBBS</h5>{" "}
                        </a>
                      </li>
                      <a href="course_details.php?course_name=MD (RN,SAMHITA,KC) &course=PG &intake=Each 5&duration=3 years&fees=3,00,000/Year* Subject to Approve from FRC&contact=%2B91-9449231730&adcontact=%2B91-8487878000&ademail=ssackalol@gmail.com&head=Dr. Rohit Gokarn&heademail=ssackalol@gmail.com"></a>
                    </ul>
                    <a href="course_details.php?course_name=MD (RN,SAMHITA,KC) &course=PG &intake=Each 5&duration=3 years&fees=3,00,000/Year* Subject to Approve from FRC&contact=%2B91-9449231730&adcontact=%2B91-8487878000&ademail=ssackalol@gmail.com&head=Dr. Rohit Gokarn&heademail=ssackalol@gmail.com">
                      <ul style={{ listStyle: "none" }}>
                        <h5 className="text-danger mt-4">Details</h5>
                        <li className="crs">
                          <p>Course Type : UG</p>
                        </li>
                        <li className="crs">
                          <p>Duration of Course : 4.5 years</p>
                        </li>
                        <li className="crs">
                          <p>Course Intake: 150</p>
                        </li>
                        <li className="crs">
                          <p>Fee: As per FRC A.Y.2023-24</p>
                        </li>
                      </ul>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Ayurveda Modal */}
        <div
          className="modal fade"
          id="ayurveda"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel" />
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <div className="d-flex align-items-center">
                  <img
                    src="assets/images/icon/ayurveda.png"
                    className="img-fluid modal_icon"
                    alt="engineering icon"
                  />
                  {/* <h2>Ayurveda</h2> */}
                  <Link to={"/ayurveda"}>
                    <h2>Ayurveda</h2>
                  </Link>
                </div>
                <div className="row mt-5">
                  <div className="col-md-6">
                    <ul>
                      <li className="crs">
                        <a href="course_details.php?course_name=BAMS (Ayurveda) &course=UG &intake=60&duration=4.5 years&fees=GQ- 271000/Year,MQ- 385000/Year Subject to change from A.Y.2021-22&contact=%2B91-9449231730&adcontact=%2B91-8487878000&ademail=ssackalol@gmail.com&head=Dr. Rohit Gokarn&heademail=ssackalol@gmail.com">
                          <h5>BAMS (Ayurveda)</h5>
                        </a>
                      </li>
                    </ul>
                    <a href="course_details.php?course_name=BAMS (Ayurveda) &course=UG &intake=60&duration=4.5 years&fees=GQ- 271000/Year,MQ- 385000/Year Subject to change from A.Y.2021-22&contact=%2B91-9449231730&adcontact=%2B91-8487878000&ademail=ssackalol@gmail.com&head=Dr. Rohit Gokarn&heademail=ssackalol@gmail.com"></a>
                    <ul style={{ listStyle: "none" }}>
                      <a href="course_details.php?course_name=BAMS (Ayurveda) &course=UG &intake=60&duration=4.5 years&fees=GQ- 271000/Year,MQ- 385000/Year Subject to change from A.Y.2021-22&contact=%2B91-9449231730&adcontact=%2B91-8487878000&ademail=ssackalol@gmail.com&head=Dr. Rohit Gokarn&heademail=ssackalol@gmail.com">
                        <h5 className="text-danger mt-4">Details</h5>
                        <li className="crs">
                          <p>Course Type : UG</p>
                        </li>
                        <li className="crs">
                          <p>Duration of Course : 4.5 years</p>
                        </li>
                        <li className="crs">
                          <p>Internship : 1 year Internship</p>
                        </li>
                        <li className="crs">
                          <p>Course Intake : 100</p>
                        </li>
                        <li className="crs">
                          <p>Fees :GQ-271000/Year, MQ385000/Year</p>
                        </li>
                      </a>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Homeopathy Modal */}
        <div
          className="modal fade"
          id="homeopathy"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel" />
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <div className="d-flex align-items-center">
                  <img
                    src="assets/images/icon/homeopathy.png"
                    className="img-fluid modal_icon"
                    alt="engineering icon"
                  />
                  {/* <h2>Homeopathy</h2> */}
                  <Link to={"/homoeopathy"}>
                    <h2>Homoeopathy</h2>
                  </Link>
                </div>
                <div className="row mt-5">
                  <div className="col-md-6">
                    <ul>
                      <li className="crs">
                        <a href="course_details.php?course_name=B.H.M.S (Homeopathy) &course=UG &intake=100&duration=5.5 years&fees=G.Q -1,15,000/- M.Q.-1,55,000/-Subject to change from A.Y.2021-22&contact=%2B91-9879562184&adcontact=%2B91-8487878000&ademail=sshckalol@gmail.com&head=Dr. Priyanka Vyas&heademail=sshckalol@gmail.com">
                          <h4 className="text-center">
                            B.H.M.S. (Homoeopathy)
                          </h4>
                        </a>
                      </li>
                    </ul>
                    <ul style={{ listStyle: "none" }}>
                      <h5 className="text-danger mt-4">Details</h5>
                      <li className="crs">
                        <p>Course Type : UG</p>
                      </li>
                      <li className="crs">
                        <p>Duration of Course : 5.5 years</p>
                      </li>
                      <li className="crs">
                        <p>Course Intake : 100</p>
                      </li>
                      <li className="crs">
                        <p>Fees : G.Q -1,15,000/- M.Q.-1,55,000/-</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* law Modal */}
        <div
          className="modal fade"
          id="law"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel" />
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <div className="d-flex align-items-center">
                  <img
                    src="assets/images/icon/law.png"
                    className="img-fluid modal_icon"
                    alt="engineering icon"
                  />
                  {/* <h2>Law</h2> */}
                  <Link to={"/law"}>
                    <h2>Law</h2>
                  </Link>
                </div>
                <div className="row mt-5">
                  <div className="col-md-6">
                    <ul>
                      <li className="crs">
                        <a href="course_details.php?course_name=LLB  &course=UG &intake=120&duration=3 years&fees=25,000&contact=%2B91-9427483055&adcontact=%2B91-9726117000&ademail=sslckalol@gmail.com&head=Dr. Dixit Patel &heademail=dr_mahesh@gmail.com ">
                          <h5>Law</h5>
                        </a>
                      </li>
                    </ul>
                    <a href="course_details.php?course_name=LLB  &course=UG &intake=120&duration=3 years&fees=25,000&contact=%2B91-9427483055&adcontact=%2B91-9726117000&ademail=sslckalol@gmail.com&head=Dr. Dixit Patel &heademail=dr_mahesh@gmail.com "></a>
                    <ul style={{ listStyle: "none" }}>
                      <a href="course_details.php?course_name=LLB  &course=UG &intake=120&duration=3 years&fees=25,000&contact=%2B91-9427483055&adcontact=%2B91-9726117000&ademail=sslckalol@gmail.com&head=Dr. Dixit Patel &heademail=dr_mahesh@gmail.com ">
                        <h5 className="text-danger mt-4">Details</h5>
                        <li className="crs">
                          <p>Course Type : UG</p>
                        </li>
                        <li className="crs">
                          <p>Duration of Course : 3 years</p>
                        </li>
                        <li className="crs">
                          <p>Course Intake : 120</p>
                        </li>
                        <li className="crs">
                          <p>Fees : 25000</p>
                        </li>
                      </a>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Arts Modal */}
        <div
          className="modal fade"
          id="arts"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel" />
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <div className="d-flex align-items-center">
                  <img
                    src="assets/images/icon/arts.png"
                    className="img-fluid modal_icon"
                    alt="engineering icon"
                  />
                  {/* <h2>Arts</h2> */}
                  <Link to={"/arts"}>
                    <h2>Arts</h2>
                  </Link>
                </div>
                <div className="row mt-5">
                  <div className="col-md-6">
                    <ul>
                      <li className="crs">
                        <a href="course_details.php?course_name= BA (Arts)  &course=UG &intake=120&duration=3 years&fees=12,000&contact=%2B91-9879488309 / %2B91-9824804530&adcontact=%2B91-9879488309 / %2B91-9824804530&ademail=commerce.swaminarayanuni@gmail.com&head=Mr. Dhruv S. Patel&heademail=commerce.swaminarayanuni@gmail.com">
                          <h5>BA (Arts)</h5>
                        </a>
                      </li>
                    </ul>
                    <a href="course_details.php?course_name= BA (Arts)  &course=UG &intake=120&duration=3 years&fees=12,000&contact=%2B91-9879488309 / %2B91-9824804530&adcontact=%2B91-9879488309 / %2B91-9824804530&ademail=commerce.swaminarayanuni@gmail.com&head=Mr. Dhruv S. Patel&heademail=commerce.swaminarayanuni@gmail.com"></a>
                    <ul style={{ listStyle: "none" }}>
                      <a href="course_details.php?course_name= BA (Arts)  &course=UG &intake=120&duration=3 years&fees=12,000&contact=%2B91-9879488309 / %2B91-9824804530&adcontact=%2B91-9879488309 / %2B91-9824804530&ademail=commerce.swaminarayanuni@gmail.com&head=Mr. Dhruv S. Patel&heademail=commerce.swaminarayanuni@gmail.com">
                        <h5 className="text-danger mt-4">Details</h5>
                        <li className="crs">
                          <p>Course Type : UG</p>
                        </li>
                        <li className="crs">
                          <p>Duration of Course : 3 years</p>
                        </li>
                        <li className="crs">
                          <p>Course Intake : 120</p>
                        </li>
                        <li className="crs">
                          <p>Fees : 15,000</p>
                        </li>
                      </a>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* IT Modal */}
        <div
          className="modal fade"
          id="it"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel" />
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <div className="d-flex align-items-center">
                  <img
                    src="assets/images/icon/technology.png"
                    className="img-fluid modal_icon"
                    alt="engineering icon"
                  />
                  {/* <h2>IT &amp; Computer</h2> */}
                  <Link to={"/it-cs"}>
                    <h2>IT &amp; Computer</h2>
                  </Link>
                </div>
                <div className="row mt-5">
                  <div className="col-md-6">
                    <ul>
                      <li className="crs">
                        <a href="course_details.php?course_name= BCA  &course=UG &intake=120&duration=3 years&fees=30,000&contact=%2B91-9879488309 / %2B91-9824804530&adcontact=%2B91-9879488309 / %2B91-9824804530&ademail=commerce.swaminarayanuni@gmail.com&head=Mr. Dhruv S. Patel&heademail=commerce.swaminarayanuni@gmail.com">
                          <h5>BCA (IT &amp; Computer)</h5>
                        </a>
                      </li>
                    </ul>
                    <a href="course_details.php?course_name= BCA  &course=UG &intake=120&duration=3 years&fees=30,000&contact=%2B91-9879488309 / %2B91-9824804530&adcontact=%2B91-9879488309 / %2B91-9824804530&ademail=commerce.swaminarayanuni@gmail.com&head=Mr. Dhruv S. Patel&heademail=commerce.swaminarayanuni@gmail.com"></a>
                    <ul style={{ listStyle: "none" }}>
                      <a href="course_details.php?course_name= BCA  &course=UG &intake=120&duration=3 years&fees=30,000&contact=%2B91-9879488309 / %2B91-9824804530&adcontact=%2B91-9879488309 / %2B91-9824804530&ademail=commerce.swaminarayanuni@gmail.com&head=Mr. Dhruv S. Patel&heademail=commerce.swaminarayanuni@gmail.com">
                        <h5 className="text-danger mt-4">Details</h5>
                        <li className="crs">
                          <p>Course Type : UG</p>
                        </li>
                        <li className="crs">
                          <p>Duration of Course : 3 years</p>
                        </li>
                        <li className="crs">
                          <p>Course Intake : 120</p>
                        </li>
                        <li className="crs">
                          <p>Fees : 25,000</p>
                        </li>
                      </a>
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <ul>
                      <li className="crs">
                        <a href="course_details.php?course_name= BCA  &course=UG &intake=120&duration=3 years&fees=30,000&contact=%2B91-9879488309 / %2B91-9824804530&adcontact=%2B91-9879488309 / %2B91-9824804530&ademail=commerce.swaminarayanuni@gmail.com&head=Mr. Dhruv S. Patel&heademail=commerce.swaminarayanuni@gmail.com">
                          <h5>B.Sc.</h5>
                        </a>
                      </li>
                    </ul>
                    <a href="course_details.php?course_name= BCA  &course=UG &intake=120&duration=3 years&fees=30,000&contact=%2B91-9879488309 / %2B91-9824804530&adcontact=%2B91-9879488309 / %2B91-9824804530&ademail=commerce.swaminarayanuni@gmail.com&head=Mr. Dhruv S. Patel&heademail=commerce.swaminarayanuni@gmail.com"></a>
                    <ul style={{ listStyle: "none" }}>
                      <a href="course_details.php?course_name= BCA  &course=UG &intake=120&duration=3 years&fees=30,000&contact=%2B91-9879488309 / %2B91-9824804530&adcontact=%2B91-9879488309 / %2B91-9824804530&ademail=commerce.swaminarayanuni@gmail.com&head=Mr. Dhruv S. Patel&heademail=commerce.swaminarayanuni@gmail.com">
                        <h5 className="text-danger mt-4">Details</h5>
                        <li className="crs">
                          <p>Course Type : UG</p>
                        </li>
                        <li className="crs">
                          <p>Duration of Course : 3 years</p>
                        </li>
                        <li className="crs">
                          <p>Course Intake : 120</p>
                        </li>
                        <li className="crs">
                          <p>Fees : 25,000</p>
                        </li>
                      </a>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="description">
          <div className="container-fluid">
            <div className="register" style={{ maxWidth: "90%" }}>
              <h3>Graduate Programme 2023-24</h3>
              <div className="row d-flex justify-content-center align-items-center">
                <div className="col-md-3">
                  <div className="card mb-3">
                    <div className="d-flex flex-row-reverse card-body p-2 align-items-center  ">
                      <div className="p-2 w-100 ">
                        {/* <h4>Engineering</h4> */}
                        <Link to={"/engineering"}>
                          <h4>Engineering</h4>
                        </Link>
                      </div>
                      <div className="p-2 flex-shrink-1 ">
                        {" "}
                        <img
                          src="assets/images/icon/engineering.png"
                          className="img-fluid icon "
                          alt="engineering icon"
                        />
                      </div>
                    </div>
                    <hr />
                    <div className="d-flex justify-content-around">
                      <p className="card-text">
                        <small className="text-muted ">6 Programmes </small>
                      </p>
                      <div className=" d-flex justify-content-center align-item-center ">
                        <a
                          href=""
                          data-bs-toggle="modal"
                          data-bs-target="#engineering"
                        >
                          {" "}
                          <b>
                            More Info <i className="ri-arrow-right-line" />{" "}
                          </b>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card mb-3">
                    <div className="d-flex flex-row-reverse card-body p-2 align-items-center  ">
                      <div className="p-2 w-100 ">
                        {/* <h4>Nursing</h4> */}
                        <Link to={"/nursing"}>
                          <h4>Nursing</h4>
                        </Link>
                      </div>
                      <div className="p-2 flex-shrink-1 ">
                        {" "}
                        <img
                          src="assets/images/icon/nurse.png"
                          className="img-fluid icon "
                          alt=""
                        />
                      </div>
                    </div>
                    <hr />
                    <div className="d-flex justify-content-around">
                      <p className="card-text">
                        <small className="text-muted ">2 Programmes </small>
                      </p>
                      <div className=" d-flex justify-content-center align-item-center ">
                        <a
                          href=""
                          data-bs-toggle="modal"
                          data-bs-target="#nursing"
                        >
                          {" "}
                          <b>
                            More Info <i className="ri-arrow-right-line" />{" "}
                          </b>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card mb-3">
                    <div className="d-flex flex-row-reverse card-body p-2 align-items-center  ">
                      <div className="p-2 w-100 ">
                        {/* <h4>Basic Science</h4> */}
                        <Link to={"/science"}>
                          <h4>Basic Science</h4>
                        </Link>
                      </div>
                      <div className="p-2 flex-shrink-1 ">
                        {" "}
                        <img
                          src="assets/images/icon/science.png"
                          className="img-fluid icon "
                          alt="science"
                        />
                      </div>
                    </div>
                    <hr />
                    <div className="d-flex justify-content-around">
                      <p className="card-text">
                        <small className="text-muted ">7 Programmes </small>
                      </p>
                      <div className=" d-flex justify-content-center align-item-center ">
                        <a href="" data-bs-toggle="modal" data-bs-target="#bs">
                          {" "}
                          <b>
                            More Info <i className="ri-arrow-right-line" />{" "}
                          </b>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card mb-3">
                    <div className="d-flex flex-row-reverse card-body p-2 align-items-center  ">
                      <div className="p-2 w-100 ">
                        {/* <h4>Commerce and Management</h4> */}
                        <Link to={"/commerce"}>
                          <h4>Commerce and Management</h4>
                        </Link>
                      </div>
                      <div className="p-2 flex-shrink-1 ">
                        {" "}
                        <img
                          src="assets/images/icon/management.png"
                          className="img-fluid icon "
                          alt="managment"
                        />
                      </div>
                    </div>
                    <hr />
                    <div className="d-flex justify-content-around">
                      <p className="card-text">
                        <small className="text-muted ">2 Programmes </small>
                      </p>
                      <div className=" d-flex justify-content-center align-item-center ">
                        <a
                          href=""
                          data-bs-toggle="modal"
                          data-bs-target="#management"
                        >
                          {" "}
                          <b>
                            More Info <i className="ri-arrow-right-line" />{" "}
                          </b>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card mb-3">
                    <div className="d-flex flex-row-reverse card-body p-2 align-items-center  ">
                      <div className="p-2 w-100 ">
                        {/* <h4>MBBS</h4> */}
                        <Link to={"/sims"}>
                          <h4>MBBS</h4>
                        </Link>
                      </div>
                      <div className="p-2 flex-shrink-1 ">
                        {" "}
                        <img
                          src="assets/images/icon/doctor.png"
                          className="img-fluid icon "
                          alt="doctor"
                        />
                      </div>
                    </div>
                    <hr />
                    <div className="d-flex justify-content-around">
                      <p className="card-text">
                        <small className="text-muted ">1 Programme </small>
                      </p>
                      <div className=" d-flex justify-content-center align-item-center ">
                        <a
                          href=""
                          data-bs-toggle="modal"
                          data-bs-target="#medical"
                        >
                          {" "}
                          <b>
                            More Info <i className="ri-arrow-right-line" />{" "}
                          </b>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card mb-3">
                    <div className="d-flex flex-row-reverse card-body p-2 align-items-center  ">
                      <div className="p-2 w-100 ">
                        {/* <h4>Ayurveda</h4> */}
                        <Link to={"/ayurveda"}>
                          <h4>Ayurveda</h4>
                        </Link>
                      </div>
                      <div className="p-2 flex-shrink-1 ">
                        {" "}
                        <img
                          src="assets/images/icon/ayurveda.png"
                          className="img-fluid icon "
                          alt="doctor"
                        />
                      </div>
                    </div>
                    <hr />
                    <div className="d-flex justify-content-around">
                      <p className="card-text">
                        <small className="text-muted ">1 Programme </small>
                      </p>
                      <div className=" d-flex justify-content-center align-item-center ">
                        <a
                          href=""
                          data-bs-toggle="modal"
                          data-bs-target="#ayurveda"
                        >
                          {" "}
                          <b>
                            More Info <i className="ri-arrow-right-line" />{" "}
                          </b>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card mb-3">
                    <div className="d-flex flex-row-reverse card-body p-2 align-items-center  ">
                      <div className="p-2 w-100 ">
                        {/* <h4>Homeopathy</h4> */}
                        <Link to={"/homoeopathy"}>
                          <h4>Homoeopathy</h4>
                        </Link>
                      </div>
                      <div className="p-2 flex-shrink-1 ">
                        {" "}
                        <img
                          src="assets/images/icon/homeopathy.png"
                          className="img-fluid icon "
                          alt="doctor"
                        />
                      </div>
                    </div>
                    <hr />
                    <div className="d-flex justify-content-around">
                      <p className="card-text">
                        <small className="text-muted ">1 Programme </small>
                      </p>
                      <div className=" d-flex justify-content-center align-item-center ">
                        <a
                          href=""
                          data-bs-toggle="modal"
                          data-bs-target="#homeopathy"
                        >
                          {" "}
                          <b>
                            More Info <i className="ri-arrow-right-line" />{" "}
                          </b>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card mb-3">
                    <div className="d-flex flex-row-reverse card-body p-2 align-items-center  ">
                      <div className="p-2 w-100 ">
                        {/* <h4>Pharmacy</h4> */}
                        <Link to={"/pharmacy"}>
                          <h4>Pharmacy</h4>
                        </Link>
                      </div>
                      <div className="p-2 flex-shrink-1 ">
                        {" "}
                        <img
                          src="assets/images/icon/pharmacy.png"
                          className="img-fluid icon "
                          alt="pharmacy"
                        />
                      </div>
                    </div>
                    <hr />
                    <div className="d-flex justify-content-around">
                      <p className="card-text">
                        <small className="text-muted ">1 Programme </small>
                      </p>
                      <div className=" d-flex justify-content-center align-item-center ">
                        <a
                          href=""
                          data-bs-toggle="modal"
                          data-bs-target="#pharmacy"
                        >
                          {" "}
                          <b>
                            More Info <i className="ri-arrow-right-line" />{" "}
                          </b>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card mb-3">
                    <div className="d-flex flex-row-reverse card-body p-2 align-items-center  ">
                      <div className="p-2 w-100 ">
                        {/* <h4>Law</h4> */}
                        <Link to={"/law"}>
                          <h4>Law</h4>
                        </Link>
                      </div>
                      <div className="p-2 flex-shrink-1 ">
                        {" "}
                        <img
                          src="assets/images/icon/law.png"
                          className="img-fluid icon "
                          alt="law"
                        />
                      </div>
                    </div>
                    <hr />
                    <div className="d-flex justify-content-around">
                      <p className="card-text">
                        <small className="text-muted ">1 Programme </small>
                      </p>
                      <div className=" d-flex justify-content-center align-item-center ">
                        <a href="" data-bs-toggle="modal" data-bs-target="#law">
                          {" "}
                          <b>
                            More Info <i className="ri-arrow-right-line" />{" "}
                          </b>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card mb-3">
                    <div className="d-flex flex-row-reverse card-body p-2 align-items-center  ">
                      <div className="p-2 w-100 ">
                        {/* <h4>Arts </h4> */}
                        <Link to={"/arts"}>
                          <h4>Arts</h4>
                        </Link>
                      </div>
                      <div className="p-2 flex-shrink-1 ">
                        {" "}
                        <img
                          src="assets/images/icon/arts.png"
                          className="img-fluid icon "
                          alt="arts"
                        />
                      </div>
                    </div>
                    <hr />
                    <div className="d-flex justify-content-around">
                      <p className="card-text">
                        <small className="text-muted ">1 Programme </small>
                      </p>
                      <div className=" d-flex justify-content-center align-item-center ">
                        <a
                          href=""
                          data-bs-toggle="modal"
                          data-bs-target="#arts"
                        >
                          {" "}
                          <b>
                            More Info <i className="ri-arrow-right-line" />{" "}
                          </b>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card mb-3">
                    <div className="d-flex flex-row-reverse card-body p-2 align-items-center  ">
                      <div className="p-2 w-100 ">
                        {/* <h4>IT </h4> */}
                        <Link to={"/it-cs"}>
                          <h4>IT</h4>
                        </Link>
                      </div>
                      <div className="p-2 flex-shrink-1 ">
                        {" "}
                        <img
                          src="assets/images/icon/technology.png"
                          className="img-fluid icon "
                          alt="technology"
                        />
                      </div>
                    </div>
                    <hr />
                    <div className="d-flex justify-content-around">
                      <p className="card-text">
                        <small className="text-muted ">2 Programmes </small>
                      </p>
                      <div className=" d-flex justify-content-center align-item-center ">
                        <a href="" data-bs-toggle="modal" data-bs-target="#it">
                          {" "}
                          <b>
                            More Info <i className="ri-arrow-right-line" />{" "}
                          </b>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card mb-3">
                    <div className="d-flex flex-row-reverse card-body p-2 align-items-center  ">
                      <div className="p-2 w-100 ">
                        <Link to={"/education"}>
                          <h4>Education</h4>
                        </Link>
                      </div>
                      <div className="p-2 flex-shrink-1 ">
                        {" "}
                        <img
                          src="assets/images/icon/education.png"
                          className="img-fluid icon "
                          alt="educa tion"
                        />
                      </div>
                    </div>
                    <hr />
                    <div className="d-flex justify-content-around">
                      <p className="card-text">
                        <small className="text-muted ">1 Programme </small>
                      </p>
                      <div className=" d-flex justify-content-center align-item-center ">
                        <a
                          href=""
                          data-bs-toggle="modal"
                          data-bs-target="#education"
                        >
                          {" "}
                          <b>
                            More Info <i className="ri-arrow-right-line" />{" "}
                          </b>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card mb-3">
                    <div className="d-flex flex-row-reverse card-body p-2 align-items-center  ">
                      <div className="p-2 w-100 ">
                        {/* <h4>Physiotherapy </h4> */}
                        <Link to={"/Physiotherapy"}>
                          <h4>Physiotherapy</h4>
                        </Link>
                      </div>
                      <div className="p-2 flex-shrink-1 ">
                        {" "}
                        <img
                          src="assets/images/icon/physiotherapy.png"
                          className="img-fluid icon "
                          alt="educa tion"
                        />
                      </div>
                    </div>
                    <hr />
                    <div className="d-flex justify-content-around">
                      <p className="card-text">
                        <small className="text-muted ">1 Programme </small>
                      </p>
                      <div className=" d-flex justify-content-center align-item-center ">
                        <a
                          href=""
                          data-bs-toggle="modal"
                          data-bs-target="#physiotherapy"
                        >
                          {" "}
                          <b>
                            More Info <i className="ri-arrow-right-line" />{" "}
                          </b>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

export default Graduate;
