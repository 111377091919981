import React from 'react'

function Chart() {
  return (
    <div className='Chart'>
        <>
  <h1 className="text-center mt-5">
    SWAMINARAYAN UNIVERSITY ORGANIZATIONAL CHART
  </h1>
  <div className="header">
    {/* The header content */}
    <div className="container h-100">
      <div className="d-flex h-100 text-center align-items-center">
        <div className="w-100 text-white">
          <img src="assets/images/orgChart-01.jpg" alt="Organization" />
        </div>
      </div>
    </div>
  </div>
</>

    </div>
  )
}

export default Chart