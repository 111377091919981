import React from 'react'
import { Link } from 'react-router-dom'

function After12th() {
  return (
    <div className='After12th '>
<div class="page-banner-area bg-2">
<div class="container">
<div class="page-banner-content">
<h1>After 12<sup>th</sup></h1>
<ul>
<li> <Link to={'./../'}>  Home </Link></li>


<li>After 12 <sup>th</sup></li>
</ul>
</div>
</div>
</div>
<div className="container mt-5"><div className="row"><div className="col-md-9">
<div className="register" style={{maxWidth:'1120px'}} >
<div className="container-fluid">
   <div className="row">
    <h3>After 12 <sup>th</sup></h3>
    <br/>
   <h2 className='text-center text-light' style={{backgroundColor: "#FC7F03"}}>MBBS </h2>
    <table className="table table-responsive table-bordered text-center">
        <thead>
          
            <tr>
                <th>Name of Program</th>
              
                <th className='text-center'>Eligibility </th>
                <th className='text-center'>Intake </th>
            </tr>
        </thead>
        <tbody >
            <tr>
                <td>MBBS</td>
            
                <td className='text-center'>The candidate shall have cleared 12th from Science Stream with PCB Subjects with  below requirements : 
General: PCB - Avg 50% and Overall 50% + NEET 50 PR 
Reserved: PCB - Avg 40% and Overall 40% + NEET 40 PR 
Shall have cleared 12th from recognized board
</td>
                <td className='text-center'>150</td>
            </tr>

           
        </tbody>
    </table>
    
    <br/>
    <h2 className='text-center text-light' style={{backgroundColor: "#FC7F03"}}>Ayurveda </h2>
    <table className="table table-responsive table-bordered text-center">
        <thead>
            
            <tr>
                <th>Name of Program</th>
              
                <th className='text-center'>Eligibility </th>
                <th className='text-center'>Intake </th>
            </tr>
        </thead>
        <tbody >
            <tr>
                <td>BAMS</td>
            
                <td className='text-center'>The candidate shall have cleared 12th from Science Stream with PCB Subjects with  below requirements : 
General: PCB - Avg 50% and Overall 50% + NEET 50 PR 
Reserved: PCB - Avg 40% and Overall 40% + NEET 40 PR 
Shall have cleared 12th from recognized board


</td>
                <td className='text-center'>100</td>
            </tr>

           
        </tbody>
    </table>
    
    <br/>
    {/* <h5 className='bg-danger text-light'>Recognised State/Central Board </h5> */}
    <h2 className='text-center text-light' style={{backgroundColor: "#FC7F03"}}>Homoeopathy </h2>
    <table className="table table-responsive table-bordered text-center">
        <thead>
            
            <tr>
                <th>Name of Program</th>
              
                <th className='text-center'>Eligibility </th>
                <th className='text-center'>Intake </th>
            </tr>
        </thead>
        <tbody >
            <tr>
                <td>BHMS </td>
            
                <td className='text-center'>The candidate shall have cleared 12th from Science Stream with PCB Subjects with  below requirements : 
General: PCB - Avg 50% and Overall 50% + NEET 50 PR 
Reserved: PCB - Avg 40% and Overall 40% + NEET 40 PR 
Shall have cleared 12th from recognized board



</td>
                <td className='text-center'>100</td>
            </tr>

           
        </tbody>
    </table>
    <br/>
    <h2 className='text-center text-light' style={{backgroundColor: "#FC7F03"}}>NURSING </h2>
    <table className="table table-responsive table-bordered text-center">
        <thead>
            
            <tr>
                <th>Name of Program</th>
              
                <th className='text-center'>Eligibility </th>
                <th className='text-center'>Intake </th>
            </tr>
        </thead>
        <tbody >
            <tr>
                <td>B.sc Nursing </td>
            
                <td className='text-center'>The candidate shall have passed 12th with  Physics, Chemistry and Biology as  
Compulsory subjects along with English  subject with aggregate  
(40% for reserved category students and 45%  marks for General Category Students) from  recognized State/Central Board

</td>
                <td className='text-center'>100</td>
            </tr>
            <tr>
                <td>GNM</td>
            
                <td className='text-center'>The candidate shall have passed 12th with  English and must have obtained a minimum  of 40% for General Category students and  35% for reserved category students from any  recognized Board.  
                <br/>OR  <br/>
12TH with English having 40% of marks in  vocational ANM Course from the school  recognized by Indian Nursing Council



</td>
                <td className='text-center'>100</td>
            </tr>
            <tr>
                <td>ANM</td>
            
                <td className='text-center'>The Candidate shall have passed 12th with  English subject. Course Is offered to Girls  Only

</td>
                <td className='text-center'>20</td>
            </tr>
            {/* <tr>
                <td>BPT</td>
            
                <td className='text-center'>The candidate shall have cleared 12th from Science Stream with PCB Subjects with below  requirements : 
General: PCB - Avg 50% and Overall 50% + NEET 50 PR 
Reserved: PCB - Avg 40% and Overall 40% + NEET 40 PR 
Shall have cleared 12th from recognized board



</td>
                <td className='text-center'>100</td>
            </tr> */}


           
        </tbody>
    </table>

    <br/>
    <h2 className='text-center text-light' style={{backgroundColor: "#FC7F03"}}>PHYSIOTHERAPY </h2>
    <table className="table table-responsive table-bordered text-center">
        <thead>
            
            <tr>
                <th>Name of Program</th>
              
                <th className='text-center'>Eligibility </th>
                <th className='text-center'>Intake </th>
            </tr>
        </thead>
        <tbody >
            
            <tr>
                <td>BPT</td>
            
                <td className='text-center'>Shall have passed the higher secondary examination with science subjects Physics, Chemistry 
                and Biology conducted by the Gujarat higher secondary education board and examination considered as equivalent by 
                the Gujarat government and admission committee. the student shall fulfil the criteria given by the Gujarat State 
                Council for Physiotherapy.



</td>
                <td className='text-center'>50</td>
            </tr>


           
        </tbody>
    </table>

    <br/>
    <h2 className='text-center text-light' style={{backgroundColor: "#FC7F03"}}>Engineering  </h2>
    <table className="table table-responsive table-bordered text-center">
        <thead>
            
            <tr>
                <th>Name of Program</th>
              <th>Department</th>
                <th className='text-center'>Eligibility </th>
                <th className='text-center'>Intake </th>
            </tr>
        </thead>
        <tbody >
            <tr>
                <td>B.Tech. (Bachelor of Technology) </td>
            <td>Automobile </td>
                <td className='text-center'>10 + 2 from recognized Board with  Minimum 45% in PCM
</td>
                <td className='text-center'>30</td>
            </tr>
           
            <tr>
                <td>B.Tech. (Bachelor of Technology) </td>
            <td>Mechanical  </td>
                <td className='text-center'>10 + 2 from recognized Board with  Minimum 45% in PCM
</td>
                <td className='text-center'>60</td>
            </tr>
            <tr>
                <td>Electrical 
Engineering
 </td>
            <td>Automobile </td>
                <td className='text-center'>10 + 2 from recognized Board with  Minimum 45% in PCM
</td>
                <td className='text-center'>60</td>
            </tr>
           
            <tr>
                <td>B.Tech. (Bachelor of Technology) </td>
            <td>Automobile </td>
                <td className='text-center'>10 + 2 from recognized Board with  Minimum 45% in PCM
</td>
                <td className='text-center'>30</td>
            </tr>
            
            <tr>
                <td>B.Tech. (Bachelor of Technology) </td>
            <td>Computer  
Engineering
 </td>
                <td className='text-center'>10 + 2 from recognized Board with  Minimum 45% in PCM
</td>
                <td className='text-center'>120</td>
            </tr>

            <tr>
                <td>B.Tech. (Bachelor of Technology) </td>
            <td>Information  
Technology
 </td>
                <td className='text-center'>10 + 2 from recognized Board with  Minimum 45% in PCM
</td>
                <td className='text-center'>60</td>
            </tr>


            <tr>
                <td>B.Tech. (Bachelor of Technology) </td>
            <td>Civil Engineering  </td>
                <td className='text-center'>10 + 2 from recognized Board with  Minimum 45% in PCM
</td>
                <td className='text-center'>30</td>
            </tr>   

        </tbody>
    </table>
    
    <br/>
    <h2 className='text-center text-light' style={{backgroundColor: "#FC7F03"}}>IT and Computer Science  </h2>
    <table className="table table-responsive table-bordered text-center">
        <thead>
            
            <tr>
                <th>Name of Program</th>
             
                <th className='text-center'>Eligibility </th>
                <th className='text-center'>Intake </th>
            </tr>
        </thead>
        <tbody >
            <tr>
                <td>BCA  </td>
           
                <td className='text-center'>10 + 2 from recognized Board 
</td>
                <td className='text-center'>180</td>
            </tr>
            <tr>
                <td>B.Sc. Information Technology   </td>
           
                <td className='text-center'>10 + 2 from recognized Board 
</td>
                <td className='text-center'>60</td>
            </tr>
           
           
        
        </tbody>
    </table>
    <br/>
    <h2 className='text-center text-light' style={{backgroundColor: "#FC7F03"}}>Science  </h2>
    <table className="table table-responsive table-bordered text-center">
        <thead>
            
            <tr>
                <th>Name of Program</th>
                <th>Department</th>
             
                <th className='text-center'>Eligibility </th>
                <th>Admission</th>
                <th className='text-center'>Intake </th>
            </tr>
        </thead>
        <tbody >
            <tr>
                <td>B.Sc.  </td>
                <td>Chemistry </td>
           
                <td className='text-center'>10+2 with PCB/PCM from  recognized Board  
</td>
<td>As per UGC </td>
                <td className='text-center'>120</td>
            </tr>
            <tr>
                <td>B.Sc.  </td>
                <td>Microbiology  </td>
           
                <td className='text-center'>10+2 with PCB/PCM from  recognized Board   
</td>
<td>As per UGC </td>
                <td className='text-center'>60</td>
            </tr>

            <tr>
                <td>B.Sc.  </td>
                <td>Botany  </td>
           
                <td className='text-center'>10+2 with PCB/PCM from  recognized Board   
</td>
<td>As per UGC </td>
                <td className='text-center'>60</td>
            </tr>

            <tr>
                <td>B.Sc.  </td>
                <td>Zoology  </td>
           
                <td className='text-center'>10+2 with PCB/PCM from  recognized Board   
</td>
<td>As per UGC </td>
                <td className='text-center'>120</td>
            </tr>

            <tr>
                <td>B.Sc.  </td>
                <td>Mathematics  </td>
           
                <td className='text-center'>10+2 with PCB/PCM from  recognized Board 
</td>
<td>As per UGC </td>
                <td className='text-center'>60</td>
            </tr>

            <tr>
                <td>B.Sc.  </td>
                <td>Physics  </td>
           
                <td className='text-center'>10+2 with PCB/PCM from  recognized Board 
</td>
<td>As per UGC </td>
                <td className='text-center'>30</td>
            </tr>

           
           
           
           
        
        </tbody>
    </table>

    <br/>
    <h2 className='text-center text-light' style={{backgroundColor: "#FC7F03"}}>Pharmacy </h2>
    <table className="table table-responsive table-bordered text-center">
        <thead>
            
            <tr>
                <th>Name of Program</th>
             
                <th className='text-center'>Eligibility </th>
                <th className='text-center'>Intake </th>
            </tr>
        </thead>
        <tbody >
            <tr>
                <td>Pharm.D   </td>
           
                <td className='text-center'>10+2 with PCB/PCM from recognized Board
</td>
                <td className='text-center'>30</td>
            </tr>
            <tr>
                <td>B.Pharm  </td>
           
                <td className='text-center'>10+2 with PCB/PCM from recognized Board
</td>
                <td className='text-center'>60</td>
            </tr>
           
           
        
        </tbody>
    </table>

    <br/>
    <h2 className='text-center text-light' style={{backgroundColor: "#FC7F03"}}>Commerce and Management  </h2>
    <table className="table table-responsive table-bordered text-center">
        <thead>
            
            <tr>
                <th>Name of Program</th>
             
                <th className='text-center'>Eligibility </th>
                <th className='text-center'>Intake </th>
            </tr>
        </thead>
        <tbody >
            <tr>
                <td>BBA (H)  </td>
           
                <td className='text-center'>10 + 2 from recognized Board 


                </td>
                <td className='text-center'>120</td>
            </tr>
            <tr>
                <td>B.com  (H)  </td>
           
                <td className='text-center'>10 + 2 from recognized Board 


                </td>
                <td className='text-center'>120</td>
            </tr>
           
           
        
        </tbody>
    </table>
    <br/>
    <h2 className='text-center text-light' style={{backgroundColor: "#FC7F03"}}>PARAMEDICAL COURSES


</h2>
    <table className="table table-responsive table-bordered text-center">
        <thead>
            
            <tr>
                <th>Name of Program</th>
             
                <th className='text-center'>Course type </th>
                <th className='text-center'>Intake </th>
                <th>Duration</th>
                <th>Proposed Fees Yearly</th>
            </tr>
        </thead>
        <tbody >
            <tr>
                <td>MLT   </td>
           
                <td className='text-center'>Certificate</td>
                <td>50</td>
                <td>1 Year</td>
                <td>20000</td>
           
            </tr>
            <tr>
                <td>Sanitary Inspector   </td>
           
                <td className='text-center'>Certificate</td>
                <td>50</td>
                <td>1 Year</td>
                <td>20000</td>
           
            </tr>
            <tr>
                <td>Dialysis Technician </td>
           
                <td className='text-center'>Certificate</td>
                <td>50</td>
                <td>1 Year</td>
                <td>20000</td>
           
            </tr>
            <tr>
                <td>Infection Control Nurse  </td>
           
                <td className='text-center'>Certificate</td>
                <td>50</td>
                <td>3.6 Year</td>
                <td>20000</td>
           
            </tr>
            <tr>
                <td>Operation theater Technician  </td>
           
                <td className='text-center'>Certificate</td>
                <td>50</td>
                <td>1 Year</td>
                <td>30000</td>
           
            </tr>
            <tr>
                <td>X-Ray Technician  </td>
           
                <td className='text-center'>Certificate</td>
                <td>50</td>
                <td>1 Year</td>
                <td>30000</td>
           
            </tr>
            <tr>
                <td>CSSD Technician </td>
           
                <td className='text-center'>Certificate</td>
                <td>50</td>
                <td>9 Months</td>
                <td>20000</td>
           
            </tr>
            <tr>
                <td>BLS  </td>
           
                <td className='text-center'>Certificate</td>
                <td>50</td>
                <td>1 day</td>
                <td>1000</td>
           
            </tr>
            <tr>
                <td>ACLS   </td>
           
                <td className='text-center'>Certificate</td>
                <td>50</td>
                <td>2 days</td>
                <td>3500</td>
           
            </tr>
           
           
           
        
        </tbody>
    </table>


   </div>

</div>
</div></div>


<div class="col-lg-3">
<div class="additional-information">
<h3>What ?? </h3>
<div class="list">
<ul>
<li><Link to={'/after10th'} className="nav-link">After 10<sup>th</sup> </Link></li>
<li><Link to={'/after12th'} className="nav-link">
                        AFTER 12<sup>th</sup>
                        </Link></li>
<li><Link to={'/afterUG'} className="nav-link">
                        AFTER UG
                        </Link></li>
<li><Link to={'/afterPG'} className="nav-link">
                        AFTER PG 
                        </Link></li>

</ul>
</div>
</div>
</div>
</div></div>


    </div>
  )
}

export default After12th