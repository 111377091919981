import React from "react";

function AboutCollege() {
  return (
    <div>
      <>
        {/* Start For Content */}
        <div className="container mt-5 mb-5">
          <div className="row">
            <div className="col-md-4 mt-4">
              <img
                // src="../assets/nursing/images/nursingCollege.jpeg"
                src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/media/undefined_1713259032601.jpeg"
                alt=""
                className="img-responsive img-thumbnail"
                width={500}
                height={600}
              />
              <br />
              <br />
            </div>
            <div className="col-md-8">
              <h2>
                About College
                <br />
              </h2>
              <p style={{ textAlign: "justify" }}>
                Shree Swaminarayan College of Nursing is a prestigious
                educational institution dedicated to providing high-quality
                education and training in various academic disciplines related
                to nursing and healthcare. This faculty places a strong emphasis
                on cultivating academic excellence and research in subjects such
                as Nursing, Healthcare Management, Medical Literature, History
                of Nursing, Sociology in Healthcare, and other related fields.
                Through its comprehensive academic programs, this faculty offers
                a wide range of educational opportunities for students, spanning
                from undergraduate programs to postgraduate studies, and even
                opportunities for advanced research through Ph.D. programs. The
                institution's holistic approach to education ensures that
                students not only gain theoretical knowledge but also develop
                practical skills, enabling them to contribute significantly to
                the healthcare and nursing communities and society at large.
                Shree Swaminarayan Faculty of Nursing is committed to nurturing
                a deep understanding of the world of nursing, healthcare, and
                promoting scholarly inquiry. This commitment reflects the
                faculty's dedication to producing well-rounded and capable
                graduates who can address complex challenges and make meaningful
                advancements in their chosen nursing and healthcare fields.
              </p>
            </div>
          </div>
          <br />
          <br />
        </div>{" "}
        {/* End For Content */}
      </>
    </div>
  );
}

export default AboutCollege;
