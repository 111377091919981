import React from "react";

export default function Courses() {
  return (
    <div>
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-md-12">
            <h2 className="mb-5">
              <strong>About the Courses</strong>
            </h2>

            <div>
              <p>
                Pharmacy is a field of healthcare that involves the preparation,
                dispensing, and monitoring of medications to ensure their safe
                and effective use. Pharmacy courses are designed to provide
                students with the knowledge and skills necessary to become
                licensed pharmacists or work in related healthcare professions.
                Pharmacy courses typically cover a range of topics, including
                pharmacology, pharmaceutical chemistry, pharmacy law and ethics,
                drug delivery systems, patient counselling, and medication
                therapy management. Students will also learn about disease
                states, drug interactions, adverse effects, and other factors
                that may affect medication therapy. Pharmacy programs typically
                require students to complete a certain number of supervised
                clinical hours in a variety of settings, such as community
                pharmacies, hospitals, and long-term care facilities. These
                clinical experiences allow students to apply their knowledge and
                skills in real-world situations, working alongside licensed
                pharmacists and other healthcare professionals. In addition to
                traditional classroom and clinical experiences, many pharmacy
                courses also incorporate technology and simulation-based
                training to help students develop the skills they need to
                succeed in the rapidly evolving healthcare industry. Pharmacy is
                a challenging and rewarding field that offers a wide range of
                career opportunities, from working as a community pharmacist to
                pursuing research or academia. If you are interested in pursuing
                a career in healthcare and have a passion for helping others, a
                pharmacy course may be the right choice for you.
              </p>
            </div>
            <br />

            <h2>
              <strong>Introduction to B.Pharm Course (4 Yrs.Duration) </strong>
            </h2>
            <br />
            <h4>
              <strong>Approved Intake : 60 Seats</strong>
            </h4>

            <h5>
              <strong>Fees : 59,000/- Rs Per Year</strong>
            </h5>
            <p>
              <br />
              B.Pharm (Bachelor of Pharmacy) is an undergraduate degree program
              in pharmacy that focuses on the study of drug composition,
              effects, and interactions, as well as the process of preparing and
              dispensing medications. The program aims to provide students with
              a comprehensive understanding of pharmacy practice and equip them
              with the knowledge and skills necessary to work in various
              healthcare settings.
              <br />
              B.Pharm courses typically last for four years and cover a wide
              range of topics, including pharmaceutics, pharmacology,
              pharmaceutical chemistry, pharmacognosy, and pharmacy practice.
              Students also learn about drug regulations, clinical research,
              drug therapy management, and patient counselling. The program
              includes both theoretical and practical components, with students
              engaging in laboratory work, projects, and clinical rotations to
              gain hands-on experience in pharmacy practice. During clinical
              rotations, students work under the supervision of licensed
              pharmacists in various healthcare settings, such as hospitals,
              clinics, and community pharmacies.
              <br />
              Graduates of B.Pharm programs are eligible to work as pharmacists
              in a variety of settings, including community pharmacies,
              hospitals, pharmaceutical companies, research labs, and government
              agencies. They can also pursue advanced degrees, such as M.Pharm
              (Master of Pharmacy) or Ph.D. in Pharmacy, to specialize in a
              particular area of pharmacy practice or pursue research in the
              field. B.Pharm is a challenging and rewarding program that
              prepares students for a fulfilling career in healthcare. If you
              are interested in pursuing a career in pharmacy and have a passion
              for helping others, a B.Pharm course may be the right choice for
              you.
            </p>
            <br />

            <h2>
              <strong>Introduction to M.Pharm Course (2 Yrs. Duration) </strong>
            </h2>
            <br />
            <h4>
              <strong>Approved Intake</strong>
            </h4>
            <ol type="1">
              <li>
                <strong>M.Pharm in Pharmaceutics: 06 Seats</strong>
              </li>
              <li>
                <strong>
                  M.Pharm in Pharmaceutical Quality Assurance: 09 Seats
                </strong>
              </li>
            </ol>

            <h5>
              <strong>Fees : 1,01,400/- Rs Per Year</strong>
            </h5>
            <p>
              <br />
              M.Pharm (Master of Pharmacy) is a postgraduate degree program in
              pharmacy that provides advanced training in various aspects of
              pharmaceutical sciences. The program is designed to prepare
              students for leadership roles in the pharmaceutical industry,
              research and development, and academia.
              <br />
              M.Pharm courses typically last for two years and cover a wide
              range of topics, including drug design and development, drug
              delivery systems, pharmacokinetics, pharmacology, and pharmacy
              practice. Students also learn about regulatory affairs, clinical
              research, and pharmaceutical marketing. The program includes both
              theoretical and practical components, with students engaging in
              laboratory work, projects, and research to gain hands-on
              experience in pharmaceutical sciences. During the program,
              students are required to complete a research project, usually in
              collaboration with a pharmaceutical company or research
              institution.
              <br />
              Graduates of M.Pharm programs are equipped with the knowledge and
              skills necessary to work in various sectors of the pharmaceutical
              industry, including research and development, quality assurance,
              regulatory affairs, and marketing. They can also pursue a career
              in academia, teaching and conducting research in pharmaceutical
              sciences.
              <br />
              M.Pharm is a challenging and rewarding program that provides
              advanced training in pharmaceutical sciences and prepares students
              for a leadership role in the pharmaceutical industry. If you are
              interested in pursuing a career in the pharmaceutical industry or
              academia and have a passion for innovation and research, an
              M.Pharm course may be the right choice for you.
            </p>
            <br />

            <h2>
              <strong>
                Introduction to Pharm D. course (6 Yrs. Duration){" "}
              </strong>
            </h2>
            <br />
            <h4>
              <strong>Approved Intake : 30 Seats</strong>
            </h4>

            <h5>
              <strong>Fees : 1,35,000/- Rs Per Year</strong>
            </h5>
            <p>
              <br />
              Doctor of Pharmacy (Pharm.D) is a professional degree program in
              pharmacy that focuses on patient-centered care and medication
              management. The program aims to provide students with advanced
              knowledge and skills necessary to work as clinical pharmacists and
              provide comprehensive medication management services to patients
              in various healthcare settings.
              <br />
              Pharm.D programs typically last for six years and include a wide
              range of topics, including pharmaceutics, pharmacology,
              pharmaceutical chemistry, pharmacotherapy, and pharmacy practice.
              Students also learn about healthcare systems, healthcare policies,
              and healthcare economics. The program includes both theoretical
              and practical components, with students engaging in laboratory
              work, projects, and clinical rotations to gain hands-on experience
              in pharmacy practice. During clinical rotations, students work
              under the supervision of licensed pharmacists in various
              healthcare settings, such as hospitals, clinics, and community
              pharmacies.
              <br />
              Graduates of Pharm.D programs are eligible to work as clinical
              pharmacists in a variety of healthcare settings, including
              hospitals, clinics, and community pharmacies. They can also pursue
              advanced practice residency programs or fellowship programs to
              specialize in a particular area of pharmacy practice or pursue a
              career in research.
              <br />
              Pharm.D is a challenging and rewarding program that provides
              advanced training in pharmacy practice and prepares students for a
              leadership role in patient-centered care. If you are interested in
              pursuing a career as a clinical pharmacist and have a passion for
              patient care, a Pharm.D program may be the right choice for you.
            </p>
            <br />

            <h2>
              <strong>
                Introduction to PG Diploma in Drug Regulatory Affairs
              </strong>
            </h2>
            <br />
            <h4>
              <strong>Approved Intake : 100 Seats</strong>
            </h4>

            <p>
              <br />
              Postgraduate Diploma in Drug Regulatory Affairs is a specialized
              program that provides students with an in-depth understanding of
              the regulatory requirements governing the pharmaceutical industry.
              The program focuses on developing the knowledge and skills
              necessary to navigate the complex regulatory landscape and ensure
              compliance with the regulations.
              <br />
              The program covers a wide range of topics, including drug
              development, clinical trials, marketing authorization, regulatory
              compliance, and pharmacovigilance. Students also learn about
              regulatory affairs in different regions, such as the United
              States, Europe, and Asia.
              <br />
              The duration of the program is usually one year, and it includes
              both theoretical and practical components. Students engage in
              coursework, case studies, and projects to gain hands-on experience
              in regulatory affairs. The program also includes guest lectures by
              industry experts and regulatory authorities to provide students
              with practical insights into regulatory affairs.
              <br />
              Graduates of PG Diploma in Drug Regulatory Affairs are equipped
              with the knowledge and skills necessary to work in regulatory
              affairs in the pharmaceutical industry. They can pursue careers in
              regulatory affairs, quality assurance, pharmacovigilance, and
              related fields. They can also choose to pursue advanced degrees,
              such as Master's or Doctoral degrees, in regulatory affairs or
              related fields. If you are interested in pursuing a career in drug
              regulatory affairs and have a passion for ensuring patient safety,
              a PG Diploma in Drug Regulatory Affairs may be the right choice
              for you.
            </p>
            <br />
          </div>
        </div>
      </div>
    </div>
  );
}
