import React from 'react'

function PrincipalSecretory() {
  return (
    <div>

<div className="container mt-5 mb-5">

    <h2>
      	Principal Secretary/ Secretary and Commissioner / Director of AYUSH or Homoeopathy 
    </h2>

<div className="row register mt-2" style={{ maxWidth: "100%" }}>

    <div className="container">  
      <div className="alumni-area mt-4">
        <div className="row">
          <div className="col-md-12">

            <h3>
                1.	Principal Secretary/ Secretary and Commissioner - 
            </h3>

            <p><a href="https://gmscl.gujarat.gov.in/board-director-eng.htm"> https://gmscl.gujarat.gov.in/board-director-eng.htm </a></p>

                <table
                  className="table table-bordered custom-table" style={{ height: '100px', maxWidth: "100%" , textAlign: "center" , border: "1px solid black" }}
                >
                    <thead style={{ height: '50px'}}>
                        <tr>
                            <th>NAME</th>
                            <th>DESIGNATION</th>
                            <th>CONTACT NUMBER</th>
                            <th>EMAIL ID</th>
                            <th>PHOTO</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>SHRI DHANANJAY DWIVEDI, IAS</td>
                            <td>Principal Secretary (Medical Services & Medical Education), Health & Family Welfare Department</td>
                            <td>079-23251401</td>
                            <td>sechfwd@gujarat.gov.in</td>
                            <td>
                                <img
                                  src="../assets/images/dwivedi.jpg"
                                  alt="SHRI DHANANJAY DWIVEDI, IAS"
                                //   className="img-responsive img-thumbnail"
                                  width={220}
                                  height={160}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>

                {/* <br/> */}

                {/* <div class="card mb-5 shadow rounded" style={{ maxWidth: '900px', backgroundColor: "rgba(203, 195, 152,0.3)", border: "1px solid black"}}>
                  <div class="row no-gutters">
                    <div class="col-md-4">
                      <img src="../assets/images/dwivedi.jpg" alt="..." height={240} width={240}/>
                    </div>
                    <div class="col-md-8">
                      <div class="card-body">
                        <h4 class="card-title">SHRI DHANANJAY DWIVEDI, IAS</h4>
                        <p class="card-text"><strong>DESIGNATION :</strong>Principal Secretary (Medical Services & Medical Education), Health & Family Welfare Department</p>
                        <p class="card-text"><strong>CONTACT NUMBER :</strong> 079-23251401</p>
                        <p class="card-text"><strong>EMAIL ID :</strong> sechfwd@gujarat.gov.in</p>
                      </div>
                    </div>
                  </div>
                </div> */}

          </div>
          
          <div className="col-md-12 mt-5">

            <h3>
                2.	Director of AYUSH or Homoeopathy - 
            </h3>

            <p><a href="https://ayush.gujarat.gov.in/list-of-directors.htm"> https://ayush.gujarat.gov.in/list-of-directors.htm </a></p>

                <table
                  className="table table-bordered custom-table" style={{ height: '100px', maxWidth: "100%" , textAlign: "center" , border: "1px solid black" }}
                >
                    <thead style={{ height: '50px'}}>
                        <tr>
                            <th>NAME</th>
                            <th>DESIGNATION</th>
                            <th>CONTACT NUMBER</th>
                            <th>EMAIL ID</th>
                            <th>PHOTO</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Prof. Vd. Jayeshkumar M. Parmar</td>
                            <td>Director Office Of Director, AYUSH Government of Gujarat</td>
                            <td>079-23253793</td>
                            <td>dir-ayush@gujarat.gov.in</td>
                            <td>
                                <img
                                  src="../assets/images/jayeshkumar.jpg"
                                  alt="Prof. Vd. Jayeshkumar M. Parmar"
                                  className="img-responsive img-thumbnail"
                                  width={180}
                                  height={100}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>

                {/* <br/> */}

                {/* <div class="card mb-5 shadow rounded" style={{ maxWidth: '900px', backgroundColor: "rgba(203, 195, 152, 0.3)", border: "1px solid black"}}>
                  <div class="row no-gutters">
                    <div class="col-md-4">
                      <img src="../assets/images/jayeshkumar.jpg" alt="..." height={240} width={240}/>
                    </div>
                    <div class="col-md-8">
                      <div class="card-body">
                        <h4 class="card-title">Prof. Vd. Jayeshkumar M. Parmar</h4>
                        <p class="card-text"><strong>DESIGNATION :</strong>Director Office Of Director, AYUSH Government of Gujarat</p>
                        <p class="card-text"><strong>CONTACT NUMBER :</strong>079-23253793</p>
                        <p class="card-text"><strong>EMAIL ID :</strong>dir-ayush@gujarat.gov.in</p>
                      </div>
                    </div>
                  </div>
                </div> */}

          </div>
        </div>
      </div>
    </div>

</div>

</div>
      
    </div>
  )
}

export default PrincipalSecretory;
