import React from 'react'

function FeesDetails() {
    const banner1 = 'https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/1.jpeg'

    return (
      <div>
        
 <div className="container mt-5">
 <div className="row justify-content-center mt-2  p-0">
    <div className="col-lg-12 col-md-12">
        <h4 className='text-uppercase'>Fees for MBBS Students AY 2023-24</h4>
     <table className="table table-bordered text-center">
        <thead className='bg-danger text-light p-3'>
            <tr>
                <th>Sl. No.</th>
                <th>Description of fees</th>
                <th>To be paid</th>
                <th>Amount </th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>01</td>
               <td>Tuition </td>
               <td>Semester vise</td>
                <td>As per FRC, Govt. of Gujarat*</td>
            </tr>
            <tr>
                <td>
                    02
                </td>
                <td>Uni. Enrolment </td>
                <td>One time at the admission</td>
                <td>25000/-</td>
            </tr>
            <tr>
                <td>03</td>
                <td>E - Suvidha </td>
                <td>Yearly</td>
                <td>1000/-</td>
            </tr>
            <tr>
                <td></td>
                <td>At the time of admission</td>
                <td></td>
                <td>* + 26000</td>
            </tr>
            
        </tbody>
     </table>
    </div>
    <div className="col-lg-12 col-md-12">
        <h4 className='text-uppercase'>Plus following fees are for those who opt other facilities of the institute:</h4>
     <table className="table table-bordered text-center">
        <thead className='bg-danger text-light'>
        <tr>
            
                <th>Sl. No.</th>
                <th>Description of fees</th>
                <th>To be paid</th>
                <th>Amount </th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>01</td>
                <th colSpan={3}>Hostel Fees* Triple sharing including mess facility</th>
            </tr>
            <tr>
                <td></td>
                <th>
                Girls
                </th>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <td></td>
                <td>
                Non AC
                </td>
                <td>Yearly</td>
                <td>1 Lakh</td>
            </tr>
            <tr>
                <td></td>
                <td>
                AC
                </td>
                <td>Yearly</td>
                <td>1.5 Lakh</td>
            </tr>
            <tr>
                <td></td>
                <th>
                Boys
                </th>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <td></td>
                <td>
                Non AC
                </td>
                <td>Yearly</td>
                <td>1 Lakh</td>
            </tr>
            <tr>
                <td></td>
                <td>
                AC (Small room without gallery)
                </td>
                <td>Yearly</td>
                <td>1.25 Lakh</td>
            </tr>
            <tr>
                <td></td>
                <td>
                AC (Big room with gallery)
                </td>
                <td>Yearly</td>
                <td>1.5 Lakh</td>
            </tr>
            <tr>
                <td></td>
                <td>
                Hostel Deposit
                </td>
                <td>At the time of Admission</td>
                <td>20000
(Refundable)
</td>
            </tr>
            <tr>
                <td>02</td>
                <td>
                Transportation 
                </td>
                <td>Yearly (1st Nov. to 31st October)</td>
                <td>22000/-</td>
            </tr>
          
        </tbody>
     </table>
     <h4 className="mt-3">AT EVERY UNIVERSITY EXAMS FOR QUALIFYING TO NEXT PHASE

</h4>
     <table className="table table-bordered mb-3">
        <thead className='bg-danger text-light'>
            <tr>
                <th>No. </th>
                <th>Description of fees</th>
                <th>To be Paid</th>
                <th>Amount</th>
            </tr>

        </thead>
        <tbody>
            <tr>
                <td>01</td>
                <td>Uni. Examination</td>
                <td>At the time of Uni exams</td>
                <td>30000/-</td>
            </tr>
        </tbody>
     </table>


     <h4 className="mt-5 ">BANK DETAILS </h4>
     <hr />
     <h5>  NAME:     SWAMINARAYAN INST OF MEDICAL SCI AND RES</h5>   
     <h5>  SAVINGS A/C NO.  50100582015633</h5>
     <h5>  HDFC BANK BRN.0894 KALOL-382721</h5>
     <h5>  IFSC : HDFC0000894</h5>



     
    </div>

 
  </div>
 </div>

         <div className="container mt-5 " >
      <div className="row">
         
         
          <div className="col md-12  mb-5">
          <img src={banner1} alt="" srcset="" />
          </div>
      </div>
      </div></div>
    )
}

export default FeesDetails