import React from 'react'
import { toast } from 'react-toastify';
import axios from 'axios';
import { useState, useRef, useEffect } from 'react';
import { COLLEGE_PERMISSION_GET } from '../../Utils/InfoUploadingConst';

function RecognitionITCS() {

  const [data, setData] = useState([]);

  // const navigate = useNavigate();

  const getData = async () => {
      const config = {
          method: "get",
          url: COLLEGE_PERMISSION_GET,
          headers: {
              "Content-Type": "application/json",
          },
      };

      await axios(config)
          .then((res) => {
              res.data.data.sort((a, b) => b.id - a.id);
              res.data.data.forEach((element) => {
                  element.attachments = JSON.parse(element.attachments);
              });
              console.log(res.data.data)
              setData(res.data.data);

              
          })
          .catch((err) => {
              console.log(err);
          });
  };

  useEffect(() => {
      getData();
  }, []);


  return (
    <div>

<div className="container mt-5 mb-5">
        <div className="row register" style={{ maxWidth: "100%" }}>
          <div className="col-md-12">
            <h3>
              Recognition           
            </h3>
            <p>Recognition documents Links are below</p>
          </div>

        
<div className="container">

  
<div className="alumni-area pt-100 pb-70">
<div className="row">

{data
    ?.filter((item) => item.type === "Recognition")
    ?.map((item, key) => {
      return (
      item.status === "ACTIVE" && item.college_id == "1111009"  &&(
<div className="col-md-4">
<a href={item?.attachments} target="_blank">
  <div className="single-alumoni-updates-card">
  <div className=" d-flex flex-row flex-wrap align-items-center ">
    <div className=" bg-danger text-center p-3 me-3">
    <i class="ri-file-download-line text-light " style={{fontSize:"20px"}}></i>
    </div>
   <div className="">
    <p>
      {item?.title} Recognition
    </p>
   </div>
  </div>
</div>
</a>
  </div>
))
}
)}
  

</div>

</div>
</div>
         
        </div>
        <br />
        <br />
      </div>






{/* <div className="container mt-5 mb-5">
  <div className="row">
    <div className="col-md-12">
      <h2>
        Recognition
        <br />
      </h2>
    </div>

    <div className="row">

    {data
    ?.filter((item) => item.type === "Recognition")
    ?.map((item, key) => {
      return (
      item.status === "ACTIVE" && item.college_id == "1111007"  &&(
        
    <div className="col-md-3 mt-5" key={item.id}>
      


      <h5>
        
        <a  href={item?.attachments} target='_blank' > {item?.title} </a>

      </h5>

    

      </div>

)
)
})
}
      
  
  </div>
   
  </div>
  <br />
  <br />
</div> */}


    </div>
  )
}

export default RecognitionITCS;