import './App.css';
import Router from './app/Router/Router';
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {BrowserRouter} from 'react-router-dom'
import {Route} from 'react-router';
import { useState } from 'react';
import SplashScreen from './app/Components/SplashScreen/SplashScreen';
import ScrollToTop from './app/Components/ScrollToTop/ScrollToTop';

function App() {
  const [loading,setLoading] = useState(true)

  setTimeout(() => {
    setLoading(false)
  }, 2000);
  return (
    <div className="App">
      <BrowserRouter>
      {/* {
        loading?
        <SplashScreen/>
        : */}
        <>
          <ScrollToTop/>
          <Router/>
          <ToastContainer autoClose={2000} />
        </>
      {/* } */}
      </BrowserRouter>
    </div>
  );
}

export default App;
