import React from "react";

function Eligibility() {
  return (
    <div>
      <div className="container mt-5 mb-5">
        <div className="row register" style={{ maxWidth: "100%" }}>
          <div className="col-md-12">
            <p>
              <span style={{ fontSize: "18pt" }}>
                <h3>Eligibility Details</h3>
              </span>
              <br />
            </p>
            <p>
              The following are the course eligibility conducted by Shree
              Swaminarayan Enginnering College
            </p>

            <p>
              <strong>All Courses Eligibility Details:</strong>
              <br />
            </p>
            <table
              className="table table-bordered"
              style={{ borderCollapse: "collapse", width: "100%", height: 173 }}
              border={1}
            >
              <tbody>
                <tr style={{ height: 20 }}>
                  <td
                    style={{
                      width: "5.17544%",
                      height: 20,
                      textAlign: "center",
                    }}
                  >
                    <strong>S.No</strong>
                    <br />
                  </td>
                  <td style={{ width: "10.4386%", height: 20 }}>
                    <strong>Course</strong>
                    <br />
                  </td>

                  <td style={{ width: "74.2105%", height: 20 }}>
                    <strong>Eligibility</strong>
                  </td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td
                    style={{
                      width: "5.17544%",
                      height: 20,
                      textAlign: "center",
                    }}
                  >
                    1<br />
                  </td>
                  <td style={{ width: "10.4386%", height: 20 }}>
                    Diploma
                    <br />
                  </td>

                  <td>
                    Passed SSC (10th) examination from a recognized board and
                    English and Mathematics (Basic/Standard) as compulsory
                    subjects.
                  </td>
                </tr>

                <tr style={{ height: 20 }}>
                  <td
                    style={{
                      width: "5.17544%",
                      height: 20,
                      textAlign: "center",
                    }}
                  >
                    2<br />
                  </td>
                  <td style={{ width: "10.4386%", height: 20 }}>
                    Undergraduate (Degree)
                    <br />
                  </td>

                  <td>
                    Passed HSC (12th) examination with minimum 45% (40% for
                    Reserved Categories SC/ST/OBC/EWS) in Theory OR Theory +
                    Practical with Maths, Physics and Chemistry as compulsory
                    subjects.
                  </td>
                </tr>

                <tr style={{ height: 20 }}>
                  <td
                    style={{
                      width: "5.17544%",
                      height: 20,
                      textAlign: "center",
                    }}
                  >
                    3<br />
                  </td>
                  <td style={{ width: "10.4386%", height: 20 }}>
                    Postgraduate (ME)
                    <br />
                  </td>

                  <td>
                    Passed B.E./B.Tech. or equivalent examination in relative
                    discipline with minimum 50% (45% for Reserved Categories
                    SC/ST/OBC/EWS) from a recognized university.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Eligibility;
