import React from 'react'

function Auditorium() {
  return (
    <div className='Auditorium'>
        <>
  <div className="campus-life-area pt-100 pb-70">
    <div className="container">
      <h3 className="text-center mb-4 ">Auditorium</h3>
      <p className="text-center">
        The University has a high-tech facilities auditorium with a capacity of
        more than 250 people. The auditorium is accessible for a variety of
        cultural activities.
      </p>
    </div>
  </div>
  <div className="row justify-content-center mt-5">
    <div className="col-lg-4 col-md-6">
      <div className="single-campus-card">
        <div className="img">
          <a href="">
            <img src="assets/images/facilities/campus-life-1.jpg" alt="Image" />
          </a>
        </div>
      </div>
    </div>
    <div className="col-lg-4 col-md-6">
      <div className="single-campus-card">
        <div className="img">
          <a href="">
            <img src="assets/images/facilities/auditorium2.JPG" alt="Image" />
          </a>
        </div>
      </div>
    </div>
  </div>
</>

    </div>
  )
}

export default Auditorium