import React from 'react'

function DeanMessage() {
  return (
   
   
   <div className="container mt-3">
    <div className="row mb-5">
        <div className="col-md-12">
        <h3 className="text-center">DEAN’S MESSAGE</h3>
        <hr />
      <div className="card border-0 shadow-sm">
        <div className="card-body">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-sm-8 col-lg-6">
                {/* Section Heading*/}
                <div
                  className="section_heading text-center wow fadeInUp"
                  data-wow-delay="0.2s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.2s",
                    animationName: "fadeInUp"
                  }}
                >
                  {/* <h3>Engineering</h3> */}
                  <div className="line" />
                </div>
              </div>
            </div>
            <div className="row">
              
              <div className="col-12 col-sm-6 col-lg-5 align-center">
                <div>
                    <div

                      className="single_advisor_profile wow fadeInUp"
                      data-wow-delay="0.2s"
                      style={{
                        visibility: "visible",
                        animationDelay: "0.2s",
                        animationName: "fadeInUp"
                      }}
                    >
                      {/* Team Thumb*/}
                      <div className="advisor_thumb">
                      <br/>
                      <br/>
                        <img className='rounded'
                          src="../assets/images/deannew.jpg"
                          alt="vicepresident"
                          width="100%"
                        />
                        {/* Social Info*/}
                      </div>
                      {/* Team Details*/} <br />
                      <div className="single_advisor_details_info text-center">
                        <h3 className='text-danger'> Dr. Ashwin Sanghavi,</h3>
                        <p className="designation"> M.B.B.S,MD (Paediatric)

F.I.A.P</p>
<p className="designation"> Swaminarayan Institute
of Medical Sciences &
Research,

Kalol, Gandhinagar</p>
                      </div>
                    </div>
                  </div>
              </div>
              <div className="col-12 col-sm-6 col-lg-7 text-center mt-5">
                <p>It gives me great pleasure to welcome all of you to the Swaminarayan Institute of Medical Sciences
and Research, Kalol.</p>
<p>Our medical college along with PSM Multispeciality Hospital has 390 teaching beds to provide
excellent health services to the community and a great academic atmosphere for future doctors.
Swaminarayan Institute of Medical Sciences and Research, Kalol, and PSM Multispeciality Hospital
are run by Shree Swaminarayan Vishvamangal Gurukul and we strive to provide the best healthcare
facilities to the community.</p>
<p>The committed and dedicated faculty of this institute will ensure that students graduating from this
institute will not only have technical expertise but also develop to be ethical and graceful doctors to
society.</p>
<p>Our staff and students will leave no stone unturned to provide quality medical care in the field of
research. On their behalf, I assure you that the aim and objective of our medical college as well as
the PSM Multispeciality Hospital will be fully accomplished. Our priorities will be to provide the best
medical services and excellent teaching for our medical students.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
        </div>
    </div>
    <div className="row mb-5">
        <div className="col-md-12">
        <h3 className="text-center">SUPERITENDENT’S MESSAGE</h3>
        <hr />
      <div className="card border-0 shadow-sm">
        <div className="card-body">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-sm-8 col-lg-6">
                {/* Section Heading*/}
                <div
                  className="section_heading text-center wow fadeInUp"
                  data-wow-delay="0.2s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.2s",
                    animationName: "fadeInUp"
                  }}
                >
                  {/* <h3>Engineering</h3> */}
                  <div className="line" />
                </div>
              </div>
            </div>
            <div className="row">
              
              <div className="col-12 col-sm-6 col-lg-5 align-center">
                <div>
                  <div

                      className="single_advisor_profile wow fadeInUp"
                      data-wow-delay="0.2s"
                      style={{
                        visibility: "visible",
                        animationDelay: "0.2s",
                        animationName: "fadeInUp"
                      }}
                    >
                      {/* Team Thumb*/}
                      <div className="advisor_thumb">
                      <br/>
                      <br/>
                        <img className='rounded'
                          // src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/employee_image/Employee_Student_Photo/undefined_1694519299895.jpeg"
                          src="../assets/images/msnew.jpg"
                          alt="Medical sup.jpg"
                          width="100%"
                        />
                        {/* Social Info*/}
                      </div>
                      {/* Team Details*/} <br />
                      <div className="single_advisor_details_info text-center">
                      <h3 className='text-danger'>Prof (Dr.) Bhavana Shah</h3>
                    <p>M B B S, MD 
Medical superintendent,</p>
<p className="designation"> Swaminarayan Institute
of Medical Sciences &
Research,

Kalol, Gandhinagar</p>
                      </div>
                    </div>
                  </div>
              </div>
              <div className="col-12 col-sm-6 col-lg-7 text-center mt-5">
                <p>I would like to welcome you to PSM Multispeciality Hospital, a tertiary care hospital
attached to Swaminarayn Institute of Medical Sciences and research, Kalol . <br /> <br />

This is a state of art Multispeciality hospital having 390+ beds. We offer highly subsidized
services ranging from basic life support to speciality care to all sections of the society of
Gujarat. Besides these services, our outreach Programmes hold medical camps in the rural
areas serving the poor and weaker sections of the society. <br /> <br />

We have student friendly atmosphere and a rich culture and a perfect ambience for
research and academics. I can assure all aspiring doctors that PSM Multispeciality Hospital
will prove to be a great place for learning medicine and an opportunity to emerge from
here as a competent doctors fully prepared to serve the society and Nation. <br /> <br />

It’s a challenge to keep pace with the fast growing technology and rapid advancement in
Health Care. The challenge is well accepted to provide quality health care while retaining
the values amongst our committed Medical, Nursing and Paramedical Staff. We will
continue to strive for excellence and any contribution made would help the suffering
humanity.</p>

              </div>
            </div>
          </div>
        </div>
      </div>
        </div>
    </div>
   </div>
  )
}

export default DeanMessage