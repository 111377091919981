import React from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useState, useRef, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Http } from "../../Services/Services";
import { SYLLABUS_GET10, SYLLABUS_GET3 } from "../../Utils/InfoUploadingConst";
import { LOCAL_DEPARTMENT } from "../../Utils/LocalStorageConstants";


export default function AyurvedaSyllabus() {

  const [data, setData] = useState([]);

  const navigate = useNavigate();

  const departmentOpt = JSON.parse(
    localStorage.getItem(LOCAL_DEPARTMENT)
  )

  const getData = async () => {
      const config = {
          method: "get",
          url: SYLLABUS_GET10,
          headers: {
              "Content-Type": "application/json",
          },
      };

      await axios(config)
          .then((res) => {
              res.data.data.sort((a, b) => b.id - a.id);
              console.log(res.data.data)
              setData(res.data.data);
          })
          .catch((err) => {
              console.log(err);
          });
  };

  useEffect(() => {
      getData();
  }, []);

  
  return (
    <div>

<div className="container mt-5 mb-5">
        <div className="row">

          <section className="course-section">
            <div class="container">

              <div className="section-title">
                <h2>Syllabus</h2>
              </div>
              <div className="sub-section-title">
                <h2>All Faculty</h2>
              </div>

              <div class="row mbr-justify-content-center">
                {Array.from(new Set(data.map(item => item.department)))
                  .map((department, key) => {
                  const departmentData = data.find(item => item.department === department);
                  if (!departmentData) {
                  // Skip rendering if no matching data for the department
                  return null;
                }
                return (
                <div className="col-lg-6 mbr-col-md-10" key={key}>
                  <Link to={`/ayurveda/Syllabus/${departmentData.department}`}>
                    <div className="wrap">

                      <div className="ico-wrap">
                        <span
                          className="mbr-iconfont fa fa-graduation-cap"
                          style={{ color: "#fff" }}
                        >
                        </span>
                      </div>

                      <div className="text-wrap vcenter">
                        <h2
                          className="mbr-fonts-style mbr-bold mbr-section-title3 display-5"
                          style={{ color: "#fff" }}
                          >
                          {
                            departmentOpt.find(s => s.id == department)?.name
                          }
                        </h2>
                        <p
                          className="mbr-fonts-style text1 mbr-text display-6"
                          style={{ color: "#fff" }}
                        >
                          To get more details on Syllabus details, Click here
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
                );
                  })
                }
              </div>

            </div>
          </section>
          
        </div>
      </div>

    </div>
  );
}
