import React from "react";

export default function ResearchPublications() {
  return (
    <div>
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-md-12">
            <p>
              <span style={{ fontSize: "18pt" }}>
                <strong>Research & Publications 2022-23</strong>
              </span>
              <br />
            </p>

            <table
              className="table table-bordered"
              style={{ borderCollapse: "collapse", width: "100%", height: 173 }}
              border={1}
            >
              <tbody>
                <tr>
                  <td>
                    <span style={{ color: "#000000" }}>
                      &nbsp;<strong>Sl.no.</strong>
                    </span>
                  </td>
                  <td>
                    <span style={{ color: "#000000" }}>
                      <strong>Name of Faculty</strong>
                    </span>
                  </td>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>
                        <strong>Title of Article/ Book</strong>
                      </span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>
                        <strong>Name of Journal/ Publisher</strong>
                      </span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>
                        <strong>
                          Journal/ Magazine/ Conference/News Letter
                        </strong>
                      </span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>
                        <strong>Published Date</strong>
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>1</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>
                        Dr Anita Patel with Dr Aishwarya
                      </span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>
                        AYURVEDIC MANAGEMENT OF IRRITABLE BOWEL SYNDROME INDUCED
                        PARIKARTIKA (FISSURE-IN ANO): A CASE REPORT- DOI:
                        10.7897/2277-4343.130477
                      </span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>
                        International Journal of Research in Ayurveda and
                        Pharmacy-
                      </span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>
                        International Journal of Research in Ayurveda and
                        Pharmacy (IJRAP) is an online and print Journal.-(ISSN
                        Online:2229–3566, ISSN Print:2277–4343)
                      </span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>
                        Volume 13, Issue 4 , Jul - Aug 2022
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>2</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>
                        Dr Anita Patel with Dr Mayukha
                      </span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>
                        A REVIEW ON KADARA AND ITS MULTI MODAL TREATMENT
                        APPROACH WITH SPECIFIC REFERENCE TO AGNIKARMA-
                        <a
                          style={{ color: "#000000" }}
                          href="https://doi.org/10.47070/ijapr.v10i4.2297"
                        >
                          https://doi.org/10.47070/ijapr.v10i4.2297
                        </a>
                      </span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>
                        International Journal of Ayurveda and Pharma Research
                      </span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>
                        International Journal of Ayurveda and Pharma Research-
                        ISSN: 2322 - 0902 (P)
                      </span>
                      <br />
                      <span style={{ color: "#000000" }}>
                        {" "}
                        ISSN: 2322 - 0910 (O)
                      </span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>
                        April 2022 | Vol 10 | Issue 4 44
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>3</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>
                        Dr Anita Patel with Dr Dinesh K S
                      </span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>
                        Effect of Ayurveda in management of dysbiosis with
                        special reference to Bifidobacterium in children with
                        autism spectrum disorders-
                        <a
                          style={{ color: "#000000" }}
                          href="https://doi.org/10.51248/.v42i2.972"
                        >
                          https://doi.org/10.51248/.v42i2.972
                        </a>
                      </span>
                    </p>
                  </td>
                  <td className="text-center">
                    <span style={{ color: "#000000" }}>----</span>
                  </td>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>
                        <a
                          style={{ color: "#000000" }}
                          href="https://biomedicineonline.org/index.php/home/IABMS"
                        >
                          Biomedicine (Print ISSN: 0970 2067), official
                          publication of
                        </a>
                        <a
                          style={{ color: "#000000" }}
                          href="https://biomedicineonline.org/index.php/home/IABMS"
                        >
                          {" "}
                          Indian Association of Biomedical Scientists (IABMS)
                        </a>
                        ,
                      </span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>1/5/22</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>4</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>Dr Shanti R Nair</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>
                        Review of gara visha adishtan w.s.r to cosmetic toxicity
                      </span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>
                        world journal of pharmacy and pharmaceutical sciences
                      </span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>
                        volume 12,issue 2,647,654, review article ISSN 2278
                        -4357
                      </span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>
                        Accepted 26 january 2023
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>5</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>
                        Dr. Sruthi Mohan with Dr. Hemal Bhatt
                      </span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>
                        Article - A Critical Review on Charakokta Hrudya
                        Dashemani w.s.r. to Amla Rasa
                      </span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>
                        International Journal of Ayurved &amp; Pharmaceutical
                        Chemistry
                      </span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>
                        International Journal of Ayurved &amp; Pharmaceutical
                        Chemistry
                      </span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>
                        July 10th 2022 volume 17, issue 1
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>6</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>
                        Dr. Visakh Sakthidharan
                      </span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>
                        Study of anukta srotas WSR to stanyavaha srotas - A
                        review
                      </span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>WJPPS</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>
                        An International Peer Reviewed Journal for
                        Pharmaceutical and Medical Research and Technology
                      </span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>
                        July 24 2022 Volume 11, issue 8
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>7</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>
                        Dr. Rajat Patel with Dr. Arun R. Vaidya
                      </span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>
                        Book-Vivekchandra
                      </span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>Book Rivers</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>Book Rivers</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>
                        January 27th 2023
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>8</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>
                        Dr Priya Deshpande
                      </span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>
                        Endometriosis and Ayurved - Review article
                      </span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>
                        Ayurved Patrika , Ayurved Seva Sangh
                      </span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>
                        Ayurved Patrika: National Scientific Journal of Ayurved
                        ISSN 2278 -0726
                      </span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>December 2022</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>9</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>
                        Mr. Jitendra Jani
                      </span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>
                        Vartaman Samaje Sanskritasya Mahatvam
                      </span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>
                        Shanti e-journal of research
                      </span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>
                        Shanti e-journal of research
                      </span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>June 2022</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>10</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>
                        Dr Priya Deshpande
                      </span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>
                        Review article - Polycystic Ovarian Disease Preventive
                        Approach
                      </span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>
                        IESRF: Innovative Publication
                      </span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>
                        Journal of Preventive Medicine and Holistic Health
                        :2022:8(2)Jpmhh Print ISSN: 2454-6704. Online ISSN:
                        2454-6712
                      </span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>30 January 2023</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>11</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>
                        Dr. Anita Ratnavat
                      </span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>
                        "Effect of B-2CLF Yoga( herbal face pack) in the
                        management of yuvan pidaka"
                      </span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>
                        High Technology Letters, International journal
                      </span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>
                        Peer Review, ISSN NO: 1006-6748,
                      </span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>
                        published date - 6 June 2022
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>12</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>Dr Sarin T</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>
                        Exploring Mother Nature -Khadira
                      </span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>
                        The Ancient Ayurveda
                      </span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>
                        The Ancient Ayurveda Magazine, 16th issue
                      </span>
                    </p>
                  </td>
                  <td className="text-center">
                    <span style={{ color: "#000000" }}>----</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>13</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>Dr.Ayana R</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>
                        4 best yoga in Menopause
                      </span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>
                        The Ancient Ayurveda
                      </span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>
                        The Ancient Ayurveda Magazine,17th issue
                      </span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>January,2023</span>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
