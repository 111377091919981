import React, { useEffect, useState } from "react";
import axios from "axios";
import "./style.scss";
import { useNavigate, useParams } from "react-router-dom";
import { NEW_WEB_STAFF } from "../../Utils/InfoUploadingConst.js";
import { StaffDeptWise } from "../../Data/StaffDeptWise.js";

function StaffDetails() {
  const params = useParams();
  // let title = params.serviceName;
  let lastIndex = StaffDeptWise[params.serviceName]?.length;
  let title = StaffDeptWise[params.serviceName][lastIndex - 1];
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  const getData = async () => {
    const config = {
      method: "get",
      url: NEW_WEB_STAFF,
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios(config);
      // res.data.data.sort((a, b) => b.id - a.id);
      setData(
        res.data.data.filter(
          (item) => item.department_id === title && item.status === "ACTIVE"
        )
      );
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData();
  }, [title]);

  const role_order = [
    "DEAN",
    "HOD",
    "PROFESSOR",
    "ASSOCIATE PROFESSOR",
    "ASSISTANT PROFESSOR",
    "SENIOR RESIDENT",
    "JUNIOR RESIDENT",
    "TUTOR",
  ];

  // Sort data based on role_order
  const sortedData = data.sort((a, b) => {
    return role_order.indexOf(a.role) - role_order.indexOf(b.role);
  });

  console.log("sortedData -", sortedData);

  const roleMapping = {
    ASSTPR: "Assistant Professor",
    ASPR: "Associate Professor",
    ASSTLIB: "Assistant Librarian",
    Attend: "Attender",
    AdmCoun: "Admission Counsellor",
    BME: "BioMedical Engineer",
    COE: "Controller Of Exams",
    CLERK: "Clerk",
    DMS: "Deputy Medical Superintendent",
    DOFAC: "Dean Of Faculty (Diploma)",
    DOFUGPG: "Dean Of faculty (UG & PG)",
    JR: "Junior Resident",
    JRF: "Junior Research Fellow",
    LA: "Lab Assistant",
    MO: "Medical Officer",
    PROF: "Professor",
    Phy: "Physiotherapist",
    PA: "Panchakarma Assistant",
    Recep: "Receptionist",
    REG: "Registrar",
    SR: "Senior Resident",
    Tut: "Tutor",
    READ: "Reader",
    OFFE: "Office Executive",
    VP: "Vice Principal",
    PRO: "Provost",
    LIB: "Librarian",
    ACCNT: "Accountant",
    CC: "Clerk Cum Cashier",
    P: "Peon",
    HOD: "Head of The Department",

    SUACC: "SUACC",
    IT: "IT",

    Dean: "Dean/Principal",
    Mas: "Massure",
    "IT Cell": "Website Coordinator",
    Amission: "Admission Officer",
    SACC: "Sr Accountant",
    NET: "Network Administrator",
    "Exe-HR": "Executive-Human Resources",
    "H-HR": "Assistant Manager-Human Resource",
    SECOR: "Security Officer",
    PROC: "Proctor",
    SOPS: "Section Officer-Purchase & Stores",
    DIRP: "Director Placements",
    CWAR: "Chief Warden",
    DENIQSC: "Dean IQSC",
    DENSW: "Dean Student Welfare",
    DERES: "Dean Research",
    DIA: "Director International Affairs",
    DREGAC: "Deputy Registrar(Accreditation & Compliance)",
    DREGAD: "Deputy Registrar(Admissions and Admin)",
    EXAM: "Examiner",
    FOP: "Finance Officer",
    HITS: "Head IT Services",
    MANTAIN: "Mantainance Engineer",
  };

  return (
    <div className="GoverningBody">
      <div className="container">
        <h1 className="text-center mt-5">Department of {title}</h1>
        <br />
        <br />
        <div className="events-area pt-20 pb-20">
          <div className="container d-flex">
            <div className="row w-100">
              {sortedData.length === 0 ? (
                <h3 className="my-5 text-center">No Record Found</h3>
              ) : (
                sortedData.map((item, idx) => (
                  <div key={idx} className="col-12 col-sm-6 col-lg-3">
                    <div
                      className="single_advisor_profile wow fadeInUp"
                      data-wow-delay="0.2s"
                    >
                      <div className="advisor_thumb">
                        {/* <img
                          src={
                            item.photo ||
                            "../assets/images/emails/su_final_logo.png"
                          }
                          alt={item.name}
                          className="eventimage"

                        /> */}
                        <img
                          src={
                            item?.photo
                              ? item?.photo
                              : "/assets/images/white-logo.png"
                          }
                          className="card-img-top"
                          alt={item.name}
                          style={{
                            height: "350px",
                            objectFit: "cover",
                            objectPosition: "top",
                          }}
                        />
                      </div>
                      <div className="single_advisor_details_info">
                        <h5 className="card-title">{item.name || "---"}</h5>
                        <h6 className="text-dark">
                          Registration No : {item.reg_no || "--"}{" "}
                        </h6>
                        <h6 className="text-dark">
                          Qualification: {item.qualification || "---"}
                        </h6>
                        <h6 className="text-dark">
                          Designation:{" "}
                          {roleMapping[item.role] || item.role || "--"}
                        </h6>
                        <h6 className="text-dark">
                          Department: {item.department_id || "---"}
                        </h6>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StaffDetails;
