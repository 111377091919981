import React from "react";

export default function Eligibility() {
  return (
    <div>
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-md-12">
            <p>
              <span style={{ fontSize: "18pt" }}>
                <strong>Fee Structure</strong>
              </span>
              <br />
            </p>

            <p>
              <strong>Fee Structure (Nursing Faculty) Details:</strong>
              <br />
            </p>
            <table
              className="table table-bordered"
              style={{ borderCollapse: "collapse", width: "100%", height: 173 }}
              border={1}
            >
              <tbody>
                <tr style={{ height: 20 }}>
                  <td>
                    <strong>Sr No</strong>
                    <br />
                  </td>
                  <td>
                    <strong>Types of Fees</strong>
                    <br />
                  </td>
                  <td>
                    <strong>B.Sc.</strong>
                    <br />
                  </td>
                  <td>
                    <strong>P. B.Sc. Nursing</strong>
                    <br />
                  </td>
                  <td>
                    <strong>M. Sc. Nursing</strong>
                    <br />
                  </td>
                  <td>
                    <strong>GNM Nursing</strong>
                    <br />
                  </td>
                  <td>
                    <strong>ANM Nursing</strong>
                    <br />
                  </td>
                  <td>
                    <strong>Total</strong>
                    <br />
                  </td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td rowSpan={2}>1</td>
                  <td>Tution Fees GQ</td>
                  <td>106000</td>

                  <td>70000</td>
                  <td>120000</td>
                  <td>94000</td>
                  <td>38000</td>
                  <td>428000</td>
                </tr>

                <tr style={{ height: 20 }}>
                  <td>EXTRA</td>
                  <td>0</td>
                  <td>30000</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>30000</td>
                </tr>
              </tbody>
            </table>

            <p>
              <strong>One Time Fees:</strong>
            </p>
            <table
              className="table table-bordered"
              style={{ borderCollapse: "collapse", width: "100%", height: 173 }}
              border={1}
            >
              <tbody>
                <tr style={{ height: 20 }}>
                  <td>
                    <strong>Sr No</strong>
                    <br />
                  </td>
                  <td>
                    <strong>Types of Fees</strong>
                    <br />
                  </td>
                  <td>
                    <strong>B.Sc.</strong>
                    <br />
                  </td>
                  <td>
                    <strong>P. B.Sc. Nursing</strong>
                    <br />
                  </td>
                  <td>
                    <strong>M. Sc. Nursing</strong>
                    <br />
                  </td>
                  <td>
                    <strong>GNM Nursing</strong>
                    <br />
                  </td>
                  <td>
                    <strong>ANM Nursing</strong>
                    <br />
                  </td>
                  <td>
                    <strong>Total</strong>
                    <br />
                  </td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td>1</td>
                  <td>Application fees ( one time)</td>
                  <td>300</td>
                  <td>300</td>
                  <td>300</td>
                  <td>300</td>
                  <td>300</td>
                  <td>1500</td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td>2</td>
                  <td>University development fees</td>
                  <td>5000</td>
                  <td>5000</td>
                  <td>5000</td>
                  <td>-</td>
                  <td>-</td>
                  <td>15000</td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td>3</td>
                  <td>Enrollment Fees</td>
                  <td>500</td>
                  <td>500</td>
                  <td>500</td>
                  <td>100</td>
                  <td>100</td>
                  <td>1700</td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td>4</td>
                  <td>I card Fees</td>
                  <td>100</td>
                  <td>100</td>
                  <td>100</td>
                  <td>100</td>
                  <td>100</td>
                  <td>500</td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td>5</td>
                  <td>Library Fees</td>
                  <td>500</td>
                  <td>500</td>
                  <td>500</td>
                  <td>500</td>
                  <td>500</td>
                  <td>2500</td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td>6</td>
                  <td>Professional regulatory fees</td>
                  <td>2000</td>
                  <td>2000</td>
                  <td>2000</td>
                  <td>0</td>
                  <td>0</td>
                  <td>6000</td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td>7</td>
                  <td>CAUTION FEES (Refundable)</td>
                  <td>5000</td>
                  <td>5000</td>
                  <td>5000</td>
                  <td>0</td>
                  <td>0</td>
                  <td>15000</td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td>8</td>
                  <td>Total</td>
                  <td>13400</td>
                  <td>13400</td>
                  <td>13400</td>
                  <td>1000</td>
                  <td>1000</td>
                  <td>42200</td>
                </tr>
              </tbody>
            </table>

            <p>
              <strong>Yearly Fees:</strong>
              <br />
            </p>
            <table
              className="table table-bordered"
              style={{ borderCollapse: "collapse", width: "100%", height: 173 }}
              border={1}
            >
              <tbody>
                <tr style={{ height: 20 }}>
                  <td>
                    <strong>Sr No</strong>
                    <br />
                  </td>
                  <td>
                    <strong>Types of Fees</strong>
                    <br />
                  </td>
                  <td>
                    <strong>B.Sc.</strong>
                    <br />
                  </td>
                  <td>
                    <strong>P. B.Sc. Nursing</strong>
                    <br />
                  </td>
                  <td>
                    <strong>M. Sc. Nursing</strong>
                    <br />
                  </td>
                  <td>
                    <strong>GNM Nursing</strong>
                    <br />
                  </td>
                  <td>
                    <strong>ANM Nursing</strong>
                    <br />
                  </td>
                  <td>
                    <strong>Total</strong>
                    <br />
                  </td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td>1</td>
                  <td>Clinical Fees</td>
                  <td>5000</td>
                  <td>5000</td>
                  <td>5000</td>
                  <td>0</td>
                  <td>0</td>
                  <td>15000</td>
                </tr>

                <tr style={{ height: 20 }}>
                  <td>2</td>
                  <td>Exam Fees</td>
                  <td>1350</td>
                  <td>1350</td>
                  <td>1600</td>
                  <td>430</td>
                  <td>430</td>
                  <td>5160</td>
                </tr>

                <tr style={{ height: 20 }}>
                  <td>3</td>
                  <td>Group Insurance Fees</td>
                  <td>100</td>
                  <td>100</td>
                  <td>100</td>
                  <td>100</td>
                  <td>100</td>
                  <td>500</td>
                </tr>

                <tr style={{ height: 20 }}>
                  <td>4</td>
                  <td>Sports Fees</td>
                  <td>500</td>
                  <td>500</td>
                  <td>500</td>
                  <td>500</td>
                  <td>500</td>
                  <td>2500</td>
                </tr>
                
                <tr style={{ height: 20 }}>
                  <td>5</td>
                  <td>E- Suvidha fees PER YEAR/  SEM ASK TO MANAGEMENT</td>
                  <td>500</td>
                  <td>500</td>
                  <td>500</td>
                  <td>500</td>
                  <td>500</td>
                  <td>2500</td>
                </tr>

                <tr style={{ height: 20 }}>
                  <td>6</td>
                  <td>Total</td>
                  <td>7450</td>
                  <td>7450</td>
                  <td>7700</td>
                  <td>1530</td>
                  <td>1530</td>
                  <td>25660</td>
                </tr>

              </tbody>
            </table>

            <table
              className="table table-bordered"
              style={{ borderCollapse: "collapse", width: "100%", height: 173 }}
              border={1}
            >
              <tbody>
                
                <tr style={{ height: 20 }}>
                  <td>1</td>
                  <td>Hostel Fees</td>
                  <td>B.Sc. Nursing</td>
                  <td>P.B.Sc. Nursing</td>
                  <td>M.Sc. Nursing</td>
                  <td>GNM Nursing</td>
                  <td>ANM Nursing</td>
                </tr>

                <tr style={{ height: 20 }}>
                  <td>2</td>
                  <td>Open</td>
                  <td>70,000</td>
                  <td>70,000</td>
                  <td>70,000</td>
                  <td>70,000</td>
                  <td>70,000</td>
                </tr>

                <tr style={{ height: 20 }}>
                  <td>3</td>
                  <td>SC/ST</td>
                  <td>Scholarship +50,000</td>
                  <td>Scholarship +50,000</td>
                  <td>Scholarship +50,000</td>
                  <td>Scholarship +50,000</td>
                  <td>Scholarship +50,000</td>
                </tr>
              </tbody>
            </table>

          </div>
        </div>
      </div>
    </div>
  );
}
