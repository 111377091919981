import React from 'react'
import { Link } from 'react-router-dom'

function AboutHospital() {
  return (
    <div>
      <div class="page-banner-area bg-3">
<div class="container">
<div class="page-banner-content">
<h1>About Hospital</h1>
<ul>
<li> <Link to={'./../'}>  Home </Link></li>


<li>About Hospital</li>
</ul>
</div>
</div>
</div>
      
      <div className="container mt-5 mb-5">
    <div className="row">
      <div className="col-md-12">
        <div className="wpb_column vc_column_container vc_col-sm-6">
          <div className="vc_column-inner">
            <div className="wpb_wrapper">
              <div className="wpb_text_column wpb_content_element ">
                <div className="wpb_wrapper">
                  <p>
                    <strong>“Where life gives reason for smile”</strong>&nbsp;is the central theme 
                    at PSH HOSPITAL for the fast and happy recovery of its patients. PSH HOSPITAL 
                    is a HOMOEOPATHIC hospital located in Kalol, Gujarat, India. Founded in 2017, 
                    today it is one of the best homoeopathic hospital in North Gujarat 
                    with 50 beds and over specialty centers. PSH hospital offers state-of-the-art 
                    diagnostic, therapeutic and intensive care facilities in a one-stop medical center.
                  </p>
                  <p>
                    PSH hospital serves an array of patients including domestic patients from Kalol & neighboring 
                    Villages in Gujarat. PSH Hospital is one of the finest world-class hospitals in Kalol offering 
                    world-class homeopathic facilities covering the body from head to toe, individualizing each one. 
                    PSH Hospital is equipped with the best facilities to take care of in-patient, out-patient and 
                    daycare of the patient in the shortest, most reliable & most harmless manner.
                  </p>
                  <p>
                    At PSH Hospital, we are a team of medical, nursing, volunteer and administrative staff working 
                    in close co-operation to take care of the patient in a compassionate manner.
                  </p>
                  <p>
                    <strong>
                      Few of the salient features of PSH Hospitals, Kalol:
                    </strong>
                  </p>
                  <ul>
                    <li>More than 10 clinical specialties under one roof</li>
                    <li>First homeopathic  hospital in Kalol</li>
                    <li>
                      More than 50  beds Hospital in the Kalol
                    </li>
                    <li>
                    	Dedicated  to cure the patient as a whole 
                    </li>
                    <li>Dedicated wing for patients</li>
                  </ul>
                  <p>
                    For More Details Visit :{" "}
                    <a
                      href="sshchospital@gmail.com"
                      target="_blank"
                      rel="noopener"
                    >
                      sshchospital@gmail.com
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  )
}

export default AboutHospital