import React from 'react'

function DetailsOfDirector() {
  return (
    <div>

<div className="container mt-5 mb-5">

<div className="row register" style={{ maxWidth: "100%" }}>
    <div className="col-md-12">
      <h3>
        Details of Director / Principal / Medical Superintendent :  
      </h3>
    </div>


    <div className="container">  
      <div className="alumni-area mt-4">
        <div className="row">
          <div className="col-md-12">

                <table
                  className="table table-bordered custom-table" style={{ height: '100px', maxWidth: "100%" , textAlign: "center" }}
                >
                    <thead style={{ height: '50px'}}>
                        <tr>
                            <th>NAME</th>
                            <th>DESIGNATION</th>
                            <th>REGISTRAION NO</th>
                            <th>DATE OF BIRTH</th>
                            <th>QUALIFICATION</th>
                            <th>DATE OF JOINING</th>
                            <th>EXPERIENCE</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>DR. DINESH RAO</td>
                            <td>Principal, Professor & HOD</td>
                            <td>19665</td>
                            <td>30-03-68</td>
                            <td>MD</td>
                            <td>14-11-22</td>
                            <td>20 YEARS</td>
                        </tr>
                    </tbody>
                </table>

          </div>
        </div>
      </div>
    </div>

</div>

</div>
      
    </div>
  )
}

export default DetailsOfDirector;
