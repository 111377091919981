import React from 'react'

function ViceChancellor() {
  return (
    <div>

<div className="container mt-5 mb-5">

<div className="row register" style={{ maxWidth: "100%" }}>
    <div className="col-md-12">
      <h3>
    	Vice-Chancellor:  
      </h3>
    </div>


    <div className="container">  
      <div className="alumni-area mt-4">
        <div className="row">
          <div className="col-md-12">

                <table
                  className="table table-bordered custom-table" style={{ height: '100px', maxWidth: "100%" , textAlign: "center" }}
                >
                    <thead style={{ height: '50px'}}>
                        <tr>
                            <th>NAME</th>
                            <th>DESIGNATION</th>
                            <th>DATE OF BIRTH</th>
                            <th>QUALIFICATION</th>
                            <th>DATE OF JOINING</th>
                            <th>EXPERIENCE</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>DR. M.S. RAO</td>
                            <td>Provost</td>
                            <td></td>
                            <td>MD, PhD, MBA</td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>

          </div>
        </div>
      </div>
    </div>

</div>

</div>
      
    </div>
  )
}

export default ViceChancellor;
