import React from "react";
import axios from "axios";
import { useState, useRef, useEffect } from "react";
import { COMPANIES_GET, PLACEMENTS_GET, STUDENT_INFO } from "../../Utils/InfoUploadingConst";

export default function PlacementDetails() {

  const [data, setData] = useState([]);
  const [companydata, setCompanyData] = useState([]);
  const [studentdata, setStudentData] = useState([]);

  const getData = async () => {
    const config = {
      method: 'get',
      url: PLACEMENTS_GET,
      headers: {
        'Content-Type': 'application/json',
      },
    };

    try {
      const res = await axios(config);
      // res.data.data.sort((a, b) => b.id - a.id);
      // setData(res.data.data);
      setData(res.data.data.filter(item => item.status === "ACTIVE" && item.college_id === 1111010));
      console.log("data -", data);
    } catch (err) {
      console.log(err);
    }

    const config2 = {
      method: 'get',
      url: COMPANIES_GET,
      headers: {
        'Content-Type': 'application/json',
      },
    };

    try {
      const res = await axios(config2);
      // res.data.data.sort((a, b) => b.id - a.id);
      // setCompanyData(res.data.data);
      setCompanyData(res.data.data.filter(item => item.status === "ACTIVE" && item.college_id === 1111010));
      console.log("companydata -", companydata);
    } catch (err) {
      console.log(err);
    }

    const config3 = {
      method: 'get',
      url: STUDENT_INFO,
      headers: {
        'Content-Type': 'application/json',
      },
    };

    try {
      const res = await axios(config3);
      // res.data.data.sort((a, b) => b.id - a.id);
      // setCompanyData(res.data.data);
      setStudentData(res.data.data.filter(item => item.status === "ACTIVE" && item.college_id === "1111010"));
      console.log("studentdata -", studentdata);
    } catch (err) {
      console.log(err);
    }

  };

  useEffect(() => {
    getData();
  }, []);


  return (
    <div className="PlacementDetails">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <br />
            <br />
            <br />
            <h1 className="text-center">Placement Details</h1>
            <br />
            <br />
            <div className="card border-0 shadow-sm">
              <div className="card-header d-flex justify-content-between ">
                {/* <h5>Company Name Here</h5>
                <h5>No of Placements - 10</h5> */}

              </div>
              <div className="card-body">
                <div className="container-fluid">
                  <div className="row">
                    {/* CARD START HERE  */}

                  { data?.map ((item,key) => {
                     return(                  
                    <div className="col-12 col-sm-6 col-lg-3" key={key}>
                      <div
                        className="single_advisor_profile wow fadeInUp"
                        data-wow-delay="0.3s"
                      >
                        <div className="advisor_thumb">
                          <img
                            // src="https://i.pravatar.cc/150?img=12"
                            src={`${
                              studentdata?.find(
                                (s) => s.id == item.student_id
                              )?.student_picture
                                ? studentdata?.find(
                                  (s) => s.id == item.student_id
                                )?.student_picture
                                : "https://i.pravatar.cc/150?img=12"
                            }`}
                            alt="vicepresident"
                            width="100%"
                          />
                        </div>
                        <div className="single_advisor_details_info">
                          <h6>
                            Name : 
                            {item?.student_name}
                            {/* {
                              studentdata?.find(
                                (s) => s.id == item.student_id
                              )?.name
                            } */}
                          </h6>

                          {/* <p className="designation">
                            <strong>Role Here</strong>
                          </p> */}

                          <p className="designation">
                            <strong>Company : 
                              {/* {item?.company}  */}
                              {
                                companydata?.find(
                                  (s) => s.id == item.company
                                )?.companyname
                              }
                            </strong>
                          </p>

                          <br />
                          <p className="text-center">
                            <strong>Package: {item?.package}</strong>
                          </p>
                          <p className="text-center">
                            <strong>Batch: {item?.year}</strong>
                          </p>

                        </div>
                      </div>
                    </div>
                     )
                    })
                  }

                    {/* CARD END HERE  */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
