import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// import "./NewsAnnoucementEvents.css";
import { UNIVERSITY_NOTIFICATIONS_GET } from "../../../Utils/InfoUploadingConst";
import axios from "axios";

export default function NotificationList() {
  let { type } = useParams();

  const [events, setEvents] = useState([]);

  const getData = async () => {
    const config1 = {
      method: "get",
      url: UNIVERSITY_NOTIFICATIONS_GET,
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios(config1)
      .then((res) => {
        console.log(res);
        res.data.data.forEach((element) => {
          element.attachments = JSON.parse(element.attachments);
        });
        const eventsData = res.data.data.filter(
          (element) => element.status === "ACTIVE"
        );
        console.log(eventsData);
        setEvents(eventsData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="text-center">
            <h1 className="mt-5">{type.toUpperCase()}</h1>
          </div>

          {type === "Announcements" || type === "News" || type === "Events" ? (
            <section className="content">
              <div className="announcement-slider border-r-xs-0 border-r position-relative">
                <div>
                  <ul className="nolist list-unstyled position-relative mb-0 px-lg-5 pt-lg-5">
                    {events
                      .filter((item) => item.type === type)
                      ?.map((d, key) => {
                        return (
                          <li className="border-bottom pb-3 mt-3">
                            <h4 className="font-weight-bold mt-0">
                              <a href={d.attachments}>{d.title}</a>
                            </h4>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
            </section>
          ) : (
            <>
              <h1 className="mt-5">No {type} Found</h1>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
