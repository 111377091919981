import React from "react";
import { Link } from "react-router-dom";
import "../Connect/Downloads/Brochure.scss";

function Events() {
  return (
    <div className="Events ">
      <div class="page-banner-area bg-3">
        <div class="container">
          <div class="page-banner-content">
            <h1>Events </h1>
            <ul>
              <li>
                {" "}
                <Link to={"./../"}> Home </Link>
              </li>

              <li>Events</li>
            </ul>
          </div>
        </div>
      </div>

      <div
        className="container-fluid register mt-3"
        style={{ maxWidth: "1800px" }}
      >
        <h3> Events</h3>
        <div className="row photo-gallery zoom-galler photos mt-3 ">
          <div className="col-lg-3 col-md-4 ">
            <h5>DOCTOR's DAY CELEBRATION 1st July 2023</h5>
            <div className="single-events-card style-3">
              <div className="events-image">
                <a
                  href="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/Homoeopathy_Assets/Events/InCollage_20230707_160842650.jpg"
                  data-lightbox="photos"
                >
                  <img
                    className="img-fluid"
                    src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/Homoeopathy_Assets/Events/InCollage_20230707_160842650.jpg"
                    alt="engineering poster"
                  />
                </a>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-4 ">
            <h5>
              Student Wing organised &quot;Inter college Homeopathic Quiz
              Competition for under graduate Homeopathic students&quot;.
            </h5>
            <div className="single-events-card style-3">
              <div className="events-image">
                <a
                  href="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/Homoeopathy_Assets/Events/IMG-20230702-WA0004.jpg"
                  data-lightbox="photos"
                >
                  <img
                    className="img-fluid"
                    src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/Homoeopathy_Assets/Events/IMG-20230702-WA0004.jpg"
                    alt="engineering poster"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="row photo-gallery zoom-galler photos mt-3 ">
          <div className="col-lg-3 col-md-4 ">
            <h5>DOCTOR's DAY CELEBRATION 1st July 2023</h5>
            <div className="single-events-card style-3">
              <div className="events-image">
                <a
                  href="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/Homoeopathy_Assets/Events/InCollage_20230707_160842650.jpg"
                  data-lightbox="photos"
                >
                  <img
                    className="img-fluid"
                    src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/Homoeopathy_Assets/Events/InCollage_20230707_160842650.jpg"
                    alt="engineering poster"
                  />
                </a>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-4 ">
            <h5>Cholera Event regarding the causes &amp; spread of Cholera</h5>
            <div className="single-events-card style-3">
              <div className="events-image">
                <a
                  href="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/Homoeopathy_Assets/Events/InCollage_20230707_155759591.jpg"
                  data-lightbox="photos"
                >
                  <img
                    className="img-fluid"
                    src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/Homoeopathy_Assets/Events/InCollage_20230707_155759591.jpg"
                    alt="engineering poster"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="row photo-gallery zoom-galler photos mt-3 ">
          <h4>Sports week Celebration "Aarohan 2k23"</h4> <br />
          <br />
          <br />
          <div className="col-lg-3 col-md-4 ">
            <div className="single-events-card style-3">
              <div className="events-image">
                <a
                  href="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/Homoeopathy_Assets/Events/InCollage_20230620_183326966.jpg"
                  data-lightbox="photos"
                >
                  <img
                    className="img-fluid"
                    src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/Homoeopathy_Assets/Events/InCollage_20230620_183326966.jpg"
                    alt="engineering poster"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 ">
            <div className="single-events-card style-3">
              <div className="events-image">
                <a
                  href="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/Homoeopathy_Assets/Events/InCollage_20230620_183028766.jpg"
                  data-lightbox="photos"
                >
                  <img
                    className="img-fluid"
                    src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/Homoeopathy_Assets/Events/InCollage_20230620_183028766.jpg"
                    alt="engineering poster"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="row photo-gallery zoom-galler photos mt-3 ">
          <br />
          <br />
          <br />
          <div className="col-lg-3 col-md-4 ">
            <h5> API Score Seminar by VC Sir 2022, SU</h5>
            <div className="single-events-card style-3">
              <div className="events-image">
                <a
                  href="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/Homoeopathy_Assets/Events/InCollage_20230620_180124897.jpg"
                  data-lightbox="photos"
                >
                  <img
                    className="img-fluid"
                    src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/Homoeopathy_Assets/Events/InCollage_20230620_180124897.jpg"
                    alt="engineering poster"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 ">
            <h5>
              {" "}
              Beat plastic pollution, Save water, Save trees, throw bottles /
              garbage in dustbins
            </h5>
            <div className="single-events-card style-3">
              <div className="events-image">
                <a
                  href="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/Homoeopathy_Assets/Events/InCollage_20230620_214137553.jpg"
                  data-lightbox="photos"
                >
                  <img
                    className="img-fluid"
                    src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/Homoeopathy_Assets/Events/InCollage_20230620_214137553.jpg"
                    alt="engineering poster"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 ">
            <h5>Sanskrit Day Celebration 2022, SSHC</h5>
            <div className="single-events-card style-3">
              <div className="events-image">
                <a
                  href="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/Homoeopathy_Assets/Events/InCollage_20230620_180749520.jpg"
                  data-lightbox="photos"
                >
                  <img
                    className="img-fluid"
                    src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/Homoeopathy_Assets/Events/InCollage_20230620_180749520.jpg"
                    alt="engineering poster"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 ">
            <h5>
              Shishyopnayan Sanskar for new batch of 1st year BHMS of the
              academic year 2023-2024, SSHC
            </h5>
            <div className="single-events-card style-3">
              <div className="events-image">
                <a
                  href="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/Homoeopathy_Assets/Events/InCollage_20230620_181521569.jpg"
                  data-lightbox="photos"
                >
                  <img
                    className="img-fluid"
                    src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/Homoeopathy_Assets/Events/InCollage_20230620_181521569.jpg"
                    alt="engineering poster"
                  />
                </a>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-4 ">
            <h5>HPV screening- Cervical Cancer Day Seminar</h5>
            <div className="single-events-card style-3">
              <div className="events-image">
                <a
                  href="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/Homoeopathy_Assets/Events/InCollage_20230620_182558976.jpg"
                  data-lightbox="photos"
                >
                  <img
                    className="img-fluid"
                    src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/Homoeopathy_Assets/Events/InCollage_20230620_182558976.jpg"
                    alt="engineering poster"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 ">
            <h5>World Homeopathy Day Celebration</h5>
            <div className="single-events-card style-3">
              <div className="events-image">
                <a
                  href="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/Homoeopathy_Assets/Events/InCollage_20230615_134457414.jpg"
                  data-lightbox="photos"
                >
                  <img
                    className="img-fluid"
                    src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/Homoeopathy_Assets/Events/InCollage_20230615_134457414.jpg"
                    alt="engineering poster"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="row photo-gallery zoom-galler photos ">
          <h2>Foundation Course 1st BHMS</h2> <br />
          <br />
          <br />
          <div className="col-lg-3 col-md-4 ">
            <div className="single-events-card style-3">
              <div className="events-image">
                <a
                  href="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/Homoeopathy_Assets/Events/IMG-20230408-WA0023.jpg"
                  data-lightbox="photos"
                >
                  <img
                    className="img-fluid"
                    src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/Homoeopathy_Assets/Events/IMG-20230408-WA0023.jpg"
                    alt="engineering poster"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4">
            <div className="single-events-card style-3">
              <div className="events-image">
                <a
                  href="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/Homoeopathy_Assets/Events/IMG-20230408-WA0030.jpg"
                  data-lightbox="photos"
                >
                  <img
                    className="img-fluid"
                    src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/Homoeopathy_Assets/Events/IMG-20230408-WA0030.jpg"
                    alt="engineering poster"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4">
            <div className="single-events-card style-3">
              <div className="events-image">
                <a
                  href="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/Homoeopathy_Assets/Events/IMG-20230408-WA0031.jpg"
                  data-lightbox="photos"
                >
                  <img
                    className="img-fluid"
                    src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/Homoeopathy_Assets/Events/IMG-20230408-WA0031.jpg"
                    alt="engineering poster"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4">
            <div className="single-events-card style-3">
              <div className="events-image">
                <a
                  href="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/Homoeopathy_Assets/Events/IMG-20230408-WA0037.jpg"
                  data-lightbox="photos"
                >
                  <img
                    className="img-fluid"
                    src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/Homoeopathy_Assets/Events/IMG-20230408-WA0037.jpg"
                    alt="engineering poster"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4">
            <div className="single-events-card style-3">
              <div className="events-image">
                <a
                  href="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/Homoeopathy_Assets/Events/IMG-20230408-WA0043.jpg"
                  data-lightbox="photos"
                >
                  <img
                    className="img-fluid"
                    src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/Homoeopathy_Assets/Events/IMG-20230408-WA0043.jpg"
                    alt="engineering poster"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4">
            <div className="single-events-card style-3">
              <div className="events-image">
                <a
                  href="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/Homoeopathy_Assets/Events/IMG-20230408-WA0045.jpg"
                  data-lightbox="photos"
                >
                  <img
                    className="img-fluid"
                    src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/Homoeopathy_Assets/Events/IMG-20230408-WA0045.jpg"
                    alt="engineering poster"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4">
            <div className="single-events-card style-3">
              <div className="events-image">
                <a
                  href="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/Homoeopathy_Assets/Events/IMG-20230408-WA0047.jpg"
                  data-lightbox="photos"
                >
                  <img
                    className="img-fluid"
                    src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/Homoeopathy_Assets/Events/IMG-20230408-WA0047.jpg"
                    alt="engineering poster"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4">
            <div className="single-events-card style-3">
              <div className="events-image">
                <a
                  href="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/Homoeopathy_Assets/Events/IMG-20230408-WA0049.jpg"
                  data-lightbox="photos"
                >
                  <img
                    className="img-fluid"
                    src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/Homoeopathy_Assets/Events/IMG-20230408-WA0049.jpg"
                    alt="engineering poster"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4">
            <div className="single-events-card style-3">
              <div className="events-image">
                <a
                  href="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/Homoeopathy_Assets/Events/IMG-20230408-WA0053.jpg"
                  data-lightbox="photos"
                >
                  <img
                    className="img-fluid"
                    src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/Homoeopathy_Assets/Events/IMG-20230408-WA0053.jpg"
                    alt="engineering poster"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4">
            <div className="single-events-card style-3">
              <div className="events-image">
                <a
                  href="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/Homoeopathy_Assets/Events/IMG-20230408-WA0053.jpg"
                  data-lightbox="photos"
                >
                  <img
                    className="img-fluid"
                    src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/Homoeopathy_Assets/Events/IMG-20230408-WA0053.jpg"
                    alt="engineering poster"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="row photo-gallery zoom-galler photos mt-3 ">
          <h2>CBDC for 1st BHMS Program by HEB, Ahmedabad</h2> <br />
          <br />
          <br />
          <div className="col-lg-3 col-md-4 ">
            <div className="single-events-card style-3">
              <div className="events-image">
                <a
                  href="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/Homoeopathy_Assets/Events/IMG-20230408-WA0050.jpg"
                  data-lightbox="photos"
                >
                  <img
                    className="img-fluid"
                    src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/Homoeopathy_Assets/Events/IMG-20230408-WA0050.jpg"
                    alt="engineering poster"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="row photo-gallery zoom-galler photos mt-3 ">
          <h2>Board of Studies SSHC, Swaminarayan University</h2> <br />
          <br />
          <br />
          <div className="col-lg-3 col-md-4 ">
            <div className="single-events-card style-3">
              <div className="events-image">
                <a
                  href="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/Homoeopathy_Assets/Events/IMG-20230408-WA0054.jpg"
                  data-lightbox="photos"
                >
                  <img
                    className="img-fluid"
                    src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/Homoeopathy_Assets/Events/IMG-20230408-WA0054.jpg"
                    alt="engineering poster"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Events;
