import React from 'react'

function MissionVisionMBBS() {

  const banner1 = 'https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/images/DJI_0449.JPG'

  return (
    <div className='AboutSwaminarayanUniversity'>
        <div className="graduate-admission pt-100 pb-70">
  <div className="container">
    <div className="row">
      <div className="col-lg-8">
        <div className="how-to-apply">
        <div className="submit">
            <h3>Vision</h3>
            <p>
             <ul>
              <li>
              To provide quality medical education to students, aspiring to become visionary
healthcare professionals in the culture that fosters academic excellence and
promotes research and innovation, while adopting holistic healthcare
approach.
              </li>
             </ul>
            </p>
          </div>
          <div className="financial">
            <h3>Mission</h3>
            <p>
             <ul>
              <li>
              We are committed to producing visionary doctors who have a zeal for
perpetual learning and are capable of performing well in diverse professional
and cultural settings.
              </li> <br />
              <li>
              While we provide the students with all the tools required to successfully take
on the world, we also endeavor in them a healthy cultural rootedness that
combines the best of traditional values with modern and progressive
sensibilities.
              </li>
             </ul>
            </p>
          </div>
        
        </div>
      </div>
      <div className="col-lg-4">
        <div className="additional-information">
          <h3 className='text-center'>Swaminarayan Institute of Medical Sciences and Research, Kalol</h3>
          <img src={banner1} />
        </div>
      </div>
    </div>
  </div>
</div>

    </div>
  )
}

export default MissionVisionMBBS