import React from "react";

export default function Labs() {
  return (
    <div>
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-md-12">
            <p>
              <span style={{ fontSize: "18pt" }}>
                <strong>Labs</strong>
              </span>
              <br />
            </p>

            <div className="card mb-3">
              <div className="row no-gutters">
                <div className="col-md-8">
                  <div className="card-body pt-5 ">
                    <h4 className="card-title mb-3">Physiology Lab</h4>
                    <p className="card-text">
                      State-of-the art Laboratory with high quality instruments
                      and equipment is established for in-depth study of all
                      Laboratory/ Clinical instruments. The Lab also conducts 11
                      Hematology experiments and gives hands-on training for all
                      Clinical Examination on patients including ECG
                      examination. The Lab is established in adquate space so as
                      to accomodate a batch of 30 students at a time. The
                      students are trained to gain skills in conducting all
                      Laboratory tests and Clinical examinations under expert
                      Faculty.
                    </p>
                  </div>
                </div>

                <div className="col-md-4">
                  <img
                    src="/assets/ayurveda/images/physiology-lab.jpg"
                    style={{'height':'100%','width':'100%','object-fit':'fill'}}
                    alt="..."
                  />
                </div>
              </div>
            </div>

            <div className="card mb-3">
              <div className="row no-gutters">
                <div className="col-md-4">
                  <img
                    src="/assets/ayurveda/images/dravyaguna-lab.jpg"
                    style={{'height':'100%','width':'100%','object-fit':'fill'}}
                    alt="..."
                  />
                </div>
                <div className="col-md-8">
                  <div className="card-body pt-5 ">
                    <h4 className="card-title mb-3">Dravyaguna Lab</h4>
                    <p className="card-text">
                      The Department of Dravyaguna is well equipped with a
                      Pharmacognosy laboratory .The Laboratory has the
                      instruments and chemicals required for the pharmacognosy
                      practicals. As per the norms 10 -15 practicals are formed
                      in pharmacognosy for each batch. The Students are trained
                      in taking transverse sections of various parts of plant
                      like root, stem, leaf and observing the characters under
                      the microscope and differentiating with the use of various
                      stains. Thus the students are made familiar with the
                      macroscopic and microscopic identity of plants.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="card mb-3">
              <div className="row no-gutters">
                <div className="col-md-8">
                  <div className="card-body pt-5 ">
                    <h4 className="card-title mb-3">Dissection Hall</h4>
                    <p className="card-text">
                      The Dissection hall is well equipped with adequate
                      infrastructure and has regular availability of cadavers
                      each year with 15 dissection sets for 12 dissection tables
                      and sufficient X-ray boxes to develop the skills of
                      dissecting, identifying the bones and organs structures of
                      the body. It has ample informative material with 32
                      posters, 30 wet specimens, 30 models, 3 disarticulated
                      sets to study normal disposition, interrelationships,
                      gross, functional and applied anatomy of the various
                      structures in the body.
                    </p>
                  </div>
                </div>

                <div className="col-md-4">
                  <img
                    src="/assets/ayurveda/images/dissection-hall.jpg"
                    style={{'height':'100%','width':'100%','object-fit':'fill'}}
                    alt="..."
                  />
                </div>
              </div>
            </div>

            <div className="card mb-3">
              <div className="row no-gutters">
                <div className="col-md-4">
                  <img
                    src="/assets/ayurveda/images/pathology-lab.jpg"
                    style={{'height':'100%','width':'100%','object-fit':'fill'}}
                    alt="..."
                  />
                </div>
                <div className="col-md-8">
                  <div className="card-body pt-5 ">
                    <h4 className="card-title mb-3">Pathology Lab</h4>
                    <p className="card-text">
                      Pathology Lab is well equipped with all modern laboratory
                      eqyuipment and instruments to conduct various
                      investigations. All blood tests, Urine tests, Sputum,
                      Stool and Semen investigations are being trained to the
                      students by well versed Faculties. The Lab practical
                      teching sessions are conducted in Batch of 30 students
                      each so as to enable them to get proper training and
                      ensure that every student gets to participate in every
                      single practicle. The Lab serves as a place for students
                      to get a practical ap-proch of the Pathology and aquiring
                      sound knowledge.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="card mb-3">
              <div className="row no-gutters">
                <div className="col-md-8">
                  <div className="card-body pt-5 ">
                    <h4 className="card-title mb-3">
                      Rasashastra & Bhaishajya Lab
                    </h4>
                    <p className="card-text">
                      Rasashastara and Bhaishajya Kalpana Lab is equipped with
                      all the instruments and equipment requiered to train
                      students in Ayurveda drug manufacturing. Yearly each batch
                      is given hands on training of around 90 practicals. The
                      same training is very useful for students for designing
                      and manufacturing their own medicine in clinical practice.
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <img
                    src="/assets/ayurveda/images/rasashastra-lab.jpg"
                    style={{'height':'100%','width':'100%','object-fit':'fill'}}
                    alt="..."
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
