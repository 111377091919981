import React, { useEffect, useState } from "react";
import "./examform.scss";
import { STUDENT_EXAM_DETAILS } from "../../Utils/apiConst";
import { useParams } from "react-router-dom";
import { Http } from "../../Services/Services";
import { toast } from "react-toastify";
import ProgressBar from "../../Components/ProgressBar/ProgressBar";
import moment, { months } from "moment";
import {
  LOCAL_COLLEGE,
  LOCAL_DEPARTMENT,
} from "../../Utils/LocalStorageConstants";
import { departmentData } from "../../Data/admissionData";
import {
  ACADEMICS_ADD_CLASS,
  ACADEMICS_ADD_SEMESTER,
} from "../../Utils/Academics.apiConst";

export default function ExamApplicationForm() {
  const [data, setData] = useState({});

  const today = moment().format("DD/MM/YYYY");
  const collegeData = JSON.parse(localStorage.getItem(LOCAL_COLLEGE));
  const DepartmentData = JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT));

  const [classData, setClassData] = useState({});
  const [semesterData, setSemesterData] = useState({});

  const params = useParams();

  const getData = async () => {
    console.log("here");
    // setLoading(true);
    await Http.get(STUDENT_EXAM_DETAILS + "/" + params.id)
      .then(async (res) => {
        console.log(res.data);
        setData(res.data);
        if (res.data.exam) {
          const [classD, semesterData] = await Promise.all([
            Http.get(ACADEMICS_ADD_CLASS + "/" + res.data.exam.class_id).catch(
              (err) => {
                console.log(err);
              }
            ),
            Http.get(
              ACADEMICS_ADD_SEMESTER + "/" + res.data.exam.semester_id
            ).catch((err) => {
              console.log(err);
            }),
          ]);
          setClassData(classD.data.data);
          setSemesterData(semesterData.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response?.data?.message || "Something went wrong");
      });

    // setLoading(false);
  };

  const handlePrint = () => {
    window.print();
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <section className="m-1 p-4">
        <div className="d-print-none">
          <ProgressBar value={2} />
        </div>
        <div className="row d-flex align-items-center justify-content-center">
          <div className="col-sm-2">
            <img
              src="/assets/images/favicon.png"
              className="logo "
              alt="logo"
              style={{
                width: "100%",
              }}
            />
          </div>
          <div className="col-sm-6">
            <h5 className="text-center ">
              SWAMINARAYAN UNIVERSITY
              <br />
              EXAMINATION FORM
            </h5>
          </div>
        </div>
        <br />

        <div className="row mb-4">
          <div className="col-sm-9">
            <strong>
              Instructions: <b></b>
            </strong>
            <ol style={{ fontSize: "0.75rem" }}>
              {/* <li>
                This Examination form should be filled by the candidate in
                his/her own handwriting
              </li> */}
              <li>Use Capital Letters in Boxes.</li>
              <li>
                If application is not on prescribed form or incomplete, it will
                be rejected.
              </li>
              <li>
                Separate examination form should be filling for each Semester/
                Exam
              </li>
              <li>
                ABC ID (Mandatory): <b></b> (ID HERE COMPULSARY)
              </li>
            </ol>
          </div>

          <div className="col-sm-3 d-flex justify-content-end">
            <div
              className="img-container text-center d-flex align-items-center justify-content-center"
              style={{
                border: "1.5px solid black",
                width: "60%",
                height: "80%",
              }}
            >
              Student Image
            </div>
          </div>
        </div>

        <hr />

        <div className="row d-flex justify-content-around">
          <p className="col-sm-6 text-center">
            Examination : {months(data?.commitee?.month - 1 || 0)}{" "}
            <b>{data?.commitee?.year}</b>
          </p>
        </div>

        <div className="row">
          <p className="col-sm-12 text-center">
            Are you Regular or Repeater : <b></b>
          </p>
        </div>
        <br />

        <div className="row">
          <div className="col-sm-4">
            <p>
              Semester / Year:{" "}
              <b>
                {semesterData?.name} / {classData?.name}
              </b>{" "}
            </p>
          </div>
          <div className="col-sm-4">
            <p>
              Course:{" "}
              {
                collegeData?.find((s) => s.id == data?.student?.college_id)
                  ?.name
              }{" "}
              <b></b>
            </p>
          </div>
          <div className="col-sm-4">
            <p>
              Branch Name:{" "}
              {
                departmentData?.find(
                  (s) => s.id == data?.student?.department_id
                )?.name
              }
              <b></b>
            </p>
          </div>
        </div>

        <div className="col-sm-12">
          <p>
            Enrollment Number: {data?.student_id}
            <b></b>{" "}
          </p>
        </div>

        <br />
        <h6>
          Name of the Examinee : {data?.student?.name}
          <b></b>
        </h6>

        {/* <div className="col-sm-12">
          <p>Surname: <b></b></p>
        </div> */}

        <div className="col-sm-12">
          <p>
            Full Name: {data?.student?.name}
            <b></b>{" "}
          </p>
        </div>

        <div className="col-sm-12">
          <p>
            Father's Name: {data?.student?.father_name}
            <b></b>
          </p>
        </div>

        <div className="col-sm-12">
          <p>
            Mother's Name: {data?.student?.mother_name}
            <b></b>
          </p>
        </div>

        <div className="col-sm-12">
          <p>
            Address: {data?.student?.address}
            <b></b>
          </p>
        </div>

        <div className="col-sm-12">
          <p>
            Mobile No / Telephone: {data?.student?.phone}
            <b></b>
          </p>
        </div>

        <br />
        <h6>
          Mention Subjects (Theory & Practical in which appearing with Code No.)
        </h6>

        <div className="col-sm-12">
          <p>
            Appearing in Semester / Year: <b></b> {semesterData?.name} /{" "}
            {classData?.name} All Theory / Practical / Sessional
          </p>
        </div>

        <div className="col-sm-12">
          <table className="table table-bordered">
            <thead>
              {/* <tr className="text-center">
                <th scope="col" colSpan={3}>
                  Theory
                </th>
                <th scope="col" colSpan={3}>
                  Practical
                </th>
              </tr> */}
              <tr className="text-center">
                <th scope="col">SN</th>
                <th scope="col">Subject Code</th>
                <th scope="col">Title of Subject</th>
                <th scope="col">Type</th>
              </tr>
            </thead>
            <tbody className="text-center">
              {data?.enrollmentData?.map((i, key) => (
                <tr>
                  <td>{key + 1}</td>
                  <td>
                    {data?.courseData?.find((s) => s.id == i.subject)?.code}
                  </td>
                  <td>
                    {data?.courseData?.find((s) => s.id == i.subject)?.name}
                  </td>
                  <td>
                    {data?.courseData?.find((s) => s.id == i.subject)?.type}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <br />
        <h6 className="mt-5">
          Candidate's Declaration: <b></b>
        </h6>
        <ol>
          <li>
            I certify that this examination form has been filled by me and the
            information given therein is correct and I shall be personally
            responsible for the same.
          </li>
          <li>
            I understand that if it is found later on that the information
            furnished above is false then my result of examination will be
            cancelled.
          </li>
          <li>
            I assure you that I will complete the requirement of attendance and
            sessional work prescribed for the course of my registration. Kindly
            permit me to appear in the examination to be held in (sem.)
            …………………………………………… I accept to abide by all the rules and regulations
            of study and examination as prescribed by the Dept./institution and
            Swaminarayan University, kalol (Gujarat)
          </li>
        </ol>

        <div className="row d-flex">
          <div className="col-sm-6">
            <p>
              Place: <b></b> Gujurat
              <br />
              Date: <b></b> {today}
            </p>
          </div>

          <div className="col-sm-6">
            <p>
              Candidate's Signature: <b></b>
              <br />
              Candidate's Full Name: {data?.student?.name}
              <b></b>
            </p>
          </div>
        </div>

        <hr />
        <br />
        <h6 className="text-center">
          (To be certified by the Dean of Faculty / HOD)
        </h6>
        <br />
        <h6>
          CERTIFIED THAT: <b></b>
        </h6>
        <ol>
          <li>
            The entries in the examination form have been examined and verified
            properly and found correct. The candidate is eligible to appear in
            the examination as per relevant ordinance and rules of Swaminarayan
            University, kalol (Gujarat).
          </li>
          <li>
            The foresaid candidate is not debarred from appearing at the above
            examination (Due to rustication, expulsion, attendance, practical’s,
            illness etc.) and has completed the academic requirement as per
            ordinance of the University.
          </li>
          <li>
            If due to any reason the student does not complete the academic
            requirement up to the time of exam his admission card will be
            returned to the University and the candidate will be debarred from
            appearing in the examination.
          </li>
          <li>
            Attendance of the candidate till now is above 75% of total classes
            held. If its attendance falls below 75% up to the time of
            commencement of examination, his admit card will be returned to
            examination section with information.
          </li>
        </ol>
        <br />
        <div className="row">
          <div className="col-sm-4 ">
            <br />
            <p>
              Date: <b></b>
              {today}
            </p>
          </div>
          <div className="col-sm-4"></div>
          <div className="col-sm-4 text-center">
            <hr />
            <p>
              Name & Signature
              <br />
              (Dean of faculty or HOD)
            </p>
          </div>
        </div>
      </section>

      <div className="row p-5">
        <div className="col-sm-12">
          <button
            className="btn btn-danger d-print-none float-right"
            onClick={handlePrint}
          >
            Print
          </button>
        </div>
      </div>
    </>
  );
}
