import React from "react";
import axios from "axios";
import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../Router/RouterConfig";
import "../Connect/Downloads/Brochure.scss";
import { LOCAL_EMPLOYEE } from "../../Utils/LocalStorageConstants";
import { STAFF_DATA } from "../../Utils/apiConst";

function NonTeachingStaffArts() {
  const navigate = useNavigate();

  const [data, setData] = useState([]);

  const [employeeData, setEmployeeData] = useState([]);

  async function fetchData() {
    const college_id = "1111004";
    await axios
      .get(`${STAFF_DATA}?college_id=${college_id}&teaching_type=NON_TEACHING&status=ACTIVE`)
      .then((res) => {
        setEmployeeData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  useEffect(() => {
    fetchData();
  }, []);

  function formatDate(dateString) {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    const formattedDate = new Date(dateString).toLocaleDateString(
      "en-US",
      options
    );
    return formattedDate;
  }

  const roleMapping = {
    ASSTPR: "Assistant Professor",
    ASPR: "Associate Professor",
    ASSTLIB: "Assistant Librarian",
    Attend: "Attender",
    AdmCoun: "Admission Counsellor",
    BME: "BioMedical Engineer",
    COE: "Controller Of Exams",
    CLERK: "Clerk",
    DMS: "Deputy Medical Superintendent",
    DOFAC: "Dean Of Faculty (Diploma)",
    DOFUGPG: "Dean Of faculty (UG & PG)",
    JR: "Junior Resident",
    JRF: "Junior Research Fellow",
    LA: "Lab Assistant",
    MO: "Medical Officer",
    PROF: "Professor",
    Phy: "Physiotherapist",
    PA: "Panchakarma Assistant",
    Recep: "Receptionist",
    REG: "Registrar",
    SR: "Senior Resident",
    Tut: "Tutor",
    READ: "Reader",
    OFFE: "Office Executive",
    VP: "Vice Principal",
    PRO: "Provost",
    LIB: "Librarian",
    ACCNT: "Accountant",
    CC: "Clerk Cum Cashier",
    P: "Peon",
    HOD: "Head of The Department",

    SUACC: "SUACC",
    IT: "IT",

    Dean: "Dean/Principal",
    Mas: "Massure",
    "IT Cell": "Website Coordinator",
    Amission: "Admission Officer",
    SACC: "Sr Accountant",
    NET: "Network Administrator",
    "Exe-HR": "Executive-Human Resources",
    "H-HR": "Assistant Manager-Human Resource",
    SECOR: "Security Officer",
    PROC: "Proctor",
    SOPS: "Section Officer-Purchase & Stores",
    DIRP: "Director Placements",
    CWAR: "Chief Warden",
    DENIQSC: "Dean IQSC",
    DENSW: "Dean Student Welfare",
    DERES: "Dean Research",
    DIA: "Director International Affairs",
    DREGAC: "Deputy Registrar(Accreditation & Compliance)",
    DREGAD: "Deputy Registrar(Admissions and Admin)",
    EXAM: "Examiner",
    FOP: "Finance Officer",
    HITS: "Head IT Services",
    MANTAIN: "Mantainance Engineer",
  };

  return (
    <div className="GoverningBody">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <br />
            <br />
            <br />
            <h1 className="text-center">Non-Teaching Staff</h1>
            <br />
            <br />
            <div className="card border-0 shadow-sm">
              <div className="card-body">
                <div className="container">
                  <div className="row">
                    <div className="events-area pt-100 pb-70">
                      <div className="container d-flex">
                        <div className="row justify-content-center">
                          {employeeData.map((item, key) => {
                            return (
                              <>
                                <div className="col-12 col-sm-6 col-lg-3">
                                  <div
                                    className="single_advisor_profile wow fadeInUp"
                                    data-wow-delay="0.3s"
                                  >
                                    <div className="advisor_thumb">
                                      {item?.photo ? (
                                        <img
                                          className="eventimage"
                                          src={item.photo}
                                          alt="Image"
                                        />
                                      ) : (
                                        <div className="logo">
                                          <a href="/">
                                            <img
                                              src="/assets/images/white-logo.png"
                                              className="white-logo"
                                              alt="logo"
                                            />
                                          </a>
                                        </div>
                                      )}
                                    </div>

                                    <div className="single_advisor_details_info">
                                      <h6>Name: {item.name || "---"}</h6>

                                      <p className="text-center">
                                        <strong>
                                          Designation:{" "}
                                          {roleMapping[item.role] || item.role || "--"}
                                        </strong>
                                      </p>

                                      { item?.staff_id ?
                                      <p className="text-center">
                                        <strong>
                                          Registration No : {item.staff_id || "--"}{" "}
                                        </strong>
                                      </p>
                                      : "" }

                                      { item?.date_of_birth ?
                                      <p className="text-center">
                                        <strong>
                                          Date of Birth : {item?.date_of_birth || "--"}{" "}
                                        </strong>
                                      </p>
                                      : "" }

                                      { item?.qualification ?
                                      <p className="text-center">
                                        <strong>
                                          Qualification : {item?.qualification || "--"}{" "}
                                        </strong>
                                      </p>
                                      : "" }

                                      <p className="text-center">
                                        <strong>
                                          Date of Joining : {item.date_of_joining || "--"}{" "}
                                        </strong>
                                      </p>

                                      <br />
                                      {/* <p className="text-center">
                                        <strong>
                                          Department:
                                          {item.department_id || "--"}
                                        </strong>
                                      </p> */}
                                      <p className="text-center">
                                        <strong>
                                          Experience: {item.experience || "--"}
                                        </strong>
                                      </p>

                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NonTeachingStaffArts;
