import React from "react";

export default function ArtsFeeStructure() {
  return (
    <div>
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-md-12">
            <p>
              <span style={{ fontSize: "18pt" }}>
                <strong>IT & Computer Science Fee Structure</strong>
              </span>
            </p>

            <p>
              <strong>All Fee Structure Details:</strong>
            </p>
            <table
              className="table table-bordered"
              style={{ borderCollapse: "collapse", width: "100%", height: 173 }}
              border={1}
            >
              <tbody>
                <tr style={{ height: 20 }}>
                  <td>
                    <strong>Sr No</strong>
                  </td>
                  <td>
                    <strong>Course Name</strong>
                  </td>
                  <td>
                    <strong>Fees</strong>
                  </td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td>1</td>
                  <td>BCA</td>
                  <td>
                    25,000/- Yearly
                  </td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td>2</td>
                  <td>MCA</td>
                  <td>50,000/- Yearly</td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td>3</td>
                  <td>B.Sc. IT</td>
                  <td>
                    25,000/- Yearly
                  </td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td>4</td>
                  <td>M.Sc. IT</td>
                  <td>
                    50,000/- Yearly
                  </td>
                </tr>
                
              </tbody>
            </table>

           
          </div>
        </div>
      </div>
    </div>
  );
}
