import React from "react";

const principalImage = "../assets/science/principal-science.jpg";

function PrincipalMessage() {
  return (
    <div>
      <>
        {/* Start For Content */}
        <div className="container mt-5 mb-5">
          <div className="row">
            <div className="col-md-3">
              <img
                src={principalImage}
                alt="pricipal-image"
                className="img-responsive img-thumbnail"
                width={261}
                height={261}
              />
              <br />
              <br />

              <h3>
                Dr Pradeep U Verma
                <br />
              </h3>
              <p style={{}}>
              Dean & Principal [Shree Swaminarayan Science College]<br />
              Faculty of Science<br />
              Swaminarayan University <br />
              </p>
            </div>
            <div className="col-md-9">
              <h2>
                Principal's Message
                <br />
              </h2>
              <p style={{ textAlign: "justify" }}>
                Welcome to Shree Swaminarayan Science College official website!
                I am delighted to have this opportunity to connect with you and
                share our vision, mission, and values as an esteemed institution
                of scientific education and research.
                <br />
                <br />
                At Shree Swaminarayan Science College, we believe in the power
                of science to shape a better world. Our college stands as a
                beacon of knowledge, innovation, and excellence, dedicated to
                nurturing the next generation of scientists, researchers, and
                leaders. We take pride in offering a diverse range of programs
                and opportunities that cater to the ever-evolving needs of the
                scientific community.
                <br />
                <br />
                As the principal of Shree Swaminarayan Science College, I am
                honoured to lead a team of dedicated educators and professionals
                who are committed to providing a transformative learning
                experience. Our faculty members are renowned experts in their
                respective fields, fostering a rich and stimulating academic
                environment. They are passionate about mentoring students and
                guiding them on their path towards scientific discovery.
                <br />
                <br />
                Research lies at the heart of our college, and we encourage our
                students to actively engage in hands-on research projects,
                pushing the boundaries of knowledge in their chosen fields.
                Through our state-of-the-art laboratories, cutting-edge
                technology, and collaborative partnerships, we offer an
                environment that nurtures curiosity, critical thinking, and
                innovation.
                <br />
                <br />
                We firmly believe in the importance of interdisciplinary
                collaboration, recognizing that the most significant
                breakthroughs often arise at the intersections of different
                scientific disciplines. Our curriculum encourages students to
                explore diverse areas of study, enabling them to develop a
                holistic understanding of science and its applications.
                <br />
                <br />
                In addition to academic rigor, we are committed to fostering an
                inclusive and supportive community. We embrace diversity in all
                its forms and value the unique perspectives and experiences that
                each individual brings. We strive to create an environment that
                is equitable, respectful, and free from discrimination, allowing
                every student to thrive and reach their full potential.
                <br />
                <br />
                I extend my deepest gratitude to all our students, faculty,
                staff, and partners for their unwavering dedication and
                commitment to scientific excellence. Together, we can make a
                profound impact on society and contribute to solving the
                pressing challenges of our time.
                <br />
                <br />
                Thank you for visiting Shree Swaminarayan Science College
                website. We look forward to welcoming you to our vibrant and
                inspiring scientific community.
              </p>
            </div>
          </div>
          <br />
          <br />
        </div>{" "}
        {/* End For Content */}
      </>
    </div>
  );
}

export default PrincipalMessage;
