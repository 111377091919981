import React from 'react'
import '../Arts/OfficerProfile.scss'

function VicePresident3Physio() {

    const data = {
        quote : "Creativity is just connecting things. When you ask creative people how they did something, they feel a little guilty because they didn't really do it, they just saw something. It seemed obvious to them after a while. That's because they were able to connect experiences they've had and synthesize new things."
    }

  return (
    <div>
      <>
      <div className="container mt-5 mb-5">
      <h1 className="text-center">Profile</h1>
      <br/>
      <div className="row">
        <div className="col-md-4">
            <br/>
            <img
              src="../assets/images/academics/vicepresident-2.jpg"
              alt=""
              className="img-responsive img-thumbnail"
              width={400}
              height={220}
            />
            <br />
            <br />
       
        </div>
        <div className="col-md-8">
          {/* <h2>
          Vice President
            <br />
          </h2> */}
          <h4 className="text-danger text-bold">Swami Bhaktinandandasji</h4>
          <h6 className="text-dark">Vice President</h6>
          <p style={{ textAlign: "justify" }}>
          He is young and energetic and has experience of being associated with the Trust since the last many years, in administration of the various educational institutes attached with the Trust. He has done his Bachelors (B.A.) in Sanskrit and at present pursuing M.A. He is a member of the Institutes run by Shree Swaminarayan Vishvamangal Gurukul, Kalol. His present responsibilities in the Trust and University are Administration and recruitment of faculty, implement the policies formulated by Shree Swaminarayan Vishvamangal Gurukul, Kalol, ensure the recruitment and retention of well-motivated, trained and developed staff to ensure that it achieves the mission and objectives. He also ensures institutional effectiveness through professional management of the College.
          </p>
          <p style={{ textAlign: "justify" }}>
          He annually evaluates the performances of employees and approves the annual budget. Managing and monitoring of implementation of the resource allocation process within the institute. He is also a member of the Governing Body .
          </p>
          <var></var>
        </div>
      </div>
      {/* <br /> */}
      {
      data?.quote&&
    <div className="row justify-content-center align-content-center mt-5">
      <div className="col-md-12">
        <blockquote className="otro-blockquotes">
            <h4> Message -</h4>
            <h6 className="ml-5">{data?.quote}</h6>
          <span className="text-dark">- "Swami Bhaktinandandasji"</span>
        </blockquote>
      </div>
    </div>
    }
      
      </div>
      </>
    </div>
  )
}

export default VicePresident3Physio;
