import React from "react";

function FeeStructure() {
  return (
    <div>
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-md-12">
            <p>
              <strong>FEE STRUCTURE</strong>&nbsp; - Govt. Fee Regulation
              Committee(FRC) Approved - Academic year (2022-2023)
              <br />
            </p>

            {/* B.Sc Start  */}
            <h4 style={{ margin: "35px 0 15px 0" }}>B.Sc. (Honours) Fees</h4>
            <table
              className="table table-bordered"
              style={{ borderCollapse: "collapse", width: "100%", height: 40 }}
              border={1}
            >
              <tbody>
                <tr style={{ height: 20 }}>
                  <td style={{ width: "20%", height: 20 }}>
                    <span style={{ color: "#000000" }}>
                      <strong>Semester</strong>
                    </span>
                    <br />
                  </td>
                  <td style={{ width: "20%", height: 20 }}>
                    <span style={{ color: "#000000" }}>
                      <strong>Tution Fees Per Semester</strong>
                    </span>
                    <br />
                  </td>
                  <td style={{ width: "20%", height: 20 }}>
                    <span style={{ color: "#000000" }}>
                      <strong>Other Fees per Semester</strong>
                    </span>
                    <br />
                  </td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td style={{ width: "20%", height: 20 }}>
                    Semester 1
                    <br />
                  </td>
                  <td style={{ width: "20%", height: 20 }}>
                    Rs. 12,500/-
                    <br />
                  </td>
                  <td style={{ width: "20%", height: 20 }}>Rs. 3,400/-</td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td style={{ width: "20%", height: 20 }}>
                    Semester 2
                    <br />
                  </td>
                  <td style={{ width: "20%", height: 20 }}>
                    Rs. 12,500/-
                    <br />
                  </td>
                  <td style={{ width: "20%", height: 20 }}>Rs. 500/-</td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td style={{ width: "20%", height: 20 }}>
                    Semester 3
                    <br />
                  </td>
                  <td style={{ width: "20%", height: 20 }}>
                    Rs. 12,500/-
                    <br />
                  </td>
                  <td style={{ width: "20%", height: 20 }}>Rs. 1,500/-</td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td style={{ width: "20%", height: 20 }}>
                    Semester 4
                    <br />
                  </td>
                  <td style={{ width: "20%", height: 20 }}>
                    Rs. 12,500/-
                    <br />
                  </td>
                  <td style={{ width: "20%", height: 20 }}>Rs. 500/-</td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td style={{ width: "20%", height: 20 }}>
                    Semester 5
                    <br />
                  </td>
                  <td style={{ width: "20%", height: 20 }}>
                    Rs. 12,500/-
                    <br />
                  </td>
                  <td style={{ width: "20%", height: 20 }}>Rs. 1,500/-</td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td style={{ width: "20%", height: 20 }}>
                    Semester 6
                    <br />
                  </td>
                  <td style={{ width: "20%", height: 20 }}>
                    Rs. 12,500/-
                    <br />
                  </td>
                  <td style={{ width: "20%", height: 20 }}>Rs. 500/-</td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td style={{ width: "20%", height: 20 }}>
                    Semester 7
                    <br />
                  </td>
                  <td style={{ width: "20%", height: 20 }}>
                    Rs. 12,500/-
                    <br />
                  </td>
                  <td style={{ width: "20%", height: 20 }}>Rs. 1,500/-</td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td style={{ width: "20%", height: 20 }}>
                    Semester 8
                    <br />
                  </td>
                  <td style={{ width: "20%", height: 20 }}>
                    Rs. 12,500/-
                    <br />
                  </td>
                  <td style={{ width: "20%", height: 20 }}>Rs. 500/-</td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td style={{ width: "20%", height: 20 }}>
                    Exam Fees + Exam Form Fees
                    <br />
                  </td>
                  <td style={{ width: "20%", height: 20 }}>
                    -
                    <br />
                  </td>
                  <td style={{ width: "20%", height: 20 }}>
                    Rs. 1,350/- Per Semester
                  </td>
                </tr>
              </tbody>
            </table>

            {/* B.Sc Other Fees  */}
            <h4 style={{ margin: "35px 0 15px 0" }}>B.Sc. Other Fees Detail</h4>
            <table
              className="table table-bordered"
              style={{ borderCollapse: "collapse", width: "100%", height: 40 }}
              border={1}
            >
              <tbody>
                <tr style={{ height: 20 }}>
                  <td style={{ width: "20%", height: 20 }}>
                    <span style={{ color: "#000000" }}>
                      <strong>Fee Type</strong>
                    </span>
                    <br />
                  </td>
                  <td style={{ width: "20%", height: 20 }}>
                    <span style={{ color: "#000000" }}>
                      <strong>Amount</strong>
                    </span>
                    <br />
                  </td>
                  <td style={{ width: "20%", height: 20 }}>
                    <span style={{ color: "#000000" }}>
                      <strong>Duration</strong>
                    </span>
                    <br />
                  </td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td style={{ width: "20%", height: 20 }}>
                    Id Card Fees
                    <br />
                  </td>
                  <td style={{ width: "20%", height: 20 }}>
                    Rs. 100/-
                    <br />
                  </td>
                  <td rowSpan="5" style={{ width: "20%", height: 20 }}>
                    Only Once during the time of Admission
                  </td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td style={{ width: "20%", height: 20 }}>
                    Insurance
                    <br />
                  </td>
                  <td style={{ width: "20%", height: 20 }}>
                    Rs. 100/-
                    <br />
                  </td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td style={{ width: "20%", height: 20 }}>
                    Admission Form
                    <br />
                  </td>
                  <td style={{ width: "20%", height: 20 }}>
                    Rs. 100/-
                    <br />
                  </td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td style={{ width: "20%", height: 20 }}>
                    Enrolment Fees
                    <br />
                  </td>
                  <td style={{ width: "20%", height: 20 }}>
                    Rs. 200/-
                    <br />
                  </td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td style={{ width: "20%", height: 20 }}>
                    Development Fees
                    <br />
                  </td>
                  <td style={{ width: "20%", height: 20 }}>
                    Rs. 12,00/-
                    <br />
                  </td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td style={{ width: "20%", height: 20 }}>
                    Sports Fees
                    <br />
                  </td>
                  <td style={{ width: "20%", height: 20 }}>Rs. 500/-</td>
                  <td rowSpan="2" style={{ width: "20%", height: 20 }}>
                    Per Year
                  </td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td style={{ width: "20%", height: 20 }}>
                    Library Fees
                    <br />
                  </td>
                  <td style={{ width: "20%", height: 20 }}>Rs. 500/-</td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td style={{ width: "20%", height: 20 }}>
                    E-Suvidha
                    <br />
                  </td>
                  <td style={{ width: "20%", height: 20 }}>
                    Rs. 500/-
                    <br />
                  </td>
                  <td style={{ width: "20%", height: 20 }}>
                    Per Semester
                    <br />
                  </td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td style={{ width: "20%", height: 20 }}>
                    <strong style={{ color: "#000000" }}>Total</strong>
                    <br />
                  </td>
                  <td style={{ width: "20%", height: 20 }}>
                    <strong style={{ color: "#000000" }}>Rs. 3,400/-</strong>
                    <br />
                  </td>
                </tr>
              </tbody>
            </table>

            {/* M.Sc START  */}
            <h4 style={{ margin: "35px 0 15px 0" }}>M.Sc. Fees</h4>
            <table
              className="table table-bordered"
              style={{ borderCollapse: "collapse", width: "100%", height: 40 }}
              border={1}
            >
              <tbody>
                <tr style={{ height: 20 }}>
                  <td style={{ width: "20%", height: 20 }}>
                    <span style={{ color: "#000000" }}>
                      <strong>Semester</strong>
                    </span>
                    <br />
                  </td>
                  <td style={{ width: "20%", height: 20 }}>
                    <span style={{ color: "#000000" }}>
                      <strong>Tution Fees Per Semester</strong>
                    </span>
                    <br />
                  </td>
                  <td style={{ width: "20%", height: 20 }}>
                    <span style={{ color: "#000000" }}>
                      <strong>Other Fees per Semester</strong>
                    </span>
                    <br />
                  </td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td style={{ width: "20%", height: 20 }}>
                    Semester 1
                    <br />
                  </td>
                  <td style={{ width: "20%", height: 20 }}>
                    Rs. 20,000/-
                    <br />
                  </td>
                  <td style={{ width: "20%", height: 20 }}>Rs. 3,700/-</td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td style={{ width: "20%", height: 20 }}>
                    Semester 2
                    <br />
                  </td>
                  <td style={{ width: "20%", height: 20 }}>
                    Rs. 20,000/-
                    <br />
                  </td>
                  <td style={{ width: "20%", height: 20 }}>Rs. 500/-</td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td style={{ width: "20%", height: 20 }}>
                    Semester 3
                    <br />
                  </td>
                  <td style={{ width: "20%", height: 20 }}>
                    Rs. 20,000/-
                    <br />
                  </td>
                  <td style={{ width: "20%", height: 20 }}>Rs. 1,500/-</td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td style={{ width: "20%", height: 20 }}>
                    Semester 4
                    <br />
                  </td>
                  <td style={{ width: "20%", height: 20 }}>
                    Rs. 20,000/-
                    <br />
                  </td>
                  <td style={{ width: "20%", height: 20 }}>Rs. 500/-</td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td style={{ width: "20%", height: 20 }}>
                    Exam Fees + Exam Form Fees
                    <br />
                  </td>
                  <td style={{ width: "20%", height: 20 }}>
                    -
                    <br />
                  </td>
                  <td style={{ width: "20%", height: 20 }}>
                    Rs. 1,600/- Per Semester
                  </td>
                </tr>
              </tbody>
            </table>

            {/* B.Sc Other Fees  */}
            <h4 style={{ margin: "35px 0 15px 0" }}>M.Sc. Other Fees Detail</h4>
            <table
              className="table table-bordered"
              style={{ borderCollapse: "collapse", width: "100%", height: 40 }}
              border={1}
            >
              <tbody>
                <tr style={{ height: 20 }}>
                  <td style={{ width: "20%", height: 20 }}>
                    <span style={{ color: "#000000" }}>
                      <strong>Fee Type</strong>
                    </span>
                    <br />
                  </td>
                  <td style={{ width: "20%", height: 20 }}>
                    <span style={{ color: "#000000" }}>
                      <strong>Amount</strong>
                    </span>
                    <br />
                  </td>
                  <td style={{ width: "20%", height: 20 }}>
                    <span style={{ color: "#000000" }}>
                      <strong>Duration</strong>
                    </span>
                    <br />
                  </td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td style={{ width: "20%", height: 20 }}>
                    Id Card Fees
                    <br />
                  </td>
                  <td style={{ width: "20%", height: 20 }}>
                    Rs. 100/-
                    <br />
                  </td>
                  <td rowSpan="5" style={{ width: "20%", height: 20 }}>
                    Only Once during the time of Admission
                  </td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td style={{ width: "20%", height: 20 }}>
                    Insurance
                    <br />
                  </td>
                  <td style={{ width: "20%", height: 20 }}>
                    Rs. 100/-
                    <br />
                  </td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td style={{ width: "20%", height: 20 }}>
                    Admission Form
                    <br />
                  </td>
                  <td style={{ width: "20%", height: 20 }}>
                    Rs. 100/-
                    <br />
                  </td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td style={{ width: "20%", height: 20 }}>
                    Enrolment Fees
                    <br />
                  </td>
                  <td style={{ width: "20%", height: 20 }}>
                    Rs. 500/-
                    <br />
                  </td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td style={{ width: "20%", height: 20 }}>
                    Development Fees
                    <br />
                  </td>
                  <td style={{ width: "20%", height: 20 }}>
                    Rs. 12,00/-
                    <br />
                  </td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td style={{ width: "20%", height: 20 }}>
                    Sports Fees
                    <br />
                  </td>
                  <td style={{ width: "20%", height: 20 }}>Rs. 500/-</td>
                  <td rowSpan="2" style={{ width: "20%", height: 20 }}>
                    Per Year
                  </td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td style={{ width: "20%", height: 20 }}>
                    Library Fees
                    <br />
                  </td>
                  <td style={{ width: "20%", height: 20 }}>Rs. 500/-</td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td style={{ width: "20%", height: 20 }}>
                    E-Suvidha
                    <br />
                  </td>
                  <td style={{ width: "20%", height: 20 }}>
                    Rs. 500/-
                    <br />
                  </td>
                  <td style={{ width: "20%", height: 20 }}>
                    Per Semester
                    <br />
                  </td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td style={{ width: "20%", height: 20 }}>
                    <strong style={{ color: "#000000" }}>Total</strong>
                    <br />
                  </td>
                  <td style={{ width: "20%", height: 20 }}>
                    <strong style={{ color: "#000000" }}>Rs. 3,700/-</strong>
                    <br />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FeeStructure;
