import React from "react";

const principalImage = "../assets/images/academics/ravikumar.jpg";

function PrincipalMessage() {
  return (
    <div>
      <>
        {/* Start For Content */}
        <div className="container mt-5 mb-5">
          <div className="row">
            <div className="col-md-3">
              <img
                src={principalImage}
                alt="pricipal-image-Dr. Vijaykumar"
                className="img-responsive img-thumbnail"
                width={261}
                height={261}
              />
              <br />
              <br />

              <h3>
              Prof. Ravikumar K

                <br />
              </h3>
              <p style={{}}>
              Dean- Engineering<br/>
Swaminarayan University
                
              </p>
            </div>
            <div className="col-md-9">
              <h2>
                Principal's Message
                <br />
              </h2>
              <p style={{ textAlign: "justify" }}>
              I am delighted to extend a warm welcome to all of you. As you embark on this exciting
journey of exploration and discovery, I invite you to envision the endless possibilities that
await you within our vibrant and dynamic community. Here, amidst the corridors of
innovation and the halls of knowledge, you will find a place where your engineering dreams
can take flight.
<br/><br/>
We take pride in offering a diverse range of engineering disciplines, each designed to foster
innovation, creativity, and excellence in the respective field. Whether your passion lies in
mechanical engineering, electrical engineering, computer science, civil engineering, or any of
our other specialized departments, you will find a wealth of opportunities to pursue your
interests and hone your skills. <br/><br/>
What sets us apart is not just our state-of-the-art facilities or renowned faculty, but our
unwavering commitment in nurturing the next generation of engineering leaders. From
hands-on projects to industry partnerships &amp; internships to research opportunities, we provide
the tools, resources, and support needed to thrive both academically and professionally.
<br/><br/>
Institute is not just a place of learning; it is a community. Here, we have a diverse and
inclusive environment where ideas are shared, collaborations are formed. Our close-knit
community of students, faculty is united by a shared passion for engineering and a common
goal of making a positive impact on the world. we are dedicated to equipping with the skills,
knowledge, and confidence to thrive in a ever-evolving world <br/><br/>
On behalf of everyone in the Institute I extend my best wishes to you as you embark on this
exciting journey of discovery. Remember, your potential is limitless, and your future begins
here and we are thrilled to be a part of it. <br/><br/>
We look forward to welcoming you to our campus and supporting you in achieving your
goals and aspirations.
<br/><br/>
Dean-Engineering<br/>
Swaminarayan University, <br/>  
Kalol
              </p>
            </div>
          </div>
        </div>{" "}
        {/* End For Content */}
      </>
    </div>
  );
}

export default PrincipalMessage;
