import React from "react";
import "../Home/Home.scss";

function Mission_Vision() {
  return (
    <div>
      <>
        <div className="MissionVision">
          <div className="health-care-area ptb-100 bg-f4f6f9">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-8 col-md-8">
                  <div className="single-health-care-card">
                    <div className="card"></div>
                    <div className="health-care-content">
                      <a href="#">
                        <h3>Mission</h3>
                      </a>
                      <p>
                        <i
                          className="ri-checkbox-circle-line"
                          style={{ color: "#e32845" }}
                        />{" "}
                        Shree Swaminarayan Homoeopathy College, Kalol and its
                        mother institute have following missions – To cater the
                        educational needs of aspiring students to join for
                        health science course by providing them best quality
                        education and better infrastructure. To pursue
                        excellence and set the pace in the field of Homeopathic
                        science.
                        <br />
                        <i
                          className="ri-checkbox-circle-line"
                          style={{ color: "#e32845" }}
                        />{" "}
                        To initiate and promote experimentation and innovations
                        in Homeopathic education in collaboration with bodies
                        like Ministry of Health and Family Welfare (Department
                        of AYUSH, New Delhi), Central Council of Homeopathic,
                        New Delhi, Government of Maharashtra, Maharashtra
                        University of Health Sciences, Nashik and Central
                        Council for Research in Homeopathic New Delhi, etc.
                        <br />
                        <i
                          className="ri-checkbox-circle-line"
                          style={{ color: "#e32845" }}
                        />{" "}
                        To attain self-sufficiency in under-graduate and
                        post-graduate education to meet the country’s need for
                        highly qualified teachers, physicians and researchers in
                        health sector. To develop the spirit of creativity
                        amongst the teachers and students in the research field.
                        To provide high quality patient care through Homeopathic
                        science.
                        <br />
                        <i
                          className="ri-checkbox-circle-line"
                          style={{ color: "#e32845" }}
                        />{" "}
                        To make a disease free world through a scientific
                        approach to Homeopathic. Rural development by providing
                        them clean drinking water, rural sanitation, health
                        monitoring of school children, water conservation,
                        primary education and shelter to needy student.
                        <br />
                        <i
                          className="ri-checkbox-circle-line"
                          style={{ color: "#e32845" }}
                        />{" "}
                        To be amongst first ten most preferred Homeopathic
                        institutions in India. To attract, retain and develop
                        competent faculty.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4">
                  <div className="single-health-care-card">
                    <div className="img">
                      <a href="#">
                        <img
                          src="../assets/images/academics/mission.jpg"
                          alt="Image"
                          className="homeomisionimg"
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-lg-5 col-md-5">
                    <div className="single-health-care-card">
                      <div className="card">
                        <a href="#">
                          <img
                            src="../assets/images/academics/vision.jpg"
                            alt="Image"
                            className="visionhomeoimg"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-7 col-md-7">
                    <div className="single-health-care-card">
                      <div className="card"></div>
                      <div className="health-care-content">
                        <a href="#">
                          <h3>Vision</h3>
                        </a>
                        <p>
                          <i
                            className="ri-checkbox-circle-line"
                            style={{ color: "#e32845" }}
                          />{" "}
                          Shree Swaminarayan Homoeopathy College, Kalol is a
                          well-known and leading institute in Guarat state in
                          the field of health education. We are dedicated to
                          provide the highest quality education, training,
                          teaching, health care and research in the field of
                          Homeopathy.
                          <br />
                          <br />
                          <i
                            className="ri-checkbox-circle-line"
                            style={{ color: "#e32845" }}
                          />{" "}
                          We train student to become the healer of the future. A
                          practitioner dedicated to understand his patients
                          physical & psychological suffering and treating him
                          accordingly. A practitioner treating an individual and
                          not a disease.
                          <br />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

export default Mission_Vision;
