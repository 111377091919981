import React from "react";
import axios from 'axios';
import { useState,useEffect,useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { ACADEMIC_CALENDAR_GET } from "../../Utils/InfoUploadingConst";

export default function AcademicCalender({  setLoading }) {

  const [data, setData] = useState([]);

  const navigate = useNavigate();  

  const getData = async () => {
      const config = {
          method: "get",
          url: ACADEMIC_CALENDAR_GET,
          headers: {
              "Content-Type": "application/json",
          },
      };

      await axios(config)
          .then((res) => {
            // setLoading(0);
              res.data.data.sort((a, b) => b.session - a.session);
              res.data.data.forEach((element) => {
                  element.attachments = JSON.parse(element.attachments);
              });
              console.log(res.data.data)
              setData(res.data.data);
          })
          .catch((err) => {
            // setLoading(0);
              console.log(err);
          });
  };

  useEffect(() => {
      getData();
  }, []);

  function formatSession(session) {
    // Assuming session is in the format "YYYY"
    const academicYear = `${session}-${parseInt(session, 10) + 1}`;
    return academicYear;
  }

  const sessionformat = {
    2025: "2025-26" ,
    2024: "2024-25" ,
    2023: "2023-24" ,
    2022: "2022-23" ,
    2021: "2021-22" ,
    2020: "2020-21" ,
    2019: "2019-20" ,
    2018: "2018-19" ,
    2017: "2017-18" ,
    2016: "2016-17" ,
    2015: "2015-16" ,
    2014: "2014-15" ,
    2013: "2013-14" ,
  }


  return (
    <div>

<div className="container mt-5">
        <div className="row register" style={{ maxWidth: "100%" }}>
          <div className="col-md-12">
            <h3>
              ACADEMIC CALENDAR            
            </h3>
            <p>Academic Calendar documents Links are below</p>
          </div>

        
<div className="container">

  
<div className="alumni-area mt-4">
<div className="row">

{
      data
      .filter((item) => item.status == 'ACTIVE' && item.college_id == 1111008 && item.type === "Academic Calendar")
      .map((item, key) => ( 
<div className="col-md-4">
<a href={item?.attachments} target="_blank">
  <div className="single-alumoni-updates-card">
  <div className=" d-flex flex-row flex-wrap align-items-center ">
    <div className=" bg-danger text-center p-3 me-3">
    <i class="ri-file-download-line text-light " style={{fontSize:"20px"}}></i>
    </div>
   <div className="">
    <h5>
      {sessionformat[item?.session] || item?.session} Academic Calendar
    </h5>
   </div>
  </div>
</div>
</a>
  </div>
  ))

}

</div>

</div>
</div>
         
        </div>
        <br />
        <br />
      </div>

      {/* <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-md-12">
            <p>
              <strong>ACADEMIC CALENDER 2023-24</strong>
              <br />
            </p>

            <table
              className="table table-bordered"
              style={{ borderCollapse: "collapse", width: "100%", height: 40 }}
              border={1}
            >
              <tbody>
                <tr style={{ height: 20 }}>
                  <td style={{ width: "25%", height: 20, textAlign: "center" }}>
                    <span style={{ color: "#000000", textAlign: "center" }}>
                      <strong>Activities</strong>
                    </span>
                    <br />
                  </td>
                  <td style={{ width: "25%", height: 20, textAlign: "center" }}>
                    <span style={{ color: "#000000" }}>
                      <strong>
                        ODD SEMESTER (First & Third Semester B.Pharm)
                      </strong>
                    </span>
                    <br />
                  </td>
                  <td style={{ width: "25%", height: 20, textAlign: "center" }}>
                    <span style={{ color: "#000000" }}>
                      <strong>
                        EVEN SEMESTER (Second & fourth Semester B.Pharm)
                      </strong>
                    </span>
                    <br />
                  </td>
                  <td style={{ width: "25%", height: 20, textAlign: "center" }}>
                    <span style={{ color: "#000000" }}>
                      <strong>YEAR WISE (Second Year Pharm D.)</strong>
                    </span>
                    <br />
                  </td>
                </tr>

                <tr style={{ height: 20 }}>
                  <td style={{ textAlign: "center" }}>Start of Semester</td>
                  <td style={{ textAlign: "center" }}>01/08/2023</td>
                  <td style={{ textAlign: "center" }}>22/01/2024</td>
                  <td style={{ textAlign: "center" }}>17/07/2023</td>
                </tr>

                <tr style={{ height: 20 }}>
                  <td style={{ textAlign: "center" }}>Class test (from)</td>
                  <td style={{ textAlign: "center" }}>As per the Time-Table</td>
                  <td style={{ textAlign: "center" }}>As per the Time-Table</td>
                  <td style={{ textAlign: "center" }}>As per the Time-Table</td>
                </tr>

                <tr style={{ height: 20 }}>
                  <td style={{ textAlign: "center" }}>
                    Assignment submission (Previous semester)
                  </td>
                  <td style={{ textAlign: "center" }}>-----</td>
                  <td style={{ textAlign: "center" }}>-----</td>
                  <td style={{ textAlign: "center" }}>-----</td>
                </tr>

                <tr style={{ height: 20 }}>
                  <td style={{ textAlign: "center" }}>
                    Result of Remedial midterm exam of previous semester
                  </td>
                  <td style={{ textAlign: "center" }}>-----</td>
                  <td style={{ textAlign: "center" }}>-----</td>
                  <td style={{ textAlign: "center" }}>-----</td>
                </tr>

                <tr style={{ height: 20 }}>
                  <td style={{ textAlign: "center" }}>Midterm Exam (from)</td>
                  <td style={{ textAlign: "center" }}>25/10/2023</td>
                  <td style={{ textAlign: "center" }}>08/04/2024</td>
                  <td style={{ textAlign: "center" }}>
                    First Exam - 09/10/2023
                    <br />
                    Second Exam- 16/01/2024
                    <br />
                    Third Exam- 01/04/2024
                  </td>
                </tr>

                <tr style={{ height: 20 }}>
                  <td style={{ textAlign: "center" }}>
                    Result of Midterm Exam
                  </td>
                  <td style={{ textAlign: "center" }}>06/11/2023</td>
                  <td style={{ textAlign: "center" }}>22/04/2024</td>
                  <td style={{ textAlign: "center" }}>
                    First Exam - 23/10/2023
                    <br />
                    Second Exam- 29/01/2024
                    <br />
                    Third Exam- 12/04/2024
                  </td>
                </tr>

                <tr style={{ height: 20 }}>
                  <td style={{ textAlign: "center" }}>
                    Assignment submission (Current Submission)
                  </td>
                  <td style={{ textAlign: "center" }}>30/11/2023</td>
                  <td style={{ textAlign: "center" }}>29/04/2024</td>
                  <td style={{ textAlign: "center" }}>12/02/2024</td>
                </tr>

                <tr style={{ height: 20 }}>
                  <td style={{ textAlign: "center" }}>
                    End Of the semester and Last date for submission of Marks-
                    continuous evaluation/internal evaluation
                  </td>
                  <td style={{ textAlign: "center" }}>30/12/2023</td>
                  <td style={{ textAlign: "center" }}>31/05/2024</td>
                  <td style={{ textAlign: "center" }}>13/04/2024</td>
                </tr>

                <tr style={{ height: 20 }}>
                  <td style={{ textAlign: "center" }}>Preparatory Holidays</td>
                  <td style={{ textAlign: "center" }}>-----</td>
                  <td style={{ textAlign: "center" }}>-----</td>
                  <td style={{ textAlign: "center" }}>
                    15/04/2024 to 22/04/2024
                  </td>
                </tr>

                <tr style={{ height: 20 }}>
                  <td style={{ textAlign: "center" }}>
                    End Semester Examination (Practical)
                  </td>
                  <td style={{ textAlign: "center" }}>01/01/2024</td>
                  <td style={{ textAlign: "center" }}>03/06/2024</td>
                  <td style={{ textAlign: "center" }}>22/04/2024</td>
                </tr>

                <tr style={{ height: 20 }}>
                  <td style={{ textAlign: "center" }}>
                    End Semester Examination (Theory) and Supplementary
                    Examination (practical)
                  </td>
                  <td style={{ textAlign: "center" }}>08/01/2024</td>
                  <td style={{ textAlign: "center" }}>10/06/2024</td>
                  <td style={{ textAlign: "center" }}>29/04/2024</td>
                </tr>

                <tr style={{ height: 20 }}>
                  <td style={{ textAlign: "center" }}>
                    Supplementary Exam previous semester (Theory) From
                  </td>
                  <td style={{ textAlign: "center" }}>08/01/2024</td>
                  <td style={{ textAlign: "center" }}>10/06/2024</td>
                  <td style={{ textAlign: "center" }}>29/04/2024</td>
                </tr>

                <tr>
                  <td colSpan={4}>
                    Diwali vacations will start from 08/11/2023 to 18/11/2023
                    <br />
                    For the first semester will commence based on the direction
                    of ACPC.
                  </td>
                </tr>
                
              </tbody>
            </table>
          </div>
        </div>
      </div> */}

    </div>
  );
}
