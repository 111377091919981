import React from 'react'

function Nep() {
  return (
    <div>
      <div className="description">
  <div className="container-fluid p-5">
  <nav>
                <div
                  className="nav nav-tabs d-flex justify-content-between"
                  id="nav-tab"
                  role="tablist"
                >
             
                  <button
                    className="nav-link "
                    id="nav-bams-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-bams"
                    type="button"
                    role="tab"
                    aria-controls="nav-bams"
                    aria-selected="false"
                  >
                    Ayurveda
                  </button>
                  <button
                    className="nav-link"
                    id="nav-bhms-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-bhms"
                    type="button"
                    role="tab"
                    aria-controls="nav-bhms"
                    aria-selected="false"
                  >
                    Homoeopathy
                  </button>
                  <button
                    className="nav-link"
                    id="nav-engineer-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-engineer"
                    type="button"
                    role="tab"
                    aria-controls="nav-engineer"
                    aria-selected="false"
                  >
                    Engineering
                  </button>
                  <button
                    className="nav-link"
                    id="nav-nursing-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-nursing"
                    type="button"
                    role="tab"
                    aria-controls="nav-nursing"
                    aria-selected="false"
                  >
                    Nursing
                  </button>
                  <button
                    className="nav-link"
                    id="nav-law-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-law"
                    type="button"
                    role="tab"
                    aria-controls="nav-law"
                    aria-selected="false"
                  >
                    LAW
                  </button>
                  <button
                    className="nav-link"
                    id="nav-pharmacy-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-pharmacy"
                    type="button"
                    role="tab"
                    aria-controls="nav-pharmacy"
                    aria-selected="false"
                  >
                    Pharmacy
                  </button>
                  <button
                    className="nav-link active"
                    id="nav-science-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-science"
                    type="button"
                    role="tab"
                    aria-controls="nav-science"
                    aria-selected="false"
                  >
                    Science
                  </button>
                  <button
                    className="nav-link"
                    id="nav-commerce-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-commerce"
                    type="button"
                    role="tab"
                    aria-controls="nav-commerce"
                    aria-selected="false"
                  >
                    Commerce
                  </button>
                  <button
                    className="nav-link"
                    id="nav-educate-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-educate"
                    type="button"
                    role="tab"
                    aria-controls="nav-educate"
                    aria-selected="false"
                  >
                    B.Ed
                  </button>
                  <button
                    className="nav-link"
                    id="nav-bca-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-bca"
                    type="button"
                    role="tab"
                    aria-controls="nav-bca"
                    aria-selected="false"
                  >
                   BCA
                  </button>
                  <button
                    className="nav-link"
                    id="nav-tech-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-tech"
                    type="button"
                    role="tab"
                    aria-controls="nav-tech"
                    aria-selected="false"
                  >
                    BSc. IT
                  </button>
                  
             
                </div>
              </nav>
    <div className="tab-content" id="nav-tabContent">
      <div
        className="tab-pane fade show active"
        id="nav-science"
        role="tabpanel"
        aria-labelledby="nav-science-tab"
      >
        <div className="science">
          <div className="row">
          <div className="col-md-4">
                <h4 className='mb-2 mt-4 text-center text-uppercase'>Bsc Science</h4>
                <hr />
                <object
                          height={500}
                          data="https://nexenstial.in/unversity_assests/BSc Science.pdf#toolbar=0"
                          type="application/pdf"
                          width="100%"
                        >
                          <p>
                            It appears you don't have a PDF plugin for this
                            browser. No biggie... you can{" "}
                            <a href="https://nexenstial.in/unversity_assests/BSc Science.pdf#toolbar=0">
                              click here to download the PDF file.
                            </a>
                          </p>
                        </object>
            </div>
          </div>
          
         
        </div>
      </div>
      <div
        className="tab-pane fade "
        id="nav-bca"
        role="tabpanel"
        aria-labelledby="nav-bca-tab"
      >
        <div className="bca">
          <div className="row">
          <div className="col-md-4">
                <h4 className='mb-2 mt-4 text-center text-uppercase'>BCA</h4>
                <hr />
                <object
                          height={500}
                          data="https://nexenstial.in/unversity_assests/NEP BCA SYLLABUS.pdf#toolbar=0"
                          type="application/pdf"
                          width="100%"
                        >
                          <p>
                            It appears you don't have a PDF plugin for this
                            browser. No biggie... you can{" "}
                            <a href="https://nexenstial.in/unversity_assests/NEP BCA SYLLABUS.pdf#toolbar=0">
                              click here to download the PDF file.
                            </a>
                          </p>
                        </object>
            </div>
          </div>
          
         
        </div>
      </div>
      <div
        className="tab-pane fade"
        id="nav-tech"
        role="tabpanel"
        aria-labelledby="nav-tech-tab"
      >
        <div className="tech">
          <div className="row">
          <div className="col-md-4">
                <h4 className='mb-2 mt-4 text-center text-uppercase'>Bsc IT & Technology</h4>
                <hr />
                <object
                          height={500}
                          data="https://nexenstial.in/unversity_assests/NEP-B.Sc-IT- Faculty of IT & CS SYLLABUS.pdf#toolbar=0"
                          type="application/pdf"
                          width="100%"
                        >
                          <p>
                            It appears you don't have a PDF plugin for this
                            browser. No biggie... you can{" "}
                            <a href="https://nexenstial.in/unversity_assests/NEP-B.Sc-IT- Faculty of IT & CS SYLLABUS.pdf.pdf#toolbar=0">
                              click here to download the PDF file.
                            </a>
                          </p>
                        </object>
            </div>
          </div>
          
         
        </div>
       
      </div>
      <div
        className="tab-pane fade "
        id="nav-commerce"
        role="tabpanel"
        aria-labelledby="nav-commerce-tab"
      >
        <div className="commerce">
          <div className="row">
          <div className="col-md-4">
                <h4 className='mb-2 mt-4 text-center text-uppercase'>BBA </h4>
                <hr />
            <object
                          height={500}
                          data="https://nexenstial.in/unversity_assests/NEP B.BA. Syllabus.pdf#toolbar=0"
                          type="application/pdf"
                          width="100%"
                        >
                          <p>
                            It appears you don't have a PDF plugin for this
                            browser. No biggie... you can{" "}
                            <a href="https://nexenstial.in/unversity_assests/NEP B.BA. Syllabus.pdf#toolbar=0">
                              click here to download the PDF file.
                            </a>
                          </p>
                        </object>
            </div>
            <div className="col-md-4">
                <h4 className='mb-2 mt-4 text-center text-uppercase'>B.Com. </h4>
                <hr />
                <object
                          height={500}
                          data="https://nexenstial.in/unversity_assests/NEP B.COM SYLLABUS.pdf#toolbar=0"
                          type="application/pdf"
                          width="100%"
                        >
                          <p>
                            It appears you don't have a PDF plugin for this
                            browser. No biggie... you can{" "}
                            <a href="https://nexenstial.in/unversity_assests/NEP B.COM SYLLABUS.pdf#toolbar=0">
                              click here to download the PDF file.
                            </a>
                          </p>
                        </object>
            </div>
          </div>
          
         
        </div>
      </div>
   
    </div>
  </div>
</div>

      
    </div>
  )
}

export default Nep