import React from "react";

function ContactUsLaw() {
  return (
    <div>
      <>
        <div className=" asdfghjk jumbotron mt-5 mb-5">
          <div className="container mb-5">
            <h1>Contact us!</h1>
            <p>
              If you want to contact us please use this form below. Or send the
              email to{" "}
              <a href="admission@swaminarayanuniversity.ac.in">
                admission@swaminarayanuniversity.ac.in
              </a>{" "}
              &amp; call us at <a href="tel:6357675561">+91 6357675561</a> or{" "}
              <a href="tel:1800-843-2900">1800-843-2900</a>
            </p>
          </div>
        </div>
        <div className="container mb-5">
          <div className="row">
            <div className="col-md-6">
              <h2>Google Map</h2>
              <p>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3666.494935692944!2d72.50521897483013!3d23.225070008742126!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395c277122e2336d%3A0x21689c99dc796c09!2sShree%20Swaminarayan%20Law%20College!5e0!3m2!1sen!2sin!4v1712750771837!5m2!1sen!2sin"
                  title="SU Law"
                  width="600"
                  height="450"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </p>
            </div>
            <div className="col-md-6">
              <h2>Contact Form</h2>
              <p>If you have any question please send this from.</p>
              <p />
              <form
                id="formbuiler1"
                action="#"
                name="contactus_en"
                method="post"
                encType=""
                acceptCharset="utf-8"
              >
                <input
                  type="hidden"
                  name="csrf_csz"
                  id="csrf_csz"
                  defaultValue="3584429371ed0083f3ac210312a4433c"
                />
                <div>
                  <label className="control-label" htmlFor="name">
                    Name <i style={{ color: "red" }}>*</i>
                  </label>
                  <div className="controls">
                    <input
                      type="text"
                      name="name"
                      defaultValue=""
                      id="name"
                      className="form-control"
                      placeholder=""
                      required="required"
                      maxLength={255}
                    />
                  </div>
                </div>
                <div>
                  <label className="control-label" htmlFor="email">
                    Email Address <i style={{ color: "red" }}>*</i>
                  </label>
                  <div className="controls">
                    <input
                      type="email"
                      name="email"
                      defaultValue=""
                      id="email"
                      className="form-control"
                      placeholder=""
                      required="required"
                      maxLength={255}
                    />
                  </div>
                </div>
                <div>
                  <label className="control-label" htmlFor="contact_type">
                    Contact Type <i style={{ color: "red" }}>*</i>
                  </label>
                  <select
                    id="contact_type"
                    name="contact_type"
                    className="form-control"
                    required="required"
                  >
                    <option value="">-- Choose Type --</option>
                    <option value="question">Question</option>
                    <option value="contactus">ContactUs</option>
                    <option value="service">Service</option>
                  </select>
                </div>
                <div>
                  <label className="control-label" htmlFor="message">
                    Message <i style={{ color: "red" }}>*</i>
                  </label>
                  <div className="controls">
                    <textarea
                      name="message"
                      id="message"
                      className="form-control"
                      placeholder=""
                      required="required"
                      rows={4}
                      defaultValue={""}
                    />
                  </div>
                </div>
                <br />
                <br />
                <div className="form-actions">
                  <input
                    type="submit"
                    name="submit"
                    defaultValue="Send now"
                    id="submit"
                    className="btn btn-primary"
                    placeholder=""
                  />{" "}
                  <input
                    type="reset"
                    name="reset"
                    defaultValue="Reset"
                    id="reset"
                    className="btn btn-default"
                    placeholder=""
                  />{" "}
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

export default ContactUsLaw;
