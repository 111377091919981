import React from "react";

export default function Workshop() {
  return (
    <div>
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-md-12">
            <p>
              <span style={{ fontSize: "18pt" }}>
                <strong>
                  Recognition/ awards/ Prizes in conference / workshop/ seminar/
                  symposia
                </strong>
              </span>
              <br />
            </p>

            <ul>
              <li>
                <p>
                  Orientation Program on Information and Technology for teacher
                  educator at A. G. Teachers College, C.T.E. Center, Ahmedabad
                  In March-2006
                </p>
              </li>

              <li>
                <p>
                  Seminar on “Moderns Trends in educational research paper “At
                  Sharda Adhyapan Mandir, Pansar.” Organized by Educational
                  Faculty College Teacher Association. ”
                </p>
              </li>
              <li>
                <p>
                  An Educational Conference on “Change the rules of the game, DE
                  learning, Unlearning and relearning” organized by Shri M. N.
                  Shukla College of Education.
                </p>
              </li>
              <li>
                <p>
                  Lectures of ‘Expansion of teacher education’ and ‘Personality
                  Development through Positive Attitude’ organized by Education
                  Faculty College Teacher association at school of education,
                  Ahmedabad ,dated 19th Nov.2006.
                </p>
              </li>
              <li>
                <p>
                  Seminar on ‘expansion of teacher education’ and ‘Personality
                  Development through Positive Attitude’ organized by Education
                  Faculty College Teacher association 19th Nov.2006 at school of
                  education, Ahmedabad ,dated 19th Nov.2006.
                </p>
              </li>
              <li>
                <p>
                  Seminar on ‘expansion of teacher education’ and ‘Personality
                  Development through Positive Attitude’ organized by Education
                  Faculty College Teacher association 19th Nov.2006 at school of
                  education, Ahmedabad ,dated 19th Nov.2006.
                </p>
              </li>
              <li>
                <p>
                  Seminar on ‘How To write a research paper’on 30th Jan,
                  2007.Department of Education, Gujarat Uni., Ahmedabad.
                </p>
              </li>
              <li>
                <p>
                  A Workshop of Model of Teaching during 1st-2nd may, 2007 at
                  SMNK Dalal Education college for women, A ’bad.
                </p>
              </li>

              <li>
                <p>
                  A Seminar on simulation in teaching Method organized by
                  Chankya Mahavidhalaya & Gujarat University Education
                  Department on 30th June,2007 .
                </p>
              </li>
              <li>
                <p>
                  Relevance of Linear Programme in Higher education in Ahmedabad
                  City. International Journal for Research in Education Vol.-2
                  issue in January-2013(IJPE)
                </p>
              </li>
              <li>
                <p>
                  An Educational Conference on Role of Teacher Educators for
                  Student Counselling on 8-4-2007 at Veda Swami Vivekananda
                  Educational Trust.
                </p>
              </li>
              <li>
                <p>
                  Seminar on Marketing Educational Services at Department of
                  Education, Gujarat University, Ahmedabad on Dated 4th
                  April,2008.
                </p>
              </li>
              <li>
                <p>
                  Seminar on Basics of Educational research at Department of
                  Education, Ganpat University on dated 17th February, 2008.
                </p>
              </li>
              <li>
                <p>
                  A workshop on Advanced Educational research on 23-03-2008 to
                  25-03-2008 at department of Education and Dr. Baba Saheb
                  Ambedkar Open University.
                </p>
              </li>
              <li>
                <p>
                  Educational Seminar on ‘Bhartiya Jivan, Shikshan And
                  Prashikshan’ on dated 18th April,2008 by Education Faculty.
                </p>
              </li>
              <li>
                <p>
                  One Day State Level Conference on innovative Programme for
                  girls’ education in rural area presented a paper titled
                  education strategies for the improvement of the girls
                  education in rural area on dated 9th April 2006.
                </p>
              </li>
              <li>
                <p>
                  Intel Technology Programme essential course on 24-03-2008 to
                  29-03-2008 at Chankya Shikshan Sankul.
                </p>
              </li>
              <li>
                <p>
                  Education Seminar on “Shikhak-Prashikshan ni Vistarti Jati
                  Kshitijo and Vyaktitva vikas ma Vidyayak Drashtikon no Phado”
                  on dated 19th Nov., 2006 by education Faculty.
                </p>
              </li>
              <li>
                <p>
                  Inter Collegiate Youth Festival in Mime, Skit during
                  23-09-2002 to 25-09-2002.
                </p>
              </li>
              <li>
                <p>
                  • A Report on Attending the Seminar-cum-Workshop on “NAAC for
                  Universities” on 16-17th Jun 2023, Anand , Gujarat
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
