import React from "react";

function Mission_VisionArts() {
  return (
    <div>
      <>
        <div className="MissionVision">
          <div className="health-care-area ptb-100 bg-f4f6f9">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-6 col-md-6">
                  <div className="single-health-care-card">
                    <div className="card"></div>
                    <div className="health-care-content">
                      <a href="#">
                        <h3>Mission</h3>
                      </a>
                      <p>
                        <i
                          className="ri-checkbox-circle-line"
                          style={{ color: "#e32845" }}
                        />{" "}
                        Our Arts College aims to be a leading institution of
                        artistic education and research, recognized for its
                        commitment to academic excellence, creative expression,
                        and the development of critical thinking and
                        problem-solving skills. We strive to cultivate a culture
                        of artistic curiosity, collaboration, and ethical
                        conduct, empowering our students to make meaningful
                        contributions to the arts and address the pressing
                        challenges of our time.
                        <br />
                        <br />
                        <br />
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="single-health-care-card">
                    <div className="img">
                      <a href="#">
                        <img
                          src="../assets/images/academics/mission.jpg"
                          alt="Image"
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-lg-6 col-md-6">
                    <div className="single-health-care-card">
                      <div className="card">
                        <a href="#">
                          <img
                            src="../assets/images/academics/vision.jpg"
                            alt="Image"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="single-health-care-card">
                      <div className="card"></div>
                      <div className="health-care-content">
                        <a href="#">
                          <h3>Vision</h3>
                        </a>
                        <p>
                          <i
                            className="ri-checkbox-circle-line"
                            style={{ color: "#e32845" }}
                          />{" "}
                          To provide exceptional education, foster artistic
                          inquiry, and promote innovation in the field of arts,
                          preparing students to become knowledgeable and
                          responsible global citizens who contribute to the
                          advancement of society.
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

export default Mission_VisionArts;
