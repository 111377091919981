import React from 'react'

function AcademicFacilities() {
  return (
    <div className='AcademicFacilities'>
        <div className="campus-life-area pt-100 pb-70">
  <div className="container">
    <h3 className="text-center mb-4 ">Academic Facilities</h3>
    <p className="text-justify">
      Swaminarayan University ​​established by the SSVG are fully equipped to
      meet the curricular requirements in term of latest laboratories simulation
      software, e-learning facilities, language laboratories, internet
      connectivity including Wi-Fi, computers centres and computers with each
      department. Class rooms are entirely furnished with provision for audio-
      visual facilities, overhead projectors, out of which a few are smart
      classrooms. All the programs function independently with their separate
      academic facilities and faculty/staff
    </p>
    <p className="text-justify">
      Computers are available for faculty, staff and are also installed in
      hostels and the library. There is dedicated ERP section which deals all
      intuitions, students, faculty related data and information. The attendance
      of faculty and staff is monitored through a biometric system.
    </p>
    <h3 className="text-center mb-4 ">Learning Environments</h3>
    <p>
      Swaminarayan University provides high-quality, cutting-edge learning
      resources include an impressive library, IT and study support facilities
      giving our students access to various resources like e-journals, online
      databases, e-books, books, and DVDs. There’s 24-hour PC access, WIFI
      internet across campus and a dedicated Edge Ahead Centre to support
      students with skills development and specific learning difficulties. Our
      academic departments are home to some of the best, high-quality resources
      in Higher Education. We can help you to get the best out of the
      information, equipment, facilities, and support to ensure you meet your
      full potential. All our departments offer modern, inspiring learning
      environments to fully prepare you for your future career.
    </p>
    <table className="table table-bordered">
      <tbody>
        <tr>
          <th>Sr. No.</th>
          <th>Name of Institutes</th>
          <th>Place</th>
        </tr>
        <tr>
          <td>1</td>
          <td>
            Shree Swaminarayan College of Engineering &amp; Technology (Diploma)
          </td>
          <td>Kalol</td>
        </tr>
        <tr>
          <td>2</td>
          <td>
            Shree Swaminarayan College of Engineering &amp; Technology (Degree
            &amp; PG)
          </td>
          <td>Kalol</td>
        </tr>
        <tr>
          <td>3</td>
          <td>Shree Swaminarayan Ayurvedic College</td>
          <td>Kalol</td>
        </tr>
        <tr>
          <td>4</td>
          <td>Shree Swaminarayan Homoeopathic College</td>
          <td>Kalol</td>
        </tr>
        <tr>
          <td>5</td>
          <td>Shree Swaminarayan Physiotherapy College</td>
          <td>Ahmedabad</td>
        </tr>
        <tr>
          <td>6</td>
          <td>Shree Swaminarayan Nursing College (BSC &amp; GNM)</td>
          <td>Kalol</td>
        </tr>
        <tr>
          <td>7</td>
          <td>Shree Swaminarayan College of Pharmacy</td>
          <td>Kalol</td>
        </tr>
        <tr>
          <td>8</td>
          <td>Shree Swaminarayan Science College</td>
          <td>Kalol</td>
        </tr>
        <tr>
          <td>9</td>
          <td>Shree Swaminarayan B. Ed College</td>
          <td>Kalol</td>
        </tr>
        <tr>
          <td>10</td>
          <td>Shri Swaminarayan Law College</td>
          <td>Kalol</td>
        </tr>
        <tr>
          <td>11</td>
          <td>Shree Swaminarayan B.Sc. College</td>
          <td>Bhinmal, Rajasthan</td>
        </tr>
        <tr>
          <td>12</td>
          <td>Shree Swaminarayan International School</td>
          <td>Kalol</td>
        </tr>
        <tr>
          <td>13</td>
          <td>Shree Swaminarayan International School</td>
          <td>Ranip, Ahmedabad</td>
        </tr>
        <tr>
          <td>14</td>
          <td>Shree Swaminarayan International School (CBSE)</td>
          <td>Ranip, Ahmedabad</td>
        </tr>
        <tr>
          <td>15</td>
          <td>SHREE SWAMINARAYAN PHYSIOTHERAPY COLLEGE, KALOL</td>
          <td>KALOL, Ahmedabad</td>
        </tr>
        <tr>
          <td>16</td>
          <td>SWAMINARAYAN INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, KALOL</td>
          <td>KALOL, Ahmedabad</td>
        </tr>
        <tr>
          <td>17</td>
          <td>FACULTY OF ARTS AND HUMANITIES, KALOL</td>
          <td>KALOL, Ahmedabad</td>
        </tr>
        <tr>
          <td>18</td>
          <td>FACULTY OF COMMERCE AND MANAGEMENT, KALOL</td>
          <td>KALOL, Ahmedabad</td>
        </tr>
        <tr>
          <td>19</td>
          <td>FACULTY OF IT AND COMPUTER SCIENCES, KALOL</td>
          <td>KALOL, Ahmedabad</td>
        </tr>
        <tr>
          <td>20</td>
          <td>SHRI SWAMINARAYAN COLLEGE OF PHARMACY, KALOL</td>
          <td>KALOL, Ahmedabad</td>
        </tr>

      </tbody>
    </table>
    <div className="row justify-content-center mt-5">
      <div className="col-lg-4 col-md-6">
        <div className="single-campus-card">
          <div className="img">
            <a href="">
              <img src="https://nexenstial.in/unversity_assests/images/facilities/IMG_1308.JPG" alt="Image" />
            </a>
          </div>
          <div className="campus-content">
            <h3>Class Room</h3>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6">
        <div className="single-campus-card">
          <div className="img">
            <a href="">
              <img src="https://nexenstial.in/unversity_assests/images/facilities/pharmacy1.jpeg" alt="Image" />
            </a>
          </div>
          <div className="campus-content">
            <h3>Pharmacy Lab </h3>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6">
        <div className="single-campus-card">
          <div className="img">
            <a href="">
              <img src="https://nexenstial.in/unversity_assests/images/facilities/computerlab.JPG" alt="Image" />
            </a>
          </div>
          <div className="campus-content">
            <h3>Computer Lab</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    </div>
  )
}

export default AcademicFacilities