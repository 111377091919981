import React, { useEffect, useState } from "react";
import { Http } from "../../Services/Services";
import {
  STUDENT_EXAM_DETAILS,
  PRE_STUDENT_ENROLLMENT,
} from "../../Utils/apiConst";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import { ROUTES } from "../../Router/RouterConfig";
import ProgressBar from "../../Components/ProgressBar/ProgressBar";
import Loader from "../../Components/Loader/Loader";

const StudentExaminationCourseRegisteration = () => {
  const navigate = useNavigate();
  const [studentId, setStudentId] = useState("");
  const { state } = useLocation();

  const check = async () => {
    if (state?.step2) {
      let id = localStorage.getItem("Examid");
      if (id) {
        setStudentId(id);
        await handleGetStudentExam(id);
      }
    }
  };

  useEffect(() => {
    check();
  }, []);

  const [flag, setFlag] = useState(false);

  const [value, setValue] = useState();

  const [email, setEmail] = useState("");

  const [address, setAddress] = useState("");

  const [classid, setClassid] = useState();

  const [name, setName] = useState("");

  const [city, setCity] = useState("");

  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(false);

  const handleGetStudentExam = async (student_id) => {
    setLoading(true);
    let x1 = [];
    await Http.get(STUDENT_EXAM_DETAILS + "/" + student_id)
      .then(async (res) => {
        console.log(res.data);
        setLoading(false);
        sessionStorage.setItem("dept_id", res.data.student.department_id);
        sessionStorage.setItem("clg_id", res.data.student.college_id);
        setAddress(res.data.student.address);
        setEmail(res.data.student.email);
        setName(res.data.student.name);
        setCity(res.data.student.city);
        setClassid(res.data.enrollmentData[0]?.class_examination_id);
        x1 = res.data;
        setData(res.data);
        if (res.data.enrollmentData.length > 0) {
          localStorage.setItem("x", true);
        }
        localStorage.setItem("Phdcollege_id", res.data.commitee.college_id);
        localStorage.setItem("Phdprogram_id", res.data.commitee.program_id);
        setFlag(true);
        setValue(1);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        toast.error(err.response?.data?.message || "Something went wrong");
      });

    // const config = {
    //   method: "get",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // };

    // if (x1?.timeTableData?.length > 0) {
    //   await axios({
    //     ...config,
    //     url: `${PRE_STUDENT_ENROLLMENT}?class_examination_id=${x1?.timeTableData[0]?.class_examination_id}&student_id=${x1.student?.user_id}`,
    //   })
    //     .then((res) => {
    //       console.log(res.data);
    //       if (res.data.enrolledStudent?.length == x1.timeTableData?.length) {
    //         console.log("Error");
    //         navigate(`${ROUTES.MainExamPayment}/${studentId}`, {
    //           state: {
    //             lastStep: true,
    //           },
    //         });
    //       }
    //       setFlag(true);
    //       setValue(1);
    //       setLoading(false);
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //       setLoading(false);
    //     });
    // }
  };

  const handleApplySubject = async (time_table) => {
    setLoading(true);
    localStorage.setItem("x", true);
    let x = localStorage.getItem("x");
    if (!x) {
      toast.error("Please apply for subjects");
      return;
    }
    setClassid(time_table.class_examination_id);
    const obj = {
      student_id: data.student_id,
      subject: time_table.course_id,
      time_table_id: time_table.id,
      class_examination_id: time_table.class_examination_id,
      status: "PENDING",
    };

    Http.post(PRE_STUDENT_ENROLLMENT, obj)
      .then((res) => {
        toast.success("Applied Successfully");
        handleGetStudentExam(data.student_id);
      })
      .catch((err) => {
        toast.error(err.response.data.message || "Something went wrong");
      });
  };

  const route = () => {
    setFlag(false);
    setStudentId("");
    setValue(0);
  };

  const next = () => {
    let y = localStorage.getItem("x");
    if (!y) {
      return toast.error("Please Select subjects");
    }
    localStorage.removeItem("x");

    let payment_status = data?.enrollmentData?.filter(
      (s) => s.payment_status != null
    );
    if (payment_status?.length > 0) {
      navigate(`/examApplicationForm/${studentId}`, {
        state: {
          step2: true,
          data: {
            name: name,
            address: address,
            email: email,
            sid: studentId,
            city: city,
            class_examination_id: classid,
          },
        },
      });
    } else {
      navigate(`${ROUTES.MainExamPayment}/${studentId}`, {
        state: {
          lastStep: true,
          data: {
            name: name,
            address: address,
            email: email,
            sid: studentId,
            city: city,
            class_examination_id: classid,
          },
        },
      });
    }
  };

  return (
    <div className="registeration">
      <Loader loading={loading} />
      <div className="d-flex justify-content-center align-items-center p-5">
        <div className="w-sm-50 w-100">
          <h4>Student Examination Course Registration</h4>
          <hr />
          <div className="row">
            <div style={{ display: "flex", justifyContent: "center" }}>
              <ProgressBar value={value} />
            </div>
            <div className="col-sm-12">
              <div className="form-group">
                <label htmlFor="">Student Enrollment Number</label>
                <input
                  value={studentId}
                  onChange={(e) => {
                    setStudentId(e.target.value);
                  }}
                  type="text"
                  className="form-control"
                  placeholder="Enter Student Registeration Number"
                />
              </div>
            </div>
            <div className="col-sm-12 mt-3">
              <button
                onClick={() => handleGetStudentExam(studentId)}
                className="btn btn-danger"
              >
                Submit
              </button>
            </div>
          </div>
          {flag ? (
            <div className="row mt-5">
              <table className="">
                <tr style={{ height: "50px" }}>
                  <th>Sl.No</th>
                  <th>Subject</th>
                  <th>Exam Date</th>
                  <th>Enrollment</th>
                </tr>
                {data?.timeTableData?.map((i, key) => (
                  <tr style={{ height: "50px" }}>
                    <td>{key + 1}</td>
                    <td>
                      {data?.courseData?.find((s) => s.id == i.course_id)?.name}
                    </td>
                    <td>{i.date?.split("T")[0]}</td>
                    <td>
                      {data?.enrollmentData?.find(
                        (s) => s.subject == i.course_id
                      ) ? (
                        <button
                          className={`btn btn${
                            data?.enrollmentData?.find(
                              (s) => s.subject == i.course_id
                            )?.status == "PENDING" ||
                            data?.enrollmentData?.find(
                              (s) => s.subject == i.course_id
                            )?.status == "ACTIVE"
                              ? "-outline-"
                              : "-"
                          }danger`}
                        >
                          {data?.enrollmentData?.find(
                            (s) => s.subject == i.course_id
                          )?.status == "ACTIVE"
                            ? "Applied"
                            : data?.enrollmentData?.find(
                                (s) => s.subject == i.course_id
                              )?.status == "PENDING"
                            ? "Pending"
                            : "Removed by higher authority"}
                        </button>
                      ) : (
                        <button
                          className="btn btn-danger"
                          onClick={() => {
                            handleApplySubject(i);
                          }}
                        >
                          Apply
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </table>
              <div
                className="d-flex my-3 mx-2"
                style={{ gap: "10px", marginTop: "2rem" }}
              >
                {flag ? (
                  <>
                    <button
                      className="mr-3 btn btn-danger"
                      onClick={() => next()}
                    >
                      Save & Continue
                    </button>

                    <button
                      className="mr-3 btn btn-danger"
                      onClick={() => route()}
                    >
                      Go Back
                    </button>
                  </>
                ) : null}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default StudentExaminationCourseRegisteration;
