import React from "react";
import { useState } from "react";
import Stepper from "react-stepper-horizontal";

const ProgressBar = ({ value }) => {
  let activeStep = value;
  const steps = [{ title: "Step 1" }, { title: "Step 2" }, { title: "Step 3" }];
  return (
    <div class="container">
      <Stepper
        steps={steps}
        activeColor="#dc3545"
        completeColor="#dc3545"
        activeStep={value}
      />
    </div>
  );
};

export default ProgressBar;
