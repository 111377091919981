import React from 'react'
import { Link } from 'react-router-dom'

function ResidentialFacilities() {
  return (
    <div className='ResidentialFacilities'>
        <>
  <div className="campus-life-area pt-100 pb-70">
    <div className="container">
      <h3 className="text-center mb-4 ">Hostel Facilities</h3>
      <p className="text-center">
        The University provides a rental based hostel facility to institutes’
        students with a full-fledged separate hostel facility for boys and girls
        students. It also provides high-quality and safe and sound facilities of
        lodging and boarding to cater to the needs of outstation students. It
        has full-fledged facilities with a Garden, Gymnasium and well-furnished
        air conditioned/non-air-conditioned accommodation. Students are
        monitored with their wellbeing by an efficient and qualified custodian.
        The newly constructed hostel buildings are provided with internet
        facilities, individual beds and wardrobes, attached bathrooms, study
        tables and chairs, morning breakfast and dinners as well. The hostel
        building has 24x7 power backup and in-house mess facility, maintaining
        high standard of hygiene and R/O water supply.
      </p>
    </div>
  </div>
  {/* make it live  */}
    {/* <div className="row mt-3">
    <div className="col-md-4">
<a href='' target="_blank">
  <div className="single-alumoni-updates-card">
  <div className=" d-flex flex-row  align-items-center ">
    <div className=" bg-danger text-center p-3 me-3">
    <i class="ri-file-download-line text-light " style={{fontSize:"20px"}}></i>
    </div>
   <div className="">
    <h5>
     File Name Students List
    </h5>
   </div>
  </div>
</div>
</a>
  </div>
    </div> */}
  <div className="row justify-content-center mt-5">
    <div className="col-lg-4 col-md-6">
      <div className="single-campus-card">
        <div className="img">
          <a href="">
            <img src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/facilities/hostel.JPG" alt="Image" />
          </a>
        </div>
      </div>
    </div>
    {/* <div className="col-lg-4 col-md-6">
      <div className="single-campus-card">
        <div className="img">
          <a href="">
            <img src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/facilities/hostel-3.JPG" alt="Image" />
          </a>
        </div>
      </div>
    </div>
    <div className="col-lg-4 col-md-6">
      <div className="single-campus-card">
        <div className="img">
          <a href="">
            <img src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/facilities/hostel-2.JPG" alt="Image" />
          </a>
        </div>
      </div>
    </div> */}
    
    <div className="col-lg-4 col-md-6">
      <div className="single-campus-card">
        <div className="img">
          <a href="">
            <img src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/university+images/hostel1.jpeg" alt="Image" />
          </a>
        </div>
      </div>
    </div>
    <div className="col-lg-4 col-md-6">
      <div className="single-campus-card">
        <div className="img">
          <a href="">
            <img src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/university+images/hostel2.jpeg" alt="Image" />
          </a>
        </div>
      </div>
    </div>
    <div className="col-lg-4 col-md-6">
      <div className="single-campus-card">
        <div className="img">
          <a href="">
            <img src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/university+images/hostel3.jpeg" alt="Image" />
          </a>
        </div>
      </div>
    </div>
    <div className="col-lg-4 col-md-6">
      <div className="single-campus-card">
        <div className="img">
          <a href="">
            <img src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/university+images/hostel5.jpeg" alt="Image" />
          </a>
        </div>
      </div>
    </div>
    <div className="col-lg-4 col-md-6">
      <div className="single-campus-card">
        <div className="img">
          <a href="">
            <img src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/university+images/hostel6.jpeg" alt="Image" />
          </a>
        </div>
      </div>
    </div>
    <div className="col-lg-4 col-md-6">
      <div className="single-campus-card">
        <div className="img">
          <a href="">
            <img src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/facilities/hostel-4.JPG" alt="Image" />
          </a>
        </div>
      </div>
    </div>
    <div className="col-lg-4 col-md-6">
      <div className="single-campus-card">
        <div className="img">
          <a href="">
            <img src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/facilities/gym.JPG" alt="Image" />
          </a>
        </div>
      </div>
    </div>
  </div>






  {/* <div className="register" style={{maxWidth:'1320px'}}>
 <div className="container-fluid">
 <div className="row justify-content-center mt-2  p-0">
    <div className="col-lg-6 col-md-6">
        <h3>Girls Hostel</h3>
     <table className="table table-bordered text-center">
        <thead className='bg-danger text-light p-3'>
            <tr>
                <th>Students</th>
                <th>Facility</th>
                <th>Fees/Annum</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>3 Students</td>
                <td rowSpan="3">Non AC Room, <br/> Attached Bathroom</td>
                <td>₹ 85,000/-</td>
            </tr>
            <tr>
                <td>4 Students</td>
               
                <td>₹ 80,000/-</td>
            </tr>
            <tr>
                <td>8 Students</td>
               
                <td>₹ 70,000/-</td>
            </tr>
        </tbody>
     </table>
    </div>
    <div className="col-lg-6 col-md-6">
        <h3>Boys Hostel</h3>
     <table className="table table-bordered text-center">
        <thead className='bg-danger text-light'>
            <tr>
                <th>Students</th>
                <th>Facility</th>
                <th>Fees/Annum</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>5 Students</td>
                <td rowSpan="3">Non AC Room, <br/> Attached Bathroom</td>
                <td>₹ 70,000/-</td>
            </tr>
            
        </tbody>
     </table>
    </div>

    <div className="col-md-12 mt-3 mb-5">
        <h4 className='text-center'>Transportation Facility From Ahmedabad & Gandhinagar <Link className='text-danger' to="/Transportation">Click here</Link> Check the Rout</h4>
    </div>
  </div>
 </div>
  </div> */}
</>

    </div>
  )
}

export default ResidentialFacilities