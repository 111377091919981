import React from "react";

function Mission_VisionPhysio() {
  return (
    <div>
      <>
        <div className="MissionVision">
          <div className="health-care-area ptb-100 bg-f4f6f9">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-6 col-md-6">
                  <div className="single-health-care-card">
                    <div className="card"></div>
                    <div className="health-care-content">
                      <a href="#">
                        <h3>Mission</h3>
                      </a>
                      <p>
                        <i
                          className="ri-checkbox-circle-line"
                          style={{ color: "#e32845" }}
                        />{" "}
                        Our Physiotherapy College aspires to be a prominent
                        institution of Physiotherapy education and research,
                        acknowledged for its dedication to academic excellence,
                        pioneering breakthroughs in traditional healthcare, and
                        the cultivation of critical thinking and problem-solving
                        abilities in the field of physiotherapeutic practice. We
                        are committed to fostering a culture of Physiotherapy
                        exploration, collaboration, and ethical conduct,
                        enabling our students to generate substantial
                        contributions to physiotherapeutic knowledge and
                        confront the contemporary health-related issues of our
                        era.
                        <br />
                        <br />
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="single-health-care-card">
                    <div className="img">
                      <a href="#">
                        <img
                          src="../assets/images/academics/mission.jpg"
                          alt="Image"
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-lg-6 col-md-6">
                    <div className="single-health-care-card">
                      <div className="card">
                        <a href="#">
                          <img
                            src="../assets/images/academics/vision.jpg"
                            alt="Image"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="single-health-care-card">
                      <div className="card"></div>
                      <div className="health-care-content">
                        <a href="#">
                          <h3>Vision</h3>
                        </a>
                        <p>
                          <i
                            className="ri-checkbox-circle-line"
                            style={{ color: "#e32845" }}
                          />{" "}
                          To provide exceptional education, foster advanced
                          Physiotherapy practice, and promote innovation in the
                          field of Physiotherapy, preparing students to become
                          skilled and compassionate healthcare professionals who
                          contribute to the advancement of Physiotherapy
                          healthcare.
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

export default Mission_VisionPhysio;
