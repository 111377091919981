import React from "react";

const principalImage = "/assets/education/images/g-u-shah.jpg";

function PrincipalMessage() {
  return (
    <div>
      <>
        {/* Start For Content */}
        <div className="container mt-5 mb-5">
          <div className="row">
            <div className="col-md-3">
              <img
                src={principalImage}
                alt="pricipal-image"
                className="img-responsive img-thumbnail"
                width={261}
                height={261}
              />
              <br />
              <br />

              <h3>
                Dr. G.U Shah
                <br />
              </h3>
              <p style={{}}>
                Dean, Faculty of Education Swaminarayan University, Kalol
              </p>
            </div>
            <div className="col-md-9">
              <h2>
                Principal's Message
                <br />
              </h2>
              <p style={{ textAlign: "justify" }}>
                The primary goal is to create a dynamic educational environment
                that fosters the organization of scholastic and co-scholastic
                activities within the Department and across various Teacher
                Education Colleges. We aim to inspire teachers and teacher
                educators to immerse themselves in their subjects, gaining an
                in-depth understanding and continually acquiring new knowledge
                across diverse curriculum areas and practical research domains.
                Our vision is to cultivate competent, dedicated, and
                research-oriented educators who can provide transformative
                educational leadership and effectively adapt to the evolving
                global educational landscape. Additionally, we are committed to
                promoting educational research that embraces a multi and
                intra-disciplinary approach, ensuring that our endeavors remain
                at the forefront of innovative pedagogical methods and
                strategies.
                <br />
                <br />
                To further enhance the quality of education, we also prioritize
                the integration of technology and modern teaching methodologies
                in our curriculum. We encourage teachers and teacher educators
                to stay abreast of technological advancements and pedagogical
                innovations, enabling them to harness the power of digital tools
                and online resources to enrich the learning experience.
                Additionally, we foster a culture of collaboration, where
                educators collaborate with their peers, students, and other
                stakeholders to explore new avenues for educational improvement.
                We believe that by nurturing a spirit of continuous learning,
                research, and adaptability, we can better equip our educators to
                address the challenges posed by the ever-changing global
                educational landscape and ultimately contribute to the
                betterment of society through the empowerment of future
                generations.
              </p>
            </div>
          </div>
          <br />
          <br />
        </div>{" "}
        {/* End For Content */}
      </>
    </div>
  );
}

export default PrincipalMessage;
