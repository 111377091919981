import React from 'react'
// import vid from '../../assets/dummy.mp4'
const Nad = () => {
    const vid1="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/abc/km_20230223-1_1080p_30f_20230223_124613+(1).mp4"
    const vid2="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/abc/km_20230223-2_1080p_30f_20230223_143920+(1).mp4"
   
    const vid3="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/abc/km_20230223-3_1080p_30f_20230223_144633+(1).mp4"
    const vid4="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/abc/km_20230223-4_1080p_30f_20230223_145227+(1).mp4"
    return (
        <div>
            <div className='p-4'>
                <h3>
                    Academic Bank of Credits (ABC) program
                </h3>
                <div className='row'>
                    <div className="col-md-6 p-3">
                        <video className='w-100' controls>
                            <source src={vid1} type="video/mp4"/>
                                Your browser does not support the video tag.
                        </video>
                    </div>
                    <div className="col-md-6 p-3">
                        <video className='w-100' controls>
                            <source src={vid2} type="video/mp4"/>
                                Your browser does not support the video tag.
                        </video>
                    </div>
                    <div className="col-md-6 p-3">
                        <video className='w-100' controls>
                            <source src={vid3} type="video/mp4"/>
                                Your browser does not support the video tag.
                        </video>
                    </div>
                    <div className="col-md-6 p-3">
                        <video className='w-100' controls>
                            <source src={vid4} type="video/mp4"/>
                                Your browser does not support the video tag.
                        </video>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Nad