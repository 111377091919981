import React from "react";
import "./Authorities.scss";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../Router/RouterConfig";
import { profileData } from "./ProfileData";

function AcademicCounsil() {
  const navigate = useNavigate();

  const changeDir = (i) => {
    navigate(ROUTES.Profile, { state: { data: i } });
  };

  return (
    <div className="AcademicCouncil GoverningBody">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1 className="text-center">Academic Council</h1>
            <div className="card border-0 shadow-sm">
              <div className="card-body">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-12 col-sm-8 col-lg-6">
                      {/* Section Heading*/}
                      <div
                        className="section_heading text-center wow fadeInUp"
                        data-wow-delay="0.2s"
                        style={{
                          visibility: "visible",
                          animationDelay: "0.2s",
                          animationName: "fadeInUp",
                        }}
                      >
                        {/* <h3>Engineering</h3> */}
                        <div className="line" />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <h3 className="text-left">Vice-Chancellor</h3>
                    <div className="col-12 col-sm-6 col-lg-3 align-center">
                      <div
                        onClick={() => {
                          changeDir(profileData[4]);
                        }}
                      >
                        <div
                          className="single_advisor_profile wow fadeInUp"
                          data-wow-delay="0.2s"
                          style={{
                            visibility: "visible",
                            animationDelay: "0.2s",
                            animationName: "fadeInUp",
                          }}
                        >
                          {/* Team Thumb*/}
                          <div className="advisor_thumb">
                            <img
                              src="assets/images/academics/vc.jpg"
                              alt="vicepresident"
                              width="100%"
                            />
                            {/* Social Info*/}
                          </div>
                          {/* Team Details*/}
                          <div className="single_advisor_details_info">
                            <h6> Prof (Dr.) M. S. Rao</h6>
                            <p className="designation">
                              {" "}
                              Provost (Vice-Chancellor) <br />
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className="card border-0 shadow-sm">
              <div className="card-body">
                <div className="container">
                  <div className="row">
                    <h3 className="text-left">Members</h3>
                    {/* Single Advisor*/}

                    {/* Single Advisor*/}
                    <div className="col-12 col-sm-6 col-lg-3">
                      <a href="#">
                        <div
                          className="single_advisor_profile wow fadeInUp"
                          data-wow-delay="0.3s"
                          style={{
                            visibility: "visible",
                            animationDelay: "0.3s",
                            animationName: "fadeInUp",
                          }}
                        >
                          {/* Team Thumb*/}
                          <div className="advisor_thumb">
                            <img
                              src="assets/images/academics/ravipatel.jpg"
                              alt="vicepresident"
                              width="100%"
                            />
                            {/* Social Info*/}
                          </div>
                          {/* Team Details*/}
                          <div className="single_advisor_details_info">
                            <h6>Dr. Ravikumar Patel</h6>
                            <p className="designation">
                              Dean, Faculty of Pharmacy
                            </p>
                          </div>
                        </div>
                      </a>
                    </div>
                    {/* Single Advisor*/}
                    <div className="col-12 col-sm-6 col-lg-3">
                      <a href="#">
                        <div
                          className="single_advisor_profile wow fadeInUp"
                          data-wow-delay="0.3s"
                          style={{
                            visibility: "visible",
                            animationDelay: "0.3s",
                            animationName: "fadeInUp",
                          }}
                        >
                          {/* Team Thumb*/}
                          <div className="advisor_thumb">
                            <img
                              src="assets/images/academics/rohit.jpg"
                              alt="vicepresident"
                              width="100%"
                            />
                            {/* Social Info*/}
                          </div>
                          {/* Team Details*/}
                          <div className="single_advisor_details_info">
                            <h6>Dr. Rohit Gokarn</h6>
                            <p className="designation">
                              Dean Faculty of Ayurveda
                            </p>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3 align-center">
                      <a href="#">
                        <div
                          className="single_advisor_profile wow fadeInUp"
                          data-wow-delay="0.2s"
                          style={{
                            visibility: "visible",
                            animationDelay: "0.2s",
                            animationName: "fadeInUp",
                          }}
                        >
                          {/* Team Thumb*/}
                          <div className="advisor_thumb">
                            <img
                              src="assets/nursing/images/trilok.png"
                              alt="vicepresident"
                              width="100%"
                              height="290px"
                            />
                            {/* Social Info*/}
                          </div>
                          {/* Team Details*/}
                          <div className="single_advisor_details_info">
                            <h6>Prof. (Dr.) Trilok Sompura</h6>
                            <p className="designation">
                              Dean, Faculty of Nursing
                            </p>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-6 col-lg-3">
                      <a href="#">
                        <div
                          className="single_advisor_profile wow fadeInUp"
                          data-wow-delay="0.2s"
                          style={{
                            visibility: "visible",
                            animationDelay: "0.2s",
                            animationName: "fadeInUp",
                          }}
                        >
                          {/* Team Thumb*/}
                          <div className="advisor_thumb">
                            <img
                              src="assets/images/academics/dinesh rao.jpg"
                              alt="Members"
                              width="100%"
                            />
                            {/* Social Info*/}
                          </div>
                          {/* Team Details*/}
                          <div className="single_advisor_details_info">
                            <h6> Dr. Dinesh Rao</h6>
                            <p className="designation">
                              {" "}
                              Dean, Faculty of Homoeopathy
                            </p>
                          </div>
                        </div>
                      </a>
                    </div>

                    <div className="col-12 col-sm-6 col-lg-3">
                      <a href="#">
                        <div
                          className="single_advisor_profile wow fadeInUp"
                          data-wow-delay="0.2s"
                          style={{
                            visibility: "visible",
                            animationDelay: "0.2s",
                            animationName: "fadeInUp",
                          }}
                        >
                          {/* Team Thumb*/}
                          <div className="advisor_thumb">
                            <img
                              src="assets/images/academics/dixit.jpg"
                              alt="Members"
                              width="100%"
                            />
                            {/* Social Info*/}
                          </div>
                          {/* Team Details*/}
                          <div className="single_advisor_details_info">
                            <h6> Dr. Dixit Patel</h6>
                            <p className="designation">
                              I/C Dean, Faculty of Law
                            </p>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3">
                      <a href="#">
                        <div
                          className="single_advisor_profile wow fadeInUp"
                          data-wow-delay="0.2s"
                          style={{
                            visibility: "visible",
                            animationDelay: "0.2s",
                            animationName: "fadeInUp",
                          }}
                        >
                          {/* Team Thumb*/}
                          <div className="advisor_thumb">
                            <img
                              src="assets/images/academics/pradeip.jpg"
                              alt="Members"
                              width="100%"
                            />
                            {/* Social Info*/}
                          </div>
                          {/* Team Details*/}
                          <div className="single_advisor_details_info">
                            <h6>Dr. Pradeep Varma</h6>
                            <p className="designation">
                              Dean, Faculty of Science
                            </p>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3">
                      <a href="#">
                        <div
                          className="single_advisor_profile wow fadeInUp"
                          data-wow-delay="0.2s"
                          style={{
                            visibility: "visible",
                            animationDelay: "0.2s",
                            animationName: "fadeInUp",
                          }}
                        >
                          {/* Team Thumb*/}
                          <div className="advisor_thumb">
                            <img
                              src="assets/images/academics/vijay.jpg"
                              alt="Members"
                              width="100%"
                            />
                            {/* Social Info*/}
                          </div>
                          {/* Team Details*/}
                          <div className="single_advisor_details_info">
                            <h6> Dr. Vijay Gadhvi </h6>
                            <p className="designation">
                              Dean,Faculty of Engineering (UG & PG)
                            </p>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-6 col-lg-3">
                      <a href="#">
                        <div
                          className="single_advisor_profile wow fadeInUp"
                          data-wow-delay="0.2s"
                          style={{
                            visibility: "visible",
                            animationDelay: "0.2s",
                            animationName: "fadeInUp",
                          }}
                        >
                          {/* Team Thumb*/}
                          <div className="advisor_thumb">
                            <img
                              src="assets/images/academics/nirav.jpg"
                              alt="Members"
                              width="100%"
                            />
                            {/* Social Info*/}
                          </div>
                          {/* Team Details*/}
                          <div className="single_advisor_details_info">
                            <h6>Nirav Kavar</h6>
                            <p className="designation">
                              Assistant Professor, Faculty of Engineering
                            </p>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3">
                      <a href="#">
                        <div
                          className="single_advisor_profile wow fadeInUp"
                          data-wow-delay="0.2s"
                          style={{
                            visibility: "visible",
                            animationDelay: "0.2s",
                            animationName: "fadeInUp",
                          }}
                        >
                          {/* Team Thumb*/}
                          <div className="advisor_thumb">
                            <img
                              src="assets/images/academics/srividya.jpg"
                              alt="Members"
                              width="100%"
                            />
                            {/* Social Info*/}
                          </div>
                          {/* Team Details*/}
                          <div className="single_advisor_details_info">
                            <h6>Dr. Srividya Subramanian</h6>
                            <p className="designation">
                              Professor, Faculty of Ayurveda
                            </p>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3">
                      <a href="#">
                        <div
                          className="single_advisor_profile wow fadeInUp"
                          data-wow-delay="0.2s"
                          style={{
                            visibility: "visible",
                            animationDelay: "0.2s",
                            animationName: "fadeInUp",
                          }}
                        >
                          {/* Team Thumb*/}
                          <div className="advisor_thumb">
                            <img
                              src="assets/images/academics/sonamodi.jpg"
                              alt="Members"
                              width="100%"
                            />
                            {/* Social Info*/}
                          </div>
                          {/* Team Details*/}
                          <div className="single_advisor_details_info">
                            <h6>Ms. Sona Modi</h6>
                            <p className="designation">
                              {" "}
                              Assistant Professor, Faculty of Nursing
                            </p>
                          </div>
                        </div>
                      </a>
                    </div>

                    <div className="col-12 col-sm-6 col-lg-3">
                      <a href="#">
                        <div
                          className="single_advisor_profile wow fadeInUp"
                          data-wow-delay="0.2s"
                          style={{
                            visibility: "visible",
                            animationDelay: "0.2s",
                            animationName: "fadeInUp",
                          }}
                        >
                          {/* Team Thumb*/}
                          <div className="advisor_thumb">
                            <img
                              src="assets/images/academics/nitin.jpg"
                              alt="Members"
                              width="100%"
                            />
                            {/* Social Info*/}
                          </div>
                          {/* Team Details*/}
                          <div className="single_advisor_details_info">
                            <h6>Dr. Nitin Raval</h6>
                            <p className="designation">
                              Dean, Faculty of Education
                            </p>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-6 col-lg-3">
                      <a href="#">
                        <div
                          className="single_advisor_profile wow fadeInUp"
                          data-wow-delay="0.2s"
                          style={{
                            visibility: "visible",
                            animationDelay: "0.2s",
                            animationName: "fadeInUp",
                          }}
                        >
                          {/* Team Thumb*/}
                          <div className="advisor_thumb">
                            <img
                              src="assets/images/academics/priyanshi.jpg"
                              alt="Members"
                              width="100%"
                            />
                            {/* Social Info*/}
                          </div>
                          {/* Team Details*/}
                          <div className="single_advisor_details_info">
                            <h6>Ms.Priyanshi Sharma</h6>
                            <p className="designation">
                              Assistant Professor, Faculty of Science
                            </p>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3">
                      <a href="#">
                        <div
                          className="single_advisor_profile wow fadeInUp"
                          data-wow-delay="0.2s"
                          style={{
                            visibility: "visible",
                            animationDelay: "0.2s",
                            animationName: "fadeInUp",
                          }}
                        >
                          {/* Team Thumb*/}
                          <div className="advisor_thumb">
                            <img
                              src="assets/images/academics/bankim.jpg"
                              alt="Members"
                              width="100%"
                            />
                            {/* Social Info*/}
                          </div>
                          {/* Team Details*/}
                          <div className="single_advisor_details_info">
                            <h6>Dr. Bankim Shah</h6>
                            <p className="designation">Director, SSIS</p>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3">
                      <a href="#">
                        <div
                          className="single_advisor_profile wow fadeInUp"
                          data-wow-delay="0.2s"
                          style={{
                            visibility: "visible",
                            animationDelay: "0.2s",
                            animationName: "fadeInUp",
                          }}
                        >
                          {/* Team Thumb*/}
                          <div className="advisor_thumb">
                            <img
                              src="assets/images/academics/anita.jpg"
                              alt="Members"
                              width="100%"
                            />
                            {/* Social Info*/}
                          </div>
                          {/* Team Details*/}
                          <div className="single_advisor_details_info">
                            <h6>Dr. Anita Patel</h6>
                            <p className="designation">
                              Professor, Faculty of Ayurveda
                            </p>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-6 col-lg-3">
                      <a href="#">
                        <div
                          className="single_advisor_profile wow fadeInUp"
                          data-wow-delay="0.2s"
                          style={{
                            visibility: "visible",
                            animationDelay: "0.2s",
                            animationName: "fadeInUp",
                          }}
                        >
                          {/* Team Thumb*/}
                          <div className="advisor_thumb">
                            <img
                              src="assets/images/academics/mnpatel.jpg"
                              alt="Members"
                              width="100%"
                            />
                            {/* Social Info*/}
                          </div>
                          {/* Team Details*/}
                          <div className="single_advisor_details_info">
                            <h6>Dr. M.N. Patel</h6>
                            <p className="designation">Eminent Educationist</p>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3">
                      <a href="#">
                        <div
                          className="single_advisor_profile wow fadeInUp"
                          data-wow-delay="0.2s"
                          style={{
                            visibility: "visible",
                            animationDelay: "0.2s",
                            animationName: "fadeInUp",
                          }}
                        >
                          {/* Team Thumb*/}
                          <div className="advisor_thumb">
                            <img
                              src="assets/images/academics/madhubhai.jpg"
                              alt="Members"
                              width="100%"
                            />
                            {/* Social Info*/}
                          </div>
                          {/* Team Details*/}
                          <div className="single_advisor_details_info">
                            <h6>Dr. Madhubhai Patel</h6>
                            <p className="designation">Academician</p>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3">
                      <a href="#">
                        <div
                          className="single_advisor_profile wow fadeInUp"
                          data-wow-delay="0.2s"
                          style={{
                            visibility: "visible",
                            animationDelay: "0.2s",
                            animationName: "fadeInUp",
                          }}
                        >
                          {/* Team Thumb*/}
                          <div className="advisor_thumb">
                            <img
                              src="assets/images/academics/maheshsir.jpg"
                              alt="Members"
                              width="100%"
                            />
                            {/* Social Info*/}
                          </div>
                          {/* Team Details*/}
                          <div className="single_advisor_details_info">
                            <h6>Dr. Mahesh Patel</h6>
                            <p className="designation">Academician, Mehsana</p>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3">
                      <a href="#">
                        <div
                          className="single_advisor_profile wow fadeInUp"
                          data-wow-delay="0.2s"
                          style={{
                            visibility: "visible",
                            animationDelay: "0.2s",
                            animationName: "fadeInUp",
                          }}
                        >
                          {/* Team Thumb*/}
                          <div className="advisor_thumb">
                            <img
                              src="assets/images/academics/vicepresident-1.jpg"
                              alt="Members"
                              width="100%"
                            />
                            {/* Social Info*/}
                          </div>
                          {/* Team Details*/}
                          <div className="single_advisor_details_info">
                            <h6>Swami Bhaktavatsaldasji</h6>
                            <p className="designation">Vice President</p>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-6 col-lg-3">
                      <a href="#">
                        <div
                          className="single_advisor_profile wow fadeInUp"
                          data-wow-delay="0.2s"
                          style={{
                            visibility: "visible",
                            animationDelay: "0.2s",
                            animationName: "fadeInUp",
                          }}
                        >
                          {/* Team Thumb*/}
                          <div className="advisor_thumb">
                            <img
                              src="assets/images/academics/dhiraj.jpg"
                              alt="Members"
                              width="100%"
                            />
                            {/* Social Info*/}
                          </div>
                          {/* Team Details*/}
                          <div className="single_advisor_details_info">
                            <h6>CA Dhiraj Kaka</h6>
                            <p className="designation">
                              Chartered Accountant, Ahmedabad
                            </p>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3">
                      <a href="#">
                        <div
                          className="single_advisor_profile wow fadeInUp"
                          data-wow-delay="0.2s"
                          style={{
                            visibility: "visible",
                            animationDelay: "0.2s",
                            animationName: "fadeInUp",
                          }}
                        >
                          {/* Team Thumb*/}
                          <div className="advisor_thumb">
                            <img
                              src="assets/images/academics/vppatel.jpg"
                              alt="Members"
                              width="100%"
                            />
                            {/* Social Info*/}
                          </div>
                          {/* Team Details*/}
                          <div className="single_advisor_details_info">
                            <h6>Justice V.P. Patel</h6>
                            <p className="designation">
                              Chairman State Consumer Forum, Sola
                            </p>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/*</div>*/}
              <br />
              <div className="card border-0 shadow-sm">
                <div className="card-body">
                  <div className="container">
                    <div className="row">
                      <h3 className="text-left">Member Secretary</h3>
                      <div className="col-12 col-sm-6 col-lg-3">
                        <div
                          onClick={() => {
                            changeDir(profileData[7]);
                          }}
                          className="single_advisor_profile wow fadeInUp"
                          data-wow-delay="0.2s"
                          style={{
                            visibility: "visible",
                            animationDelay: "0.2s",
                            animationName: "fadeInUp",
                          }}
                        >
                          {/* Team Thumb*/}
                          <div className="advisor_thumb">
                            <img
                              src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/Governer+Body/ajit-sir-b.jpg"
                              alt="Members"
                              width="100%"
                            />
                            {/* Social Info*/}
                          </div>
                          {/* Team Details*/}
                          <div className="single_advisor_details_info">
                            <h6> Dr Ajit Gangavane </h6>
                            <p className="designation"> Registrar</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AcademicCounsil;
