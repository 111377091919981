import React from "react";

export default function Workshop() {
  return (
    <div>
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-md-12">
            <p>
              <strong>
               Seminar / Workshop / Webinar / Industrial Visit
              </strong>
            </p>

            <table>
              <tr>
                <td>1.&nbsp;</td>
                <td>
                  <p>
                    Webinar on “Lecture on reservoir capacity estimation using
                    satellite images” on 6th august, 2022, Saturday. (Civil
                    Engineering Department) Expert - Dr. Jabbar Yazad.
                  </p>
                </td>
              </tr>

              <tr>
                <td>2.&nbsp;</td>
                <td>
                  <p>
                    Industrial visit at JK Lakshmi Cement Company on 3rd
                    september, 2022, Saturday. (Civil Engineering Department).
                  </p>
                </td>
              </tr>

              <tr>
                <td>3.&nbsp;</td>
                <td>
                  <p>
                    Webinar on "Application on Recycled aggregates in concrete
                    construction” on 25th August, 2022. (Civil Engineering
                    Department) Expert - Mr. Gaurav vyas.
                  </p>
                </td>
              </tr>

              <tr>
                <td>4.&nbsp;</td>
                <td>
                  <p>
                    Webinar on "Electrical Vehicle: The of Future Technology" on
                    25th August, 2022, (Electrical Engineering Department)
                    Expert - Prof. Mahit Jain
                  </p>
                </td>
              </tr>

              <tr>
                <td>5.&nbsp;</td>
                <td>
                  <p>
                    Workshop on Application of Ansys Analysis Software on 9th
                    September, 2022 Expert – Mr. Jayendra Patel (Mechanical
                    Engineering Department).
                  </p>
                </td>
              </tr>

              <tr>
                <td>6.&nbsp;</td>
                <td>
                  <p>
                    Industrial visit at Kvalitat Technologies LLP on 23rd
                    December 2022. (Computer Engineering Department).
                  </p>
                </td>
              </tr>

              <tr>
                <td>7.&nbsp;</td>
                <td>
                  <p>
                    Industrial visit at Janmarg Limited on 7th October, 2022.
                    (Civil Engineering Department)
                  </p>
                </td>
              </tr>

              <tr>
                <td>8.&nbsp;</td>
                <td>
                  <p>
                    Webinar on "Sustainable Solutions for Black Cotton Soil
                    Improvement with Waste Materials" on 6th April 2023. Expert
                    - Khalak Abarar Ahemad A. (Civil Engineering Department)
                  </p>
                </td>
              </tr>

              <tr>
                <td>9.&nbsp;</td>
                <td>
                  <p>
                    Workshop on "PCB DESIGN" on 23rd March 2023. Expert – Mr.
                    Nilesh Prajapati (Electrical Engineering Department)
                  </p>
                </td>
              </tr>

              <tr>
                <td>10.&nbsp;</td>
                <td>
                  <p>
                    Webinar on Career Opportunity for Engineers through GATE on
                    7th February, 2022 (Electrical Engineering Department).
                  </p>
                </td>
              </tr>

              <tr>
                <td>11.&nbsp;</td>
                <td>
                  <p>
                    Webinar on “3 Proven preparation rules to get a high-paid
                    job without worrying about your branch” on 20th may, 2023.
                    (Computer Engineering Department) Expert – Mr. Purushottam
                  </p>
                </td>
              </tr>

              <tr>
                <td>12.&nbsp;</td>
                <td>
                  <p>
                    {" "}
                    Awareness Programme to Observe on 26th June, 2023. Expert –
                    Dr. Twinkal Patel, Mr. Meet Modh and Mr. Pranav Joshi .
                  </p>
                </td>
              </tr>

              <tr>
                <td>13.&nbsp;</td>
                <td>
                  <p>
                    Industrial Visit in New Construction Building in SSVG Campus
                    on 19th April 2023. (Civil Engineering Department)
                  </p>
                </td>
              </tr>

              <tr>
                <td>14.&nbsp;</td>
                <td>
                  <p>
                    One day workshop on Energy Conservation and Solar Benefits
                    on 20th April 2023. Patel (Mechanical Engineering
                    Department) Expert – Mr. Nirav Mehata
                  </p>
                </td>
              </tr>

              <tr>
                <td>15.&nbsp;</td>
                <td>
                  <p>
                    Seminar of Guidance on IELTS and English Language on 20th
                    April 2023. Patel (Mechanical Engineering Department) Expert
                    – Ms. Archana Gupta
                  </p>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
