import React from "react";
import { useParams } from "react-router-dom";
import { simsServices } from "../../Data/simsServices";

function Service() {
  const params = useParams();
  console.log(params.serviceName);
  let lastIndex = simsServices[params.serviceName]?.length;
  let title = simsServices[params.serviceName][lastIndex - 1];
  let data = simsServices[params.serviceName].slice(0, lastIndex - 1);
  return (
    <div>
      <div className="container  text-center">
        <h1 className="text-center mt-4">{title}</h1>
        {data?.map(
          (therapy, index) =>
            index % 4 === 0 && (
              <div
                key={index}
                className="row d-flex justify-content-center mb-5"
              >
                {data.slice(index, index + 4).map((therapy, idx) => (
                  <div key={idx} className="col-md-6  mt-4 mr-4">
                    <div className="card">
                      <div className="card-body">
                        <p>{therapy}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )
        )}
      </div>
    </div>
  );
}

export default Service;
