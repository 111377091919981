import React from "react";

function AboutCollege() {
  return (
    <div>
      <>
        {/* Start For Content */}
        <div className="container mt-5 mb-5">
          <div className="row">
            <div className="col-md-3">
              <img
                src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/faculties/homoeopathy/assets/1637632899_2902-org.jpg"
                alt=""
                className="img-responsive img-thumbnail"
                width={261}
                height={261}
              />
              <br />
              <br />
            </div>
            <div className="col-md-9">
              <h2>
                About College
                <br />
              </h2>
              <p style={{ textAlign: "justify" }}>
                Shree Swaminarayan Law College is a prestigious educational
                institution committed to providing high-quality education and
                training in various academic disciplines related to law and
                legal studies. This college places a strong emphasis on
                cultivating academic excellence and research in subjects such as
                Legal Studies, Business Law, Legal Literature, History of Law,
                Sociology of Law, and other related fields. Through its
                comprehensive academic programs, this college offers a wide
                range of educational opportunities for students, spanning from
                undergraduate programs to postgraduate studies, and even
                opportunities for advanced research through Ph.D. programs. The
                institution's holistic approach to legal education ensures that
                students not only gain theoretical knowledge but also develop
                practical legal skills, enabling them to contribute
                significantly to the legal and business communities and society
                at large. Shree Swaminarayan Law College is committed to
                nurturing a deep understanding of the world of law and legal
                studies and promoting scholarly inquiry. This commitment
                reflects the college's dedication to producing well-rounded and
                capable graduates who can address complex legal challenges and
                make meaningful advancements in their chosen legal and
                professional fields.
              </p>
            </div>
          </div>
          <br />
          <br />
        </div>{" "}
        {/* End For Content */}
      </>
    </div>
  );
}

export default AboutCollege;
