import React from 'react';

function InfrastructurePhysiotherapy() {

// Image Urls
//Classroom
const classroom1 = "/assets/science/Infrastructure/classroom-1.jpg";
const classroom2 = "/assets/science/Infrastructure/classroom-2.jpg";
const classroom3 = "/assets/science/Infrastructure/classroom-3.jpg";
const classroom4 = "/assets/science/Infrastructure/classroom-4.jpg";

//Anotomy Lab
const AnotomyLab1 = "/assets/science/Infrastructure/anotomy1.jpg";
const AnotomyLab2 = "/assets/science/Infrastructure/anotomy2.jpg";
const AnotomyLab3 = "/assets/science/Infrastructure/anotomy3.jpg";

//Electrotherapy Lab
const ElectroLab1 = "/assets/science/Infrastructure/electro1.jpg";
const ElectroLab2 = "/assets/science/Infrastructure/electro2.jpg";
const ElectroLab3 = "/assets/science/Infrastructure/electro3.jpg";

//Exercise Therapy Lab
const ExerciseTherapyLab1 = "/assets/science/Infrastructure/exercisetherapy1.jpg";
const ExerciseTherapyLab2 = "/assets/science/Infrastructure/exercisetherapy2.jpg";

//Physiology Lab
const PhysiologyLab1 = "/assets/science/Infrastructure/physiology1.jpg";
const PhysiologyLab2 = "/assets/science/Infrastructure/physiology2.jpg";

  return (
    <div className="Infrastructure-Physiotherapy">

      <>
        <div className="campus-life-area pt-100 pb-70">
          <div className="container">
            <h1 className="text-center mb-4 ">Infrastructure Details</h1>
            <p className="text-center">
              Our college is well equipped with the state-of-the-art facilities
              for classrooms and laboratories in all departments.
            </p>
          </div>
        </div>

        <div className="row justify-content-center mt-3">
          <h3 className="text-center mb-4 ">CLASSROOMS</h3>
          <div className="col-lg-4 col-md-6">
            <div className="single-card">
              <div className="img">
                <a href="">
                  <img src={classroom1} alt="Image" height={400}/>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single-card">
              <div className="img">
                <a href="">
                  <img src={classroom2} alt="Image" height={400}/>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single-card">
              <div className="img">
                <a href="">
                  <img src={classroom3} alt="Image" height={400}/>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mt-2">
           <br/>
            <div className="single-card">
              <div className="img">
                <a href="">
                  <img src={classroom4} alt="Image" height={380}/>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center mt-5">
          <h3 className="text-center mb-4 ">ANOTOMY LAB</h3>
          <div className="col-lg-4 col-md-6">
            <div className="single-card">
              <div className="img">
                <a href="">
                  <img src={AnotomyLab1} alt="Image" height={380}/>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single-card">
              <div className="img">
                <a href="">
                  <img src={AnotomyLab2} alt="Image" height={380}/>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single-card">
              <div className="img">
                <a href="">
                  <img src={AnotomyLab3} alt="Image" height={380}/>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center mt-5">
          <h3 className="text-center mb-4 ">ELECTROTHERAPY LAB</h3>
          <div className="col-lg-4 col-md-6">
            <div className="single-card">
              <div className="img">
                <a href="">
                  <img src={ElectroLab1} alt="Image" height={380}/>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single-card">
              <div className="img">
                <a href="">
                  <img src={ElectroLab2} alt="Image" height={380}/>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single-card">
              <div className="img">
                <a href="">
                  <img src={ElectroLab3} alt="Image" height={380}/>
                </a>
              </div>
            </div>
          </div>
        </div>
        
        <div className="row justify-content-center mt-5">
          <h3 className="text-center mb-4 ">EXERCISE THERAPY LAB</h3>
          <div className="col-lg-4 col-md-6">
            <div className="single-card">
              <div className="img">
                <a href="">
                  <img src={ExerciseTherapyLab1} alt="Image" height={380}/>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single-card">
              <div className="img">
                <a href="">
                  <img src={ExerciseTherapyLab2} alt="Image" height={380}/>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center mt-5">
          <h3 className="text-center mb-4 ">PHYSIOLOGY LAB</h3>
          <div className="col-lg-4 col-md-6">
            <div className="single-card">
              <div className="img">
                <a href="">
                  <img src={PhysiologyLab1} alt="Image" height={380}/>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single-card">
              <div className="img">
                <a href="">
                  <img 
                    src={PhysiologyLab2} 
                    alt="Image" 
                    height={380}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <br/>
        <br/>
        
      </>
      
    </div>
  )
}

export default InfrastructurePhysiotherapy;
