import React from 'react';
import axios from 'axios';
import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useReactToPrint } from 'react-to-print';
import { PREV_QUESTION_PAPER_GET, PREV_QUESTION_PAPER_GET1 } from '../../Utils/apiConst';
import { ACADEMICS_ADD_CLASS, ACADEMICS_ADD_SECTION, ACADEMICS_ADD_SEMESTER, ACADEMICS_ADD_SUBJECT } from '../../Utils/Academics.apiConst';

function PrevQuestionPapers() {

    const navigate = useNavigate();
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });

    const [data, setData] = useState();

    //All Use States
  const [facultyopt, setFacultyOpt] = useState(
    JSON.parse(localStorage.getItem("COLLEGE"))
  );

  // console.log(facultyopt);

  const [program, setProgram] = useState(
    JSON.parse(localStorage.getItem("PROGRAM"))
  );

  
  const [loading, setLoading] = useState(0);
  const [flag, setFlag] = useState(0);
  const [department, setDepartment] = useState([]);
  
  const [faculty, setFaculty] = useState();
  const [prog, setProg] = useState();
  const [depart, setDepart] = useState();
  const [currentclass, setCurrentClass] = useState();
  const [currentSemester, setCurrentSemester] = useState();
  const [classopt, setClassOpt] = useState([]);
  const [sectionOpt, setSectionOpt] = useState([]);
  const [semesterOpt, setSemesterOpt] = useState([]);
  const [subjectOpt, setSubjectOpt] = useState([]);
  
  useEffect(() => {
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    axios({
      ...config,
      url: `${ACADEMICS_ADD_CLASS}?college_id=${faculty}`,
    })
      .then((res) => {
        setClassOpt(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });

    axios({
      ...config,
      url: `${ACADEMICS_ADD_SECTION}?college_id=${faculty}`,
    })
      .then((res) => {
        setSectionOpt(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });

    axios({
      ...config,
      url: `${ACADEMICS_ADD_SEMESTER}?college_id=${faculty}&class_id=${currentclass}`,
    })
      .then((res) => {
        // console.log("Semester", res.data.data);
        setSemesterOpt(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
      console.log("Fetching semesters for faculty:", faculty, "and class:", currentclass);

      axios({
        ...config,
        url: `${ACADEMICS_ADD_SUBJECT}`,
      })
        .then((res) => {
          setSubjectOpt(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });

  }, [faculty, currentclass]);

  useEffect(() => {
    setDepartment(
      JSON.parse(localStorage.getItem("DEPARTMENT"))?.filter(
        (itemt) => itemt.college_id == faculty
      )
    );
  }, [faculty]);

    const getData = async () => {
        if (
          !faculty ||
          !prog ||
          !depart ||
          !currentclass ||
          !currentSemester
        )
          return toast.error("Please fill all the Fields");
        setLoading(1);
        const config = {
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
          url: `${PREV_QUESTION_PAPER_GET1}?college_id=${faculty}&department_id=${depart}&class_id=${currentclass}&semester_id=${currentSemester}`,
        };
    
        await axios(config)
          .then((res) => {
            console.log("data",res.data.data);
            res.data.data.forEach((element) => {
                element.attachments = JSON.parse(element.attachments);
            });            
            setData(res.data.data);
    
            setFlag(1);
          })
          .catch((err) => {
            console.log(err);
          });
        setLoading(0);
      };

    useEffect(() => {
        getData();
    },[]);

    const openQuestionPaper = (url) => {
        window.open(url, '_blank');
    };


  return (
    <div className="Enrollment-area mt-5 pb-70 ">

        {!flag ? (
        <div className="container">

            <div className="d-flex justify-content-center align-items-center">
                <img
                  src="/assets/images/favicon.png"
                  className="mr-5"
                  width="150"
                  alt="logo"
                />
                <div className="ml-5 text-center">
                  <h2 class="text-uppercase mx-5"> Swaminarayan University</h2>
                </div>
            </div>
            <br/>

            <div className="row">
                <div className="card shadow-sm border-0">
                    <h3 className="card-header text-center">
                      PREVIOUS YEAR QUESTION PAPERS
                      <br />
                    </h3>
                </div>
            </div>

            <div className="card-body">
                <div className="col-md-12">
                    <div className="row">

                    <div className="col-md-6 mt-4">
                        <div className="form-group">
                            <label htmlFor="">Program</label>
                            <select
                                className="form-control"
                                value={prog}
                                onChange={(e) => setProg(e.target.value)}
                            >
                            <option value="">Select Program</option>
                                {program &&
                                  program?.map((item, key) => {
                                    return (
                                    <>
                                      <option value={item?.id}>{item?.name}</option>
                                    </>
                                  );
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="col-md-6 mt-4">
                        <div className="form-group">
                            <label htmlFor="">Faculty</label>
                            <select
                                className="form-control"
                                value={faculty}
                                onChange={(e) => setFaculty(e.target.value)}
                            >
                            <option value="">Select Faculty</option>
                                {facultyopt &&
                                  facultyopt?.map((item, key) => {
                                    return (
                                    <>
                                      <option value={item?.id}>{item?.name}</option>
                                    </>
                                    );
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="col-md-6 mt-4">
                        <div className="form-group">
                            <label htmlFor="">Department</label>
                            <select
                                className="form-control"
                                value={depart}
                                onChange={(e) => setDepart(e.target.value)}
                            >
                            <option value="">Select Department</option>
                              {department &&
                                department?.map((item, key) => {
                                  return (
                                    <option value={item?.id}>{item?.name}</option>
                                  );
                              })}
                            </select>
                        </div>
                    </div>
                    <div className="col-md-6 mt-4">
                        <div className="form-group">
                            <label htmlFor="">Class</label>
                            <select
                                className="form-control"
                                value={currentclass}
                                onChange={(e) => setCurrentClass(e.target.value)}
                            >
                            <option value="">Select Class</option>
                              {classopt
                                ?.filter((s) => s?.department_id == depart)
                                ?.map((i, key) => (
                                  <option value={i?.id}>{i?.name}</option>
                              ))}
                            </select>
                        </div>
                    </div>
                    <div className="col-md-6 mt-4">
                        <div className="form-group">
                            <label htmlFor="">Semester</label>
                            <select
                                className="form-control"
                                value={currentSemester}
                                onChange={(e) => setCurrentSemester(e.target.value)}
                            >
                            <option value="">Select Semester</option>
                              {semesterOpt
                                ?.filter((s) => s.class_id == currentclass)
                                ?.map((i, key) => (
                                  <option value={i?.id}>{i?.name}</option>
                              ))}
                            </select>
                        </div>
                    </div>

                    </div>
                </div>
            </div>

            <br />
            <div className="col-md-12 mt-4">
                <button
                    className="btn btn-primary d-print-none float-right"
                    onClick={getData}
                >
                    Submit
                </button>
            </div>

        </div>

) : (
    <div className="container p-5" ref={componentRef}>
      <div className="d-flex justify-content-center align-items-center">
        <img
          src="/assets/images/favicon.png"
          className="mr-5"
          width="150"
          alt="logo"
        />
        <div className="mx-3 text-center">
          {/* <h4>સ્વામિનારાયણ યુનિવર્સિટી, કલોલ</h4> */}
          <h2 class="text-uppercase"> Swaminarayan University</h2>
        </div>
      </div>

      <br/>
      <br/>

      <h3 class="text-uppercase text-center">
        Previous Year Qusetion Papers
      </h3>

      <br />

      
        <div className="table-responsive ">

          <table className="table table-bordered result ">
            <thead>
              <tr>
                <th>Faculty Name :
                    <p></p>
                </th>
                <td>
                    {facultyopt?.find((s) => s?.id == data[0]?.college_id)?.name || "N/A"}
                </td>

                <th>Department :
                    <p></p>
                </th>
                <td>
                    {department?.find(s=>s?.id == data[0]?.department_id)?.name}
                </td>
              </tr>
              <tr>
                <th>Class :
                    <p></p>
                </th>
                <td>
                    {classopt?.find(s=>s?.id == data[0]?.class_id)?.name}
                </td>

                <th>Semester :
                    <p></p>
                </th>
                <td>
                    {semesterOpt?.find(s=>s?.id == data[0]?.semester_id)?.name}
                </td>
              </tr>
            </thead>
          </table>

          {/* <div class="card">
            <div className="card-body"> */}
                {/* <h5 class=""> Previous Year Question Papers</h5> */}

            <table className="table result table-bordered ">
              <thead>
                <tr>
                  <th>
                    Sl.No. 
                    <p></p>  
                  </th>
                  <th>
                    Course Name - Code
                    <p></p>
                  </th>
                  <th>
                    Title 
                    <p></p>
                  </th>
                  <th>
                    To View Qusetion Papers Click Here <p></p>
                  </th>                
                </tr>             
              </thead>
              
              <tbody>
                {data &&
                    data
                        .map((item, key) => {
                          if (item.status == "ACTIVE") {
                            
                            {
                              return (
                                <tr key={key}>
                                  
                                  <td>{key + 1}</td>
                                  <td>
                                      {subjectOpt?.find(s=>s?.id == item?.subject_id)?.name} - {subjectOpt?.find(s=>s?.id == item?.subject_id)?.code}
                                  </td>
                                  <td>{item?.title}</td>
                                  <td>
                                    <a href={item?.attachments} target="_blank">
                                        View Question Paper
                                    </a>
                                  </td>
                                  {/* <td>
                                    <button
                                      onClick={() => openQuestionPaper(item?.attachments)}
                                      className="text-primary"
                                    >
                                      View Question Paper
                                    </button>
                                  </td> */}
                
                                </tr>
                              );                
                            } 
                          }
                        })
                }
              </tbody>
            </table>

            {/* </div>
          </div> */}
          
        </div>


      <div className="row mt-3">
        <div className="col-md-12">
          <div className="float-end text-center mb-5 mt-4"></div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <button
            onClick={() => setFlag(0)}
            className="btn btn-primary d-print-none mx-3"
          >
            Back
          </button>
        
        </div>
      </div>
    </div>
  )}
      
    </div>
  )
}

export default PrevQuestionPapers;
