import React from "react";

export default function Eligibility() {
  return (
    <div>
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-md-12">
            <p>
              <span style={{ fontSize: "18pt" }}>
                <strong>Fee Structure</strong>
              </span>
              <br />
            </p>

            <p>
              <strong>Academic Year (2022-23)</strong>
              <br />
            </p>
            <table
              className="table table-bordered"
              style={{ borderCollapse: "collapse", width: "100%", height: 173 }}
              border={1}
            >
              <tbody>
                <tr style={{ height: 20 }}>
                  <td>
                    <strong>Course</strong>
                  </td>
                  <td>
                    <strong>Govt. Quota (Per Annum) </strong>
                  </td>
                  <td>
                    <strong>Management Quota (Per Annum)</strong>
                  </td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td>B.A.M.S</td>
                  <td>Rs 2,71,000/-</td>
                  <td>Rs 3,85,000/-</td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td>MD/MS</td>
                  <td>Rs 4,25,000/-</td>
                  <td>Rs 6,25,000/-</td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td>Ph.D</td>
                  <td>---</td>
                  <td>Rs 1,20,000/-</td>
                </tr>
              </tbody>
            </table>
            *Fixed by SFI, Gujarat

            <br />
            <br />
            <table
              className="table table-bordered"
              style={{ borderCollapse: "collapse", width: "100%", height: 173 }}
              border={1}
            >
              <tbody>
                <tr style={{ height: 20 }}>
                  <td>
                    <strong>Hostel Name</strong>
                  </td>
                  <td>
                    <strong>Facility</strong>
                  </td>
                  <td>
                    <strong>Fee (Per Annum)</strong>
                  </td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td>Boys Hostel</td>
                  <td>Non AC</td>
                  <td>Rs 70,000/-</td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td rowSpan={6}>Girls Hostel</td>
                  <td>Non AC - 3 Students</td>
                  <td>Rs 85,000/-</td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td>Non AC - 4 Students</td>                  
                  <td>Rs. 80,000/-</td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td>Non AC - 8 Students</td>                  
                  <td>Rs. 70,000/-</td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td>AC - 3 Students</td>                  
                  <td>Rs. 1,00,000/-</td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td>AC - 4 Students</td>                  
                  <td>Rs. 95,000/-</td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td>AC - 8 Students</td>                  
                  <td>Rs. 85,000/-</td>
                </tr>
              </tbody>
            </table>
          <p>
            Note:<br/>
            <ol>
              <li>Hostel Stay is Compulsory for all outstation students(Except Residents of Kalol/Ahmedabad/Gandhinagar)</li>
              <li>Refundable Depsit of Rs.5000 shall be paid separately.</li>
              <li>Hostel fees has to paid annually only.</li>
              <li>Room allotment is done on first come first serve basis.</li>
              <li>The fee includes pure veg. Indian meal with accommodation, limited breakfast with tea, lunch & dinner.</li>
              <li>Students have to get their bed sheet, pillow & cover, water jug, bucket, tumbler and other necessary things.</li>
            </ol>
          </p>
          </div>
        </div>
      </div>
    </div>
  );
}
