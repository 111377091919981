import React from 'react'

function AdmissionsArts() {
  return (
    <div>

<div className="container mt-5 mb-5">


  <div className="row">
    <div className="col-md-12">
      <p>
        The process of Admission to BHMS Course starts after declaration of
        results of 12th (HSC) Science and NEET. The minimum requirement for
        admission includes passing marks in Physics, Chemistry. Biology with
        English and with compulsory NEET.
      </p>
      <p>
        Every year the admissions are done by Gujarat State Govt. through{" "}
        <a href="#">
          Admission committee for professional medical educational courses
          (ACPMEC)
        </a>
      </p>
      <p>
        <a href="#">
          Admission Committee for Professional Under Graduate Medical Courses
          (ACPUGMEC)
        </a>{" "}
        Constituted under Section 4 of the Gujarat Professional Medical
        Education Institutions, Regulation of Admission and Fixation of Fees
        Act., 2007, Guj.3 of 2008 Government of Gujarat, Gandhinagar
      </p>
      <p>
        For admissions to first year B.H.M.S. All India quota is 15 seats,
        Gujarat State Government quota is 60 seats, NRI quota is 15 seats and
        Management quota is 10 seats out of total 100 seats each year.
      </p>
      <p>
        For fees according to the Fee Regulatory Committee (Government of
        Gujarat)
      </p>
      <p>
        For admissions and inquiry:
        <br />
        Contact Us:
      </p>
      <p>
        Dr. Jayadatt Mehta,&nbsp; Contact - 8487878000,&nbsp; Dr Dinesh Rao,
        Contact - 9323691618
      </p>
      <p>
        Email: sshckalol@gmail.com
        <br />
      </p>
    </div>
  </div>
</div>





    </div>
  )
}

export default AdmissionsArts;