import React from 'react'
import axios from 'axios';
import { useState,useEffect,useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { COLLEGE_PERMISSION_GET } from '../../Utils/InfoUploadingConst';

function AffiliationPhysio() {
    const [data, setData] = useState([]);

  const navigate = useNavigate();

  const getData = async () => {
      const config = {
          method: "get",
          url: COLLEGE_PERMISSION_GET,
          headers: {
              "Content-Type": "application/json",
          },
      };

      await axios(config)
          .then((res) => {
              res.data.data.sort((a, b) => b.id - a.id);
              res.data.data.forEach((element) => {
                  element.attachments = JSON.parse(element.attachments);
              });
              console.log(res.data.data)
              setData(res.data.data);

          })
          .catch((err) => {
              console.log(err);
          });
  };

  useEffect(() => {
      getData();
  }, []);


  return (
    <div>

<div className="container mt-3">
        <div className="row register" style={{ maxWidth: "100%" }}>
          <div className="col-md-12">
            <h3>
              AFFILIATION           
            </h3>
            
          </div>

        
<div className="container">

  
<div className="alumni-area">
<div className="row">

{data
    ?.filter((item) => item.type === "College Affiliation")
    ?.map((item, key) => {
      return (
      item.status === "ACTIVE" && item.college_id == "1111014"  &&( 
<div className="col-md-4">
<a href={item?.attachments} target="_blank">
  <div className="single-alumoni-updates-card">
  <div className=" d-flex flex-row flex-wrap align-items-center ">
    <div className=" bg-danger text-center p-3 me-3">
    <i class="ri-file-download-line text-light " style={{fontSize:"20px"}}></i>
    </div>
   <div className="">
    <h5>
      {item?.title} Affiliation
    </h5>
   </div>
  </div>
</div>
</a>
  </div>
  )
  )
  })
  }

</div>

</div>
</div>
         
        </div>
      </div>

      {/* <h3>Affiliated University</h3>
      <div className="container-fluid">
      <table className='table table-bordered'
        style={{ borderCollapse: "collapse", width: "100%", height: 60 }}
        border={1}
      >
        <tbody>
          <tr>
            <td style={{ width: "50%", height: 10 }}>
              Name of University
              <br />
            </td>
            <td style={{ width: "50%", height: 10 }}>Swaminarayan University</td>
          </tr>
          <tr>
            <td style={{ width: "50%", height: 10 }}>
              Address
              <br />
            </td>
            <td style={{ width: "50%", height: 10 }}>
            Ahmedabad-Mehsana Highway, Kalol, Gandhinagar - 382725, Gujarat.
            </td>
          </tr>
          <tr>
            <td style={{ width: "50%", height: 20 }}>
              Phone
              <br />
            </td>
            <td style={{ width: "50%", height: 20 }}>
              +91-9879614190, 1800-843-2900
            </td>
          </tr>
          <tr>
            <td style={{ width: "50%", height: 10 }}>
              Fax
              <br />
            </td>
            <td style={{ width: "50%", height: 10 }}></td>
          </tr>
          <tr>
            <td style={{ width: "50%", height: 10 }}>
              Website
              <br />
            </td>
            <td style={{ width: "50%", height: 10 }}>
              www.swaminarayanuniversity.ac.in
            </td>
          </tr>
        </tbody>
      </table>
      </div>
      <p>
        <br />
      </p>
      <h3>Office Bearers</h3>
      <div className="container-fluid">
      <table className='table-bordered table'
        style={{ borderCollapse: "collapse", width: "100%", height: 84 }}
        border={1}
      >
        <tbody>
          <tr>
            <td style={{ width: "25%" }}>
              <strong>Name</strong>
              <br />
            </td>
            <td style={{ width: "25%" }}>
              <strong>Designation</strong>
              <br />
            </td>
            <td style={{ width: "25%" }}>
              <strong>Contact No.</strong>
              <br />
            </td>
            <td style={{ width: "25%" }}>
              <strong>Email</strong>
              <br />
            </td>
          </tr>
          <tr>
            <td style={{ width: "25%" }}>Prof (Dr.) M. S. Rao</td>
            <td style={{ width: "25%" }}>Vice-Chancellor</td>
            <td style={{ width: "25%" }}>+91-6351047973</td>
            <td style={{ width: "25%" }}>provost@swaminarayanuniversity.ac.in</td>
          </tr>
          <tr>
            <td style={{ width: "25%" }}>Dr. A K Gangawane</td>
            <td style={{ width: "25%" }}>
              Registrar
            </td>
            <td style={{ width: "25%" }}></td>
            <td style={{ width: "25%" }}></td>
          </tr>
        </tbody>
      </table>
      </div>
      <p>
        <br />
      </p> */}

    </div>
  )
}

export default AffiliationPhysio;
