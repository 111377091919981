import React from 'react';

function NCH2() {
  return (
    <div>

        <div className="container mt-5 mb-5">

            <div className="row register" style={{ maxWidth: "100%" }}>
                <div className="col-md-12">
                  <h3>
                  	Any other information by the Commission:        
                  </h3>
                </div>

        
                <div className="container">  
                  <div className="alumni-area mt-4">
                    <div className="row">
                      <div className="col-md-10">
         
                            <h6>1.	NCH Latest News : <a href="https://nch.org.in/news">https://nch.org.in/news</a></h6>
                            <br/>
                            <h6>2.	NCH Gazette Notifications : <a href="https://nch.org.in/gazette ">https://nch.org.in/gazette </a></h6>
                            <br/>

                      </div>
                    </div>
                  </div>
                </div>
         
            </div>
       
        </div>
      
    </div>
  )
}

export default NCH2;
