import React from 'react'

function CollegeCouncil() {
  return (
    <div><div className="container register mb-5" style={{maxWidth:'1300px'}}>
    <div className="row">
      <h3 className="col-md-12 text-uppercase">COLLEGE COUNCIL Members</h3>
      <div className="col-md-12" style={{ textAlign: "justify" }}>
        Shree Swaminarayan Homoeopathy College has constituted a College Council
        as per the guidelines of Central Council of Homoeopathy under the
        Chairmanship of Principal to formulate, implement, and review academic
        related activities as per the vision and mission of the institution. The
        College Council is the chief decision making body and it is advisory in
        nature. The college council is the major committee of the institution for
        making, important decisions on curricular, co-curricular activities. The
        College Council shall meet at least four times in an academic year to draw
        up the details of curriculum and training programme, enforcement of
        discipline and other academic matters. The Council shall also organize
        inter-departmental meetings including periodical review.
      </div>
      <div className="col-md-12" style={{ textAlign: "justify" }}>
        <br />
      </div>
      <div className="col-md-12" style={{ textAlign: "justify" }}>
        
      </div>
      <div className="col-md-12" style={{ textAlign: "justify" }}>
        <strong />
        <br />
      </div>
      <div className="col-md-12" style={{ textAlign: "justify" }}>
        <table className='table table-bordered text-center'
          style={{ width: "100%", borderCollapse: "collapse", height: 100 }}
          cellSpacing={5}
          cellPadding={5}
          border={1}
        >
          <tbody>
            <tr >
             
              <td style={{ width: "20.8668%"}}>
                <strong>Name of The Department</strong>
              </td>
              <td style={{ width: "28.7596%" }}>
                <strong>Head of Department</strong>
              </td>
            
            </tr>
            <tr >
              
              <td style={{ width: "20.8668%"}}>
              Principal & Medical Superitendant (Chairperson)
                <br />
              </td>
              <td style={{ width: "28.7596%" }}>
              Dr(Prof) Dinesh Rao
                <br />
              </td>
            </tr>
            <tr>
              <td>
                Anatomy
              </td>
              <td>Dr(Prof) D. N. Roy</td>
            </tr>
            <tr>
              <td>Physiology</td>
              <td>Dr(Prof) Karuna Roy</td>
            </tr>
           <tr>
           
<td>Hom. Pharmacy</td>
<td>Dr(Prof) Sandya Sahu</td>
           </tr>
           <tr>
            <td>Pathology</td>
            <td>Dr(Prof)Vivek Tiwari</td>
           </tr>
           <tr>
            <td>Forensic Medicine and Toxicology</td>
            <td>Dr. Jyoti Jaiswar</td>
           </tr>
           <tr>
            <td>Surgery</td>
            <td>Dr(Prof) Janmuna Rawat</td>
           </tr>
           <tr>
            <td>Gynecology and Obstretics</td>
            <td>Dr. Archana Raj</td>
           </tr>
           <tr>
            <td>
              Practice of Medicine
            </td>
            <td>Dr. Dinesh Rao</td>
           </tr>

           <tr><td>Community Medicine</td>
           <td>Dr. Anupama Tiwari (I/C)</td></tr>
           <tr>
            <td>Hom. Materia Medica</td>
            <td>Dr.(Prof) Brahmadeo Mishra</td>
           </tr>
           <tr>
            <td>Organon of Medicine</td>
            <td>Dr.(Prof) Jatin Dhagat</td>
           </tr>
           <tr>
            <td>
              Repertory
            </td>
            <td>Dr.(Prof) Dhwanika Dhagat</td>
           </tr>
           <tr>
            <td>
              Trustee
            </td>
            <td>
              Swami Bhaktavatsaladasji
            </td>
           </tr>
          </tbody>
        </table>
        <p>
          <br />
        </p>
        <p>
          <br />
        </p>
      </div>
    </div>
  </div>
  </div>
  )
}

export default CollegeCouncil