import React from 'react';

function NodalOfficer() {
  return (
    <div>

        <div className="container mt-5 mb-5">

            <div className="row register" style={{ maxWidth: "100%" }}>
                <div className="col-md-12">
                  <h3>
                    Nodal Officer     
                  </h3>
                </div>

        
                <div className="container">  
                  <div className="alumni-area mt-4">
                    <div className="row">
                      <div className="col-md-12">
         
                            <table
                              className="table table-bordered custom-table" style={{ height: '100px', maxWidth: "100%" , textAlign: "center" }}
                            >
                                <thead style={{ height: '50px'}}>
                                    <tr>
                                        <th>NAME</th>
                                        <th>DESIGNATION</th>
                                        <th>EMAIL ID</th>
                                        <th>CONTACT NO.</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>DR. VIVEK KUMAR</td>
                                        <td>PROFESSOR IN DEPARTMENT OF PATHOLOGY</td>
                                        <td>dr.vivektiwari@swaminarayanuniversity.ac.in</td>
                                        <td>9827542668</td>
                                    </tr>
                                </tbody>
                            </table>

                      </div>
                    </div>
                  </div>
                </div>
         
            </div>
       
        </div>
      
    </div>
  )
}

export default NodalOfficer;
