import React from 'react'

function AboutCollege() {
  return (
    <div><>
    {/* Start For Content */}
    <div className="container mt-5 mb-5">
      <div className="row">
        <div className="col-md-3">
        
            <img
              src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/media/undefined_1713952978730.jpg"
              alt=""
              className="img-responsive img-thumbnail"
              width={400}
              height={600}
            />
            <br />
            <br />

       
       
        </div>
        <div className="col-md-9">
          <h2>
          About College
            <br />
          </h2>
          <p style={{ textAlign: "justify" }}>
      
            With the blessings of Shree Swami Premswarupdasji Maharaj “Shree Swaminarayan Homoeopathic Medical College & Hospital” came into the existence in the year of 2017. The sole Aim and Object of this college is to provide Homoeopathic treatment to the poor and needy people of Kalol & its surroundings. Swamiji looks forward to the development of the Homoeopathic System of Medicine through teaching and research and to utilize the Homoeopathic system of medicine for the benefit of common mass. The institute is permitted by the Central Council of Homoeopathy, CCH and approved by AYUSH. The college is affiliated to the Gujarat University, Gujarat for its undergraduate course. The college has an intake capacity of 100 undergraduate students (B.H.M.S.) per year.
          </p>
          <p  style={{ textAlign: "justify" }}>
          In Continuation of the true spirit of charity, SHREE SWAMINARAYAN
          VISHVAMANGAL GURUKUL, KALOL is equality and strongly committed to the
          philosophy of offering technology-centered education routed in the
          ideas of charity and philanthropy. The Trust is a distinguished
          organization committed to the multidimensional growth of education
          fraternity. The trust is well aware of the social, administrative and
          technological needs of contemporary society, which it targets to
          fulfill through education.
        </p>
          
        </div>
      </div>
      
    </div>{" "}
    {/* End For Content */}
    
  </>
  </div>
  )
}

export default AboutCollege