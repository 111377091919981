import React from 'react';

function AboutCollegePhysio() {
  return (

    <div>

    <div className="container mt-5">

  <h1 className="mb-4 text-center">About College</h1>

      {/* <div className="row"> */}
      <div className="row register" style={{maxWidth: "100%"}}>
        <div className="col-md-4">
          <br/>
          <br/>
          <br/>
          <br/>
          <img
            // src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/faculties/homoeopathy/assets/1637632899_2902-org.jpg"
            // src="../assets/images/campus-information/campus-3.jpg"
            src="../assets/physiotherapy/Campus Image.jpg"
            alt="College"
            className="img-fluid rounded"
          />
        </div>
        <div className="col-md-8">
          {/* <h2 className="mb-4">About College</h2> */}
            <p className="text-justify mb-4" >
                      We are aﬃliated to Swaminarayan University since 2023. Our pious mission to start this 
                      institute is to provide education, to include self-reliance in young minds and to groom 
                      young generation for better future by inducing self-confidence in students and to prepare 
                      for professional environment.  Physiotherapy is a crucial component of the healthcare 
                      industry, encompassing rehabilitation and diverse treatment modalities for individuals 
                      across all age groups. 
                    </p>
                    <p className="text-justify mb-4">
                   The field of physiotherapy is rapidly expanding, offering promising career opportunities 
                   in India and abroad as the demand continues to rise. We provide care for individuals with 
                    pediatric, neurological, orthopedic, sports rehabilitation, women's health, geriatric, 
                cardio-pulmonary, and various other conditions.
                    </p>
          <p className="text-justify mb-4">
                    Our institution offers a wide range of modern amenities such as contemporary classrooms, 
                     fully-equipped laboratories, a cafeteria, and a 350-bed multi-specialty hospital on campus. 
                     The hospital features an ICU, NICU, emergency departments, and different wards, including 
                  a well-equipped physiotherapy department with regular outpatient services. Additionally, 
                     we organize various awareness campaigns and free physiotherapy camps. We have a team of highly 
                     educated internal and external faculty members. 
                    </p>
        </div>

        <div className="row mt-5">
        <div className="col-md-12">
          <h3 className="text-center mb-4">Graduate Program 2023-24</h3>
          <div className="card mb-4">
            <div className="card-body">
              <h4 className="card-title">Bachelor of Physiotherapy</h4>
              <ul className="list-unstyled mb-0">
                <li>Course type: UG</li>
                <li>Duration of course: 4 years</li>
                <li>Course intake: 50</li>
                <li>Fees: ₹77,000/-</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-5">
        <div className="col-md-12">
          <h3 className="text-center mb-4">Highlights</h3>
            <p>
              1)  Canteen amenities.
            </p>
            <p>
              2) Accommodation facilities for both female and male students.
            </p>
            <p>
              3) Transport services availability.
            </p>
            <p>
               4) Interactive classrooms equipped with multimedia technology. 
             </p>
            <p>
               5) On-site parent hospital with 350 beds.
             </p>
             <p>
               6) Proficient teaching and administrative staff.
             </p>
             <p>
               7) Opportunities for scholarships and on-campus job placements.
             </p>
             <p>
               8) Diverse range of academic, co-curricular, and extracurricular activities.
             </p>
             <p>
               9) Comprehensive education encompassing theoretical and practical aspects with access to a wide range of patients on campus.
             </p>
            <p>
               10)  Serene and spiritual atmosphere.
             </p>
             <p>
               11)  Practical learning in a professional setting.
             </p>
             <p>
               12)  Regular organization of hands-on workshops, seminars, and conferences.
             </p>
            <p>
              13)  Educational trips to various regions of India.
            </p>
            <p>
              14)  Promotion of sports achievements at different levels with training support.
             </p>
        </div>
      </div>

      </div>

    </div>

    </div>

  )
}

export default AboutCollegePhysio;