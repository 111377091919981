import React from "react";

export default function ArtsEligibility() {
  return (
    <div>
      <div className="container mt-5 mb-5">
        <div className="row register" style={{ maxWidth: "100%" }}>
          <div className="col-md-12">
            <p>
              <span style={{ fontSize: "18pt" }}>
                <h3>IT and CS Eligibility</h3>
              </span>
              <br />
            </p>

            <p>
              <strong>All Eligibility Details:</strong>
              <br />
            </p>
            <table
              className="table table-bordered"
              style={{ borderCollapse: "collapse", width: "100%", height: 173 }}
              border={1}
            >
              <tbody>
                <tr style={{ height: 20 }}>
                  <td>
                    <strong>Sr No</strong>
                    <br />
                  </td>
                  <td>
                    <strong>Course Name</strong>
                    <br />
                  </td>
                  <td>
                    <strong>Eligibility</strong>
                    <br />
                  </td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td>
                    1
                    <br />
                  </td>
                  <td>
                    BCA
                    <br />
                  </td>
                  <td>10 +2 any stream</td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td>
                    2
                    <br />
                  </td>
                  <td>
                    MCA
                    <br />
                  </td>
                  <td>
                    Graduation any stream with 40%
                    <br />
                  </td>
                </tr>

                <tr style={{ height: 20 }}>
                  <td>
                    3
                    <br />
                  </td>
                  <td>
                    B.Sc IT
                    <br />
                  </td>
                  <td>
                    10+2 any Stream
                    <br />
                  </td>
                </tr>

                <tr style={{ height: 20 }}>
                  <td>
                    4
                    <br />
                  </td>
                  <td>
                    M.Sc IT
                    <br />
                  </td>
                  <td>
                    Graduation any stream with 40%
                    <br />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
