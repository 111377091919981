import React from 'react'

function AboutCollegeDesign() {
  return (
    <div>

    <div className="container mt-5">
      <h1 className="mb-4 text-center">About College</h1>

      <div className="row register" style={{maxWidth: "100%"}}>

      <div className="row">
        <div className="col-md-4">
          <br/>
          <img
            src="../assets/physiotherapy/Campus Image.jpg"
            alt="College"
            className="img-fluid rounded"
          />
        </div>
        <div className="col-md-8">
          <p className="">
            Welcome to the Faculty of Design at Swaminaryan University, where creativity meets innovation! We are committed to nurturing aspiring designers and providing them with the tools and knowledge to excel in the dynamic world of design. Whether you're passionate about graphic design, fashion design, or industrial design, we offer comprehensive programs tailored to your interests and career aspirations.
          </p>
          <p>
            Our institution offers a wide range of modern amenities such as contemporary classrooms, fully-equipped laboratories, a cafeteria, and a 350-bed multi-specialty hospital on campus. The hospital features an ICU, NICU, emergency departments, and different wards, including a well-equipped physiotherapy department with regular outpatient services. Additionally, we organize various awareness campaigns and free physiotherapy camps. We have a team of highly educated internal and external faculty members.
          </p>
        </div>
      </div>

      <div className="row mt-5">
        <div className="col-md-12">
        <h3>Why Choose Us?</h3>
            <p>
              Experienced Faculty: Learn from industry experts and seasoned professionals who are dedicated for 
              helping you unlock your full potential.
            </p>
            <p>
              State-of-the-Art Facilities: Our institute is equipped with modern design studios, computer labs, and workshops to facilitate hands-on learning experiences.
            </p>
            <p>
              Industry Connections: Gain valuable insights and networking opportunities through our partnerships with leading design firms and organizations.
            </p>
            <p>
              Creative Environment: Immerse yourself in a vibrant and supportive community of fellow designers where collaboration thrives.
            </p>
            <br/>
            <br/>
        </div>
        <div className="row mt-2">
        <div className="col-md-6">
          <h3>Student Life</h3>
          <p>At Swaminaryan University Faculty of Design, education goes beyond the classroom. Get involved in student clubs, design competitions, and workshops to enhance your skills and make lasting connections.</p>
          <h3>Career Opportunities</h3>
          <p>With a degree from Swaminaryan University Faculty of Design, you'll be prepared for diverse career opportunities in the ever-evolving field of design. Our graduates go on to become successful designers, creative directors, entrepreneurs, and more.</p>
        </div>
        <div className="col-md-6">
          <h3>Stay Connected</h3>
          <p>Follow us on social media to stay updated on the latest news, events, and student achievements at Swaminaryan University Faculty of Design.</p>
          <h3>Visit Us</h3>
          <p>Experience our campus and learn more about our programs by scheduling a campus tour. We look forward to welcoming you to Swaminaryan University Faculty of Design.</p>
          <br/>
          <br/>
          <br/>
          <br/>
        </div>
        <h3>Contact Us</h3>
          <p>Have questions about our programs or admissions process? Don't hesitate to reach out to our friendly staff for assistance.</p>
        </div>
      </div>

      </div>

    </div>
      
    </div>
  )
}

export default AboutCollegeDesign;
