import React from 'react'

function AdmissionsDesign() {
  return (
    <div>
      
    </div>
  )
}

export default AdmissionsDesign;
