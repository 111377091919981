import React from 'react'
import { Link } from 'react-router-dom'

function After10th() {
  return (
    <div className='After10th '>
<div class="page-banner-area bg-2">
<div class="container">
<div class="page-banner-content">
<h1>After 10<sup>th</sup></h1>
<ul>
<li> <Link to={'./../'}>  Home </Link></li>


<li>After 10 <sup>th</sup></li>
</ul>
</div>
</div>
</div>
<div className="container mt-5">
<div className="row">
    <div className="col-md-9"><div className=" register graduate-admission pt-100 pb-70" style={{maxWidth:'1320px'}} >
<div className="container">
   <div className="row">

    <div className="col-md-12">
    <h2 className='text-center text-light' style={{backgroundColor: "#FC7F03"}}>After 10<sup>th</sup>
     </h2>
    <table className="table table-bordered">
        <thead>
           
            <tr>
                <th>Name of Program</th>
                <th>Branch </th>
                <th className='text-center' width="45%">Eligibility </th>
                <th className='text-center'>Intake </th>
            </tr>
        </thead>
        <tbody >
            <tr>
                <td>Diploma Engineering</td>
                <td>Automobile </td>
                <td className='text-center'>10th Pass and English and maths as  compulsory subjects</td>
                <td className='text-center'>30</td>
            </tr>

            <tr>
                <td>Diploma Engineering</td>
                <td >Mechanical  </td>
                <td className='text-center' >10th Pass with English and Maths as compulsory  Subject

</td>
                <td className='text-center'>60</td>
            </tr>

            <tr>
                <td>Diploma Engineering</td>
                <td>Electrical 
Engineering

 </td>
                <td className='text-center'>10th Pass and English and maths as  compulsory subjects</td>
                <td className='text-center'>60</td>
            </tr>
            <tr>
                <td>Diploma Engineering</td>
                <td>Computer Engineering 

 </td>
                <td className='text-center'>10th Pass and English and maths as  compulsory subjects</td>
                <td className='text-center'>120</td>
            </tr>
            <tr>
                <td>Diploma Engineering</td>
                <td>Information Technology  

 </td>
                <td className='text-center'>10th Pass and English and maths as  compulsory subjects</td>
                <td className='text-center'>60</td>
            </tr>
            <tr>
                <td>Diploma Engineering</td>
                <td>Civil Engineering 

 </td>
                <td className='text-center'>10th Pass and English and maths as  compulsory subjects</td>
                <td className='text-center'>30</td>
            </tr>
            <tr>
                <td>Diploma Engineering  (C 2 D)
</td>
                <td>Automobile, Mechanical, Electrical, Computer  
Engineering, Information  Technology, Civil  
Engineering

 </td>
                <td className='text-center'>ITI Pass with Allied Subject </td>
                <td className='text-center'>Subject to  
Availability
</td>
            </tr>
        </tbody>
    </table>
    </div>
   
   </div>

</div>
</div></div>
<div class="col-lg-3">
<div class="additional-information">
<h3>What ?? </h3>
<div class="list">
<ul>
<li><Link to={'/after10th'} className="nav-link">After 10<sup>th</sup> </Link></li>
<li><Link to={'/after12th'} className="nav-link">
                        AFTER 12th
                        </Link></li>
<li><Link to={'/afterUG'} className="nav-link">
                        AFTER UG
                        </Link></li>
<li><Link to={'/afterPG'} className="nav-link">
                        AFTER PG 
                        </Link></li>

</ul>
</div>
</div>
</div>
</div>
</div>


    </div>
  )
}

export default After10th