import React from "react";

// Image Urls
const pic1 = "/assets/law/images/pic1.jpg";
const pic2 = "/assets/law/images/pic2.jpg";
const pic3 = "/assets/law/images/pic3.jpg";
const pic4 = "/assets/law/images/pic4.jpg";
const pic5 = "/assets/law/images/pic5.jpg";
const pic6 = "/assets/law/images/pic6.jpg";
const pic7 = "/assets/law/images/pic7.jpg";
const pic8 = "/assets/law/images/pic8.jpg";
const pic9 = "/assets/law/images/pic9.jpg";
const pic10 = "/assets/law/images/pic10.jpg";
const pic11 = "/assets/law/images/pic11.jpg";
const pic12 = "/assets/law/images/pic12.jpg";


function Infrastructure() {
  return (
    <div className="Achievements">
      <>
        <div className="campus-life-area pt-100 pb-70">
          <div className="container">
            <h3 className="text-center mb-4 ">Achievements</h3>
            <p className="text-center">
            Pictures of Achivements by staff and Students of Faculty of Law.
            </p>
          </div>
        </div>

        

        <div className="row justify-content-center mt-5">
          <div className="col-lg-4 col-md-6">
            <div className="single-campus-card">
              <div className="img">
                <a href="">
                  <img src={pic1} alt="Image" />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single-campus-card">
              <div className="img">
                <a href="">
                  <img src={pic2} alt="Image" />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center mt-5">
          <div className="col-lg-4 col-md-6">
            <div className="single-campus-card">
              <div className="img">
                <a href="">
                  <img src={pic3} alt="Image" />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single-campus-card">
              <div className="img">
                <a href="">
                  <img src={pic4} alt="Image" />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center mt-5">
          <div className="col-lg-4 col-md-6">
            <div className="single-campus-card">
              <div className="img">
                <a href="">
                  <img src={pic5} alt="Image" />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single-campus-card">
              <div className="img">
                <a href="">
                  <img src={pic6} alt="Image" />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center mt-5">
          <div className="col-lg-4 col-md-6">
            <div className="single-campus-card">
              <div className="img">
                <a href="">
                  <img src={pic7} alt="Image" />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single-campus-card">
              <div className="img">
                <a href="">
                  <img src={pic8} alt="Image" />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center mt-5">
          <div className="col-lg-4 col-md-6">
            <div className="single-campus-card">
              <div className="img">
                <a href="">
                  <img src={pic9} alt="Image" />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single-campus-card">
              <div className="img">
                <a href="">
                  <img src={pic10} alt="Image" />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center mt-5">
          <div className="col-lg-4 col-md-6">
            <div className="single-campus-card">
              <div className="img">
                <a href="">
                  <img src={pic11} alt="Image" />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single-campus-card">
              <div className="img">
                <a href="">
                  <img src={pic12} alt="Image" />
                </a>
              </div>
            </div>
          </div>
        </div>

        
        
      </>
    </div>
  );
}

export default Infrastructure;
