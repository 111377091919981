import React from "react";

export default function Eligibility() {
  return (
    <div>
      <div className="container mt-5 mb-5">
        <div className="row register" style={{ maxWidth: "100%" }}>
          <div className="col-md-12">
            <p>
              <span style={{ fontSize: "18pt" }}>
                <h3>Eligibility</h3>
              </span>
              <br />
            </p>

            <p>
              <strong>Eligibility Details:</strong>
              <br />
            </p>
            <table
              className="table table-bordered"
              style={{ borderCollapse: "collapse", width: "100%", height: 173 }}
              border={1}
            >
              <tbody>
                <tr style={{ height: 20 }}>
                  <td>
                    <strong>Sr No</strong>
                    <br />
                  </td>
                  <td>
                    <strong>Course Name</strong>
                    <br />
                  </td>
                  <td>
                    <strong>Duration</strong>
                    <br />
                  </td>
                  <td>
                    <strong>Eligibility</strong>
                    <br />
                  </td>
                  <td>
                    <strong>
                      General Category (including Physically Disabled)
                    </strong>
                    <br />
                  </td>
                  <td>
                    <strong>
                      Reveresed (SC, ST, SEBC including Physically Disabled)
                      Categories
                    </strong>
                    <br />
                  </td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td>
                    1
                    <br />
                  </td>
                  <td>
                    MSC Nursing
                    <br />
                  </td>
                  2 Years
                  
                  <td>
                    Minimum one year of work experience after Basic B.Sc.
                    Nursing. Minimum one year of work experience prior or after
                    Post Basic B.Sc. Nursing.
                  </td>
                  <td>BSC/ PBBSC PASS WITH 55%</td>
                  <td>BSC/ PBBSC PASS WITH 55%</td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td>
                    2
                    <br />
                  </td>
                  <td>
                    BSC Nursing
                    <br />
                  </td>
                  4 Years
                  
                  <td>
                    HSC passed with Physics, Chemistry and Biology Theory and
                    Practical and English in percentage The minimum age for
                    admission shall be 17 years on or before 31st December
                  </td>
                  <td>45%</td>
                  <td>40%</td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td>
                    3
                    <br />
                  </td>
                  <td>
                    POST BASIC BSC NURSING
                    <br />
                  </td>
                  2 YEARS
                  
                  <td>
                    Obtained a certificate in General Nursing and Midwifery and
                    registered as R.N.R.M. with the State Nurses Registration
                    Council. A male nurse, trained before the implementation of
                    the new integrated course besides being registered as a
                    nurse with State Nurses Registration Council
                  </td>
                  <td>50%</td>
                  <td>50%</td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td>
                    4
                    <br />
                  </td>
                  <td>
                    GNM Nursing
                    <br />
                  </td>
                  3 YEARS
                  
                  <td>
                    HSC with Science Stream, General Stream, vyavsaylakshi
                    stream, uchchatar uttar buniyadi pravah Stream with English
                    subject The minimum age for admission shall be 17 years on
                    or before 31st December
                  </td>
                  <td>40%</td>
                  <td>35%</td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td>
                    5
                    <br />
                  </td>
                  <td>
                    ANM Nursing
                    <br />
                  </td>
                  2 YEARS
                  
                  <td>
                    HSC with Science Stream, General Stream, vyavsaylakshi
                    stream, uchchatar uttar buniyadi pravah Stream with English
                    subject The minimum age for admission shall be 17 years on
                    or before 31st December
                  </td>
                  <td>PASS</td>
                  <td>PASS</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
