import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import Navbar from "../Components/Navbar/Navbar";
import axios from "axios";
import { ALL_DATA } from "../Utils/apiConst";
import AboutSwaminarayanUniversity from "../Pages/About/SwaminarayanUniversity/AboutSwaminarayanUniversity";
import Act from "../Pages/About/SwaminarayanUniversity/Act";
import Approval from "../Pages/About/SwaminarayanUniversity/Approval";
import GoverningBody from "../Pages/About/AutoritiesOfUniversity/GoverningBody";
import Chart from "../Pages/About/SwaminarayanUniversity/Chart";
import Milestones from "../Pages/About/SwaminarayanUniversity/Milestones";
import MissionVision from "../Pages/About/SwaminarayanUniversity/MissionVision";
import Objectives from "../Pages/About/SwaminarayanUniversity/Objectives";
import Home from "../Pages/Home/Home";
import BoardManagement from "../Pages/About/AutoritiesOfUniversity/BoardManagement";
import AcademicCouncil from "../Pages/About/AutoritiesOfUniversity/AcademicCouncil";
import BoardOfStudies from "../Pages/About/AutoritiesOfUniversity/BoardOfStudies";
import President from "../Pages/About/KeyManagement/President";
import Graduate from "../Pages/Program2022_23/Graduate";
import PostGraduate from "../Pages/Program2022_23/PostGraduate";
import AboutPhd from "../Pages/Program2022_23/Doctral_PhD/AboutPhd";
import AcademicRegulations from "../Pages/Program2022_23/Doctral_PhD/AcademicRegulations";
import PhdSubjects from "../Pages/Program2022_23/Doctral_PhD/PhdSubjects";
import PhdNotifications from "../Pages/Program2022_23/Doctral_PhD/PhdNotifications";
import Diploma from "../Pages/Program2022_23/Diploma";
import Certificate from "../Pages/Program2022_23/Certificate";
import Infrastructure from "../Pages/CampusFacilities/Infrastructure";
import AcademicFacilities from "../Pages/CampusFacilities/AcademicFacilities";
import CampusFacilities from "../Pages/CampusFacilities/CampusFacilities";
import Transportation from "../Pages/CampusFacilities/Transportation";
import ResidentialFacilities from "../Pages/CampusFacilities/ResidentialFacilities";
import Library from "../Pages/CampusFacilities/Library";
import Schools from "../Pages/CampusFacilities/Schools";
import Auditorium from "../Pages/CampusFacilities/Auditorium";
import Media from "../Pages/Connect/WhatsHappening/Media";
import Brochure from "../Pages/Connect/Downloads/Brochure";
import VicePresidents from "../Pages/About/KeyManagement/VicePresidents";
import Deans from "../Pages/About/KeyManagement/Deans";
import OtherUniversityOfficers from "../Pages/About/KeyManagement/OtherUniversityOfficers";
import Footer from "../Components/Navbar/Footer/Footer";
import AdmissionEnquiry from "../Pages/Admission/AdmissionEnquiry";
import SankalpVision2030 from "../Pages/About/SwaminarayanUniversity/SankalpVision2030";
import EventCalendar from "../Pages/Connect/WhatsHappening/EventCalendar";
import Recruitment from "../Pages/TopNav/Recruitment";
import NotFoundPage from "../Pages/Home/NotFoundPage";
import SimsRedirect from "../Pages/sims/SimsRedirect";
import OfficerProfile from "../Pages/Profile/OfficerProfile";
import Homoeopathy from "../Pages/Homoeopathy/Home";
import About_us from "../Pages/Homoeopathy/About_us";
import FoundersMessage from "../Pages/Homoeopathy/FoundersMessage";
import PrincipalMessage from "../Pages/Homoeopathy/PrincipalMessage";
import Mission_Vision from "../Pages/Homoeopathy/Mission_Vision";
import AboutCollege from "../Pages/Homoeopathy/AboutCollege";
import CollegeCouncil from "../Pages/Homoeopathy/CollegeCouncil";
import AboutHospital from "../Pages/Homoeopathy/AboutHospital";
import ContactUs from "../Pages/Homoeopathy/ContactUs";
import Navbar2 from "../Components/Navbar/Navbar2";
import Footer2 from "../Components/Navbar/Footer/Footer2";
import Navbar3 from "../Components/Navbar/Navbar3";
import Footer3 from "../Components/Navbar/Footer/Footer3";
import PrivacyPolicy from "../Pages/Home/PrivacyPolicy";

import Rules from "../Pages/Homoeopathy/Rules";
import Syllabus from "../Pages/Homoeopathy/Syllabus";
import FeeStructure from "../Pages/Homoeopathy/FeeStructure";
import Admission from "../Pages/Homoeopathy/Admission";
import Courses from "../Pages/Homoeopathy/Courses";
import StudentPayment from "../Pages/student/StudentPayment";

// Phd Admissions
import PhdAdmissions from "../Pages/Phd/Admissions";
import PhdAdmissionsII from "../Pages/Phd/PhdBatchII";
import PhdHallTicket from "../Pages/Phd/PhdHallTickets";
import PhdExamForm from "../Pages/Phd/PhdExamForm";
import PHDExamPayment from "../Pages/Phd/PHDExamPayment";
import PhdFormPrint from "../Pages/Phd/PhdFormPrint";
import Payment_Success from "../Pages/Phd/Payment_Success";
import Recognition from "../Pages/Homoeopathy/Recognition";
import Affiliation from "../Pages/Homoeopathy/Affiliation";
import Permission from "../Pages/Homoeopathy/Permission";
import PhdAdmissionForm from "../Pages/Program2022_23/Doctral_PhD/PhdAdmissionForm";

//Fees
import StudentDueFees from "../Pages/student/StudentDueFees";
import StudentDueFeesPayment from "../Pages/student/StudentDueFeesPayment";

// Admissions

// ayurvedic

import Ayurvedic from "../Pages/sims/Home";
import DeanMedicalSuperintendent from "../Pages/sims/DeanMedicalSuperintendent";
import IntakeSanctionedCapacity from "../Pages/sims/IntakeSanctionedCapacity";
import FeesDetails from "../Pages/sims/FeesDetails";
import RecognitionStatus from "../Pages/sims/RecognitionStatus";
import StudentsList from "../Pages/sims/StudentsList";
import Academic_Activities from "../Pages/sims/Academic_Activities";
import ResultOfExamination from "../Pages/sims/ResultOfExamination";
import ReseachandPublication from "../Pages/sims/ReseachandPublication";
import RulesRegulations from "../Pages/sims/Rules&Regulations";
import Admissions from "../Pages/sims/Admissions";
import Syllabuss from "../Pages/sims/Syllabus";
import Contact from "../Pages/sims/ContactUs";
import Payment from "../Pages/Payment/Payment";
import Results from "../Pages/Results/Results";
import PrevResults from "../Pages/Results/PrevResults";
import PrevResultsEngg from "../Pages/Results/PrevResultsEngg";
import PrevResultsPharm from "../Pages/Results/PrevResultsPharm";
import MissionVisionMBBS from "../Pages/sims/MissionVisionMBBS";
import AboutMBBSHospital from "../Pages/sims/AboutMBBSHospital";
import DeanMessage from "../Pages/sims/DeanMessage";

//ExamTimeTable
import ExamTimeTable from "../Pages/ExamCorner/ExamTimeTable";
import Citizencharter from "../Pages/sims/Citizencharter";
import TeachingStaff from "../Pages/sims/TeachingStaff";
import RecheckingForm from "../Pages/Results/RecheckingForm";
import Reassesment from "../Pages/Results/Reassesments";
import DraftResults from "../Pages/Results/DraftResults";
import Notifications from "../Pages/ExamCorner/Notifications";
import Nad from "../Pages/NAD/Nad";
import HomeopathyTeachingStaff from "../Pages/Homoeopathy/HomeopathyTeachingStaff";
import HomeopathyHospitalStaff from "../Pages/Homoeopathy/HomeopathyHospitalStaff";
import AdminStaff from "../Pages/Homoeopathy/AdminStaff";
import DraftResultsEng from "../Pages/Results/DraftResutlsEng";
import ResultsEng from "../Pages/Results/ResultsEng";
import DraftResultsPharmacy from "../Pages/Results/DraftResultsPharmacy";
import ResultsPharmacy from "../Pages/Results/ResultsPharmacy";
import PaymentGateway from "../Pages/PaymentGateway/PaymentGateway";
import { ROUTES } from "./RouterConfig";
import StudentExaminationCourseRegisteration from "../Pages/student/StudentExaminationCourseRegisteration";
import AdmissionForm from "../Pages/Admission/AdmissionForms";
import ReexamDraftResults from "../Pages/Results/ReexamDraftResults";
import ReexamResults from "../Pages/Results/ReexamResults";
import After10th from "../Pages/Programes/After10th";
import After12th from "../Pages/Programes/After12th";
import AfterUG from "../Pages/Programes/AfterUG";
import AfterPG from "../Pages/Programes/AfterPG";
import OPD from "../Pages/Homoeopathy/OPD";
import IPD from "../Pages/Homoeopathy/IPD";
import Events from "../Pages/Homoeopathy/Events";
import HostelFacilities from "../Pages/CampusFacilities/HostelFacilities";
import StudentsComplaitns from "../Pages/student/StudentsComplaitns";
import StudentsListHomeo from "../Pages/Homoeopathy/StudentsListHomeo";
import ResearchandPublication from "../Pages/Homoeopathy/ResearchandPublication";
import HomeoResults from "../Pages/Homoeopathy/HomeoResults";
import UniversityEvents from "../Pages/Connect/UniversityEvents";

import {
  LOCAL_COLLEGE,
  LOCAL_COLLEGE_SPECIALIZATION,
  LOCAL_COLLEGE_TYPES,
  LOCAL_DEPARTMENT,
  LOCAL_EMPLOYEE,
  LOCAL_JOBROLES,
  LOCAL_PROGRAM,
  LOCAL_SPECIALIZATION,
  LOCAL_USER_ROLES,
} from "../Utils/LocalStorageConstants";
import UGCinformation from "../Components/Navbar/UGCinformation";
import Events1 from "../Pages/Homoeopathy/Events1";
import EventsDetails from "./EventsDetails";

import Event from "../Pages/Connect/WhatsHappening/Event";

import AdmissionNotifications from "../Pages/Homoeopathy/AdmissionNotifications";
import UniversityNotifications from "../Pages/Homoeopathy/UniversityNotifications";
import Ombudsperson from "../Pages/About/KeyManagement/Ombudsperson";
import Nep from "../Pages/Connect/Nep";
import ReExamPharmacyResuslts from "../Pages/Results/ReExamPharmacyResuslts";
import PhdAdmissionFormError from "../Pages/Program2022_23/Doctral_PhD/PhdAdmissionFormError";
import ExamApplicationForm from "../Pages/student/ExamApplicationForm";
import ReExamEngResults from "../Pages/Results/ReExamEngResults";
//import StudentsList from "../Pages/sims/StudentsList";

// Science Faculty Pages
import Navbar_science from "../Components/Navbar/Navbar-science";
import Footer_science from "../Components/Navbar/Footer/Footer2";
import Science from "../Pages/science/Home";
import SciencePrincipalMessage from "../Pages/science/PrincipalMessage";
import ScienceAboutUs from "../Pages/science/About_us";
import ScienceMissionVision from "../Pages/science/Mission_Vision";
import ScienceFoundersMessage from "../Pages/science/FoundersMessage";
import ScienceAboutCollege from "../Pages/science/AboutCollege";
import ScienceCollegeCouncil from "../Pages/science/CollegeCouncil";
import ScienceCourses from "../Pages/science/Courses";
import ScienceFeeStructure from "../Pages/science/FeeStructure";
import ScienceRules from "../Pages/science/Rules";
import ScienceSyllabus from "../Pages/science/Syllabus";
import ScienceSyllabusDocs from "../Pages/science/SyllabusDocs";
import ScienceEligibility from "../Pages/science/Eligibility";
import ScienceTeachingStaff from "../Pages/science/TeachingStaff";
import ScienceInfrastructure from "../Pages/science/Infrastructure";

//Engineering Faculty Pages
import Navbar_engineering from "../Components/Navbar/Navbar-engineering";
import Footer_engineering from "../Components/Navbar/Footer/Footer-engineering";

import Engineering from "../Pages/Engineering/Home";
import EngineeringCourses from "../Pages/Engineering/Courses";
import EngineeringEligibility from "../Pages/Engineering/Eligibility";
import EngineeringFeeStructure from "../Pages/Engineering/FeeStructure";
import EngineeringAcademicCalender from "../Pages/Engineering/AcademicCalender";
import EngineeringPrincipleMessage from "../Pages/Engineering/PrincipalMessage";
import EngineeringTeachingStaff from "../Pages/Engineering/TeachingStaff";
import EngineeringWorkshop from "../Pages/Engineering/Workshop";
import EngineeringFunding from "../Pages/Engineering/Funding";
import EngineeringPublications from "../Pages/Engineering/Publications.jsx";
import EngineeringCampusPlacements from "../Pages/Engineering/CampusPlacements.jsx";
import EngineeringAlumniMeetings from "../Pages/Engineering/AlumniMeetings.jsx";
import EngineeringSyllabus from "../Pages/Engineering/Syllabus";
import EngineeringSyllabusDepartments from "../Pages/Engineering/SyllabusDeptartments";
import EngineeringSyllabusDocs from "../Pages/Engineering/SyllabusDocs";

//Pharmacy Faculty Pages
import Navbar_pharmacy from "../Components/Navbar/Navbar-pharmacy";
import Footer_pharmacy from "../Components/Navbar/Footer/Footer-pharmacy";
import PharmacyHome from "../Pages/Pharmacy/Home.jsx";
import PharmacyCourses from "../Pages/Pharmacy/Courses";
import PharmacyEligibility from "../Pages/Pharmacy/Eligibility";
import PharmacyAcademicCalender from "../Pages/Pharmacy/AcademicCalender";
import PharmacyPrincipalMessage from "../Pages/Pharmacy/PrincipalMessage";
import PharmacyWorkshopPublications from "../Pages/Pharmacy/WorkshopPublications";
import PharmacyPlacements from "../Pages/Pharmacy/Placements";
import PharmacyTeachingStaff from "../Pages/Pharmacy/TeachingStaff";
import PharmacyFoundersMessage from "../Pages/Pharmacy/FoundersMessage";
import PharmacyAboutUs from "../Pages/Pharmacy/About_us";
import PharmacyMissionVision from "../Pages/Pharmacy/Mission_Vision";
import PharmacyInfrastructure from "../Pages/Pharmacy/Infrastructure";
import PharmacySyllabus from "../Pages/Pharmacy/Syllabus";

//Arts & Commerce
import Navbar_arts_commerce from "../Components/Navbar/Navbar-arts-commerce.jsx";
import Footer_arts_commerce from "../Components/Navbar/Footer/Footer-arts-commerce.jsx";
import ArtsCommerceHome from "../Pages/ArtsCommerce/Home.jsx";
import ArtsCommerceArtsCourses from "../Pages/ArtsCommerce/ArtsCourses.jsx";
import ArtsCommerceCommerceCourses from "../Pages/ArtsCommerce/CommerceCourses.jsx";
import ArtsCommerceArtsEligibility from "../Pages/ArtsCommerce/ArtsEligibility.jsx";
import ArtsCommerceCommerceEligibility from "../Pages/ArtsCommerce/CommerceEligibility.jsx";
import ArtsCommerceArtsFeeStructure from "../Pages/ArtsCommerce/ArtsFeeStructure.jsx";
import ArtsCommerceCommerceFeeStructure from "../Pages/ArtsCommerce/CommerceFeeStructure.jsx";
import ArtsCommercePrincipalMessage from "../Pages/ArtsCommerce/PrincipalMessage.jsx";
import ArtsCommerceTeachingStaff from "../Pages/ArtsCommerce/TeachingStaff.jsx";
import ArtsCommerceFoundersMessage from "../Pages/ArtsCommerce/FoundersMessage.jsx";
import ArtsCommerceAboutUs from "../Pages/ArtsCommerce/About_us.jsx";
import ArtsCommerceMissionVision from "../Pages/ArtsCommerce/Mission_Vision.jsx";
import ArtsCommerceAboutCollege from "../Pages/ArtsCommerce/AboutCollege.jsx";

// IT-CS
import Navbar_itcs from "../Components/Navbar/Navbar-it-cs.jsx";
import Footer_itcs from "../Components/Navbar/Footer/Footer-it-cs.jsx";
import ItCsHome from "../Pages/IT_CS/Home.jsx";
import ItCsAboutUs from "../Pages/IT_CS/About_us.jsx";
import ItCsAboutCollege from "../Pages/IT_CS/AboutCollege.jsx";
import ItCsMissionVision from "../Pages/IT_CS/Mission_Vision.jsx";
import ItCsPrincipalMessage from "../Pages/IT_CS/PrincipalMessage.jsx";
import ItCsCourses from "../Pages/IT_CS/Courses.jsx";
import ItCsEligibility from "../Pages/IT_CS/Eligibility.jsx";
import ItCsFeeStructure from "../Pages/IT_CS/FeeStructure.jsx";
import ItCsAcademicCalender from "../Pages/IT_CS/AcademicCalender.jsx";
import ItCsTeachingStaff from "../Pages/IT_CS/TeachingStaff.jsx";
import ItCsFoundersMessage from "../Pages/IT_CS/FoundersMessage.jsx";

//Law
import Navbar_law from "../Components/Navbar/Navbar-law.jsx";
import Footer_law from "../Components/Navbar/Footer/Footer-law.jsx";
import LawHome from "../Pages/Law/Home.jsx";
import LawAboutCollege from "../Pages/Law/AboutCollege.jsx";
import LawAboutUs from "../Pages/Law/About_us.jsx";
import LawFoundersMessage from "../Pages/Law/FoundersMessage.jsx";
import LawMissionVision from "../Pages/Law/Mission_Vision.jsx";
import LawPrincipalMessage from "../Pages/Law/PrincipalMessage.jsx";
import LawCourses from "../Pages/Law/Courses.jsx";
import LawEligibility from "../Pages/Law/Eligibility.jsx";
import LawFeeStructure from "../Pages/Law/FeeStructure.jsx";
import LawAcademicEvents from "../Pages/Law/AcademicEvents.jsx";
import LawPublications from "../Pages/Law/Publications.jsx";
import LawTeachingStaff from "../Pages/Law/TeachingStaff.jsx";
import LawAchievements from "../Pages/Law/Achievements.jsx";

// Nursing
import Navbar_nursing from "../Components/Navbar/Navbar-nursing.jsx";
import Footer_nursing from "../Components/Navbar/Footer/Footer-nursing.jsx";
import NursingHome from "../Pages/Nursing/Home.jsx";
import NursingAboutUs from "../Pages/Nursing/About_us.jsx";
import NursingAboutCollege from "../Pages/Nursing/AboutCollege.jsx";
import NursingFoundersMessage from "../Pages/Nursing/FoundersMessage.jsx";
import NursingPrincipalMessage from "../Pages/Nursing/PrincipalMessage.jsx";
import NursingMissionVision from "../Pages/Nursing/Mission_Vision.jsx";
import NursingEligibility from "../Pages/Nursing/Eligibility.jsx";
import NursingFeeStructure from "../Pages/Nursing/FeeStructure.jsx";
import NursingTeachingStaff from "../Pages/Nursing/TeachingStaff.jsx";
import NursingPlacementDetails from "../Pages/Nursing/PlacementDetails.jsx";
import NursingSyllabus from "../Pages/Nursing/Syllabus.jsx";
import NursingSyllabusDocs from "../Pages/Nursing/SyllabusDocs.jsx";

//Education
import Navbar_education from "../Components/Navbar/Navbar-education.jsx";
import Footer_education from "../Components/Navbar/Footer/Footer-education.jsx";
import EducationHome from "../Pages/Education/Home.jsx";
import EducationAboutUs from "../Pages/Education/About_us.jsx";
import EducationAboutCollege from "../Pages/Education/AboutCollege.jsx";
import EducationFoundersMessage from "../Pages/Education/FoundersMessage.jsx";
import EducationPrincipalMessage from "../Pages/Education/PrincipalMessage.jsx";
import EducationMissionVision from "../Pages/Education/Mission_Vision.jsx";
import EducationTeachingStaff from "../Pages/Education/TeachingStaff.jsx";
import EducationCourses from "../Pages/Education/Courses.jsx";
import EducationFeeStructure from "../Pages/Education/FeeStructure.jsx";
import EducationResearchActivity from "../Pages/Education/ResearchActivity.jsx";
import EducationWorkshop from "../Pages/Education/Workshop.jsx";
import EducationPlacements from "../Pages/Education/PlacementDetails.jsx";
import EducationPublications from "../Pages/Education/Publications.jsx";
import EducationSyllabus from "../Pages/Education/Syllabus.jsx";
import EducationSyllabusDocs from "../Pages/Education/SyllabusDocs.jsx";
import EducationEligibility from "../Pages/Education/Eligibility.jsx";

// Ayurveda
import Navbar_ayurveda from "../Components/Navbar/Navbar-ayurveda.jsx";
import Footer_ayurveda from "../Components/Navbar/Footer/Footer-ayurveda.jsx";
import AyurvedaHome from "../Pages/Ayurveda/Home.jsx";
import AyurvedaAboutCollege from "../Pages/Ayurveda/AboutCollege.jsx";
import AyurvedaAboutUs from "../Pages/Ayurveda/About_us.jsx";
import AyurvedaFoundersMessage from "../Pages/Ayurveda/FoundersMessage.jsx";
import AyurvedaMissionVision from "../Pages/Ayurveda/Mission_Vision.jsx";
import AyurvedaPrincipalMessage from "../Pages/Ayurveda/PrincipalMessage.jsx";
import AyurvedaTeachingStaff from "../Pages/Ayurveda/TeachingStaff.jsx";
import AyurvedaCollegeCommittee from "../Pages/Ayurveda/CollegeCommittee.jsx";
import AyurvedaResearchPublications from "../Pages/Ayurveda/ResearchPublications.jsx";
import AyurvedaEligibility from "../Pages/Ayurveda/Eligibility.jsx";
import AyurvedaFeeStructure from "../Pages/Ayurveda/FeeStructure.jsx";
import AyurvedaSyllabus from "../Pages/Ayurveda/Syllabus.jsx";
import AyurvedaSyllabusDocs from "../Pages/Ayurveda/SyllabusDocs.jsx";
import AyurvedaLabs from "../Pages/Ayurveda/Labs.jsx";
import AyurvedaMuseums from "../Pages/Ayurveda/Museums.jsx";
import AyurvedaExtraFacilities from "../Pages/Ayurveda/ExtraFacilities.jsx";
import AyurvedaHospitalAdministration from "../Pages/Ayurveda/HospitalAdministration.jsx";
import AyurvedaHospitalStaff from "../Pages/Ayurveda/HospitalStaff.jsx";
import AyurvedaHospitalStaffAttendance from "../Pages/Ayurveda/HospitalStaffAttendance.jsx";
import AyurvedaClinicalDepartments from "../Pages/Ayurveda/ClinicalDepartments.jsx";

import ScienceEvents from "../Pages/science/ScienceEvents";
import EngineeringEvents from "../Pages/Engineering/EngineeringEvents";
import ReseachandPublicationEng from "../Pages/Engineering/ResearchandPublicationEng";
import ReseachandPublicationScience from "../Pages/science/ResearchandPublicationScience";
import ResearchDetails from "./ResearchDetails";
import StudentsListScience from "../Pages/science/StudentsListScience";
import StudentsListEng from "../Pages/Engineering/StudentsListEng";
import ScienceResults from "../Pages/science/ScienceResults";
import EngResults from "../Pages/Engineering/EngResults";

import AyurvedaEvents from "../Pages/Ayurveda/AyurvedaEvents.jsx";
import NursingEvents from "../Pages/Nursing/NursingEvents.jsx";
import PharmacyEvents from "../Pages/Pharmacy/PharmacyEvents.jsx";
import EducationEvents from "../Pages/Education/EducationEvents.jsx";
import AyurvedaResults from "../Pages/Ayurveda/AyurvedaResults.jsx";
import StudentsListAyurveda from "../Pages/Ayurveda/StudentsListAyurveda.jsx";
import EngClgPermission from "../Pages/Engineering/EngClgPermission.jsx";
import ScienceClgPermission from "../Pages/science/ScienceClgPermission.jsx";

import HomeoRecruitment from "../Pages/Homoeopathy/HomeoRecruitment.jsx";

import AyurvedaClinicalMaterial from "../Pages/Ayurveda/ClinicalMaterial.jsx";
import AyurvedaOpdServices from "../Pages/Ayurveda/OpdServices.jsx";
import AyurvedaDedicatedCovidHealthCareCentre from "../Pages/Ayurveda/DedicatedCovidHealthCareCentre.jsx";
import AyurvedaIpdServices from "../Pages/Ayurveda/IpdServices.jsx";
import AyurvedaDiagnosticServices from "../Pages/Ayurveda/DiagnosticServices.jsx";
import AyurvedaTherapies from "../Pages/Ayurveda/Therapies.jsx";
import AyurvedaStudentList from "../Pages/Ayurveda/StudentList.jsx";
import LawSyllabus from "../Pages/Law/LawSyllabus.jsx";
import LawSyllabusSem from "../Pages/Law/LawSyllabusSem.jsx";
import LawSyllabusDocs from "../Pages/Law/LawSyllabusDocs.jsx";
import NursingSyllabusSem from "../Pages/Nursing/NursingSyllabusSem.jsx";
import ArtsCommerceSyllabus from "../Pages/ArtsCommerce/ArtsCommerceSyllabus.jsx";
import ArtsCommerceSyllabusSem from "../Pages/ArtsCommerce/ArtsCommerceSyllabusSem.jsx";
import ArtsCommerceSyllabusDocs from "../Pages/ArtsCommerce/ArtsCommerceSyllabusDocs.jsx";
import HomeoSyllabusSem from "../Pages/Homoeopathy/HomeoSyllabusSem.jsx";
import HomeoSyllabusDocs from "../Pages/Homoeopathy/HomeoSyllabusDocs.jsx";
import EngClgAffiliation from "../Pages/Engineering/EngClgAffiliation.jsx";
import ScienceClgAffiliation from "../Pages/science/ScienceClgAffiliation.jsx";
import Publications from "../Pages/Law/Publications.jsx";
import NursingResearchandPublication from "../Pages/Nursing/NursingResearchandPublication.jsx";
import ArtsCommerceResearchandPublication from "../Pages/ArtsCommerce/ArtsCommerceResearchandPublication.jsx";
import ITCSPublications from "../Pages/IT_CS/ITCSPublications.jsx";
import EngSyllabusSem from "../Pages/Engineering/SyllabusDeptartments";
import HomeoSyllabus from "../Pages/Homoeopathy/Syllabus";
import ScienceSyllabusSem from "../Pages/science/SyllabusDocs";
import PharmacySyllabusSem from "../Pages/Pharmacy/PharmacySyllabusSem.jsx";
import PharmacySyllabusDocs from "../Pages/Pharmacy/PharmacySyllabusDocs.jsx";
import ITCSSyllabus from "../Pages/IT_CS/ITCSSyllabus.jsx";
import ITCSSyllabusSem from "../Pages/IT_CS/ITCSSyllabusSem.jsx";
import ITCSSyllabusDocs from "../Pages/IT_CS/ITCSSyllabusDocs.jsx";
import EducationSyllabusSem from "../Pages/Education/EducationSyllabusSem.jsx";
import AyurvedaSyllabusSem from "../Pages/Ayurveda/AyurvedaSyllabusSem.jsx";
import LawEvents from "../Pages/Law/AcademicEvents.jsx";
import EngTeachingStaff from "../Pages/Engineering/EngTeachingStaff.jsx";
import NonTeachingStaffEng from "../Pages/Engineering/NonTeachingStaffEng.jsx";
import NonTeachingStaffArtsComm from "../Pages/ArtsCommerce/NonTeachingStaffArtsComm.jsx";
import NonTeachingStaffHomeo from "../Pages/Homoeopathy/NonTeachingStaffHomeo.jsx";
import NonTeachingStaffPharmacy from "../Pages/Pharmacy/NonTeachingStaffPharmacy.jsx";
import NonTeachingStaffScience from "../Pages/science/NonTeachingStaffScience.jsx";
import MedicalRecruitment from "../Pages/sims/MedicalRecruitment.jsx";
import EngRecruitment from "../Pages/Engineering/EngRecruitment.jsx";
import AyurvedaRecruitment from "../Pages/Ayurveda/AyurvedaRecruitment.jsx";
import LawRecruitment from "../Pages/Law/LawRecruitment.jsx";
import NursingRecruitment from "../Pages/Nursing/NursingRecruitment.jsx";
import ArtsCommerceRecruitment from "../Pages/ArtsCommerce/ArtsCommerceRecruitment.jsx";
import PharmacyRecruitment from "../Pages/Pharmacy/PharmacyRecruitment.jsx";
import ITCSRecruitment from "../Pages/IT_CS/ITCSRecruitment.jsx";
import EducationRecruitment from "../Pages/Education/EducationRecruitment.jsx";
import ScienceRecruitment from "../Pages/science/ScienceRecruitment.jsx";
import MedicineClgAffiliation from "../Pages/sims/MedicineClgAffiliation.jsx";
import MedicineStudentResults from "../Pages/sims/MedicineStudentResults.jsx";
import MedicineOPDServices from "../Pages/sims/MedicineOPDServices.jsx";
import MedicineIPDServices from "../Pages/sims/MedicineIPDServices.jsx";
import StudentsListMedical from "../Pages/sims/StudentsListMedical.jsx";
import TeachingStaffMedical from "../Pages/sims/TeachingStaffMedical.jsx";
import NonTeachingStaffMedical from "../Pages/sims/NonTeachingStaffMedical.jsx";
import SimsFoundersMessage from "../Pages/sims/FounderMessage.jsx";


import MedicalClgPermission from "../Pages/sims/MedicalClgPermission.jsx";
import StudentsListLaw from "../Pages/Law/StudentsListLaw.jsx";
import StudentsResultsLaw from "../Pages/Law/StudentsResultsLaw.jsx";
import StudentsListNursing from "../Pages/Nursing/StudentsListNursing.jsx";
import StudentsResultsNursing from "../Pages/Nursing/StudentsResultsNursing.jsx";
import StudentsListCommerce from "../Pages/ArtsCommerce/StudentsListCommerce.jsx";
import StudentsResultsCommerce from "../Pages/ArtsCommerce/StudentsResultsCommerce.jsx";
import StudentsListITCS from "../Pages/IT_CS/StudentsListITCS.jsx";
import StudentsResultsITCS from "../Pages/IT_CS/StudentsResultsITCS.jsx";
import StudentsListPharmacy from "../Pages/Pharmacy/StudentsListPharmacy.jsx";
import StudentsResultsPharmacy from "../Pages/Pharmacy/StudentsResultsPharmacy.jsx";
import StudentsListEducation from "../Pages/Education/StudentsListEducation.jsx";
import StudentsResultsEducation from "../Pages/Education/StudentsResultsEducation.jsx";
import NonTeachingStaffAyurveda from "../Pages/Ayurveda/NonTeachingAyu.jsx";
import NonTeachingStaffLaw from "../Pages/Law/NonTeachingStaffLaw.jsx";
import NonTeachingStaffNursing from "../Pages/Nursing/NonTeachingStaffNursing.jsx";
import NonTeachingStaffITCS from "../Pages/IT_CS/NonTeachingStaffITCS.jsx";
import NonTeachingStaffEducation from "../Pages/Education/NonTeachingStaffEducation.jsx";
import PrevQuestionPapers from "../Pages/Results/PrevQuestionPapers.jsx";
import Eventssmuk from "./Eventssmuk.jsx";
import simsAboutCollege from "../Pages/sims/simsAboutCollege.jsx";
import Services from "../Pages/sims/Services.jsx";
import Service from "../Pages/sims/Service.jsx";
import Status from "../Pages/sims/Status.jsx";
import VicePresidentMsg from "../Pages/sims/VicePresidentMsg.jsx";
import Provost from "../Pages/sims/Provost.jsx";
import Registrar from "../Pages/sims/Registrar.jsx";
import Advisor from "../Pages/sims/Advisor.jsx";
import Gallery from "../Pages/sims/Gallery.jsx";
import StaffDetails from "../Pages/sims/StaffDetails.jsx";
import PresidentMsg from "../Pages/President.jsx";
import VicePresident from "../Pages/VicePresident.jsx";
import VicePresident2 from "../Pages/VicePresident2.jsx";
import VicePresident3 from "../Pages/VicePresident3.jsx";
import VicePresidentsims from "../Pages/sims/VicePresident.jsx";
import VicePresident2sims from "../Pages/sims/VicePresident2.jsx";
import VicePresident3sims from "../Pages/sims/VicePresident3.jsx";
import VicePresidentAyu from "../Pages/Ayurveda/VicePresident.jsx";
import VicePresidentsAyu from "../Pages/Ayurveda/VicePresidents.jsx";
import VicePresident2Ayu from "../Pages/Ayurveda/VicePresident2.jsx";
import VicePresident3Ayu from "../Pages/Ayurveda/VicePresident3.jsx";
import VicePresidentsLaw from "../Pages/Law/VicePresidents.jsx";
import VicePresidentLaw from "../Pages/Law/VicePresident.jsx";
import VicePresident2Law from "../Pages/Law/VicePresident2.jsx";
import VicePresident3Law from "../Pages/Law/VicePresident3.jsx";
import VicePresidentsNursing from "../Pages/Nursing/VicePresidents.jsx";
import VicePresidentNursing from "../Pages/Nursing/VicePresident.jsx";
import VicePresident2Nursing from "../Pages/Nursing/VicePresident2.jsx";
import VicePresident3Nursing from "../Pages/Nursing/VicePresident3.jsx";
import VicePresidentsArtsCommerce from "../Pages/ArtsCommerce/VicePresidents.jsx";
import VicePresidentArtsCommerce from "../Pages/ArtsCommerce/VicePresident.jsx";
import VicePresident2ArtsCommerce from "../Pages/ArtsCommerce/VicePresident2.jsx";
import VicePresident3ArtsCommerce from "../Pages/ArtsCommerce/VicePresident3.jsx";
import VicePresidentsHomeo from "../Pages/Homoeopathy/VicePresidents.jsx";
import VicePresidentHomeo from "../Pages/Homoeopathy/VicePresident.jsx";
import VicePresident2Homeo from "../Pages/Homoeopathy/VicePresident2.jsx";
import VicePresident3Homeo from "../Pages/Homoeopathy/VicePresident3.jsx";
import VicePresidentsPharmacy from "../Pages/Pharmacy/VicePresidents.jsx";
import VicePresidentPharmacy from "../Pages/Pharmacy/VicePresident.jsx";
import VicePresident2Pharmacy from "../Pages/Pharmacy/VicePresident2.jsx";
import VicePresident3Pharmacy from "../Pages/Pharmacy/VicePresident3.jsx";
import VicePresidentsEducation from "../Pages/Education/VicePresidents.jsx";
import VicePresidentEducation from "../Pages/Education/VicePresident.jsx";
import VicePresident2Education from "../Pages/Education/VicePresident2.jsx";
import VicePresident3Education from "../Pages/Education/VicePresident3.jsx";
import VicePresidentsScience from "../Pages/science/VicePresidents.jsx";
import VicePresidentScience from "../Pages/science/VicePresident.jsx";
import VicePresident2Science from "../Pages/science/VicePresident2.jsx";
import VicePresident3Science from "../Pages/science/VicePresident3.jsx";
import VicePresidentsITCS from "../Pages/IT_CS/VicePresidents.jsx";
import VicePresidentITCS from "../Pages/IT_CS/VicePresident.jsx";
import VicePresident2ITCS from "../Pages/IT_CS/VicePresident2.jsx";
import VicePresident3ITCS from "../Pages/IT_CS/VicePresident3.jsx";
import NavbarArts from "../Components/Navbar/Navbar-Arts.jsx";
import Footerarts from "../Components/Navbar/Footer/Footer-arts.jsx";
import ArtsHome from "../Pages/Arts/ArtsHome.jsx";
import About_usArts from "../Pages/Arts/About_usArts.jsx";
import ContactUsArts from "../Pages/Arts/ContactUs.jsx";
import FoundersMessageArts from "../Pages/Arts/FoundersMessage.jsx";
import Mission_VisionArts from "../Pages/Arts/Mission_Vision.jsx";
import PrincipalMessageArts from "../Pages/Arts/PrincipalMessage.jsx";
import VicePresidentsArts from "../Pages/Arts/VicePresidents.jsx";
import VicePresidentArts from "../Pages/Arts/VicePresident.jsx";
import VicePresident2Arts from "../Pages/Arts/VicePresident2.jsx";
import VicePresident3Arts from "../Pages/Arts/VicePresident3.jsx";
import AboutCollegeArts from "../Pages/Arts/AboutCollege.jsx";
import AdmissionsArts from "../Pages/Arts/Admission.jsx";
import TeachingStaffArts from "../Pages/Arts/TeachingStaff.jsx";
import NonTeachingStaffArts from "../Pages/Arts/NonTeachingStaffArtsComm.jsx";
import ArtsResearchandPublication from "../Pages/Arts/ArtsCommerceResearchandPublication.jsx";
import ArtsRecruitment from "../Pages/Arts/ArtsCommerceRecruitment.jsx";
import StudentsListArts from "../Pages/Arts/StudentsListCommerce.jsx";
import StudentsResultsArts from "../Pages/Arts/StudentsResultsCommerce.jsx";
import ArtsSyllabus from "../Pages/Arts/ArtsCommerceSyllabus.jsx";
import ArtsSyllabusSem from "../Pages/Arts/ArtsCommerceSyllabusSem.jsx";
import ArtsSyllabusDocs from "../Pages/Arts/ArtsCommerceSyllabusDocs.jsx";
import Eventssims from "../Pages/sims/Events1.jsx";
import Eventscommerce from "../Pages/ArtsCommerce/Events1.jsx";
import Eventsarts from "../Pages/Arts/Events1.jsx";
import EventsITCS from "../Pages/IT_CS/Events1.jsx";
import EventsLaw from "../Pages/Law/Events1.jsx";
import AyuClgPermission from "../Pages/Ayurveda/AyuClgPermission.jsx";
import AyuClgAffiliation from "../Pages/Ayurveda/AyuClgAffiliation.jsx";
import LawClgPermission from "../Pages/Law/LawClgPermission.jsx";
import LawClgAffiliation from "../Pages/Law/LawClgAffiliation.jsx";
import NursingClgPermission from "../Pages/Nursing/NursingClgPermission.jsx";
import NursingClgAffiliation from "../Pages/Nursing/NursingClgAffiliation.jsx";
import ArtsClgPermission from "../Pages/Arts/ArtsClgPermission.jsx";
import ArtsClgAffiliation from "../Pages/Arts/ArtsClgAffiliation.jsx";
import CommerceClgPermission from "../Pages/ArtsCommerce/CommerceClgPermission.jsx";
import CommerceClgAffiliation from "../Pages/ArtsCommerce/CommerceClgAffiliation.jsx";
import PharmacyClgPermission from "../Pages/Pharmacy/PharmacyClgPermission.jsx";
import PharmacyClgAffiliation from "../Pages/Pharmacy/PharmacyClgAffiliation.jsx";
import ITCSClgPermission from "../Pages/IT_CS/ITCSClgPermission.jsx";
import ITCSClgAffiliation from "../Pages/IT_CS/ITCSClgAffiliation.jsx";
import EducationClgPermission from "../Pages/Education/EduClgPermission.jsx";
import EducationClgAffiliation from "../Pages/Education/EduClgAffiliation.jsx";
import VicePresidentsEng from "../Pages/Engineering/EngVicePresidents.jsx";
import AboutCollegePharmacy from "../Pages/Pharmacy/AboutCollege.jsx";
import PCI_SIF_Pharmacy from "../Pages/Pharmacy/PCI_SIF_Pharmacy.jsx";
import AcademicCalendar from "../Pages/Ayurveda/AcademicCalendar.jsx";
import AcademicCalendarLaw from "../Pages/Law/AcademicCalendar.jsx";
import AcademicCalendarNursing from "../Pages/Nursing/AcademicCalendar.jsx";
import AcademicCalenderEducation from "../Pages/Education/AcademicCalender.jsx";
import AcademicCalendarScience from "../Pages/science/AcademicCalendar.jsx";
import AcademicCalendersims from "../Pages/sims/AcademicCalender.jsx";
import AcademicCalenderArts from "../Pages/Arts/AcademicCalender.jsx";
import AcademicCalenderCommerce from "../Pages/ArtsCommerce/AcademicCalender.jsx";
import AcademicCalenderHomeopathy from "../Pages/Homoeopathy/AcademicCalender.jsx";
import RegistrationLetter from "../Pages/Homoeopathy/RegistrationLetter.jsx";
import PhotoGalleryHomeopathy from "../Pages/Homoeopathy/PhotoGallery.jsx";
import PhotoGalleryScience from "../Pages/science/PhotoGallery.jsx";
import PhotoGalleryAyurveda from "../Pages/Ayurveda/PhotoGallery.jsx";
import HospitalStaffHomeo from "../Pages/Homoeopathy/HospitalStaff.jsx";
import UGCLetterPermissionScience from "../Pages/science/UGCLetterPermission.jsx";
import RulesAyu from "../Pages/Ayurveda/Rules.jsx";
import RulesNursing from "../Pages/Nursing/Rules.jsx";
import RulesPharmacy from "../Pages/Pharmacy/Rules.jsx";
import RulesEng from "../Pages/Engineering/RulesEng.jsx";
import HospitalStaffAttendancesims from "../Pages/sims/HospitalStaffAttendance.jsx";
import AboutCollegeEngineering from "../Pages/Engineering/AboutCollege.jsx";
import InfrastructureEngineering from "../Pages/Engineering/Infrastructure.jsx";
import HospitalStaffAttendanceNursing from "../Pages/Nursing/HospitalStaffAttendance.jsx";
import HospitalStaffAttendanceAyurveda from "../Pages/Ayurveda/HospitalStaffAttendance.jsx";
import HospitalStaffAttendanceHomeo from "../Pages/Homoeopathy/HospitalStaffAttendance.jsx";
import RecognitionITCS from "../Pages/IT_CS/Recognition.jsx";
import SyllabusEducation from "../Pages/Education/EducationSyllabus.jsx";
import SyllabusDocsEducation from "../Pages/Education/EducationSyllabusDocs.jsx";
import InfrastructureAyurveda from "../Pages/Ayurveda/Infrastructure.jsx";
import NavbarPhysiotherapy from "../Components/Navbar/Navbar-Physiotherapy.jsx";
import FooterPhysiotherapy from "../Components/Navbar/Footer/Footer-physiotherapy.jsx";
import HomePhysio from "../Pages/Physiotherapy/HomePhysio.jsx";
import AboutCollegePhysio from "../Pages/Physiotherapy/AboutCollege.jsx";
import PrincipalMessagePhysiotherapy from "../Pages/Physiotherapy/PrincipalMessage.jsx";
import AdmissionsPhysiotherapy from "../Pages/Physiotherapy/Admission.jsx";
import InfrastructurePhysiotherapy from "../Pages/Physiotherapy/InfrastructurePhysiotherapy.jsx";
import About_usPhysio from "../Pages/Physiotherapy/About_us.jsx";
import VicePresidentPhysio from "../Pages/Physiotherapy/VicePresident.jsx";
import VicePresidentsPhysio from "../Pages/Physiotherapy/VicePresidents.jsx";
import VicePresident2Physio from "../Pages/Physiotherapy/VicePresident2.jsx";
import VicePresident3Physio from "../Pages/Physiotherapy/VicePresident3.jsx";
import RecruitmentPhysio from "../Pages/Physiotherapy/RecruitmentPhysio.jsx";
import ResearchandPublicationPhysio from "../Pages/Physiotherapy/ResearchandPublicationPhysio.jsx";
import PermissionPhysio from "../Pages/Physiotherapy/PermissionPhysio.jsx";
import AffiliationPhysio from "../Pages/Physiotherapy/AffiliationPhysio.jsx";
import TeachingStaffPhysio from "../Pages/Physiotherapy/TeachingStaffPhysio.jsx";
import NonTeachingStaffPhysio from "../Pages/Physiotherapy/NonTeachingStaffPhysio.jsx";
import StudentList from "../Pages/Ayurveda/StudentList.jsx";
import StudentResults from "../Pages/Physiotherapy/StudentResults.jsx";
import StudentsListPhysio from "../Pages/Physiotherapy/StudentsList.jsx";
import SyllabusPhysio from "../Pages/Physiotherapy/Syllabus.jsx";
import SyllabusSemPhysio from "../Pages/Physiotherapy/SyllabusSemPhysio.jsx";
import PhysioSyllabusDocs from "../Pages/Physiotherapy/SyllabusDocs.jsx";
import AcademicCalenderPhysio from "../Pages/Physiotherapy/AcademicCalender.jsx";
import LibraryPhysio from "../Pages/Physiotherapy/Library.jsx";
import HomeUniversityNotifications from "../Pages/Home/Notifications/NotificationList.jsx"
import Mission_VisionPhysio from "../Pages/Physiotherapy/Mission_Vision.jsx";
import NavbarDesign from "../Components/Navbar/Navbar-Design.jsx";
import FooterDesign from "../Components/Navbar/Footer/Footer-Design.jsx";
import HomeDesign from "../Pages/Design/HomeDesign.jsx";
import LibraryDesign from "../Pages/Design/Library.jsx";
import AcademicCalendarDesign from "../Pages/Design/AcademicCalendar.jsx";
import EventsDesign from "../Pages/Design/Events.jsx";
import SyllabusDesign from "../Pages/Design/Syllabus.jsx";
import SyllabusSemDesign from "../Pages/Design/SyllabusSem.jsx";
import SyllabusDocsDesign from "../Pages/Design/SyllabusDocs.jsx";
import StudentsListDesign from "../Pages/Design/StudentsList.jsx";
import StudentsResultDesign from "../Pages/Design/StudentsResult.jsx";
import TeachingStaffDesign from "../Pages/Design/TeachingStaff.jsx";
import NonTeachingStaffDesign from "../Pages/Design/NonTeachingStaff.jsx";
import AffiliationDesign from "../Pages/Design/Affiliation.jsx";
import PermissionDesign from "../Pages/Design/Permission.jsx";
import ResearchandPublicationDesign from "../Pages/Design/ResearchandPublication.jsx";
import RecruitmentDesign from "../Pages/Design/Recruitment.jsx";
import VicePresidentsDesign from "../Pages/Design/VicePresidents.jsx";
import VicePresidentDesign from "../Pages/Design/VicePresident.jsx";
import VicePresident2Design from "../Pages/Design/VicePresident2.jsx";
import VicePresident3Design from "../Pages/Design/VicePresident3.jsx";
import MissionVisionDesign from "../Pages/Design/MissionVision.jsx";
import AboutUsDesign from "../Pages/Design/AboutUs.jsx";
import InfrastructureDesign from "../Pages/Design/Infrastructure.jsx";
import AdmissionsDesign from "../Pages/Design/Admissions.jsx";
import PrincipalsMessageDesign from "../Pages/Design/PrincipalsMessage.jsx";
import AboutCollegeDesign from "../Pages/Design/AboutCollege.jsx";
import ContactUsPhysio from "../Pages/Physiotherapy/ContactUs.jsx";
import ContactUsEng from "../Pages/Engineering/ContactUs.jsx";
import ContactUsAyurveda from "../Pages/Ayurveda/ContactUs.jsx";
import ContactUsLaw from "../Pages/Law/ContactUs.jsx";
import ContactUsCommerce from "../Pages/ArtsCommerce/ContactUs.jsx";
import ContactUsPharmacy from "../Pages/Pharmacy/ContactUs.jsx";
import ContactUsITCS from "../Pages/IT_CS/ContactUs.jsx";
import ContactUsEducation from "../Pages/Education/ContactUs.jsx";
import ContactUsScience from "../Pages/science/ConatctUs.jsx";
import EventsPhysio from "../Pages/Physiotherapy/EventsPhysio.jsx";
import AchievementsEng from "../Pages/Engineering/Achievements.jsx";
import AchievementsDetails from "./AchievementsDetails.jsx";
import AchievementsAyu from "../Pages/Ayurveda/Achievements.jsx";
import AchievementsLaw from "../Pages/Law/AchievementsLaw.jsx";
import AchievementsNursing from "../Pages/Nursing/Achievements.jsx";
import AchievementsArts from "../Pages/Arts/Achievements.jsx";
import AchievementsCommerce from "../Pages/ArtsCommerce/Achievements.jsx";
import AchievementsSims from "../Pages/sims/Achievements.jsx";
import AchievementsHomeo from "../Pages/Homoeopathy/Achievements.jsx";
import AchievementsPharmacy from "../Pages/Pharmacy/Achievements.jsx";
import AchievementsITCS from "../Pages/IT_CS/Achievements.jsx";
import AchievementsEducation from "../Pages/Education/Achievements.jsx";
import AchievementsScience from "../Pages/science/Achievements.jsx";
import AchievementsPhysio from "../Pages/Physiotherapy/Achievements.jsx";
import AchievementsDesign from "../Pages/Design/Achievements.jsx";
import NCH1 from "../Pages/Homoeopathy/NCH1.jsx";
import NCH2 from "../Pages/Homoeopathy/NCH2.jsx";
import NCH3 from "../Pages/Homoeopathy/NCH3.jsx";
import NodalOfficer from "../Pages/Homoeopathy/NodalOfficer.jsx";
import DetailsOfDirector from "../Pages/Homoeopathy/DetailsOfDirector.jsx";
import ViceChancellor from "../Pages/Homoeopathy/ViceChancellor.jsx";
import RegistrarNCH from "../Pages/Homoeopathy/Registrar.jsx";
import PrincipalSecretory from "../Pages/Homoeopathy/PrincipalSecretory.jsx";
import ContactUsDesign from "../Pages/Design/ContactUs.jsx";
import Mission_VisionEng from "../Pages/Engineering/Mission_Vision.jsx";
import Pgseats from "../Pages/sims/Pgseats.jsx";
import YearlyPlanner from "../Pages/sims/YearlyPlanner.jsx";
import TimeTable from "../Pages/sims/TimeTable.jsx";
import Examination from "../Pages/sims/Examination.jsx";
import ResultsSim from "../Pages/sims/Results.jsx";
import AwardsandMedals from "../Pages/sims/AwardsandMedals.jsx";
import HospitalDetails from "../Pages/sims/HospitalDetails.jsx";
import StatusandRecognition from "../Pages/sims/StatusandRecognition.jsx";
import Hostel from "../Pages/sims/Hostel.jsx";
import GrievanceRedressalOfficer from "../Pages/sims/GrievanceRedressalOfficer.jsx";
import LibrarySim from "../Pages/sims/Library.jsx";
import IDP from "../Pages/IDP.jsx";
import Prospectus from "../Pages/Prospectus.jsx";

const RouteWithNav = ({ Element }) => {
  const [colleges, setColleges] = useState();

  const getAllData = async () => {
    var config = {
      method: "get",
      url: ALL_DATA,
      headers: {
        "content-type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        console.log(res);
        localStorage.setItem("ALL_DATA", JSON.stringify(res.data));
        localStorage.setItem(
          LOCAL_USER_ROLES,
          JSON.stringify(res.data.userRoles)
        );
        localStorage.setItem(LOCAL_COLLEGE, JSON.stringify(res.data.college));
        localStorage.setItem(
          LOCAL_COLLEGE_SPECIALIZATION,
          JSON.stringify(res.data.collegeSpecialization)
        );
        localStorage.setItem(
          LOCAL_COLLEGE_TYPES,
          JSON.stringify(res.data.collegeType)
        );
        localStorage.setItem(
          LOCAL_DEPARTMENT,
          JSON.stringify(res.data.department)
        );
        localStorage.setItem(LOCAL_JOBROLES, JSON.stringify(res.data.jobRoles));
        localStorage.setItem(LOCAL_PROGRAM, JSON.stringify(res.data.program));
        localStorage.setItem(
          LOCAL_SPECIALIZATION,
          JSON.stringify(res.data.specialization)
        );
        localStorage.setItem(
          LOCAL_USER_ROLES,
          JSON.stringify(res.data.userRoles)
        );
        localStorage.setItem(LOCAL_EMPLOYEE, JSON.stringify(res.data.employee));
        setColleges(res.data.college);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAllData();
  }, []);
  return (
    <>
      <Navbar />
      <Element />
      <Footer />
    </>
  );
};

const RouteWithNavHomeo = ({ Element }) => {
  return (
    <>
      <Navbar2 />
      <Element />
      <Footer2 />
    </>
  );
};

const RouteWithNavMbbs = ({ Element }) => {
  return (
    <>
      <Navbar3 />
      <Element />
      <Footer3 />
    </>
  );
};

const RouteWithNavScience = ({ Element }) => {
  return (
    <>
      <Navbar_science />
      <Element />
      <Footer_science />
    </>
  );
};

const RouteWithNavEngineering = ({ Element }) => {
  return (
    <>
      <Navbar_engineering />
      <Element />
      <Footer_engineering />
    </>
  );
};

const RouteWithNavPharmacy = ({ Element }) => {
  return (
    <>
      <Navbar_pharmacy />
      <Element />
      <Footer_pharmacy />
    </>
  );
};

const RouteWithNavArts = ({ Element }) => {
  return (
    <>
      <NavbarArts />
      <Element />
      <Footerarts />
    </>
  );
};

const RouteWithNavArtsCommerce = ({ Element }) => {
  return (
    <>
      <Navbar_arts_commerce />
      <Element />
      <Footer_arts_commerce />
    </>
  );
};

const RouteWithNavItCs = ({ Element }) => {
  return (
    <>
      <Navbar_itcs />
      <Element />
      <Footer_itcs />
    </>
  );
};

const RouteWithNavLaw = ({ Element }) => {
  return (
    <>
      <Navbar_law />
      <Element />
      <Footer_law />
    </>
  );
};

const RouteWithNavNursing = ({ Element }) => {
  return (
    <>
      <Navbar_nursing />
      <Element />
      <Footer_nursing />
    </>
  );
};

const RouteWithNavEducation = ({ Element }) => {
  return (
    <>
      <Navbar_education />
      <Element />
      <Footer_education />
    </>
  );
};

const RouteWithNavAyurveda = ({ Element }) => {
  return (
    <>
      <Navbar_ayurveda />
      <Element />
      <Footer_ayurveda />
    </>
  );
};

const RouteWithNavPhysiotherapy = ({ Element }) => {
  return (
    <>
      <NavbarPhysiotherapy />
      <Element />
      <FooterPhysiotherapy />
    </>
  );
};

const RouteWithNavDesign = ({ Element }) => {
  return (
    <>
      <NavbarDesign />
      <Element />
      <FooterDesign />
    </>
  );
};

function Router() {
  return (
    <div>
      <Routes>
        <Route exact path="/" element={<RouteWithNav Element={Home} />} />
        <Route exact path="/university-notifications/:type" element={<RouteWithNav Element={HomeUniversityNotifications} />} />
        <Route
          exact
          path="*"
          element={<RouteWithNav Element={NotFoundPage} />}
        />
        <Route
          exact
          path="/About"
          element={<RouteWithNav Element={AboutSwaminarayanUniversity} />}
        />
        <Route
          exact
          path="/MissionVision"
          element={<RouteWithNav Element={MissionVision} />}
        />
        <Route
          exact
          path="/Objectives"
          element={<RouteWithNav Element={Objectives} />}
        />
        <Route
          exact
          path="/Milestones"
          element={<RouteWithNav Element={Milestones} />}
        />
        <Route exact path="/Chart" element={<RouteWithNav Element={Chart} />} />
        <Route exact path="/Act" element={<RouteWithNav Element={Act} />} />
        <Route
          exact
          path="/Approval"
          element={<RouteWithNav Element={Approval} />}
        />
        <Route
          exact
          path="/ugcInformation"
          element={<RouteWithNav Element={UGCinformation} />}
        />
        <Route
          exact
          path="/GoverningBody"
          element={<RouteWithNav Element={GoverningBody} />}
        />
        <Route
          exact
          path="/Board-Of-Management"
          element={<RouteWithNav Element={BoardManagement} />}
        />
        <Route
          exact
          path="/AcademicCouncil"
          element={<RouteWithNav Element={AcademicCouncil} />}
        />
        <Route
          exact
          path="/BoardOfStudies"
          element={<RouteWithNav Element={BoardOfStudies} />}
        />
        <Route
          exact
          path="/President"
          element={<RouteWithNav Element={President} />}
        />
        <Route
          exact
          path="/VicePresidents"
          element={<RouteWithNav Element={VicePresidents} />}
        />
        <Route exact path="/Deans" element={<RouteWithNav Element={Deans} />} />
        <Route
          exact
          path="/OtherUniversityOfficers"
          element={<RouteWithNav Element={OtherUniversityOfficers} />}
        />
        <Route
          exact
          path="/Graduate"
          element={<RouteWithNav Element={Graduate} />}
        />
        <Route
          exact
          path="/PostGraduate"
          element={<RouteWithNav Element={PostGraduate} />}
        />
        <Route
          exact
          path="/AboutPrograms"
          element={<RouteWithNav Element={AboutPhd} />}
        />
        <Route
          exact
          path="/AcademicRegulations"
          element={<RouteWithNav Element={AcademicRegulations} />}
        />
        <Route
          exact
          path="/PhdSubjects"
          element={<RouteWithNav Element={PhdSubjects} />}
        />
        <Route
          exact
          path="/PhdNotifications"
          element={<RouteWithNav Element={PhdNotifications} />}
        />
        <Route
          exact
          path="/Diploma"
          element={<RouteWithNav Element={Diploma} />}
        />
        <Route
          exact
          path="/Certificate"
          element={<RouteWithNav Element={Certificate} />}
        />
        <Route
          exact
          path="/Infrastructure"
          element={<RouteWithNav Element={Infrastructure} />}
        />
        <Route
          exact
          path="/AcademicFacilities"
          element={<RouteWithNav Element={AcademicFacilities} />}
        />
        <Route
          exact
          path="/after10th"
          element={<RouteWithNav Element={After10th} />}
        />
        <Route
          exact
          path="/after12th"
          element={<RouteWithNav Element={After12th} />}
        />
        <Route
          exact
          path="/afterUG"
          element={<RouteWithNav Element={AfterUG} />}
        />
        <Route
          exact
          path="/afterPG"
          element={<RouteWithNav Element={AfterPG} />}
        />
        <Route
          exact
          path="/CampusFacilities"
          element={<RouteWithNav Element={CampusFacilities} />}
        />
        <Route
          exact
          path="/Transportation"
          element={<RouteWithNav Element={Transportation} />}
        />
        <Route
          exact
          path="/hostelFacilities"
          element={<RouteWithNav Element={HostelFacilities} />}
        />
        <Route
          exact
          path="/ResidentialFacilities"
          element={<RouteWithNav Element={ResidentialFacilities} />}
        />
        <Route
          exact
          path="/Library"
          element={<RouteWithNav Element={Library} />}
        />
        <Route
          exact
          path="/Schools"
          element={<RouteWithNav Element={Schools} />}
        />
        <Route
          exact
          path="/Auditorium"
          element={<RouteWithNav Element={Auditorium} />}
        />
        <Route
          exact
          path="/EventCalendar"
          element={<RouteWithNav Element={EventCalendar} />}
        />
        <Route
          exact
          path="/events"
          element={<RouteWithNav Element={UniversityEvents} />}
        />
        <Route
          exact
          path="/eventsDetails/:id"
          element={<RouteWithNavScience Element={Eventssmuk} />}
        />
        <Route
          exact
          path="/AdmissionNotifications"
          element={<RouteWithNav Element={AdmissionNotifications} />}
        />
        <Route
          exact
          path="/UniversityNotifications"
          element={<RouteWithNav Element={UniversityNotifications} />}
        />
        {/* <Route
          exact
          path="/EventCalendar"
          element={<RouteWithNav Element={EventCalendar} />}
        /> */}
        <Route exact path="/Media" element={<RouteWithNav Element={Media} />} />
        <Route exact path="/Event" element={<RouteWithNav Element={Event} />} />
        <Route
          exact
          path="/Brochure"
          element={<RouteWithNav Element={Brochure} />}
        />
        <Route
          exact
          path="/AdmissionEnquiry"
          element={<RouteWithNav Element={AdmissionEnquiry} />}
        />
        <Route
          exact
          path="/SankalpVision"
          element={<RouteWithNav Element={SankalpVision2030} />}
        />
        <Route
          exact
          path="/Recruitment"
          element={<RouteWithNav Element={Recruitment} />}
        />
        <Route exact path="/Nep" element={<RouteWithNav Element={Nep} />} />
        <Route
          exact
          path="/Sims/Jobs"
          element={<RouteWithNav Element={SimsRedirect} />}
        />
        <Route
          exact
          path="/Profile"
          element={<RouteWithNav Element={OfficerProfile} />}
        />
        <Route
          exact
          path="/Ombudsperson"
          element={<RouteWithNav Element={Ombudsperson} />}
        />
        ///////Admission ////
        <Route
          exact
          path="/admissionForms"
          element={<RouteWithNav Element={AdmissionForm} />}
        />
        //Student Due Fees
        <Route
          exact
          path={ROUTES.PayFees}
          element={<RouteWithNav Element={StudentDueFees} />}
        />
        <Route
          exact
          path={ROUTES.StudentFeePayment}
          element={<RouteWithNav Element={StudentDueFeesPayment} />}
        />
        //Phd
        <Route
          exact
          path={ROUTES.PhdExamForm}
          element={<RouteWithNav Element={PhdExamForm} />}
        />
        <Route
          exact
          path={ROUTES.PhdExamPayment}
          element={<RouteWithNav Element={PHDExamPayment} />}
        />
        <Route
          exact
          path={ROUTES.PhdFormPrint}
          element={<RouteWithNav Element={PhdFormPrint} />}
        />
        <Route
          exact
          path={ROUTES.Payment_Success}
          element={<RouteWithNav Element={Payment_Success} />}
        />
        <Route
          exact
          path="/PhdAdmissionForm"
          element={<RouteWithNav Element={PhdAdmissionForm} />}
        />
        <Route
          exact
          path="/phdAdmissions/:id"
          element={<RouteWithNav Element={PhdAdmissions} />}
        />
        <Route
          exact
          path="/phdAdmissionsBII/:id"
          element={<RouteWithNav Element={PhdAdmissionsII} />}
        />
        <Route
          exact
          path="/phdHallTicket/:id"
          element={<RouteWithNav Element={PhdHallTicket} />}
        />
        <Route
          exact
          path="/Payment"
          element={<RouteWithNav Element={Payment} />}
        />
        <Route exact path="/nad" element={<RouteWithNav Element={Nad} />} />
        <Route
          exact
          path="/PrivacyPolicy"
          element={<RouteWithNav Element={PrivacyPolicy} />}
        />
        <Route
          exact
          path="/payment-gateway"
          element={<RouteWithNav Element={PaymentGateway} />}
        />
        {/* Exam Time ExamTimeTable */}
        <Route
          exact
          path="/ExamTimeTable"
          element={<RouteWithNav Element={ExamTimeTable} />}
        />
        <Route exact path="/Results" element={<Results />} />
        <Route exact path="/prevResults" element={<PrevResults />} />
        <Route exact path="/prevEnggresults" element={<PrevResultsEngg />} />
        <Route exact path="/prevPharmresults" element={<PrevResultsPharm />} />
        <Route exact path="/Results-eng" element={<ResultsEng />} />
        <Route exact path="/Results-pharmacy" element={<ResultsPharmacy />} />
        <Route
          exact
          path="/prevYearQuestionPapers"
          element={<PrevQuestionPapers />}
        />
        <Route
          exact
          path="/re-exam-Results-pharmacy"
          element={<ReExamPharmacyResuslts />}
        />
        <Route
          exact
          path="/re-exam-results-eng"
          element={<ReExamEngResults />}
        />
        <Route
          exact
          path="/re-exam-Results-pharmacy"
          element={<ReExamPharmacyResuslts />}
        />
        <Route
          exact
          path="/services/:serviceName"
          element={<RouteWithNavMbbs Element={Services} />}
        />
        <Route
          exact
          path="/service/:serviceName"
          element={<RouteWithNavMbbs Element={Service} />}
        />
        <Route exact path="/reCheckingForm" element={<RecheckingForm />} />
        <Route exact path="/reAssessmentForm" element={<Reassesment />} />
        <Route exact path="/draftResults" element={<DraftResults />} />
        <Route
          exact
          path="/reExamDraftResults"
          element={<ReexamDraftResults />}
        />
        <Route exact path="/reExamResults" element={<ReexamResults />} />
        <Route exact path="/draftResults-eng" element={<DraftResultsEng />} />
        <Route
          exact
          path="/exam-registeration"
          element={
            <RouteWithNav Element={StudentExaminationCourseRegisteration} />
          }
        />
        <Route
          exact
          path="/examApplicationForm/:id"
          element={<RouteWithNav Element={ExamApplicationForm} />}
        />
        <Route
          exact
          path="/prevention-of-caste-based-discrimination"
          element={<RouteWithNav Element={StudentsComplaitns} />}
        />
        <Route
          exact
          path={`${ROUTES.MainExamPayment}/:id`}
          element={<RouteWithNav Element={StudentPayment} />}
        />
        <Route
          exact
          path="/draftResults-pharmacy"
          element={<DraftResultsPharmacy />}
        />
        <Route
          exact
          path="/Notifications"
          element={<RouteWithNav Element={Notifications} />}
        />
        <Route
          exact
          path="/IDP"
          element={<RouteWithNav Element={IDP} />}
        />
        <Route
          exact
          path="/Prospectus"
          element={<RouteWithNav Element={Prospectus} />}
        />
        {/* Homeopathy website */}
        <Route
          exact
          path="/Homoeopathy/*"
          element={<RouteWithNavHomeo Element={NotFoundPage} />}
        />
        <Route
          exact
          path="/Homoeopathy/"
          element={<RouteWithNavHomeo Element={Homoeopathy} />}
        />
        <Route
          exact
          path="/Homoeopathy/Hospital-Staff-Attendance"
          element={<RouteWithNavHomeo Element={HospitalStaffAttendanceHomeo} />}
        />
        <Route
          exact
          path="/Homoeopathy/Gallery"
          element={<RouteWithNavHomeo Element={PhotoGalleryHomeopathy} />}
        />
        <Route
          exact
          path="/Homoeopathy/Gallery-Photos/:id"
          element={<RouteWithNavHomeo Element={EventsDetails} />}
        />
        <Route
          exact
          path="/Homoeopathy/AcademicCalendar"
          element={<RouteWithNavHomeo Element={AcademicCalenderHomeopathy} />}
        />
        <Route
          exact
          path="/Homoeopathy/AboutUs"
          element={<RouteWithNavHomeo Element={About_us} />}
        />
        <Route
          exact
          path="/Homoeopathy/FoundersMessage"
          element={<RouteWithNavHomeo Element={FoundersMessage} />}
        />
        <Route
          exact
          path="/Homoeopathy/PrincipalMessage"
          element={<RouteWithNavHomeo Element={PrincipalMessage} />}
        />
        <Route
          exact
          path="/Homoeopathy/MissionVision"
          element={<RouteWithNavHomeo Element={Mission_Vision} />}
        />
        <Route
          exact
          path="/Homoeopathy/AboutCollege"
          element={<RouteWithNavHomeo Element={AboutCollege} />}
        />
        <Route
          exact
          path="/Homoeopathy/CollegeCouncil"
          element={<RouteWithNavHomeo Element={CollegeCouncil} />}
        />
        <Route
          exact
          path="/Homoeopathy/AboutHospital"
          element={<RouteWithNavHomeo Element={AboutHospital} />}
        />
        <Route
          exact
          path="/Homoeopathy/ContactUs"
          element={<RouteWithNavHomeo Element={ContactUs} />}
        />
        <Route
          exact
          path="/Homoeopathy/Recruitment"
          element={<RouteWithNavHomeo Element={HomeoRecruitment} />}
        />
        <Route
          exact
          path="/Homoeopathy/Recognition"
          element={<RouteWithNavHomeo Element={Recognition} />}
        />
        <Route
          exact
          path="/Homoeopathy/Reg.letter"
          element={<RouteWithNavHomeo Element={RegistrationLetter} />}
        />
        <Route
          exact
          path="/Homoeopathy/Rules"
          element={<RouteWithNavHomeo Element={Rules} />}
        />
        <Route
          exact
          path="/Homoeopathy/Syllabus"
          element={<RouteWithNavHomeo Element={HomeoSyllabus} />}
        />
        <Route
          exact
          path="/Homoeopathy/Syllabus/:department"
          element={<RouteWithNavHomeo Element={HomeoSyllabusSem} />}
        />
        <Route
          exact
          path="/Homoeopathy/Syllabus/:year/:semester/:department"
          element={<RouteWithNavHomeo Element={HomeoSyllabusDocs} />}
        />
        <Route
          exact
          path="/Homoeopathy/StudentList"
          element={<RouteWithNavHomeo Element={StudentsListHomeo} />}
        />
        <Route
          exact
          path="/Homoeopathy/resultsList"
          element={<RouteWithNavHomeo Element={HomeoResults} />}
        />
        <Route
          exact
          path="/Homoeopathy/ResearchandPublication"
          element={<RouteWithNavHomeo Element={ResearchandPublication} />}
        />
        <Route
          exact
          path="/Homoeopathy/FeeStructure"
          element={<RouteWithNavHomeo Element={FeeStructure} />}
        />
        <Route
          exact
          path="/Homoeopathy/Admissions"
          element={<RouteWithNavHomeo Element={Admission} />}
        />
        <Route
          exact
          path="/Homoeopathy/Link_for_all_related_bodies_of_the_Central_government_state_government_and_union_territory_Administration"
          element={<RouteWithNavHomeo Element={NCH1} />}
        />
        <Route
          exact
          path="/Homoeopathy/Other_Information_by_the_Commission"
          element={<RouteWithNavHomeo Element={NCH2} />}
        />
        <Route
          exact
          path="/Homoeopathy/Activities_of_Ministry_of_Ayush"
          element={<RouteWithNavHomeo Element={NCH3} />}
        />
        <Route
          exact
          path="/Homoeopathy/NodalOfficer"
          element={<RouteWithNavHomeo Element={NodalOfficer} />}
        />
        <Route
          exact
          path="/Homoeopathy/Details_Of_Medical_Superintendent"
          element={<RouteWithNavHomeo Element={DetailsOfDirector} />}
        />
        <Route
          exact
          path="/Homoeopathy/ViceChancellor"
          element={<RouteWithNavHomeo Element={ViceChancellor} />}
        />
        <Route
          exact
          path="/Homoeopathy/Registrar"
          element={<RouteWithNavHomeo Element={RegistrarNCH} />}
        />
        <Route
          exact
          path="/Homoeopathy/PrincipalSecretary"
          element={<RouteWithNavHomeo Element={PrincipalSecretory} />}
        />
        <Route
          exact
          path="/Homoeopathy/Course"
          element={<RouteWithNavHomeo Element={Courses} />}
        />
        <Route
          exact
          path="/Homoeopathy/Affiliation"
          element={<RouteWithNavHomeo Element={Affiliation} />}
        />
        <Route
          exact
          path="/Homoeopathy/Permission"
          element={<RouteWithNavHomeo Element={Permission} />}
        />
        <Route
          exact
          path="/Homoeopathy/teachingStaff"
          element={<RouteWithNavHomeo Element={HomeopathyTeachingStaff} />}
        />
        <Route
          exact
          path="/Homoeopathy/non-teachingStaff"
          element={<RouteWithNavHomeo Element={NonTeachingStaffHomeo} />}
        />
        <Route
          exact
          path="/Homoeopathy/HospitalStaff"
          element={<RouteWithNavHomeo Element={HospitalStaffHomeo} />}
        />
        <Route
          exact
          path="/Homoeopathy/hospitalStaff"
          element={<RouteWithNavHomeo Element={HomeopathyHospitalStaff} />}
        />
        <Route
          exact
          path="/Homoeopathy/adminStaff"
          element={<RouteWithNavHomeo Element={AdminStaff} />}
        />
        <Route
          exact
          path="/Homoeopathy/OPD"
          element={<RouteWithNavHomeo Element={OPD} />}
        />
        <Route
          exact
          path="/Homoeopathy/IPD"
          element={<RouteWithNavHomeo Element={IPD} />}
        />
        <Route
          exact
          path="/Homoeopathy/events"
          element={<RouteWithNavHomeo Element={Events1} />}
        />
        <Route
          exact
          path="/Homoeopathy/events1"
          element={<RouteWithNavHomeo Element={Events} />}
        />
        <Route
          exact
          path="/Homoeopathy/eventsDetails/:id"
          element={<RouteWithNavHomeo Element={EventsDetails} />}
        />
        <Route
          exact
          path="/Homoeopathy/Achievements-Awards-Medals"
          element={<RouteWithNavHomeo Element={AchievementsHomeo} />}
        />
        <Route
          exact
          path="/Homoeopathy/Details/:id"
          element={<RouteWithNavHomeo Element={AchievementsDetails} />}
        />
        <Route
          exact
          path="/Homoeopathy/President"
          element={<RouteWithNavHomeo Element={PresidentMsg} />}
        />
        <Route
          exact
          path="/Homoeopathy/Provost"
          element={<RouteWithNavHomeo Element={Provost} />}
        />
        <Route
          exact
          path="/Homoeopathy/Vice-Chancellor"
          element={<RouteWithNavHomeo Element={Provost} />}
        />
        <Route
          exact
          path="/Homoeopathy/VicePresidentMsg"
          element={<RouteWithNavHomeo Element={VicePresidentsHomeo} />}
        />
        <Route
          exact
          path="/Homoeopathy/Vice-President"
          element={<RouteWithNavHomeo Element={VicePresidentHomeo} />}
        />
        <Route
          exact
          path="/Homoeopathy/VicePresident"
          element={<RouteWithNavHomeo Element={VicePresident2Homeo} />}
        />
        <Route
          exact
          path="/Homoeopathy/vice-President-Profile"
          element={<RouteWithNavHomeo Element={VicePresident3Homeo} />}
        />
        <Route
          exact
          path="/Homoeopathy/Registrar-Profile"
          element={<RouteWithNavHomeo Element={Registrar} />}
        />
        <Route
          exact
          path="/Homoeopathy/ContactUs"
          element={<RouteWithNavHomeo Element={ContactUs} />}
        />
        {/* Ayurvedic */}
        <Route
          exact
          path="/sims/*"
          element={<RouteWithNavMbbs Element={NotFoundPage} />}
        />
        <Route
          exact
          path="/sims"
          element={<RouteWithNavMbbs Element={Ayurvedic} />}
        />
        <Route
          exact
          path="/sims/AcademicCalendar"
          element={<RouteWithNavMbbs Element={AcademicCalendersims} />}
        />
        <Route
          exact
          path="/sims/deanMedicalSuperintendent"
          element={<RouteWithNavMbbs Element={DeanMedicalSuperintendent} />}
        />
          <Route
          exact
          path="/sims/FoundersMessage"
          element={<RouteWithNavMbbs Element={SimsFoundersMessage} />}
        />
         <Route
          exact
          path="/sims/Pgseats"
          element={<RouteWithNavMbbs Element={Pgseats} />}
        />
           <Route
          exact
          path="/sims/gallery"
          element={<RouteWithNavMbbs Element={Gallery} />}
        />
        <Route
          exact
          path="/sims/Hospital-Staff-Attendance"
          element={<RouteWithNavMbbs Element={HospitalStaffAttendancesims} />}
        />
        <Route
          exact
          path="/sims/events"
          element={<RouteWithNavMbbs Element={Eventssims} />}
        />
        <Route
          exact
          path="/sims/eventsDetails/:id"
          element={<RouteWithNavMbbs Element={EventsDetails} />}
        />
        <Route
          exact
          path="/sims/Achievements-Awards-Medals"
          element={<RouteWithNavMbbs Element={AchievementsSims} />}
        />
        <Route
          exact
          path="/sims/Details/:id"
          element={<RouteWithNavMbbs Element={AchievementsDetails} />}
        />
         <Route
          exact
          path="/sims/VicePresidentMsg"
          element={<RouteWithNavMbbs Element={VicePresidentMsg} />}
        />
        <Route
          exact
          path="/sims/Vice-President"
          element={<RouteWithNavMbbs Element={VicePresidentsims} />}
        />
        <Route
          exact
          path="/sims/VicePresident"
          element={<RouteWithNavMbbs Element={VicePresident2sims} />}
        />
        <Route
          exact
          path="/sims/vice-President-Profile"
          element={<RouteWithNavMbbs Element={VicePresident3sims} />}
        />
        <Route
          exact
          path="/sims/Provost(Vice-Chancellor)"
          element={<RouteWithNavMbbs Element={Provost} />}
        />
         <Route
          exact
          path="/sims/Transportation"
          element={<RouteWithNavMbbs Element={Transportation} />}
        />
        {/* <Route
          exact
          path="/sims/Library"
          element={<RouteWithNavMbbs Element={Library} />}
        /> */}
        <Route
          exact
          path="/sims/ResidentialFacilities"
          element={<RouteWithNavMbbs Element={ResidentialFacilities} />}
        />
        <Route
          exact
          path="/sims/Registrar"
          element={<RouteWithNavMbbs Element={Registrar} />}
        />
        <Route
          exact
          path="/sims/Advisor"
          element={<RouteWithNavMbbs Element={Advisor} />}
        />
        <Route
          exact
          path="/Profile"
          element={<RouteWithNavMbbs Element={OfficerProfile} />}
        />
        <Route
          exact
          path="/staff/:serviceName"
          element={<RouteWithNavMbbs Element={StaffDetails} />}
        />
        <Route
          exact
          path="/sims/AboutCollege"
          element={<RouteWithNavMbbs Element={simsAboutCollege} />}
        />
        <Route
          exact
          path="/sims/intakeSanctionedCapacity"
          element={<RouteWithNavMbbs Element={IntakeSanctionedCapacity} />}
        />
        <Route
          exact
          path="/sims/feeDetails"
          element={<RouteWithNavMbbs Element={FeesDetails} />}
        />
        <Route
          exact
          path="/sims/recognitionStatus"
          element={<RouteWithNavMbbs Element={RecognitionStatus} />}
        />
        <Route
          exact
          path="/sims/status"
          element={<RouteWithNavMbbs Element={Status} />}
        />
        <Route
          exact
          path="/sims/studentsList"
          element={<RouteWithNavMbbs Element={StudentsList} />}
        />
        <Route
          exact
          path="/sims/academicsActivities"
          element={<RouteWithNavMbbs Element={Academic_Activities} />}
        />
        <Route
          exact
          path="/sims/resultOfExamination"
          element={<RouteWithNavMbbs Element={ResultOfExamination} />}
        />
        <Route
          exact
          path="/sims/researchandPulication"
          element={<RouteWithNavMbbs Element={ReseachandPublication} />}
        />
        <Route
          exact
          path="/sims/clgSyllabus"
          element={<RouteWithNavMbbs Element={Syllabuss} />}
        />
        <Route
          exact
          path="/sims/rulesRegulation"
          element={<RouteWithNavMbbs Element={RulesRegulations} />}
        />
        <Route
          exact
          path="/sims/admissions"
          element={<RouteWithNavMbbs Element={Admissions} />}
        />
        <Route
          exact
          path="/sims/contactUs"
          element={<RouteWithNavMbbs Element={Contact} />}
        />
        <Route
          exact
          path="/sims/missionVision"
          element={<RouteWithNavMbbs Element={MissionVisionMBBS} />}
        />
        <Route
          exact
          path="/sims/aboutHospital"
          element={<RouteWithNavMbbs Element={AboutMBBSHospital} />}
        />
        <Route
          exact
          path="/sims/deanMedicalSuperintendentMessage"
          element={<RouteWithNavMbbs Element={DeanMessage} />}
        />
        <Route
          exact
          path="/sims/citizenCharter"
          element={<RouteWithNavMbbs Element={Citizencharter} />}
        />
        <Route
          exact
          path="/sims/teachingStaff"
          element={<RouteWithNavMbbs Element={TeachingStaff} />}
        />
        <Route
          exact
          path="/sims/Recruitment"
          element={<RouteWithNavMbbs Element={MedicalRecruitment} />}
        />
        <Route
          exact
          path="/sims/Permission"
          element={<RouteWithNavMbbs Element={MedicalClgPermission} />}
        />
        <Route
          exact
          path="/sims/Affiliation"
          element={<RouteWithNavMbbs Element={MedicineClgAffiliation} />}
        />
        <Route
          exact
          path="/sims/StudentList"
          element={<RouteWithNavMbbs Element={StudentsListMedical} />}
        />
        <Route
          exact
          path="/sims/resultsList"
          element={<RouteWithNavMbbs Element={MedicineStudentResults} />}
        />
        <Route
          exact
          path="/sims/TeachingStaff"
          element={<RouteWithNavMbbs Element={TeachingStaffMedical} />}
        />
        <Route
          exact
          path="/sims/non-teachingStaff"
          element={<RouteWithNavMbbs Element={NonTeachingStaffMedical} />}
        />
        <Route
          exact
          path="/sims/OpdServices"
          element={<RouteWithNavMbbs Element={MedicineOPDServices} />}
        />
        <Route
          exact
          path="/sims/IpdServices"
          element={<RouteWithNavMbbs Element={MedicineIPDServices} />}
        />
         <Route
          exact
          path="/sims/YearlyPlanner"
          element={<RouteWithNavMbbs Element={YearlyPlanner} />}
        />
         <Route
          exact
          path="/sims/TimeTable"
          element={<RouteWithNavMbbs Element={TimeTable} />}
        />
         <Route
          exact
          path="/sims/Examination"
          element={<RouteWithNavMbbs Element={Examination} />}
        />
         <Route
          exact
          path="/sims/Results"
          element={<RouteWithNavMbbs Element={ResultsSim} />}
        />
         <Route
          exact
          path="/sims/AwardsandMedals"
          element={<RouteWithNavMbbs Element={AwardsandMedals} />}
        />
         <Route
          exact
          path="/sims/HospitalDetails"
          element={<RouteWithNavMbbs Element={HospitalDetails} />}
        />
         <Route
          exact
          path="/sims/StatusandRecognition"
          element={<RouteWithNavMbbs Element={StatusandRecognition} />}
        />
         <Route
          exact
          path="/sims/Hostel"
          element={<RouteWithNavMbbs Element={Hostel} />}
        />
         <Route
          exact
          path="/sims/GrievanceRedressalOfficer"
          element={<RouteWithNavMbbs Element={GrievanceRedressalOfficer} />}
        />
         <Route
          exact
          path="/sims/Library"
          element={<RouteWithNavMbbs Element={LibrarySim} />}
        />
        <Route
          exact
          path="/sims/ContactUs"
          element={<RouteWithNavMbbs Element={ContactUs} />}
        />
        {/* <Route exact path='/examinationportal' element={<RouteWithExam Element={DashboardHR} />}></Route> */}
        {/* SCIENCE   */}
        <Route
          exact
          path="/science/*"
          element={<RouteWithNavScience Element={NotFoundPage} />}
        />
        <Route
          exact
          path="/science"
          element={<RouteWithNavScience Element={Science} />}
        />
        <Route
          exact
          path="/science/Gallery"
          element={<RouteWithNavScience Element={PhotoGalleryScience} />}
        />
        <Route
          exact
          path="/science/Gallery-Photos/:id"
          element={<RouteWithNavScience Element={EventsDetails} />}
        />
        <Route
          exact
          path="/science/AcademicCalendar"
          element={<RouteWithNavScience Element={AcademicCalendarScience} />}
        />
        <Route
          exact
          path="/science/PrincipalMessage"
          element={<RouteWithNavScience Element={SciencePrincipalMessage} />}
        />
        <Route
          exact
          path="/science/PrincipalMessage"
          element={<RouteWithNavScience Element={SciencePrincipalMessage} />}
        />
        <Route
          exact
          path="/science/AboutUs"
          element={<RouteWithNavScience Element={ScienceAboutUs} />}
        />
        <Route
          exact
          path="/science/FoundersMessage"
          element={<RouteWithNavScience Element={ScienceFoundersMessage} />}
        />
        <Route
          exact
          path="/science/MissionVision"
          element={<RouteWithNavScience Element={ScienceMissionVision} />}
        />
        <Route
          exact
          path="/science/AboutCollege"
          element={<RouteWithNavScience Element={ScienceAboutCollege} />}
        />
        <Route
          exact
          path="/science/CollegeCouncil"
          element={<RouteWithNavScience Element={ScienceCollegeCouncil} />}
        />
        <Route
          exact
          path="/science/Courses"
          element={<RouteWithNavScience Element={ScienceCourses} />}
        />
        <Route
          exact
          path="/science/FeeStructure"
          element={<RouteWithNavScience Element={ScienceFeeStructure} />}
        />
        <Route
          exact
          path="/science/Rules"
          element={<RouteWithNavScience Element={ScienceRules} />}
        />
        <Route
          exact
          path="/science/Syllabus"
          element={<RouteWithNavScience Element={ScienceSyllabus} />}
        />
        <Route
          exact
          path="/science/Syllabus/:year/:semester"
          element={<RouteWithNavScience Element={ScienceSyllabusDocs} />}
        />
        <Route
          exact
          path="/science/Syllabus/:department"
          element={<RouteWithNavScience Element={ScienceSyllabusSem} />}
        />
        <Route
          exact
          path="/science/Eligibility"
          element={<RouteWithNavScience Element={ScienceEligibility} />}
        />
        <Route
          exact
          path="/science/TeachingStaff"
          element={<RouteWithNavScience Element={ScienceTeachingStaff} />}
        />
        <Route
          exact
          path="/science/non-teachingStaff"
          element={<RouteWithNavScience Element={NonTeachingStaffScience} />}
        />
        <Route
          exact
          path="/science/Infrastructure"
          element={<RouteWithNavScience Element={ScienceInfrastructure} />}
        />
        <Route
          exact
          path="/science/ResearchandPublicationScience"
          element={
            <RouteWithNavScience Element={ReseachandPublicationScience} />
          }
        />
        <Route
          exact
          path="/science/StudentList"
          element={<RouteWithNavScience Element={StudentsListScience} />}
        />
        <Route
          exact
          path="/science/events"
          element={<RouteWithNavScience Element={ScienceEvents} />}
        />
        <Route
          exact
          path="/science/eventsDetails/:id"
          element={<RouteWithNavScience Element={EventsDetails} />}
        />
        <Route
          exact
          path="/science/Achievements-Awards-Medals"
          element={<RouteWithNavScience Element={AchievementsScience} />}
        />
        <Route
          exact
          path="/science/Details/:id"
          element={<RouteWithNavScience Element={AchievementsDetails} />}
        />
        <Route
          exact
          path="/science/resultsList"
          element={<RouteWithNavScience Element={ScienceResults} />}
        />
        <Route
          exact
          path="/science/Permission"
          element={<RouteWithNavScience Element={ScienceClgPermission} />}
        />
        <Route
          exact
          path="/science/Affiliation"
          element={<RouteWithNavScience Element={ScienceClgAffiliation} />}
        />
        <Route
          exact
          path="/science/UGC-Permission-Letter"
          element={<RouteWithNavScience Element={UGCLetterPermissionScience} />}
        />
        <Route
          exact
          path="/science/Recruitment"
          element={<RouteWithNavScience Element={ScienceRecruitment} />}
        />
        <Route
          exact
          path="/science/President"
          element={<RouteWithNavScience Element={PresidentMsg} />}
        />
        <Route
          exact
          path="/science/Provost"
          element={<RouteWithNavScience Element={Provost} />}
        />
        <Route
          exact
          path="/science/VicePresidentMsg"
          element={<RouteWithNavScience Element={VicePresidentsScience} />}
        />
        <Route
          exact
          path="/science/Vice-President"
          element={<RouteWithNavScience Element={VicePresidentScience} />}
        />
        <Route
          exact
          path="/science/VicePresident"
          element={<RouteWithNavScience Element={VicePresident2Science} />}
        />
        <Route
          exact
          path="/science/vice-President-Profile"
          element={<RouteWithNavScience Element={VicePresident3Science} />}
        />
        <Route
          exact
          path="/science/Registrar-Profile"
          element={<RouteWithNavScience Element={Registrar} />}
        />
        <Route
          exact
          path="/science/Admissions"
          element={<RouteWithNavScience Element={Admission} />}
        />
        <Route
          exact
          path="/science/ContactUs"
          element={<RouteWithNavScience Element={ContactUsScience} />}
        />
        {/* ENGINEERING */}
        <Route
          exact
          path="/engineering/*"
          element={<RouteWithNavEngineering Element={NotFoundPage} />}
        />
        <Route
          exact
          path="/engineering/AboutUs"
          element={<RouteWithNavEngineering Element={ScienceAboutUs} />}
        />
        <Route
          exact
          path="/engineering/MissionVision"
          element={<RouteWithNavEngineering Element={Mission_VisionEng} />}
        />
        <Route
          exact
          path="/engineering/FoundersMessage"
          element={<RouteWithNavEngineering Element={ScienceFoundersMessage} />}
        />
        <Route
          exact
          path="/engineering"
          element={<RouteWithNavEngineering Element={Engineering} />}
        />
        <Route
          exact
          path="/engineering/Courses"
          element={<RouteWithNavEngineering Element={EngineeringCourses} />}
        />
        <Route
          exact
          path="/engineering/President"
          element={<RouteWithNavEngineering Element={PresidentMsg} />}
        />
        <Route
          exact
          path="/engineering/Vice-President"
          element={<RouteWithNavEngineering Element={VicePresident} />}
        />
        <Route
          exact
          path="/engineering/VicePresident"
          element={<RouteWithNavEngineering Element={VicePresident2} />}
        />
        <Route
          exact
          path="/engineering/vice-President-Profile"
          element={<RouteWithNavEngineering Element={VicePresident3} />}
        />
        <Route
          exact
          path="/engineering/VicePresidents"
          element={<RouteWithNavEngineering Element={VicePresidentsEng} />}
        />
        <Route
          exact
          path="/engineering/Profile"
          element={<RouteWithNavEngineering Element={OfficerProfile} />}
        />
        <Route
          exact
          path="/engineering/Provost-Profile"
          element={<RouteWithNavEngineering Element={Provost} />}
        />
        <Route
          exact
          path="/engineering/Registrar-Profile"
          element={<RouteWithNavEngineering Element={Registrar} />}
        />
        <Route
          exact
          path="/engineering/Eligibility"
          element={<RouteWithNavEngineering Element={EngineeringEligibility} />}
        />
        <Route
          exact
          path="/engineering/FeeStructure"
          element={
            <RouteWithNavEngineering Element={EngineeringFeeStructure} />
          }
        />
        <Route
          exact
          path="/engineering/AcademicCalender"
          element={
            <RouteWithNavEngineering Element={EngineeringAcademicCalender} />
          }
        />
        <Route
          exact
          path="/engineering/PrincipalMessage"
          element={
            <RouteWithNavEngineering Element={EngineeringPrincipleMessage} />
          }
        />
        <Route
          exact
          path="/engineering/TeachingStaff"
          element={
            <RouteWithNavEngineering Element={EngineeringTeachingStaff} />
          }
        />
        <Route
          exact
          path="/engineering/AboutCollege"
          element={
            <RouteWithNavEngineering Element={AboutCollegeEngineering} />
          }
        />
        <Route
          exact
          path="/engineering/Infrastructure"
          element={<RouteWithNavEngineering Element={InfrastructureEngineering} />}
        />
        <Route
          exact
          path="/engineering/non-teachingStaff"
          element={<RouteWithNavEngineering Element={NonTeachingStaffEng} />}
        />
        <Route
          exact
          path="/engineering/Workshop"
          element={<RouteWithNavEngineering Element={EngineeringWorkshop} />}
        />
        <Route
          exact
          path="/engineering/Funding"
          element={<RouteWithNavEngineering Element={EngineeringFunding} />}
        />
        <Route
          exact
          path="/engineering/Publications"
          element={
            <RouteWithNavEngineering Element={EngineeringPublications} />
          }
        />
        <Route
          exact
          path="/engineering/CampusPlacements"
          element={
            <RouteWithNavEngineering Element={EngineeringCampusPlacements} />
          }
        />
        <Route
          exact
          path="/engineering/AlumniMeetings"
          element={
            <RouteWithNavEngineering Element={EngineeringAlumniMeetings} />
          }
        />
        <Route
          exact
          path="/engineering/Syllabus"
          element={<RouteWithNavEngineering Element={EngineeringSyllabus} />}
        />
        <Route
          exact
          path="/engineering/Syllabus/:department"
          element={<RouteWithNavEngineering Element={EngSyllabusSem} />}
        />
        <Route
          exact
          path="/engineering/Recruitment"
          element={<RouteWithNavEngineering Element={EngRecruitment} />}
        />
        <Route
          exact
          path="/engineering/Syllabus/:year/:semester"
          element={
            <RouteWithNavEngineering Element={EngineeringSyllabusDocs} />
          }
        />
        <Route
          exact
          path="/engineering/Permission"
          element={<RouteWithNavEngineering Element={EngClgPermission} />}
        />
        <Route
          exact
          path="/engineering/Affiliation"
          element={<RouteWithNavEngineering Element={EngClgAffiliation} />}
        />
        <Route
          exact
          path="/engineering/ContactUs"
          element={<RouteWithNavEngineering Element={ContactUsEng} />}
        />
        {/* Pharmacy  */}
        <Route
          exact
          path="/pharmacy/*"
          element={<RouteWithNavPharmacy Element={NotFoundPage} />}
        />
        <Route
          exact
          path="/pharmacy"
          element={<RouteWithNavPharmacy Element={PharmacyHome} />}
        />
        <Route
          exact
          path="/pharmacy/AboutCollege"
          element={<RouteWithNavPharmacy Element={AboutCollegePharmacy} />}
        />
        <Route
          exact
          path="/pharmacy/Permission"
          element={<RouteWithNavPharmacy Element={PharmacyClgPermission} />}
        />
        <Route
          exact
          path="/pharmacy/Affiliation"
          element={<RouteWithNavPharmacy Element={PharmacyClgAffiliation} />}
        />
        <Route
          exact
          path="/pharmacy/Courses"
          element={<RouteWithNavPharmacy Element={PharmacyCourses} />}
        />
        <Route
          exact
          path="/pharmacy/Eligibility"
          element={<RouteWithNavPharmacy Element={PharmacyEligibility} />}
        />
        <Route
          exact
          path="/pharmacy/AcademicCalender"
          element={<RouteWithNavPharmacy Element={PharmacyAcademicCalender} />}
        />
        <Route
          exact
          path="/pharmacy/PrincipalMessage"
          element={<RouteWithNavPharmacy Element={PharmacyPrincipalMessage} />}
        />
        <Route
          exact
          path="/pharmacy/PharmacyWorkshopPublications"
          element={
            <RouteWithNavPharmacy Element={PharmacyWorkshopPublications} />
          }
        />
        <Route
          exact
          path="/pharmacy/Placement-students"
          element={<RouteWithNavPharmacy Element={PharmacyPlacements} />}
        />
        <Route
          exact
          path="/pharmacy/PCI-SIF"
          element={<RouteWithNavPharmacy Element={PCI_SIF_Pharmacy} />}
        />
        <Route
          exact
          path="/pharmacy/TeachingStaff"
          element={<RouteWithNavPharmacy Element={PharmacyTeachingStaff} />}
        />
        <Route
          exact
          path="/pharmacy/non-teachingStaff"
          element={<RouteWithNavPharmacy Element={NonTeachingStaffPharmacy} />}
        />
        <Route
          exact
          path="/pharmacy/StudentList"
          element={<RouteWithNavPharmacy Element={StudentsListPharmacy} />}
        />
        <Route
          exact
          path="/Results-pharmacy"
          element={<RouteWithNavPharmacy Element={StudentsResultsPharmacy} />}
        />
        <Route
          exact
          path="/pharmacy/FoundersMessage"
          element={<RouteWithNavPharmacy Element={PharmacyFoundersMessage} />}
        />
        <Route
          exact
          path="/pharmacy/AboutUs"
          element={<RouteWithNavPharmacy Element={PharmacyAboutUs} />}
        />
        <Route
          exact
          path="/pharmacy/MissionVision"
          element={<RouteWithNavPharmacy Element={PharmacyMissionVision} />}
        />
        <Route
          exact
          path="/pharmacy/Infrastructure"
          element={<RouteWithNavPharmacy Element={PharmacyInfrastructure} />}
        />
        <Route
          exact
          path="/pharmacy/Syllabus"
          element={<RouteWithNavPharmacy Element={PharmacySyllabus} />}
        />
        <Route
          exact
          path="/pharmacy/Syllabus/:department"
          element={<RouteWithNavPharmacy Element={PharmacySyllabusSem} />}
        />
        <Route
          exact
          path="/pharmacy/Syllabus/:year/:semester"
          element={<RouteWithNavPharmacy Element={PharmacySyllabusDocs} />}
        />
        <Route
          exact
          path="/pharmacy/events"
          element={<RouteWithNavPharmacy Element={PharmacyEvents} />}
        />
        <Route
          exact
          path="/pharmacy/eventsDetails/:id"
          element={<RouteWithNavPharmacy Element={EventsDetails} />}
        />
        <Route
          exact
          path="/pharmacy/Achievements-Awards-Medals"
          element={<RouteWithNavPharmacy Element={AchievementsPharmacy} />}
        />
        <Route
          exact
          path="/pharmacy/Details/:id"
          element={<RouteWithNavPharmacy Element={AchievementsDetails} />}
        />
        <Route
          exact
          path="/pharmacy/Recruitment"
          element={<RouteWithNavPharmacy Element={PharmacyRecruitment} />}
        />
        <Route
          exact
          path="/pharmacy/President"
          element={<RouteWithNavPharmacy Element={PresidentMsg} />}
        />
        <Route
          exact
          path="/pharmacy/RulesandRegulations"
          element={<RouteWithNavPharmacy Element={RulesPharmacy} />}
        />
        <Route
          exact
          path="/pharmacy/Provost"
          element={<RouteWithNavPharmacy Element={Provost} />}
        />
        <Route
          exact
          path="/pharmacy/VicePresidentMsg"
          element={<RouteWithNavPharmacy Element={VicePresidentsPharmacy} />}
        />
        <Route
          exact
          path="/pharmacy/Vice-President"
          element={<RouteWithNavPharmacy Element={VicePresidentPharmacy} />}
        />
        <Route
          exact
          path="/pharmacy/VicePresident"
          element={<RouteWithNavPharmacy Element={VicePresident2Pharmacy} />}
        />
        <Route
          exact
          path="/pharmacy/vice-President-Profile"
          element={<RouteWithNavPharmacy Element={VicePresident3Pharmacy} />}
        />
        <Route
          exact
          path="/pharmacy/Registrar-Profile"
          element={<RouteWithNavPharmacy Element={Registrar} />}
        />
        <Route
          exact
          path="/pharmacy/ContactUs"
          element={<RouteWithNavPharmacy Element={ContactUsPharmacy} />}
        />

        {/* Arts  */}
        <Route
          exact
          path="/arts/*"
          element={<RouteWithNavArts Element={NotFoundPage} />}
        />
        <Route
          exact
          path="/arts"
          element={<RouteWithNavArts Element={ArtsHome} />}
        />
        <Route
          exact
          path="/arts/AcademicCalendar"
          element={<RouteWithNavArts Element={AcademicCalenderArts} />}
        />
        <Route
          exact
          path="/arts/Permission"
          element={<RouteWithNavArts Element={ArtsClgPermission} />}
        />
        <Route
          exact
          path="/arts/Affiliation"
          element={<RouteWithNavArts Element={ArtsClgAffiliation} />}
        />
        <Route
          exact
          path="/arts/TeachingStaff"
          element={
            <RouteWithNavArts Element={TeachingStaffArts} />
          }
        />
        <Route
          exact
          path="/arts/non-teachingStaff"
          element={
            <RouteWithNavArts Element={NonTeachingStaffArts} />
          }
        />
        <Route
          exact
          path="/arts/AboutUs"
          element={<RouteWithNavArts Element={About_usArts} />}
        />
        <Route
          exact
          path="/arts/FoundersMessage"
          element={
            <RouteWithNavArts Element={FoundersMessageArts} />
          }
        />
        <Route
          exact
          path="/arts/AboutCollege"
          element={
            <RouteWithNavArts Element={AboutCollegeArts} />
          }
        />
        <Route
          exact
          path="/arts/MissionVision"
          element={
            <RouteWithNavArts Element={Mission_VisionArts} />
          }
        />
        <Route
          exact
          path="/arts/events"
          element={<RouteWithNavArts Element={Eventsarts} />}
        />
        <Route
          exact
          path="/arts/eventsDetails/:id"
          element={<RouteWithNavArts Element={EventsDetails} />}
        />
        <Route
          exact
          path="/arts/Achievements-Awards-Medals"
          element={<RouteWithNavArts Element={AchievementsArts} />}
        />
        <Route
          exact
          path="/arts/Details/:id"
          element={<RouteWithNavArts Element={AchievementsDetails} />}
        />
        <Route
          exact
          path="/arts/PrincipalMessage"
          element={
            <RouteWithNavArts Element={PrincipalMessageArts} />
          }
        />
        <Route
          exact
          path="/arts/Syllabus"
          element={<RouteWithNavArts Element={ArtsSyllabus} />}
        />
        <Route
          exact
          path="/arts/Syllabus/:department"
          element={
            <RouteWithNavArts Element={ArtsSyllabusSem} />
          }
        />
        <Route
          exact
          path="/arts/Syllabus/:year/:semester"
          element={
            <RouteWithNavArts Element={ArtsSyllabusDocs} />
          }
        />
        <Route
          exact
          path="/arts/ResearchandPublication"
          element={
            <RouteWithNavArts
              Element={ArtsResearchandPublication}
            />
          }
        />
        <Route
          exact
          path="/arts/Recruitment"
          element={
            <RouteWithNavArts Element={ArtsRecruitment} />
          }
        />
        <Route
          exact
          path="/arts/StudentList"
          element={<RouteWithNavArts Element={StudentsListArts} />}
        />
        <Route
          exact
          path="/arts/resultsList"
          element={
            <RouteWithNavArts Element={StudentsResultsArts} />
          }
        />
        <Route
          exact
          path="/arts/ContactUs"
          element={<RouteWithNavArts Element={ContactUsArts} />}
        />
        <Route
          exact
          path="/arts/President"
          element={<RouteWithNavArts Element={PresidentMsg} />}
        />
        <Route
          exact
          path="/arts/Provost"
          element={<RouteWithNavArts Element={Provost} />}
        />
        <Route
          exact
          path="/arts/VicePresidentMsg"
          element={<RouteWithNavArts Element={VicePresidentsArts} />}
        />
        <Route
          exact
          path="/arts/Vice-President"
          element={<RouteWithNavArts Element={VicePresidentArts} />}
        />
        <Route
          exact
          path="/arts/VicePresident"
          element={<RouteWithNavArts Element={VicePresident2Arts} />}
        />
        <Route
          exact
          path="/arts/vice-President-Profile"
          element={<RouteWithNavArts Element={VicePresident3Arts} />}
        />
        <Route
          exact
          path="/arts/Registrar-Profile"
          element={<RouteWithNavArts Element={Registrar} />}
        />
        <Route
          exact
          path="/arts/Admissions"
          element={<RouteWithNavArts Element={AdmissionsArts} />}
        />

        
        {/* COMMERCE  */}
        <Route
          exact
          path="/commerce/*"
          element={<RouteWithNavArtsCommerce Element={NotFoundPage} />}
        />
        <Route
          exact
          path="/commerce"
          element={<RouteWithNavArtsCommerce Element={ArtsCommerceHome} />}
        />
        <Route
          exact
          path="/commerce/AcademicCalendar"
          element={<RouteWithNavArtsCommerce Element={AcademicCalenderCommerce} />}
        />
        <Route
          exact
          path="/commerce/arts-courses"
          element={
            <RouteWithNavArtsCommerce Element={ArtsCommerceArtsCourses} />
          }
        />
        <Route
          exact
          path="/commerce/commerce-courses"
          element={
            <RouteWithNavArtsCommerce Element={ArtsCommerceCommerceCourses} />
          }
        />
        <Route
          exact
          path="/commerce/events"
          element={<RouteWithNavArtsCommerce Element={Eventscommerce} />}
        />
        <Route
          exact
          path="/commerce/eventsDetails/:id"
          element={<RouteWithNavArtsCommerce Element={EventsDetails} />}
        />
        <Route
          exact
          path="/commerce/Achievements-Awards-Medals"
          element={<RouteWithNavArtsCommerce Element={AchievementsCommerce} />}
        />
        <Route
          exact
          path="/commerce/Details/:id"
          element={<RouteWithNavArtsCommerce Element={AchievementsDetails} />}
        />
        <Route
          exact
          path="/commerce/Permission"
          element={<RouteWithNavArtsCommerce Element={CommerceClgPermission} />}
        />
        <Route
          exact
          path="/commerce/Affiliation"
          element={<RouteWithNavArtsCommerce Element={CommerceClgAffiliation} />}
        />
        <Route
          exact
          path="/commerce/arts-eligibility"
          element={
            <RouteWithNavArtsCommerce Element={ArtsCommerceArtsEligibility} />
          }
        />
        <Route
          exact
          path="/commerce/commerce-eligibility"
          element={
            <RouteWithNavArtsCommerce
              Element={ArtsCommerceCommerceEligibility}
            />
          }
        />
        <Route
          exact
          path="/commerce/arts-fee-structure"
          element={
            <RouteWithNavArtsCommerce Element={ArtsCommerceArtsFeeStructure} />
          }
        />
        <Route
          exact
          path="/commerce/commerce-fee-structure"
          element={
            <RouteWithNavArtsCommerce
              Element={ArtsCommerceCommerceFeeStructure}
            />
          }
        />
        <Route
          exact
          path="/commerce/PrincipalMessage"
          element={
            <RouteWithNavArtsCommerce Element={ArtsCommercePrincipalMessage} />
          }
        />
        <Route
          exact
          path="/commerce/Admissions"
          element={<RouteWithNavArtsCommerce Element={Admission} />}
        />
        <Route
          exact
          path="/commerce/President"
          element={<RouteWithNavArtsCommerce Element={PresidentMsg} />}
        />
        <Route
          exact
          path="/commerce/Provost"
          element={<RouteWithNavArtsCommerce Element={Provost} />}
        />
        <Route
          exact
          path="/commerce/VicePresidentMsg"
          element={<RouteWithNavArtsCommerce Element={VicePresidentsArtsCommerce} />}
        />
        <Route
          exact
          path="/commerce/Vice-President"
          element={<RouteWithNavArtsCommerce Element={VicePresidentArtsCommerce} />}
        />
        <Route
          exact
          path="/commerce/VicePresident"
          element={<RouteWithNavArtsCommerce Element={VicePresident2ArtsCommerce} />}
        />
        <Route
          exact
          path="/commerce/vice-President-Profile"
          element={<RouteWithNavArtsCommerce Element={VicePresident3ArtsCommerce} />}
        />
        <Route
          exact
          path="/commerce/Registrar-Profile"
          element={<RouteWithNavArtsCommerce Element={Registrar} />}
        />
        <Route
          exact
          path="/commerce/TeachingStaff"
          element={
            <RouteWithNavArtsCommerce Element={ArtsCommerceTeachingStaff} />
          }
        />
        <Route
          exact
          path="/commerce/non-teachingStaff"
          element={
            <RouteWithNavArtsCommerce Element={NonTeachingStaffArtsComm} />
          }
        />
        <Route
          exact
          path="/commerce/AboutUs"
          element={<RouteWithNavArtsCommerce Element={ArtsCommerceAboutUs} />}
        />
        <Route
          exact
          path="/commerce/FoundersMessage"
          element={
            <RouteWithNavArtsCommerce Element={ArtsCommerceFoundersMessage} />
          }
        />
        <Route
          exact
          path="/commerce/AboutCollege"
          element={
            <RouteWithNavArtsCommerce Element={ArtsCommerceAboutCollege} />
          }
        />
        <Route
          exact
          path="/commerce/MissionVision"
          element={
            <RouteWithNavArtsCommerce Element={ArtsCommerceMissionVision} />
          }
        />
        <Route
          exact
          path="/commerce/Syllabus"
          element={<RouteWithNavArtsCommerce Element={ArtsCommerceSyllabus} />}
        />
        <Route
          exact
          path="/commerce/Syllabus/:department"
          element={
            <RouteWithNavArtsCommerce Element={ArtsCommerceSyllabusSem} />
          }
        />
        <Route
          exact
          path="/commerce/Syllabus/:year/:semester"
          element={
            <RouteWithNavArtsCommerce Element={ArtsCommerceSyllabusDocs} />
          }
        />
        <Route
          exact
          path="/commerce/ResearchandPublication"
          element={
            <RouteWithNavArtsCommerce
              Element={ArtsCommerceResearchandPublication}
            />
          }
        />
        <Route
          exact
          path="/commerce/Recruitment"
          element={
            <RouteWithNavArtsCommerce Element={ArtsCommerceRecruitment} />
          }
        />
        <Route
          exact
          path="/commerce/StudentList"
          element={<RouteWithNavArtsCommerce Element={StudentsListCommerce} />}
        />
        <Route
          exact
          path="/commerce/resultsList"
          element={
            <RouteWithNavArtsCommerce Element={StudentsResultsCommerce} />
          }
        />
        <Route
          exact
          path="/commerce/ContactUs"
          element={<RouteWithNavArtsCommerce Element={ContactUsCommerce} />}
        />
        {/* IT CS  */}
        <Route
          exact
          path="/it-cs/*"
          element={<RouteWithNavItCs Element={NotFoundPage} />}
        />
        <Route
          exact
          path="/it-cs"
          element={<RouteWithNavItCs Element={ItCsHome} />}
        />
        <Route
          exact
          path="/it-cs/Recognition"
          element={<RouteWithNavItCs Element={RecognitionITCS} />}
        />
        <Route
          exact
          path="/it-cs/AboutUs"
          element={<RouteWithNavItCs Element={ItCsAboutUs} />}
        />
        <Route
          exact
          path="/it-cs/AboutCollege"
          element={<RouteWithNavItCs Element={ItCsAboutCollege} />}
        />
        <Route
          exact
          path="/it-cs/MissionVision"
          element={<RouteWithNavItCs Element={ItCsMissionVision} />}
        />
        <Route
          exact
          path="/it-cs/PrincipalMessage"
          element={<RouteWithNavItCs Element={ItCsPrincipalMessage} />}
        />
        <Route
          exact
          path="/it-cs/events"
          element={<RouteWithNavItCs Element={EventsITCS} />}
        />
        <Route
          exact
          path="/it-cs/eventsDetails/:id"
          element={<RouteWithNavItCs Element={EventsDetails} />}
        />
        <Route
          exact
          path="/it-cs/Achievements-Awards-Medals"
          element={<RouteWithNavItCs Element={AchievementsITCS} />}
        />
        <Route
          exact
          path="/it-cs/Details/:id"
          element={<RouteWithNavItCs Element={AchievementsDetails} />}
        />
        <Route
          exact
          path="/it-cs/Permission"
          element={<RouteWithNavItCs Element={ITCSClgPermission} />}
        />
        <Route
          exact
          path="/it-cs/Affiliation"
          element={<RouteWithNavItCs Element={ITCSClgAffiliation} />}
        />
        <Route
          exact
          path="/it-cs/Courses"
          element={<RouteWithNavItCs Element={ItCsCourses} />}
        />
        <Route
          exact
          path="/it-cs/Eligibility"
          element={<RouteWithNavItCs Element={ItCsEligibility} />}
        />
        <Route
          exact
          path="/it-cs/FeeStructure"
          element={<RouteWithNavItCs Element={ItCsFeeStructure} />}
        />
        <Route
          exact
          path="/it-cs/AcademicCalender"
          element={<RouteWithNavItCs Element={ItCsAcademicCalender} />}
        />
        <Route
          exact
          path="/it-cs/TeachingStaff"
          element={<RouteWithNavItCs Element={ItCsTeachingStaff} />}
        />
        <Route
          exact
          path="/it-cs/non-teachingStaff"
          element={<RouteWithNavItCs Element={NonTeachingStaffITCS} />}
        />
        <Route
          exact
          path="/it-cs/StudentList"
          element={<RouteWithNavItCs Element={StudentsListITCS} />}
        />
        <Route
          exact
          path="/it-cs/resultsList"
          element={<RouteWithNavItCs Element={StudentsResultsITCS} />}
        />
        <Route
          exact
          path="/it-cs/FoundersMessage"
          element={<RouteWithNavItCs Element={ItCsFoundersMessage} />}
        />
        <Route
          exact
          path="/it-cs/ITCSPublications"
          element={<RouteWithNavItCs Element={ITCSPublications} />}
        />
        <Route
          exact
          path="/it-cs/Syllabus"
          element={<RouteWithNavItCs Element={ITCSSyllabus} />}
        />
        <Route
          exact
          path="/it-cs/Syllabus/:department"
          element={<RouteWithNavItCs Element={ITCSSyllabusSem} />}
        />
        <Route
          exact
          path="/it-cs/Syllabus/:year/:semester"
          element={<RouteWithNavItCs Element={ITCSSyllabusDocs} />}
        />
        <Route
          exact
          path="/it-cs/Recruitment"
          element={<RouteWithNavItCs Element={ITCSRecruitment} />}
        />
        <Route
          exact
          path="/it-cs/President"
          element={<RouteWithNavItCs Element={PresidentMsg} />}
        />
        <Route
          exact
          path="/it-cs/Provost"
          element={<RouteWithNavItCs Element={Provost} />}
        />
        <Route
          exact
          path="/it-cs/VicePresidentMsg"
          element={<RouteWithNavItCs Element={VicePresidentsITCS} />}
        />
        <Route
          exact
          path="/it-cs/Vice-President"
          element={<RouteWithNavItCs Element={VicePresidentITCS} />}
        />
        <Route
          exact
          path="/it-cs/VicePresident"
          element={<RouteWithNavItCs Element={VicePresident2ITCS} />}
        />
        <Route
          exact
          path="/it-cs/vice-President-Profile"
          element={<RouteWithNavItCs Element={VicePresident3ITCS} />}
        />
        <Route
          exact
          path="/it-cs/Registrar-Profile"
          element={<RouteWithNavItCs Element={Registrar} />}
        />
        <Route
          exact
          path="/it-cs/ContactUs"
          element={<RouteWithNavItCs Element={ContactUsITCS} />}
        />
        {/* Law  */}
        <Route
          exact
          path="/law/*"
          element={<RouteWithNavLaw Element={NotFoundPage} />}
        />
        <Route
          exact
          path="/law"
          element={<RouteWithNavLaw Element={LawHome} />}
        />
        <Route
          exact
          path="/law/AboutCollege"
          element={<RouteWithNavLaw Element={LawAboutCollege} />}
        />
        <Route
          exact
          path="/law/AcademicCalendar"
          element={<RouteWithNavLaw Element={AcademicCalendarLaw} />}
        />
        <Route
          exact
          path="/law/AboutUs"
          element={<RouteWithNavLaw Element={LawAboutUs} />}
        />
        <Route
          exact
          path="/law/FoundersMessage"
          element={<RouteWithNavLaw Element={LawFoundersMessage} />}
        />
        <Route
          exact
          path="/law/MissionVision"
          element={<RouteWithNavLaw Element={LawMissionVision} />}
        />
        <Route
          exact
          path="/law/PrincipalMessage"
          element={<RouteWithNavLaw Element={LawPrincipalMessage} />}
        />
        <Route
          exact
          path="/law/events"
          element={<RouteWithNavLaw Element={EventsLaw} />}
        />
        <Route
          exact
          path="/law/eventsDetails/:id"
          element={<RouteWithNavLaw Element={EventsDetails} />}
        />
        <Route
          exact
          path="/law/Achievements-Awards-Medals"
          element={<RouteWithNavLaw Element={AchievementsLaw} />}
        />
        <Route
          exact
          path="/law/Details/:id"
          element={<RouteWithNavLaw Element={AchievementsDetails} />}
        />
        <Route
          exact
          path="/law/Courses"
          element={<RouteWithNavLaw Element={LawCourses} />}
        />
        <Route
          exact
          path="/law/Eligibility"
          element={<RouteWithNavLaw Element={LawEligibility} />}
        />
        <Route
          exact
          path="/law/FeeStructure"
          element={<RouteWithNavLaw Element={LawFeeStructure} />}
        />
        <Route
          exact
          path="/law/AcademicEvents"
          element={<RouteWithNavLaw Element={LawEvents} />}
        />
        <Route
          exact
          path="/law/Permission"
          element={<RouteWithNavLaw Element={LawClgPermission} />}
        />
        <Route
          exact
          path="/law/Affiliation"
          element={<RouteWithNavLaw Element={LawClgAffiliation} />}
        />
        <Route
          exact
          path="/law/Publications"
          element={<RouteWithNavLaw Element={Publications} />}
        />
        <Route
          exact
          path="/law/TeachingStaff"
          element={<RouteWithNavLaw Element={LawTeachingStaff} />}
        />
        <Route
          exact
          path="/law/non-teachingStaff"
          element={<RouteWithNavLaw Element={NonTeachingStaffLaw} />}
        />
        <Route
          exact
          path="/law/StudentList"
          element={<RouteWithNavLaw Element={StudentsListLaw} />}
        />
        <Route
          exact
          path="/law/resultsList"
          element={<RouteWithNavLaw Element={StudentsResultsLaw} />}
        />
        <Route
          exact
          path="/law/Achievements"
          element={<RouteWithNavLaw Element={LawAchievements} />}
        />
        <Route
          exact
          path="/law/Syllabus"
          element={<RouteWithNavLaw Element={LawSyllabus} />}
        />
        <Route
          exact
          path="/law/Syllabus/:department"
          element={<RouteWithNavLaw Element={LawSyllabusSem} />}
        />
        <Route
          exact
          path="/law/Syllabus/:year/:semester"
          element={<RouteWithNavLaw Element={LawSyllabusDocs} />}
        />
        <Route
          exact
          path="/law/Recruitment"
          element={<RouteWithNavLaw Element={LawRecruitment} />}
        />
        <Route
          exact
          path="/law/President"
          element={<RouteWithNavLaw Element={PresidentMsg} />}
        />
        <Route
          exact
          path="/law/Provost"
          element={<RouteWithNavLaw Element={Provost} />}
        />
        <Route
          exact
          path="/law/VicePresidentMsg"
          element={<RouteWithNavLaw Element={VicePresidentsLaw} />}
        />
        <Route
          exact
          path="/law/Vice-President"
          element={<RouteWithNavLaw Element={VicePresidentLaw} />}
        />
        <Route
          exact
          path="/law/VicePresident"
          element={<RouteWithNavLaw Element={VicePresident2Law} />}
        />
        <Route
          exact
          path="/law/vice-President-Profile"
          element={<RouteWithNavLaw Element={VicePresident3Law} />}
        />
        <Route
          exact
          path="/law/Registrar-Profile"
          element={<RouteWithNavLaw Element={Registrar} />}
        />
        <Route
          exact
          path="/law/ContactUs"
          element={<RouteWithNavLaw Element={ContactUsLaw} />}
        />
        {/* Nursing  */}
        <Route
          exact
          path="/nursing/*"
          element={<RouteWithNavNursing Element={NotFoundPage} />}
        />
        <Route
          exact
          path="/nursing/Hospital-Staff-Attendance"
          element={<RouteWithNavNursing Element={HospitalStaffAttendanceNursing} />}
        />
        <Route
          exact
          path="/nursing/AcademicCalendar"
          element={<RouteWithNavNursing Element={AcademicCalendarNursing} />}
        />
        <Route
          exact
          path="/nursing"
          element={<RouteWithNavNursing Element={NursingHome} />}
        />
        <Route
          exact
          path="/nursing/AboutUs"
          element={<RouteWithNavNursing Element={NursingAboutUs} />}
        />
        <Route
          exact
          path="/nursing/AboutCollege"
          element={<RouteWithNavNursing Element={NursingAboutCollege} />}
        />
        <Route
          exact
          path="/nursing/FoundersMessage"
          element={<RouteWithNavNursing Element={NursingFoundersMessage} />}
        />
        <Route
          exact
          path="/nursing/PrincipalMessage"
          element={<RouteWithNavNursing Element={NursingPrincipalMessage} />}
        />
        <Route
          exact
          path="/nursing/MissionVision"
          element={<RouteWithNavNursing Element={NursingMissionVision} />}
        />
        <Route
          exact
          path="/nursing/RulesandRegulations"
          element={<RouteWithNavNursing Element={RulesNursing} />}
        />
        <Route
          exact
          path="/nursing/Eligibility"
          element={<RouteWithNavNursing Element={NursingEligibility} />}
        />
        <Route
          exact
          path="/nursing/FeeStructure"
          element={<RouteWithNavNursing Element={NursingFeeStructure} />}
        />
        <Route
          exact
          path="/nursing/Permission"
          element={<RouteWithNavNursing Element={NursingClgPermission} />}
        />
        <Route
          exact
          path="/nursing/Affiliation"
          element={<RouteWithNavNursing Element={NursingClgAffiliation} />}
        />
        <Route
          exact
          path="/nursing/TeachingStaff"
          element={<RouteWithNavNursing Element={NursingTeachingStaff} />}
        />
        <Route
          exact
          path="/nursing/non-teachingStaff"
          element={<RouteWithNavNursing Element={NonTeachingStaffNursing} />}
        />
        <Route
          exact
          path="/nursing/StudentList"
          element={<RouteWithNavNursing Element={StudentsListNursing} />}
        />
        <Route
          exact
          path="/nursing/resultsList"
          element={<RouteWithNavNursing Element={StudentsResultsNursing} />}
        />
        <Route
          exact
          path="/nursing/Placements"
          element={<RouteWithNavNursing Element={NursingPlacementDetails} />}
        />
        <Route
          exact
          path="/nursing/Syllabus"
          element={<RouteWithNavNursing Element={NursingSyllabus} />}
        />
        <Route
          exact
          path="/nursing/Syllabus/:department"
          element={<RouteWithNavNursing Element={NursingSyllabusSem} />}
        />
        <Route
          exact
          path="/nursing/Syllabus/:year/:semester"
          element={<RouteWithNavNursing Element={NursingSyllabusDocs} />}
        />
        <Route
          exact
          path="/nursing/events"
          element={<RouteWithNavNursing Element={NursingEvents} />}
        />
        <Route
          exact
          path="/nursing/eventsDetails/:id"
          element={<RouteWithNavNursing Element={EventsDetails} />}
        />
        <Route
          exact
          path="/nursing/Achievements-Awards-Medals"
          element={<RouteWithNavNursing Element={AchievementsNursing} />}
        />
        <Route
          exact
          path="/nursing/Details/:id"
          element={<RouteWithNavNursing Element={AchievementsDetails} />}
        />
        <Route
          exact
          path="/nursing/NursingResearchandPublication"
          element={
            <RouteWithNavNursing Element={NursingResearchandPublication} />
          }
        />
        <Route
          exact
          path="/nursing/Recruitment"
          element={<RouteWithNavNursing Element={NursingRecruitment} />}
        />
        <Route
          exact
          path="/nursing/President"
          element={<RouteWithNavNursing Element={PresidentMsg} />}
        />
        <Route
          exact
          path="/nursing/Provost"
          element={<RouteWithNavNursing Element={Provost} />}
        />
        <Route
          exact
          path="/nursing/VicePresidentMsg"
          element={<RouteWithNavNursing Element={VicePresidentsNursing} />}
        />
        <Route
          exact
          path="/nursing/Vice-President"
          element={<RouteWithNavNursing Element={VicePresidentNursing} />}
        />
        <Route
          exact
          path="/nursing/VicePresident"
          element={<RouteWithNavNursing Element={VicePresident2Nursing} />}
        />
        <Route
          exact
          path="/nursing/vice-President-Profile"
          element={<RouteWithNavNursing Element={VicePresident3Nursing} />}
        />
        <Route
          exact
          path="/nursing/Registrar-Profile"
          element={<RouteWithNavNursing Element={Registrar} />}
        />
        <Route
          exact
          path="/nursing/ContactUs"
          element={<RouteWithNavNursing Element={ContactUsAyurveda} />}
        />
        {/* EDUCATION */}
        <Route
          exact
          path="/education/*"
          element={<RouteWithNavEducation Element={NotFoundPage} />}
        />
        <Route
          exact
          path="/education"
          element={<RouteWithNavEducation Element={EducationHome} />}
        />
        <Route
          exact
          path="/education/AcademicCalender"
          element={<RouteWithNavEducation Element={AcademicCalenderEducation} />}
        />
        <Route
          exact
          path="/education/AboutUs"
          element={<RouteWithNavEducation Element={EducationAboutUs} />}
        />
        <Route
          exact
          path="/education/AboutCollege"
          element={<RouteWithNavEducation Element={EducationAboutCollege} />}
        />
        <Route
          exact
          path="/education/FoundersMessage"
          element={<RouteWithNavEducation Element={EducationFoundersMessage} />}
        />
        <Route
          exact
          path="/education/MissionVision"
          element={<RouteWithNavEducation Element={EducationMissionVision} />}
        />
        <Route
          exact
          path="/education/PrincipalMessage"
          element={
            <RouteWithNavEducation Element={EducationPrincipalMessage} />
          }
        />
        <Route
          exact
          path="/education/Permission"
          element={<RouteWithNavEducation Element={EducationClgPermission} />}
        />
        <Route
          exact
          path="/education/Affiliation"
          element={<RouteWithNavEducation Element={EducationClgAffiliation} />}
        />
        <Route
          exact
          path="/education/TeachingStaff"
          element={<RouteWithNavEducation Element={EducationTeachingStaff} />}
        />
        <Route
          exact
          path="/education/non-teachingStaff"
          element={
            <RouteWithNavEducation Element={NonTeachingStaffEducation} />
          }
        />
        <Route
          exact
          path="/education/StudentList"
          element={<RouteWithNavEducation Element={StudentsListEducation} />}
        />
        <Route
          exact
          path="/education/resultsList"
          element={<RouteWithNavEducation Element={StudentsResultsEducation} />}
        />
        <Route
          exact
          path="/education/Courses"
          element={<RouteWithNavEducation Element={EducationCourses} />}
        />
        <Route
          exact
          path="/education/FeeStructure"
          element={<RouteWithNavEducation Element={EducationFeeStructure} />}
        />
        <Route
          exact
          path="/education/ResearchActivity"
          element={
            <RouteWithNavEducation Element={EducationResearchActivity} />
          }
        />
        <Route
          exact
          path="/education/Workshop"
          element={<RouteWithNavEducation Element={EducationWorkshop} />}
        />
        <Route
          exact
          path="/education/Placements"
          element={<RouteWithNavEducation Element={EducationPlacements} />}
        />
        <Route
          exact
          path="/education/EducationPublications"
          element={<RouteWithNavEducation Element={EducationPublications} />}
        />
        <Route
          exact
          path="/education/Syllabus"
          element={<RouteWithNavEducation Element={SyllabusEducation} />}
        />
        <Route
          exact
          path="/education/Syllabus/:department"
          element={<RouteWithNavEducation Element={EducationSyllabusSem} />}
        />
        <Route
          exact
          path="/education/Syllabus/:year/:semester"
          element={<RouteWithNavEducation Element={SyllabusDocsEducation} />}
        />
        <Route
          exact
          path="/education/Eligibility"
          element={<RouteWithNavEducation Element={EducationEligibility} />}
        />
        <Route
          exact
          path="/education/events"
          element={<RouteWithNavEducation Element={EducationEvents} />}
        />
        <Route
          exact
          path="/education/eventsDetails/:id"
          element={<RouteWithNavEducation Element={EventsDetails} />}
        />
        <Route
          exact
          path="/education/Achievements-Awards-Medals"
          element={<RouteWithNavEducation Element={AchievementsEducation} />}
        />
        <Route
          exact
          path="/education/Details/:id"
          element={<RouteWithNavEducation Element={AchievementsDetails} />}
        />
        <Route
          exact
          path="/education/Syllabus"
          element={<RouteWithNavEducation Element={EducationSyllabus} />}
        />
        <Route
          exact
          path="/education/Syllabus/:department"
          element={<RouteWithNavEducation Element={EducationSyllabusSem} />}
        />
        <Route
          exact
          path="/education/Syllabus/:year/:semester"
          element={<RouteWithNavEducation Element={EducationSyllabusDocs} />}
        />
        <Route
          exact
          path="/education/Recruitment"
          element={<RouteWithNavEducation Element={EducationRecruitment} />}
        />
        <Route
          exact
          path="/education/President"
          element={<RouteWithNavEducation Element={PresidentMsg} />}
        />
        <Route
          exact
          path="/education/Provost"
          element={<RouteWithNavEducation Element={Provost} />}
        />
        <Route
          exact
          path="/education/VicePresidentMsg"
          element={<RouteWithNavEducation Element={VicePresidentsEducation} />}
        />
        <Route
          exact
          path="/education/Vice-President"
          element={<RouteWithNavEducation Element={VicePresidentEducation} />}
        />
        <Route
          exact
          path="/education/VicePresident"
          element={<RouteWithNavEducation Element={VicePresident2Education} />}
        />
        <Route
          exact
          path="/education/vice-President-Profile"
          element={<RouteWithNavEducation Element={VicePresident3Education} />}
        />
        <Route
          exact
          path="/education/Registrar-Profile"
          element={<RouteWithNavEducation Element={Registrar} />}
        />
        <Route
          exact
          path="/education/ContactUs"
          element={<RouteWithNavEducation Element={ContactUsEducation} />}
        />
        {/* Ayurveda */}
        <Route
          exact
          path="/ayurveda/*"
          element={<RouteWithNavAyurveda Element={NotFoundPage} />}
        />
        <Route
          exact
          path="/ayurveda"
          element={<RouteWithNavAyurveda Element={AyurvedaHome} />}
        />
        <Route
          exact
          path="/ayurveda/Infrastructure"
          element={<RouteWithNavAyurveda Element={InfrastructureAyurveda} />}
        />
        <Route
          exact
          path="/ayurveda/AboutCollege"
          element={<RouteWithNavAyurveda Element={AyurvedaAboutCollege} />}
        />
        <Route
          exact
          path="/ayurveda/AboutUs"
          element={<RouteWithNavAyurveda Element={AyurvedaAboutUs} />}
        />
        <Route
          exact
          path="/ayurveda/FoundersMessage"
          element={<RouteWithNavAyurveda Element={AyurvedaFoundersMessage} />}
        />
        <Route
          exact
          path="/ayurveda/PrincipalMessage"
          element={<RouteWithNavAyurveda Element={AyurvedaPrincipalMessage} />}
        />
        <Route
          exact
          path="/ayurveda/MissionVision"
          element={<RouteWithNavAyurveda Element={AyurvedaMissionVision} />}
        />
        <Route
          exact
          path="/ayurveda/AcademicCalendar"
          element={<RouteWithNavAyurveda Element={AcademicCalendar} />}
        />
        <Route
          exact
          path="/ayurveda/Gallery"
          element={<RouteWithNavAyurveda Element={PhotoGalleryAyurveda} />}
        />
        <Route
          exact
          path="/ayurveda/Gallery-Photos/:id"
          element={<RouteWithNavAyurveda Element={EventsDetails} />}
        />
        <Route
          exact
          path="/ayurveda/Permission"
          element={<RouteWithNavAyurveda Element={AyuClgPermission} />}
        />
        <Route
          exact
          path="/ayurveda/Affiliation"
          element={<RouteWithNavAyurveda Element={AyuClgAffiliation} />}
        />
        <Route
          exact
          path="/ayurveda/TeachingStaff"
          element={<RouteWithNavAyurveda Element={AyurvedaTeachingStaff} />}
        />
        <Route
          exact
          path="/ayurveda/non-teachingStaff"
          element={<RouteWithNavAyurveda Element={NonTeachingStaffAyurveda} />}
        />
        <Route
          exact
          path="/ayurveda/CollegeCommittee"
          element={<RouteWithNavAyurveda Element={AyurvedaCollegeCommittee} />}
        />
        <Route
          exact
          path="/ayurveda/ResearchPublications"
          element={
            <RouteWithNavAyurveda Element={AyurvedaResearchPublications} />
          }
        />
        <Route
          exact
          path="/ayurveda/Admission_and_Eligibility"
          element={<RouteWithNavAyurveda Element={AyurvedaEligibility} />}
        />
        <Route
          exact
          path="/ayurveda/FeeStructure"
          element={<RouteWithNavAyurveda Element={AyurvedaFeeStructure} />}
        />
        <Route
          exact
          path="/ayurveda/Syllabus"
          element={<RouteWithNavAyurveda Element={AyurvedaSyllabus} />}
        />
        <Route
          exact
          path="/ayurveda/Syllabus/:department"
          element={<RouteWithNavAyurveda Element={AyurvedaSyllabusSem} />}
        />
        <Route
          exact
          path="/ayurveda/Syllabus/:year/:semester"
          element={<RouteWithNavAyurveda Element={AyurvedaSyllabusDocs} />}
        />
        <Route
          exact
          path="/ayurveda/RulesandRegulations"
          element={<RouteWithNavAyurveda Element={RulesAyu} />}
        />
        <Route
          exact
          path="/ayurveda/Labs"
          element={<RouteWithNavAyurveda Element={AyurvedaLabs} />}
        />
        <Route
          exact
          path="/ayurveda/Museums"
          element={<RouteWithNavAyurveda Element={AyurvedaMuseums} />}
        />
        <Route
          exact
          path="/ayurveda/ExtraFacilities"
          element={<RouteWithNavAyurveda Element={AyurvedaExtraFacilities} />}
        />
        <Route
          exact
          path="/ayurveda/HospitalAdministration"
          element={
            <RouteWithNavAyurveda Element={AyurvedaHospitalAdministration} />
          }
        />
        <Route
          exact
          path="/ayurveda/HospitalStaff"
          element={<RouteWithNavAyurveda Element={AyurvedaHospitalStaff} />}
        />
        <Route
          exact
          path="/ayurveda/Hospital-Staff-Attendance"
          element={<RouteWithNavAyurveda Element={HospitalStaffAttendanceAyurveda} />}
        />
        <Route
          exact
          path="/ayurveda/ClinicalDepartments"
          element={
            <RouteWithNavAyurveda Element={AyurvedaClinicalDepartments} />
          }
        />
        <Route
          exact
          path="/ayurveda/events"
          element={<RouteWithNavAyurveda Element={AyurvedaEvents} />}
        />
        <Route
          exact
          path="/ayurveda/eventsDetails/:id"
          element={<RouteWithNavAyurveda Element={EventsDetails} />}
        />
        <Route
          exact
          path="/ayurveda/Achievements-Awards-Medals"
          element={<RouteWithNavAyurveda Element={AchievementsAyu} />}
        />
        <Route
          exact
          path="/ayurveda/Details/:id"
          element={<RouteWithNavAyurveda Element={AchievementsDetails} />}
        />
        <Route
          exact
          path="/ayurveda/resultsList"
          element={<RouteWithNavAyurveda Element={AyurvedaResults} />}
        />
        <Route
          exact
          path="/ayurveda/StudentList"
          element={<RouteWithNavAyurveda Element={StudentsListAyurveda} />}
        />
        <Route
          exact
          path="/ayurveda/Recruitment"
          element={<RouteWithNavAyurveda Element={AyurvedaRecruitment} />}
        />
        <Route
          exact
          path="/ayurveda/Admissions"
          element={<RouteWithNavAyurveda Element={Admission} />}
        />
        <Route
          exact
          path="/ayurveda/President"
          element={<RouteWithNavAyurveda Element={PresidentMsg} />}
        />
        <Route
          exact
          path="/ayurveda/Provost"
          element={<RouteWithNavAyurveda Element={Provost} />}
        />
        <Route
          exact
          path="/ayurveda/VicePresidentMsg"
          element={<RouteWithNavAyurveda Element={VicePresidentsAyu} />}
        />
        <Route
          exact
          path="/ayurveda/Vice-President"
          element={<RouteWithNavAyurveda Element={VicePresidentAyu} />}
        />
        <Route
          exact
          path="/ayurveda/VicePresident"
          element={<RouteWithNavAyurveda Element={VicePresident2Ayu} />}
        />
        <Route
          exact
          path="/ayurveda/vice-President-Profile"
          element={<RouteWithNavAyurveda Element={VicePresident3Ayu} />}
        />
        <Route
          exact
          path="/ayurveda/Registrar-Profile"
          element={<RouteWithNavAyurveda Element={Registrar} />}
        />
        <Route
          exact
          path="/ayurveda/ContactUs"
          element={<RouteWithNavAyurveda Element={ContactUsAyurveda} />}
        />
        ////////Engineering/////////////
        <Route
          exact
          path="/engineering/events"
          element={<RouteWithNavEngineering Element={EngineeringEvents} />}
        />
        <Route
          exact
          path="/engineering/eventsDetails/:id"
          element={<RouteWithNavEngineering Element={EventsDetails} />}
        />
        <Route
          exact
          path="/engineering/Achievements-Awards-Medals"
          element={<RouteWithNavEngineering Element={AchievementsEng} />}
        />
        <Route
          exact
          path="/engineering/Details/:id"
          element={<RouteWithNavEngineering Element={AchievementsDetails} />}
        />
        <Route
          exact
          path="/engineering/ResearchandPublicationEng"
          element={
            <RouteWithNavEngineering Element={ReseachandPublicationEng} />
          }
        />
        
        <Route
          exact
          path="/engineering/ResearchDetails/:id"
          element={<RouteWithNavEngineering Element={ResearchDetails} />}
        />
        <Route
          exact
          path="/engineering/RulesandRegulations"
          element={<RouteWithNavEngineering Element={RulesEng} />}
        />
        <Route
          exact
          path="/engineering/StudentList"
          element={<RouteWithNavEngineering Element={StudentsListEng} />}
        />
        <Route
          exact
          path="/engineering/resultsList"
          element={<RouteWithNavEngineering Element={EngResults} />}
        />
        <Route
          exact
          path="/ayurveda/ClinicalMaterial"
          element={<RouteWithNavAyurveda Element={AyurvedaClinicalMaterial} />}
        />
        {/* <Route
          exact
          path="/ayurveda/ClinicalMaterial"
          element={<RouteWithNavAyurveda Element={AyurvedaClinicalMaterial} />}
        /> */}
        <Route
          exact
          path="/ayurveda/OpdServices"
          element={<RouteWithNavAyurveda Element={AyurvedaOpdServices} />}
        />
        <Route
          exact
          path="/ayurveda/DedicatedCovidHealthCareCentre"
          element={
            <RouteWithNavAyurveda
              Element={AyurvedaDedicatedCovidHealthCareCentre}
            />
          }
        />
        <Route
          exact
          path="/ayurveda/IpdServices"
          element={<RouteWithNavAyurveda Element={AyurvedaIpdServices} />}
        />
        <Route
          exact
          path="/ayurveda/DiagnosticServices"
          element={
            <RouteWithNavAyurveda Element={AyurvedaDiagnosticServices} />
          }
        />
        <Route
          exact
          path="/ayurveda/Therapies"
          element={<RouteWithNavAyurveda Element={AyurvedaTherapies} />}
        />
        <Route
          exact
          path="/ayurveda/StudentList"
          element={<RouteWithNavAyurveda Element={AyurvedaStudentList} />}
        />

      /////////////Physiotherapy/////////////
        <Route
          exact
          path="/Physiotherapy/*"
          element={<RouteWithNavPhysiotherapy Element={NotFoundPage} />}
        />
        <Route
          exact
          path="/Physiotherapy"
          element={<RouteWithNavPhysiotherapy Element={HomePhysio} />}
        />
        <Route
          exact
          path="/Physiotherapy/AboutCollege"
          element={<RouteWithNavPhysiotherapy Element={AboutCollegePhysio} />}
        />
        <Route
          exact
          path="/Physiotherapy/PrincipalMessage"
          element={<RouteWithNavPhysiotherapy Element={PrincipalMessagePhysiotherapy} />}
        />
        <Route
          exact
          path="/Physiotherapy/Admissions"
          element={<RouteWithNavPhysiotherapy Element={AdmissionsPhysiotherapy} />}
        />
        <Route
          exact
          path="/Physiotherapy/Infrastructure"
          element={<RouteWithNavPhysiotherapy Element={InfrastructurePhysiotherapy} />}
        />
        <Route
          exact
          path="/Physiotherapy/AboutUs"
          element={<RouteWithNavPhysiotherapy Element={About_usPhysio} />}
        />
        <Route
          exact
          path="/Physiotherapy/FoundersMessage"
          element={<RouteWithNavPhysiotherapy Element={AyurvedaFoundersMessage} />}
        />
        <Route
          exact
          path="/Physiotherapy/MissionVision"
          element={<RouteWithNavPhysiotherapy Element={Mission_VisionPhysio} />}
        />
        <Route
          exact
          path="/Physiotherapy/President"
          element={<RouteWithNavPhysiotherapy Element={PresidentMsg} />}
        />
        <Route
          exact
          path="/Physiotherapy/Provost"
          element={<RouteWithNavPhysiotherapy Element={Provost} />}
        />
        <Route
          exact
          path="/Physiotherapy/VicePresidentMsg"
          element={<RouteWithNavPhysiotherapy Element={VicePresidentsPhysio} />}
        />
        <Route
          exact
          path="/Physiotherapy/Vice-President"
          element={<RouteWithNavPhysiotherapy Element={VicePresidentPhysio} />}
        />
        <Route
          exact
          path="/Physiotherapy/VicePresident"
          element={<RouteWithNavPhysiotherapy Element={VicePresident2Physio} />}
        />
        <Route
          exact
          path="/Physiotherapy/vice-President-Profile"
          element={<RouteWithNavPhysiotherapy Element={VicePresident3Physio} />}
        />
        <Route
          exact
          path="/Physiotherapy/Registrar-Profile"
          element={<RouteWithNavPhysiotherapy Element={Registrar} />}
        />
        <Route
          exact
          path="/Physiotherapy/ContactUs"
          element={<RouteWithNavPhysiotherapy Element={ContactUsPhysio} />}
        />
        <Route
          exact
          path="/Physiotherapy/Recruitment"
          element={<RouteWithNavPhysiotherapy Element={RecruitmentPhysio} />}
        />
        <Route
          exact
          path="/Physiotherapy/ResearchandPublication"
          element={<RouteWithNavPhysiotherapy Element={ResearchandPublicationPhysio} />}
        />
        <Route
          exact
          path="/Physiotherapy/Permission"
          element={<RouteWithNavPhysiotherapy Element={PermissionPhysio} />}
        />
        <Route
          exact
          path="/Physiotherapy/Affiliation"
          element={<RouteWithNavPhysiotherapy Element={AffiliationPhysio} />}
        />
        <Route
          exact
          path="/Physiotherapy/TeachingStaff"
          element={<RouteWithNavPhysiotherapy Element={TeachingStaffPhysio} />}
        />
        <Route
          exact
          path="/Physiotherapy/non-teachingStaff"
          element={<RouteWithNavPhysiotherapy Element={NonTeachingStaffPhysio} />}
        />
        <Route
          exact
          path="/Physiotherapy/studentList"
          element={<RouteWithNavPhysiotherapy Element={StudentsListPhysio} />}
        />
        <Route
          exact
          path="/Physiotherapy/resultsList"
          element={<RouteWithNavPhysiotherapy Element={StudentResults} />}
        />
        <Route
          exact
          path="/Physiotherapy/Syllabus"
          element={<RouteWithNavPhysiotherapy Element={SyllabusPhysio} />}
        />
        <Route
          exact
          path="/Physiotherapy/Syllabus/:department"
          element={<RouteWithNavPhysiotherapy Element={SyllabusSemPhysio} />}
        />
        <Route
          exact
          path="/Physiotherapy/Syllabus/:year/:semester"
          element={<RouteWithNavPhysiotherapy Element={PhysioSyllabusDocs} />}
        />
        <Route
          exact
          path="/Physiotherapy/events"
          element={<RouteWithNavPhysiotherapy Element={EventsPhysio} />}
        />
        <Route
          exact
          path="/Physiotherapy/eventsDetails/:id"
          element={<RouteWithNavPhysiotherapy Element={EventsDetails} />}
        />
        <Route
          exact
          path="/Physiotherapy/Achievements-Awards-Medals"
          element={<RouteWithNavPhysiotherapy Element={AchievementsPhysio} />}
        />
        <Route
          exact
          path="/Physiotherapy/Details/:id"
          element={<RouteWithNavPhysiotherapy Element={AchievementsDetails} />}
        />
        <Route
          exact
          path="/Physiotherapy/AcademicCalendar"
          element={<RouteWithNavPhysiotherapy Element={AcademicCalenderPhysio} />}
        />
        <Route
          exact
          path="/Physiotherapy/Transportation"
          element={<RouteWithNavPhysiotherapy Element={Transportation} />}
        />
        <Route
          exact
          path="/Physiotherapy/Library"
          element={<RouteWithNavPhysiotherapy Element={LibraryPhysio} />}
        />

      /////////////Design/////////////
        <Route
          exact
          path="/Design/*"
          element={<RouteWithNavDesign Element={NotFoundPage} />}
        />
        <Route
          exact
          path="/Design"
          element={<RouteWithNavDesign Element={HomeDesign} />}
        />
        <Route
          exact
          path="/Design/AboutCollege"
          element={<RouteWithNavDesign Element={AboutCollegeDesign} />}
        />
        <Route
          exact
          path="/Design/PrincipalMessage"
          element={<RouteWithNavDesign Element={PrincipalsMessageDesign} />}
        />
        <Route
          exact
          path="/Design/Admissions"
          element={<RouteWithNavDesign Element={AdmissionsDesign} />}
        />
        <Route
          exact
          path="/Design/Infrastructure"
          element={<RouteWithNavDesign Element={InfrastructureDesign} />}
        />
        <Route
          exact
          path="/Design/AboutUs"
          element={<RouteWithNavDesign Element={AboutUsDesign} />}
        />
        <Route
          exact
          path="/Design/FoundersMessage"
          element={<RouteWithNavDesign Element={AyurvedaFoundersMessage} />}
        />
        <Route
          exact
          path="/Design/MissionVision"
          element={<RouteWithNavDesign Element={MissionVisionDesign} />}
        />
        <Route
          exact
          path="/Design/President"
          element={<RouteWithNavDesign Element={PresidentMsg} />}
        />
        <Route
          exact
          path="/Design/Provost"
          element={<RouteWithNavDesign Element={Provost} />}
        />
        <Route
          exact
          path="/Design/VicePresidentMsg"
          element={<RouteWithNavDesign Element={VicePresidentsDesign} />}
        />
        <Route
          exact
          path="/Design/Vice-President"
          element={<RouteWithNavDesign Element={VicePresidentDesign} />}
        />
        <Route
          exact
          path="/Design/VicePresident"
          element={<RouteWithNavDesign Element={VicePresident2Design} />}
        />
        <Route
          exact
          path="/Design/vice-President-Profile"
          element={<RouteWithNavDesign Element={VicePresident3Design} />}
        />
        <Route
          exact
          path="/Design/Registrar-Profile"
          element={<RouteWithNavDesign Element={Registrar} />}
        />
        <Route
          exact
          path="/Design/ContactUs"
          element={<RouteWithNavDesign Element={ContactUsDesign} />}
        />
        <Route
          exact
          path="/Design/Recruitment"
          element={<RouteWithNavDesign Element={RecruitmentDesign} />}
        />
        <Route
          exact
          path="/Design/ResearchandPublication"
          element={<RouteWithNavDesign Element={ResearchandPublicationDesign} />}
        />
        <Route
          exact
          path="/Design/Permission"
          element={<RouteWithNavDesign Element={PermissionDesign} />}
        />
        <Route
          exact
          path="/Design/Affiliation"
          element={<RouteWithNavDesign Element={AffiliationDesign} />}
        />
        <Route
          exact
          path="/Design/TeachingStaff"
          element={<RouteWithNavDesign Element={TeachingStaffDesign} />}
        />
        <Route
          exact
          path="/Design/non-teachingStaff"
          element={<RouteWithNavDesign Element={NonTeachingStaffDesign} />}
        />
        <Route
          exact
          path="/Design/studentList"
          element={<RouteWithNavDesign Element={StudentsListDesign} />}
        />
        <Route
          exact
          path="/Design/resultsList"
          element={<RouteWithNavDesign Element={StudentsResultDesign} />}
        />
        <Route
          exact
          path="/Design/Syllabus"
          element={<RouteWithNavDesign Element={SyllabusDesign} />}
        />
        <Route
          exact
          path="/Design/Syllabus/:department"
          element={<RouteWithNavDesign Element={SyllabusSemDesign} />}
        />
        <Route
          exact
          path="/Design/Syllabus/:year/:semester"
          element={<RouteWithNavDesign Element={SyllabusDocsDesign} />}
        />
        <Route
          exact
          path="/Design/events"
          element={<RouteWithNavDesign Element={EventsDesign} />}
        />
        <Route
          exact
          path="/Design/eventsDetails/:id"
          element={<RouteWithNavDesign Element={EventsDetails} />}
        />
        <Route
          exact
          path="/Design/Achievements-Awards-Medals"
          element={<RouteWithNavDesign Element={AchievementsDesign} />}
        />
        <Route
          exact
          path="/Design/Details/:id"
          element={<RouteWithNavDesign Element={AchievementsDetails} />}
        />
        <Route
          exact
          path="/Design/AcademicCalendar"
          element={<RouteWithNavDesign Element={AcademicCalendarDesign} />}
        />
        <Route
          exact
          path="/Design/Transportation"
          element={<RouteWithNavDesign Element={Transportation} />}
        />
        <Route
          exact
          path="/Design/Library"
          element={<RouteWithNavDesign Element={LibraryDesign} />}
        />


      </Routes>
    </div>
  );
}

export default Router;
