import React from 'react'

function Library() {
  return (
    <div className='Library'>
        <div className="campus-life-area pt-100 pb-70">
  <div className="container">
    <h3 className="text-center mb-4 ">Library</h3>
    <p className="text-justify">
      Separate libraries have been established for all the colleges of the
      group. Libraries with ample space are available in all the colleges of the
      group. It has a huge reading hall &amp; a well-furnished section
      containing sufficient books and allied subjects with Digital Library.
      Details of Journal and International Journal and books available in all
      the colleges of the group are given below.
    </p>
    <p className="text-justify">
      Computers are available for faculty, staff; and are also installed in
      hostels and the library. There is dedicated ERP section which deals all
      intuitions, students, faculty related data and information. The attendance
      of faculty and staff is monitored through a biometric system.
    </p>
    <h5 className="">
      " All knowledge that the world has ever received comes from the mind; the
      infinite library of the universe is in our own mind." <br />
    </h5>
    
    <h5 className="text-danger text-end">- Swami Vivekananda </h5>

{/* make it live  */}
    {/* <div className="row mt-3">
    <div className="col-md-4">
<a href='' target="_blank">
  <div className="single-alumoni-updates-card">
  <div className=" d-flex flex-row  align-items-center ">
    <div className=" bg-danger text-center p-3 me-3">
    <i class="ri-file-download-line text-light " style={{fontSize:"20px"}}></i>
    </div>
   <div className="">
    <h5>
     File Name Students List
    </h5>
   </div>
  </div>
</div>
</a>
  </div>
    </div> */}

    <div className="row justify-content-center mt-5">
      <table className="table table-bordered">
        <tbody>
          <tr>
            <th>Sr. No.</th>
            <th>Name of Institute</th>
            <th>No. Book/Titles</th>
            <th>National</th>
            <th>Inter-National</th>
          </tr>
          <tr>
            <td>1</td>
            <td>
              Shree Swaminarayan college of Engineering &amp; Technology
              (DIPLOMA)
            </td>
            <td>1475/8625</td>
            <td>4</td>
            <td>2</td>
          </tr>
          <tr>
            <td>2</td>
            <td>
              Shree Swaminarayan college of Engineering &amp; Technology
              (Degree)
            </td>
            <td>3550/14350</td>
            <td>45</td>
            <td>2</td>
          </tr>
          <tr>
            <td>3</td>
            <td>Shree Swaminarayan college of pharmacy Nursing</td>
            <td>3151</td>
            <td>9</td>
            <td>4</td>
          </tr>
          <tr>
            <td>4</td>
            <td>Shree Swaminarayan college of pharmacy</td>
            <td>5460/544</td>
            <td>30</td>
            <td>20</td>
          </tr>
          <tr>
            <td>5</td>
            <td>Shree Swaminarayan Physiotherapy College</td>
            <td>980</td>
            <td>24</td>
            <td>3</td>
          </tr>
          <tr>
            <td>6</td>
            <td>Shree Swaminarayan Science College</td>
            <td>150</td>
            <td>0</td>
            <td>0</td>
          </tr>
          <tr>
            <td>7</td>
            <td>Shree Swaminarayan Ayurvedic College</td>
            <td>10172/1252</td>
            <td>1</td>
            <td>1</td>
          </tr>
          <tr>
            <td>8</td>
            <td>Shree Swaminarayan B. Ed College</td>
            <td>3818</td>
            <td>13</td>
            <td>15</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

    </div>
  )
}

export default Library