import React from "react";

export default function Museums() {
  return (
    <div>
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-md-12">
            <p>
              <span style={{ fontSize: "18pt" }}>
                <strong>Labs</strong>
              </span>
            </p>

            <div className="card mb-3">
              <div className="row no-gutters">
                <div className="col-md-8">
                  <div className="card-body pt-5 ">
                    <h4 className="card-title mb-3">Rasa Shastra Museum</h4>
                    <p className="card-text">
                    The museum of Rasashastra and bhaishajya Kalpana has 30 Dry  Raw material samples(Kashtoushdhi), 35 Mineral Raw Materials (Rasoushdhi) sample and 40 prepared samples.There are 20 cucibles (Musha), 10 Mortar Pestle (Khalwa) for display in the museum. Department motivates and gives handson training to students to prepare Models of different classical equipments and instruments. Batch 2017-18 have prepared 6 Models and Batch 2018-19 have prepared a Model of Ideal Rasashala (Ayurvedic Pharmacy).
                    </p>
                  </div>
                </div>

                <div className="col-md-4">
                  <img
                    src="/assets/ayurveda/images/rasa-shastra-museum.jpg"
                    style={{'height':'100%','width':'100%','object-fit':'fill'}}
                    alt="..."
                  />
                </div>
              </div>
            </div>

            <div className="card mb-3">
              <div className="row no-gutters">
                <div className="col-md-4">
                  <img
                    src="/assets/ayurveda/images/dravyaguna-museum.jpg"
                    style={{'height':'100%','width':'100%','object-fit':'fill'}}
                    alt="..."
                  />
                </div>
                <div className="col-md-8">
                  <div className="card-body pt-5 ">
                    <h4 className="card-title mb-3">Dravyaguna Museum</h4>
                    <p className="card-text">
                    The museum of Dravyaguna has 167 dry samples, 20 live specimens which are labelled with all the required details. There are 40 herbarium sheets for display in the museum, which helps the students to get an idea about making herbariums and also helps in identifying. There are 51 charts and posters in the museum related to various concepts of Dravyaguna. The museum has models related to Basics of Botany, like parts of leaf, Flower which makes the learning process easy.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="card mb-3">
              <div className="row no-gutters">
                <div className="col-md-8">
                  <div className="card-body pt-5 ">
                    <h4 className="card-title mb-3">Agada Tantra Museum</h4>
                    <p className="card-text">
                    Agada tantra encompasses the entire topic of toxicology which includes signs symptoms and management of poisoning resulting from various animal bites like snake, scorpion ,spiders and other poisonsous combinations.. Agada tantra museum gives the details of poisonous substance as well as antidotes to poison. It is a well established museum with different types of specimens and instruments with   32 weapons, 20 Dry poisonous drug specimens, 15 Anti-dotes, 13 Metallic and Non-metallic Poisons, 10 wet Drug Specimens, 13 Household poisons, 5 Bone Specimens and many Alkali and Acids, 13 Models, 30 Informative Charts.
                    </p>
                  </div>
                </div>

                <div className="col-md-4">
                  <img
                    src="/assets/ayurveda/images/agada-tantra-museum.jpg"
                    style={{'height':'100%','width':'100%','object-fit':'fill'}}
                    alt="..."
                  />
                </div>
              </div>
            </div>

            <div className="card mb-3">
              <div className="row no-gutters">
                <div className="col-md-4">
                  <img
                    src="/assets/ayurveda/images/kaumarabhritya-museum.jpg"
                    style={{'height':'100%','width':'100%','object-fit':'fill'}}
                    alt="..."
                  />
                </div>
                <div className="col-md-8">
                  <div className="card-body pt-5 ">
                    <h4 className="card-title mb-3">Kaumarabhritya Museum</h4>
                    <p className="card-text">
                    Department of Kaumarbhritya museum is very well furnished with various models, instruments & commonly used medicines in practice of Kaumarabhritya. All the educational models assembled are for the demonstration of practical aspects of topics learnt in Kaumarbhritya theory contact hours. Some of them are “Trichakra Phakka ratha” (Wooden walker for assisted walking in children), “Dhatri Pareeksha” (Characteristics of a Wet nurse), “Danta Vaividhya –Kumar & Kumari” (Different characteristics of dentition in male & female children) etc. Equipments, Instruments & drugs required for Navajata Shishu Pranapratyagamana (Neonatal resuscitation) are also displayed in the museum for the practical demonstration to students.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            
          </div>
        </div>
      </div>
    </div>
  );
}
