import React from 'react'
import '../Arts/OfficerProfile.scss'

function VicePresidentPhysio() {

    const data = {
        quote : "Creativity is just connecting things. When you ask creative people how they did something, they feel a little guilty because they didn't really do it, they just saw something. It seemed obvious to them after a while. That's because they were able to connect experiences they've had and synthesize new things."
    }

  return (
    <div>
      <>
      <div className="container mt-5 mb-5">
      <h1 className="text-center">Profile</h1>
      <br/>
      <div className="row">
        <div className="col-md-4">
            <br/>
            <img
              src="../assets/images/academics/vicepresident3.jpg"
              alt=""
              className="img-responsive img-thumbnail"
              width={400}
              height={220}
            />
            <br />
            <br />
       
        </div>
        <div className="col-md-8">
          {/* <h2>
          Vice President
            <br />
          </h2> */}
          <h4 className="text-danger text-bold">Swami Harikrishnaswarupdasji</h4>
          <h6 className="text-dark">Trustee Representative, Shree Swaminarayan Vishvamangal Gurukul</h6>
          <p style={{ textAlign: "justify" }}>
          Swaminarayan University is a springboard for learning. It is committed to high quality teaching with a thriving interactive culture
          </p>
          <p style={{ textAlign: "justify" }}>
          Swaminarayan University is a positive place where students can go to have fun with friends, talk about relevant issues and be heard without judgment. It's a place where students are challenged to make good decisions and lead in positive ways in their colleges.
          </p>
          <p style={{ textAlign: "justify" }}>
          The principle that Swaminarayan University follows: Our work should improve people’s lives beyond the boundaries of campus. This century-old guiding philosophy applies to our academics, research, and outreach.
          </p>
          <var></var>
        </div>
      </div>
      {/* <br /> */}
      {
      data?.quote&&
    <div className="row justify-content-center align-content-center mt-5">
      <div className="col-md-12">
        <blockquote className="otro-blockquotes">
            <h4> Message -</h4>
            <h6 className="ml-5">{data?.quote}</h6>
          <span className="text-dark">- "Swami Harikrishnaswarupdasji"</span>
        </blockquote>
      </div>
    </div>
    }
      
      </div>
      </>
    </div>
  )
}

export default VicePresidentPhysio;
