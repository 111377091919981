import React from 'react'
import axios from 'axios';
import "../Connect/Downloads/Brochure.scss";
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { RESEARCH_PUBLICATION_GET } from '../../Utils/InfoUploadingConst';

// function ReseachandPublication() {
//     const banner1 = 'https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/1.jpeg'

//     return (
//       <div> <div className="container mt-5" >
//       <div className="row">
         
//           <div className="col-md-12 mb-5 mt-5">
  
//               <h3>Applied for establishment of new medical college for MBBS course for the intake of 150 students</h3>
  
//           </div>
//           <div className="col md-12 mt-5 mb-5">
//           <img src={banner1} alt="" srcset="" />
//           </div>
//       </div>
//       </div></div>
//     )
// }

function ReseachandPublication() {

    const [data, setData] = useState([]);
  
    const navigate = useNavigate();
  
    const getData = async () => {
        const config = {
            method: "get",
            url: RESEARCH_PUBLICATION_GET,
            headers: {
                "Content-Type": "application/json",
            },
        };
  
        await axios(config)
            .then((res) => {
                res.data.data.sort((a, b) => b.id - a.id);
                res.data.data.forEach((element) => {
                    element.attachments = JSON.parse(element.attachments);
                });
                console.log(res.data.data)
                setData(res.data.data);
  
            })
            .catch((err) => {
                console.log(err);
            });
    };
  
    useEffect(() => {
        getData();
    }, []);
  
    const next = async (id, data1, images) => {
        console.log("error here");
        navigate(`/sims/ReseachandPublication/${id}`, {
            state: {
                images: images,
                data: data1,
            },
        });
    };
  
    return (
      <div>
  
  <div className="container mt-5 mb-5">
    <div className="row">
      <div className='col-md-12'>
        <h3> Research and Publications </h3>
      </div>
  
  
      <div className="row">
  
      {data.map((item, key) => {
        return (
        item.status === "ACTIVE" && item.college_id == "1111006"  &&(
  
      <div className='col-md-6 pt-4'>   
      <div className='card border-danger shadow-reguler' key={item.id} style={{ cursor: "pointer" }}>
  
      <a href={item?.attachments} target='_blank'>
  
        <div className="card-body">    
          <h5>{item?.title}</h5>
        
        <div className="d-flex justify-content-between">    
          <p>{item?.researcher}</p>
          <p> {item?.month} - {item?.publishdate.split('-')[0]}</p>
        </div>
  
        </div>
  
      </a>
  
      </div>                                        
    </div>
  
  )
  )
  })
  }
    
    </div>
     
    </div>
    <br />
    <br />
  </div>
  
  
  
      </div>
    )
  }

export default ReseachandPublication;