import React from "react";

function PrincipalMessagePhysiotherapy() {
  return (
    <div>
      <>
        <div className="container mt-5 mb-5">
          <div className="row">
            <div className="col-md-3">
              <img
                src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/Urvashi+Jadeja.png"
                alt=""
                className="img-responsive img-thumbnail"
                width={350}
                height={260}
              />
              <br />
              <br />

              <h3>
                Dr. Urvashi Jadeja
                <br />
              </h3>
              <p style={{}}>
                Designation - Dean (I/c) & Associate Professor <br />
                MPT (Pediatrics)
                <br />
                GSCPT Registration number - GPC-6229 <br />
                IAP registration number - 31386 <br />
                Academic Experience - 12 Years <br />
                Date of Joining - 5th September 2023 <br />
              </p>
            </div>
            <div className="col-md-9">
              <h2>
                FACULTY OF PHYSIOTHERAPY
                <br />
              </h2>
              <p style={{ textAlign: "justify" }}>
                Dr. Urvashi Jadeja (PT) holds a Master's degree in Physiotherapy
                with a specialization in Paediatrics. Currently, she serves as a
                Principal (I/c) and an Associate Professor at Shree Swaminarayan
                Physiotherapy College, which is affiliated with Swaminarayan
                University in Kalol. With over 12 years of experience in
                academics and research within the field of Physiotherapy. She
                has authored a book entitled “A Guide to Pediatric Physical
                Therapy” published by LAP in 2022. Additionally, she has
                contributed a chapter to the book “Physiotherapy in Neurological
                Conditions” published by Jaypee Brothers. She has publication of
                paper in numerous International and National Physiotherapy
                journals.
              </p>
              <br />
              <br />
              <h2>
                PRINCIPAL’S MESSAGE
                <br />
              </h2>
              <p style={{ textAlign: "justify" }}>
                I am Dr. Urvashi Jadeja, the Dean Incharge of Shree Swaminarayan
                Physiotherapy College in Kalol. It is a great honor for me to be
                a part of one of Gujarat's leading institutions, which boasts an
                impressive campus and cutting-edge facilities. We take pride in
                the fact that only a few institutions in Gujarat have their own
                multi-specialty on-campus hospitals. This unique feature
                provides students with valuable opportunities to gain practical
                skills in assessment and treatment, as well as high-quality
                clinical and academic education. Our hospital offers a wide
                range of services, including an emergency ward, ICU, NICU, and
                PICU, along with a well-equipped physiotherapy OPD.
              </p>
              <p style={{ textAlign: "justify" }}>
                We offer a variety of health science courses at our university,
                such as Medicine, Ayurveda, Homeopathy, Nursing, Pharmacy,
                Engineering, and other fields. Our goal is to educate students
                in diverse areas to support their holistic development.
              </p>
              <p style={{ textAlign: "justify" }}>
                Our institution takes pride in its team of highly skilled
                faculty members, both internal and external. These experts have
                a deep understanding of their fields and are committed to
                helping students build a successful future through their
                extensive knowledge. Our faculty not only imparts academic
                knowledge but also essential life skills to help students grow
                and develop. We believe in providing a highly resourceful
                learning environment to nurture our students effectively.
              </p>
              <p style={{ textAlign: "justify" }}>
                Our classrooms and labs are equipped with ample resources to
                support students' theoretical and practical learning. We
                encourage students to participate in both co-curricular and
                extra-curricular activities. Personal development is a top
                priority at our institution, where we emphasize behavioral
                discipline, moral integrity, and cognitive enhancement.
              </p>
            </div>
          </div>
          <br />
          <br />
        </div>{" "}
      </>
    </div>
  );
}

export default PrincipalMessagePhysiotherapy;
