import React from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { Http } from "../../Services/Services";
import { SYLLABUS_GET5 } from "../../Utils/InfoUploadingConst";
import { LOCAL_DEPARTMENT } from "../../Utils/LocalStorageConstants";
import { ACADEMICS_ADD_CLASS, ACADEMICS_ADD_SEMESTER } from "../../Utils/Academics.apiConst";


function HomeoSyllabusDocs({ setLoading }) {
  const [data, setData] = useState([]);
  const [classData, setClassData] = useState([]);
  const [semesterData, setSemesterData] = useState([]);

  const navigate = useNavigate();

  const { year, semester, department } = useParams(); // Get the departmentId from the URL

  console.log("classId",department);

  const departmentOpt = JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT));

  const getData1 = async () => {
    const config = {
      method: "get",
      url: ACADEMICS_ADD_SEMESTER,
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios(config);
      setSemesterData(res.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  const getData = async () => {
    const config = {
      method: "get",
      url: SYLLABUS_GET5,
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios(config);
      res.data.data.sort((a, b) => b.id - a.id);
      res.data.data.forEach((element) => {
        element.attachments = JSON.parse(element.attachments);
    });
      setData(res.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  const getData2 = async () => {
    const config = {
      method: "get",
      url: ACADEMICS_ADD_CLASS,
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios(config);
      setClassData(res.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  console.log("classData", classData);

  useEffect(() => {
    getData();
    getData2();
  }, []);

  useEffect(() => {
    getData1();
  }, []);

  // Filter classData based on the departmentId
  const filteredClassData = data.filter((item) => item.department === department);

  let homeoData = [
    {
      id: "UG-Anatomy",
      title: "Anatomy",
      value: "Anatomy",
    },
    {
      id: "UG-Physiology",
      title: "Physiology",
      value: "Physiology",
    },
    {
      id: "UG-Pathology & Microbiology",
      title: "Pathology & Microbiology",
      value: "Pathology & Microbiology",
    },
    {
      id: "UG-Forensic Medicine & Toxicology",
      title: "Forensic Medicine & Toxicology",
      value: "Forensic Medicine & Toxicology",
    },
    {
      id: "UG-Surgery",
      title: "Surgery",
      value: "Surgery",
    },
    {
      id: "UG-Obstetrics & Gynaecology",
      title: "Obstetrics & Gynaecology",
      value: "Obstetrics & Gynaecology",
    },
    {
      id: "UG-Practice of Medicine",
      title: "Practice of Medicine",
      value: "Practice of Medicine",
    },
    {
      id: "UG-Community Medicine",
      title: "Community Medicine",
      value: "Community Medicine",
    },
    {
      id: "UG-Homeopathic Pharmacy",
      title: "Homeopathic Pharmacy",
      value: "Homeopathic Pharmacy",
    },
    {
      id: "UG-Organon of Medicine",
      title: "Organon of Medicine",
      value: "Organon of Medicine",
    },
    {
      id: "UG-Materia Medica",
      title: "Materia Medica",
      value: "Materia Medica",
    },
    {
      id: "UG-Repertory",
      title: "Repertory",
      value: "Repertory",
    },
    {
      id: "UG-Yog",
      title: "Yog",
      value: "Yog",
    },

    {
      id: "PG/MASTER-ORGANON OF MEDICINE",
      title: "Organon of Medicine",
      value: "ORGANON OF MEDICINE"
    },
    {
      id: "PG/MASTER-REPERTORY",
      title: "Repertory",
      value: "REPERTORY",
    },
    {
      id: "PG/MASTER-MATERIA",
      title: "Materia Medica",
      value: "MATERIA MEDICA",
    },
  ];

  console.log("semester data -", data?.find(
    (s) => s.department == department
  )?.semester);

  return (
    <div>
      <div className="container mt-5 mb-5">
        <div className="row">
          <section className="course-section">
            <div className="container">
              <div className="section-title">
                <h2>Syllabus</h2>
              </div>
              <div className="sub-section-title">
                <h2>All Documents</h2>
              </div>  

<div className="row mbr-justify-content-center">
{filteredClassData.map((item, key) => (
  <div className="col-lg-6 mbr-col-md-10" key={item.id}>
      <div className="wrap">
        <div className="ico-wrap">
          <span
            className="mbr-iconfont fa fa-graduation-cap"
            style={{ color: "#fff" }}
          ></span>
        </div>
        <div className="text-wrap vcenter" key={item.id}>
          <h2
            className="mbr-fonts-style mbr-bold mbr-section-title3 display-5"
            style={{ color: "#fff" }}
          >
            {/* {
              semesterData?.find(
                (s) => s.id == item.semester
              )?.name
            } */}
            {
              data?.find(
                (s) => s.department == department
              )?.semester
            }
          </h2>

          <p
            className="mbr-fonts-style text1 mbr-text display-6" key={item.id}            
          >          
           <a href={item?.attachments} target="_blank" ><span><h5 className=" text-white">To get more details on Syllabus details,<span><h5 className=" text-primary">Click here</h5></span> </h5></span></a>
          </p>

        </div>
      </div>
  </div>
))}
</div>

            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default HomeoSyllabusDocs;