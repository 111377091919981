import React from "react";

function CollegeCouncil() {
  return (
    <div>
      <div className="container register mb-5" style={{ maxWidth: "1300px" }}>
        <div className="row">
          <h3 className="col-md-12 text-uppercase">COLLEGE COUNCIL Members</h3>
          <div className="col-md-12" style={{ textAlign: "justify" }}>
            TEXT HERE
          </div>
          <div className="col-md-12" style={{ textAlign: "justify" }}>
            <br />
          </div>
          <div className="col-md-12" style={{ textAlign: "justify" }}></div>
          <div className="col-md-12" style={{ textAlign: "justify" }}>
            <strong />
            <br />
          </div>
          <div className="col-md-12" style={{ textAlign: "justify" }}>
            <table
              className="table table-bordered text-center"
              style={{ width: "100%", borderCollapse: "collapse", height: 100 }}
              cellSpacing={5}
              cellPadding={5}
              border={1}
            >
              <tbody>
                <tr>
                  <td style={{ width: "20.8668%" }}>
                    <strong>Name of The Department</strong>
                  </td>
                  <td style={{ width: "28.7596%" }}>
                    <strong>Head of Department</strong>
                  </td>
                </tr>

                <tr>
                  <td>Test here</td>
                  <td>Test here</td>
                </tr>
              </tbody>
            </table>
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CollegeCouncil;
