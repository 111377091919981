import React from "react";

export default function Eligibility() {
  return (
    <div>
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-md-12">

            <p>
              <span style={{ fontSize: "18pt" }}>
                <strong>College Committees</strong>
              </span>
              <br />
            </p>

            <div className="card">
              <h5 className="card-header">Anti Ragging Committee</h5>
              <div className="card-body">
                <h5 className="card-title">Member Names</h5>
                <p className="card-text">
                  Dr. Rohit Gokarn, Chairman
                  <br />
                  Dr. Darshana Ramole
                  <br />
                  Dr Amol Koshti
                  <br />
                  Dr Visakh Sakthidharan
                  <br />
                  Dr. Hemal Bhatt
                  <br />
                  Dr Sarin T
                  <br />
                  Miss. Prajapati Janki Rajeshbhai
                  <br />
                  Miss. Rajani Nandramani
                  <br />
                  Mr. Shivam Thakkar
                  <br />
                  Miss. Divya Rami
                </p>
              </div>
            </div>

            <br />

            <div className="card">
              <h5 className="card-header">
                Women Welfare & Prevention of Sexual Harassment Committee
              </h5>
              <div className="card-body">
                <h5 className="card-title">Member Names</h5>
                <p className="card-text">
                  Dr. Anita Patel, Coordinator
                  <br />
                  Dr. Supriya Kallianpur
                  <br />
                  Dr. Shanti Nair
                  <br />
                  Dr. Nilam Rathod
                  <br />
                  Dr Zalak Patel
                  <br />
                  Dr. Anagha N
                </p>
              </div>
            </div>

            <br />

            <div className="card">
              <h5 className="card-header">
                Career Guidance Placement Committee
              </h5>
              <div className="card-body">
                <h5 className="card-title">Member Names</h5>
                <p className="card-text">
                  Dr. Rohit Gokarn, Chairman
                  <br />
                  Dr. Chandramouli Raju, Coordinator
                  <br />
                  Dr. Jaydatt Mehta
                  <br />
                  Dr. Darshana Ramole
                  <br />
                  Dr. Priya Deshpande
                  <br />
                  Dr. Amol Koshti
                </p>
              </div>
            </div>

            <br />

            <div className="card">
              <h5 className="card-header">NSS Committee</h5>
              <div className="card-body">
                <h5 className="card-title">Member Names</h5>
                <p className="card-text">
                  Mr. Jitendra Jani, Coordinator
                  <br />
                  Dr. Rajat Patel, Member
                  <br />
                  Dr. Shanti Nair, Member
                  <br />
                  Dr. Hiral Jadav, Member
                  <br />
                  Dr. Merin Jose, Member
                  <br />
                  Dr. Sruthi Mohan, Member
                  <br />
                  Dr Arathy Menon, Member
                </p>
              </div>
            </div>

            <br />

            <div className="card">
              <h5 className="card-header">Website Committee</h5>
              <div className="card-body">
                <h5 className="card-title">Member Names</h5>
                <p className="card-text">
                  Dr. Rohit Gokarn, Chairman
                  <br />
                  Dr. Amol Koshti,, Coordinator
                  <br />
                  Dr. Anita Ratnavat, Member
                  <br />
                  Dr. Visakh S, Member
                  <br />
                  Dr. Pooja Soni, Member
                </p>
              </div>
            </div>

            <br />

            <div className="card">
              <h5 className="card-header">Seminars Conferences Committee</h5>
              <div className="card-body">
                <h5 className="card-title">Member Names</h5>
                <p className="card-text">
                  Dr. Darshan Ramole, Coordinator
                  <br />
                  Dr. NIraj Sharma, Member
                  <br />
                  Dr. Anita Ratnavat, Member
                  <br />
                  Dr. Jamunavevi R, Member
                  <br />
                  Dr. Seema Dabhaliya, Member
                  <br />
                  Dr. Amruta Somani, Member
                  <br />
                  Dr. Greeshma Gopi, Member
                  <br />
                  Mr.Jitendra Jani, Member
                </p>
              </div>
            </div>

            <br />

            <div className="card">
              <h5 className="card-header">Medical Camps Committee</h5>
              <div className="card-body">
                <h5 className="card-title">Member Names</h5>
                <p className="card-text">
                  Prof. Darshana Ramole, Chairman
                  <br />
                  Dr. Jaydatt Mehta, Convenor
                  <br />
                  Dr. Suresh Prajapati, Member
                  <br />
                  Dr. Archana Patel, Member
                  <br />
                  Dr Saurabh Patel, Member
                  <br />
                  Dr Sangita Jadav, Member
                  <br />
                  Management Representative Member
                </p>
              </div>
            </div>

            <br />

            <div className="card">
              <h5 className="card-header">Sports Committee</h5>
              <div className="card-body">
                <h5 className="card-title">Member Names</h5>
                <p className="card-text">
                  Dr.Sarin T, Coordinator
                  <br />
                  Dr. J Sivanarayan, Member
                  <br />
                  Dr. Zalak Patel, Member
                  <br />
                  Dr. Vishakh S, Member
                  <br />
                  Dr. Nilam Rathod, Member
                </p>
              </div>
            </div>

            <br />

            <div className="card">
              <h5 className="card-header">Research Committee</h5>
              <div className="card-body">
                <h5 className="card-title">Member Names</h5>
                <p className="card-text">
                  Dr. Rohit Gokarn, Coordinator
                  <br />
                  Dr Anita Patel, Member
                  <br />
                  Dr. Amol Koshti, Member
                  <br />
                  Dr. Hemal Bhatt, Member
                  <br />
                  Dr. Vishal Solanki, Member
                </p>
              </div>
            </div>

            <br />

            <div className="card">
              <h5 className="card-header">Cultural Committee</h5>
              <div className="card-body">
                <h5 className="card-title">Member Names</h5>
                <p className="card-text">
                  Dr.Priyadarshini Nambiar, Coordinator
                  <br />
                  Dr Krishnendu N Member
                  <br />
                  Dr.Ayana R, Member
                  <br />
                  Dr.Sindhura, Member
                  <br />
                  Dr.Sreejith K, Member
                  <br />
                  Dr.Divya Sharma, Member
                </p>
              </div>
            </div>

            <br />

            <div className="card">
              <h5 className="card-header">Literary Committee</h5>
              <div className="card-body">
                <h5 className="card-title">Member Names</h5>
                <p className="card-text">
                  Dr. Supriya Kallianpur, Coordinator
                  <br />
                  Mr.Jitendra Jani, Member
                  <br />
                  Dr. Hemal Bhatt, Member
                  <br />
                  Dr. Ayana R, Member
                  <br />
                  Dr. Visakh Sakthidharan, Member
                </p>
              </div>
            </div>

            <br />

            <div className="card">
              <h5 className="card-header">Library</h5>
              <div className="card-body">
                <h5 className="card-title">Member Names</h5>
                <p className="card-text">
                  Dr. Supriya Kallianpur, Coordinator
                  <br />
                  Dr. Zalak Patel, Member
                  <br />
                  Mr. Jitendra Jani, Member
                  <br />
                  Mrs. Twinkal Patel, Librarian, Member
                </p>
              </div>
            </div>

            <br />

            <div className="card">
              <h5 className="card-header">NABH Quality & Safety Committee</h5>
              <div className="card-body">
                <h5 className="card-title">Member Names</h5>
                <p className="card-text">
                  Prof. Rohit Gokarn, Chairman
                  <br />
                  Dr. Darshana Ramole, Co-ordinator
                  <br />
                  Dr. Jaydatt Mehta, Member
                  <br />
                  Dr. Amol Koshti, Member
                </p>
              </div>
            </div>

            <br />

            <div className="card">
              <h5 className="card-header">Disciplinary Committee</h5>
              <div className="card-body">
                <h5 className="card-title">Member Names</h5>
                <p className="card-text">
                  Prof.Rohit Gokarn, Chairman
                  <br />
                  Dr. Amol Koshti, Member
                  <br />
                  Dr. Anita Patel, Member
                  <br />
                  Dr. Jaydatt Mehta, Member
                  <br />
                  Dr. Anita Ratnawat, Member
                  <br />
                  Dr. Sarin T. Member
                  <br />
                  Swami Bhaktavatsaldasji, Advisory Member
                </p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}
