export const studentData=[
    {
      name: "Priyansh raj",
      email: "prynsh@yopmail.com",
      department: "LAW",
      college: "college",
      usn: "01fe20bcs212",
      "type": "External"
  },
  {
    name: "Guru raj",
    email: "guru@nexenstial.com",
    department: "LAW",
    college: "college",
    usn: "01fe20bcs213",
    "type": "External"
  },
  {
    name: "Dr.Anita Patel",
    email: "rd@swaminarayanuniversity.ac.in",
    department: "LAW",
    college: "Faculty of LAW",
    usn: "SUKALOLDUMMYID",
    "type": "External"
  },
  {
    name: "Provost",
    email: "provost@swaminarayanuniversity.ac.in",
    department: "TESTDEPT",
    college: "TESTCOLLEGE",
    usn: "TESTENROLLMENTNO",
    "type": "External"
  },
    // {
    //   "sl_no": 1,
    //   "name": "AJAYKUMAR SURESHBHAI BAROT",
    //   "college": "Law",
    //   "department": "Law",
    //   "category": "SEBC/OBC",
    //   "mobile_number": 9033228086,
    //   "email": "AJAYBAROT71@GMAIL.COM",
    //   "usn": "SUK220806DR001",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 2,
    //   "name": "Anmol Bharatkumar Dabhi",
    //   "college": "Law",
    //   "department": "Law",
    //   "category": "GEN",
    //   "mobile_number": 9638510129,
    //   "email": "anmoldabhi97@gmail.com",
    //   "usn": "SUK220806DR002",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 3,
    //   "name": "BAROT HARSHADKUMAR DALICHANDBHAI",
    //   "college": "Law",
    //   "department": "Law",
    //   "category": "SC",
    //   "mobile_number": 6351967155,
    //   "email": "harshadbarot168@gmail.com",
    //   "usn": "SUK220806DR003",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 4,
    //   "name": "BASIYA UMEDBHAI GABHRUBHAI",
    //   "college": "Law",
    //   "department": "Law",
    //   "category": "GEN",
    //   "mobile_number": 9979399499,
    //   "email": "U.G.BASIYA82@GMAIL.COM",
    //   "usn": "SUK220806DR004",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 5,
    //   "name": "CHAUDHARY PINKESHKUMAR RAMJIBHAI",
    //   "college": "Law",
    //   "department": "Law",
    //   "category": "SEBC/OBC",
    //   "mobile_number": 9429424040,
    //   "email": "pinkesh4040@gmail.com",
    //   "usn": "SUK220806DR005",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 6,
    //   "name": "DAVDA DONIKA HARESHBHAI",
    //   "college": "Law",
    //   "department": "Law",
    //   "category": "GEN",
    //   "mobile_number": 7405249974,
    //   "email": "donika.dave27@gmail.com",
    //   "usn": "SUK220806DR006",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 7,
    //   "name": "DHAVAL AMRUTLAL RATHOD",
    //   "college": "Law",
    //   "department": "Law",
    //   "category": "SEBC/OBC",
    //   "mobile_number": 9825822453,
    //   "email": "dhavalrathod_85@yahoo.co.in",
    //   "usn": "SUK220806DR007",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 8,
    //   "name": "GADHAVI JANALBEN BALRAMSINH",
    //   "college": "Law",
    //   "department": "Law",
    //   "category": "SEBC/OBC",
    //   "mobile_number": 9998674263,
    //   "email": "janalgadhavi16@gmail.com",
    //   "usn": "SUK220806DR008",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 9,
    //   "name": "Gayatri Trivedi",
    //   "college": "Law",
    //   "department": "Law",
    //   "category": "GEN",
    //   "mobile_number": 9726797047,
    //   "email": "gayatri2a@yahoo.com",
    //   "usn": "SUK220806DR009",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 10,
    //   "name": "GONDALIYA MILAN KISHORBHAI",
    //   "college": "Law",
    //   "department": "Law",
    //   "category": "GEN",
    //   "mobile_number": 9879417798,
    //   "email": "milangondaliya24@gmail.com",
    //   "usn": "SUK220806DR010",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 11,
    //   "name": "Gondhia Mayuri Kiritkumar",
    //   "college": "Law",
    //   "department": "Law",
    //   "category": "GEN",
    //   "mobile_number": 9429550530,
    //   "email": "mkgondhia@gmail.com",
    //   "usn": "SUK220806DR011",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 12,
    //   "name": "HARSHILKUMAR DINESHBHAI BANAWALA",
    //   "college": "Law",
    //   "department": "Law",
    //   "category": "SEBC/OBC",
    //   "mobile_number": 9408168544,
    //   "email": "banawalahd94081@gmail.com",
    //   "usn": "SUK220806DR012",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 13,
    //   "name": "IMRANKHAN AHMEDKHAN NAGORI",
    //   "college": "Law",
    //   "department": "Law",
    //   "category": "GEN",
    //   "mobile_number": 9428595822,
    //   "email": "imrannagori1978@gmail.com",
    //   "usn": "SUK220806DR013",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 14,
    //   "name": "JADAV DHANVANTIBEN MANGALDAS",
    //   "college": "Law",
    //   "department": "Law",
    //   "category": "SC",
    //   "mobile_number": 7990994204,
    //   "email": "Dhanvantipooja@gmail.com",
    //   "usn": "SUK220806DR014",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 15,
    //   "name": "JADEJA JAYRAJSINH NARENDRASINH",
    //   "college": "Law",
    //   "department": "Law",
    //   "category": "GEN",
    //   "mobile_number": 9978800001,
    //   "email": "jadejajayrajsinhji@gmail.com",
    //   "usn": "SUK220806DR015",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 16,
    //   "name": "JADEJA MAHAVIRSINH NARENDRASINH",
    //   "college": "Law",
    //   "department": "Law",
    //   "category": "GEN",
    //   "mobile_number": 9825799971,
    //   "email": "mahavirsinhjadeja972@gmail.com",
    //   "usn": "SUK220806DR016",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 17,
    //   "name": "JIGNESHKUMAR DAHYABHAI SOLANKI",
    //   "college": "Law",
    //   "department": "Law",
    //   "category": "SC",
    //   "mobile_number": 7698888835,
    //   "email": "j.d.solanki2511@gmail.com",
    //   "usn": "SUK220806DR017",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 18,
    //   "name": "KANUDAWALA PIYUSHKUMAR JAGDISHBHAI",
    //   "college": "Law",
    //   "department": "Law",
    //   "category": "SEBC/OBC",
    //   "mobile_number": 9429718140,
    //   "email": "piyushkanudawala@gmail.com",
    //   "usn": "SUK220806DR018",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 19,
    //   "name": "KARISHMABANU MOHMMADSAFIBHAI MEMON",
    //   "college": "Law",
    //   "department": "Law",
    //   "category": "GEN",
    //   "mobile_number": 7016850660,
    //   "email": "memonkarishma92@gmail.com",
    //   "usn": "SUK220806DR019",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 20,
    //   "name": "KASUNDRA JAYDEEPKUMAR AVCHARBHAI",
    //   "college": "Law",
    //   "department": "Law",
    //   "category": "GEN",
    //   "mobile_number": 9925119779,
    //   "email": "kasundrajaydeep@gmail.com",
    //   "usn": "SUK220806DR020",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 21,
    //   "name": "Lakhatariya Maulik Bikhubhai",
    //   "college": "Law",
    //   "department": "Law",
    //   "category": "SEBC/OBC",
    //   "mobile_number": 7874700707,
    //   "email": "mauliklakhatariya@gmail.com",
    //   "usn": "SUK220806DR021",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 22,
    //   "name": "MASALIYA NIKITABEN DHIRAJLAL",
    //   "college": "Law",
    //   "department": "Law",
    //   "category": "GEN",
    //   "mobile_number": 9427670626,
    //   "email": "n9978588@gmail.com",
    //   "usn": "SUK220806DR022",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 23,
    //   "name": "MEETKUMAR JITENDRAKUMAR PANDIT",
    //   "college": "Law",
    //   "department": "Law",
    //   "category": "GEN",
    //   "mobile_number": 9898407670,
    //   "email": "meetpandit2309@gmail.com",
    //   "usn": "SUK220806DR023",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 24,
    //   "name": "NAGANI KUNDANBEN VITHTHALBHAI",
    //   "college": "Law",
    //   "department": "Law",
    //   "category": "GEN",
    //   "mobile_number": 9913598347,
    //   "email": "KUNDANPATEL9090@GMAIL.COM",
    //   "usn": "SUK220806DR024",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 25,
    //   "name": "Nariya Nita VAllabhbhai",
    //   "college": "Law",
    //   "department": "Law",
    //   "category": "GEN",
    //   "mobile_number": 9824220841,
    //   "email": "vimalpandya013@gmail.com",
    //   "usn": "SUK220806DR025",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 26,
    //   "name": "Nikunj vyas",
    //   "college": "Law",
    //   "department": "Law",
    //   "category": "GEN",
    //   "mobile_number": 9426472003,
    //   "email": "advnikunjvyas@gmail.com",
    //   "usn": "SUK220806DR026",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 27,
    //   "name": "PARMAR BHUMIKA BHAYLALBHAI",
    //   "college": "Law",
    //   "department": "Law",
    //   "category": "SC",
    //   "mobile_number": 9725295111,
    //   "email": "bhumiparmar1711@gmail.com",
    //   "usn": "SUK220806DR027",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 28,
    //   "name": "PARMAR PANKAJ PRAVINCHANDRA",
    //   "college": "Law",
    //   "department": "Law",
    //   "category": "SEBC/OBC",
    //   "mobile_number": 7383838888,
    //   "email": "pankajpparmar@gmail.com",
    //   "usn": "SUK220806DR028",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 29,
    //   "name": "PATADIYA UDAY BHUPENDRABHAI",
    //   "college": "Law",
    //   "department": "Law",
    //   "category": "GEN",
    //   "mobile_number": 9737504044,
    //   "email": "ubpatadiya@gmail.com",
    //   "usn": "SUK220806DR029",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 30,
    //   "name": "PATEL ANILKUMAR JAYANTIBHAI",
    //   "college": "Law",
    //   "department": "Law",
    //   "category": "GEN",
    //   "mobile_number": 8849999810,
    //   "email": "advocateanilpatel@gmail.com",
    //   "usn": "SUK220806DR030",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 31,
    //   "name": "PATEL ARATIBEN",
    //   "college": "Law",
    //   "department": "Law",
    //   "category": "GEN",
    //   "mobile_number": 9638176767,
    //   "email": "ARATIDPATEL91@GMAIL.COM",
    //   "usn": "SUK220806DR031",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 32,
    //   "name": "PATEL JANKIBEN VISHNUBHAI",
    //   "college": "Law",
    //   "department": "Law",
    //   "category": "GEN",
    //   "mobile_number": 9925098368,
    //   "email": "mitesh.patel28892@gmail.com",
    //   "usn": "SUK220806DR032",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 33,
    //   "name": "PATEL PRITESHKUMAR NARESHBHAI",
    //   "college": "Law",
    //   "department": "Law",
    //   "category": "GEN",
    //   "mobile_number": 9033426424,
    //   "email": "pritesh1321990@gmail.com",
    //   "usn": "SUK220806DR033",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 34,
    //   "name": "RADADIYA RAJESHKUMAR VITHTHALBHAI",
    //   "college": "Law",
    //   "department": "Law",
    //   "category": "GEN",
    //   "mobile_number": 9825294225,
    //   "email": "ddrschool2274@gmail.com",
    //   "usn": "SUK220806DR034",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 35,
    //   "name": "RANA YUVRAJSINH BHARATSINH",
    //   "college": "Law",
    //   "department": "Law",
    //   "category": "GEN",
    //   "mobile_number": 8530904343,
    //   "email": "yuvrajsinhzala8090@gmail.com",
    //   "usn": "SUK220806DR035",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 36,
    //   "name": "RASHMITA GIRISHBHAI PATEL",
    //   "college": "Law",
    //   "department": "Law",
    //   "category": "ST",
    //   "mobile_number": 7202078604,
    //   "email": "rashmitapatel916@gmail.com",
    //   "usn": "SUK220806DR036",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 37,
    //   "name": "RONAKBHAI ASHOKKUMAR PATEL",
    //   "college": "Law",
    //   "department": "Law",
    //   "category": "GEN",
    //   "mobile_number": 9714979620,
    //   "email": "ronakbhai46@gmail.com",
    //   "usn": "SUK220806DR037",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 38,
    //   "name": "SAPANA KUMARI VRAJVALI MISHRA",
    //   "college": "Law",
    //   "department": "Law",
    //   "category": "GEN",
    //   "mobile_number": 9033396340,
    //   "email": "svmishra151ici@gmail.com",
    //   "usn": "SUK220806DR038",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 39,
    //   "name": "Shah Bhaumik",
    //   "college": "Law",
    //   "department": "Law",
    //   "category": "GEN",
    //   "mobile_number": 9825110388,
    //   "email": "advocatebhaumikshah@gmail.com",
    //   "usn": "SUK220806DR039",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 40,
    //   "name": "SHAH KRINABEN KAMLESHBHAI",
    //   "college": "Law",
    //   "department": "Law",
    //   "category": "GEN",
    //   "mobile_number": 6351857075,
    //   "email": "kkshah1995@gmail.com",
    //   "usn": "SUK220806DR040",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 41,
    //   "name": "SHANGHVI ASCHARAYA HARIOMJAMNADAS",
    //   "college": "Law",
    //   "department": "Law",
    //   "category": "GEN",
    //   "mobile_number": 9825472744,
    //   "email": "mahadevcomputerspc@yahoo.com",
    //   "usn": "SUK220806DR041",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 42,
    //   "name": "Sohel udheja",
    //   "college": "Law",
    //   "department": "Law",
    //   "category": "SEBC/OBC",
    //   "mobile_number": 9825989598,
    //   "email": "soheludheja05@gmail.com",
    //   "usn": "SUK220806DR042",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 43,
    //   "name": "SOLANKI VINODKUMAR BHALABHAI",
    //   "college": "Law",
    //   "department": "Law",
    //   "category": "SC",
    //   "mobile_number": 8140933768,
    //   "email": "vinodsolanki94@gmail.com",
    //   "usn": "SUK220806DR043",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 44,
    //   "name": "TAMBOLI YOGINI RAJENDRABHAI",
    //   "college": "Law",
    //   "department": "Law",
    //   "category": "GEN",
    //   "mobile_number": 9824148625,
    //   "email": "yoginikunj_ap@yahoo.co.in",
    //   "usn": "SUK220806DR044",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 45,
    //   "name": "THAKOR AJATBEN BABUJI",
    //   "college": "Law",
    //   "department": "Law",
    //   "category": "SEBC/OBC",
    //   "mobile_number": 9824241986,
    //   "email": "ajatthakor19@gmail.com",
    //   "usn": "SUK220806DR045",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 46,
    //   "name": "TULSIKUMAR ROCHIRAM",
    //   "college": "Law",
    //   "department": "Law",
    //   "category": "GEN",
    //   "mobile_number": 9824176317,
    //   "email": "trsharma2016@gmail.com",
    //   "usn": "SUK220806DR046",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 47,
    //   "name": "VADNAGRA BHAVIK",
    //   "college": "Law",
    //   "department": "Law",
    //   "category": "GEN",
    //   "mobile_number": 9978178542,
    //   "email": "bsoni171@gmail.com",
    //   "usn": "SUK220806DR047",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 48,
    //   "name": "Variya Hardik Harkishandas",
    //   "college": "Law",
    //   "department": "Law",
    //   "category": "GEN",
    //   "mobile_number": 9723581624,
    //   "email": "hardikvariya117@gmail.com",
    //   "usn": "SUK220806DR048",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 49,
    //   "name": "Dr JINUSEKHAR S",
    //   "college": "AYU/PTSR",
    //   "department": "Prasuti Tantra & Streeroga (PTSR)",
    //   "category": "GEN",
    //   "mobile_number": 7907941016,
    //   "email": "jinusekhars@gmail.com",
    //   "usn": "SUK220206DR001",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 50,
    //   "name": "Dr Ketan Rathwa",
    //   "college": "AYU/RSBK",
    //   "department": "Rasa Shastra & Bhaishajya Kalpana(RSBK)",
    //   "category": "ST",
    //   "mobile_number": 9427789065,
    //   "email": "Ketan4849@gmail.com",
    //   "usn": "SUK220206DR002",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 51,
    //   "name": "Dr Milind Chatrabhuji",
    //   "college": "AYU/RSBK",
    //   "department": "Rasa Shastra & Bhaishajya Kalpana(RSBK)",
    //   "category": "Gen",
    //   "mobile_number": 9016821101,
    //   "email": "dr.milindchatrabhuji@gmail.com",
    //   "usn": "SUK220206DR003",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 52,
    //   "name": "Dr Palak",
    //   "college": "AYU/RN",
    //   "department": "Roganidan & Vikruti Vigyan (RN)",
    //   "category": "GENB",
    //   "mobile_number": 8427777797,
    //   "email": "palak.pruthi77777@gmail.com",
    //   "usn": "SUK220206DR004",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 53,
    //   "name": "DURGESH KUMAR",
    //   "college": "AYU/KC",
    //   "department": "Kayachikitsa ( KC)",
    //   "category": "SEBC/OBC",
    //   "mobile_number": 9708345012,
    //   "email": "durgeshkumar3004@gmail.com",
    //   "usn": "SUK220206DR005",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 54,
    //   "name": "Gaitonde Hrishikesh Anant Mangala",
    //   "college": "AYU/SAMHITA",
    //   "department": "SAMHITA",
    //   "category": "GEN",
    //   "mobile_number": 9322221714,
    //   "email": "dr.hrishikesh@gmail.com",
    //   "usn": "SUK220206DR006",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 55,
    //   "name": "HARMEET KOUR",
    //   "college": "AYU/SL",
    //   "department": "Shalyatantra( SL)",
    //   "category": "GEN",
    //   "mobile_number": 9697122134,
    //   "email": "drharmeetkour1711@gmail.com",
    //   "usn": "SUK220206DR007",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 56,
    //   "name": "LOVNISH",
    //   "college": "AYU/KC",
    //   "department": "Kayachikitsa ( KC)",
    //   "category": "GEN",
    //   "mobile_number": 9872977773,
    //   "email": "drlovnishpruthi@gmail.com",
    //   "usn": "SUK220206DR008",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 57,
    //   "name": "MAHI PRAVESH MISHRA",
    //   "college": "AYU/PTSR",
    //   "department": "Prasuti Tantra & Streeroga (PTSR)",
    //   "category": "GEN",
    //   "mobile_number": 9826239560,
    //   "email": "drmahimishra@gmail.com",
    //   "usn": "SUK220206DR009",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 58,
    //   "name": "PATEL BHADRESHKUMAR",
    //   "college": "AYU/Samhita",
    //   "department": "SAMHITA",
    //   "category": "GEN",
    //   "mobile_number": 9427070646,
    //   "email": "bpositive.arky@gmail.com",
    //   "usn": "SUK220206DR010",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 59,
    //   "name": "Prabudh kumar praveen",
    //   "college": "AYU/SL",
    //   "department": "Shalyatantra( SL)",
    //   "category": "GEN",
    //   "mobile_number": 7903765393,
    //   "email": "pk97713@gmail.com",
    //   "usn": "SUK220206DR011",
    //   "type": "External"
    // }
   , {
      "sl_no": 100,
      "name": "Rajat Rajendrakumar Patel",
      "college": "AYU/SAM",
      "department": "Samhita & Sidhanta( SAM)",
      "category": "GEN",
      "mobile_number": 9978545953,
      "email": "dr.rajatpatel@gmail.com",
      "usn": "SUK220206DR012",
      "type": "Internal"
    },
    // {
    //   "sl_no": 61,
    //   "name": "Rajput Meghasinh",
    //   "college": "AYU/KC",
    //   "department": "Kayachikitsa ( KC)",
    //   "category": "GEN",
    //   "mobile_number": 7600162153,
    //   "email": "rajputmeghasinh9595@gmail.com",
    //   "usn": "SUK220206DR013",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 62,
    //   "name": "RUNGHE MANOJ RAMKRISHNARAO",
    //   "college": "AYU/SAM",
    //   "department": "Samhita & Sidhanta( SAM)",
    //   "category": "GEN",
    //   "mobile_number": 9687748944,
    //   "email": "manojrunghe14@gmail.com",
    //   "usn": "SUK220206DR014",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 63,
    //   "name": "SARIKA GUPTA",
    //   "college": "AYU/SL",
    //   "department": "Shalyatantra( SL)",
    //   "category": "GEN",
    //   "mobile_number": 9596951194,
    //   "email": "drsarikagupta94@gmail.com",
    //   "usn": "SUK220206DR015",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 64,
    //   "name": "Shashank kumar",
    //   "college": "AYU/KC",
    //   "department": "Kayachikitsa ( KC)",
    //   "category": "GEN",
    //   "mobile_number": 9891352112,
    //   "email": "dr.shashank575@gmail.com",
    //   "usn": "SUK220206DR016",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 65,
    //   "name": "YADAV VIJAY GANESH",
    //   "college": "AYU/SL",
    //   "department": "Shalyatantra( SL)",
    //   "category": "SEBC/OBC",
    //   "mobile_number": 9565879717,
    //   "email": "drvijaysid@gmail.com",
    //   "usn": "SUK220206DR017",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 66,
    //   "name": "Dr. Priyadarshini Nambiar",
    //   "college": "Pharmacy",
    //   "department": "Pharmacy",
    //   "category": "GEN",
    //   "mobile_number": 8550015852,
    //   "email": "nambiardrpriya@gmail.com",
    //   "usn": "SUK221106DR001",
    //   "type": "Internal"
    // },
    // {
    //   "sl_no": 67,
    //   "name": "Nayak Nehal\n bahen",
    //   "college": "Pharmacy",
    //   "department": "Pharmacy",
    //   "category": "SEBC/OBC",
    //   "mobile_number": 8866109725,
    //   "email": "nehal.9291@gmail.com",
    //   "usn": "SUK221106DR002",
    //   "type": "Internal"
    // },
    // {
    //   "sl_no": 68,
    //   "name": "PARTH MANILAL PATEL",
    //   "college": "Pharmacy",
    //   "department": "Pharmacy",
    //   "category": "GEN",
    //   "mobile_number": 9879659697,
    //   "email": "parth@memphisvisioncare.com",
    //   "usn": "SUK221106DR003",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 69,
    //   "name": "Patel Bhumika",
    //   "college": "Pharmacy",
    //   "department": "Pharmacy",
    //   "category": "GEN",
    //   "mobile_number": 9712975939,
    //   "email": "bhumipatel3535@yahoo.in",
    //   "usn": "SUK221106DR004",
    //   "type": "Internal"
    // },
    // {
    //   "sl_no": 70,
    //   "name": "PATEL FORAM PARESHKUMAR",
    //   "college": "Pharmacy",
    //   "department": "Pharmacy",
    //   "category": "GEN",
    //   "mobile_number": 9879649697,
    //   "email": "foram1497@gmail.com",
    //   "usn": "SUK221106DR005",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 71,
    //   "name": "PATEL TAPANKUMAR NAVINCHANDRA",
    //   "college": "Pharmacy",
    //   "department": "Pharmacy",
    //   "category": "GEN",
    //   "mobile_number": 9727478823,
    //   "email": "tapankaka2901@gmail.com",
    //   "usn": "SUK221106DR006",
    //   "type": "Internal"
    // },
    // {
    //   "sl_no": 72,
    //   "name": "Tamboli Maheshvari Rajendrakumar",
    //   "college": "Pharmacy",
    //   "department": "Pharmacy",
    //   "category": "GEN",
    //   "mobile_number": 9909013236,
    //   "email": "maheshvaripatel3@gmail.com",
    //   "usn": "SUK221106DR007",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 73,
    //   "name": "ZALA KALUBHA SHIVUBHA",
    //   "college": "Pharmacy",
    //   "department": "Pharmacy",
    //   "category": "GEN",
    //   "mobile_number": 9016185270,
    //   "email": "zalapharma9725@gmail.com",
    //   "usn": "SUK221106DR008",
    //   "type": "Internal"
    // },
    // {
    //   "sl_no": 74,
    //   "name": "Aesha Dipakkumar Nayak",
    //   "college": "Faculty of Science",
    //   "department": "Chemistry",
    //   "category": "GEN",
    //   "mobile_number": 8238795379,
    //   "email": "aesha.nayak@gmail.com",
    //   "usn": "SUK221206DR001",
    //   "type": "Internal"
    // },
    // {
    //   "sl_no": 75,
    //   "name": "BHATI UTTAMSINH HARISINH",
    //   "college": "Faculty of Science",
    //   "department": "Botany",
    //   "category": "SEBC/OBC",
    //   "mobile_number": 9409036538,
    //   "email": "uttamrajvee137@gmail.com",
    //   "usn": "SUK221206DR002",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 76,
    //   "name": "BINDU S PILLAI",
    //   "college": "Faculty of Science",
    //   "department": "Chemistry",
    //   "category": "GEN",
    //   "mobile_number": 9427209706,
    //   "email": "pillaibindu76@gmail.com",
    //   "usn": "SUK221206DR003",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 77,
    //   "name": "DAVE DEEP ANILKUMAR",
    //   "college": "Faculty of Science",
    //   "department": "Microbiology",
    //   "category": "GEN",
    //   "mobile_number": 9898387509,
    //   "email": "daved3350@gmail.com",
    //   "usn": "SUK221206DR004",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 78,
    //   "name": "KANZARIYA BIPINKUMAR NATVARLAL",
    //   "college": "Faculty of Science",
    //   "department": "Microbiology",
    //   "category": "SEBC/OBC",
    //   "mobile_number": 7698959760,
    //   "email": "kanzariyabipin5@gmail.com",
    //   "usn": "SUK221206DR005",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 79,
    //   "name": "KHENI CHANDRESHKUMAR",
    //   "college": "Faculty of Science",
    //   "department": "Maths",
    //   "category": "GEN",
    //   "mobile_number": 9909949994,
    //   "email": "cpkheni@gmail.com",
    //   "usn": "SUK221206DR006",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 80,
    //   "name": "Krushnarajsinh mahendrsinh Chavada",
    //   "college": "Faculty of Science",
    //   "department": "Botany",
    //   "category": "GEN",
    //   "mobile_number": 9427151081,
    //   "email": "krushnarajsinhdanta@gmail.com",
    //   "usn": "SUK221206DR007",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 81,
    //   "name": "Nakum Hitesh Narottam",
    //   "college": "Faculty of Science",
    //   "department": "Botany",
    //   "category": "SEBC/OBC",
    //   "mobile_number": 9904949663,
    //   "email": "hiteshnakum85@gmail.com",
    //   "usn": "SUK221206DR008",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 82,
    //   "name": "NIRAJ TAYDE",
    //   "college": "Faculty of Science",
    //   "department": "Chemistry",
    //   "category": "GEN",
    //   "mobile_number": 9904042617,
    //   "email": "niraj9770@gmail.com",
    //   "usn": "SUK221206DR009",
    //   "type": "Internal"
    // },
    // {
    //   "sl_no": 83,
    //   "name": "Patel Jinalben Rameshbhai",
    //   "college": "Science/botany",
    //   "department": "Botany",
    //   "category": "Gen",
    //   "mobile_number": 9737540564,
    //   "email": "jinal5380@gmail.com",
    //   "usn": "SUK221206DR010",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 84,
    //   "name": "PATEL PARAGKUMAR RAMESHBHAI",
    //   "college": "Faculty of Science",
    //   "department": "Botany",
    //   "category": "OTHER",
    //   "mobile_number": 9824469859,
    //   "email": "Patelparag861995@gmail.com",
    //   "usn": "SUK221206DR011",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 85,
    //   "name": "PATELKULDIPKUMAR ASHOKBHAI",
    //   "college": "Faculty of Science",
    //   "department": "Maths",
    //   "category": "GEN",
    //   "mobile_number": 8141305772,
    //   "email": "kapatelbaps@gmail.com",
    //   "usn": "SUK221206DR012",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 86,
    //   "name": "PRAJAPATI CHIRAGKUMAR DINESHBHAI",
    //   "college": "Faculty of Science",
    //   "department": "Chemistry",
    //   "category": "SEBC/OBC",
    //   "mobile_number": 8141545431,
    //   "email": "chirag28091996@gmail.com",
    //   "usn": "SUK221206DR013",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 87,
    //   "name": "PRAJAPATI MEHULBHAI MAGANBHAI",
    //   "college": "Faculty of Science",
    //   "department": "Chemistry",
    //   "category": "SEBC/OBC",
    //   "mobile_number": 9898894889,
    //   "email": "mehul.prajapati976@gmail.com",
    //   "usn": "SUK221206DR014",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 88,
    //   "name": "PRATIK BHARATKUMAR BAROT",
    //   "college": "Faculty of Science",
    //   "department": "Maths",
    //   "category": "SEBC/OBC",
    //   "mobile_number": 7383193595,
    //   "email": "barot520@gmail.com",
    //   "usn": "SUK221206DR015",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 89,
    //   "name": "PRATIK YOGESHBHAI THAKER",
    //   "college": "Faculty of Science",
    //   "department": "Botany",
    //   "category": "GEN",
    //   "mobile_number": 9727340791,
    //   "email": "pratikthaker1317@gmail.com",
    //   "usn": "SUK221206DR016",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 90,
    //   "name": "RATHOD HANSABEN GOMAJI",
    //   "college": "Faculty of Science",
    //   "department": "Microbiology",
    //   "category": "SC",
    //   "mobile_number": 8347881382,
    //   "email": "hansarathod26791@gmail.com",
    //   "usn": "SUK221206DR017",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 91,
    //   "name": "YUVRAJSINH K VALA",
    //   "college": "Faculty of Science",
    //   "department": "Botany",
    //   "category": "SEBC/OBC",
    //   "mobile_number": 9825906926,
    //   "email": "yuvrajsinh.bt@gmail.com",
    //   "usn": "SUK221206DR018",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 92,
    //   "name": "JAMUNA RAWAT",
    //   "college": "Homeopathy",
    //   "department": "Homeopathy",
    //   "category": "GEN",
    //   "mobile_number": 9408782432,
    //   "email": "dr.rawat.ahmedabad@gmail.com",
    //   "usn": "SUK220606DR001",
    //   "type": "Internal"
    // },
    // {
    //   "sl_no": 93,
    //   "name": "MANIYAR SONALI KIRITBHAI",
    //   "college": "Homeopathy",
    //   "department": "Homeopathy",
    //   "category": "GEN",
    //   "mobile_number": 8154910349,
    //   "email": "sonalimaniyar04@gmail.com",
    //   "usn": "SUK220606DR002",
    //   "type": "Internal"
    // },
    // {
    //   "sl_no": 94,
    //   "name": "GADHVI VISHAL KARSAN",
    //   "college": "Faculty of Engineering and Technology",
    //   "department": "Electrical Engineering",
    //   "category": "SEBC/OBC",
    //   "mobile_number": 7016709409,
    //   "email": "vishalgadhavi90@GMAIL.COM",
    //   "usn": "SUK220506DR001",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 95,
    //   "name": "KAVAR NIRAVKUMAR SAVJIBHAI",
    //   "college": "Faculty of Engineering and Technology",
    //   "department": "Mechanical Engineering",
    //   "category": "GEN",
    //   "mobile_number": 7984014522,
    //   "email": "niravkavar522@gmail.com",
    //   "usn": "SUK220506DR002",
    //   "type": "Internal"
    // },
    // {
    //   "sl_no": 96,
    //   "name": "KIRAN RAMANBHAI PRAJAPATI",
    //   "college": "Faculty of Engineering and Technology",
    //   "department": "Electrical Engineering",
    //   "category": "SEBC/OBC",
    //   "mobile_number": 9925100724,
    //   "email": "srikiranprajapati@gmail.com",
    //   "usn": "SUK220506DR003",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 97,
    //   "name": "Patel Chiragkumar Pravinbhai",
    //   "college": "Faculty of Engineering and Technology",
    //   "department": "Mechanical Engineering",
    //   "category": "GEN",
    //   "mobile_number": 9727118902,
    //   "email": "anjchirag@gmail.com",
    //   "usn": "SUK220506DR004",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 98,
    //   "name": "PATEL SANKETKUMAR KETANBHAI",
    //   "college": "Faculty of Engineering and Technology",
    //   "department": "Mechanical Engineering",
    //   "category": "GEN",
    //   "mobile_number": 9428659098,
    //   "email": "er.sanket4u@gmail.com",
    //   "usn": "SUK220506DR005",
    //   "type": "Internal"
    // },
    // {
    //   "sl_no": 99,
    //   "name": "SHAH KRUNAL PANKAJKUMAR",
    //   "college": "Faculty of Engineering and Technology",
    //   "department": "Mechanical Engineering",
    //   "category": "GEN",
    //   "mobile_number": 9726358369,
    //   "email": "krunal.8387@yahoo.co.in",
    //   "usn": "SUK220506DR006",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 100,
    //   "name": "Chavda jaydipsinh Ajitsinh",
    //   "college": "EDU",
    //   "department": "EDU",
    //   "category": "GEN",
    //   "mobile_number": 9624056051,
    //   "email": "jaydipsinh_chavda@ymail.com",
    //   "usn": "SUK220406DR001",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 101,
    //   "name": "KANABAR MEHUL KIRTIKUMAR",
    //   "college": "EDU",
    //   "department": "EDU",
    //   "category": "GEN",
    //   "mobile_number": 9898353660,
    //   "email": "mehul.kanabar84@gmail.com",
    //   "usn": "SUK220406DR002",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 102,
    //   "name": "NIHARIKA PRABHUBHAI PANCHAL",
    //   "college": "EDU",
    //   "department": "EDU",
    //   "category": "SEBC/OBC",
    //   "mobile_number": 8488956682,
    //   "email": "panchalniharika85@gmail.com",
    //   "usn": "SUK220406DR003",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 103,
    //   "name": "PATEL DIMPALBAHEN PRAVINBHAI",
    //   "college": "EDU",
    //   "department": "EDU",
    //   "category": "GEN",
    //   "mobile_number": 9978723924,
    //   "email": "dipal.22939@gmail.com",
    //   "usn": "SUK220406DR004",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 104,
    //   "name": "PATEL TRUPTIBEN JAYANTILAL",
    //   "college": "EDU",
    //   "department": "EDU",
    //   "category": "GEN",
    //   "mobile_number": 9687047719,
    //   "email": "davul101179@yahoo.com",
    //   "usn": "SUK220406DR005",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 105,
    //   "name": "Swami  Harikrushna Swarupdasji",
    //   "college": "EDU",
    //   "department": "EDU",
    //   "category": "GEN",
    //   "mobile_number": 9898208282,
    //   "email": "shastriswami2@gmail.com",
    //   "usn": "SUK220406DR006",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 106,
    //   "name": "Maulikkumar Bhatt",
    //   "college": "Sanskrit",
    //   "department": "Sanskrit",
    //   "category": "GEN",
    //   "mobile_number": 7048834884,
    //   "email": "bhattmaulik4@gmail.com",
    //   "usn": "SUK220106DR001",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 107,
    //   "name": "PARMAR SONALBEN BACHUBHAI",
    //   "college": "Sanskrit",
    //   "department": "Sanskrit",
    //   "category": "SC",
    //   "mobile_number": 8849498684,
    //   "email": "sonalpiyeja82@gmail.com",
    //   "usn": "SUK220106DR002",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 108,
    //   "name": "RAJYAGURU VEDANGKUMAR",
    //   "college": "Sanskrit",
    //   "department": "Sanskrit",
    //   "category": "GEN",
    //   "mobile_number": 8488842209,
    //   "email": "rajyaguruvedang@gmail.com",
    //   "usn": "SUK220106DR003",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 109,
    //   "name": "Ghosh Soma Dwijodas",
    //   "college": "Commerce",
    //   "department": "Commerce",
    //   "category": "GEN",
    //   "mobile_number": 9879214666,
    //   "email": "soma345@gmail.com",
    //   "usn": "SUK220306DR001",
    //   "type": "External"
    // },
    // {
    //   "sl_no": 110,
    //   "name": "JITENDRA KUMAR",
    //   "college": "Computer Science", 
    //   "department": "Computer Science",
    //   "category": "SEBC/OBC",
    //   "mobile_number": 8445559015,
    //   "email": "jitendrak301@gmail.com",
    //   "usn": "SUK220706DR001",
    //   "type": "External"
    // }

    { 
      "sl_no":1
      ,"name":"Vangari Nitin Rajubhai",
      "college":"Faculty of Arts & Humanities",
      "category":"Gen",
      "mobile":6355300163,
      "email":"nitinvangari1988@gmail.com",
      "department":"ARTS & HUMANITIES - Ph.D",
      "usn":"PSUK230106DR011", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":2
      ,"name":"Hiren Mahadevbhai Bhatt",
      "college":"Faculty of Arts & Humanities",
      "category":"GEN",
      "mobile":8511463573,
      "email":"Hirenmbhatt59@gmail.com",
      "department":"ARTS & HUMANITIES - Ph.D",
      "usn":"PSUK230106DR012", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":3
      ,"name":"Sadhu viveksagardasji guru uttampriya dasji",
      "college":"Faculty of Arts & Humanities",
      "category":"Gen",
      "mobile":9978067304,
      "email":"Vivekdhran@gmail.com",
      "department":"ARTS & HUMANITIES - Ph.D",
      "usn":"PSUK230106DR013", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":4
      ,"name":"NIRAV THAKAR",
      "college":"Faculty of Arts & Humanities",
      "category":"Gen",
      "mobile":9574796811,
      "email":"nrvthakar@gmail.com",
      "department":"ARTS & HUMANITIES - Ph.D",
      "usn":"PSUK230106DR014", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":5
      ,"name":"Sadhu KunjavihariDasji",
      "college":"Faculty of Arts & Humanities",
      "category":"Gen",
      "mobile":9099097778,
      "email":"kvswami@sgvp.org",
      "department":"ARTS & HUMANITIES - Ph.D",
      "usn":"PSUK230106DR015", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":6
      ,"name":"THUMMAR RAJESHWARI BHARATBHAI",
      "college":"Faculty of Arts & Humanities",
      "category":"Gen",
      "mobile":6354247988,
      "email":"rajeshwarithummar76@gmail.com",
      "department":"ARTS & HUMANITIES - Ph.D",
      "usn":"PSUK230106DR016", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":7
      ,"name":"Jayswal Vrundan Pareshkumar",
      "college":"Shree Swaminarayan Ayurvdic College",
      "category":"Gen",
      "mobile":8460781520,
      "email":"dr.vrundanjayswal@gmail.com",
      "department":"AYURVEDA - Ph.D",
      "usn":"PSUK230206DR023", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":8
      ,"name":"Dr. Merin Jose",
      "college":"Shree Swaminarayan Ayurvdic College",
      "category":"Gen",
      "mobile":9496884141,
      "email":"merinjoze@gmail.com",
      "department":"AYURVEDA - Ph.D",
      "usn":"PSUK230206DR024", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":9
      ,"name":"PATEL SHRUTI RAJESHBHAI",
      "college":"Shree Swaminarayan Ayurvdic College",
      "category":"Gen",
      "mobile":9428596354,
      "email":"drshrutipatel89@gmail.com",
      "department":"AYURVEDA - Ph.D",
      "usn":"PSUK230206DR025", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":10,
      "name":"Patel Nidhi Prakashbhai",
      "college":"Shree Swaminarayan Ayurvdic College",
      "category":"Gen",
      "mobile":9979108315,
      "email":"nidhi5066patel@gmail.com",
      "department":"AYURVEDA - Ph.D",
      "usn":"PSUK230206DR026", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":11,
      "name":"KANERIA VIRALKUMAR PRAVINBHAI",
      "college":"Shree Swaminarayan Ayurvdic College",
      "category":"Gen",
      "mobile":9428118833,
      "email":"dr.viralkaneria@gmail.com",
      "department":"AYURVEDA - Ph.D",
      "usn":"PSUK230206DR027", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":12,
      "name":"PARMAR NASHIR NURMAHMAD",
      "college":"Shree Swaminarayan Ayurvdic College",
      "category":"SeBC/OBC",
      "mobile":9429878600,
      "email":"nashirayur@gmail.com",
      "department":"AYURVEDA - Ph.D",
      "usn":"PSUK230206DR028", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":13,
      "name":"LALWANI GIRISH INDRAKUMAR",
      "college":"Shree Swaminarayan Ayurvdic College",
      "category":"Gen",
      "mobile":9925291325,
      "email":"girishlalwani1510@gmail.com",
      "department":"AYURVEDA - Ph.D",
      "usn":"PSUK230206DR029", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":14,
      "name":"ASNANI KIRAN GOVINDRAM (KOMAL)",
      "college":"Faculty of Commerce & management",
      "category":"GEN",
      "mobile":9913677936,
      "email":"KIRANASNANI85@GMAIL.COM",
      "department":"COMMERCE - Ph.D",
      "usn":"PSUK230306DR010", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":15,
      "name":"LALWANI HEENA GOPALDAS",
      "college":"Faculty of Commerce & management",
      "category":"GEN",
      "mobile":7984694824,
      "email":"heena.lalwani619@gmail.com",
      "department":"COMMERCE - Ph.D",
      "usn":"PSUK230306DR011", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":16,
      "name":"PATEL ANJALIBEN PANKAJBHAI",
      "college":"Faculty of Commerce & management",
      "category":"GEN",
      "mobile":8347712593,
      "email":"patelanjali7057@gmail.com",
      "department":"COMMERCE - Ph.D",
      "usn":"PSUK230306DR012", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":17,
      "name":"Doshi Shivani Tarunbhai",
      "college":"Faculty of Commerce & management",
      "category":"Gen",
      "mobile":9725091425,
      "email":"shivanidoshi1992@gmail.com",
      "department":"COMMERCE - Ph.D",
      "usn":"PSUK230306DR013", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":18,
      "name":"PANDYA NIRZA SHAILENDRA",
      "college":"Faculty of Commerce & management",
      "category":"Gen",
      "mobile":9409664575,
      "email":"nirzapandya1990@gmail.com",
      "department":"COMMERCE - Ph.D",
      "usn":"PSUK230306DR014", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":19,
      "name":"PRAJAPATI NIMISHA PARSHOTAMBHAI",
      "college":"Faculty of Commerce & management",
      "category":"SEBC/OBC",
      "mobile":7698462933,
      "email":"nimishaprajapati4122000@gmail.com",
      "department":"COMMERCE - Ph.D",
      "usn":"PSUK230306DR015", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":20,
      "name":"Nisha S Makwana",
      "college":"Faculty of Commerce & management",
      "category":"SC",
      "mobile":9586253559,
      "email":"nishamakwana26@gmail.com",
      "department":"COMMERCE - Ph.D",
      "usn":"PSUK230306DR016", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":21,
      "name":"GOHIL SHIVANI BHARATSINH",
      "college":"Faculty of Commerce & management",
      "category":"Gen",
      "mobile":9724905363,
      "email":"shivani.gohil123@gmail.com",
      "department":"COMMERCE - Ph.D",
      "usn":"PSUK230306DR017", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":22,
      "name":"Kevin Rameshbhai Patel",
      "college":"Faculty of Commerce & management",
      "category":"Gen",
      "mobile":8401000000,
      "email":"kvnpatel1615@gmail.com",
      "department":"COMMERCE - Ph.D",
      "usn":"PSUK230306DR018", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":23,
      "name":"Shweta Singh Subedar",
      "college":"Faculty of Commerce & management",
      "category":"Gen",
      "mobile":9265116837,
      "email":"singh.shweta191991@gmail.com",
      "department":"COMMERCE - Ph.D",
      "usn":"PSUK230306DR019", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":24,
      "name":"Nidhi Bhikhubhai Patel",
      "college":"Faculty of IT & Computer Science",
      "category":"SEBC/OBC",
      "mobile":8690869656,
      "email":"nidhipatel1462@gmail.com",
      "department":"IT AND COMPUTER SCIENCE - Ph.D",
      "usn":"PSUK230706DR006", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":25,
      "name":"SUTHAR VANITABEN HASMUKHBHAI",
      "college":"Faculty of IT & Computer Science",
      "category":"gen",
      "mobile":9099930247,
      "email":"vanitamistry15184@gmail.com",
      "department":"IT AND COMPUTER SCIENCE - Ph.D",
      "usn":"PSUK230706DR007", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":26,
      "name":"Patel Poonam Bharatbhai",
      "college":"Faculty Of Education",
      "category":"GEN",
      "mobile":9428603754,
      "email":"poonam7813@gmail.com",
      "department":"EDUCATION - Ph.D",
      "usn":"PSUK230406DR015", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":27,
      "name":"GAJJAR VARSHA MANEKLAL",
      "college":"Faculty Of Education",
      "category":"SEBC/OBC",
      "mobile":9265033380,
      "email":"gajjarvarsha77800@gmail.com",
      "department":"EDUCATION - Ph.D",
      "usn":"PSUK230406DR016", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":28,
      "name":"RAJATIYA SHANTIBEN JIVABHAI",
      "college":"Faculty Of Education",
      "category":"SEBC/OBC",
      "mobile":8140878818,
      "email":"shantirajathiyashantirajathiya@gmail.com",
      "department":"EDUCATION - Ph.D",
      "usn":"PSUK230406DR017", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":29,
      "name":"PATEL ANJANA NARAYANBHAI",
      "college":"Faculty Of Education",
      "category":"Gen",
      "mobile":9408781785,
      "email":"patelanjana1969@gmail.com",
      "department":"EDUCATION - Ph.D",
      "usn":"PSUK230406DR018", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":30,
      "name":"MODI BHUMIKA PRAVINCHANDRA",
      "college":"Faculty Of Education",
      "category":"SEBC/OBC",
      "mobile":7777906952,
      "email":"bhumikamodi53@gmail.com",
      "department":"EDUCATION - Ph.D",
      "usn":"PSUK230406DR019", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":31,
      "name":"SHARMA ROHANKUMAR SURESHCHANDRA",
      "college":"Faculty Of Education",
      "category":"GEN",
      "mobile":9512637872,
      "email":"SR59271@GMAIL.COM",
      "department":"EDUCATION - Ph.D",
      "usn":"PSUK230406DR020", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":32,
      "name":"Truptiben Ashvinbhai Thakkar",
      "college":"Swaminarayan College of Engineering & Technology",
      "category":"gen",
      "mobile":7600511821,
      "email":"truptithakkar097@gmail.com",
      "department":"ENGINEERING - Ph.D",
      "usn":"PSUK230506DR007", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":33,
      "name":"KHATRI DHAVAL ASHWINKUMAR",
      "college":"Swaminarayan College of Engineering & Technology",
      "category":"GEN",
      "mobile":9974013803,
      "email":"aitdhaval@gmail.com",
      "department":"ENGINEERING - Ph.D",
      "usn":"PSUK230506DR008", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":34,
      "name":"RANAVADIYA ANJUMANBANU ABDULKADAR",
      "college":"Swaminarayan College of Engineering & Technology",
      "category":"Gen",
      "mobile":9979548277,
      "email":"anju.ranavadiya@gmail.com",
      "department":"ENGINEERING - Ph.D",
      "usn":"PSUK230506DR009", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":35,
      "name":"Patel Chaitanya Jayantibhai",
      "college":"Faculty Of Law",
      "category":"GEN",
      "mobile":8238197339,
      "email":"advchaitanyaj@gmail.com",
      "department":"LAW - Ph.D",
      "usn":"PSUK230806DR068", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":36,
      "name":"JAI UPADHYAY",
      "college":"Faculty Of Law",
      "category":"GEN",
      "mobile":8238197339,
      "email":"advchaitanyaj@gmail.com",
      "department":"LAW - Ph.D",
      "usn":"PSUK230806DR069", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":37,
      "name":"Komalben Bhikhabhai Prajapati",
      "college":"Faculty Of Law",
      "category":"SEBC/OBC",
      "mobile":7096259794,
      "email":"kbprajapatiadvocate11@gmail.com",
      "department":"LAW - Ph.D",
      "usn":"PSUK230806DR070", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":38,
      "name":"KAJALBEN MUKESHBHAI",
      "college":"Faculty Of Law",
      "category":"GEN",
      "mobile":7600804049,
      "email":"kajal.lawyer21@gmail.com",
      "department":"LAW - Ph.D",
      "usn":"PSUK230806DR071", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":39,
      "name":"Malvi Anand Agrawal",
      "college":"Faculty Of Law",
      "category":"GEN",
      "mobile":9016211216,
      "email":"malvi_agrawal@yahoo.com",
      "department":"LAW - Ph.D",
      "usn":"PSUK230806DR072", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":40,
      "name":"Kavi Maheshkumar Patel",
      "college":"Faculty Of Law",
      "category":"GEN",
      "mobile":9537918650,
      "email":"kkavip@gmail.com",
      "department":"LAW - Ph.D",
      "usn":"PSUK230806DR073", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":41,
      "name":"Kishankumar Ramphal Maurya",
      "college":"Faculty Of Law",
      "category":"GEN",
      "mobile":9737770123,
      "email":"advkishanmaurya@gmail.com",
      "department":"LAW - Ph.D",
      "usn":"PSUK230806DR074", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":42,
      "name":"JOSHI MEGHA SHARADKUMAR",
      "college":"Faculty Of Law",
      "category":"Gen",
      "mobile":9016966897,
      "email":"meghajoshi1005@gmail.com",
      "department":"LAW - Ph.D",
      "usn":"PSUK230806DR075", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":43,
      "name":"KEYUR HARESHKUMAR SHAH",
      "college":"Faculty Of Law",
      "category":"Gen",
      "mobile":8485913422,
      "email":"keyur.shah953@gmail.com",
      "department":"LAW - Ph.D",
      "usn":"PSUK230806DR076", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":44,
      "name":"TRIVEDI ANURADHA ANILBHAI",
      "college":"Faculty Of Law",
      "category":"Gen",
      "mobile":9712435845,
      "email":"anuradha4490@gmail.com",
      "department":"LAW - Ph.D",
      "usn":"PSUK230806DR077", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":45,
      "name":"TRIVEDI ADITYA ANILBHAI",
      "college":"Faculty Of Law",
      "category":"Gen",
      "mobile":8238108967,
      "email":"trivedi.aaditya27@gmail.com",
      "department":"LAW - Ph.D",
      "usn":"PSUK230806DR078", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":46,
      "name":"PATEL VANDANABEN PRAVINKUMAR",
      "college":"Faculty Of Law",
      "category":"Gen",
      "mobile":8469215525,
      "email":"vandu5525@gmail.com",
      "department":"LAW - Ph.D",
      "usn":"PSUK230806DR079", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":47,
      "name":"SHAH PRIYA SHANTILAL",
      "college":"Faculty Of Law",
      "category":"Gen",
      "mobile":9376611717,
      "email":"priyashah1588@gmail.com",
      "department":"LAW - Ph.D",
      "usn":"PSUK230806DR080", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":48,
      "name":"PANDYA VIMAL LABHSHANKAR",
      "college":"Faculty Of Law",
      "category":"Gen",
      "mobile":9979914049,
      "email":"vl.pandya1986@gmail.com",
      "department":"LAW - Ph.D",
      "usn":"PSUK230806DR081", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":49,
      "name":"SHAKTISINH VIKRAMSINH PARMAR",
      "college":"Faculty Of Law",
      "category":"Gen",
      "mobile":8780108019,
      "email":"SHAKTISINHP0@GMAIL.COM",
      "department":"LAW - Ph.D",
      "usn":"PSUK230806DR082", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":50,
      "name":"Sagar Dangar",
      "college":"Faculty Of Law",
      "category":"SEBC/OBC",
      "mobile":9409850000,
      "email":"adv.sagar.h.dangar@gmail.com",
      "department":"LAW - Ph.D",
      "usn":"PSUK230806DR083", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":51,
      "name":"SAFWANKHAN MAHAMMADHANIF SINDHI",
      "college":"Faculty Of Law",
      "category":"SEBC/OBC",
      "mobile":9429770077,
      "email":"safwansindhi@gmail.com",
      "department":"LAW - Ph.D",
      "usn":"PSUK230806DR084", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":52,
      "name":"ATIFKHAN MAHAMMADHANIF SINDHI",
      "college":"Faculty Of Law",
      "category":"SEBC/OBC",
      "mobile":9429980786,
      "email":"atifsindhi97@gmail.com",
      "department":"LAW - Ph.D",
      "usn":"PSUK230806DR085", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":53,
      "name":"MOTLA ASHVINI HARISHBHAI",
      "college":"Faculty Of Law",
      "category":"Gen",
      "mobile":8141333187,
      "email":"ashwinimotla27@gmail.com",
      "department":"LAW - Ph.D",
      "usn":"PSUK230806DR086", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":54,
      "name":"DHRANGU MERAMAN ARJAN",
      "college":"Faculty Of Law",
      "category":"SEBC/OBC",
      "mobile":8980934344,
      "email":"meramand65@gmail.com",
      "department":"LAW - Ph.D",
      "usn":"PSUK230806DR087", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":55,
      "name":"Barot Sandip Kishorkumar",
      "college":"Faculty Of Law",
      "category":"SEBC/OBC",
      "mobile":9898592827,
      "email":"sandip.barot20@gmail.com",
      "department":"LAW - Ph.D",
      "usn":"PSUK230806DR088", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":56,
      "name":"MANGALAM DUSHYANTBHAI JOSHI",
      "college":"Faculty Of Law",
      "category":"Gen",
      "mobile":9016300201,
      "email":"mangalam.advocate@gmail.com",
      "department":"LAW - Ph.D",
      "usn":"PSUK230806DR089", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":57,
      "name":"KISHAN CHAKWAWALA",
      "college":"Faculty Of Law",
      "category":"SEBC/OBC",
      "mobile":9825047071,
      "email":"ADV.KRCHAKWAWALA@GMAIL.COM",
      "department":"LAW - Ph.D",
      "usn":"PSUK230806DR090", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":58,
      "name":"MANAN DOSHI",
      "college":"Faculty Of Law",
      "category":"SEBC/OBC",
      "mobile":9825000622,
      "email":"manandoshi20@gmail.com",
      "department":"LAW - Ph.D",
      "usn":"PSUK230806DR091", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":59,
      "name":"DESAI SIDDHARTH JAGDISHBHAI",
      "college":"Faculty Of Law",
      "category":"Gen",
      "mobile":9898580598,
      "email":"SIDDESAI4U@GMAIL.COM",
      "department":"LAW - Ph.D",
      "usn":"PSUK230806DR092", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":60,
      "name":"DAKSHINI PIYUSHKUMAR SAGALCHAND",
      "college":"Faculty Of Law",
      "category":"SEBC/OBC",
      "mobile":9979316566,
      "email":"piyushdakshini007@gmail.com",
      "department":"LAW - Ph.D",
      "usn":"PSUK230806DR093", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":61,
      "name":"MANJOTHI MOHSIN ABDULKADAR",
      "college":"Faculty Of Law",
      "category":"GEN",
      "mobile":9727322827,
      "email":"mkmanjothi83@gmail.com",
      "department":"LAW - Ph.D",
      "usn":"PSUK230806DR094", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":62,
      "name":"TEJALBEN NAGJIBHAI ROHIT",
      "college":"Faculty Of Law",
      "category":"SEBC/OBC",
      "mobile":7984865087,
      "email":"tejalrohit2012@gmail.com",
      "department":"LAW - Ph.D",
      "usn":"PSUK230806DR095", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":63,
      "name":"CHAUDHARY SOHIL LALJIBHAI",
      "college":"Faculty Of Law",
      "category":"SC",
      "mobile":9904168872,
      "email":"sohilchaudhary2112@gmai.com",
      "department":"LAW - Ph.D",
      "usn":"PSUK230806DR096", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":64,
      "name":"DUBEY AMITKUMAR SHIVKUMAR",
      "college":"Faculty Of Law",
      "category":"general",
      "mobile":9099799341,
      "email":"dramitdubey2@gmail.com",
      "department":"LAW - Ph.D",
      "usn":"PSUK230806DR097", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":65,
      "name":"JAGAD YASH PRAFULBHAI",
      "college":"Faculty Of Law",
      "category":"Gen",
      "mobile":9924271646,
      "email":"jagadyash6@gmail.com",
      "department":"LAW - Ph.D",
      "usn":"PSUK230806DR098", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":66,
      "name":"Pabari Jignesh Kishorbhai",
      "college":"Faculty Of Law",
      "category":"GEN",
      "mobile":8866986601,
      "email":"8866986601j@gmail.com",
      "department":"LAW - Ph.D",
      "usn":"PSUK230806DR099", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":67,
      "name":"TAKALKAR KUSH RAMCHANDRA",
      "college":"Faculty Of Law",
      "category":"GEN",
      "mobile":7069700600,
      "email":"KUSHTAKALKAR@GMAIL.COM",
      "department":"LAW - Ph.D",
      "usn":"PSUK230806DR100", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":68,
      "name":"Rachana",
      "college":"Swaminarayan Institute of Medical Science",
      "category":"SEBC/OBC",
      "mobile":9950166621,
      "email":"rggehlot6621@gmail.com",
      "department":"MEDICINE - Ph.D",
      "usn":"PSUK231306DR001", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":69,
      "name":"AKSHITA CHOUDHARY",
      "college":"Swaminarayan Institute of Medical Science",
      "category":"",
      "mobile":8875935201,
      "email":"akshitachoudhary199@gmail.com",
      "department":"MEDICINE - Ph.D",
      "usn":"PSUK231306DR002", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":70,
      "name":"Akanksha Mehta",
      "college":"Swaminarayan Institute of Medical Science",
      "category":"",
      "mobile":7980225129,
      "email":"m.akanksha1986@gmail.com",
      "department":"MEDICINE - Ph.D",
      "usn":"PSUK231306DR003", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":71,
      "name":"Dholakia Urja Jaykarbhai",
      "college":"Swaminarayan Institute of Medical Science",
      "category":"Gen",
      "mobile":8141019501,
      "email":"rathodurja@gmail.com",
      "department":"MEDICINE - Ph.D",
      "usn":"PSUK231306DR004", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":72,
      "name":"BISWAJIT BIDHANCHANDRA HAZRA",
      "college":"Shree Swaminarayan  College of Pharmacy",
      "category":"GEN",
      "mobile":9662458299,
      "email":"biswajit_2159@rediffmail.com",
      "department":"PHARMACY - Ph.D",
      "usn":"PSUK231106DR011", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":73,
      "name":"RATHOD KETAN SHANTILALA",
      "college":"Shree Swaminarayan  College of Pharmacy",
      "category":"SC",
      "mobile":9978298645,
      "email":"ketanrathod2012@gmail.com",
      "department":"PHARMACY - Ph.D",
      "usn":"PSUK231106DR012", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":74,
      "name":"Patel Rakeshkumar Pravinbhai",
      "college":"Shree Swaminarayan  College of Pharmacy",
      "category":"GEN",
      "mobile":9924173206,
      "email":"patelrakesh161984@gmail.com",
      "department":"PHARMACY - Ph.D",
      "usn":"PSUK231106DR013", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":75,
      "name":"Zalak Bhupendrakumar Patel",
      "college":"Shree Swaminarayan  College of Pharmacy",
      "category":"GEN",
      "mobile":9898507464,
      "email":"zalakleuva111@gmail.com",
      "department":"PHARMACY - Ph.D",
      "usn":"PSUK231106DR014", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":76,
      "name":"Patel Jahanviben chandrakant",
      "college":"Shree Swaminarayan  College of Pharmacy",
      "category":"Gen",
      "mobile":9662543408,
      "email":"jahanvi.patel@sal.edu.in",
      "department":"PHARMACY - Ph.D",
      "usn":"PSUK231106DR015", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":77,
      "name":"MS. ANU Viral PATEL",
      "college":"Shree Swaminarayan  College of Pharmacy",
      "category":"Gen",
      "mobile":9067545410,
      "email":"anu.patel@sal.edu.in",
      "department":"PHARMACY - Ph.D",
      "usn":"PSUK231106DR016", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":78,
      "name":"vidhi vishnubhai patel",
      "college":"Shree Swaminarayan  College of Pharmacy",
      "category":"Gen",
      "mobile":7041608494,
      "email":"vidhi1594@gmail.com",
      "department":"PHARMACY - Ph.D",
      "usn":"PSUK231106DR017", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":79,
      "name":"PATEL FORAMBEN RAJUBHAI",
      "college":"Shree Swaminarayan  College of Pharmacy",
      "category":"Gen",
      "mobile":7359105164,
      "email":"forampatelr293@gmail.com",
      "department":"PHARMACY - Ph.D",
      "usn":"PSUK231106DR018", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":80,
      "name":"Thakker Krina Sureshkumar",
      "college":"Shree Swaminarayan  College of Pharmacy",
      "category":"Gen",
      "mobile":8866122714,
      "email":"krinathakkar94@gmail.com",
      "department":"PHARMACY - Ph.D",
      "usn":"PSUK231106DR019", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":81,
      "name":"Soni Mansi Mukeshkumar",
      "college":"Shree Swaminarayan  College of Pharmacy",
      "category":"Gen",
      "mobile":9662709699,
      "email":"mansisoni256@gmail.com",
      "department":"PHARMACY - Ph.D",
      "usn":"PSUK231106DR020", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":82,
      "name":"GAIN SNEHAKUMARI SONIRAVBHAI",
      "college":"Faculty of Science",
      "category":"ST",
      "mobile":9408579537,
      "email":"snehagain2000@gmail.com",
      "department":"SCIENCE - Ph.D",
      "usn":"PSUK231206DR028", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":83,
      "name":"PRAJAPATI HARSHILKUMAR RAJESHBHAI",
      "college":"Faculty of Science",
      "category":"SEBC/OBC",
      "mobile":7862922496,
      "email":"pharshil1999@gmail.com",
      "department":"SCIENCE - Ph.D",
      "usn":"PSUK231206DR029", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":84,
      "name":"Sonu rao",
      "college":"Faculty of Science",
      "category":"SEBC/OBC",
      "mobile":8290994102,
      "email":"Sonubaisa24@gmail.com",
      "department":"SCIENCE - Ph.D",
      "usn":"PSUK231206DR030", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":85,
      "name":"SANDEEP KUMAR",
      "college":"Faculty of Science",
      "category":"SEBC/OBC",
      "mobile":9509877136,
      "email":"SKBANJARA21@GMAIL.COM",
      "department":"SCIENCE - Ph.D",
      "usn":"PSUK231206DR031", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":86,
      "name":"Kruti Kanakkumar Doshi",
      "college":"Faculty of Science",
      "category":"Gen",
      "mobile":9408069555,
      "email":"krutidoshi98@gmail.com",
      "department":"SCIENCE - Ph.D",
      "usn":"PSUK231206DR032", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":87,
      "name":"JOGI JULLY CHANDRESHBHAI",
      "college":"Faculty of Science",
      "category":"Gen",
      "mobile":9409770461,
      "email":"jullyjogi36@gmail.com",
      "department":"SCIENCE - Ph.D",
      "usn":"PSUK231206DR033", 
      "type":"PhD Scholar"
      },
      { 
      "sl_no":88,
      "name":"VAIDYA NIMESHKUMAR PRAKASHCHANDRA",
      "college":"Swaminarayan College of Engineering & Technology",
      "category":"Gen",
      "mobile":9409135209,
      "email":"nimesh.vaidya11@gmail.com",
      "department":"ENGINEERING - Ph.D",
      "usn":"PSUK230506DR010", 
      "type":"PhD Scholar"
      },
   
    
  ]