import React from "react";
import axios from "axios";
import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { MILESTONES } from "../../../Utils/InfoUploadingConst";
import "./Swaminarayan.scss";

function Milestones() {
  const [data, setData] = useState([]);

  const navigate = useNavigate();

  const getData = async () => {
    const config = {
      method: "get",
      url: MILESTONES,
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios(config);
      // res.data.data.sort((a, b) => b.id - a.id);
      res.data.data.sort((a, b) => new Date(b.date) - new Date(a.date));
      setData(res.data.data);
      console.log("data -", res.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);
    const [month, day, year] = formattedDate.split(" ");
    return `${year}, ${month} ${day.replace(",", "")}`;
  };

  return (
    <div className="Milestones">
      <>
        <h1 className="text-center">Milestones</h1>
        {/* <div className="timeline">
          <div className="  cnt left">
            <div className="date">2022, April 7</div>
            <i className="icon fa fa-home" />
            <div className="content">
              <p>
                Governor assent received on the Gujarat Private Universities
                Bill 2022
              </p>{" "}
              <br />
              <p>
                Gazette Notification issued for Gujarat Private Universities
                (Amendment) Act 2022 (Guj.7 of 2022)
              </p>
            </div>
          </div>
          <div className=" cnt right">
            <div className="date">2022, April 11</div>
            <i className="icon fa fa-gift" />
            <div className="content">
              <p>
                Prof (Dr.) M. S. Rao, appointed as the first Provost of the
                University
              </p>
            </div>
          </div>
          <div className="  cnt left">
            <div className="date">2022, May 20</div>
            <i className="icon fa fa-home" />
            <div className="content">
              <p>
                Prof (Dr.)M. S. Rao assumes the charge of the first Provost{" "}
              </p>{" "}
              <br />
            </div>
          </div>
          <div className=" cnt right">
            <div className="date">2022, June 1</div>
            <i className="icon fa fa-gift" />
            <div className="content">
              <p>
                Gujarat Government Gazette (Extraordinary) Notification No.
                GH/SH/15/EPU/2022/2803/KH-1 dated 1st June 2022 under sub
                section (2) of section 1 of the Gujarat Private Universities
                (Amendment) Act, 2022 (Guj.7 of 2022).
              </p>
            </div>
          </div>
          <div className="  cnt left">
            <div className="date">2022, June 7</div>
            <i className="icon fa fa-home" />
            <div className="content">
              <p>
                First Governing Body constituted as per Gujarat Private
                Universities Act, 2009
              </p>{" "}
              <br />
            </div>
          </div>
          <div className=" cnt right">
            <div className="date">2022, June 8</div>
            <i className="icon fa fa-gift" />
            <div className="content">
              <p>
                First Board of Management constituted as per Gujarat Private
                Universities Act, 2009
              </p>
            </div>
          </div>
          <div className="  cnt left">
            <div className="date">2022, June 13</div>
            <i className="icon fa fa-home" />
            <div className="content">
              <p>
                First Academic Council constituted as per Gujarat Private
                Universities Act, 2009.
              </p>{" "}
              <br />
            </div>
          </div>
          <div className=" cnt right">
            <div className="date">2022, Aug 27</div>
            <i className="icon fa fa-gift" />
            <div className="content">
              <p>
                1st Meeting of the Academic Council held at Conference Room of
                Swaminarayan University, Kalol
              </p>
            </div>
          </div>
        </div> */}

        <div className="timeline">
          {data?.map((milestone, index) => (
            <div
              key={milestone.id}
              className={`cnt ${index % 2 === 0 ? "left" : "right"}`}
            >
              <div className="date">{formatDate(milestone.date)}</div>
              <i
                className={`icon fa ${index % 2 === 0 ? "fa-home" : "fa-gift"}`}
              />
              <div className="content">
                <p>{milestone.description}</p>
              </div>
            </div>
          ))}
        </div>
      </>
    </div>
  );
}

export default Milestones;
