import React from "react";
import { Link } from "react-router-dom";

// Image Urls
//Classroom
const classroom1 = "../assets/science/Infrastructure/classroom-1.jpg";
const classroom2 = "../assets/science/Infrastructure/classroom-2.jpg";
const classroom3 = "../assets/science/Infrastructure/classroom-3.jpg";
const classroom4 = "../assets/science/Infrastructure/classroom-4.jpg";

//Analytic Lab
const AnalyticLab1 = "/assets/science/infrastructure/analytic-lab-1.jpg";
const AnalyticLab2 = "/assets/science/infrastructure/analytic-lab-2.jpg";

//Biology Lab
const BiologyLab1 = "/assets/science/infrastructure/biology-lab-1.jpg";

//Botany Lab
const BotanyLab1 = "/assets/science/infrastructure/botany-lab-1.jpg";

//Chemistry Lab
const ChemistryLab1 = "/assets/science/infrastructure/chemistry-lab-1.jpg";
const ChemistryLab2 = "/assets/science/infrastructure/chemistry-lab-2.jpg";
const ChemistryLab3 = "/assets/science/infrastructure/chemistry-lab-3.jpg";

//Microbilogy Lab
const MicrobiologyLab1 =
  "/assets/science/infrastructure/microbiology-lab-1.jpg";

//Physics Lab
const PhysicsLab1 = "/assets/science/infrastructure/physics-lab-1.jpg";
const PhysicsLab2 = "/assets/science/infrastructure/physics-lab-2.jpg";

//Zoology Lab
const ZoologyLab1 = "/assets/science/infrastructure/chemistry-lab-1.jpg";

function InfrastructureAyurveda() {
  return (
    <div className="ResidentialFacilities">
      <>
        <div className="campus-life-area pt-100 pb-70">
          <div className="container">
            <h3 className="text-center mb-4 ">Infrastructure Details</h3>
            <p className="text-center">
              Our college is well equipped with the state-of-the-art facilities
              for classrooms and laboratories in all departments.
            </p>
          </div>
        </div>

        <div className="row justify-content-center mt-5">
          <h3 className="text-center mb-4 ">Classrooms</h3>
          <div className="col-lg-4 col-md-6">
            <div className="single-campus-card">
              <div className="img">
                <a href="">
                  <img src={classroom1} alt="Image" />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single-campus-card">
              <div className="img">
                <a href="">
                  <img src={classroom2} alt="Image" />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single-campus-card">
              <div className="img">
                <a href="">
                  <img src={classroom3} alt="Image" />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single-campus-card">
              <div className="img">
                <a href="">
                  <img src={classroom4} alt="Image" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

export default InfrastructureAyurveda;
