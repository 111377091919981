import React from 'react'
import { Link } from 'react-router-dom'

function HostelFacilities() {
  return (
    <div className='HostelFacilities'>
        <>
  <div className="campus-life-area pt-100 pb-70">
    <div className="container-fluid">
      <h3 className="text-center mb-4 ">Hostel Facilities</h3>
      <p className="text-center">
    The University takes great pride in providing comfortable and secure hostel facilities for both boys and girls. We understand the importance of a conducive living environment that fosters academic growth, personal development, and a sense of community. Our hostels are designed to cater to the unique needs of both genders, ensuring a safe and inclusive atmosphere for all residents.
      </p>
    </div>
  </div>
  <div className="row justify-content-center mt-2">
    <div className="col-lg-4 col-md-6">
        <h3>Girls Hostel</h3>
     <table className="table table-bordered text-center">
        <thead className='bg-danger text-light p-3'>
            <tr>
                <th>Students</th>
                <th>Facility</th>
                <th>Fees/Annum</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>3 Students</td>
                <td rowSpan="3">Non AC Room, <br/> Attached Bathroom</td>
                <td>₹ 85,000/-</td>
            </tr>
            <tr>
                <td>4 Students</td>
               
                <td>₹ 80,000/-</td>
            </tr>
            <tr>
                <td>8 Students</td>
               
                <td>₹ 70,000/-</td>
            </tr>
        </tbody>
     </table>
    </div>
    <div className="col-lg-4 col-md-6">
        <h3>Boys Hostel</h3>
     <table className="table table-bordered text-center">
        <thead className='bg-danger text-light'>
            <tr>
                <th>Students</th>
                <th>Facility</th>
                <th>Fees/Annum</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>5 Students</td>
                <td rowSpan="3">Non AC Room, <br/> Attached Bathroom</td>
                <td>₹ 70,000/-</td>
            </tr>
            
        </tbody>
     </table>
    </div>

    <div className="col-md-12 mt-3 mb-5">
        <h4 className='text-center'>Transportation Facility From Ahmedabad & Gandhinagar <Link className='text-danger' to="/Transportation">Click here</Link> Check the Rout</h4>
    </div>
  </div>
</>

    </div>
  )
}

export default HostelFacilities