import React from "react";

export default function CommerceCourses() {
  return (
    <div>
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-md-12">
            <p>
              <span style={{ fontSize: "18pt" }}>
                <strong>Commerce Courses</strong>
              </span>
              <br />
            </p>

            <p>
              <strong>All Courses Details:</strong>
              <br />
            </p>
            <table
              className="table table-bordered"
              style={{ borderCollapse: "collapse", width: "100%", height: 173 }}
              border={1}
            >
              <tbody>
                <tr style={{ height: 20 }}>
                  <td>
                    <strong>Sr No</strong>
                    <br />
                  </td>
                  <td>
                    <strong>Course Name</strong>
                    <br />
                  </td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td>
                    1
                    <br />
                  </td>
                  <td>
                    B.COM (Hons)
                    <br />
                  </td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td>
                    2
                    <br />
                  </td>
                  <td>
                    M.COM
                    <br />
                  </td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td>
                    3
                    <br />
                  </td>
                  <td>
                   BBA (Hons)
                    <br />
                  </td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td>
                    4
                    <br />
                  </td>
                  <td>
                   MBA
                    <br />
                  </td>
                </tr>
              </tbody>
            </table>

            <br />

            <table
              className="table table-bordered"
              style={{ borderCollapse: "collapse", width: "100%", height: 173 }}
              border={1}
            >
              <tbody>
                <tr style={{ height: 20 }}>
                  <td>
                    <strong>Sr No</strong>
                    <br />
                  </td>
                  <td>
                    <strong>Certificate Course</strong>
                    <br />
                  </td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td>
                    1
                    <br />
                  </td>
                  <td>
                    Digital Marketing
                    <br />
                  </td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td>
                    2
                    <br />
                  </td>
                  <td>
                    Supply Chain Management
                    <br />
                  </td>
                </tr>
              
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
