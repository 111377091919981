import React from 'react'
import './Graduate.scss'

function PostGraduate() {
  return (
    <div className='PostGraduate Graduate'>
        <>
  {/* Modal */}
  <div
    className="modal fade"
    id="engineering"
    tabIndex={-1}
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog modal-xl">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel" />
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          />
        </div>
        <div className="modal-body">
          <div className="d-flex align-items-center">
            <img
              src="assets/images/icon/engineering.png"
              className="img-fluid modal_icon"
              alt="engineering icon"
            />
            <h2>Engineering</h2>
          </div>
          <div className="row mt-5">
           
            <div className="col-md-4">
              <ul>
                <li className="crs">
                  <a href="course_details.php?course_name=Mechanical Engineering &course=PG &intake=18&duration=2 years&fees=79,000&contact=%2B91-9588803611&adcontact=%2B91-9023976473/%2B91-9023965729&ademail=ssvgkalol@gmail.com&head=Dr. Geetanjali Amrawat&heademail=sudeanengg@gmail.com">
                    <h5>Mechanical Engineering</h5>{" "}
                  </a>
                </li>
                <a href="course_details.php?course_name=Mechanical Engineering &course=PG &intake=18&duration=2 years&fees=79,000&contact=%2B91-9588803611&adcontact=%2B91-9023976473/%2B91-9023965729&ademail=ssvgkalol@gmail.com&head=Dr. Geetanjali Amrawat&heademail=sudeanengg@gmail.com"></a>
              </ul>
              <a href="course_details.php?course_name=Mechanical Engineering &course=PG &intake=18&duration=2 years&fees=79,000&contact=%2B91-9588803611&adcontact=%2B91-9023976473/%2B91-9023965729&ademail=ssvgkalol@gmail.com&head=Dr. Geetanjali Amrawat&heademail=sudeanengg@gmail.com">
                <ul style={{ listStyle: "none" }}>
                  <h5 className="text-danger mt-4">Details</h5>
                  <li className="crs">
                    <p>Course Type : PG</p>
                  </li>
                  <li className="crs">
                    <p>Duration of Course : 2 years</p>
                  </li>
                  <li className="crs">
                    <p>Course Intake : 18</p>
                  </li>
                  <li className="crs">
                    <p>Fees : 87000*</p>
                  </li>
                </ul>
              </a>
            </div>
            <div className="col-md-4">
              <ul>
                <li className="crs">
                  <a href="course_details.php?course_name=Electrical Engineering &course=PG &intake=18&duration=2 years&fees=79,000&contact=%2B91-9588803611&adcontact=%2B91-9023976473/%2B91-9023965729&ademail=ssvgkalol@gmail.com&head=Dr. Geetanjali Amrawat&heademail=sudeanengg@gmail.com">
                    <h5>Electrical Engineering</h5>{" "}
                  </a>
                </li>
                <a href="course_details.php?course_name=Electrical Engineering &course=PG &intake=18&duration=2 years&fees=79,000&contact=%2B91-9588803611&adcontact=%2B91-9023976473/%2B91-9023965729&ademail=ssvgkalol@gmail.com&head=Dr. Geetanjali Amrawat&heademail=sudeanengg@gmail.com"></a>
              </ul>
              <a href="course_details.php?course_name=Electrical Engineering &course=PG &intake=18&duration=2 years&fees=79,000&contact=%2B91-9588803611&adcontact=%2B91-9023976473/%2B91-9023965729&ademail=ssvgkalol@gmail.com&head=Dr. Geetanjali Amrawat&heademail=sudeanengg@gmail.com">
                <ul style={{ listStyle: "none" }}>
                  <h5 className="text-danger mt-4">Details</h5>
                  <li className="crs">
                    <p>Course Type : PG</p>
                  </li>
                  <li className="crs">
                    <p>Duration of Course : 2 years</p>
                  </li>
                  <li className="crs">
                    <p>Course Intake : 18</p>
                  </li>
                  <li className="crs">
                    <p>Fees : 87000*</p>
                  </li>
                </ul>
              </a>
            </div>
            <div className="col-md-4">
              <ul>
                <li className="crs">
                  <a href="course_details.php?course_name=Computer Engineering &course=PG &intake=18&duration=2 years&fees=79,000&contact=%2B91-9588803611&adcontact=%2B91-9023976473/%2B91-9023965729&ademail=ssvgkalol@gmail.com&head=Dr. Geetanjali Amrawat&heademail=sudeanengg@gmail.com">
                    <h5>Computer Engineering</h5>{" "}
                  </a>
                </li>
                <a href="course_details.php?course_name=Computer Engineering &course=PG &intake=18&duration=2 years&fees=79,000&contact=%2B91-9588803611&adcontact=%2B91-9023976473/%2B91-9023965729&ademail=ssvgkalol@gmail.com&head=Dr. Geetanjali Amrawat&heademail=sudeanengg@gmail.com"></a>
              </ul>
              <a href="course_details.php?course_name=Computer Engineering &course=PG &intake=18&duration=2 years&fees=79,000&contact=%2B91-9588803611&adcontact=%2B91-9023976473/%2B91-9023965729&ademail=ssvgkalol@gmail.com&head=Dr. Geetanjali Amrawat&heademail=sudeanengg@gmail.com">
                <ul style={{ listStyle: "none" }}>
                  <h5 className="text-danger mt-4">Details</h5>
                  <li className="crs">
                    <p>Course Type : PG</p>
                  </li>
                  <li className="crs">
                    <p>Duration of Course : 2 years</p>
                  </li>
                  <li className="crs">
                    <p>Course Intake : 18</p>
                  </li>
                  <li className="crs">
                    <p>Fees : 87000*</p>
                  </li>
                </ul>
              </a>
            </div>
            
           
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Nursing Modal */}
  <div
    className="modal fade"
    id="nursing"
    tabIndex={-1}
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog modal-xl">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel" />
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          />
        </div>
        <div className="modal-body">
          <div className="d-flex align-items-center">
            <img
              src="assets/images/icon/nurse.png"
              className="img-fluid modal_icon"
              alt="engineering icon"
            />
            <h2>Nursing</h2>
          </div>
          <div className="row mt-5">
            <div className="col-md-4">
              <ul>
                <li className="crs">
                  <a href="course_details.php?course_name=M.Sc. Nursing &course=PG &intake=25&duration=2 years&fees=120000* Subject to Approve from FRC&contact=%2B91-9427483055&adcontact=%2B91-9427483055&ademail=ssnckalol@gmail.com&head=Mrs. Kalpana Patel &heademail=ssnckalol@gmail.com">
                    <h5>M.Sc. Nursing</h5>{" "}
                  </a>
                </li>
                <a href="course_details.php?course_name=M.Sc. Nursing &course=PG &intake=25&duration=2 years&fees=120000* Subject to Approve from FRC&contact=%2B91-9427483055&adcontact=%2B91-9427483055&ademail=ssnckalol@gmail.com&head=Mrs. Kalpana Patel &heademail=ssnckalol@gmail.com"></a>
              </ul>
              <a href="course_details.php?course_name=M.Sc. Nursing &course=PG &intake=25&duration=2 years&fees=120000* Subject to Approve from FRC&contact=%2B91-9427483055&adcontact=%2B91-9427483055&ademail=ssnckalol@gmail.com&head=Mrs. Kalpana Patel &heademail=ssnckalol@gmail.com">
                <ul style={{ listStyle: "none" }}>
                  <h5 className="text-danger mt-4">Details</h5>
                  <li className="crs">
                    <p>Course Type : PG</p>
                  </li>
                  <li className="crs">
                    <p>Duration of Course : 2 years</p>
                  </li>
                  <li className="crs">
                    <p>Course Intake : 25</p>
                  </li>
                  <li className="crs">
                    <p>Fees : 120000* Subject to Approve from FRC</p>
                  </li>
                </ul>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Basic Science */}
  <div
    className="modal fade"
    id="bs"
    tabIndex={-1}
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog modal-xl">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel" />
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          />
        </div>
        <div className="modal-body">
          <div className="d-flex align-items-center">
            <img
              src="assets/images/icon/science.png"
              className="img-fluid modal_icon"
              alt="engineering icon"
            />
            <h2>Master of Science</h2>
          </div>
          <div className="row mt-5">
            <div className="col-md-4">
              <ul>
                <li className="crs">
                  <a href="course_details.php?course_name=Civil Engineering &course=PG &intake=18&duration=2 years&fees=79,000&contact=%2B91-9588803611&adcontact=%2B91-9023976473/%2B91-9023965729&ademail=ssvgkalol@gmail.com&head=Dr. Geetanjali Amrawat&heademail=sudeanengg@gmail.com">
                    <h5>M.Sc. in Chemistry</h5>{" "}
                  </a>
                </li>
                <a href="course_details.php?course_name=Civil Engineering &course=PG &intake=18&duration=2 years&fees=79,000&contact=%2B91-9588803611&adcontact=%2B91-9023976473/%2B91-9023965729&ademail=ssvgkalol@gmail.com&head=Dr. Geetanjali Amrawat&heademail=sudeanengg@gmail.com"></a>
              </ul>
              <a href="course_details.php?course_name=Civil Engineering &course=PG &intake=18&duration=2 years&fees=79,000&contact=%2B91-9588803611&adcontact=%2B91-9023976473/%2B91-9023965729&ademail=ssvgkalol@gmail.com&head=Dr. Geetanjali Amrawat&heademail=sudeanengg@gmail.com">
                <ul style={{ listStyle: "none" }}>
                  <h5 className="text-danger mt-4">Details</h5>
                  <li className="crs">
                    <p>Course Type : PG</p>
                  </li>
                  <li className="crs">
                    <p>Duration of Course : 2 years</p>
                  </li>
                  <li className="crs">
                    <p>Course Intake : 240</p>
                  </li>
                  <li className="crs">
                    <p>Fees : 40000</p>
                  </li>
                </ul>
              </a>
            </div>
            <div className="col-md-4">
              <ul>
                <li className="crs">
                  <a href="course_details.php?course_name=Civil Engineering &course=PG &intake=18&duration=2 years&fees=79,000&contact=%2B91-9588803611&adcontact=%2B91-9023976473/%2B91-9023965729&ademail=ssvgkalol@gmail.com&head=Dr. Geetanjali Amrawat&heademail=sudeanengg@gmail.com">
                    <h5>M.Sc. in Microbiology</h5>{" "}
                  </a>
                </li>
                <a href="course_details.php?course_name=Civil Engineering &course=PG &intake=18&duration=2 years&fees=79,000&contact=%2B91-9588803611&adcontact=%2B91-9023976473/%2B91-9023965729&ademail=ssvgkalol@gmail.com&head=Dr. Geetanjali Amrawat&heademail=sudeanengg@gmail.com"></a>
              </ul>
              <a href="course_details.php?course_name=Civil Engineering &course=PG &intake=18&duration=2 years&fees=79,000&contact=%2B91-9588803611&adcontact=%2B91-9023976473/%2B91-9023965729&ademail=ssvgkalol@gmail.com&head=Dr. Geetanjali Amrawat&heademail=sudeanengg@gmail.com">
                <ul style={{ listStyle: "none" }}>
                  <h5 className="text-danger mt-4">Details</h5>
                  <li className="crs">
                    <p>Course Type : PG</p>
                  </li>
                  <li className="crs">
                    <p>Duration of Course : 2 years</p>
                  </li>
                  <li className="crs">
                    <p>Course Intake : 120</p>
                  </li>
                  <li className="crs">
                    <p>Fees : 40000</p>
                  </li>
                </ul>
              </a>
            </div>
            <div className="col-md-4">
              <ul>
                <li className="crs">
                  <a href="course_details.php?course_name=Civil Engineering &course=PG &intake=18&duration=2 years&fees=79,000&contact=%2B91-9588803611&adcontact=%2B91-9023976473/%2B91-9023965729&ademail=ssvgkalol@gmail.com&head=Dr. Geetanjali Amrawat&heademail=sudeanengg@gmail.com">
                    <h5>M.Sc. in Botany</h5>{" "}
                  </a>
                </li>
                <a href="course_details.php?course_name=Civil Engineering &course=PG &intake=18&duration=2 years&fees=79,000&contact=%2B91-9588803611&adcontact=%2B91-9023976473/%2B91-9023965729&ademail=ssvgkalol@gmail.com&head=Dr. Geetanjali Amrawat&heademail=sudeanengg@gmail.com"></a>
              </ul>
              <a href="course_details.php?course_name=Civil Engineering &course=PG &intake=18&duration=2 years&fees=79,000&contact=%2B91-9588803611&adcontact=%2B91-9023976473/%2B91-9023965729&ademail=ssvgkalol@gmail.com&head=Dr. Geetanjali Amrawat&heademail=sudeanengg@gmail.com">
                <ul style={{ listStyle: "none" }}>
                  <h5 className="text-danger mt-4">Details</h5>
                  <li className="crs">
                    <p>Course Type : PG</p>
                  </li>
                  <li className="crs">
                    <p>Duration of Course : 2 years</p>
                  </li>
                  <li className="crs">
                    <p>Course Intake : 60</p>
                  </li>
                  <li className="crs">
                    <p>Fees : 40000</p>
                  </li>
                </ul>
              </a>
            </div>
            <div className="col-md-4">
              <ul>
                <li className="crs">
                  <a href="course_details.php?course_name=Civil Engineering &course=PG &intake=18&duration=2 years&fees=79,000&contact=%2B91-9588803611&adcontact=%2B91-9023976473/%2B91-9023965729&ademail=ssvgkalol@gmail.com&head=Dr. Geetanjali Amrawat&heademail=sudeanengg@gmail.com">
                    <h5>M.Sc. in Zoology</h5>{" "}
                  </a>
                </li>
                <a href="course_details.php?course_name=Civil Engineering &course=PG &intake=18&duration=2 years&fees=79,000&contact=%2B91-9588803611&adcontact=%2B91-9023976473/%2B91-9023965729&ademail=ssvgkalol@gmail.com&head=Dr. Geetanjali Amrawat&heademail=sudeanengg@gmail.com"></a>
              </ul>
              <a href="course_details.php?course_name=Civil Engineering &course=PG &intake=18&duration=2 years&fees=79,000&contact=%2B91-9588803611&adcontact=%2B91-9023976473/%2B91-9023965729&ademail=ssvgkalol@gmail.com&head=Dr. Geetanjali Amrawat&heademail=sudeanengg@gmail.com">
                <ul style={{ listStyle: "none" }}>
                  <h5 className="text-danger mt-4">Details</h5>
                  <li className="crs">
                    <p>Course Type : PG</p>
                  </li>
                  <li className="crs">
                    <p>Duration of Course : 2 years</p>
                  </li>
                  <li className="crs">
                    <p>Course Intake : 60</p>
                  </li>
                  <li className="crs">
                    <p>Fees : 40000</p>
                  </li>
                </ul>
              </a>
            </div>
            <div className="col-md-4">
              <ul>
                <li className="crs">
                  <a href="course_details.php?course_name=Civil Engineering &course=PG &intake=18&duration=2 years&fees=79,000&contact=%2B91-9588803611&adcontact=%2B91-9023976473/%2B91-9023965729&ademail=ssvgkalol@gmail.com&head=Dr. Geetanjali Amrawat&heademail=sudeanengg@gmail.com">
                    <h5>M.Sc. in Mathematics</h5>{" "}
                  </a>
                </li>
                <a href="course_details.php?course_name=Civil Engineering &course=PG &intake=18&duration=2 years&fees=79,000&contact=%2B91-9588803611&adcontact=%2B91-9023976473/%2B91-9023965729&ademail=ssvgkalol@gmail.com&head=Dr. Geetanjali Amrawat&heademail=sudeanengg@gmail.com"></a>
              </ul>
              <a href="course_details.php?course_name=Civil Engineering &course=PG &intake=18&duration=2 years&fees=79,000&contact=%2B91-9588803611&adcontact=%2B91-9023976473/%2B91-9023965729&ademail=ssvgkalol@gmail.com&head=Dr. Geetanjali Amrawat&heademail=sudeanengg@gmail.com">
                <ul style={{ listStyle: "none" }}>
                  <h5 className="text-danger mt-4">Details</h5>
                  <li className="crs">
                    <p>Course Type : PG</p>
                  </li>
                  <li className="crs">
                    <p>Duration of Course : 2 years</p>
                  </li>
                  <li className="crs">
                    <p>Course Intake : 60</p>
                  </li>
                  <li className="crs">
                    <p>Fees : 40000</p>
                  </li>
                </ul>
              </a>
            </div>
            <div className="col-md-4">
              <ul>
                <li className="crs">
                  <a href="course_details.php?course_name=Civil Engineering &course=PG &intake=18&duration=2 years&fees=79,000&contact=%2B91-9588803611&adcontact=%2B91-9023976473/%2B91-9023965729&ademail=ssvgkalol@gmail.com&head=Dr. Geetanjali Amrawat&heademail=sudeanengg@gmail.com">
                    <h5>M.Sc. in Biotechnology</h5>{" "}
                  </a>
                </li>
                <a href="course_details.php?course_name=Civil Engineering &course=PG &intake=18&duration=2 years&fees=79,000&contact=%2B91-9588803611&adcontact=%2B91-9023976473/%2B91-9023965729&ademail=ssvgkalol@gmail.com&head=Dr. Geetanjali Amrawat&heademail=sudeanengg@gmail.com"></a>
              </ul>
              <a href="course_details.php?course_name=Civil Engineering &course=PG &intake=18&duration=2 years&fees=79,000&contact=%2B91-9588803611&adcontact=%2B91-9023976473/%2B91-9023965729&ademail=ssvgkalol@gmail.com&head=Dr. Geetanjali Amrawat&heademail=sudeanengg@gmail.com">
                <ul style={{ listStyle: "none" }}>
                  <h5 className="text-danger mt-4">Details</h5>
                  <li className="crs">
                    <p>Course Type : PG</p>
                  </li>
                  <li className="crs">
                    <p>Duration of Course : 2 years</p>
                  </li>
                  <li className="crs">
                    <p>Course Intake : 30</p>
                  </li>
                  <li className="crs">
                    <p>Fees : 40000</p>
                  </li>
                </ul>
              </a>
            </div>
            <div className="col-md-4">
              <ul>
                <li className="crs">
                  <a href="course_details.php?course_name=Civil Engineering &course=PG &intake=18&duration=2 years&fees=79,000&contact=%2B91-9588803611&adcontact=%2B91-9023976473/%2B91-9023965729&ademail=ssvgkalol@gmail.com&head=Dr. Geetanjali Amrawat&heademail=sudeanengg@gmail.com">
                    <h5>M.Sc. in Physics</h5>{" "}
                  </a>
                </li>
                <a href="course_details.php?course_name=Civil Engineering &course=PG &intake=18&duration=2 years&fees=79,000&contact=%2B91-9588803611&adcontact=%2B91-9023976473/%2B91-9023965729&ademail=ssvgkalol@gmail.com&head=Dr. Geetanjali Amrawat&heademail=sudeanengg@gmail.com"></a>
              </ul>
              <a href="course_details.php?course_name=Civil Engineering &course=PG &intake=18&duration=2 years&fees=79,000&contact=%2B91-9588803611&adcontact=%2B91-9023976473/%2B91-9023965729&ademail=ssvgkalol@gmail.com&head=Dr. Geetanjali Amrawat&heademail=sudeanengg@gmail.com">
                <ul style={{ listStyle: "none" }}>
                  <h5 className="text-danger mt-4">Details</h5>
                  <li className="crs">
                    <p>Course Type : PG</p>
                  </li>
                  <li className="crs">
                    <p>Duration of Course : 2 years</p>
                  </li>
                  <li className="crs">
                    <p>Course Intake : 30</p>
                  </li>
                  <li className="crs">
                    <p>Fees : 40000</p>
                  </li>
                </ul>
              </a>
            </div>
            <div className="col-md-4">
              <ul>
                <li className="crs">
                  <a href="course_details.php?course_name=Civil Engineering &course=PG &intake=18&duration=2 years&fees=79,000&contact=%2B91-9588803611&adcontact=%2B91-9023976473/%2B91-9023965729&ademail=ssvgkalol@gmail.com&head=Dr. Geetanjali Amrawat&heademail=sudeanengg@gmail.com">
                    <h5>M.Sc. in Pharmaceutical Analytical Chemistry</h5>{" "}
                  </a>
                </li>
                <a href="course_details.php?course_name=Civil Engineering &course=PG &intake=18&duration=2 years&fees=79,000&contact=%2B91-9588803611&adcontact=%2B91-9023976473/%2B91-9023965729&ademail=ssvgkalol@gmail.com&head=Dr. Geetanjali Amrawat&heademail=sudeanengg@gmail.com"></a>
              </ul>
              <a href="course_details.php?course_name=Civil Engineering &course=PG &intake=18&duration=2 years&fees=79,000&contact=%2B91-9588803611&adcontact=%2B91-9023976473/%2B91-9023965729&ademail=ssvgkalol@gmail.com&head=Dr. Geetanjali Amrawat&heademail=sudeanengg@gmail.com">
                <ul style={{ listStyle: "none" }}>
                  <h5 className="text-danger mt-4">Details</h5>
                  <li className="crs">
                    <p>Course Type : PG</p>
                  </li>
                  <li className="crs">
                    <p>Duration of Course : 2 years</p>
                  </li>
                  <li className="crs">
                    <p>Course Intake : 40</p>
                  </li>
                  <li className="crs">
                    <p>Fees : 50000</p>
                  </li>
                </ul>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/*Management */}
  <div
    className="modal fade"
    id="management"
    tabIndex={-1}
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog modal-xl">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel" />
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          />
        </div>
        <div className="modal-body">
          <div className="d-flex align-items-center">
            <img
              src="assets/images/icon/management.png"
              className="img-fluid modal_icon"
              alt="engineering icon"
            />
            <h2>Management</h2>
          </div>
          <div className="row mt-5">
            <div className="col-md-6">
              <ul>
                <li className="crs">
                  <a href="course_details.php?course_name=Civil Engineering &course=PG &intake=18&duration=2 years&fees=79,000&contact=%2B91-9588803611&adcontact=%2B91-9023976473/%2B91-9023965729&ademail=ssvgkalol@gmail.com&head=Dr. Geetanjali Amrawat&heademail=sudeanengg@gmail.com">
                    <h5>M.Com</h5>{" "}
                  </a>
                </li>
                <a href="course_details.php?course_name=Civil Engineering &course=PG &intake=18&duration=2 years&fees=79,000&contact=%2B91-9588803611&adcontact=%2B91-9023976473/%2B91-9023965729&ademail=ssvgkalol@gmail.com&head=Dr. Geetanjali Amrawat&heademail=sudeanengg@gmail.com"></a>
              </ul>
              <a href="course_details.php?course_name=Civil Engineering &course=PG &intake=18&duration=2 years&fees=79,000&contact=%2B91-9588803611&adcontact=%2B91-9023976473/%2B91-9023965729&ademail=ssvgkalol@gmail.com&head=Dr. Geetanjali Amrawat&heademail=sudeanengg@gmail.com">
                <ul style={{ listStyle: "none" }}>
                  <h5 className="text-danger mt-4">Details</h5>
                  <li className="crs">
                    <p>Course Type : PG</p>
                  </li>
                  <li className="crs">
                    <p>Duration of Course : 2 years</p>
                  </li>
                  <li className="crs">
                    <p>Course Intake : 120</p>
                  </li>
                  <li className="crs">
                    <p>Fees : 15,000/- fee per annum</p>
                  </li>
                </ul>
              </a>
            </div>
            <div className="col-md-6">
              <ul>
                <li className="crs">
                  <a href="course_details.php?course_name=MBA- Hospitality, Finance, Marketting, HR &course=Master &intake=30&duration=2 years&fees=60,000&contact=%2B91-98794888309 / %2B91-9824804530&adcontact=%2B91-9879488309 / %2B91-9824804530&ademail=commerce.swaminarayanuni@gmail.com&head=Dr. Dhruv S. Patel  &heademail=sudeanengg@gmail.com">
                    <h5>MBA- Hospitality, Finance, Marketting, HR</h5>{" "}
                  </a>
                </li>
                <a href="course_details.php?course_name=MBA- Hospitality, Finance, Marketting, HR &course=Master &intake=30&duration=2 years&fees=60,000&contact=%2B91-98794888309 / %2B91-9824804530&adcontact=%2B91-9879488309 / %2B91-9824804530&ademail=commerce.swaminarayanuni@gmail.com&head=Dr. Dhruv S. Patel  &heademail=sudeanengg@gmail.com"></a>
              </ul>
              <a href="course_details.php?course_name=MBA- Hospitality, Finance, Marketting, HR &course=Master &intake=30&duration=2 years&fees=60,000&contact=%2B91-98794888309 / %2B91-9824804530&adcontact=%2B91-9879488309 / %2B91-9824804530&ademail=commerce.swaminarayanuni@gmail.com&head=Dr. Dhruv S. Patel  &heademail=sudeanengg@gmail.com">
                <ul style={{ listStyle: "none" }}>
                  <h5 className="text-danger mt-4">Details</h5>
                  <li className="crs">
                    <p>Course Type : Master</p>
                  </li>
                  <li className="crs">
                    <p>Duration of Course : 2 years</p>
                  </li>
                  <li className="crs">
                    <p>Course Intake : 120</p>
                  </li>
                  <li className="crs">
                    <p>Fees : 70,000/- fee per annum</p>
                  </li>
                </ul>
              </a>
            </div>
           
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Pharmacy Modal */}
  <div
    className="modal fade"
    id="pharmacy"
    tabIndex={-1}
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog modal-xl">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel" />
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          />
        </div>
        <div className="modal-body">
          <div className="d-flex align-items-center">
            <img
              src="assets/images/icon/pharmacy.png"
              className="img-fluid modal_icon"
              alt="engineering icon"
            />
            <h2>Pharmacy</h2>
          </div>
          <div className="row mt-5">
            <div className="col-md-4">
              <ul>
                <li className="crs">
                  <a href="course_details.php?course_name=Pharm D & course=PG &intake=30&duration=6 years&fees=1,30,000&contact=%2B91-9428114678&adcontact=%2B91-9099063158&ademail=bph258owner@gtu.edu.in&head=Dr. Ravi Patel&heademail= ravipharma84@gmail.com">
                    <h5>Pharma D</h5>{" "}
                  </a>
                </li>
                <a href="course_details.php?course_name=Pharm D &course=PG &intake=30&duration=6 years&fees=1,30,000&contact=%2B91-9428114678&adcontact=%2B91-9099063158&ademail=bph258owner@gtu.edu.in&head=Dr. Ravi Patel&heademail= ravipharma84@gmail.com"></a>
              </ul>
              <a href="course_details.php?course_name=Pharm D &course=PG &intake=30&duration=6 years&fees=1,30,000&contact=%2B91-9428114678&adcontact=%2B91-9099063158&ademail=bph258owner@gtu.edu.in&head=Dr. Ravi Patel&heademail= ravipharma84@gmail.com">
                <ul style={{ listStyle: "none" }}>
                  <h5 className="text-danger mt-4">Details</h5>
                  <li className="crs">
                    <p>Course Type : PG</p>
                  </li>
                  <li className="crs">
                    <p>Duration of Course : 6 years</p>
                  </li>
                  <li className="crs">
                    <p>Course Intake : 30</p>
                  </li>
                  <li className="crs">
                    <p>Fees : 1,30,000</p>
                  </li>
                </ul>
              </a>
            </div>
            <div className="col-md-4">
              <ul>
                <li className="crs">
                  <a href="course_details.php?course_name=M.Pharm (Pharmaceutics) &course=PG &intake=6&duration=2 years&fees=92000&contact=%2B91-9428114678&adcontact=%2B91-9099063158&ademail=bph258owner@gtu.edu.in&head=Dr. Ravi Patel&heademail= ravipharma84@gmail.com">
                    <h5>M.Pharm (Pharmaceutics)</h5>{" "}
                  </a>
                </li>
                <a href="course_details.php?course_name=M.Pharm (Pharmaceutics) &course=PG &intake=6&duration=2 years&fees=92000&contact=%2B91-9428114678&adcontact=%2B91-9099063158&ademail=bph258owner@gtu.edu.in&head=Dr. Ravi Patel&heademail= ravipharma84@gmail.com"></a>
              </ul>
              <a href="course_details.php?course_name=M.Pharm (Pharmaceutics) &course=PG &intake=6&duration=2 years&fees=92000&contact=%2B91-9428114678&adcontact=%2B91-9099063158&ademail=bph258owner@gtu.edu.in&head=Dr. Ravi Patel&heademail= ravipharma84@gmail.com">
                <ul style={{ listStyle: "none" }}>
                  <h5 className="text-danger mt-4">Details</h5>
                  <li className="crs">
                    <p>Course Type : PG</p>
                  </li>
                  <li className="crs">
                    <p>Duration of Course : 2 years</p>
                  </li>
                  <li className="crs">
                    <p>Course Intake : 6</p>
                  </li>
                  <li className="crs">
                    <p>Fees : 1,01,400</p>
                  </li>
                </ul>
              </a>
            </div>
            <div className="col-md-4">
              <ul>
                <li className="crs">
                  <a href="course_details.php?course_name=Civil Engineering &course=PG &intake=18&duration=2 years&fees=79,000&contact=%2B91-9588803611&adcontact=%2B91-9023976473/%2B91-9023965729&ademail=ssvgkalol@gmail.com&head=Dr. Geetanjali Amrawat&heademail=sudeanengg@gmail.com">
                    <h5>M.Pharm (Quality Assurance)</h5>{" "}
                  </a>
                </li>
                <a href="course_details.php?course_name=Civil Engineering &course=PG &intake=18&duration=2 years&fees=79,000&contact=%2B91-9588803611&adcontact=%2B91-9023976473/%2B91-9023965729&ademail=ssvgkalol@gmail.com&head=Dr. Geetanjali Amrawat&heademail=sudeanengg@gmail.com"></a>
              </ul>
              <a href="course_details.php?course_name=Civil Engineering &course=PG &intake=18&duration=2 years&fees=79,000&contact=%2B91-9588803611&adcontact=%2B91-9023976473/%2B91-9023965729&ademail=ssvgkalol@gmail.com&head=Dr. Geetanjali Amrawat&heademail=sudeanengg@gmail.com">
                <ul style={{ listStyle: "none" }}>
                  <h5 className="text-danger mt-4">Details</h5>
                  <li className="crs">
                    <p>Course Type : PG</p>
                  </li>
                  <li className="crs">
                    <p>Duration of Course : 2 years</p>
                  </li>
                  <li className="crs">
                    <p>Course Intake : 9</p>
                  </li>
                  <li className="crs">
                    <p>Fees : 1,01,400</p>
                  </li>
                </ul>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Medical Modal */}
  <div
    className="modal fade"
    id="medical"
    tabIndex={-1}
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog modal-xl">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel" />
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          />
        </div>
        <div className="modal-body">
          <div className="d-flex align-items-center">
            <img
              src="assets/images/icon/doctor.png"
              className="img-fluid modal_icon"
              alt="engineering icon"
            />
            <h2>Medical</h2>
          </div>
          <div className="row mt-5">
            <div className="col-md-6">
              <ul>
                <li className="crs">
                  <a href="course_details.php?course_name=MD (RN,SAMHITA,KC) &course=PG &intake=Each 5&duration=3 years&fees=3,00,000/Year* Subject to Approve from FRC&contact=%2B91-9449231730&adcontact=%2B91-8487878000&ademail=ssackalol@gmail.com&head=Dr. Rohit Gokarn&heademail=ssackalol@gmail.com">
                    <h5>MD (RN,SAMHITA,KC)</h5>{" "}
                  </a>
                </li>
                <a href="course_details.php?course_name=MD (RN,SAMHITA,KC) &course=PG &intake=Each 5&duration=3 years&fees=3,00,000/Year* Subject to Approve from FRC&contact=%2B91-9449231730&adcontact=%2B91-8487878000&ademail=ssackalol@gmail.com&head=Dr. Rohit Gokarn&heademail=ssackalol@gmail.com"></a>
              </ul>
              <a href="course_details.php?course_name=MD (RN,SAMHITA,KC) &course=PG &intake=Each 5&duration=3 years&fees=3,00,000/Year* Subject to Approve from FRC&contact=%2B91-9449231730&adcontact=%2B91-8487878000&ademail=ssackalol@gmail.com&head=Dr. Rohit Gokarn&heademail=ssackalol@gmail.com">
                <ul style={{ listStyle: "none" }}>
                  <h5 className="text-danger mt-4">Details</h5>
                  <li className="crs">
                    <p>Course Type : PG</p>
                  </li>
                  <li className="crs">
                    <p>Duration of Course : 3 years</p>
                  </li>
                  <li className="crs">
                    <p>Course Intake: Each 5</p>
                  </li>
                  <li className="crs">
                    <p>Fee: 3,00,000/Year* Subject to Approve from FRC</p>
                  </li>
                </ul>
              </a>
            </div>
            <div className="col-md-6">
              <ul>
                <li className="crs">
                  <a href="course_details.php?course_name=MS (SHALYA, PTSR) &course=PG &intake=Each 5&duration=3 years&fees=5,00,000/Year* Subject to Approve from FRC&contact=%2B91-9449231730&adcontact=%2B91-8487878000&ademail=ssackalol@gmail.com&head=Dr. Rohit Gokarn&heademail=ssackalol@gmail.com">
                    <h5>MS (SHALYA, PTSR)</h5>
                  </a>
                </li>
                <a href="course_details.php?course_name=MS (SHALYA, PTSR) &course=PG &intake=Each 5&duration=3 years&fees=5,00,000/Year* Subject to Approve from FRC&contact=%2B91-9449231730&adcontact=%2B91-8487878000&ademail=ssackalol@gmail.com&head=Dr. Rohit Gokarn&heademail=ssackalol@gmail.com"></a>
              </ul>
              <a href="course_details.php?course_name=MS (SHALYA, PTSR) &course=PG &intake=Each 5&duration=3 years&fees=5,00,000/Year* Subject to Approve from FRC&contact=%2B91-9449231730&adcontact=%2B91-8487878000&ademail=ssackalol@gmail.com&head=Dr. Rohit Gokarn&heademail=ssackalol@gmail.com">
                <ul style={{ listStyle: "none" }}>
                  <h5 className="text-danger mt-4">Details</h5>
                  <li className="crs">
                    <p>Course Type : PG</p>
                  </li>
                  <li className="crs">
                    <p>Duration of Course : 3 years</p>
                  </li>
                  <li className="crs">
                    <p>Course Intake: Each 5</p>
                  </li>
                  <li className="crs">
                    <p>Fee: 5,00,000/Year* Subject to Approve from FRC</p>
                  </li>
                </ul>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* law Modal */}
  <div
    className="modal fade"
    id="law"
    tabIndex={-1}
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog modal-xl">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel" />
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          />
        </div>
        <div className="modal-body">
          <div className="d-flex align-items-center">
            <img
              src="assets/images/icon/law.png"
              className="img-fluid modal_icon"
              alt="engineering icon"
            />
            <h2>Law</h2>
          </div>
          <div className="row mt-5">
            <div className="col-md-4">
              <ul>
                <li className="crs">
                  <a href="course_details.php?course_name=Bussines Law&course=LLM-PG&intake=40&duration=2 years&fees=29,000&contact=%2B91-9726117000&adcontact=%2B91-9664636857&ademail=sslckalol@gmail.com&head=Dr. Dixit Patel&heademail= dr_mahesh@mail.com">
                    <h5>Bussines Law</h5>
                  </a>
                </li>
                <a href="course_details.php?course_name=Bussines Law&course=LLM-PG&intake=40&duration=2 years&fees=29,000&contact=%2B91-9726117000&adcontact=%2B91-9664636857&ademail=sslckalol@gmail.com&head=Dr. Dixit Patel&heademail= dr_mahesh@mail.com">
                  <ul style={{ listStyle: "none" }}>
                    <h5 className="text-danger mt-4">Details</h5>
                    <li className="crs">
                      <p>Course Type : PG</p>
                    </li>
                    <li className="crs">
                      <p>Duration of Course : 2 years</p>
                    </li>
                    <li className="crs">
                      <p>Course Intake: 40</p>
                    </li>
                    <li className="crs">
                      <p>Fee: 29000</p>
                    </li>
                  </ul>
                </a>
              </ul>
            </div>
            <div className="col-md-4">
              <ul>
                <li className="crs">
                  <a href="course_details.php?course_name=Marine Time Law&course=LLM-PG&intake=40&duration=2 years&fees=50,000&contact=%2B91-9726117000&adcontact=%2B91-9664636857&ademail=sslckalol@gmail.com&head=Dr. Dixit Patel&heademail= dr_mahesh@mail.com">
                    <h5>Marine Time Law</h5>
                  </a>
                </li>
              </ul>
              <a href="course_details.php?course_name=Marine Time Law&course=LLM-PG&intake=40&duration=2 years&fees=50,000&contact=%2B91-9726117000&adcontact=%2B91-9664636857&ademail=sslckalol@gmail.com&head=Dr. Dixit Patel&heademail= dr_mahesh@mail.com">
                {" "}
                <ul style={{ listStyle: "none" }}>
                  <h5 className="text-danger mt-4">Details</h5>
                  <li className="crs">
                    <p>Course Type : PG</p>
                  </li>
                  <li className="crs">
                    <p>Duration of Course : 2 years</p>
                  </li>
                  <li className="crs">
                    <p>Course Intake: 40</p>
                  </li>
                  <li className="crs">
                    <p>Fee: 50000</p>
                  </li>
                </ul>
              </a>
            </div>
            <div className="col-md-4">
              <ul>
                <li className="crs">
                  <a href="course_details.php?course_name=Criminal Law&course=LLM-PG&intake=40&duration=2 years&fees=29,000&contact=%2B91-9726117000&adcontact=%2B91-9664636857&ademail=sslckalol@gmail.com&head=Dr. Dixit Patel&heademail= dr_mahesh@mail.com">
                    <h5>Criminal Law</h5>
                  </a>
                </li>
                <a href="course_details.php?course_name=Criminal Law&course=LLM-PG&intake=40&duration=2 years&fees=29,000&contact=%2B91-9726117000&adcontact=%2B91-9664636857&ademail=sslckalol@gmail.com&head=Dr. Dixit Patel&heademail= dr_mahesh@mail.com">
                  <ul style={{ listStyle: "none" }}>
                    <h5 className="text-danger mt-4">Details</h5>
                    <li className="crs">
                      <p>Course Type : PG</p>
                    </li>
                    <li className="crs">
                      <p>Duration of Course : 2 years</p>
                    </li>
                    <li className="crs">
                      <p>Course Intake: 40</p>
                    </li>
                    <li className="crs">
                      <p>Fee: 29000</p>
                    </li>
                  </ul>
                </a>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Arts Modal */}
  <div
    className="modal fade"
    id="arts"
    tabIndex={-1}
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog modal-xl">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel" />
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          />
        </div>
        <div className="modal-body">
          <div className="d-flex align-items-center">
            <img
              src="assets/images/icon/arts.png"
              className="img-fluid modal_icon"
              alt="engineering icon"
            />
            <h2>Arts</h2>
          </div>
          <div className="row mt-5">
            <div className="col-md-6">
              <ul>
                <li className="crs">
                  <a href="course_details.php?course_name=MA &course=Master &intake=60&duration=2 years&fees=25,000&contact=%2B91-98794888309 / %2B91-9824804530&adcontact=%2B91-9879488309 / %2B91-9824804530&ademail=commerce.swaminarayanuni@gmail.com&head=Dr. Dhruv S. Patel  &heademail=sudeanengg@gmail.com">
                    <h5>MA (Arts)</h5>
                  </a>
                </li>
                <a href="course_details.php?course_name=MA &course=Master &intake=60&duration=2 years&fees=25,000&contact=%2B91-98794888309 / %2B91-9824804530&adcontact=%2B91-9879488309 / %2B91-9824804530&ademail=commerce.swaminarayanuni@gmail.com&head=Dr. Dhruv S. Patel  &heademail=sudeanengg@gmail.com">
                  <ul style={{ listStyle: "none" }}>
                    <h5 className="text-danger mt-4">Details</h5>
                    <li className="crs">
                      <p>Course Type : Master</p>
                    </li>
                    <li className="crs">
                      <p>Duration of Course : 2 years</p>
                    </li>
                    <li className="crs">
                      <p>Course Intake: 120</p>
                    </li>
                    <li className="crs">
                      <p>Fee: 25000</p>
                    </li>
                  </ul>
                </a>
              </ul>
            </div>
            <a href="course_details.php?course_name=MA &course=Master &intake=60&duration=2 years&fees=25,000&contact=%2B91-98794888309 / %2B91-9824804530&adcontact=%2B91-9879488309 / %2B91-9824804530&ademail=commerce.swaminarayanuni@gmail.com&head=Dr. Dhruv S. Patel  &heademail=sudeanengg@gmail.com"></a>
          </div>
          <a href="course_details.php?course_name=MA &course=Master &intake=60&duration=2 years&fees=25,000&contact=%2B91-98794888309 / %2B91-9824804530&adcontact=%2B91-9879488309 / %2B91-9824804530&ademail=commerce.swaminarayanuni@gmail.com&head=Dr. Dhruv S. Patel  &heademail=sudeanengg@gmail.com"></a>
        </div>
        <a href="course_details.php?course_name=MA &course=Master &intake=60&duration=2 years&fees=25,000&contact=%2B91-98794888309 / %2B91-9824804530&adcontact=%2B91-9879488309 / %2B91-9824804530&ademail=commerce.swaminarayanuni@gmail.com&head=Dr. Dhruv S. Patel  &heademail=sudeanengg@gmail.com"></a>
      </div>
      <a href="course_details.php?course_name=MA &course=Master &intake=60&duration=2 years&fees=25,000&contact=%2B91-98794888309 / %2B91-9824804530&adcontact=%2B91-9879488309 / %2B91-9824804530&ademail=commerce.swaminarayanuni@gmail.com&head=Dr. Dhruv S. Patel  &heademail=sudeanengg@gmail.com"></a>
    </div>
    <a href="course_details.php?course_name=MA &course=Master &intake=60&duration=2 years&fees=25,000&contact=%2B91-98794888309 / %2B91-9824804530&adcontact=%2B91-9879488309 / %2B91-9824804530&ademail=commerce.swaminarayanuni@gmail.com&head=Dr. Dhruv S. Patel  &heademail=sudeanengg@gmail.com"></a>
  </div>
  <a href="course_details.php?course_name=MA &course=Master &intake=60&duration=2 years&fees=25,000&contact=%2B91-98794888309 / %2B91-9824804530&adcontact=%2B91-9879488309 / %2B91-9824804530&ademail=commerce.swaminarayanuni@gmail.com&head=Dr. Dhruv S. Patel  &heademail=sudeanengg@gmail.com">
    {/* IT Modal */}
  </a>
  <div
    className="modal fade"
    id="it"
    tabIndex={-1}
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <a href="course_details.php?course_name=MA &course=Master &intake=60&duration=2 years&fees=25,000&contact=%2B91-98794888309 / %2B91-9824804530&adcontact=%2B91-9879488309 / %2B91-9824804530&ademail=commerce.swaminarayanuni@gmail.com&head=Dr. Dhruv S. Patel  &heademail=sudeanengg@gmail.com"></a>
    <div className="modal-dialog modal-xl">
      <a href="course_details.php?course_name=MA &course=Master &intake=60&duration=2 years&fees=25,000&contact=%2B91-98794888309 / %2B91-9824804530&adcontact=%2B91-9879488309 / %2B91-9824804530&ademail=commerce.swaminarayanuni@gmail.com&head=Dr. Dhruv S. Patel  &heademail=sudeanengg@gmail.com"></a>
      <div className="modal-content">
        <a href="course_details.php?course_name=MA &course=Master &intake=60&duration=2 years&fees=25,000&contact=%2B91-98794888309 / %2B91-9824804530&adcontact=%2B91-9879488309 / %2B91-9824804530&ademail=commerce.swaminarayanuni@gmail.com&head=Dr. Dhruv S. Patel  &heademail=sudeanengg@gmail.com">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel" />
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
        </a>
        <div className="modal-body">
          <a href="course_details.php?course_name=MA &course=Master &intake=60&duration=2 years&fees=25,000&contact=%2B91-98794888309 / %2B91-9824804530&adcontact=%2B91-9879488309 / %2B91-9824804530&ademail=commerce.swaminarayanuni@gmail.com&head=Dr. Dhruv S. Patel  &heademail=sudeanengg@gmail.com">
            <div className="d-flex align-items-center">
              <img
                src="assets/images/icon/technology.png"
                className="img-fluid modal_icon"
                alt="engineering icon"
              />
              <h2>IT &amp; Computer</h2>
            </div>
          </a>
          <div className="row mt-5">
            <a href="course_details.php?course_name=MA &course=Master &intake=60&duration=2 years&fees=25,000&contact=%2B91-98794888309 / %2B91-9824804530&adcontact=%2B91-9879488309 / %2B91-9824804530&ademail=commerce.swaminarayanuni@gmail.com&head=Dr. Dhruv S. Patel  &heademail=sudeanengg@gmail.com"></a>
            <div className="col-md-6">
              <a href="course_details.php?course_name=MA &course=Master &intake=60&duration=2 years&fees=25,000&contact=%2B91-98794888309 / %2B91-9824804530&adcontact=%2B91-9879488309 / %2B91-9824804530&ademail=commerce.swaminarayanuni@gmail.com&head=Dr. Dhruv S. Patel  &heademail=sudeanengg@gmail.com"></a>
              <ul>
                <a href="course_details.php?course_name=MA &course=Master &intake=60&duration=2 years&fees=25,000&contact=%2B91-98794888309 / %2B91-9824804530&adcontact=%2B91-9879488309 / %2B91-9824804530&ademail=commerce.swaminarayanuni@gmail.com&head=Dr. Dhruv S. Patel  &heademail=sudeanengg@gmail.com"></a>
                <li className="crs">
                  <a href="course_details.php?course_name=MA &course=Master &intake=60&duration=2 years&fees=25,000&contact=%2B91-98794888309 / %2B91-9824804530&adcontact=%2B91-9879488309 / %2B91-9824804530&ademail=commerce.swaminarayanuni@gmail.com&head=Dr. Dhruv S. Patel  &heademail=sudeanengg@gmail.com">
                    <a href="course_details.php?course_name=MCA &course=Master &intake=60&duration=2 years&fees=50,000&contact=%2B91-98794888309 / %2B91-9824804530&adcontact=%2B91-9879488309 / %2B91-9824804530&ademail=commerce.swaminarayanuni@gmail.com&head=Dr. Dhruv S. Patel  &heademail=sudeanengg@gmail.com">
                      <h5>MCA</h5>
                    </a>
                  </a>
                </li>
                <a href="course_details.php?course_name=MA &course=Master &intake=60&duration=2 years&fees=25,000&contact=%2B91-98794888309 / %2B91-9824804530&adcontact=%2B91-9879488309 / %2B91-9824804530&ademail=commerce.swaminarayanuni@gmail.com&head=Dr. Dhruv S. Patel  &heademail=sudeanengg@gmail.com">
                  <a href="course_details.php?course_name=MCA &course=Master &intake=60&duration=2 years&fees=50,000&contact=%2B91-98794888309 / %2B91-9824804530&adcontact=%2B91-9879488309 / %2B91-9824804530&ademail=commerce.swaminarayanuni@gmail.com&head=Dr. Dhruv S. Patel  &heademail=sudeanengg@gmail.com"></a>
                </a>
              </ul>
              <a href="course_details.php?course_name=MA &course=Master &intake=60&duration=2 years&fees=25,000&contact=%2B91-98794888309 / %2B91-9824804530&adcontact=%2B91-9879488309 / %2B91-9824804530&ademail=commerce.swaminarayanuni@gmail.com&head=Dr. Dhruv S. Patel  &heademail=sudeanengg@gmail.com">
                <a href="course_details.php?course_name=MCA &course=Master &intake=60&duration=2 years&fees=50,000&contact=%2B91-98794888309 / %2B91-9824804530&adcontact=%2B91-9879488309 / %2B91-9824804530&ademail=commerce.swaminarayanuni@gmail.com&head=Dr. Dhruv S. Patel  &heademail=sudeanengg@gmail.com"></a>
                <ul style={{ listStyle: "none" }}>
                  <a href="course_details.php?course_name=MCA &course=Master &intake=60&duration=2 years&fees=50,000&contact=%2B91-98794888309 / %2B91-9824804530&adcontact=%2B91-9879488309 / %2B91-9824804530&ademail=commerce.swaminarayanuni@gmail.com&head=Dr. Dhruv S. Patel  &heademail=sudeanengg@gmail.com">
                    <h5 className="text-danger mt-4">Details</h5>
                    <li className="crs">
                      <p>Course Type : Master</p>
                    </li>
                    <li className="crs">
                      <p>Duration of Course : 2 years</p>
                    </li>
                    <li className="crs">
                      <p>Course Intake: 60</p>
                    </li>
                    <li className="crs">
                      <p>Fee: 66,000/- fee per annum</p>
                    </li>
                  </a>
                </ul>
              </a>
            </div>
            <a href="course_details.php?course_name=MA &course=Master &intake=60&duration=2 years&fees=25,000&contact=%2B91-98794888309 / %2B91-9824804530&adcontact=%2B91-9879488309 / %2B91-9824804530&ademail=commerce.swaminarayanuni@gmail.com&head=Dr. Dhruv S. Patel  &heademail=sudeanengg@gmail.com"></a>
            <div className="col-md-6">
              <a href="course_details.php?course_name=MA &course=Master &intake=60&duration=2 years&fees=25,000&contact=%2B91-98794888309 / %2B91-9824804530&adcontact=%2B91-9879488309 / %2B91-9824804530&ademail=commerce.swaminarayanuni@gmail.com&head=Dr. Dhruv S. Patel  &heademail=sudeanengg@gmail.com"></a>
              <ul>
                <a href="course_details.php?course_name=MA &course=Master &intake=60&duration=2 years&fees=25,000&contact=%2B91-98794888309 / %2B91-9824804530&adcontact=%2B91-9879488309 / %2B91-9824804530&ademail=commerce.swaminarayanuni@gmail.com&head=Dr. Dhruv S. Patel  &heademail=sudeanengg@gmail.com"></a>
                <li className="crs">
                  <a href="course_details.php?course_name=MA &course=Master &intake=60&duration=2 years&fees=25,000&contact=%2B91-98794888309 / %2B91-9824804530&adcontact=%2B91-9879488309 / %2B91-9824804530&ademail=commerce.swaminarayanuni@gmail.com&head=Dr. Dhruv S. Patel  &heademail=sudeanengg@gmail.com"></a>
                  <a href="course_details.php?course_name=M.Sc. IT &course=Master &intake=60&duration=2 years&fees=50,000&contact=%2B91-98794888309 / %2B91-9824804530&adcontact=%2B91-9879488309 / %2B91-9824804530&ademail=commerce.swaminarayanuni@gmail.com&head=Dr. Dhruv S. Patel  &heademail=sudeanengg@gmail.com">
                    <h5>M.Sc IT </h5>
                  </a>
                </li>
                <a href="course_details.php?course_name=M.Sc. IT &course=Master &intake=60&duration=2 years&fees=50,000&contact=%2B91-98794888309 / %2B91-9824804530&adcontact=%2B91-9879488309 / %2B91-9824804530&ademail=commerce.swaminarayanuni@gmail.com&head=Dr. Dhruv S. Patel  &heademail=sudeanengg@gmail.com"></a>
              </ul>
              <a href="course_details.php?course_name=M.Sc. IT &course=Master &intake=60&duration=2 years&fees=50,000&contact=%2B91-98794888309 / %2B91-9824804530&adcontact=%2B91-9879488309 / %2B91-9824804530&ademail=commerce.swaminarayanuni@gmail.com&head=Dr. Dhruv S. Patel  &heademail=sudeanengg@gmail.com"></a>
              <ul style={{ listStyle: "none" }}>
                <a href="course_details.php?course_name=M.Sc. IT &course=Master &intake=60&duration=2 years&fees=50,000&contact=%2B91-98794888309 / %2B91-9824804530&adcontact=%2B91-9879488309 / %2B91-9824804530&ademail=commerce.swaminarayanuni@gmail.com&head=Dr. Dhruv S. Patel  &heademail=sudeanengg@gmail.com">
                  <h5 className="text-danger mt-4">Details</h5>
                  <li className="crs">
                    <p>Course Type : Master</p>
                  </li>
                  <li className="crs">
                    <p>Duration of Course : 2 years</p>
                  </li>
                  <li className="crs">
                    <p>Course Intake: 60</p>
                  </li>
                  <li className="crs">
                    <p>Fee: 50000</p>
                  </li>
                </a>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="description">
    <div className="container-fluid">
      <div className="register" style={{ maxWidth: "90%" }}>
        <h3>Post Graduate Programme 2023-24</h3>
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col-md-3">
            <div className="card mb-3">
              <div className="d-flex flex-row-reverse card-body p-2 align-items-center  ">
                <div className="p-2 w-100 ">
                  <h4>Engineering</h4>
                  <p>Engineering and Technology</p>
                </div>
                <div className="p-2 flex-shrink-1 ">
                  {" "}
                  <img
                    src="assets/images/icon/engineering.png"
                    className="img-fluid icon "
                    alt="engineering icon"
                  />
                </div>
              </div>
              <hr />
              <div className="d-flex justify-content-around">
                <p className="card-text">
                  <small className="text-muted ">6 Programmes </small>
                </p>
                <div className=" d-flex justify-content-center align-item-center ">
                  <a
                    href=""
                    data-bs-toggle="modal"
                    data-bs-target="#engineering"
                  >
                    {" "}
                    <b>
                      More Info <i className="ri-arrow-right-line" />{" "}
                    </b>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card mb-3">
              <div className="d-flex flex-row-reverse card-body p-2 align-items-center  ">
                <div className="p-2 w-100 ">
                  <h4>Nursing</h4>
                  <p> Nursing</p>
                </div>
                <div className="p-2 flex-shrink-1 ">
                  {" "}
                  <img
                    src="assets/images/icon/nurse.png"
                    className="img-fluid icon "
                    alt=""
                  />
                </div>
              </div>
              <hr />
              <div className="d-flex justify-content-around">
                <p className="card-text">
                  <small className="text-muted ">1 Programmes </small>
                </p>
                <div className=" d-flex justify-content-center align-item-center ">
                  <a href="" data-bs-toggle="modal" data-bs-target="#nursing">
                    {" "}
                    <b>
                      More Info <i className="ri-arrow-right-line" />{" "}
                    </b>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card mb-3">
              <div className="d-flex flex-row-reverse card-body p-2 align-items-center  ">
                <div className="p-2 w-100 ">
                  <h4>Master of Science</h4>
                  <p>Basic Science and Research</p>
                </div>
                <div className="p-2 flex-shrink-1 ">
                  {" "}
                  <img
                    src="assets/images/icon/science.png"
                    className="img-fluid icon "
                    alt="science"
                  />
                </div>
              </div>
              <hr />
              <div className="d-flex justify-content-around">
                <p className="card-text">
                  <small className="text-muted ">8 Programmes </small>
                </p>
                <div className=" d-flex justify-content-center align-item-center ">
                  <a href="" data-bs-toggle="modal" data-bs-target="#bs">
                    {" "}
                    <b>
                      More Info <i className="ri-arrow-right-line" />{" "}
                    </b>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card mb-3">
              <div className="d-flex flex-row-reverse card-body p-2 align-items-center  ">
                <div className="p-2 w-100 ">
                  <h4>Management</h4>
                  <p>School of Managment</p>
                </div>
                <div className="p-2 flex-shrink-1 ">
                  {" "}
                  <img
                    src="assets/images/icon/management.png"
                    className="img-fluid icon "
                    alt="managment"
                  />
                </div>
              </div>
              <hr />
              <div className="d-flex justify-content-around">
                <p className="card-text">
                  <small className="text-muted ">3 Programmes </small>
                </p>
                <div className=" d-flex justify-content-center align-item-center ">
                  <a
                    href=""
                    data-bs-toggle="modal"
                    data-bs-target="#management"
                  >
                    {" "}
                    <b>
                      More Info <i className="ri-arrow-right-line" />{" "}
                    </b>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card mb-3">
              <div className="d-flex flex-row-reverse card-body p-2 align-items-center  ">
                <div className="p-2 w-100 ">
                  <h4>Medical</h4>
                  <p>Medical Science &amp; Research</p>
                </div>
                <div className="p-2 flex-shrink-1 ">
                  {" "}
                  <img
                    src="assets/images/icon/doctor.png"
                    className="img-fluid icon "
                    alt="doctor"
                  />
                </div>
              </div>
              <hr />
              <div className="d-flex justify-content-around">
                <p className="card-text">
                  <small className="text-muted ">2 Programmes </small>
                </p>
                <div className=" d-flex justify-content-center align-item-center ">
                  <a href="" data-bs-toggle="modal" data-bs-target="#medical">
                    {" "}
                    <b>
                      More Info <i className="ri-arrow-right-line" />{" "}
                    </b>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card mb-3">
              <div className="d-flex flex-row-reverse card-body p-2 align-items-center  ">
                <div className="p-2 w-100 ">
                  <h4>Pharmacy</h4>
                  <p>Pharmacy </p>
                </div>
                <div className="p-2 flex-shrink-1 ">
                  {" "}
                  <img
                    src="assets/images/icon/pharmacy.png"
                    className="img-fluid icon "
                    alt="pharmacy"
                  />
                </div>
              </div>
              <hr />
              <div className="d-flex justify-content-around">
                <p className="card-text">
                  <small className="text-muted ">3 Programmes </small>
                </p>
                <div className=" d-flex justify-content-center align-item-center ">
                  <a href="" data-bs-toggle="modal" data-bs-target="#pharmacy">
                    {" "}
                    <b>
                      More Info <i className="ri-arrow-right-line" />{" "}
                    </b>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card mb-3">
              <div className="d-flex flex-row-reverse card-body p-2 align-items-center  ">
                <div className="p-2 w-100 ">
                  <h4>Law</h4>
                  <p>Law</p>
                </div>
                <div className="p-2 flex-shrink-1 ">
                  {" "}
                  <img
                    src="assets/images/icon/law.png"
                    className="img-fluid icon "
                    alt="law"
                  />
                </div>
              </div>
              <hr />
              <div className="d-flex justify-content-around">
                <p className="card-text">
                  <small className="text-muted ">3 Programmes </small>
                </p>
                <div className=" d-flex justify-content-center align-item-center ">
                  <a href="" data-bs-toggle="modal" data-bs-target="#law">
                    {" "}
                    <b>
                      More Info <i className="ri-arrow-right-line" />{" "}
                    </b>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card mb-3">
              <div className="d-flex flex-row-reverse card-body p-2 align-items-center  ">
                <div className="p-2 w-100 ">
                  <h4>Arts </h4>
                  <p>Master of Arts</p>
                </div>
                <div className="p-2 flex-shrink-1 ">
                  {" "}
                  <img
                    src="assets/images/icon/arts.png"
                    className="img-fluid icon "
                    alt="arts"
                  />
                </div>
              </div>
              <hr />
              <div className="d-flex justify-content-around">
                <p className="card-text">
                  <small className="text-muted ">1 Programmes </small>
                </p>
                <div className=" d-flex justify-content-center align-item-center ">
                  <a href="" data-bs-toggle="modal" data-bs-target="#arts">
                    {" "}
                    <b>
                      More Info <i className="ri-arrow-right-line" />{" "}
                    </b>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card mb-3">
              <div className="d-flex flex-row-reverse card-body p-2 align-items-center  ">
                <div className="p-2 w-100 ">
                  <h4>IT &amp; Computer </h4>
                  <p>Computer and Technology</p>
                </div>
                <div className="p-2 flex-shrink-1 ">
                  {" "}
                  <img
                    src="assets/images/icon/technology.png"
                    className="img-fluid icon "
                    alt="technology"
                  />
                </div>
              </div>
              <hr />
              <div className="d-flex justify-content-around">
                <p className="card-text">
                  <small className="text-muted ">2 Programmes </small>
                </p>
                <div className=" d-flex justify-content-center align-item-center ">
                  <a href="" data-bs-toggle="modal" data-bs-target="#it">
                    {" "}
                    <b>
                      More Info <i className="ri-arrow-right-line" />{" "}
                    </b>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</>

    </div>
  )
}

export default PostGraduate