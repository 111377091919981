export const simsServices = {

    IPDOPDServices: [
        "Registration : All patients requiring any consultation, investigation, emergency care or treatment shall be registered with a unique registration number on their first visit to the hospital. S/he is added to the data pool of the hospital and the number becomes the unique identifier for all future interactions",
        "Purpose : To have a uniform process for registration of patients with unique identifiers for each patient and to maintain the records of patients coming to the hospital.",
        "Category of Patients",
        "Cash",
        "PMJAY",
        "Camp Patients",
        "Referred for consultation",
        "IPD / OPD Services",
      ],
    EmergencyServices: [
      "A dedicated emergency number – +91-9638052525 for efficient and effective post trauma care with",
      "Highly qualified team of on-campus super specialists.",
      "24X7 accesses to OT & ICU care.",
      "High tech infrastructure & support for post trauma resuscitation.",
      "Emergency Services",
    ],
    CentralLaboratory: [
      "Pathology",
      "Histopathology",
      "Hematology",
      "Cytology",
      "Clinical Pathology",
      "Microbiology",
      "Serology",
      "MycoBacteriology",
      "Bio Chemistry",
      "Clinical Chemistry",
      "Endo Craniology",
      "Advance Genetic & Molecular Biology Outsourcing",
      "The department provides referral services to accredited reference laboratories for esoteric tests and tests that are available in-house.",
      "Central Laboratory",
    ],
    Radiology: [
      "Digital radiography (x-ray unit):",
      "Routine x-rays of the body are performed for outpatients as well as in-patients with our digital radiography units.",
      "Advanced technology ultrasound units with color Doppler facilities: 1) 	Ultrasound is a form of diagnostic imaging that does not use ionizing radiation. . Instead sound waves are used, which reflect from the body tissues giving an image on the screen. There are three main areas where ultrasound is performed: 2) 	General ultrasound: for abdomen, pelvis and soft tissues of the body. 3) 	Obstetric ultrasound: performed to look out age of the fetus and to ensure healthy development of fetus. 3d and 4d sonography of fetus has broadened the horizon in obstetrical imaging. 4) 	ColorDoppler ultrasound: for abnormalities of arteries and veins of the body. Biopsy of masses and drainage of abscess or fluid can be performed under ultrasonography guidance.",
      "Radiology",
    ],
    Pharmacy: [
      "•	Pharmacy facilities are being provided within the hospital premises to serve the needs of patients and is being operated on institutional basis. Medicines are being dispensed on uniform rate and considerable discount is offered to all. It is well equipped with modern computers, other machines for proper storage of medicines which is efficiently managed by a team of professionals. Moreover, to ensure access to and appropriate use of medicines with the ultimate aim of the well-being of the patient, there is well trained and educated pharmacists with appropriate skills. All required and necessary licenses are maintained and regularized time to time.",
      "•	Useful info and health tips: 1) o	Keep it cool, dark and dry 2) o	Medicines should be kept in a cool, dry place and protected from light. 3) o	Exposure to light, heat and moisture can accelerate the breakdown of medicines, which may reduce their efficacy or cause superficial changes, e.g. discoloration. In a warm and humid country like Singapore, appropriate storage is thus particularly important.",
      "•	Keep away from children: 1) o	Poisoning incidents involving children are often attributable to the accidental ingestion. 2) o	These are usually preventable if medicines are stored appropriately. 3) o	Therefore, medicines should always be kept in a locked drawer/cabinet or at higher levels, beyond the reach of children. Medicine that has changed color, consistency or odor should never be used regardless of expiry.",
      "24*7 Pharmacy",
    ],
    BloodBank:[
      "•	The blood bank is committed to protecting the safety of both donors and potential recipients by providing quality blood and blood products from non-remunerated, healthy donors following a stringent screening process. Voluntary blood donation, being the safest form of blood donation, is encouraged. The blood bank provides for the blood requirements of patients within the hospital as well as various other hospitals and nursing homes in the city. The blood bank is licensed to prepare Whole Blood, Packed Red blood Cells, Platelet concentrate, Fresh Frozen plasma, Cryoprecipitate, Single donor platelets (platelets prepared by pheresis), Plasma pheresis and leucodepleted blood.",
      "•	Following blood donation, every blood unit is separated into its components and tested for ABO & Rh blood grouping, Antibody screen, Screening for infectious diseases such as HIV, Hepatitis B, Hepatitis C, Syphilis & Malarial Parasite with highly sensitive and fully automated technologies.",
      "•	The blood bank is equipped with the state of art equipment that enables use of advanced techniques for processing, screening and storage of blood, thus providing the assurance of quality blood products for all patients. As part of its ongoing quest for excellence, the blood bank is in the process of acquiring a separate NABH accreditation for the blood bank.",
      "Blood Bank",
    ],
    Dialysis: [
      "Overview : Overview The function of your kidneys is to purify your body by removing waste and excess fluid. Dialysis is a treatment used for people whose kidneys don't work properly. It's a common treatment that has been used for people with kidney problems since the 1940s.",
      "The Purpose of Dialysis : A properly functioning kidney helps prevent salt, extra water, and waste from accumulating in your body. It also helps control blood pressure and regulates important chemicals in the blood, such as sodium (salt) and potassium. When your kidneys don't perform these functions due to disease or injury, dialysis can help purify the blood and remove waste.",
      "How It Works : There are two different types of dialysis: 1. Hemodialysis involves using an artificial kidney, known as a hemodialyzer, to remove waste and chemicals from the blood. It accesses the blood through a minor surgical procedure in the arm or leg, or through a plastic tube in the neck called a catheter. 2. Peritoneal dialysis involves the surgical implantation of a catheter into your stomach area. During treatment, a special fluid called dialystate is pumped into the abdomen where it draws waste out of the bloodstream",
      "Desired Results : Not all kidney failure is permanent. Dialysis can temporarily aid the kidneys while they repair themselves. Treatment typically lasts until the kidneys begin to work on their own again. However, in chronic kidney failure, the kidneys rarely get better and dialysis is needed for the rest of your life or until a kidney transplant becomes an option.",
      "Dialysis",
    ],
    Ambulance: [
      "For providing emergency medical care which takes care of: ",
      "Basic Life Support.",
      "In transit preliminary medical care.",
      "Leading to optimal & timely interventions.",
      "Ambulance",
    ],
    PMJAY: [
      "PMJAY (Pradhan Mantri Jan Arogya Yojana) is a government healthcare scheme in India aimed at providing financial protection to poor and vulnerable families against hospitalization expenses. The services covered under PMJAY include:",
      "1. Hospitalization expenses: Coverage for medical and surgical procedures, including pre and post-hospitalization expenses, up to a specified limit.",
      "2. Secondary and tertiary care: Access to secondary and tertiary healthcare services for a wide range of medical conditions, including critical illnesses.",
      "3. Diagnostic services: Coverage for diagnostic tests and procedures essential for the diagnosis and management of medical conditions.",
      "4. Medications and consumables: Provision of essential medications, medical supplies, and consumables required during hospitalization.",
      "5. Consultation fees: Coverage for fees associated with consultations with healthcare providers, including specialists and surgeons.",
      "6. Transportation expenses: Reimbursement or provision of transportation expenses incurred for hospitalization, including ambulance services.",
      "7. Follow-up care: Coverage for follow-up consultations, medications, and diagnostic tests post-hospitalization, as deemed necessary by healthcare providers.",
      "8. Package rates: Services are provided based on predefined package rates for different medical procedures and treatments, ensuring transparency and standardization of costs.",
      "It's important to note that the specific services covered under PMJAY may vary slightly depending on the state or region in India and updates to the scheme's guidelines.",
      "PMJAY",
    ],
    VaccinationServices: [
      "A vaccination overview encompasses various aspects of immunization, including its importance, the process of vaccination, types of vaccines, benefits, and challenges. Here's a comprehensive overview:",
      "1.	Importance of Vaccination: 1.1.	Vaccination is one of the most effective public health interventions, preventing millions of deaths each year from infectious diseases. 1.2.	It helps protect individuals from serious diseases and complications, reduces the spread of infectious agents within communities, and contributes to the control and elimination of vaccine-preventable diseases. ",
      "2.	Process of Vaccination: 2.1.	Vaccination involves administering a vaccine, which contains weakened or killed pathogens, parts of pathogens, or synthetic antigens, to stimulate the immune system without causing the disease. 2.2.	The immune system responds to the vaccine by producing antibodies and memory cells, providing immunity against the targeted disease. 2.3.	Vaccines are typically administered through injections, but some can be given orally or nasally. ",
      "3.	Types of Vaccines: 3.1.	Live Attenuated Vaccines: Contains weakened forms of the pathogen (e.g., measles, mumps, rubella vaccines). 3.2.	Inactivated Vaccines: Contains killed pathogens (e.g., polio vaccine). 3.3.	Subunit, Recombinant, Polysaccharide, and Conjugate Vaccines: Contain specific components of the pathogen (e.g., hepatitis B vaccine, pneumococcal vaccine). 3.4.	mRNA Vaccines: Contain genetic material that instructs cells to produce viral proteins, triggering an immune response (e.g., COVID-19 mRNA vaccines). 3.5.	Vector Vaccines: Use harmless viruses or bacteria as vectors to deliver antigens (e.g., adenovirus-based COVID-19 vaccines). ",
      "4.	Benefits of Vaccination: 4.1.	Protects against infectious diseases and their complications, reducing morbidity and mortality. 4.2.	Helps achieve herd immunity, where a significant portion of the population is immune, providing indirect protection to those who are not vaccinated or cannot be vaccinated. 4.3.	Prevents outbreaks and epidemics, contributing to public health and economic stability. ",
      "5.	Challenges and Considerations: 5.1.	Vaccine Hesitancy: Some individuals may be reluctant to receive vaccines due to safety concerns, misinformation, or cultural beliefs. 5.2.	Access and Equity: Disparities in vaccine access, availability, and affordability can limit vaccination coverage, particularly in low-income and marginalized communities. 5.3.	Vaccine Development and Distribution: Developing and distributing vaccines requires significant resources, infrastructure, and collaboration among governments, healthcare organizations, and pharmaceutical companies. 5.4.	Vaccine Safety Monitoring: Ongoing monitoring of vaccine safety is essential to detect and address adverse events following immunization (AEFI) and maintain public trust in vaccination programs. ",
      "Overall, vaccination is a cornerstone of preventive medicine and public health, playing a vital role in protecting individuals and communities from infectious diseases. Effective vaccination programs require comprehensive strategies that address access barriers, build trust, and ensure the safety and efficacy of vaccines.",
      "Vaccination Services",
    ],
    CommunityHealthandOutreach: [
      "Community Health and Outreach",
    ]  

}