import React from "react";

// Image Urls

const classroom1 = "/assets/pharmacy/images/classroom-1.jpg";
const classroom2 = "/assets/pharmacy/images/classroom-2.jpg";
const centralInstrumentalLab1 = "/assets/pharmacy/images/central-instrumental-lab-1.jpg";
const centralInstrumentalLab2 = "/assets/pharmacy/images/central-instrumental-lab-2.jpg";
const computerLab = "/assets/pharmacy/images/computer-lab.jpg";
const library = "/assets/pharmacy/images/library.jpg";
const pharmaceuticalChemistryLab1 = "/assets/pharmacy/images/pharmaceutical-chemistry-lab-1.jpg";
const pharmaceuticalChemistryLab2 = "/assets/pharmacy/images/pharmaceutical-chemistry-lab-2.jpg";
const pharmacognosyLab = "/assets/pharmacy/images/pharmacognosy-lab.jpg";
const pharmacologyLab = "/assets/pharmacy/images/pharmacology-lab.jpg";

const PharmacyInfastructure1 = "/assets/pharmacy/images/infa1.png";
const PharmacyInfastructure2 = "/assets/pharmacy/images/infa2.png";


function Infrastructure() {
  return (
    <div className="ResidentialFacilities">
      <>
        <div className="campus-life-area pt-100 pb-70">
          <div className="container">
            <h3 className="text-center mb-4 ">Infrastructure Details</h3>
            <p className="text-center">
              Our college is well equipped with the state-of-the-art facilities
              for classrooms and laboratories in all departments.
            </p>
          </div>
        </div>

        <div className="row justify-content-center mt-5">
          <h3 className="text-center mb-4 ">Classrooms</h3>
          <div className="col-lg-4 col-md-6">
            <div className="single-campus-card">
              <div className="img">
                <a href="">
                  <img src={classroom1} alt="Image" />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single-campus-card">
              <div className="img">
                <a href="">
                  <img src={classroom2} alt="Image" height="410px" width="400%"/>
                </a>
              </div>
            </div>
          </div>
          
        </div>

        <div className="row justify-content-center mt-5">
          <h3 className="text-center mb-4 ">Central Instrumental Lab</h3>
          <div className="col-lg-4 col-md-6">
            <div className="single-campus-card">
              <div className="img">
                <a href="">
                  <img src={centralInstrumentalLab1} alt="Image" />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single-campus-card">
              <div className="img">
                <a href="">
                  <img src={centralInstrumentalLab2} alt="Image" />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center mt-5">
          <h3 className="text-center mb-4 ">Computer Lab</h3>
          <div className="col-lg-4 col-md-6">
            <div className="single-campus-card">
              <div className="img">
                <a href="">
                  <img src={computerLab} alt="Image" />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center mt-5">
          <h3 className="text-center mb-4 ">Library</h3>
          <div className="col-lg-4 col-md-6">
            <div className="single-campus-card">
              <div className="img">
                <a href="">
                  <img src={library} alt="Image" />
                </a>
              </div>
            </div>
          </div>
        </div>
        
        <div className="row justify-content-center mt-5">
          <h3 className="text-center mb-4 ">Pharmaceutical Chemistry Lab</h3>
          <div className="col-lg-5 col-md-6">
            <div className="single-campus-card">
              <div className="img">
                <a href="">
                  <img src={pharmaceuticalChemistryLab1} alt="Image" height={400} width="100%"/>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-6">
            <div className="single-campus-card">
              <div className="img">
                <a href="">
                  <img src={pharmaceuticalChemistryLab2} alt="Image" height={400} width="100%"/>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-6">
            <div className="single-campus-card">
              <div className="img">
                <a href="">
                  <img src={PharmacyInfastructure1} alt="Image" height={400} width="100%"/>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-6">
            <div className="single-campus-card">
              <div className="img">
                <a href="">
                  <img src={PharmacyInfastructure2} alt="Image" height={400} width="100%"/>
                </a>
              </div>
            </div>
          </div>
          
        </div>

        <div className="row justify-content-center mt-5">
          <h3 className="text-center mb-4 ">Pharmacognosy Lab</h3>
          <div className="col-lg-4 col-md-6">
            <div className="single-campus-card">
              <div className="img">
                <a href="">
                  <img src={pharmacognosyLab} alt="Image" height={400} width="100%"/>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center mt-5">
          <h3 className="text-center mb-4 ">Pharmacology Lab</h3>
          <div className="col-lg-4 col-md-6">
            <div className="single-campus-card">
              <div className="img">
                <a href="">
                  <img src={pharmacologyLab} alt="Image" height={400} width="100%"/>
                </a>
              </div>
            </div>
          </div>
        </div>

        
        {/* <div className="register" style={{maxWidth:'1320px'}}>
 <div className="container-fluid">
 <div className="row justify-content-center mt-2  p-0">
    <div className="col-lg-6 col-md-6">
        <h3>Girls Hostel</h3>
     <table className="table table-bordered text-center">
        <thead className='bg-danger text-light p-3'>
            <tr>
                <th>Students</th>
                <th>Facility</th>
                <th>Fees/Annum</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>3 Students</td>
                <td rowSpan="3">Non AC Room, <br/> Attached Bathroom</td>
                <td>₹ 85,000/-</td>
            </tr>
            <tr>
                <td>4 Students</td>
               
                <td>₹ 80,000/-</td>
            </tr>
            <tr>
                <td>8 Students</td>
               
                <td>₹ 70,000/-</td>
            </tr>
        </tbody>
     </table>
    </div>
    <div className="col-lg-6 col-md-6">
        <h3>Boys Hostel</h3>
     <table className="table table-bordered text-center">
        <thead className='bg-danger text-light'>
            <tr>
                <th>Students</th>
                <th>Facility</th>
                <th>Fees/Annum</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>5 Students</td>
                <td rowSpan="3">Non AC Room, <br/> Attached Bathroom</td>
                <td>₹ 70,000/-</td>
            </tr>
            
        </tbody>
     </table>
    </div>

    <div className="col-md-12 mt-3 mb-5">
        <h4 className='text-center'>Transportation Facility From Ahmedabad & Gandhinagar <Link className='text-danger' to="/Transportation">Click here</Link> Check the Rout</h4>
    </div>
  </div>
 </div>
  </div> */}
      </>
    </div>
  );
}

export default Infrastructure;
