import React from 'react';
import axios from "axios";
import { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Parser } from "html-to-react";
import { ACHIEVEMENTS_GET } from '../Utils/InfoUploadingConst';

function AchievementsDetails() {

    const locate = useLocation();
  const navigate = useNavigate();

  // let images = locate.state.images;

  let x = locate.state.data.date.split("T")[0];
  // let data = locate.state.data;
  const [data, setData] = useState(locate.state.data);
  const [images, setImages] = useState(locate.state.images);
  const htmlParser = new Parser();

  const college_id = data?.college_id;

  // console.log("images -", images);
  // console.log("data -", data?.college_id);

  const [info, setInfo] = useState([]);

  function customSortByDate(item1, item2) {
    return item2.date.localeCompare(item1.date);
  }

  const fetchData = async (collegeId) => {
    const config = {
      method: "get",
      url: ACHIEVEMENTS_GET,
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await axios(config);
      const responseData = response.data.data;

      // Sort the data by ID in descending order
      // responseData.sort((a, b) => b.id - a.id);
      responseData.sort(customSortByDate);

      // Filter recent events by college_id and limit to 4 items
      const recentData = responseData
        .filter((element) => element.college_id === data.college_id && element.status === "ACTIVE")
        .slice(0, 4);

      setInfo(recentData);
    } catch (err) {
      console.log(err);
    }
  };

  const handleClick = (item) => {
    // Set clicked item's data to the data state
    setData(item);

    // Set clicked item's images to the images state
    setImages(JSON.parse(item.attachments)); // Assuming item.attachments is a JSON string

    // Navigate to the eventsDetails page with updated state
    const pathParts = locate.pathname.split('/');
    const newPath = `${pathParts.slice(0, -1).join('/')}/${item.id}`;

  navigate(newPath, {
    state: {
      images: JSON.parse(item.attachments),
      data: item,
    },
  });
  };

  useEffect(() => {
    // Fetch recent events related to the initial data's college_id
    fetchData(data?.college_id);
  }, [data]);

console.log("info data-", data);

  return (
    <div>

    <div className="container-fluid register"  style={{maxWidth: "90%"}}>
        <h3>ACHIEVEMENTS, AWARDS AND MEDALS DETAILS</h3>
        <div className="row">
          <div className="col-sm-8">
            <div className="events-details-left-content pr-20">
              {images.length > 1 ? (

                <div className="banner-area">
                  <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-indicators">
                      {images && images
                        ?.map((item, index) => (
                          <button
                            key={index}
                            type="button"
                            data-bs-target="#carouselExampleCaptions"
                            data-bs-slide-to={index}
                            className={index === 0 ? "active" : ""}
                            aria-label={`Slide ${index + 1}`}
                          />
                        ))}
                    </div>
                    <div className="carousel-inner">
                      {images && images
                        ?.map((item, index) => (
                          <div key={index} className={`carousel-item ${index === 0 ? "active" : ""}`} data-bs-interval="7000">
                            <img src={item} className="d-block w-100 img-fluid h-80 main-slider" alt="..." />
                              <div className="carousel-caption d-none d-md-block">
                                <h1 className="text-light">{item.title}</h1>
                              </div>
                          </div>
                      ))}
                    </div>
                    <button
                      className="carousel-control-prev"
                      type="button"
                      data-bs-target="#carouselExampleCaptions"
                      data-bs-slide="prev"
                    >
                    <span className="carousel-control-prev-icon" aria-hidden="true" />
                    <span className="visually-hidden">Previous</span>
                    </button>
                    <button
                      className="carousel-control-next"
                      type="button"
                      data-bs-target="#carouselExampleCaptions"
                      data-bs-slide="next"
                    >
                    <span className="carousel-control-next-icon" aria-hidden="true" />
                    <span className="visually-hidden">Next</span>
                    </button>
                  </div>
                </div>

              ) : (
                <div className="events-image text-center">
                  <img
                    className="img-fluid"
                    src={images[0]}
                    alt="Image"
                  />
                </div>
              )}
              <div className="meetings mt-3">
                <h2>{data?.title}</h2>
                <p className="text-muted">
                  {x.slice(-2)} - {data?.month} - {data?.year}
                </p>
                <p>{htmlParser.parse(data?.description)}</p>
              </div>
            </div>
          </div>

          <div className="col-sm-4">
            <br/>
            <br/>
            <br/>
            {/* <br/> */}
            <div class="tranding">
              <br/>
              <h3>Recent Events</h3>
              <br/>

              {info?.map((item, index) => {
                // Parse the attachments JSON string into an array
                const attachmentsArray = JSON.parse(item.attachments);

                // Get the first image URL if attachmentsArray has at least one item
                const firstImage = attachmentsArray.length > 0 ? attachmentsArray[0] : '';

                return (
                  <div className="tranding-box" key={index}>
                    <a 
                      onClick={() => handleClick(item)}
                    >
                    <div className="tranding-content">
                      <img src={firstImage} alt="Image" />
                      <h4>
                        {item.title}
                      </h4>
                      <p>{item?.date.split("T")[0]}</p>
                    </div>
                    </a>
                  </div>
                );
              })}

            </div>
          </div>          
        </div>
      </div>
      
    </div>
  )
}

export default AchievementsDetails;
