import React from "react";
import axios from "axios";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

function RulesAyu() {
  

  return (
    <div>
      <div className="container mt-5 mb-5">
        <div className="row register" style={{ maxWidth: "100%" }}>
          <div className="col-md-12">
            <h3>
              Rules & Regulations
            
            </h3>
            <p>Rules and Rugulations documents Link are below</p>
          </div>

        
<div className="container">

  
<div className="alumni-area pt-100 pb-70">
<div className="row">

<div className="col-md-4">
<a href="/assets/ayurveda/documents/rul-reg-mse-ay-2016-9-7.pdf" target="_blank">
  <div className="single-alumoni-updates-card">
  <div className=" d-flex flex-row flex-wrap align-items-center ">
    <div className=" bg-danger text-center p-3 me-3">
    <i class="ri-file-download-line text-light " style={{fontSize:"20px"}}></i>
    </div>
   <div className="">
    <p>
      Ayurveda Rules & Regulations
    </p>
   </div>
  </div>
</div>
</a>
  </div>
  

</div>

</div>
</div>
         
        </div>
        <br />
        <br />
      </div>
    </div>
  );
}

export default RulesAyu;
