import axios from "axios";
import React from "react";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import Loader from "../../Components/Loader/Loader.jsx";
import { GET_EXAM_FORM } from "../../Utils/PHDapiconst.js";
import { useReactToPrint } from "react-to-print";
import { sessionOpt } from "../../Data/sessionData.js";
import { ROUTES } from "../../Router/RouterConfig";

const PhdFormPrint = () => {
  const [details, setDetails] = useState([]);
  const navigate = useNavigate();
  let Phdid = localStorage.getItem("Phdid");

  const [loading, setLoading] = useState(0);

  const refToPrint = useRef();

  const handlePrint = useReactToPrint({
    content: () => refToPrint.current,
    documentTitle: "PHD Exam Form",
  });

  const getData = async () => {
    setLoading(1);
    const config = {
      url: `${GET_EXAM_FORM}?regNo=${Phdid}`,
      method: "get",
      Headers: {
        "content-type": "application/json",
      },
    };
    await axios(config)
      .then((res) => {
        setLoading(0);
        console.log(res.data.data);
        setDetails(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const route = () => {
    navigate(ROUTES.PhdExamForm);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <div className="container-fluid">
        <Loader loading={loading} />
        <div className="card-body" ref={refToPrint}>
          <div className="row">
            <div className="col-12 d-flex justify-content-center mb-5">
              <img
                src="/assets/images/white-logo.png"
                style={{ width: "300px", margin: "auto" }}
                alt=""
              />
            </div>
            <div className="col-md-12 d-flex justify-content-center ">
              <h1>PhD Examination Form 2023-24</h1>
            </div>
            <hr />
          </div>

          {details.length > 0
            ? details &&
              details?.map((item, key) => {
                return (
                  <div>
                    <div className="row mx-auto mt-5">
                      <h4>PERSONAL INFORMATION</h4>
                    </div>

                    <div className="">
                      <div className="row mx-auto mt-3">
                        <div className="col-md-4 mb-3">
                          <div className="form-group">
                            <label htmlFor="">
                              Enrollment Number{" "}
                              <small className="text-danger">*</small>{" "}
                            </label>
                            <input
                              type="text"
                              readOnly={true}
                              name="regNo"
                              className="form-control"
                              value={item?.regNo}
                            />
                          </div>
                        </div>

                        <div className="col-md-4 mb-3">
                          <div className="form-group">
                            <label htmlFor="">
                              {" "}
                              Year/Session Of Examination{" "}
                              <small className="text-danger">*</small>
                            </label>
                            <select
                              className="form-control"
                              value={item?.year}
                              name="year"
                              disabled="true"
                            >
                              <option value="">2023</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="">
                              Name of Candidate{" "}
                              <small className="text-danger">*</small>
                            </label>
                            <input
                              type="text"
                              name="name"
                              className="form-control"
                              value={item?.name}
                              readOnly={true}
                            />
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="">
                              Phone number{" "}
                              <small className="text-danger">*</small>
                            </label>
                            <input
                              type="number"
                              name="contact"
                              className="form-control"
                              value={item?.contact}
                              readOnly={true}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="mx-auto row flex">
                        <div className="col-md-4 mt-4">
                          <div className="form-group">
                            <label htmlFor="">
                              Email <small className="text-danger">*</small>
                            </label>
                            <input
                              type="text"
                              name="email"
                              readOnly={true}
                              className="form-control"
                              value={item?.email}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 mt-4">
                          <div className="form-group">
                            <label htmlFor="">
                              Address <small className="text-danger">*</small>
                            </label>
                            <textarea
                              readOnly={true}
                              name="address"
                              className="form-control"
                              value={item?.address}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 mt-4">
                          <div className="form-group">
                            <label htmlFor="">
                              City <small className="text-danger">*</small>
                            </label>
                            <input
                              type="text"
                              readOnly={true}
                              name="place"
                              className="form-control"
                              value={item?.place}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="mx-auto row flex">
                        <div className="col-md-4 mt-4">
                          <div className="form-group">
                            <label htmlFor="">Research Methodology</label>
                            <input
                              type="text"
                              name="research_methodology"
                              className="form-control"
                              readOnly={true}
                              value={item?.research_methodology}
                            />
                          </div>
                        </div>

                        <div className="col-md-4 mt-4">
                          <div className="form-group">
                            <label htmlFor="">Core Subject Paper</label>
                            <input
                              type="text"
                              name="core_subject_paper"
                              className="form-control"
                              readOnly={true}
                              value={item?.core_subject_paper}
                            />
                          </div>
                        </div>

                        <div className="col-md-4 mt-4">
                          <div className="form-group">
                            <label htmlFor="">DOB (DD/MM/YYYY) </label>
                            <input
                              type="date"
                              name="dob"
                              className="form-control"
                              readOnly={true}
                              value={item?.dob?.split("T")[0]}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="mx-auto flex row">
                        <div className="col-md-4 mt-4">
                          <div className="form-group">
                            <label htmlFor="">
                              Gender <small className="text-danger">*</small>
                            </label>
                            <select
                              name="gender"
                              className="form-control"
                              readOnly={true}
                              value={item?.gender}
                            >
                              <option value="">Select Gender</option>
                              <option value={"Male" || "male"}>Male</option>
                              <option value={"Female" || "female"}>
                                Female
                              </option>
                              <option value="Other">Other</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-4 mt-4">
                          <div className="form-group">
                            <label htmlFor="">
                              Subject <small className="text-danger">*</small>
                            </label>
                            <input
                              name="subject"
                              className="form-control"
                              value={item?.subject}
                              type="text"
                              readOnly={true}
                            />
                          </div>
                        </div>

                        <div className="col-md-4 mt-4">
                          <div className="form-group">
                            <label htmlFor="">
                              Discipline{" "}
                              <small className="text-danger">*</small>
                            </label>
                            <input
                              type="text"
                              name="discipline"
                              className="form-control"
                              value={item?.discipline}
                              readOnly={true}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="mx-auto flex row">
                        <div className="col-md-4 mt-4">
                          <div className="form-group">
                            <label htmlFor="">
                              Session <small className="text-danger">*</small>
                            </label>
                            <select
                              name="session"
                              className="form-control"
                              value={item?.session}
                              disabled="true"
                            >
                              <option value="">Select Session</option>
                              {sessionOpt &&
                                sessionOpt.map((item, key) => (
                                  <option value={item.id} key={key}>
                                    {item?.name}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>

                        <div className="col-md-4 mt-4">
                          <div className="form-group">
                            <label htmlFor="">
                              Date Of Submission (DD/MM/YYYY){" "}
                            </label>
                            <input
                              type="date"
                              name="date"
                              className="form-control"
                              readOnly={true}
                              value={item?.date.split("T")[0]}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row mx-auto mt-5">
                        <h4>SUPERVISOR INFORMATION</h4>
                      </div>

                      <div className="mx-auto flex row">
                        <div className="col-md-4 mt-4">
                          <div className="form-group">
                            <label htmlFor="">
                              {" "}
                              Name <small className="text-danger">*</small>
                            </label>
                            <input
                              className="form-control"
                              name="supervisor_name"
                              type="text"
                              value={item?.supervisor_name}
                              readOnly={true}
                            />
                          </div>
                        </div>

                        <div className="col-md-4 mt-4">
                          <div className="form-group">
                            <label htmlFor="">
                              {" "}
                              Designation{" "}
                              <small className="text-danger">*</small>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              value={item?.supervisor_desig}
                              name="supervisor_desig"
                              readOnly={true}
                            />
                          </div>
                        </div>

                        <div className="col-md-4 mt-4">
                          <div className="form-group">
                            <label htmlFor="">
                              {" "}
                              Address <small className="text-danger">*</small>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              value={item?.supervisor_address}
                              name="supervisor_address"
                              readOnly={true}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-center my-4 d-print-none">
                        <button
                          className="btn d-flex justify-content-center align-items-center rounded text-light"
                          style={{ background: "#E32845" }}
                          onClick={handlePrint}
                        >
                          {" "}
                          <i className="ri-file-download-line"></i> &nbsp;&nbsp;
                          Print / Download
                        </button>

                        <button
                          className="btn btn-danger btn-lg float-end"
                          style={{ marginLeft: "2rem" }}
                          onClick={() => route()}
                        >
                          Go Back
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })
            : null}
        </div>
      </div>
    </div>
  );
};

export default PhdFormPrint;
