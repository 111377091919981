import React from "react";

export default function Funding() {
  return (
    <div>
      <div className="container register mb-5" style={{ maxWidth: "1300px" }}>
        <div className="row">
          <h3 className="col-md-12 text-uppercase">
            Any funding project inhouse (Internal) and external funding project
            or SSIP grant
          </h3>
          <div className="col-md-12" style={{ textAlign: "justify" }}>
            Research is the foundation of a country. It is said so because
            substantial research plays a pivotal role in pushing the nation
            forward. Having built Indus to contribute to a more significant
            cause, which is to work towards the nation's progress, encourages
            students to develop valuable outcomes through research and
            innovation.
          </div>
          <div className="col-md-12" style={{ textAlign: "justify" }}>
            <br />
          </div>
          <div className="col-md-12" style={{ textAlign: "justify" }}></div>
          <div className="col-md-12" style={{ textAlign: "justify" }}>
            <strong />
            <br />
          </div>
          <div className="col-md-12" style={{ textAlign: "justify" }}>
            <table
              className="table table-bordered text-center"
              style={{ width: "100%", borderCollapse: "collapse", height: 100 }}
              cellSpacing={5}
              cellPadding={5}
              border={1}
            >
              <tbody>
                <tr>
                  <td style={{ width: "20.8668%" }}>
                    <strong>Student Name</strong>
                  </td>
                  <td style={{ width: "28.7596%" }}>
                    <strong>Enrollment Number</strong>
                  </td>
                  <td style={{ width: "28.7596%" }}>
                    <strong>Name of Activity</strong>
                  </td>
                  <td style={{ width: "28.7596%" }}>
                    <strong>Grant</strong>
                  </td>
                </tr>

                <tr>
                  <td>Patel Krutik A</td>
                  <td>186710306055</td>
                  <td rowSpan={3}>
                    Vishwakarma Yojna An Approach Towards Urbanization Palsana
                    village
                  </td>
                  <td rowSpan={3}>66000/-</td>
                </tr>

                <tr>
                  <td>Pranav Dave</td>
                  <td>186710306010</td>
                </tr>

                <tr>
                  <td>Vivek Tiwari</td>
                  <td>186710309092</td>
                </tr>

                <tr>
                  <td>Patel Jaiminkumar</td>
                  <td>181153102041</td>
                  <td rowSpan={5}>
                    Fabrication of Bobber Bike by using of Scrap
                  </td>
                  <td rowSpan={5}>22000/-</td>
                </tr>

                <tr>
                  <td>Zinzuwadia Nirav</td>
                  <td>181153102001</td>
                </tr>

                <tr>
                  <td>Adraja Jaydip</td>
                  <td>181153102001</td>
                </tr>

                <tr>
                  <td>Patel Rajat</td>
                  <td>161150102004</td>
                </tr>

                <tr>
                  <td>Chavada Harshraj</td>
                  <td>181153102005</td>
                </tr>
              </tbody>
            </table>
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
