import React from 'react'
import './Brochure.scss'

function Brochure() {
  return (
    <div className='Brochure'>
        <>
  <div className="events-area pb-70">
    <div className="container">
      <div className="row  ">
        <div className="row zoom-galler photos justify-content-center">
          <div className="col-lg-4 col-md-6">
            <div className="single-events-card style-3">
              <div className="events-image">
                <a
                  href="assets/images/brouchers/phdadmission.jpeg"
                  data-lightbox="photos"
                >
                  <img
                    className="img-fluid"
                    src="assets/images/brouchers/phdadmission.jpeg"
                    alt="engineering poster"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single-events-card style-3">
              <div className="events-image">
                <a
                  href="assets/images/brouchers/admission_poster.jpeg"
                  data-lightbox="photos"
                >
                  <img
                    className="img-fluid"
                    src="assets/images/brouchers/admission_poster.jpeg"
                    alt="admission poster"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</></div>

  )
}

export default Brochure