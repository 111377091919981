export const serviceData = {
  generalMedicine: [
    "Patient Consultations in Outpatient and Inpatient Department",
    "Patient Management in Inpatient Department",
    "Diabetes Management",
    "Hypertension Management",
    "Obesity Management",
    "Management of all cardiac diseases",
    "Management of Critically Ill patient with ventilatory support (invasive and non-invasive)",
    "Pleurocentesis (Pleural Tapping)",
    "Paracentesis (Ascitic Tapping)",
    "ICD Tube Insertion",
    "Lumbar Puncture",
    "Dialysis unit",
    "Electrocardiography (ECG)",
    "Echocardiogram (Echo)",
    "Pulmonary Function Test (PFT)",
    "Medical Intensive Care Unit (MICU)",
    "Intensive Coronary Care Unit (ICCU)",
    "General Medicine",
  ],
  generalSurgery: [
    "Abdominal surgery",
    "Emergency surgical interventions",
    "Minimally invasive procedures",
    "Hernia repair",
    "Gallbladder and appendix removal",
    "Colorectal surgery",
    "Thyroid and parathyroid surgery",
    "Breast surgery, including mastectomy and lumpectomy",
    "Endocrine surgery",
    "Gastrointestinal surgery",
    "Trauma surgery",
    "Laparoscopic surgeries",
    "Upper gastrointestinal surgery, including stomach and esophageal procedures",
    "Liver and pancreatic surgery",
    "Vascular surgery for arterial and venous conditions",
    "General Surgery",
  ],
  ent: [
    "Diagnosis and treatment of ear disorders",
    "Diagnosis and treatment of nasal disorders",
    "Diagnosis and treatment of throat disorders",
    "Hearing assessment and management",
    "Tinnitus evaluation and management",
    "Allergy testing and treatment",
    "Management of sinus conditions",
    "Evaluation and treatment of voice disorders",
    "Head and neck cancer evaluation and management",
    "Pediatric ENT care",
    "ENT",
  ],
  anesthesiology: [
    "General anesthesia administration",
    "Regional anesthesia techniques",
    "Sedation for various medical procedures",
    "Preoperative assessment and optimization",
    "Intraoperative monitoring and support",
    "Postoperative pain management",
    "Acute pain management interventions",
    "Chronic pain management consultations",
    "Obstetric anesthesia for labor and delivery",
    "Pediatric anesthesia services",
    "Cardiothoracic anesthesia for heart and lung surgeries",
    "Neurosurgical anesthesia for brain and spine procedures",
    "Ambulatory anesthesia for outpatient surgeries",
    "Anesthesiology",
  ],
  dermatology: [
    "Diagnosis and treatment of skin conditions",
    "Skin cancer screening and management",
    "Acne treatment and management",
    "Psoriasis management",
    "Eczema (dermatitis) treatment",
    "Wound care and management",
    "Dermatologic surgery (e.g., mole removal, skin biopsies)",
    "Hair and scalp disorders treatment",
    "Nail disorders treatment",
    "Cosmetic dermatology procedures (e.g., Botox, fillers)",
    "Management of allergic skin reactions",
    "Pediatric dermatology services",
    "Dermatology",
  ],
  orthopedic: [
    "Fracture management and casting",
    "Joint replacement surgeries (e.g., hip, knee)",
    "Arthroscopic procedures for joint conditions",
    "Treatment of sports injuries (e.g., ACL tears)",
    "Spinal surgeries (e.g., laminectomy, spinal fusion)",
    "Hand and wrist surgeries (e.g., carpal tunnel release)",
    "Foot and ankle surgeries (e.g., bunionectomy)",
    "Treatment of orthopedic trauma (e.g., dislocations)",
    "Management of degenerative joint diseases (e.g., osteoarthritis)",
    "Pediatric orthopedic care (e.g., congenital deformities)",
    "Orthopedic oncology (e.g., bone tumor resection)",
    "Management of soft tissue injuries (e.g., tendon repair)",
    "Orthopedic rehabilitation and physical therapy",
    "Arthritis management and joint injections",
    "Orthopedic prosthetics and orthotics fitting",
    "Orthopedic",
  ],
  dentistry: [
    "Routine dental exams and cleanings",
    "Dental X-rays and imaging",
    "Fillings and restorations for cavities",
    "Tooth extractions",
    "Root canal therapy",
    "Dental implants",
    "Gum disease treatment (periodontal therapy)",
    "Oral surgery procedures (e.g., wisdom teeth removal)",
    "Pediatric dentistry (care for children)",
    "Dentures and partials",
    "TMJ disorder treatment",
    "Oral cancer screenings",
    "Dentistry",
  ],
  respiratoryMedicine: [
    "Diagnosis and treatment of asthma",
    "Management of chronic obstructive pulmonary disease (COPD)",
    "Evaluation and treatment of pneumonia",
    "Diagnosis and management of lung infections",
    "Treatment of bronchitis",
    "Pulmonary function testing (spirometry)",
    "Management of pulmonary fibrosis",
    "Sleep apnea evaluation and treatment",
    "Treatment of bronchiectasis",
    "Diagnosis and management of lung cancer",
    "Treatment of pulmonary embolism",
    "Oxygen therapy and home mechanical ventilation",
    "Management of interstitial lung diseases",
    "Lung cancer screening and early detection",
    "Smoking cessation counselling and support",
    "Respiratory Medicine",
  ],
  obstetricsAndGynecology: [
    "Prenatal care and pregnancy management",
    "Obstetric ultrasound imaging",
    "Labor and delivery management",
    "Caesarean section deliveries",
    "Vaginal deliveries",
    "High-risk pregnancy care",
    "Fetal monitoring during labor",
    "Family planning counseling and contraception services",
    "Pap smears and cervical cancer screening",
    "Breast exams and mammography referrals",
    "Management of menstrual disorders",
    "Diagnosis and treatment of infertility",
    "Menopause management and hormone therapy",
    "Gynecological surgery (e.g., hysterectomy, ovarian cystectomy)",
    "Evaluation and treatment of pelvic pain",
    "Contraceptive implant insertion and removal",
    "Counselling and support for survivors of sexual assault",
    "Adolescent gynaecology and reproductive health services",
    "Obstetrics And Gynecology",
  ],
  ophthalmology: [
    "Comprehensive eye examinations",
    "Diagnosis and management of refractive errors (e.g., myopia, hyperopia)",
    "Treatment of eye conditions such as glaucoma and cataracts",
    "Management of retinal disorders including macular degeneration and diabetic retinopathy",
    "Prescription of corrective lenses and contact lenses",
    "Treatment of ocular infections and inflammations",
    "Pediatric eye care and management of childhood eye conditions",
    "Evaluation and management of ocular trauma and injuries",
    "Screening for eye diseases and vision disorders",
    "Ophthalmology",
  ],
  psychiatry: [
    "Psychiatric evaluations and assessments",
    "Diagnosis and treatment of mental health disorders",
    "Medication management for psychiatric conditions",
    "Psychotherapy and counseling sessions",
    "Crisis intervention and suicide prevention",
    "Substance abuse evaluations and treatments",
    "Management of mood disorders (e.g., depression, bipolar disorder)",
    "Anxiety disorders treatment (e.g., generalized anxiety disorder, panic disorder)",
    "Psychiatric consultation for medical and surgical conditions",
    "Collaboration with other healthcare providers for comprehensive care",
    "Psychiatry",
  ],
  physiotherapy: [
    "Rehabilitation after injury or surgery",
    "Pain management through exercise and manual therapy",
    "Improvement of mobility and range of motion",
    "Strengthening exercises for muscles and joints",
    "Treatment for sports injuries and musculoskeletal conditions",
    "Balance and coordination training",
    "Postural correction and ergonomic advice",
    "Neurological rehabilitation for conditions like stroke or spinal cord injury",
    "Respiratory physiotherapy for lung conditions",
    "Patient education on self-management techniques",
    "Physiotherapy",
  ],
  OpdServices: [
    "Casualty (Emergency Medicine)",
    "General Medicine",
    "General Surgery",
    "ENT",
    "Dermatology",
    "Orthopedic",
    "Dentistry",
    "Respiratory Medicine",
    "Obstetrics and Gynecology",
    "Ophthalmology",
    "Neuro Surgery",
    "Urology",
    "Nephrology",
    "Gastrology",
    "Oncology",
    "Psychiatry",
    "OPD Services",
  ],
  IpdServices: [
    "Casualty (Emergency Medicine)",
    "General Medicine",
    "General Surgery",
    "ENT",
    "Dermatology",
    "Orthopedic",
    "Dentistry",
    "Respiratory Medicine",
    "Obstetrics and Gynecology",
    "Ophthalmology",
    "Neuro Surgery",
    "Urology",
    "Nephrology",
    "Gastrology",
    "Oncology",
    "Psychiatry",
    "IPD Services",
  ],
  healthCheckup: [
    "Basic Body Profile (72 tests) - ₹770",
    "Male Complete Profile (74 Tests) - ₹1100",
    "Female Complete Profile (74 Tests) - ₹1100",
    "Liver Function Test - ₹210",
    "Kidney Function Test - ₹180",
    "ANC Profile - ₹420",
    "Enzyme Test - ₹320",
    "Hormonal Test(Female) - ₹1100",
    "Diabetic Profile - ₹400",
    "Anameia Profile - ₹650",
    "Thyroid Profile(B) - ₹350",
    "Thyroid Profile(A) - ₹300",
    "Fever Test Profile - ₹170",
    "Infertility Profile - ₹550",
    "Joint Pain and Arthitis Profile - ₹365",
    "Cardiac Profile - ₹1140",
    "Health Check Up",
  ],
  Doctors: [
    "Dr. Ashwin J. Sanghavi - M.D.,D Ped, F.I.A.P.",
    "Dr. Daivesh Pankaj Shah - M.S.Orthopedics",
    "Dr. Vishwa Davra - MD Pathology",
    "Dr. Sanjay Mehta - MBBS, MD (Microbiology)",
    "Dr hitesh balubhai patel - MD (medicine)",
    "Dr.Rinkal Diwan - M.S.ENT",
    "Dr Bhargav Bhaliya - MD Medicine,FCCS",
    "Dr Mahimna Vasavda - M.B.B.S, D.Ortho",
    "Dr. Rajendra Upadhyay - MS surgery",
    "DR IVVALA ANAND SHAKER - PhD (MEDICAL BIOCHEMISTRY)",
    "Dr. Nakul Sule - MBBS, MS , FIAGES",
    "Dr. Sandeep Mithapara - M.D.S. ( Maxillofacial Surgeon ), Fellowship in Head and Neck Cancer",
    "Dr. Bharat Gandhi - MD(Radiology), DMRE",
    "Dr. Hardik Patel - MBBS, DNB PSYCHIATRY",
    "Dr.Jyotin s shah - M.D",
    "Dr. Dhwaniben R Makwana - MBBS, DNB (Dermatology)",
    "Dr. Jignesh Jethva - MS",
    "Dr Parth Upadhyay - BDS, MDS Orthodontist and Dentofacial Orthopaedics",
    "Dr.Jaimini parmar - MBBS MD anesthesiology",
    "Dr Shubham Suryavanshi - MBBS, MD RADIOLOGY",
    "Dr Twinkle Hoon - MD Microbiology",
    "Dr. Mittal Panchal - MBBS, MD- BIOCHEMISTRY",
    "Dr. Parimal Gandhi - MD Pathology",
    "Dr Dimple Yadav - M.sc Medical Microbiology",
    "Akshita Choudhary - M.Sc medical",
    "Dr. Nilaykumar Patel - MD",
    "Dr. Rutvij Pancholi - MS Orthopedic",
    "Dr. Ridhdhi B.Patel - MD Microbiologist",
    "Dr Deep Parmar - Neuro & Spine surgeon",
    "Dr. Aarti Patel - Dch DNB pediatrics",
    "Dr. Nija Shah - M.S. Ent and head and neck surgeon",
    "Dr. Dhrumin Prajapati - MD Respiratory Medicine",
    "DR SHAILESHKUMAR C BANKER - MS (OBST & GYNEC)",
    "Dr Shivani Vora - MD pathology",
    "Dr Prasanna Shah - MBBS, MS ORTHO, Diploma in Sports Medicine, FIAS, FARCS",
    "Dr. Patel Kaushalkumar Ghanshyambhai - MBBS",
    "Dr.Kundan Malhotra - M.S.Ophthamologist",
    "Dr Ekta Gajjar - MD PEDIATRICS",
    "Dr.Sachinkumar Mansukhlal Darji - M.D. Microbiology",
    "Dr. Vyas Hardik - M S - General surgery",
    "Dr Akshay patel - MS Ortho",
    "Dr. Jaishil Panchal - MS",
    "Dr. Nitin Maliwad - MD General Medicine",
    "Doctors",
  ]
};
