import React from 'react'

function Infrastructure() {
  return (
    <div className='Infrastructure'>
        <div className="academics-details-area pt-90 pb-70">
  <div className="container">
    <div className="row">
      <div className="col-lg-12">
        <div className="academics-details">
          <div className="details">
            <h2>Infrastructure</h2>
            <p style={{ textAlign: "justify" }}>
              SSGV has provided all the modern infrastructure facilities as per
              the norms of AICTE, AYUSH, NCISM, NCH, PCI, NCTE, BCI, Gujarat
              Technological University, Gujarat University, Gujarat Ayurved
              University and other apex bodies. SSGV group of Institutes have
              plenty of amenities including a gigantic auditorium, huge sports
              ground and Institute building is segmented in well-equipped
              audiovideo Classrooms, spacious Departments with well- equipped
              laboratories, Store, student support and counseling cell, Ladies
              room, Boy’s room, Sports room, Conference hall, Administrative
              office, Examination section &amp; Principal’s cabin, excellent
              gymnasium and recreational activity room.
            </p>
            <p style={{ textAlign: "justify" }}>
              The campus is endowed with buildings constructed to provide all
              the basic amenities which are required for effective teaching-
              learning process, health care and research. At the commencement of
              the academic year, assessment for replacement or up-gradation of
              the existing infrastructure is carried out as per need and based
              on the suggestions from associated departments and personnel. The
              constituent college have established with their own separate and
              selfcontained buildings with administration area, teaching
              departments, patient wards, classrooms, seminar hall, library,
              laboratories etc.
            </p>
            <div className="details-images">
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/facilities/infra_one.jpeg"
                    alt="Gurukul Top View"
                    height="100%"
                  />
                </div>
                <div className="col-lg-6 col-md-6">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/facilities/infra_two.jpeg"
                    alt="Universiry Front View"
                    height="100%"
                  />
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
          <div className="registration-and-fee">
            <h2>Facilities</h2>
            <p style={{ textAlign: "justify" }}>
              The institute has developed with adequate general campus
              facilities such as office space, faculty rooms, library,
              classrooms, seminar hall, canteen, wash rooms, student amenities
              and residential facilities for students and faculty to facilitate
              the conduct of the various academic, research and extension
              activities associated with the teaching and learning processes.
            </p>
            <div className="list">
              <ul>
                <li>1. CCTV surveillance and 24x7 security service</li>
                <li>
                  2. Adequate separate Cabins/Chambers with essential facilities
                </li>
                <li>3. Go-Green Initiative with Full Fledge Garden Facility</li>
                <li>
                  4. High Speed Internet Connect with 24*7 Electricity Backup
                </li>
              </ul>
            </div>
          </div>
          <div className="prepare-exam">
            <h3>Land, Building &amp; Infrastructure Facilities</h3>
            <p style={{ textAlign: "justify" }}>
              Swaminarayan University is proposed to be established across area
              of 21 acres of land in Saij, Kalol is owned by Shree Swaminarayan
              Vishvamangal Gurukul with proper revenue mutations, usage
              permission for educational institutions in all its campuses. The
              entire land units acquired in phased manner from time to time as
              per mutation records in respective campuses are single unit land
              holding and owned by Shree Swaminarayan Vishwamangal Gurukul. The
              entire land is non-mortgaged. All land for proposed Swaminarayan
              University is free from any encumbrance and no dues of any nature
              including Government due in this respect are outstanding. All
              documents regarding land has been attached with application.
            </p>
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="exam-card">
                  <h4>Corporate Facilities</h4>
                  <p style={{ textAlign: "justify" }}>
                    The institute has developed with adequate general campus
                    facilities such as office space, faculty rooms, library,
                    classrooms, seminar hall, canteen, wash rooms, student
                    amenities and residential facilities for students and
                    faculty to facilitate the conduct of the various academic,
                    research and extension activities associated with the
                    teaching and learning processes.
                    <br /> <br />
                    The entire campus is covered under CCTV surveillance and
                    24x7 security service as well as fire safety. Adequate
                    separate cabins/chambers with essential facilities have been
                    provided to all staff members of corresponding departments
                    including administration office.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="exam-card">
                  <h4>Hostel Facilities</h4>
                  <p style={{ textAlign: "justify" }}>
                    The Sponsoring Body provided rental based hostel facility to
                    institutes’ students a full-fledged separate hostel facility
                    for boys and girls students is accessible. It provides
                    high-quality and safe and sound facilities of lodging and
                    boarding to cater to the needs of outstation students. It
                    has full-fledged facilities with Garden, Gymnasium and
                    well-furnished air conditioned/non-air-conditioned
                    accommodation. Students are monitored with their wellbeing
                    by an efficient and qualified custodian. The newly
                    constructed hostel buildings are provided with internet
                    facilities, individual beds and wardrobes, attached
                    bathrooms, study tables and chairs, morning breakfast and
                    dinners as well. The hostel building has 24*7 power backup
                    and in-house mess facility, maintaining high standard of
                    hygiene and R/O water supply.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="exam-card">
                  <h4>Auditorium</h4>
                  <p style={{ textAlign: "justify" }}>
                    The institute has auditorium with high-tech facilities and
                    having capacity of more than 250 people. The auditorium is
                    accessible for a variety of cultural activities.{" "}
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="exam-card">
                  <h4>Transport</h4>
                  <p style={{ textAlign: "justify" }}>
                    The institute has fleet of buses plying in all the areas of
                    Ahmedabad and Gandhinagar for students, faculty and staff
                    members. Apart from these facilities, the AMTS service is
                    also available from major parts of the city{" "}
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="exam-card">
                  <h4>Sports Ground</h4>
                  <p style={{ textAlign: "justify" }}>
                    The University provides ample opportunities for students to
                    boost their selfesteem and to develop teamwork as well as
                    leadership skills among them. Also, a healthy mind resides
                    in a healthy body. The institute encourages students to
                    participate in various extracurricular events of different
                    level to enrich their lives beyond the academic. The
                    institute has facility for playing and practicing various
                    Indoor and Outdoor sports such as Table tennis, Badminton,
                    Carom, Chess, Kabaddi, Kho-Kho, Volley ball, Athletics and
                    Cricket. The sport ground is developed for various outdoor
                    sports. The institute also has well equipped Badminton court
                    and Indoor Game room for various Indoor sports.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="exam-card">
                  <h4>Gymnasium</h4>
                  <p style={{ textAlign: "justify" }}>
                    Complete Gym Facility Management and administration of a
                    safe, well-run Gym/Fitness Center where students, faculties
                    and staff members will benefit from working with experienced
                    and qualified instructors.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="exam-card">
                  <h4>Cafeteria</h4>
                  <p style={{ textAlign: "justify" }}>
                    The College Canteen is a trendy meeting place for students.
                    Canteens develop social skills among students. It serves an
                    array of eatables at moderate prices. Fully air-conditioned
                    canteen with pleasing to the eye tables and cozy chairs add
                    comfort while enjoying range of savory food. The College
                    operates one fully airconditioned canteen having a seating
                    capacity of fifty people.{" "}
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="exam-card">
                  <h4>ATM &amp; Banking Facility</h4>
                  <p style={{ textAlign: "justify" }}>
                    Sponsoring Body provides facility of IDBI ATM is operative
                    in the campus for his Employee and Students.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="exam-card">
                  <h4>Parking Lot</h4>
                  <p style={{ textAlign: "justify" }}>
                    The College has a separate parking lot in the campus, for
                    students and staff. For regulating entry, parking stickers
                    are issued to the bona-fide users. The parking space is
                    enough for students, staff members and visitors. The
                    sponsoring body has taken painstaking measure to ensure the
                    security of life and property in the campus.{" "}
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="exam-card">
                  <h4>Garden</h4>
                  <p style={{ textAlign: "justify" }}>
                    College has a lavish Garden for the student, to present them
                    place to relax and experience the nature at heart. Gardens
                    are a wonderful and exciting way to make almost any
                    classroom curriculum come alive and show "real-life" meaning
                    to students as they learn. A garden can provide an
                    opportunity for students to participate in hands-on learning
                    that teaches not only the intended subject but also
                    responsibility, teamwork, and respect for nature, others,
                    and themselves.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    </div>
  )
}

export default Infrastructure