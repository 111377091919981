import React from "react";

const principalImage = "/assets/engineering/images/dean-engg.jpg";

function PrincipalMessage() {
  return (
    <div>
      <>
        {/* Start For Content */}
        <div className="container mt-5 mb-5">
          {/* <div className="row">
            <div className="col-md-3">
              <img
                src={principalImage}
                alt="pricipal-image"
                className="img-responsive img-thumbnail"
                width={261}
                height={261}
              />
              <br />
              <br />

              <h3>
                Dr. Vijaykumar B Gadhavi
                <br />
              </h3>
              <p style={{}}>
                Dean, Faculty of IT & Computer Science
                Swaminarayan University, Kalol
                
              </p>
            </div>
            <div className="col-md-9">
              <h2>
                Dean / Principal's Message
                <br />
              </h2>
              <p style={{ textAlign: "justify" }}>
                It is my pleasure to welcome you to the esteemed Swaminarayan
                college of IT & Computer Science at Swaminarayan
                University. As you embark on this transformative journey, you
                become a part of a community dedicated to excellence in
                education, research, and innovation.
                <br />
                <br />
                At our college, we are committed to nurturing the
                next generation of engineers and problem solvers. Our
                comprehensive degree courses are designed to equip you with not
                only technical skills but also critical thinking, creativity,
                and a deep understanding of the societal impact of engineering.
                <br />
                <br />
                Our distinguished faculty members, renowned in their respective
                fields, are passionate educators and dedicated mentors. They
                will guide you through a curriculum that blends theoretical
                knowledge with practical hands-on experience, ensuring that you
                are well-prepared to tackle real-world challenges.
                <br />
                <br />
                Our state-of-the-art laboratories, modern classrooms, and
                collaborative spaces provide you with an environment conducive
                to exploration and learning. You will have access to
                cutting-edge technologies and resources that will empower you to
                turn your ideas into reality.
                <br />
                <br />
                Beyond the classroom, we encourage you to engage in
                extracurricular activities and projects that align with your
                interests. These experiences will not only enhance your
                engineering skills but also help you develop leadership,
                teamwork, and communication abilities.
                <br />
                <br />
                As you navigate your academic journey, remember that success is
                not only measured by grades but by the impact you make on the
                world. Through internships, industry partnerships, and research
                opportunities, you will have the chance to contribute to
                advancements in your chosen field.
                <br />
                <br />
                On behalf of the faculty and staff of the engineering college, I
                extend my best wishes to you. We are excited to accompany you on
                this exciting voyage of discovery and learning. Together, we
                will shape the future through innovation and engineering
                excellence.
                <br />
                <br />
                Sincerely,
                <br />
                Dr. Vijaukumar B Gadhavi
                <br />
                Dean, Faculty of IT & Computer Science
                <br />
                Swaminarayan University, Kalol
              </p>
            </div>
          </div> */}

<div className="row"> 
            <div className="col-md-3">
            <br/>
              <img
                // src="../assets/images/academics/geetanjali.jpg"
                src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/employee_image/Employee_Student_Photo/undefined_1701843038922.26"
                alt="pricipal-image-Dr. Geetanjali"
                className="img-responsive img-thumbnail"
                width={261}
                height={261}
              />
              <br />
              <br />

              <h3>
              Dr. Geetanjali Amarawat

                <br />
              </h3>
              <p style={{}}>
              Dean, Faculty of IT and CS
                Swaminarayan University, Kalol
                
              </p>
            </div>
            <div className="col-md-9">
              <h2>
                Dean / Principal's Message
                <br />
              </h2>
              <p style={{ textAlign: "justify" }}>
                Dear Students ,
                <br/>
                <br/>
                <br/>
              It is my privilege to welcome you to the vibrant community of aspiring engineers here 
              at Swaminarayan college of engineering and technology. As the Dean of the College of Engineering, 
              I extend my heartfelt greetings and am excited to embark on this academic journey with each of you.

                <br />
                <br />
                Engineering is not just a field of study; it's a way of thinking, a passion for innovation, 
                and a commitment to solving complex challenges that shape the world around us. At our college, 
                we are dedicated to nurturing these qualities within our students, equipping you with 
                the knowledge, skills, and mindset necessary to thrive in the dynamic landscape of engineering.

                <br />
                <br />
                Our distinguished faculty members are not only experts in their respective disciplines 
                but also devoted educators who are committed to your intellectual growth and personal development. 
                Through interactive lectures, hands-on projects, and collaborative research, we aim to provide 
                you with an enriching learning experience that goes beyond textbooks.

                <br />
                <br />
                Our state-of-the-art laboratories, cutting-edge research facilities, and industry partnerships offer you the opportunity to engage in practical application of theories learned in the classroom. We believe that innovation flourishes when academic concepts merge with real-world challenges, and we encourage you to seize these opportunities to explore, create, and innovate.

                <br />
                <br />
                As you navigate your academic journey, remember that success is not solely determined by grades, but also by the character you develop, the relationships you build, and the passion you bring to your work. Engage in extracurricular activities, join clubs that align with your interests, and make the most of every opportunity to grow both personally and professionally.
                <br />
                <br />
                Once again, welcome to Swaminarayan college of engineering and technology. I am excited to witness your growth and achievements over the coming years. Together, let's create a legacy of excellence in engineering.
                <br />
                <br />
                Wishing you a fulfilling and transformative journey ahead!
                <br />
                <br />
                Warm regards,
                <br />
                Dr. Geetanjali Amarawat
                <br />
                Dean, Faculty of IT and CS

                <br />
                Swaminarayan University, Kalol
              </p>
            </div>
          </div>
          <br />
          <br />
        </div>{" "}
        {/* End For Content */}
      </>
    </div>
  );
}

export default PrincipalMessage;
