import React from "react";

export default function ArtsEligibility() {
  return (
    <div>
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-md-12">
            <p>
              <span style={{ fontSize: "18pt" }}>
                <strong>Arts Eligibility</strong>
              </span>
              <br />
            </p>

            <p>
              <strong>Arts Eligibility Details:</strong>
              <br />
            </p>
            <table
              className="table table-bordered"
              style={{ borderCollapse: "collapse", width: "100%", height: 173 }}
              border={1}
            >
              <tbody>
                <tr style={{ height: 20 }}>
                  <td>
                    <strong>Sr No</strong>
                    <br />
                  </td>
                  <td>
                    <strong>Course Name</strong>
                    <br />
                  </td>
                  <td>
                    <strong>Eligibility</strong>
                    <br />
                  </td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td>
                    1
                    <br />
                  </td>
                  <td>
                    BA (Hons) English & Sanskrit
                    <br />
                  </td>
                  <td>
                    Candidate must have completed class 12th in any stream
                    whether science, Arts or commerce but most preferably Arts.
                  </td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td>
                    2
                    <br />
                  </td>
                  <td>
                    MA (English & Sanskrit)
                    <br />
                  </td>
                  <td>
                    Graduation in any stream but most preferably Arts
                    <br />
                  </td>
                </tr>
              </tbody>
            </table>

            <table
              className="table table-bordered"
              style={{ borderCollapse: "collapse", width: "100%", height: 173 }}
              border={1}
            >
              <tbody>
                <tr style={{ height: 20 }}>
                  <td>
                    <strong>Cource Name</strong>
                    <br />
                  </td>
                  <td>
                    <strong>Eligibility</strong>
                    <br />
                  </td>
                  <td>
                    <strong>Duration</strong>
                    <br />
                  </td>
                  <td>
                    <strong>Semester / Non-Semester </strong>
                    <br />
                  </td>
                  <td>
                    <strong>Proposed Fee </strong>
                    <br />
                  </td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td>Diploma in Sanskrit</td>
                  <td>10th/SSLC or Equivalent</td>
                  <td>One year</td>
                  <td>Annual</td>
                  <td>6,000</td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td>Diploma in Astrology</td>
                  <td>10th/SSLC or Equivalent</td>
                  <td>One year</td>
                  <td>Annual</td>
                  <td>10,000</td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td>Diploma in Vedic Studies(Paurohitya)</td>
                  <td>10th/SSLC or Equivalent</td>
                  <td>One year</td>
                  <td>Annual</td>
                  <td>6,000</td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td>Diploma in Vasthusastra</td>
                  <td>10th/SSLC or Equivalent</td>
                  <td>One year</td>
                  <td>Annual</td>
                  <td>10,000</td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td>Diploma in Vedanthic philosophy</td>
                  <td>10th/SSLC or Equivalent</td>
                  <td>One year</td>
                  <td>Annual</td>
                  <td>6,000</td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td>BA-Sanskrit</td>
                  <td>10+2 or Equivalent</td>
                  <td>3 years</td>
                  <td>Semester</td>
                  <td>10,000 Per Semester</td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td>BA-Astrology</td>
                  <td>10+2 or Equivalent</td>
                  <td>3 years</td>
                  <td>Semester</td>
                  <td>10,000 Per Semester</td>
                </tr>
                <tr style={{ height: 20 }}>
                  <td>MA-Astrology</td>
                  <td>Any Degree or Equivalent from a recognised University</td>
                  <td>2 years</td>
                  <td>Annual</td>
                  <td>20,000</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
