export const BASE_URL = "https://server.swaminarayanuniversity.ac.in/api";
// export const BASE_URL = "http://localhost:4000/api";

//SUK230804LB029
// export const BASE_URL = process.env.REACT_APP_MODE == "development" ? "http://localhost:4000" : "https://server.swaminarayanuniversity.ac.in";

// export const BASE_URL = 'http://localhost:4000/api'

export default `${BASE_URL}/admission/otp`;

export const VERIFY_OTP = `${BASE_URL}/frontOffice/admission-enquiry`;

export const PHD_ADMISSION = `${BASE_URL}/admission/phdadmissions`;
export const PAYMENT_CHECK = `${BASE_URL}/payment/check`;
export const PHD_CHECK = `${BASE_URL}/admission/phdcheck`;

export const AWS_URL_GENERATOR = `${BASE_URL}/asset/url`;

export const COLLEGE = `${BASE_URL}/hr/college`;

export const DEPARTMENT = `${BASE_URL}/hr/department`;
export const PREV_QUESTION_PAPER_GET = `${BASE_URL}/infoUploading/getPrevQuestionPaper`;
export const PREV_QUESTION_PAPER_GET1 = `${BASE_URL}/infoUploading/getPrevQuestionPaper1`;

export const EXAM_RESULT = `${BASE_URL}/exam/result`;
export const PREV_EXAM_RESULT = `${BASE_URL}/exam/prevresult`;
export const PREV_ENGG_EXAM_RESULT = `${BASE_URL}/exam/prevgetResultsEng`;
export const PREV_PHARM_EXAM_RESULT = `${BASE_URL}/exam/prevgetResultsPharm`;

export const EXAM_RESULT_ENG = `${BASE_URL}/exam/result-eng`;

export const EXAM_RESULT_PHARMACY = `${BASE_URL}/exam/result-pharmacy`;
export const RE_EXAM_RESULT_PHARMACY = `${BASE_URL}/exam/re-exam/result-pharmacy`;
export const RE_EXAM_RESULT_ENG = `${BASE_URL}/exam/re-exam/result-eng`;
export const EXAM_RESULTS_DRAFT = `${BASE_URL}/exam/draftResult`;
export const EXAM_RESULTS_DRAFT_ENG = `${BASE_URL}/exam/draftResult-eng`;
export const EXAM_RESULTS_DRAFT_PHAR = `${BASE_URL}/exam/draftResult-pharmacy`;

export const HDFC_PAYMENT_GATEWAY = `${BASE_URL}/payment/hdfc`;
export const HDFC_PAYMENT_GATEWAY1 = `${BASE_URL}/payment/hdfc`;

export const ALL_DATA = `${BASE_URL}/hr/data/getall`; //get

//Student Details

export const ALL_STUDENTS = `${BASE_URL}/student`;

export const STUDENTBYID = `${BASE_URL}/student`;

export const STUDENT_SESSION = `${BASE_URL}/student/session/all`;

export const STUDENT_EXAM_DETAILS = `${BASE_URL}/exam/student/exam-info`;

export const PRE_EXAM_COMMITTEE = `${BASE_URL}/exam/pre/exam-committee`;

export const PRE_STUDENT_ENROLLMENT = `${BASE_URL}/exam/pre/student`;

export const REMEDIAL_RESULTS = `${BASE_URL}/exam/re-exam/results`;
export const CALCULATE_PREVIOUS_RE_EXAM = `${BASE_URL}/exam/re-exam/calculate`;

//Student Complaints

export const STUDENT_COMPLAINT = `${BASE_URL}/student/complaint`;

export const STUDENT_COMPLAINT_GET = `${BASE_URL}/student/complaint`;

// TEACHING AND NON-TEACHING STAFF

export const STAFF_DATA = `${BASE_URL}/employee/newStaff`;
