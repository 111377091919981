import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { HDFC_PAYMENT_GATEWAY } from "../../Utils/apiConst";
import { useNavigate, useLocation } from "react-router-dom";
import { GET_EXAM_FORM } from "../../Utils/PHDapiconst.js";
import ProgressBar from "../../Components/ProgressBar/ProgressBar";
import { toast } from "react-toastify";
import { ROUTES } from "../../Router/RouterConfig";

const StudentDueFeesPayment = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  console.log(state?.data);
  const routing = () => {
    let a = localStorage.getItem("redirect");
    if (state == null || a) {
      localStorage.removeItem("redirect");
      navigate(ROUTES.PayFees);
    } else {
      setName(state.data.name);
      setEmail(state.data.email);
      setAddress(state.data.address);
      setCity(state.data.place);
    }
  };

  const [amount, setAmount] = useState(5000);
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [usn, setUsn] = useState();
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [stateName, setState] = useState("Gujarat");
  const [country, setCountry] = useState("India");
  const [pincode, setPincode] = useState("");

  const ref = useRef();

  let value = 2;
  const [data, setData] = useState();
  const [uid, setUid] = useState();

  useEffect(() => {
    routing();
  }, []);

  const handleSubmit = async () => {
    if (
      !amount ||
      !name ||
      !email ||
      !address ||
      !city ||
      !stateName ||
      !country ||
      !pincode
    ) {
      toast.error("Please fill all the fields");
      return;
    }
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
      url: HDFC_PAYMENT_GATEWAY,
      data: {
        amount,
        merchant_param1: name,
        merchant_param2: usn,
        merchant_param3: email,
        merchant_param4: "PHD_Exam_Payment",
        merchant_param5: uid,
        billing_address: address,
        billing_city: city,
        billing_state: stateName,
        billing_country: country,
        billing_zip: pincode,
        merchant_faculty: 1,
      },
      method: "post",
    };
    await axios(config)
      .then((res) => {
        console.log(res.data);
        localStorage.setItem("Payment_details", JSON.stringify(res.data));
        setData(res.data);
        localStorage.setItem("redirect", true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (data && data.encRequest && data.accessCode) {
      ref.current.click();
    }
  }, [data]);

  const route = () => {
    value = 1;
    navigate(ROUTES.PayFees);
  };

  return (
    <div style={{ minHeight: "500px" }} className="container">
      <div className="card-body">
        <div className="row">
          <div className="col-12 d-flex justify-content-center mb-5">
            <img
              src="/assets/images/white-logo.png"
              style={{ width: "300px", margin: "auto" }}
              alt=""
            />
          </div>
          <div className="col-md-12 d-flex justify-content-center ">
            <h1>Payment Of Due Fees Year Wise</h1>
          </div>
          <hr />
          <ProgressBar value={value} />
          <div className="row">
            <div className="col-md-3"></div>
            <div className="col-md-6">
              <h5>Pay Online</h5>
              <div className="form-group">
                <label>Amount in Rs</label>
                <input
                  value={state.data?.amount}
                  readOnly={true}
                  type="number"
                  className="form-control"
                  placeholder="Enter amount here"
                />
              </div>
              <div className="row mt-2">
                <div className="col-6">
                  <div className="form-group">
                    <label>Name</label>
                    <input
                      value={name || state?.data1?.name}
                      onChange={(e) => setName(e.target.value)}
                      type="text"
                      className="form-control"
                      placeholder="Enter Full Name here"
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label>Enrollment Number(optional)</label>
                    <input
                      value={usn || state?.data?.user_id}
                      onChange={(e) => setUsn(e.target.value)}
                      type="text"
                      className="form-control"
                      placeholder="Starts with SUK..."
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="form-group">
                  <label>Email</label>
                  <input
                    type="email"
                    value={email || state?.data?.email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    className="form-control"
                    placeholder="Enter Email here"
                  />
                  <p>
                    <strong className="text-danger"> Note: </strong> You will
                    recieve a email with the reciept of payment
                  </p>
                </div>
              </div>
              <div className="row mt-2">
                <div className="form-group">
                  <label>Address</label>
                  <input
                    type="text"
                    value={address || state?.data?.address}
                    onChange={(e) => {
                      setAddress(e.target.value);
                    }}
                    className="form-control"
                    placeholder="Enter Address here"
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-6">
                  <div className="form-group">
                    <label>City </label>
                    <input
                      value={city || state?.data?.city}
                      onChange={(e) => setCity(e.target.value)}
                      type="text"
                      className="form-control"
                      placeholder="Enter City here"
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label>state</label>
                    <input
                      value={stateName}
                      onChange={(e) => setState(e.target.value)}
                      type="text"
                      className="form-control"
                      placeholder="Enter state here"
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-6">
                  <div className="form-group">
                    <label>Country</label>
                    <input
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                      type="text"
                      className="form-control"
                      placeholder="Enter Country here"
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label>Pincode</label>
                    <input
                      value={pincode}
                      onChange={(e) => setPincode(e.target.value)}
                      type="text"
                      className="form-control"
                      placeholder="Enter Pincode here"
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex">
                <button
                  onClick={() => handleSubmit()}
                  className="btn btn-danger mt-3 mb-5"
                >
                  Pay Now
                </button>

                <button
                  onClick={() => route()}
                  className="btn btn-danger mt-3 mb-5"
                  style={{ marginLeft: "1rem" }}
                >
                  Go back
                </button>
              </div>
            </div>
            <div className="col-md-3"></div>
          </div>
          <div>
            {data && data.encRequest && data.accessCode && (
              <form
                method="post"
                action="https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction"
              >
                <input
                  type="hidden"
                  id="encRequest"
                  name="encRequest"
                  value={data.encRequest}
                />
                <input
                  type="hidden"
                  name="access_code"
                  id="access_code"
                  value={data.accessCode}
                />
                <input
                  ref={ref}
                  type="submit"
                  className="btn btn-danger"
                  value="Pay Now"
                  style={{ display: "none" }}
                />
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentDueFeesPayment;
