import React from "react";
import { Http } from "../../Services/Services";
import { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { feeData } from "../../Data/ExamFee";
import {
  HDFC_PAYMENT_GATEWAY1,
  HDFC_PAYMENT_GATEWAY,
} from "../../Utils/apiConst";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import Loader from "../../Components/Loader/Loader";
import { STUDENT_EXAM_DETAILS } from "../../Utils/apiConst";
import ProgressBar from "../../Components/ProgressBar/ProgressBar";

function StudentPayment() {
  let value = 2;
  const navigate = useNavigate();

  const [last, setLast] = useState(false);

  const ref = useRef();

  const { state } = useLocation();
  console.log(state);
  const verify = async () => {
    if (
      !state?.lastStep &&
      (state?.step2 == undefined || state?.step2 == null)
    ) {
      navigate("/exam-registeration");
    } else if (state?.lastStep || state?.step2) {
      await Http.get(`${HDFC_PAYMENT_GATEWAY1}/${state?.data?.sid}`).then(
        (res) => {
          console.log("Payment Data", res.data.data);
          if (res.data.data?.order_status == "Success") {
            setLast(true);
          }
        }
      );
    }
  };

  const params = useParams();

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState([]);

  const [paymentdata, setPaymentdata] = useState();

  const [amount, setAmount] = useState(1);

  const [x, setX] = useState();

  const [name, setName] = useState(state?.data?.name);
  const [email, setEmail] = useState(state?.data?.email);
  const [usn, setUsn] = useState(state?.data?.sid);
  const [address, setAddress] = useState(state?.data?.address);
  const [city, setCity] = useState(state?.data?.city);
  const [stateName, setState] = useState("Gujarat");
  const [country, setCountry] = useState("India");
  const [pincode, setPincode] = useState("");
  const [classid, setClassid] = useState(state?.data?.class_examination_id);

  let amt;
  const getData = async () => {
    setLoading(true);
    await Http.get(STUDENT_EXAM_DETAILS + "/" + params.id)
      .then(async (res) => {
        console.log(res.data);
        setData(res.data);
        amt = await feeData?.find(
          (s) =>
            s.college_id == res.data?.student?.college_id &&
            s.program_id == res.data?.student?.program_id
        );

        console.log("amount for exam", amt);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response?.data?.message || "Something went wrong");
      });

    let clgid = localStorage.getItem("Phdcollege_id");
    let pid = localStorage.getItem("Phdprogram_id");
    let clgdata = JSON.parse(localStorage.getItem("COLLEGE"));
    let pgmdata = JSON.parse(localStorage.getItem("PROGRAM"));
    let a = await clgdata?.find((s) => s.id == clgid)?.name;
    let b = await pgmdata?.find((s) => s.id == pid)?.name;
    setX(amt);
    await setAmount(amt?.FeeforSemester + amt?.Formfee);
    setLoading(false);
  };

  const handleSubmit = async () => {
    if (
      !amount ||
      !name ||
      !email ||
      !address ||
      !city ||
      !stateName ||
      !country ||
      !pincode
    ) {
      console.log(
        amount,
        name,
        email,
        address,
        city,
        stateName,
        country,
        pincode
      );
      toast.error("Please fill all the fields");
      return;
    }
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
      url: HDFC_PAYMENT_GATEWAY,
      data: {
        amount: 1,
        merchant_param1: name,
        merchant_param2: usn,
        merchant_param3: email,
        merchant_param4: "COURSE_REGISTERATION_PAYMENT",
        merchant_param5: classid,
        billing_address: address,
        billing_city: city,
        billing_state: stateName,
        billing_country: country,
        billing_zip: pincode,
        merchant_faculty: 1111012,
        faculty: 1111012,
        // merchant_faculty: sessionStorage.getItem("clg_id"),
        // faculty: sessionStorage.getItem("clg_id"),
        // college_id: sessionStorage.getItem("clg_id"),
        // department_id: sessionStorage.getItem("dept_id"),
      },
      method: "post",
    };
    await axios(config)
      .then((res) => {
        console.log(res.data);
        localStorage.setItem("Payment_details", JSON.stringify(res.data));
        setPaymentdata(res.data);
        localStorage.setItem("redirect", true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
    verify();
  }, []);

  useEffect(() => {
    setPincode(data?.student?.pin || state?.data?.pin);
    setAddress(state?.data?.address || data?.student?.current_address);
  }, [data, state]);

  useEffect(() => {
    if (paymentdata && paymentdata.encRequest && paymentdata.accessCode) {
      ref.current.click();
    }
  }, [paymentdata]);

  return (
    <div>
      <ProgressBar value={value} />
      <Loader loading={loading} />
      <div
        className="register d-flex justify-content-center align-items-center p-5"
        style={{ maxWidth: "1300px" }}
      >
        <div className="w-sm-50 w-100">
          {!last ? (
            <div className="row mt-5">
              <h3>Applied Subjects</h3>

              <table className="mb-3">
                <tr>
                  <th>Sl.No</th>
                  <th>Subject</th>
                  <th>Exam Date</th>
                  <th>Enrollment</th>
                </tr>
                {data?.timeTableData?.map((i, key) => {
                  const enrollment = data?.enrollmentData?.find(
                    (s) => s.subject == i.course_id
                  );

                  if (enrollment) {
                    return (
                      <tr key={key}>
                        <td>{key + 1}</td>
                        <td>
                          {
                            data?.courseData?.find((s) => s.id === i.course_id)
                              ?.name
                          }
                        </td>
                        <td>{i.date?.split("T")[0]}</td>
                        <td>
                          <button
                            className={`btn btn${
                              data?.enrollmentData?.find(
                                (s) => s.subject == i.course_id
                              )?.status == "PENDING" ||
                              data?.enrollmentData?.find(
                                (s) => s.subject == i.course_id
                              )?.status == "ACTIVE"
                                ? "-outline-"
                                : "-"
                            }danger`}
                          >
                            {data?.enrollmentData?.find(
                              (s) => s.subject == i.course_id
                            )?.status == "ACTIVE"
                              ? "Applied"
                              : data?.enrollmentData?.find(
                                  (s) => s.subject == i.course_id
                                )?.status == "PENDING"
                              ? "Pending"
                              : "Removed by higher authority"}
                          </button>
                        </td>
                      </tr>
                    );
                  } else {
                    return null;
                  }
                })}
              </table>
              <div style={{ paddingTop: "2rem" }}>
                <h3>Exam Fees</h3>
                <div className="d-flex" style={{ marginBottom: "2rem" }}>
                  <div className="col-sm-3">
                    <label>Exam Form Fee</label>
                    <input
                      value={x?.Formfee}
                      readOnly={true}
                      className="form-control"
                    />
                  </div>
                  <h1 style={{ marginLeft: "1.5rem", marginTop: "2.4rem" }}>
                    +
                  </h1>
                  <div className="col-sm-3" style={{ paddingLeft: "1.5rem" }}>
                    <label>Exam Fee</label>
                    <input
                      value={x?.FeeforSemester}
                      readOnly={true}
                      className="form-control"
                    />
                  </div>
                  <h1 style={{ marginLeft: "1.5rem", marginTop: "2.4rem" }}>
                    =
                  </h1>

                  <div className="col-sm-3" style={{ paddingLeft: "1.5rem" }}>
                    <label>Total Payable Fee</label>
                    <input
                      value={x?.FeeforSemester + x?.Formfee}
                      readOnly={true}
                      type="number"
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <div className="row" style={{ marginTop: "1rem" }}>
                <div className="col-md-12">
                  <h5>Pay Online</h5>
                  <div className="form-group">
                    <label>Amount in Rs</label>
                    <input
                      value={x?.FeeforSemester + x?.Formfee}
                      readOnly={true}
                      type="number"
                      className="form-control"
                      placeholder="Enter amount here"
                    />
                  </div>
                  <div className="row mt-2">
                    <div className="col-6">
                      <div className="form-group">
                        <label>Name</label>
                        <input
                          value={
                            name || state?.data?.name || data?.student?.name
                          }
                          onChange={(e) => setName(e.target.value)}
                          type="text"
                          readOnly="true"
                          className="form-control"
                          placeholder="Enter Full Name here"
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label>Enrollment Number(optional)</label>
                        <input
                          value={
                            usn || state?.data?.sid || data?.student?.user_id
                          }
                          onChange={(e) => setUsn(e.target.value)}
                          type="text"
                          readOnly="true"
                          className="form-control"
                          placeholder="Starts with SUK..."
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="form-group">
                      <label>Email</label>
                      <input
                        type="email"
                        value={
                          email || state?.data?.email || data?.student?.email
                        }
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        className="form-control"
                        placeholder="Enter Email here"
                      />
                      <p>
                        <strong className="text-danger"> Note: </strong> You
                        will recieve a email with the reciept of payment
                      </p>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="form-group">
                      <label>Address</label>
                      <input
                        type="text"
                        value={
                          address ||
                          state?.data?.address ||
                          data?.student?.current_address
                        }
                        onChange={(e) => {
                          setAddress(e.target.value);
                        }}
                        className="form-control"
                        placeholder="Enter Address here"
                      />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-6">
                      <div className="form-group">
                        <label>City </label>
                        <input
                          value={
                            city || state?.data?.city || data?.student?.city
                          }
                          onChange={(e) => setCity(e.target.value)}
                          type="text"
                          className="form-control"
                          placeholder="Enter City here"
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label>state</label>
                        <input
                          value={stateName}
                          onChange={(e) => setState(e.target.value)}
                          type="text"
                          className="form-control"
                          placeholder="Enter state here"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-6">
                      <div className="form-group">
                        <label>Country</label>
                        <input
                          value={country}
                          onChange={(e) => setCountry(e.target.value)}
                          type="text"
                          className="form-control"
                          placeholder="Enter Country here"
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label>Pincode</label>
                        <input
                          value={
                            pincode || data?.student?.pin || state?.data?.pin
                          }
                          onChange={(e) => setPincode(e.target.value)}
                          type="text"
                          className="form-control"
                          placeholder="Enter Pincode here"
                        />
                      </div>
                    </div>
                    <div
                      className=" float-end d-flex"
                      style={{ marginTop: "2rem" }}
                    >
                      <button
                        className="btn btn-danger"
                        onClick={() => handleSubmit()}
                      >
                        Pay Now
                      </button>

                      <button
                        className="btn btn-danger"
                        style={{ marginLeft: "2rem" }}
                        onClick={() =>
                          navigate("/exam-registeration", {
                            state: { step2: true },
                          })
                        }
                      >
                        Go back
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                {paymentdata &&
                  paymentdata.encRequest &&
                  paymentdata.accessCode && (
                    <form
                      method="post"
                      action="https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction"
                    >
                      <input
                        type="hidden"
                        id="encRequest"
                        name="encRequest"
                        value={paymentdata.encRequest}
                      />
                      <input
                        type="hidden"
                        name="access_code"
                        id="access_code"
                        value={paymentdata.accessCode}
                      />
                      <input
                        ref={ref}
                        type="submit"
                        className="btn btn-danger"
                        value="Pay Now"
                        style={{ display: "none" }}
                      />
                    </form>
                  )}
              </div>
            </div>
          ) : (
            <div className="row mt-5">
              You have Registered for the following courses.
              <table className="mb-3 mt-3">
                <tr>
                  <th>Sl.No</th>
                  <th>Subject</th>
                  <th>Exam Date</th>
                </tr>
                {data?.timeTableData?.map((i, key) => {
                  const enrollment = data?.enrollmentData?.find(
                    (s) => s.subject == i.course_id
                  );

                  if (enrollment) {
                    return (
                      <tr key={key}>
                        <td>{key + 1}</td>
                        <td>
                          {
                            data?.courseData?.find((s) => s.id === i.course_id)
                              ?.name
                          }
                        </td>
                        <td>{i.date?.split("T")[0]}</td>
                      </tr>
                    );
                  }
                })}
              </table>
              <br />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default StudentPayment;
