export const StaffDeptWise = {
    Anatomy: [
      "Anatomy",
      "Anatomy",
    ],
    Physiology: [
        "Physiology",
        "Physiology",
    ],
    Biochemistry: [
        "Biochemistry",
        "Biochemistry",
    ],
    Pharmacology: [
        "Pharmacology",
        "Pharmacology",
    ],
    Pathology: [
        "Pathology",
        "Pathology",
      ],
      Microbiology: [
        "Microbiology",
        "Microbiology",
      ],
      ForensicMedicine: [
        "Forensic Medicine",
        "Forensic Medicine",
      ],
      CommunityMedicine: [
        "Community Medicine",
        "Community Medicine",
      ],
      GeneralMedicine: [
        "General Medicine",
        "General Medicine",
      ],
      Pediatrics: [
        "Pediatrics",
        "Pediatrics",
      ],
      Dermatology: [
        "Dermatology",
        "Dermatology",
      ],
      Psychiatry: [
        "Psychiatry",
        "Psychiatry",
      ],
      GeneralSurgery: [
        "General Surgery",
        "General Surgery",
      ],
      Orthopedics: [
        "Orthopedics",
        "Orthopedics",
      ],
      Ophthalmology: [
        "Ophthalmology",
        "Ophthalmology",
      ],
      Otorhinolaryngology: [
        "Otorhinolaryngology",
        "Otorhinolaryngology",
      ],
      ObstetrcisGynecology: [
        "Obstetrcis & Gynecology",
        "Obstetrcis & Gynecology",
      ],
      Radiodiagnosis: [
        "Radiodiagnosis",
        "Radiodiagnosis",
      ],
      Anesthesia: [
        "Anesthesia",
        "Anesthesia",
      ],
      Dentistry: [
        "Dentistry",
        "Dentistry",
      ],

  };
  