import React from "react";

const principalImage = "/assets/nursing/images/trilok.png";

function PrincipalMessage() {
  return (
    <div>
      <>
        {/* Start For Content */}
        <div className="container mt-5 mb-5">
          <div className="row">
            <div className="col-md-3">
              <img
                src={principalImage}
                alt="pricipal-image"
                className="img-responsive img-thumbnail mt-3"
                width={261}
                height={261}
              />
              <br />
              <br />

              <h3>
                Prof. (Dr.) Trilok Sompura
                <br />
              </h3>
              <p style={{}}>
                Ph.D. Nursing, M.Sc. Nursing (Community Health Nursing) <br />
                C.OS. (Osteopathy), LLB, FCC (Forensic Nursing)
              </p>
            </div>
            <div className="col-md-9">
              <h2>
                Principal's Message
                <br />
                <br />
              </h2>
              <h6>A Warm Welcome to all,</h6>
              <p style={{ textAlign: "justify" }}>
                It is with great pleasure that I extend my warm greetings to
                each one of you. As the Dean/Principal of Shree Swaminarayan
                College of Nursing, Kalol, I am honoured to lead an institution
                dedicated to excellence in nursing education.
                <br />
                <br />
                Our college stands as a beacon of knowledge, fostering academic
                growth and nurturing compassionate healthcare professionals.
                With programs spanning Ph.D. Nursing, M.Sc. Nursing, B.Sc.
                Nursing, General Nursing and Midwifery (GNM), and ANM, we are
                committed to shaping the future of nursing through rigorous
                education, research, and practice.
                <br />
                <br />
                At Shree Swaminarayan College of Nursing, we believe in holistic
                development. Our students not only acquire clinical skills but
                also imbibe values of empathy, integrity, and patient-centered
                care and to fulfil that our college is having to PSM Hospital a
                multi-specialty hospital within the campus for clinical
                practicum as a parent Hospital. We encourage critical thinking,
                evidence-based practice, and lifelong learning.
                <br />
                <br />
                As we navigate the ever-evolving healthcare landscape, our
                faculty and staff work tirelessly to provide quality education.
                Our lush green campus, digital classrooms, well-equipped labs,
                and library serve as the foundation for academic excellence.
                <br />
                <br />
                The College is recognized by Indian nursing council, New Delhi,
                Gujarat Nursing Council, Ahmedabad and a constituent college of
                Swaminarayan University, Kalol. Our excellent and efficient team
                of faculty plan and implement both curricular and co-curricular
                activities meticulously.
                <br />
                <br />
                I invite you all to be part of our journey—a journey that
                celebrates knowledge, compassion, and service. Let us
                collaborate, learn, and contribute to the noble field of
                nursing. Together, we will make a lasting impact on patient care
                and community health.
                <br />
                <br />I look forward to a fruitful association with each one of
                you.
                <br />
                <br />
                <h6>Warm regards,</h6>
                Prof. (Dr.) Trilok Sompura
                <br />
                Dean, Faculty of Nursing, Swaminarayan University,
                <br />
                Principal, Shree Swaminarayan College of Nursing, Kalol-
                Gandhinagar - 382725
              </p>
            </div>
          </div>
          <br />
          <br />
        </div>{" "}
        {/* End For Content */}
      </>
    </div>
  );
}

export default PrincipalMessage;
