import React from 'react'

function Transportation() {
  return (
    <div className='Transportation'>
        <>
  <div className="campus-life-area pt-60 pb-70">
    <div className="container">
      <h3 className="text-center mb-4 ">Transportation</h3>
      <p className="text-center">
        The University has a fleet of buses plying in all the areas of Ahmedabad
        and Gandhinagar for students, faculty and staff members. Apart from
        these facilities, the AMTS service is also available from major parts of
        the city.
      </p>
  
 
  <div className="row justify-content-center mt-3">
    <div className="col-lg-5 col-md-6">
      <div className="single-campus-card">
        <div className="img">
          <a href="">
            <img
              src="../assets/images/facilities/transportation-1.jpg"
              alt="Image"
            />
          </a>
        </div>
      </div>
    </div>
    <div className="col-lg-5 col-md-6">
      <div className="single-campus-card">
        <div className="img">
          <a href="">
            <img
              src="../assets/images/facilities/transportation-2.jpg"
              alt="Image"
            />
          </a>
        </div>
      </div>
    </div>
    <div className="col-md-12">
   
    </div>
  
    <div className="col-md-4 mt-3">
   
    <h4>PROPOSED ROUTE</h4><hr/>
   <object data="https://nexenstial.in/unversity_assests/PROPOSED%20ROUTE.pdf" width="100%" height="600px">
     <p>Unable to display PDF file. <a href="https://nexenstial.in/unversity_assests/PROPOSED%20ROUTE.pdf">Download</a> instead.</p>
   </object>
   </div>
   <div className="col-md-4 mt-3">
   
    <h4>ROUTE 1 BUS 5040</h4><hr/>
   <object data="https://nexenstial.in/unversity_assests/ROUTE 1 BUS 5040.pdf" width="100%" height="600px">
     <p>Unable to display PDF file. <a href="https://nexenstial.in/unversity_assests/ROUTE 1 BUS 5040.pdf">Download</a> instead.</p>
   </object>
   </div>
   <div className="col-md-4 mt-3">
   
    <h4>ROUTE 2 BUS 7920</h4><hr/>
   <object data="https://nexenstial.in/unversity_assests/ROUTE 2 BUS 7920.pdf" width="100%" height="600px">
     <p>Unable to display PDF file. <a href="https://nexenstial.in/unversity_assests/ROUTE 2 BUS 7920.pdf">Download</a> instead.</p>
   </object>
   </div>
   <div className="col-md-4 mt-3">
   
    <h4>ROUTE 3 BUS 2096</h4><hr/>
   <object data="https://nexenstial.in/unversity_assests/ROUTE 3 BUS 2096.pdf" width="100%" height="600px">
     <p>Unable to display PDF file. <a href="https://nexenstial.in/unversity_assests/ROUTE 3 BUS 2096.pdf">Download</a> instead.</p>
   </object>
   </div>
   
   <div className="col-md-4 mt-3">
   
   <h4>ROUTE 4 BUS 5268</h4><hr/>
  <object data="https://nexenstial.in/unversity_assests/ROUTE 4 5268.pdf" width="100%" height="600px">
    <p>Unable to display PDF file. <a href="https://nexenstial.in/unversity_assests/ROUTE 4 5268.pdf">Download</a> instead.</p>
  </object>
  </div>
  </div>
  </div>
  </div>
  
</>

    </div>
  )
}

export default Transportation